<div fxLayout="row" class="full-width ranger-slider">
  <!-- <div style="margin-top: 25px">{{ min }}</div>
  <mat-slider
    style="width: 50vh"
    [(ngModel)]="minValue"
    [color]="minColor"
    [displayWith]="formatLabel"
    [max]="max"
    [min]="min"
    matSliderThumb
    [discrete]="true"
    class="full-width cdk-focused slider-over slider-min-value"
    step="1"
    #ngSlider
  >
    <input
      matSliderThumb
      (change)="valueChange()"
      #ngSliderThumb="matSliderThumb"
      (input)="
        minValueInput({
          source: ngSliderThumb,
          parent: ngSlider,
          value: ngSliderThumb.value
        })
      "
    />
  </mat-slider>
  <mat-slider
    style="width: 50vh; left: -128px"
    [(ngModel)]="maxValue"
    [color]="maxColor"
    [displayWith]="formatLabel"
    [max]="max"
    [min]="min"
    matSliderThumb
    [discrete]="true"
    class="full-width cdk-focused slider-over slider-max-value"
    step="1"
    #ngSlider
    ><input
        matSliderThumb
        (change)="valueChange()"
        #ngSliderThumb="matSliderThumb"
        (input)="
          maxValueInput({
            source: ngSliderThumb,
            parent: ngSlider,
            value: ngSliderThumb.value
          })
        "
    />
  </mat-slider> -->
  <mat-slider style="width: 50vh" [min]="min" [max]="max" discrete>
    <input [(ngModel)]="minValue" (dragEnd)="valueChange()" matSliderStartThumb />
    <input [(ngModel)]="maxValue" (dragEnd)="valueChange()" matSliderEndThumb />
  </mat-slider>
  <!-- <div style="margin-top: 25px; margin-left: -128px">{{ max }}</div> -->

  
</div>
