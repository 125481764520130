<div
  *ngIf="builderMode"
  class="outerCover"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter(); customMouseEnter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick()"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover">
    <div class="separator"></div>
  </div>
</div>


<div *ngIf="!builderMode" class="outerCover" (click)="onClick()">
  <div class="innerCover">
    <div class="separator"></div>
  </div>
</div>
