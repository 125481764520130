
<div>
<div (click)="$event.stopPropagation()"  fxLayout="row" fxLayoutAlign="center center">
  <div>
  <div  *ngFor="let app of integrationsMap.list; index as i">
    <div class="build_button_div"  style="margin: 10px;" (click)="navigateToIntegration(integrationsMap[app])">
      <div class="build_button" fxLayout="row">
        <div fxLayout fxLayoutAlign="center center" fxLayout="column" fxFlex="20">
          <img class="image" [src]="integrationsMap[app].logo">
        </div>
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="80">
          <div style="color: #3f51b5; font-size:  large;; font-weight: bold;" fxLayout="row"
          fxLayoutAlign="start center"> {{integrationsMap[app].title}}</div>
          <div *ngIf="integrationsMap[app].tagline" fxLayout="row" style="color: #6f7385;font-size: 12px;" fxLayoutAlign="start center">
            {{integrationsMap[app].tagline}}</div>
      </div>
      </div>
    </div>
  </div>
  </div>
</div>

</div>
