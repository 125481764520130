import { Injectable } from "@angular/core";
import { ExpressionRegistry } from "./ExpressionRegistry";

@Injectable({
  providedIn: 'root'
})
export class ExpressionManager {
    constructor(
      public expressionsRegistry: ExpressionRegistry
    ){}

    getExpressionDetail(id : string) {
      return this.expressionsRegistry.getExpressionDetail(id);
    }

    getAllExpressionDetails(widgetType?: string) {
      console.log("widgetType", widgetType)
      let expressions = this.expressionsRegistry.getAllExpressionDetails(widgetType)
      console.log("[MANAGER] all expressions:", expressions)
      return expressions
    }

    getExpressionClass(id: string) {
      let expressionClass = this.expressionsRegistry.getExpressionClass(id);
      console.log("[MANAGER] expressionClass:", expressionClass)
      return expressionClass
    }

    getAllExpressionClasses() {
      let expressionClass = this.expressionsRegistry.getAllExpressionClasses();
      console.log("[MANAGER] all expression classes:", expressionClass)
      return expressionClass
    }

}
