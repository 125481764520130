import { Injectable } from "@angular/core";
import { MetaService } from "src/app/bloom/services/meta-service";
import { PageService } from "src/app/bloom/services/page-service.service";
import { ThemeService } from "../services/theme.service";
import { ExpressionManager } from "./ExpressionManager";
import { WidgetService } from "src/app/bloom/services/widget-service.service";

@Injectable({
  providedIn: 'root'
})
export class ExpressionUtility {
    constructor(
      private metaService: MetaService,
      private expressionManager: ExpressionManager,
      private themeService: ThemeService,
      private pageService: PageService,
      public widgetService: WidgetService

    ) {
      console.log("[Expression utility]", this.themeService)
    }

    getExpression(id : string) {
      return this.expressionManager.getExpressionClass(id);
    }

    resolvePageModelExpressions(pageModel: any){
      console.log("page model imported",  JSON.parse(JSON.stringify(pageModel)))
      Object.keys(pageModel).forEach(panelId => {
        Object.keys(pageModel[panelId]).forEach(widgetId => {
          let val = pageModel[panelId][widgetId]['value']
          if(typeof val == "string" && val.substring(0, 2) == '__' && val.substring(val.length - 2) == '__'){
            let widget = this.getWidget(widgetId)
            console.log("widget", widget)
            if(!widget) return pageModel
            let expressionConfig = widget?.config?.expressionConfig
            if(!expressionConfig) return pageModel
            let expression = this.expressionManager.getExpressionClass(expressionConfig.id)
            if(!expression) return pageModel
            let result = expression.evaluateExpression(expressionConfig)
            console.log("result", result)
            pageModel[panelId][widgetId]['value'] = result
          }
        })
      })
      console.log("expressions in pageModel resolved", pageModel)
      return pageModel
    }

    resolveExpression(expressionConfig, existingValue?){
      let result = "";
      let expression = this.expressionManager.getExpressionClass(expressionConfig.id);
      let options = {
        actionMode: this.pageService.actionMode,
        existingValue: existingValue || ""
      }
      result = expression.evaluateExpression(expressionConfig, options);
      return result;
    }

    getWidget(id: string){
      let wid = null
      let pageMeta = this.metaService.pageMeta.value
      console.log("pageMeta", pageMeta)
      pageMeta?.panels.forEach(panel => {
        let widgets = this.widgetService.getWidgetsFromPanel(panel);
        widgets.forEach(widget => {
          if(widget.id == id) wid = widget
        });
      })
      return wid
    }

    getTimeZone(){
      console.log("theme service:", this.themeService)
      return this.themeService?.currentLocale?.timeZone
    }

}
