import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";

export class Icon extends Widget {
  type = 'icon';
  gridX = 1
  minGridX = 1
  config = {
    props: [
      'code',
      'conditionalConfig',
      'tooltip',
      'alignment'
    ],
    code: {
      displayName: 'Material Icon Name',
      value: 'emoji_emotions',
      type: 'google-icon-picker'
    },
    conditionalConfig: {
      enabled: false,
      displayRules: [] // {compareValue: "", operator: "=", result: "", tooltip: "", color: ""} 
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      resetValue: 'flex-start',
      type: 'app-alignment-settings'
    }
  }
  textFormat;

  constructor(id?: number, name?: string) {
    super(id, name)
    this.attachCommonConfig(this)

    // remove extra formatting other than color and fontSize
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
    for (let i = this.textFormat.props.length - 1; i >= 0; i--) {
      const prop = this.textFormat.props[i];
      // console.log("prop", prop)
      if(prop != 'fontSize' && prop != 'color'){
        this.textFormat.props.splice(i, 1)
        delete this.textFormat[prop]
      }
    }
    // console.log("text format", this.textFormat)
    // initialize color and fontSize
    this.textFormat.color.value = '#555'
    this.textFormat.fontSize.value = 24
  }

  public getWidgetConfig(){
    let map = {
      staticValue: true,
      valuePath: "config.code.value"
    }
    return map;
  }
}
