import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import axios from "axios";
import { environment } from 'src/environments/environment';
import { TokenUtil } from '../core/services/TokenUtil.service';
import { ConnectionService } from '../modules/organization/connection.service';
import { AuthServiceService } from '../shared/services/auth-service.service';
import uniqid from 'uniqid';
import { BoxCache } from '../core/boxInstances/BoxCache';
import { BehaviorSubject } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Injectable({
  providedIn: 'root'
})
export class FlowService {
  totalFlowCount: number = 0;
  isExecuting: boolean;
  constructor(
    private token: TokenUtil,
    private http: HttpClient,
    public connectionService: ConnectionService,
    public authService: AuthServiceService
  ) { }

  flowToolCreation = new BehaviorSubject<any>({})
  $flowToolDragCreation = this.flowToolCreation.asObservable()

  updatedFlowMeta = new BehaviorSubject<boolean>(false);
  $updatedFlowMeta = this.updatedFlowMeta.asObservable()

  userMadeChanges = new BehaviorSubject<boolean>(false);
  $userMadeChanges = this.userMadeChanges.asObservable()

  flowNameChange = new BehaviorSubject<boolean>(false);
  $flowNameChange = this.flowNameChange.asObservable()

  flowExecuted: boolean = false;
  BASE_URL: any = `${environment.DB_BOX_URL}/mongodb/flow`
  BOX_TOKEN: any = environment.DB_BOX_TOKEN;
  FLOW_BASE_URL:any = `${environment.SERVER_BASE_URL}/flow/`; //"https://us-central1-appiworks-dev-ci.cloudfunctions.net/workspace-server/api/flow/"//
  boxEvents: any []
  stepOptionMap: any = {};

  flowCreateConfiguration: any = {
    creationMode: 'all', // all, 'blank' or 'template'
    appsIncluded: [], // list of app names
    selectedTemplate: null, // name or ID of the selected template
    templateFilters: []
  };

  flowMap:any = {
    name: "New Flow",
    description: "",
    isActive: false,
    trigger: {}
  };

  draggedToolData: any = {}

  toolDirectCreate(data){
    this.draggedToolData = data
    this.flowToolCreation.next({eventType: "clickcreate"})
  }

  dragStarted(dragData: any){
    console.log("[flow service] dragStarted", dragData)
    this.draggedToolData = dragData
    this.flowToolCreation.next({eventType: "dragstart"})
  }

  dropTool(event: CdkDragDrop<string[]>) {
    console.log("flow tool drop event", event, "tool data", this.draggedToolData)
    this.flowToolCreation.next({eventType: "dragend"})

    setTimeout(() => {
      if(this.draggedToolData) this.resetDragToolData()
    }, 200);
  }

  resetDragToolData(){
    this.draggedToolData = {}
  }

  validateStep(step, i){
    if(!this.stepOptionMap[i]) this.stepOptionMap[i] = {};
    if(step.step_type == "action"){
      let isValid = true;
      if(!step.action || !step.connection || !step.box_id) isValid = false;
      this.stepOptionMap[i].valid = isValid;
    }  else {
      this.stepOptionMap[i].valid = true;
    }
  }

  async getNewFlowMap(map){
    let flowMap = JSON.parse(JSON.stringify(this.flowMap));
    flowMap.trigger_type = "schedule";
    if(!map?.workspaceId && this.connectionService.workSpaceId){
      flowMap.workspace_id = this.connectionService.workSpaceId
    }
    flowMap.created_by = map?.email || this.authService.profile?.email;
    flowMap.modified_by = map?.email || this.authService.profile?.email;
    flowMap.modified_at = new Date().toISOString() + "|date";
    flowMap.created_at = new Date().toISOString() + "|date";
    let result = await this.createFlow(flowMap);
    flowMap._id = result;
    return flowMap;
  }

  async saveFlow(flowMap, isScheduleJobs:boolean = true) {
    var result: any;
    try {
      console.log('isScheduleJobs',isScheduleJobs);
      if(flowMap.trigger_type == 'schedule' && isScheduleJobs == true) await this.scheduleJobs(flowMap);
      flowMap.created_at = flowMap.created_at + "|date";
      flowMap.modified_at = new Date().toISOString() + "|date";
      result = await this.updateFlow(flowMap);
      console.log("result", result)
    } catch (er) {
      throw er;
    }
    return flowMap;
  }

  async scheduleJobs(flowMap){
    let schedulers = flowMap.trigger.schedulers;
    var data = {
      flowId: flowMap._id
    }
    for(var i = 0; i < schedulers?.length; i++){
      var schedule = schedulers[i];
      var res = await this.scheduleJob(schedule, data);
      console.log("res-->", res)
      if(res && !schedule.job) {
        schedule.job = {};
        schedule.job._id = res._id;
      }
    }
    return flowMap;
  }

  async scheduleJob(schedule: any, data: any) {
    console.log("schedule job hit: schedule", schedule, "data", data)
    var payload = {
      scheduleMap: schedule,
      data: data
    }

    // console.log("payload", payload)
    console.log("payload", JSON.parse(JSON.stringify(payload)))

    var url = this.FLOW_BASE_URL + "schedule/set";
    console.log("url", url)
    var response:any = await this.http.post(url, payload, {}).toPromise();
    console.log("scheduleJob response", response)
    var result = response?.result || null;
    return result;
  }


  async setupTrigger(flowMap, event, publishedFlow){
    var conId = flowMap.trigger.connection;
    var boxId = flowMap.trigger.box_id;
    var targetUrl = this.FLOW_BASE_URL + "event/trigger/" + flowMap._id;
    let boxToken;

    // var result:any =


    // {
    //   target_url : targetUrl,
    //   box_options: {}
    // }
    var isWebhookExist = true;

    if(conId) boxToken = await this.connectionService.getBoxConfigToken(conId);

    var triggerMap = flowMap.trigger;
    var webhook = JSON.parse(JSON.stringify(triggerMap?.webhook)) || {};
    // if(webhook.box_options) result.box_options = webhook.box_options;

    let prevTargetUrl = publishedFlow?.trigger?.webhook?.targetUrl;


    if(!prevTargetUrl) isWebhookExist = false; //if it is first time
    //check for webhook
    if(isWebhookExist) isWebhookExist = await this.getAndCheckWebhook(boxId, boxToken, event, targetUrl);

    if(!isWebhookExist){
      var payload = {
        targetUrl: targetUrl
      }
      let createRes = await this.createWebhook(boxId, boxToken, event, payload, webhook);
      webhook.targetUrl = targetUrl;
      webhook.box_options = createRes;
    }
    console.log("webhook return ", webhook)
    return webhook;
  }

  async getAndCheckWebhook(boxId, boxToken, event, targetUrl){
    var functionMap = event.functions?.["getWebhook"] || null;
    if (!functionMap) return true;
    var boxUrl = `${environment.DB_BOX_URL}/${boxId}/event/${event.__id}/${functionMap.__id}`;

    var payload = {parameters: {}}

    try {
      let response:any = await this.execute(boxUrl, payload, "post", boxToken, true);
      console.log("[BOX-HTTP] get webhooks response:", response);
      let result = response?.result?.data || null;
      console.log("[BOX-HTTP] webhooks:", result);
      if(result.length == 0) return false;
      let targetUrlKey = functionMap?.options?.targetUrlKey || "targetUrl";


      let isWebhookExist = this.checkIfWebhookExists(result, targetUrl, targetUrlKey);
      return isWebhookExist;
    } catch(e){
      console.error("[BOX-HTTP] Error on get webhooks:", e)
      throw e;
    }
  }

  checkIfWebhookExists(availableWebhooks: any, targetUrl: any, targetUrlKey: any) {
    var isWebhookExists = false;
    for(var i = 0; i < availableWebhooks.length; i++){
      let hook = availableWebhooks[i][targetUrlKey];
      if(hook == targetUrl){
        isWebhookExists = true;
        break;
      }
    }
    return isWebhookExists;
  }

  async createWebhook(boxId, boxToken, event, body, webhook){
    var functionMap = event.functions?.["createWebhook"];
    var boxUrl = `${environment.DB_BOX_URL}/${boxId}/event/${event.__id}/${functionMap.__id}`;

    // let data = {};
    if(functionMap?.input?.data?.array){
      if(functionMap?.input?.data?.array.list && functionMap?.input?.data?.array.list.length > 0){

        for (let attribute of functionMap?.input?.data?.array.list) {
          // data.push(functionMap?.input?.options[attribute])
          if(attribute == "uniqueId") body['uniqueId'] = uniqid();
        }
      }
    }

    var payload:any = {
      parameters:{
        data: [body]
      }
    }

    if(webhook.optionMap && Object.keys(webhook.optionMap).length > 0){
      payload.parameters.options = webhook.optionMap
    }
    console.log("payload create webhook", payload)
    console.log("payload create webhook boxToken", boxToken)
    try {
      let response:any = await this.execute(boxUrl, payload, "post", boxToken, true);
      console.log("[BOX-HTTP] create webhook response:", response);
      let result = response?.result?.data[0] || null;
      if(response?.result?.derivationData) result.derivationData = response?.result?.derivationData;
      console.log("[BOX-HTTP] create webhook:", result);
      return result;
    } catch(e){
      console.error("[BOX-HTTP] Error on get webhooks:", e)
      throw e;
    }
  }

  async getEvents(boxId?: string) {
    var boxUrl = `${environment.DB_BOX_URL}/${boxId}/events`;
    console.log("[BOX-HTTP] get events called:", boxUrl);
    try {
      let response:any = await this.execute(boxUrl, null, "get", undefined, true);
      console.log("[BOX-HTTP] get response:", response);
      let events = response?.events || null;
      for(var i = 0; i < events.length; i++){
        let attributes = events[i]?.attributes?.fields || [];
        var functionMap = events[i]?.functions?.["createWebhook"];
        let fields = [];
        for (let attribute of attributes.list) {
          fields.push(attributes[attribute])
        }

        if(functionMap?.input?.options?.list && functionMap?.input?.options?.list.length > 0){
          let options = [];
          for (let attribute of functionMap?.input?.options?.list) {
            options.push(functionMap?.input?.options[attribute])
          }
          events[i].options = options;
        }
        events[i].fields = fields;
      }
      console.log("[BOX-HTTP] get events:", events);
      return events;
    } catch(e){
      console.error("[BOX-HTTP] Error on get events:", JSON.stringify(e))
      throw e;
    }
  }

  async getBox(boxId?: string) {
    var boxUrl = `${environment.DB_BOX_URL}/mongodb/box/get`;
    var payload = {
      parameters: {
        "query": {
          "filter": "__id=" + boxId + "|string",
          "page" : `1|1|1`
        }
      }
    }

    try {
      let response:any = await this.execute(boxUrl, payload);
      console.log("[BOX-HTTP] get response:", response);
      BoxCache.setBoxCache(response?.result?.data);
      return response?.result?.data[0] || null;
    } catch(e){
      console.error("[BOX-HTTP] Error on get box:", JSON.stringify(e))
      throw e;
    }
  }


  async getFlow(id) {
    let result: any = null;
    var boxUrl = `${this.BASE_URL}/get`;
    var payload = {
      parameters: {
        "query": {
          "filter": "_id=" + id + "|string",
          "page" : `1|100|1`
        }
      }
    }
    console.log("payload", payload)
    try {
      let response:any = await this.execute(boxUrl, payload);
      console.log("[BOX-HTTP] get response:", response);
      result = response?.result?.data[0];
    } catch(e){
      console.error("[BOX-HTTP] Error on get flow:", JSON.stringify(e))
      throw e;
    }
    return result

  }

  async getSummary(flowId:any){
    let wsUrl = `${environment.SERVER_BASE_URL}/flow/summary/${flowId}`;
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.connectionService.preAuthenticatedToken
    );
    let res: any = await this.http.get(wsUrl, { headers }).toPromise();
    return res?.result?.data || [];
  }


  async getInstanceFromCode(code: any) {
    var boxUrl = `${environment.DB_BOX_URL}/mongodb/flow_instance/get`;
    var payload = {
      parameters: {
        "query": {
          "filter": "code=" + code + "|string",
          "page" : `1|100|1`
        }
      }
    }
    try {
      let response:any = await this.execute(boxUrl, payload);
      console.log("[BOX-HTTP] get instance response:", response);
      return response?.result?.data?.[0] || null;
    } catch(e){
      console.error("[BOX-HTTP] Error on get flow instances:", JSON.stringify(e))
      throw e;
    }
  }


  async getInstance(id: any) {
    var boxUrl = `${environment.DB_BOX_URL}/mongodb/flow_instance/get`;
    var payload = {
      parameters: {
        "query": {
          "filter": "_id=" + id + "|string",
          "page" : `1|100|1`
        }
      }
    }
    try {
      let response:any = await this.execute(boxUrl, payload);
      console.log("[BOX-HTTP] get instance response:", response);
      return response?.result?.data
    } catch(e){
      console.error("[BOX-HTTP] Error on get flow instances:", JSON.stringify(e))
      throw e;
    }
  }

  async getInstances(flowId: any, options?:any) {
    var boxUrl = `${environment.DB_BOX_URL}/mongodb/flow_instance/get`;
    var payload = {
      parameters: {
        "query": {
          "filter": "flow=" + flowId + "|string",
          "sort" : "triggered_at=DESC",
          "page" : `1|100|1`
        }
      }
    }

    if(options?.filter)payload.parameters.query.filter = payload.parameters.query.filter + "," + options?.filter;

    console.log("payload", payload)

    try {
      let response:any = await this.execute(boxUrl, payload);
      console.log("[BOX-HTTP] get instance response:", response);
      return response?.result?.data
    } catch(e){
      console.error("[BOX-HTTP] Error on get flow instances:", JSON.stringify(e))
      throw e;
    }
  }

  async getAllFlows(pageNumber: any = 1, pageSize: any = 5, onlyDraft: boolean = false) {
    var boxUrl = `${this.BASE_URL}/get`;

    let workSpaceId = this.connectionService.workSpaceId = this.connectionService.selectedWorkSpace;
    var payload = {
      parameters: {
        "query": {
          "filter": "workspace_id=" + workSpaceId + "|string",
          "sort": "modified_at=DESC",
          "page": `${pageNumber}|${pageSize}|1`
        }
      }
    }
    if(onlyDraft){
      payload.parameters.query.filter += `,is_published!=true|boolean`
    }
    console.log("payload", payload)
    try {
      let response: any = await this.execute(boxUrl, payload);
      console.log("[BOX-HTTP] get response:", response);

      const flows = response?.result?.data;
      this.totalFlowCount = response?.result?.totalCount || 0; // Store the total count in the service variable

      return flows;
    } catch (e) {
      console.error("[BOX-HTTP] Error on get all flow:", JSON.stringify(e));
      throw e;
    }
  }

  async deleteFlow(flow: any) {

    // TODO: also delete the published version?

    var result: any = {};

    var boxUrl = `${this.BASE_URL}/deleteById`
    let payload = {
      parameters: {
        id: flow._id,
      },
    }

    try {
      let response:any = await this.execute(boxUrl, payload);
      console.log("[BOX-HTTP] delete response:", response);
      result.status = "DELETED";
      return result;
    } catch(e){
      console.error("[BOX-HTTP] Error on delete flow:", e)
      throw e;
    }
  }

  async createFlow(body?: any){

    if(!body.workspace_id && this.connectionService.workSpaceId){
      body.workspace_id = this.connectionService.workSpaceId
    }
    body.created_by = body.created_by || this.authService.profile?.email;
    body.modified_by = body.modified_by || this.authService.profile?.email;
    var boxUrl = `${this.BASE_URL}/create`
    let payload = {
      parameters: {
        data: [body]
      }
    }
    try {
      let response:any = await this.execute(boxUrl, payload);
      console.log("[BOX-HTTP] Create from response:", response);
      // return response?.result?.ids[0];
      return response?.result?.data?.[0]?.data?._id
    } catch(e){
      console.error("[BOX-HTTP] Error on create flow:", e)
      throw e;
    }
  }

  async updateFlow(body?: any){

    var boxUrl = `${this.BASE_URL}/update`;
    body.modified_by = this.authService.profile?.email;
    let payload = {
      parameters: {
        data: [body]
      }
    }
    try {
      console.log("update flow hit", JSON.parse(JSON.stringify(payload)))
      let response:any = await this.execute(boxUrl, payload);
      console.log("[BOX-HTTP] update from response:", response);
      return response.data
    } catch(e){
      console.error("[BOX-HTTP] Error on update flow:", e)
      throw e;
    }
  }

  async executeFlow(endpoint:any, payload: any, method:any = 'get', queryString?){
    let response: any;
    let url = `${environment.SERVER_BASE_URL}/flow/webhook/${endpoint}`;
    if(queryString) url = url + '?' + queryString;
    let options = {};
    console.log("Flow endpoint and payload", url, payload)
    try {
      if(method == "get"){
        response = await this.http.get(url, options).toPromise();
      } else {
        response = await this.http.post(url, payload, options).toPromise();
      }
      console.log("[BOX-HTTP] flow execution response: ", response);
      return response
    } catch(e){
        console.log("[BOX-HTTP] flow execution: ", e)
        throw e;
    }
  }


  async execute(url: string, payload?: any, method?: string, boxToken?: any, isFlow?: boolean){
    var options:any = {
      headers: {
        Authorization: `Bearer ${await this.token.getStatelessToken()}`,
        "Cache-Control": 'no-cache'
      }
    }

    if(isFlow && boxToken) options.headers.boxconfigToken = boxToken;
    else if(!isFlow) options.headers.boxconfigToken = this.BOX_TOKEN

    let clonedPayload = JSON.parse(JSON.stringify(payload))

    console.log("url", url)
    console.log("clonedPayload options", JSON.parse(JSON.stringify(options)), JSON.parse(JSON.stringify(clonedPayload)))
    // console.log("options", JSON.stringify(options), JSON.parse(JSON.stringify(payload)))

    let response: any;
    try {
      if(method == "get"){
        response = await this.http.get(url, options).toPromise();
      } else {
        response = await this.http.post(url, clonedPayload, options).toPromise();
      }
      console.log("[BOX-HTTP] flow execution response: ", response);
      return response
    } catch(e){
        console.log("[BOX-HTTP] flow execution: ", e)
        throw e;
    }
  }

}
