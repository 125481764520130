import { BaseRouterURL } from "./BaseRouterURL"
import { RouterUtility } from "./RouterUtility";

export class BoxURL extends BaseRouterURL {

    public getBaseUrls(env: any, box:any, options?: any) {
      console.log("[box", box)
        let config = RouterUtility.getConfig(env);

        let filteredUrls = [];
        for (const key in config) {
            if (key !== "list" && config.hasOwnProperty(key)) {
                const obj = config[key];
                if (Array.isArray(obj.boxes) && obj.boxes.includes(box)) {
                    filteredUrls.push(obj.url);
                }
            }
        }
        // console.log(`[GET-INSTANCE] [GETBASEURLS] for ${env} ${categoryType} and filteredUrls: ${filteredUrls}`);
        // if(filteredUrls.length == 0){
        //     filteredUrls = RouterUtility.getBaseUrls(env, null)
        //     // console.log("[GET-INSTANCE] [GETBASEURLS] fallback")
        //     // console.log(`[GET-INSTANCE] [GETBASEURLS] fallback filteredUrls: ${filteredUrls}`);
        // }
        return filteredUrls;
    }
}
