<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>
<ng-container *ngIf="data.content">
  <ng-container *ngIf="data.content?.isRadio">
    <div>{{data.content.radio.title}}</div>
    <mat-radio-group fxLayoutGap="10px" aria-label="Select an option" color="primary" [(ngModel)]="data.content.radio.selected">
      <mat-radio-button *ngFor="let obj of data.content.radio.values" [value]="obj.value">{{obj.name}}</mat-radio-button>
    </mat-radio-group>  
  </ng-container>
  <ng-container *ngIf="data.content.radio.messages">
    <p style="margin-top: 8px;">{{data.content.radio.messages[data.content.radio.selected]}}</p>
  </ng-container>
</ng-container>

<div mat-dialog-actions *ngIf="data.actionType == 'delete'">
  <button mat-raised-button color="primary" cdkFocusInitial (click)="onCancelClick()">Cancel</button>
  <button mat-raised-button color="warn" [mat-dialog-close]="data">Delete</button>
</div>
<div mat-dialog-actions *ngIf="data.actionType == 'prompt'">
  <button mat-raised-button color="primary" cdkFocusInitial [mat-dialog-close]="data">Yes</button>
  <button mat-raised-button color="warn" (click)="onCancelClick()">No</button>
</div>

