import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
// import { BuilderToolbarComponent } from './builder-toolbar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { DeleteDialogComponent } from './delete-dialog.component';
import { FormsModule } from '@angular/forms';
import { FlowBuilderToolsComponent } from './flow-builder-tools.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConnectionPanelListModule } from 'src/app/connection-panel/connection-panel-list/connection-panel-list.module';
import { StarchBaseModule } from 'src/app/starch/starch-base/starch-base.module';

@NgModule({
  declarations: [FlowBuilderToolsComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatToolbarModule,
    FormsModule,
    SharedModule,
    ConnectionPanelListModule,
    StarchBaseModule,
  ],
  exports: [FlowBuilderToolsComponent]
})
export class FlowBuilderToolsModule { }
