import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout'
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import { MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import { SecurityConfigModule } from '../../security/config/security-config/security-config.module';
import { FilterComponentComponent } from './filter-component.component';
import { FieldPickerModule } from '../../field-picker/field-picker.module';
import { FilterConfigModule } from '../filter-config/filter-config.module';

@NgModule({
    declarations: [
        
    ],
    exports: [
        FilterComponentComponent
    ],
    imports: [
        FilterComponentComponent,
        CommonModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatIconModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatInputModule,
        MatButtonModule,
        MatTooltipModule,
        MatSelectModule,
        MatMenuModule,
        MatCardModule,
        SecurityConfigModule,
        FieldPickerModule,
        FormsModule,
        FilterConfigModule
    ]
})
export class FilterComponentModule { }
