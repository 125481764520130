<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<div>

    <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <div fxLayout="row" >
               <h1> {{dataInput.type == 'create' ? 'Create new workspace' : 'Edit workspace'}} </h1>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>


    <div style="min-height: 100px;background-color: transparent;" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <div fxLayout="row" fxLayoutAlign="center center" >
                <div fxLayout="column" fxFlex="40">
                    Name
                </div>
                <div fxLayout="column" class="workspace-field" fxFlex="60">
                    <mat-form-field appearance="outline" style="width: 80%;"> 
                        <input maxlength="20" matInput placeholder="Name" [(ngModel)]="workspaceMap.name">
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" >
                <div fxLayout="column" fxFlex="40">
                    Description
                </div>
                <div fxLayout="column" class="workspace-field" fxFlex="60">
                    <mat-form-field appearance="outline" style="width: 80%;"> 
                        <textarea maxlength="60" matInput placeholder="Description" [(ngModel)]="workspaceMap.description"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>


    <br/>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="95">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div fxLayout="column" fxFlex="35">
                    <!-- <button mat-flat-button color="warn" type="button" style="margin-right: 1rem;">
                        Delete Workspace
                    </button> -->
                </div>
                
                <div fxLayout="column" fxFlex="30">
                    <button mat-stroked-button  [mat-dialog-close]="false" color="primary"  type="button" style="margin-right: 1rem;">
                        Cancel
                      </button>
                </div>
                <div fxLayout="column" fxFlex="30">
                    <button mat-flat-button (click)="createOrUpdateWorkspace(dataInput.type)"  color="primary" type="button">
                        {{dataInput.type == 'create' ? 'Create' : 'Update'}} 
                    </button>
                </div>
                
           </div>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>
    


</div>