import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputComponent } from './mat-input.component';
import {MatInputModule} from '@angular/material/input';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [MatInputComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule
  ],
  exports: [MatInputComponent]
})
export class InputModule { }
