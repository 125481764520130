import { Subject } from "rxjs";
import { Widget } from "./baseWidget";

export class ImageInput extends Widget {
  type = 'imageinput';
  gridX = 6
  minGridX = 3
  config = {
    props: [
      "placeholder",
      "src",
      'tooltip',
      'multi'
    ],
    placeholder: {
      displayName: "Image Input Placeholder",
      value: "Choose image",
      type: 'text',
      resetValue: '',
    },
    src: {
      displayName: "Image Source URL",
      value: "",
      type: 'text',
      resetValue: '',
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    multi: {
      displayName: "Support Multiple",
      value: false,
      type: 'boolean',
      resetValue: false
    }
  }

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
  }

  static imageInputEvent = new Subject<any>();
  static imageInputEvent$ = ImageInput.imageInputEvent.asObservable();

  public getEventSubscribe(data?:any){
    if(!data) return 1
    ImageInput.imageInputEvent.next(data);
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.src.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map)
    return map;
  }
}
