import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { BaseExpression } from "./BaseExpression";
import { formatDate } from '@angular/common';
import { ThemeService } from "../../services/theme.service";
import { ExpressionUtility } from "../expressionUtility";

@Injectable({
  providedIn: 'root',
})
export class CurrentDateTimeExpression extends BaseExpression {

  config: any = {}

  constructor(
    private themeService?: ThemeService,
    private expressionUtility?: ExpressionUtility,
    @Inject(LOCALE_ID) private locale?: string,
  ){
    super()
    this.config.id = '__currentdatetime__';
    this.config.name = 'Current Date and Time';
    this.config.description = 'Returns current date and time in selected format'
    this.config.parameters = {
      paramIds: ['datetimeFormat'],
      datetimeFormat: {
        id: 'datetimeFormat',
        name: 'Date/Time format',
        minCount: 1,
        maxCount: 1,
        required: true,
        dataType: 'string',
        widgetType: 'select',
        value: 'MMM d, y, h:mm:ss a',
        defaultValue: 'MMM d, y, h:mm:ss a',
        dynamicAllowed: false,
        dynamic: false,
        dynamicSource: '',
        availableOptions: [
          {
            name: 'ISO date format',
            value: 'YYYY-MM-DDTHH:mm:ss.sssZ',
            example: '2011-10-05T14:48:00.000Z'
          },
          {
            name: 'M/d/yy, h:mm a',
            value: 'M/d/yy, h:mm a',
            example: '6/15/15, 9:03 AM'
          },
          {
            name: 'MMM d, y, h:mm:ss a',
            value: 'MMM d, y, h:mm:ss a',
            example: 'Jun 15, 2015, 9:03:01 AM'
          },
          {
            name: 'MMMM d, y, h:mm:ss a z',
            value: 'MMMM d, y, h:mm:ss a z',
            example: 'June 15, 2015 at 9:03:01 AM GMT+1'
          },
          {
            name: 'EEEE, MMMM d, y, h:mm:ss a zzzz',
            value: 'EEEE, MMMM d, y, h:mm:ss a zzzz',
            example: 'Monday, June 15, 2015 at 9:03:01 AM GMT+01:00'
          },
          {
            name: 'M/d/yy',
            value: 'M/d/yy',
            example: '6/15/15'
          },
          {
            name: 'MMM d, y',
            value: 'MMM d, y',
            example: 'Jun 15, 2015'
          },
          {
            name: 'MMMM d, y',
            value: 'MMMM d, y',
            example: 'June 15, 2015'
          },
          {
            name: 'EEEE, MMMM d, y',
            value: 'EEEE, MMMM d, y',
            example: 'Monday, June 15, 2015'
          },
          {
            name: 'h:mm a',
            value: 'h:mm a',
            example: '9:03 AM'
          },
          {
            name: 'h:mm:ss a',
            value: 'h:mm:ss a',
            example: '9:03:01 AM'
          },
          {
            name: 'h:mm:ss a z',
            value: 'h:mm:ss a z',
            example: '9:03:01 AM GMT+1'
          },
          {
            name: 'h:mm:ss a zzzz',
            value: 'h:mm:ss a zzzz',
            example: '9:03:01 AM GMT+01:00'
          },
        ]
      }
    }
  }

  evaluateExpression(expConfig?: any, options?:any){
    console.log("[CurrentDateTimeExpression] config", expConfig)
    console.log("[CurrentDateTimeExpression] options", options)
    let id = expConfig.id
    let len = expConfig.parameters.datetimeFormat.length

    if(options && (options?.actionMode == 'create' && !expConfig?.onCreate) || (options?.actionMode == 'update' && !expConfig?.onEdit))
        return  options?.existingValue;

    if(id !== this.config.id || len == 0) {
      return null
    }
    console.log("theme service", this.themeService)

    //TODO : injected services coming undefined; need to use theme service for user's timezone


    // let timeZone: string = this.expressionUtility.getTimeZone()
    // let gmtOffset = timeZone?.substring(1, 10)

    // console.log("timezone:", timeZone, "gmtoffset", gmtOffset)

    let formattedDate
    if(expConfig.parameters.datetimeFormat[0].value == 'YYYY-MM-DDTHH:mm:ss.sssZ'){
      formattedDate = new Date().toISOString()
      console.log("iso format", formattedDate)
    } else if (!expConfig.parameters?.datetimeFormat[0]?.value) {
      formattedDate = new Date();
    } else{
      formattedDate = formatDate(new Date(), expConfig.parameters.datetimeFormat[0].value, 'en-US')
      console.log("non iso format", formattedDate)
    }
    console.log("formattedDate", formattedDate)
    return formattedDate
  }

  public getExpressionDetail() {
    return this.config
  }
}
