<div>
  <div *ngIf="!selfMap" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1.5rem" fxFlexFill>
    <mat-form-field class="fullWidth">
      <mat-label>source attribute</mat-label>
      <input
        class="fullWidth"
        #sourceAttrInput
        type="text"
        aria-label="source attribute autocomplete"
        matInput
        [formControl]="sourceAttrControl"
        [matAutocomplete]="sourceAttrAC"
        (input)="isSelectedSource = false"
      >
        <button *ngIf="sourceAttrInput.value !== ''" matSuffix mat-icon-button aria-label="Clear" (click)="sourceAttrControl.patchValue('')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete autoActiveFirstOption #sourceAttrAC="matAutocomplete" [displayWith]="displayFnAttribute" (optionSelected)="sourceAttributeSelected($event);">
          <mat-option *ngFor="let attr of filteredSourceAttrs | async" [value]="attr">
            <span [matTooltip]="attr.dataType">{{ attr.name }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="fullWidth">
        <mat-label>destination attribute</mat-label>
      <input
        class="fullWidth"
        #destAttrInput
        type="text"
        aria-label="destination attribute autocomplete"
        matInput
        [formControl]="destAttrControl"
        [matAutocomplete]="destAttrAC"
        (input)="isSelectedDest = false"
      >
      <button *ngIf="destAttrInput.value !== ''" matSuffix mat-icon-button aria-label="Clear" (click)="destAttrControl.patchValue('')">
        <mat-icon>close</mat-icon>
      </button>
      <mat-autocomplete autoActiveFirstOption #destAttrAC="matAutocomplete" [displayWith]="displayFnAttribute" (optionSelected)="destinationAttributeSelected($event);">
        <mat-option *ngFor="let attr of filteredDestAttrs | async" [value]="attr">
          <span [matTooltip]="attr.dataType">{{ attr.name }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <button mat-raised-button (click)="mapAttrAdd()" fxLayout fxLayoutAlign="space-around center">
      <mat-icon style="margin: auto;">done</mat-icon>
      <span style="margin: auto;">Save</span>
    </button>
    <button mat-raised-button (click)="cancel()" fxLayout fxLayoutAlign="space-around center">
      <!-- <mat-icon style="margin: auto;">done</mat-icon> -->
      <span style="margin: auto;">cancel</span>
    </button>
  </div>

  <div *ngIf="selfMap" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1.5rem" fxFlexFill>
    <mat-form-field class="fullWidth">
      <mat-label>Link attribute</mat-label>
      <input
        class="fullWidth"
        #sourceAttrInput
        type="text"
        aria-label="link attribute autocomplete"
        matInput
        [formControl]="sourceAttrControl"
        [matAutocomplete]="sourceAttrAC"
        (input)="isSelectedSource = false"
      >
        <button *ngIf="sourceAttrInput.value !== ''" matSuffix mat-icon-button aria-label="Clear" (click)="sourceAttrControl.patchValue('')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete autoActiveFirstOption #sourceAttrAC="matAutocomplete" [displayWith]="displayFnAttribute" (optionSelected)="sourceAttributeSelected($event);">
          <mat-option *ngFor="let attr of filteredSourceAttrs | async" [value]="attr">
            <span>{{ attr.name }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <button mat-raised-button (click)="mapAttrAdd()" fxLayout fxLayoutAlign="space-around center">
        <mat-icon style="margin: auto;">done</mat-icon>
        <span style="margin: auto;">Save</span>
      </button>
      <button mat-raised-button (click)="cancel()" fxLayout fxLayoutAlign="space-around center">
        <!-- <mat-icon style="margin: auto;">done</mat-icon> -->
        <span style="margin: auto;">cancel</span>
      </button>
  </div>
</div>
