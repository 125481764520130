import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  HostListener,
  Inject,
  Injectable,
  NgZone,
  PLATFORM_ID,
} from '@angular/core';
import { HttpCacheService } from 'src/app/core/services/HttpCacheService';


@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {
  isBrowser: any;

  constructor(
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    private httpCs: HttpCacheService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if(!this.isBrowser) return;
    this.setWindowResize();
  }

  public screenWidth: any;
  public screenHeight: any;
  public isSmallSize: boolean = false;

  setWindowResize() {
    this.screenWidth = window.innerWidth;
    if(this.screenWidth <= 599) this.isSmallSize = true;
    this.screenHeight = window.innerHeight;
  }
}
