import { BoxURL } from "./BoxURL";
import { CategoryURL } from "./CategoryURL";
import { DefaultURL } from "./DefaultURL";


export class URLRegistry {
    static registry: any = {
        "category": function () {
            return new CategoryURL();
        },
        "box": function () {
          return new BoxURL();
        },
    }
    static getRouter(type: string) {
        let router = this.registry[type];
        if (!router) {
            router = function () {
                return new CategoryURL();
            }
        }
        return router();
    }
}
