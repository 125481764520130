import { Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PollingService {
  private pollingSubscription: Subscription | null = null;

  constructor() {}

  startPolling(pollingFunction: () => void): void {
    // Check if a polling is already running, if yes, stop it first
    if (this.pollingSubscription) {
      this.stopPolling();
    }

    // Start polling immediately and then every 5 seconds
    pollingFunction();
    this.pollingSubscription = interval(5000)
      .pipe(
        switchMap(async () => pollingFunction())
      )
      .subscribe();
  }

  stopPolling(): void {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = null;
    }
  }
}
