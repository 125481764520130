import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges, Inject, PLATFORM_ID } from '@angular/core';
import { ChartService } from '../../services/chart.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

declare var google: any;

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit, OnChanges {

  // @Input() dataSource: any;
  @Input() records: any[];
  @Input() widgetMeta: any;
  @Input() chartDimensions: any
  @Input() builderMode: any

  spinner: boolean = false
  colorsArray: string[] = []
  chartData: any

  _skimmedData: any = []
  isBrowser: boolean;
  get skimmedData(){
    return this._skimmedData
  }
  set skimmedData(data){
    // console.log("setting skimmed data for", this.widgetMeta.id)
    console.log("setting skimmed data for", this.widgetMeta.id, "data", JSON.parse(JSON.stringify(data)))
    this._skimmedData = data
  }
  dragSubscription: any

  constructor(
    private chartService: ChartService,
    private widgetService: WidgetService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    }

  ngOnInit(): void {
    if(!this.isBrowser) return;
    console.log("PIE CHART COMPONENT", this.widgetMeta, this.records)
    google.charts.load('current', { packages: ['corechart'] });
    // google.charts.setOnLoadCallback(this.buildChart)

    this.chartService.builderMode = this.builderMode
    this.colorsArray = this.chartService.colorPalette
    this.buildChart(true);

    this.dragSubscription = this.widgetService.$widgetDragStarted.subscribe(data => {
      if(data.id == this.widgetMeta.id){
        console.log("widget move start alert; will set data cache for", this.widgetMeta.id, "data", this.skimmedData)
        this.chartService.setChartDataCache(this.widgetMeta.id, this.skimmedData)
      }
    })
  }

  ngOnDestroy(): void {
    this.dragSubscription.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes.dataSource?.currentValue && !changes.dataSource?.firstChange){
    //   console.log("dataSource changed", changes.dataSource.currentValue)
    //   this.buildChart()
    // }
    if(changes.chartDimensions?.currentValue && !changes.chartDimensions?.firstChange){
      console.log("DIMENSIONS RECEIVED", changes.chartDimensions?.currentValue)
      this.buildChart()
    }
    if(changes.widgetMeta?.currentValue && !changes.widgetMeta?.firstChange){
      console.log("widget meta change", changes.widgetMeta)
      if(changes.widgetMeta?.currentValue.id == changes.widgetMeta?.previousValue.id){
        console.log("will handle meta change")
        this.buildChart()
      }
    }
    if(changes.records?.currentValue && !changes.records?.firstChange){
      console.log("records change", changes.records.currentValue)
      this.buildChart(true)
    }
  }

  ngDoCheck(){}

  async buildChart(rebuild?: boolean) {
    console.log("build chart hit", this.widgetMeta.id, "records", this.records)
    this.spinner = true
    var func = async (chart: any) => {
      this.chartData = new google.visualization.DataTable();
      let config = this.widgetMeta?.config?.dataSource
      this.chartData.addColumn('string', config?.dimensions?.[0]?.attribute?.name || "Item");
      this.chartData.addColumn('number', config?.metric?.attribute?.name || "Amount");

      this.chartService.setPrecision(this.widgetMeta.config.precision?.value)

      if(rebuild){
        if (this.records?.[0]?.["__USECACHED__"]) {
          this.skimmedData = this.chartService.getChartDataCache(this.widgetMeta.id)
          console.log("cached data retrieved", JSON.parse(JSON.stringify(this.skimmedData)))
          this.chartService.clearChartCacheForWidget(this.widgetMeta.id)
          return
        } else {
          console.log("records", this.records)
          let pageData: any
          let isGrouped: boolean = this.records[0]?.__GROUPED__ || false
          if(this.records[0]?.__START__){
            this.clearCache()
            pageData = this.records.shift()
          }
          let rawRecords: any[] = []
          this.records.forEach(item => {
            if(item !== '__END__') rawRecords.push(item)
          })
          let res = this.chartService.processPieData(config, rawRecords, isGrouped)
          this.skimmedData = this.chartService.merge(this.skimmedData, res, config, pageData?.pageNumber || 1, pageData?.pageSize || res.length, rawRecords.length)
        }
      }
      console.log("skimmed data for chart", JSON.parse(JSON.stringify(this.skimmedData)))
      this.chartData.addRows(this.skimmedData)

      var options = {
        title: this.widgetMeta.config.title?.value || `${this.widgetMeta.config.dataSource.dimensions?.[0]?.attribute?.name}`,
        titleTextStyle: {
          color: this.widgetMeta.textFormat?.color?.value || '#444',    // any HTML string color ('red', '#cc00cc')
          fontName: this.widgetMeta.textFormat?.fontFamily?.value || 'Arial', // i.e. 'Times New Roman'
          fontSize: this.widgetMeta.textFormat?.fontSize?.value || 18, // 12, 18 whatever you want (don't specify px)
          bold: this.widgetMeta.textFormat?.bold?.value || false,    // true or false
          italic: this.widgetMeta.textFormat?.italic?.value || false  // true of false
        },
        is3D: true,
        colors: this.colorsArray,
        backgroundColor: this.widgetMeta.config.backgroundColor?.value || '#FFFFFF',
        width: this.chartDimensions.width || 150,
        height: this.chartDimensions.height || 150,
        chartArea: { 'bottom': '15%', 'top': '15%', 'width': '100%', 'height': '100%'},
        legend: {'position': 'bottom'}
      };
      console.log("chart options", options)
      chart().draw(this.chartData, options);
    }

    var chart = () => new google.visualization.PieChart(this.document.getElementById(this.widgetMeta.id));
    var callback = () => func(chart);

    google.charts.setOnLoadCallback(callback);
  }
  clearCache(){
    this.skimmedData = []
  }
  isDelimitor(){
    console.log("last item", this.records[this.records.length - 1])
    if(this.records[this.records.length - 1] == '__END__') return true
    else return false
  }

}
