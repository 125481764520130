import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { CdkDropList, DropListRef, moveItemInArray } from '@angular/cdk/drag-drop';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { MetaService } from '../services/meta-service';
import { BoxService } from '../services/box-service.service';
import { PageService } from '../services/page-service.service';
import { WidgetService } from '../services/widget-service.service';

import { SearchPanel } from '../models/panelClasses/searchPanel';
import { ListPanel } from '../models/panelClasses/listPanel';
import { DetailsPanel } from '../models/panelClasses/detailsPanel';

import { SearchPanelDialogComponent } from '../specialized-panels/search-panel/search-panel-dialog/search-panel-dialog.component';
import { ListPanelDialogComponent } from '../specialized-panels/list-panel/list-panel-dialog/list-panel-dialog.component';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { FormPanel } from '../models/panelClasses/formPanel';
import { FormPanelDialogComponent } from '../specialized-panels/form-panel/form-panel-dialog/form-panel-dialog.component';
import { DetailsPanelDialogComponent } from '../specialized-panels/details-panel/details-panel-dialog/details-panel-dialog.component';
import { Panel } from '../models/panelClasses/basePanel';
import { StarchBasePanelPopupComponent } from 'src/app/starch/starch-base-panel-popup/starch-base-panel-popup.component';
import { FrontendEventService } from 'src/app/shared/services/FrontendEvent.service';
import { PLATFORM_ID } from '@angular/core';
import { Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { SkeletonElement } from 'src/app/shared/spinner/skeleton/skeleton.component';
import { UrlParamsService } from 'src/app/shared/services/URLParamService';
import { debounceTime } from 'rxjs';

const PERIODIC_SAVE_TIMER_INTERVAL = 15000     //miliseconds, duration after which, meta will be saved periodically

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css'],
})
export class PageComponent implements OnInit, OnDestroy, OnChanges {
  // @Input() bloomData;

  @Input() footerConfig: any
  @Input() _builderMode: boolean = false
  @Output() messageEvent = new EventEmitter<string>();

  currentBloomId;
  currentPageMeta;
  currentPageCode;
  parsedPageCode: any = '';
  pageMetaString;
  allPagesData;
  pageNames: string[] = [];
  page_structure: any = '';
  selectedWidgetId: any = '';
  hoveredPanelId: any = '';
  activePanel: any = null;
  hoverActivePanel = -1;
  builderMode = false;

  widgetCreationLock: boolean = false;

  ready = undefined;
  timerId: any = '';

  widgetSubscription: any;
  getPageMetaSubscription: any;
  navigationDataSubscription: any;
  routeParameterSubscription: any;
  pageStructureSubscription: any;
  fragmentSubscription: any
  savePageRequest: any;
  widgetDragSubscription: any;
  toolPasteRequest:any;
  toolCutRequest: any
  createCopiedContentRequest: any
  starchDragSubscription: any;
  userChangesSubscription: any
  actionPanelRequestSub:any
  isBrowser: any;
  loginWidgetDeleted: boolean;

  panelHeightBeforeDrag: any = '';
  panelWidthBeforeDrag: any = '';
  // lastSaveTime: number = 0; // timestamp to keep when meta was last saved

  // @ViewChild('myDropList') dropList: CdkDropList;
  @ViewChildren(CdkDropList) dropLists: QueryList<CdkDropList>;
  @ViewChild('panelWrapRef') panelWrapElement!: ElementRef;
  spinner: boolean = false

  panelWidth: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private widgetService: WidgetService,
    private dialog: MatDialog,
    private metaService: MetaService,
    public pageService: PageService,
    private boxService: BoxService,
    public spinnerService: SpinnerService,
    public FEEventService: FrontendEventService,
    @Inject(PLATFORM_ID) platformId: Object,
    private title: Title,
    private meta: Meta,
    private urlService: UrlParamsService,
    private cdr: ChangeDetectorRef
    // private dropListRef: DropListRef
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if(!this.isBrowser) return;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.footerConfig?.currentValue){
      console.log("ngOnChanges, footerConfig", changes.footerConfig?.currentValue)
      this.handleFooterPage()
    }
  }

  ngOnInit(): void {
    console.log("Page component OnInit", this.footerConfig?.isFooterPage)
    this.spinner = true
    this.ready = false

    this.handleFooterPage()

    this.pageService.dataModel = {}

    this.loginWidgetDeleted = false;
    this.savePageRequest = this.pageService.$savePageRequest.subscribe(flag => {
      if(this.footerConfig?.isFooterPage) return
      if (flag) {
        console.log("savePageRequest from oninit, calling savePage")
        this.savePage()
      }
    })

    //----------------------------SUBSCRIBE TO PAGE META----------------------------------
    this.getPageMetaSubscription = this.metaService.pageMeta.subscribe((meta) => {
      
      if (JSON.stringify(this.currentPageMeta) == JSON.stringify(meta)) return

      if(this.metaService.bloomMeta && this.isBrowser && !this.builderMode) {
        this.metaSEOInit(this.metaService.bloomMeta);
      }
      if(!meta) return
      if (this.footerConfig?.isFooterPage) return
      
      // if a currentPageCode value exists in pageService bt is different from this page's code,
      // then this page is not in focus
      if (this.currentPageMeta && this.pageService.currentPageCode.value && this.pageService.currentPageCode.value !== this.currentPageMeta?.code) {
        console.log("not in focus, returning from", this.currentPageMeta?.code)
        return
      }
      this.spinner = true
      // console.log('[PAGE-INIT] onInit, pageMeta subscription', meta);


      if (meta?.code == this.currentPageCode) {
        // console.log('page meta received in page', meta.code, this.currentPageCode);

        meta = this.pageService.dataCorrectPageMeta(meta)
        //call page init function to generate data/page models
        this.pageInit(meta);

        //initialze the periodic save
        // if (this.builderMode) {
        //   console.log("calling periodic save init from pageMeta subscription")
        //   // this.periodicSaveInit()
        //   this.startPeriodicSaveTimer()
        // }
      }
      this.spinner = false
    });

    //---------------SUBSCRIBE TO URL FRAGMENT: CHECK IF #EDIT EXISTS--------------------
    this.fragmentSubscription = this.route.fragment.subscribe((fragment: string) => {
      if(this.footerConfig?.isFooterPage) return
      this.builderMode = (fragment == 'edit') ? true : false
    });

    //------------SUBSCRIBE TO WIDGET CREATION NOTIFICATION---------------
    this.widgetSubscription = this.widgetService.widgetCreationRequest.subscribe(async (widget) => {
      console.log("reached page", this.currentPageMeta?.code, "widget to create", widget)
      // if a currentPageCode value exists in pageService bt is different from this page's code,
      // then this page is not in focus
      if (this.pageService.currentPageCode.value && this.pageService.currentPageCode.value !== this.currentPageMeta?.code) {
        console.log("not in focus, returning from", this.currentPageMeta?.code)
        return
      }
      if (!this.widgetCreationLock) {
        this.widgetCreationLock = true
        console.log('widget service working:', widget, "for page", this.currentPageMeta._id);
        if (this.builderMode) {
          if (widget == 'searchPanel') {
            this.createSearchPanel();
          } else if (widget == 'listPanel') {
            this.createListPanel();
          } else if (widget == 'formPanel') {
            this.createFormPanel();
          } else if (widget == 'detailsPanel') {
            this.createDetailsPanel();
          } else {
            let updatedPageMeta: any
            // if(this.pageService.dragNewPanelPlaceholder){
            //   console.log("in page", this.pageService.dragNewPanelPlaceholder.newIndex)
            //   updatedPageMeta = await this.pageService.createPanel(widget, this.currentPageMeta, this.activePanel, null, null, null, null, this.pageService.dragNewPanelPlaceholder.newIndex);
            // } else {
            // }
            updatedPageMeta = await this.pageService.createPanel(widget, this.currentPageMeta, this.activePanel);
            // this.metaService.update(this.currentPageMeta)
            console.log("widget added to page", updatedPageMeta)
            //push into observables
            this.keepMetaInSync(updatedPageMeta)
            this.metaService.userMadeChanges.next(true);

            this.widgetCreationLock = false
          }
        }
      } else {
        console.log("DUPLICATE WIDGET CREATION DETECTED, TERMINATED!")
      }
    });

    this.widgetDragSubscription = this.widgetService.widgetDragCreationRequest.subscribe(async (widget) => {
      console.log("widget drag subscription")
      // let hoveredLayout = this.pageService.hoveredLayout;
      // let hoveredRow = this.pageService.hoveredRow;
      if (this.pageService.currentPageCode.value && this.pageService.currentPageCode.value !== this.currentPageMeta?.code) {
        console.log("not in focus, returning from", this.currentPageMeta?.code)
        return
      }
      if (!this.widgetCreationLock && this.pageService.isPointerInCanvas) {
        this.widgetCreationLock = true;
        let currentPanel = this.hoveredPanelId != -1 ? this.hoveredPanelId : null;
        if (this.builderMode) {
          if (widget == 'searchPanel') {
            this.createSearchPanel();
          } else if (widget == 'listPanel') {
            this.createListPanel();
          } else if (widget == 'formPanel') {
            this.createFormPanel();
          } else if (widget == 'detailsPanel') {
            this.createDetailsPanel();
          } else {
            let updatedPageMeta: any = await this.pageService.createPanel(widget, this.currentPageMeta, currentPanel, null, null, null, null, this.pageService.dragNewPanelPlaceholder.newIndex || undefined);
            // this.metaService.update(this.currentPageMeta)
            this.pageService.widgetReleaseFromDrag({});
            this.pageService.dragNewPanelPlaceholder = {
              newIndex: -1,
              panelId: '',
              direction: ''
            }
            console.log("selectedDragMap cleared")
            //push into observables
            this.keepMetaInSync(updatedPageMeta)
            this.metaService.userMadeChanges.next(true);

            this.widgetCreationLock = false
          }
        }
      }
    })

    this.actionPanelRequestSub = this.widgetService.actionPanelRequest.subscribe(async (data) => {
      console.log("action panel request sub")
      if(!data || !this.pageService.isPointerInCanvas) return;
      this.widgetService.actionPanelRequest.next(null);
      this.currentPageMeta.panels.push(data);
      this.keepMetaInSync(this.currentPageMeta)
      this.metaService.userMadeChanges.next(true);
      this.widgetCreationLock = false;
    })

    if(!this.starchDragSubscription) this.starchDragSubscription = this.widgetService.starchtDragCreationRequest.pipe(debounceTime(50)).subscribe(async (starchMap) => {
      if(!starchMap || !this.pageService.isPointerInCanvas) return;
      this.widgetService.starchtDragCreationRequest.next(null);
      let type =  starchMap?.__type || "base";
      let data = starchMap;
      if(type == "base") data = starchMap.nodeMap;
      var dialog = this.dialog.open(StarchBasePanelPopupComponent, {
        minWidth: "60%",
        height: 'auto',
        data: { data: data, type: type}
      });
      var diologResult = await dialog.afterClosed().toPromise();
      if(!diologResult) return;
      let panelMap = diologResult.panelMap;
      if (!this.widgetCreationLock) {
        this.widgetCreationLock = true;
        if (this.builderMode) {
          var starchListPanel = null;
          if(panelMap.listPanel.checked){
            let listPanalData = diologResult.listPanelConfig;
            listPanalData.name = 'listPanel_' + (this.currentPageMeta.panels.length + 1);
            listPanalData.id = new Date().setSeconds(new Date().getSeconds() + 10).valueOf();
            starchListPanel = listPanalData;
            console.log("listPanalData", listPanalData)
            if(panelMap.formPanel.checked ){
              let createPanel = JSON.parse(JSON.stringify(diologResult.formPanelCreatePanel));
              createPanel.id = new Date().setSeconds(new Date().getSeconds() + 65).valueOf();
              if(createPanel?.widgets?.[0]) createPanel.widgets[0].id = new Date().setSeconds(new Date().getSeconds() + 67).valueOf();
              this.currentPageMeta.panels.push(createPanel);
            }
            if(!panelMap?.searchPanel?.checked) {
              this.currentPageMeta.panels.push(listPanalData);
              if(panelMap.formPanel.checked ){
                this.currentPageMeta.panels.push(diologResult.formPanelCreatePanel2)
              }
            }
          }

          if(panelMap.searchPanel.checked){
            let searchPanelData = diologResult.searchPanelConfig;
            searchPanelData.name = 'searchPanel_' + (this.currentPageMeta.panels.length + 1);
            searchPanelData.id = new Date().setSeconds(new Date().getSeconds() + 30).valueOf();
            if(starchListPanel){
              searchPanelData["outputListPanelId"] = starchListPanel.id;
              searchPanelData["outputListPanelName"] = starchListPanel.name;
            }
            this.currentPageMeta.panels.push(searchPanelData);
            if(panelMap.searchPanel.checked){
              this.currentPageMeta.panels.push(starchListPanel);
              if(panelMap.formPanel.checked ){
                this.currentPageMeta.panels.push(diologResult.formPanelCreatePanel2)
              }
            }
          }

          if(panelMap.formPanel.checked && !panelMap.listPanel?.checked){
            let formPanelData = diologResult.formPanelConfig;
            // formPanelData.name = 'formPanel_' + (this.currentPageMeta.panels.length + 1);
            // formPanelData.id = new Date().setSeconds(new Date().getSeconds() + 50).valueOf();
            this.currentPageMeta.panels.push(formPanelData);
          }

          if(panelMap.detailsPanel.checked && !panelMap.listPanel?.checked){
            let detailsPanelData = diologResult.detailsPanelConfig;
            // detailsPanelData.name = 'detailsPanel_' + (this.currentPageMeta.panels.length + 1);
            // detailsPanelData.id = new Date().setSeconds(new Date().getSeconds() + 20).valueOf();
            this.currentPageMeta.panels.push(detailsPanelData);
          }


          console.log("this.currentPageMeta.panels", this.currentPageMeta.panels)
          this.keepMetaInSync(this.currentPageMeta)
          this.metaService.userMadeChanges.next(true);
        };

        this.widgetCreationLock = false
      }
    })

    //------------SUBSCRIBE TO URL PARAMS: CHECK PAGE CODE------------------------------
    this.routeParameterSubscription = this.route.params.subscribe((routeData) => {
      if(this.footerConfig?.isFooterPage) return
      console.log("[page] routeData", routeData)
      // if a currentPageCode value exists in pageService bt is different from this page's code,
      // then this page is not in focus
      // if (this.pageService.currentPageCode.value && this.currentPageMeta?.code && this.pageService.currentPageCode.value !== this.currentPageMeta?.code) {
      //   console.log("not in focus, returning from", this.currentPageMeta?.code)
      //   return
      // }
      this.ready = false
      let currentUrl = window.location.href;

      console.log("currentUrl",currentUrl, currentUrl.includes("/q/"))
      if(!currentUrl.includes("/q/")) {
        currentUrl = currentUrl.endsWith("/") ? currentUrl.slice(0, -1) : currentUrl; // discard trailing / 
        let parsedPagecode: any = currentUrl.split('/');
        parsedPagecode = parsedPagecode[parsedPagecode.length - 1];
        parsedPagecode = decodeURI(parsedPagecode.includes("?") ? parsedPagecode.split('?')[0] : parsedPagecode.split('#')[0]);
        this.parsedPageCode = parsedPagecode
      } else {
        this.parsedPageCode = routeData.pagecode;
      }

      console.log("parsedPagecode", this.parsedPageCode)


      // this.currentPageCode = routeData.pagecode || undefined;
      this.currentPageCode = this.parsedPageCode || undefined;
      this.pageService.currentPageCode.next(this.currentPageCode)

      if(!this.isBrowser){
        this.currentPageCode = routeData.pagecode
      }
      let page_structure = this.metaService.page_structure.value
      if (!page_structure || !page_structure.pages || !page_structure.pages.length) {
        return
      }

      this.spinner = true
      this.page_structure = page_structure;
      console.log("page structure value", page_structure, "pageMeta", this.currentPageMeta, "time", Date.now())
      //find current page code from url in page_structure and then fetch meta
      // console.log(this.page_structure);
      this.page_structure.pages.forEach(async (pageCode) => {
        // console.log("page object", page_structure[pageCode])
        if (this.currentPageCode == pageCode) {

          let page_meta: any
          let requestedCode = pageCode
          try {
            console.log("fetching meta for ", this.page_structure[pageCode].id, "code ", pageCode)
            page_meta = await this.fetchMeta(this.page_structure[pageCode].id)
            if (requestedCode != page_meta.code) {
              return
            };
            this.resetVariables()

            //call page Init to generate data/page models and initialize current page meta
            console.log("calling from route data value subscription", JSON.parse(JSON.stringify(page_meta || {})))
            this.pageInit(this.pageService.dataCorrectPageMeta(page_meta));
            // this.currentPageMeta = this.pageService.dataCorrectPageMeta(page_meta)

            //initialize periodic save
            // if (this.builderMode) {
            //   console.log("calling periodic save init from router parameter subscription")
            //   this.periodicSaveInit()
            // }
          } catch (error) {
            console.log("could not fetch page meta for ", this.page_structure[pageCode].id, "code ", pageCode)
          }
        }
      });
      this.spinnerService.hide()
      console.log("outside page structure subscription")
    });

    this.toolPasteRequest = this.pageService.toolPasteRequest.subscribe(async (data) => {
      // if a currentPageCode value exists in pageService bt is different from this page's code,
      // then this page is not in focus
      if (this.pageService.currentPageCode.value && this.pageService.currentPageCode.value !== this.currentPageMeta?.code) {
        console.log("not in focus, returning from", this.currentPageMeta?.code)
        return
      }
      if(!data) return;
      console.log("-------> tool paste request", data)
      this.pasteTool(data)
    })
    this.toolCutRequest = this.pageService.toolCutRequest.subscribe(async (data) => {
      if(!data) return;
      this.cutTool(data)
    })

    this.createCopiedContentRequest = this.pageService.createCopiedContentRequest.subscribe(async (data) => {
      console.log("createCopiedContentRequest received", data)

      if(!data) return;
      this.createCopiedTool(data);
    })


    // when user made changes to the page
    this.userChangesSubscription = this.metaService.$userMadeChanges.subscribe(isChanged => {
      console.log("isChanged", isChanged)
      if(!isChanged) return
      // delete this.metaService.isTimerSet[this.currentPageMeta.code]
      // this.periodicSaveInit()
      this.startPeriodicSaveTimer()
    })

  }

  metaSEOInit(bloomMeta){
    this.title.setTitle(`${bloomMeta?.name || 'Bloom'} - Bloom`);
    if(bloomMeta?.description)this.meta.updateTag({ name: 'description', content: bloomMeta?.description });
  }


  handleFooterPage(){
    this.builderMode = this._builderMode
    console.log("builder mode set", this.builderMode)
    console.log("footer config", this.footerConfig)

    if (this.footerConfig?.isFooterPage) {
      // this.builderMode = true
      let pageStructure = this.metaService.page_structure.value
      this.page_structure = pageStructure
      console.log("page structure loaded", pageStructure)
      console.log("pageMeta", JSON.parse(JSON.stringify(this.currentPageMeta || "value is undefined")))
      let meta: any
      if (pageStructure?.footer?.meta) {
        // this.currentPageMeta = pageStructure?.footer?.meta
        // this.currentPageCode = this.currentPageMeta.code
        meta = pageStructure?.footer?.meta
        console.log("current page meta will be initialized from existing", meta)
      } else {
        // create footer page meta
        meta = { code: '__FOOTER__', name: '__FOOTER__', panels: []}
        // this.currentPageMeta = meta
        // this.currentPageCode = meta.code
        this.page_structure.footer = pageStructure.footer || {}
        this.page_structure['footer']['meta'] = meta
      }

      console.log("page meta initialized", JSON.parse(JSON.stringify(meta || "")))
      // this.currentPageCode = this.currentPageMeta.code

      if (this.builderMode && this.footerConfig.changeContext) {
        // save old and set new context in page service
        // this.pageService.setContext({
        //   pageMeta: meta,
        //   currentPageCode: meta.code
        // })
        // console.log("context set", this.pageService.currentPageCode.value)
        console.log("calling from handle footer page", JSON.parse(JSON.stringify(meta || {})))
        this.currentPageMeta = null
        this.pageInit(meta)
        console.log("calling periodic save init from handle footer page subscription")
        // this.periodicSaveInit()
        this.startPeriodicSaveTimer()
      } else if (!this.footerConfig.changeContext) {  // show in blurred view
        this.currentPageMeta = meta
        this.currentPageCode = this.currentPageMeta.code
        this.sendMessage()
        this.ready = true
      }

      // this.ready = true
      this.spinnerService.hide()
    }
  }

  checkForLoginWidget(){
    let loginWidget = this.checkLoginWidget();
    if(this.metaService.bloomMeta.landing_page_type == "page" && this.metaService.bloomMeta.landing_page == this.currentPageCode && !loginWidget.hasWidget) {
      this.addLoginWidget();
    }

    // else if(this.metaService.bloomMeta.landing_page != this.currentPageCode && loginWidget.hasWidget) {
    //   this.widgetDeletion(loginWidget);
    //   this.loginWidgetDeleted = true;
    //   loginWidget.hasWidget = false;
    // }
  }

  async addLoginWidget(){
    let updatedPageMeta: any = await this.pageService.createPanel("login", this.currentPageMeta, this.activePanel);
    console.log("[Add login widget] widget added to page", updatedPageMeta)
    this.keepMetaInSync(updatedPageMeta)
    this.metaService.userMadeChanges.next(true);
    this.widgetCreationLock = false
  }

  checkLoginWidget(){
    let result:any = {
      hasWidget: false
    }
    for (let index = 0; index < this.currentPageMeta?.panels?.length; index++) {
      const element = this.currentPageMeta.panels[index];
      if(element.type != 'regular') continue;
      else {
        result.panelId = element.id;
        for (let i = 0; i < element?.layoutMap?.list?.length; i++) {
          let columnId = element.layoutMap.list[i]
          let column = element.layoutMap[columnId]
          result.layoutId = columnId;
          for (let j = 0; j < column?.list?.length; j++) {
            let rowId = column.list[j];
            let row = column[rowId]
            result.layoutRowId = rowId;
            for (let k = 0; k < row?.elements?.length; k++) {
              let element = row.elements[k];
              if(element.type == "login") {
                result.hasWidget = true;
                result.widgetId = element.id;
                break;
              }
            }
            if(result.hasWidget) break;
          }
          if(result.hasWidget) break;
        }
      }
      if(result.hasWidget) break;
    }
    return result;
  }


  cutTool(data){

    if(data.copiedType == "panel" && this.pageService.isToolCutted){
      let removeIndex = this.currentPageMeta.panels.findIndex(panel => panel.id == data.copiedMeta.id);
      if (removeIndex > -1) { //
        this.currentPageMeta.panels.splice(removeIndex, 1); //
      }
    } else if(data.copiedType == "widget" && this.pageService.isToolCutted){
      this.currentPageMeta.panels.forEach((e) => {
        if(this.pageService.copiedPanelId == e.id){
          if(e.type != "regular") return;
          let removeIndex = e.widgets?.findIndex(wid => wid.id == data.copiedMeta.id);
          console.log("removeIndex", removeIndex)
          if (removeIndex > -1) { //
            e.widgets.splice(removeIndex, 1); //
          }
        }
      })
    }

    this.keepMetaInSync(this.currentPageMeta)
    this.metaService.userMadeChanges.next(true);
  }

  pasteTool(data){
    if(data.copiedType == "widget"){

      if(this.hoveredPanelId != -1){
        this.currentPageMeta.panels.forEach((e) => {
          if(this.hoveredPanelId == e.id) {
            if(e.type != "regular") return;
            let widgets = this.pageService.getWidgetsFromPanel(e);
            let meta = this.duplicateWidget(data.copiedMeta, widgets);
            this.dropWidget(meta);
          }
        })
      } else {
        let widMeta = this.duplicateWidget(data.copiedMeta, []);
        let hostPanel = new Panel(Date.now(), `panel_${this.currentPageMeta.panels.length + 1}`, widMeta);
        if(!this.currentPageMeta.panels) this.currentPageMeta.panels = [];
        this.currentPageMeta.panels.push(hostPanel);
      }

    } else if (data.copiedType == "panel"){

      let meta = this.duplicatePanel(data.copiedMeta);
      let currentIndex = this.currentPageMeta.panels.findIndex(panel => panel.id == this.hoveredPanelId);
      if(currentIndex != -1){
        this.currentPageMeta.panels.splice(currentIndex + 1, 0, meta)
      } else {
        this.currentPageMeta.panels.push(meta);
      }
    }

    if(this.pageService.isToolCutted) this.pageService.isToolCutted = false;

    this.pageService.isPastedOnce = true;
    this.keepMetaInSync(this.currentPageMeta)
    this.metaService.userMadeChanges.next(true);
    console.log("this.currentPageMeta", this.currentPageMeta)
  }

  duplicatePanel(panel){
    let meta = JSON.parse(JSON.stringify(panel));
    let panelName = `panel_${this.currentPageMeta.panels && this.currentPageMeta.panels.length > 1 ? this.currentPageMeta.panels.length + 1 : 1}`
    let panelId = String(Date.now());
    meta.id = panelId;
    meta.name = panelName;
    let widgets = this.pageService.getWidgetsFromPanel(meta)
    widgets.forEach((widget, i) => {
      widget.id =  new Date().setMilliseconds(new Date().getMilliseconds() + i).valueOf();
    });

    console.log("meta", meta)
    meta = this.pageService.clonePanel(meta);
    return meta;
  }

  duplicateWidget(widget, widgets){
    let meta = JSON.parse(JSON.stringify(widget));
    let widgetName = `${meta.type}_${widgets && widgets.length > 1 ? widgets.length + 1 : 1}`
    let widgetId = String(Date.now());
    meta.id = widgetId;
    meta.name = widgetName;
    return meta;
  }

  async createCopiedTool(data){
    let type = "label"
    let content = data.content;
    if(data.type) type = data.type;
    console.log("content", content)
    let updatedPageMeta: any = await this.pageService.createPanel(type, this.currentPageMeta, this.activePanel, content);
    console.log("widget added to page", updatedPageMeta)

    //push into observables
    this.keepMetaInSync(updatedPageMeta)
    this.metaService.userMadeChanges.next(true);

    this.widgetCreationLock = false
  }

  dropWidget(widget) {
    console.log("widget dropped", widget)
    let hoveredLayout = this.pageService.hoveredLayout;
    let hoveredRow = this.pageService.hoveredRow;
    let hoveredPanelId = this.hoveredPanelId;
    if(hoveredPanelId){
        this.currentPageMeta.panels.forEach(panel => {
          if(panel.id == hoveredPanelId){
            let layout;
            if(hoveredLayout) layout = panel.layoutMap[hoveredLayout]
            let gridXLeft = 0;
            let takenGridX = 0;
            if(hoveredLayout && hoveredRow){
              layout[hoveredRow]?.['elements']?.forEach(wid => {
                takenGridX += Number(wid?.gridX || 0)
              });
              gridXLeft = 12 - takenGridX;
            }

            if(hoveredLayout && (hoveredRow && gridXLeft > 0)){
              widget.gridX = gridXLeft;
              if(layout[hoveredRow]?.type != 'elements') return;
              let index = panel.layoutMap[hoveredLayout][hoveredRow]['elements'].findIndex(item => item.id === this.hoverActivePanel);
              let indexToInsert = index + 1;
              panel.layoutMap[hoveredLayout][hoveredRow]['elements'].splice(indexToInsert, 0, widget);
            } else if (hoveredRow && hoveredRow != 1 && layout) {
              let rowId = new Date().valueOf();
              let elements = [widget];
              let index = layout['list'].findIndex(item => item === hoveredRow);
              if(index > 0){
                layout.list.splice(index + 1, 0, rowId);
              } else {
                layout.list.push(rowId);
              }
              layout[rowId] = {
                  type: "elements",
                  elements: elements
              }
            } else if (layout) {
              let rowId = new Date().valueOf();
              let elements = [widget];
              layout.list.push(rowId);
              layout[rowId] = {
                  type: "elements",
                  elements: elements
              }
            }
          }
        })
    }
  }

  //when user navigates to another page, previous page's ondestroy will be called
  //in metaService, pageMeta will be overwritten with new page meta. before that happens,
  //save the last pageMeta in database by explicitly calling update(),

  ngOnDestroy() {
    console.log(`HIT ONDESTROY FOR PAGE ${this.currentPageMeta}`)

    this.widgetSubscription?.unsubscribe()
    this.widgetDragSubscription?.unsubscribe()
    this.fragmentSubscription?.unsubscribe()
    this.getPageMetaSubscription?.unsubscribe()
    this.routeParameterSubscription?.unsubscribe()
    this.savePageRequest?.unsubscribe()
    this.toolPasteRequest?.unsubscribe();
    this.toolCutRequest?.unsubscribe()
    this.starchDragSubscription?.unsubscribe();
    this.createCopiedContentRequest?.unsubscribe();
    this.userChangesSubscription?.unsubscribe();

    // reset the observables in metaService
    // this.metaService.page_structure.next('');
    this.keepMetaInSync({})
    // this.metaService.pageMeta.next('')

    clearInterval(this.timerId)
    if (this.builderMode) {
      console.log("clear interval, calling", "time", Date.now())
      this.savePage()
    }
  }

  reorderDragStarted(event) {
    this.pageService.panelReorderDragging = true
    console.log("reorder drag started", event)
    this.panelHeightBeforeDrag = event.source?.element.nativeElement?.closest('.panel')?.clientHeight ? event.source?.element.nativeElement.closest('.panel').clientHeight + 'px' : 0
    this.panelWidthBeforeDrag = event.source?.element.nativeElement?.closest('.panel')?.clientWidth ? Math.ceil(event.source?.element.nativeElement.closest('.panel').clientWidth) + 'px' : 0
    console.log("height before", this.panelHeightBeforeDrag)
    console.log("Width before", this.panelWidthBeforeDrag)
  }

  // reorderDragEnded(event) {
  //   this.pageService.panelReorderDragging = false
  //   console.log("reorder drag ended", event) 
  //   this.panelHeightBeforeDrag = 0
  //   this.panelWidthBeforeDrag = 0
  // }

  //--------------------------METHODS----------------------------

  checkContextMatch(code) {
    return false
  }

  async savePage() {
    console.log("save page(), pageMeta", JSON.parse(JSON.stringify(this.currentPageMeta || "")))
    if (this.footerConfig?.isFooterPage && this.currentPageMeta?.code == '__FOOTER__') {
      if (this.page_structure.footer) {
        this.page_structure.footer.meta = this.currentPageMeta
        let latestPageStructure = this.metaService.page_structure.value
        latestPageStructure['footer'] = this.page_structure.footer  // append with current updates if any
        this.page_structure = latestPageStructure // assign it back
      }
      try {
        let res = await this.metaService.updatePageStructure(this.page_structure)
        console.log("footer saved")
      } catch (e) {
        console.error("footer save unusuccessful", e)
      }
    } else {
      try {
        let res = await this.metaService.update(this.currentPageMeta)
        console.log("save page successful")
      } catch(err) {
        console.error("save page unusuccessful", err)
      }
    }
  }

  /**
   * resets the necessary variables to destroy state of the current page (clean slate for new page)
   */
  resetVariables() {
    this.activePanel = null;
    this.hoverActivePanel = -1;
    this.hoveredPanelId = '';
    this.selectedWidgetId = ''
    this.widgetCreationLock = false;
  }


  startPeriodicSaveTimer () {
    console.log("start Periodic SaveTimer hit")
    if (!this.builderMode) {
      console.log("non builder mode: will return")
      return
    }
    // Clear any existing save timer
    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    // Start the save timer for 10 seconds
    this.timerId = setTimeout(() => {
      this.savePage();
    }, PERIODIC_SAVE_TIMER_INTERVAL);
  }



  // async saveMeta(){
  //   console.log("save Meta fn: currentPageMeta", JSON.parse(JSON.stringify(this.currentPageMeta || "value is undefined")))
  //   try {
  //     // console.log("inside timer function, timer", this.timerId)
  //     if (this.footerConfig?.isFooterPage && this.currentPageMeta.code == '__FOOTER__') {
  //       if (this.page_structure.footer) {
  //         this.page_structure.footer.meta = this.currentPageMeta
  //       }
  //       // console.log("calling from: page component -> periodic save init")
  //       let res = await this.metaService.updatePageStructure(this.page_structure)
  //       this.clearSaveTimer()
  //       console.log("routine footer update successful")
  //     } else {
  //       let res = await this.metaService.update(this.currentPageMeta)
  //       this.clearSaveTimer()
  //       console.log("routine page update successful")
  //     }
  //   } catch (e) {
  //     console.error("error in updating page meta", e)
  //   }
  // }

  clearSaveTimer(){
    //reset the userMadeChanges variable to sense subsequent changes as fresh
    this.metaService.userMadeChanges.next(false);
    clearInterval(this.timerId);
    this.timerId = null;
    console.log("paused the timer, will only run after next user made change detected, timerId now", this.timerId)
  }

  @HostListener('window:keydown',['$event'])
  async onKeyPress($event: KeyboardEvent) {
    if(!this.builderMode) return  // suppress keypress event in view mode
    console.log("on key press")
    if(($event.ctrlKey || $event.metaKey) && $event.key == "z" && !$event.repeat){
      let pageMeta = this.FEEventService.undo();
      if(!pageMeta) return;
      this.currentPageMeta = pageMeta;
      this.keepMetaInSync(this.currentPageMeta)
    }

    if(($event.ctrlKey || $event.metaKey) && $event.key == "y" && !$event.repeat){
      let pageMeta = this.FEEventService.redo();
      if(!pageMeta) return;
      this.currentPageMeta = pageMeta;
      this.keepMetaInSync(this.currentPageMeta)
    }
  }

  pageInit(pageMeta) {
    console.log('[PAGE INIT] pageMeta', pageMeta);
    //TODO : performance improvement
    
    let newPageMeta = JSON.stringify(pageMeta)
    let oldPageMeta = JSON.stringify(this.currentPageMeta || "")
    console.log("old meta", JSON.parse(oldPageMeta))
    console.log("new meta", JSON.parse(newPageMeta))
    // this.metaService.contextChanged.next(null);

    this.FEEventService.captureEvent(this.currentPageMeta)

    if(oldPageMeta !== newPageMeta){ // && !this.metaService.formPanelLoaded
      console.log("oldMeta !== newMeta")
      this.currentPageMeta = JSON.parse(newPageMeta)
      this.currentPageCode = this.currentPageMeta.code

      this.pageService.setContext({
        pageMeta: this.currentPageMeta,
        currentPageCode: this.currentPageCode
      })
      this.keepMetaInSync(this.currentPageMeta)
      console.log("pageMeta set in pageService", JSON.parse(JSON.stringify(this.pageService.pageMeta || "")))
      // console.log("pageService.pageMeta", this.pageService.pageMeta)

      this.pageService.currentPageCode.next(this.currentPageMeta.code)

      this.pageService.generateDataModel(this.currentPageMeta);

      this.pageService.resetPageModel()
      this.pageService.generatePageModel(this.currentPageMeta);

      this.sendMessage()
      this.ready = true;

    }else{
      console.log("same meta, skipping replace")
    }
    
    // this.cdr.detectChanges();
    if(this.builderMode) this.checkForLoginWidget()

    setTimeout(() => {
      this.panelWidth = this.panelWrapElement?.nativeElement?.offsetWidth;
      // console.log('Panel width:', this.panelWidth);
      this.handleDropLists()
    }, 300);
  }


  handleDropLists(){
    // console.log("handle drop lists called", this.dropLists)
    let dropListRef: any = this.dropLists?.first?._dropListRef
    let parentElement = dropListRef?.element?.closest('.wrapper')
    if(!dropListRef || !parentElement) return
    dropListRef.withScrollableParents([parentElement])
    // console.log("scrollable element set", dropListRef.getScrollableParents())
  }

  whatTypeIsReadyValue(){
    return typeof this.ready
  }

  // emits message to acknowledge parent for ready state
  sendMessage() {
    console.log("sending ready signal through router event")
    this.messageEvent.emit('Page is ready');
  }

  async fetchMeta(id: any) {
    console.log("inside fetchMeta", id)
    let response: any
    try {
      response = await this.metaService.get(id)
      console.log('[PAGE] fetchMeta(): pageMeta', JSON.parse(JSON.stringify(response)));
      return response;
    } catch (error) {
      console.log('[PAGE] fetchMeta(): error', error);
      throw error;
    }
  }

  panelDeselected(event) {
    if(this.widgetService.checkIfEventBubbling(event)) return
    console.log('deselected');
    this.widgetService.widgetDeselected.next(true)
    this.selectedWidgetId = -1;
    this.pageService.selectedLayout = null
    this.pageService.selectedRow = null
    this.pageService.selectedWidget = null
    this.pageService.selectedPanel = null
    this.activePanel = null;
  }

  /**
   * deletes a panel
   * @param panelId
   */
  panelDelete(panelId: any) {
    console.log("panel delete")
    let deletedPanel;
    this.currentPageMeta.panels.forEach((panel, i, obj) => {
      if (panel.id == panelId) {
        deletedPanel = panel
        obj.splice(i, 1)
      }
    });
    this.activePanel = null

    // check if selected widget is part of the panel, if so, deselect widget
    let widgets = this.widgetService.getWidgetsFromPanel(deletedPanel)
    if(widgets.find(w => w.id == this.selectedWidgetId)) {
      this.panelDeselected(null)
    }

    //update database
    // this.metaService.update(this.currentPageMeta);

    //#####################################################################################
    this.keepMetaInSync(this.currentPageMeta)
    this.metaService.userMadeChanges.next(true);
  }

  onPanelSelect(panelId: number) {
    this.activePanel = panelId;
    // console.log('panel selected', panelId);
    // console.log('this.pageService.selectedLayout', this.pageService.selectedLayout);
    // console.log('this.pageService.selectedRow', this.pageService.selectedRow);

    // searches through the panels array by id and updates selectedNow property.
    this.currentPageMeta.panels.forEach((panel) => {
      if (panel.id == panelId) {
        panel.selectedNow = true;
        // this.pageService.selectedLayout = null;

        // // if selected layout does not belong to this panel, deselect it
        // if(this.pageService.selectedLayout && !panel.layoutMap?.list?.includes(this.pageService.selectedLayout)) {
        //   console.log("selected layout is NOT part of this panel, will reset")
        //   this.pageService.selectedLayout = null;
        // }
        // if(this.pageService.selectedRow && !this.isRowInPanel(this.pageService.selectedRow, panel)){
        //   this.pageService.selectedRow = null
        // }

        this.pageService.selectedPanel = panel;
        console.log("panel selection saved in pageService", this.pageService.selectedPanel)
        // this.pageService.widgetSelected = false;
        this.pageService.panelSelected = true;
      } else {
        panel.selectedNow = false;
      }
    });
  }

  isRowInPanel(rowId, panelObj){
    console.log("isRowInPanel: rowId", rowId, "panelObj", panelObj)
    let isPresent: boolean = false
    panelObj.layoutMap?.list?.forEach(colId => {
      panelObj?.layoutMap?.[colId]?.list?.forEach(rId => {
        if(rowId == rId) isPresent = true
      })
    })
    console.log("is row present in this panel", isPresent)
    return isPresent
  }

  onPanelMouseenter(panelId: number) {
    // this.activePanel = panelId
    // console.log(panelId, "panel id received mouseenter")

    if(this.pageService.panelReorderDragging) return

    //searches through the panels array by id and updates hoveredNow property.
    this.currentPageMeta.panels.forEach((panel) => {
      if (panel.id == panelId) {
        // panel.hoveredNow = true;
        this.hoveredPanelId = panelId;
        this.pageService.hoveredPanelId = panelId;
        // console.log("this.hoveredPanelId", this.hoveredPanelId)
      }
    });
  }
  onPanelMouseleave(panelId: number) {
    // this.activePanel = panelId
    // console.log(panelId, "panel id received at mouseout")

    //searches through the panels array by id and updates hoveredNow property.
    this.currentPageMeta.panels.forEach((panel) => {
      if (panel.id == panelId) {
        // panel.hoveredNow = false;
        this.hoveredPanelId = -1;
        this.pageService.hoveredPanelId = -1;
      }
    });
  }

  widgetSelection(newSelectedWidgetId: any, panelMeta: any) {
    console.log('widget selection received in page', newSelectedWidgetId);

    if(this.selectedWidgetId !== newSelectedWidgetId){
      // let widgetListInPanel = this.widgetService.getWidgetsFromPanel(panelMeta)
      // let widgetMeta = widgetListInPanel.find(w => w.id == newSelectedWidgetId)

      // this.widgetService.openWidgetSidebarSettings.next({
      //   widgetMeta: widgetMeta,
      //   panelId: panelMeta.id
      // })
      // this.widgetService.widgetDeselected.next(true)
    }

    this.selectedWidgetId = newSelectedWidgetId;
  }

  //----------------------------------- CREATE SEARCH PANEL ---------------------------------
  async createSearchPanel() {
    console.log('inside createSearchPanel function');

    //create a search panel instance
    let hostPanel = new SearchPanel(Date.now(), 'searchPanel_' + (this.currentPageMeta.panels.length + 1));
    // hostPanel.type = 'searchpanel'

    console.log("search panel meta created", hostPanel)

    //at this point we can check screen size and set min-width variable accordingly, to set the width of dialog

    //open dialog box and get essential config of search panel
    let dialogRef: any;
    dialogRef = this.dialog.open(SearchPanelDialogComponent, {
      minHeight: '50vh',
      minWidth: '80vw',
      maxHeight: '90vh',
      data: {
        firstHit: true,
        panelMeta: hostPanel,
        pageMeta: this.currentPageMeta
      },
    })

    dialogRef.afterClosed().subscribe(data => {
      if (!data) {
        console.log("search panel configuration dialog closed unexpectedly")
      } else {
        console.log("search panel config dialog resolved", data)

        if (this.pageService.dragNewPanelPlaceholder?.newIndex) {
          this.currentPageMeta.panels.splice(this.pageService.dragNewPanelPlaceholder.newIndex, 0, data['searchPanelMeta'])
        } else {
          //push new panel to panels array
          this.currentPageMeta.panels.push(data['searchPanelMeta']);
        }

        if (data['listPanelMeta']) {
          let searchPanelIndex = this.currentPageMeta.panels.findIndex(panel => panel.id == data['searchPanelMeta'].id)
          this.currentPageMeta.panels.splice(searchPanelIndex + 1, 0, data['listPanelMeta'])
        }

        //######################################################################################
        //push into pagemeta observable
        this.keepMetaInSync(this.currentPageMeta)
        // this.metaService.update(this.currentPageMeta)
        // console.log("nexted page meta")
        this.metaService.userMadeChanges.next(true);
        // console.log("nexted user made changes")
      }
      this.clearNewPanelPlaceholderData()
      this.widgetCreationLock = false
    })

  }

  //----------------------------------- CREATE SEARCH PANEL ---------------------------------
  async createListPanel() {
    console.log('inside createListPanel function');

    //create a search panel instance
    let hostPanel = new ListPanel(Date.now(), 'listPanel_' + (this.currentPageMeta.panels.length + 1));

    console.log("list panel meta created", hostPanel)

    //open dialog box and get essential config of list panel
    let dialogRef: any;
    dialogRef = this.dialog.open(ListPanelDialogComponent, {
      minHeight: '50vh',
      minWidth: '80vw',
      maxHeight: '90vh',
      data: {
        panelMeta: hostPanel,
        pageMeta: this.currentPageMeta,
        firstHit: true
      },
    })

    dialogRef.afterClosed().subscribe(data => {
      if (!data) {
        console.log("list panel configuration dialog closed unexpectedly")
      } else {
        console.log("list panel config dialog resolved", data)

        if (this.pageService.dragNewPanelPlaceholder?.newIndex >= 0) {
          this.currentPageMeta.panels.splice(this.pageService.dragNewPanelPlaceholder.newIndex, 0, data)
        } else {
          //push new panel to panels array
          this.currentPageMeta.panels.push(data);
        }
        //######################################################################################
        //push into pagemeta observable
        this.keepMetaInSync(this.currentPageMeta)
        // this.metaService.update(this.currentPageMeta)
        this.metaService.userMadeChanges.next(true);
      }
      this.clearNewPanelPlaceholderData()
      this.widgetCreationLock = false
    })

  }

  //----------------------------------- CREATE FORM PANEL ------------------------------------
  async createFormPanel() {
    console.log('inside createFormPanel function');

    //create a search panel instance
    let hostPanel = new FormPanel(Date.now(), 'formPanel_' + (this.currentPageMeta.panels.length + 1));

    console.log("form panel meta created", hostPanel)

    //open dialog box and get essential config of form panel
    let dialogRef: any;
    dialogRef = this.dialog.open(FormPanelDialogComponent, {
      minHeight: '50vh',
      minWidth: '80vw',
      maxHeight: '90vh',
      data: {
        panelMeta: hostPanel,
        pageMeta: this.currentPageMeta,
        firstHit: true
      },
      panelClass: 'formPanelConfigCover'
    })

    dialogRef.afterClosed().subscribe(data => {
      if (!data) {
        console.log("form panel configuration dialog closed unexpectedly")
      } else {
        console.log("form panel config dialog resolved", data)


        if (this.pageService.dragNewPanelPlaceholder?.newIndex) {
          this.currentPageMeta.panels.splice(this.pageService.dragNewPanelPlaceholder.newIndex, 0, data)
        } else {
          //push new panel to panels array
          this.currentPageMeta.panels.push(data);
        }
        
        //######################################################################################
        //push into pagemeta observable
        this.keepMetaInSync(this.currentPageMeta)
        // this.metaService.update(this.currentPageMeta)
        this.metaService.userMadeChanges.next(true);
      }
      this.clearNewPanelPlaceholderData()
      this.widgetCreationLock = false
    })
  }

  //----------------------------------- CREATE FORM PANEL ------------------------------------
  async createDetailsPanel() {
    console.log('inside createDetailsPanel function');

    //create a search panel instance
    let hostPanel = new DetailsPanel(Date.now(), 'detailsPanel_' + (this.currentPageMeta.panels.length + 1));

    console.log("details panel meta created", hostPanel)

    //open dialog box and get essential config of form panel
    let dialogRef: any;
    dialogRef = this.dialog.open(DetailsPanelDialogComponent, {
      minHeight: '50vh',
      minWidth: '80vw',
      maxHeight: '90vh',
      data: {
        panelMeta: hostPanel,
        pageMeta: this.currentPageMeta,
        firstHit: true
      },
      panelClass: 'detailsPanelConfigCover'
    })

    dialogRef.afterClosed().subscribe(data => {
      if (!data) {
        console.log("details panel configuration dialog closed unexpectedly")
      } else {
        console.log("details panel config dialog resolved", data)

        if (this.pageService.dragNewPanelPlaceholder?.newIndex) {
          this.currentPageMeta.panels.splice(this.pageService.dragNewPanelPlaceholder.newIndex, 0, data)
        } else {
          //push new panel to panels array
          this.currentPageMeta.panels.push(data);
        }
        
        //######################################################################################
        //push into pagemeta observable
        this.keepMetaInSync(this.currentPageMeta)
        this.metaService.update(this.currentPageMeta)
        // this.metaService.userMadeChanges.next(true);
      }
      this.clearNewPanelPlaceholderData()
      this.widgetCreationLock = false
    })
  }

  clearNewPanelPlaceholderData(){
    this.pageService.dragNewPanelPlaceholder = {
      newIndex: -1,
      panelId: '',
      direction: ''
    }
  }

  panelReorder(event: any) {
    console.log("panel reorder drop", event)
    console.log("panel list", this.currentPageMeta.panels)
    if(!event.item.data) return
    moveItemInArray(
      this.currentPageMeta.panels,
      event.previousIndex,
      event.currentIndex
    );
    this.pageService.panelReorderDragging = false
    console.log("panel list after reorder", this.currentPageMeta.panels)

    //update database
    // this.metaService.update(this.currentPageMeta);

    //###########################################################################
    //update pagemeta observable
    this.keepMetaInSync(this.currentPageMeta)
    console.log("nexted page meta")
    this.metaService.userMadeChanges.next(true);
    console.log("nexted user made changes")
  }

  newPanelMeta(metaReceived) {
    console.log("[PAGE] new panel meta received", JSON.parse(JSON.stringify(metaReceived || "")))
    if (this.pageService.currentPageCode.value && this.pageService.currentPageCode.value !== this.currentPageMeta?.code) {
      // console.log("not in focus, returning from", this.currentPageMeta?.code)
      return
    }

    
    let index = this.currentPageMeta.panels.findIndex(panel => panel.id == metaReceived.id)

    // this.currentPageMeta.panels[index] = metaReceived
    Object.assign(this.currentPageMeta.panels[index], metaReceived);

    // console.log('[PAGE] updated page meta:', JSON.parse(JSON.stringify(this.currentPageMeta)));

    // if (this.footerConfig?.isFooterPage && this.currentPageMeta.code == '__FOOTER__') {
    //   if (this.page_structure.footer) {
    //     this.page_structure.footer['meta'] = this.currentPageMeta
    //     let latestPageStructure = this.metaService.page_structure.value
    //     latestPageStructure['footer'] = this.page_structure.footer  // append with current updates if any
    //     this.page_structure = latestPageStructure // assign it back
    //     this.metaService.page_structure.next(latestPageStructure)
    //   }
    // } else {
    // }
    this.keepMetaInSync(this.currentPageMeta)

    this.metaService.userMadeChanges.next(true);
  }

  async widgetDeletion(deletionPayload) {
    console.log("deletion request received in page", deletionPayload, this.currentPageMeta)
    this.currentPageMeta.panels.forEach((panel) => {
      if (panel.id == deletionPayload.panelId) {

        if(panel.widgets.length > 0){
          panel.widgets.forEach((widget, i, obj) => {
            if (widget.id == deletionPayload.widgetId) {
              obj.splice(i, 1)
            }
          });
        } else {
          panel.layoutMap?.[deletionPayload.layoutId]?.[deletionPayload.layoutRowId]?.['elements']?.forEach((widget, i, obj) => {
            if (widget.id == deletionPayload.widgetId) {
              obj.splice(i, 1)
              this.widgetService.widgetDeselected.next(true)
              this.selectedWidgetId = -1;
            }
          });

          // delete row if empty after widget deletion
          if (panel.layoutMap?.[deletionPayload.layoutId]?.[deletionPayload.layoutRowId]?.['elements']?.length == 0) {
            delete panel.layoutMap[deletionPayload.layoutId][deletionPayload.layoutRowId]
            panel.layoutMap[deletionPayload.layoutId].list.forEach((rowId, j, rowIdList) => {
              if(rowId == deletionPayload.layoutRowId) {
                rowIdList.splice(j, 1)
              }
            })
          }
        }
      }
    });
    console.log("after widget deletion", this.currentPageMeta)

    //update database
    // this.metaService.update(this.currentPageMeta);
    //###########################################################################
    this.keepMetaInSync(this.currentPageMeta)
    this.metaService.userMadeChanges.next(true);
    //save the new page meta in file
    // this.ms.setPage(this.currentPageMeta)
  }

  userInputHandler(event: any) {
    // console.log('text input received in page', event);
    this.pageService.insertIntoPageModel(event);
  }

  keepMetaInSync(newMeta: any){
    console.log("keep meta in sync called, new meta", JSON.parse(JSON.stringify(newMeta)))
    if (this.footerConfig?.isFooterPage && newMeta.code == '__FOOTER__') {
      if (this.page_structure.footer) {
        this.page_structure.footer['meta'] = newMeta
        let latestPageStructure = this.metaService.page_structure.value
        latestPageStructure['footer'] = this.page_structure.footer  // append with current updates if any
        this.page_structure = latestPageStructure // assign it back
        this.metaService.page_structure.next(latestPageStructure)
      }
    } else {
      this.metaService.pageMeta.next(newMeta)
      this.pageService.pageMeta = newMeta
    }

  }

  checkIfDragAllowed(drag){
    console.log("checking if drag allowed", drag)
    return drag.data ? true : false
  }
}
