
/**
 * THIS SERVICE HANDLES DATA MODEL, PAGE MODEL AND ANY INTER-WIDGET/INTER-PANEL COMMUNICATION
 * HANDLES COMMUNICATION BETWEEN SEARCH PANEL AND LIST PANEL
 */

import { Panel } from '../models/panelClasses/basePanel';
import { Paragraph } from '../models/widgetClasses/paragraph';
import { TextInput } from '../models/widgetClasses/input';
import { Button } from '../models/widgetClasses/button';
import { Label } from '../models/widgetClasses/label';
import { Select } from '../models/widgetClasses/select';
import { Table } from '../models/widgetClasses/table';
import { Separator } from '../models/widgetClasses/separator';
import { ImageInput } from './../models/widgetClasses/imageinput';
import { Image } from '../models/widgetClasses/image';
import { Icon } from '../models/widgetClasses/icon';
import { Checkbox } from '../models/widgetClasses/checkbox';
import { Choice } from '../models/widgetClasses/choice';
import { Card } from '../models/widgetClasses/card';
import { RichTextInput } from '../models/widgetClasses/richText';
import { Slider } from '../models/widgetClasses/slider';
import { Autocomplete } from '../models/widgetClasses/autocomplete';
import { TextArea } from '../models/widgetClasses/textarea';
import { Chips } from '../models/widgetClasses/chips';
import { NumberInput } from '../models/widgetClasses/numberinput';
import { DateInput } from '../models/widgetClasses/date';
import { Duration } from '../models/widgetClasses/duration';
import { DateTime } from '../models/widgetClasses/datetime';
import { Time } from '../models/widgetClasses/time';
import { Star } from '../models/widgetClasses/star';
import { Period } from '../models/widgetClasses/period';
import { Link } from '../models/widgetClasses/link';
import { Embed } from '../models/widgetClasses/embed';

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { WidgetDialogComponent } from 'src/app/shared/dialog/widget-dialog/widget.dialog';
import { MetaService } from './meta-service';
import { WidgetManager } from '../models/WidgetManager';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { Tags } from '../models/widgetClasses/tags';
import { Connection } from '../models/widgetClasses/connection';
import { FormService } from 'src/app/form/form.service';
import { BloomDefaultContentService } from './bloom-default-content.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';

const widgetMap = {
  label: Label,
  paragraph: Paragraph,
  textinput: TextInput,
  button: Button,
  table: Table,
  select: Select,
  separator: Separator,
  image: Image,
  imageinput: ImageInput,
  icon: Icon,
  checkbox: Checkbox,
  choice: Choice,
  card: Card,
  richtext: RichTextInput,
  slider: Slider,
  autocomplete: Autocomplete,
  textarea: TextArea,
  chips: Chips,
  numberinput: NumberInput,
  date: DateInput,
  time: Time,
  datetime: DateTime,
  duration: Duration,
  period: Period,
  star: Star,
  link: Link,
  embed: Embed,
  tags: Tags,
  connection: Connection
}

interface DataBindConfig {
  boxId: string,
  connectionId: string,
  boxObjectId: string,
  filters: any[],
  options: any[],
}

interface PageInfoBlock {
  pageMeta?: any;
  dataModel?: any;
  pageModel?: any;
  selectedLayout?: any;
  selectedRow?: any;
  selectedPanel?: any;
  selectedWidget?: any;
  currentPageCode?: string;
  dataModelSubValue?: any;
  pageModelSubValue?: any;
}

@Injectable({
  providedIn: 'root'
})
export class PageService {

  workspaceId: any;
  preAuthenticatedToken: any;
  pageMeta: any;
  dataModel: any = {}
  dataBindingURLs: any = [];
  dataBindingSetups: any = []
  isSubdomainBloom: boolean = false;
  injectionBufferDM: any;
  spinner: boolean = false;

  prevContextWidgetId: any;

  selectedLayout:any = null;
  selectedRow:any = null;

  hoveredLayout:any = null;
  hoveredRow:any = null;
  hoveredWidgetId:any;
  hoveredPanelId: any;

  isShowNewRowLineForLayout: any = null
  isShowRightLineForWidget: any = null
  isShowBottomLineForRow: any = null
  isShowTopLineForRow: any = null

  /**
   * the following variable acts as a connector for search panel and list panel
   * when search panel result will be injected into list panel for display
   */
  searchResultBuffer: any = new Subject()
  $searchResultBuffer: any = this.searchResultBuffer.asObservable()

  actionMode: string;


  customDataSource: any = new BehaviorSubject({})
  $customDataSource: any = this.customDataSource.asObservable();

  /**
   * acts as a connector from list panel to details/form panel
   * box information and filters for data fetching are pushed into this observable
   *
   * type: DataBindSetup
   */
  navigationData: any = new BehaviorSubject({})
  $navigationData: any = this.navigationData.asObservable()

  listToFormConnector: any = new BehaviorSubject({})
  $listToFormConnector: any = this.listToFormConnector.asObservable()

  listToDetailsConnector: any = new BehaviorSubject({})
  $listToDetailsConnector: any = this.listToDetailsConnector.asObservable()

  listToListConnector: any = new BehaviorSubject({})
  $listToListConnector: any = this.listToListConnector.asObservable()

  savePageRequest: any = new Subject()
  $savePageRequest: any = this.savePageRequest.asObservable()

  paginatorEventSearch: BehaviorSubject<any> = new BehaviorSubject('')
  $paginatorEventSearch: any = this.paginatorEventSearch.asObservable()

  pageModelSub: any = new BehaviorSubject('')
  $pageModelSub: any = this.pageModelSub.asObservable()

  dataModelSub: any = new BehaviorSubject('')
  $dataModelSub: any = this.dataModelSub.asObservable()

  currentPageCode: any = new BehaviorSubject('')
  $currentPageCode: any = this.currentPageCode.asObservable()

  widgetActionChange: any = new Subject()
  $widgetActionChange: any = this.widgetActionChange.asObservable()

  $panelMetaChange: any = new Subject()

  $2DResizedMeta: any = new Subject()

  footerInFocus: any = new BehaviorSubject<boolean>(false)
  $footerInFocus: any = this.footerInFocus.asObservable()

  pageChangeNotifier: any = new Subject()
  $pageChangeNotifier: any = this.pageChangeNotifier.asObservable()

  newWidgetDragMove: any = new Subject()
  $newWidgetDragMove: any = this.newWidgetDragMove.asObservable()

  newWidgetDragEnd: any = new Subject()
  $newWidgetDragEnd: any = this.newWidgetDragEnd.asObservable()



  pageModel = {}
  isPointerInCanvas: boolean;

  selectedPanel:any;
  selectedWidget:any;
  widgetSelected: boolean;
  panelSelected: boolean;
  copiedMeta: any;
  copiedType: any
  isToolCutted: boolean = false;
  copiedPanelId:any;
  cuttedPageMetaId: any;
  isPastedOnce: boolean = true;
  previousUrl: string;
  widgetReadyForDrag: boolean;
  selectedDragMap: { layoutId: any; rowId: any; panelId: any; widgetId: any; } = { layoutId: null, rowId: null, panelId: null, widgetId: null };
  isPrerendered: boolean;
;
  listPanelDataCache: any = {}  // { panelId => { data => any[], setAt => <timestamp> } }
  newlyCreatedWidgetMap: any = {};
  lastRecordedDragMovePosition: any

  recentlyUsedColorPalette = [
    "#C0CA33",
    "#42A5F5",
    "#455a64",
    "#c2185b",
    "#ab47bc",
    "#26a69a",
    "#00acc1",
  ];

  // dropListIdsSubject = new BehaviorSubject<string[]>([]);
  // allRowIds: any[] = []

  // direction : "top" || "bottom"
  // newIndex is index at which to create new panel
  dragNewPanelPlaceholder: {panelId?: string, direction?: string, newIndex: number} = {panelId: '', direction: '', newIndex: -1}


  savedPageContext: any = {
    pageMeta: undefined,
    dataModel: {},
    pageModel: {},
    selectedLayout: "",
    selectedRow: "",
    selectedPanel: "",
    selectedWidget: "",
    currentPageCode: "",  // observable
    dataModelSubValue: undefined, // observable
    pageModelSubValue: undefined // observable
  }

  panelReorderDragging: boolean = false

  constructor(
    private _dialog: MatDialog,
    private defaultContentService: BloomDefaultContentService,
    private metaService: MetaService,
    private formService: FormService,
    private adminService: AdminService,
    private authService: AuthServiceService,
  ) { }

  toolPasteRequest = new Subject<any>();
  toolPasteNotification = this.toolPasteRequest.asObservable()

  toolCutRequest = new Subject<any>();
  toolCutNotification = this.toolCutRequest.asObservable();

  createCopiedContentRequest = new Subject<any>();
  createCopiedContentNotification = this.createCopiedContentRequest.asObservable()


  // Observable to allow components to subscribe to the list of drop list IDs
  // getDropListIds(): Observable<string[]> {
  //   return this.dropListIdsSubject.asObservable();
  // }

  // Function to update the list of drop list IDs
  // setDropListIds(ids: string[], fromPanel: any): void {
  //   console.log("[rowIdAddition] setDropListIds request from", fromPanel)
  //   console.log("list before", JSON.parse(JSON.stringify(this.allRowIds)));
  //   ids.forEach(id => {
  //     this.allRowIds.push(id.toString());
  //     // if (!this.allRowIds.includes(id)) {
  //     // }
  //   })
  //   console.log("[rowIdAddition] allRowIds", JSON.parse(JSON.stringify(this.allRowIds)))
  //   this.dropListIdsSubject.next(this.allRowIds);
  // }


  isFooterInFocus(){
    return this.footerInFocus.value
  }

  /**
   * updates the recently used color palette with the new color
   * @param color : #hex format | 'transparent'
   */
  addToColorPalette(color: string){
    if(color == 'transparent') return
    if (!this.recentlyUsedColorPalette.includes(color)) {
      if (this.recentlyUsedColorPalette.length + 1 > 15) this.recentlyUsedColorPalette.pop()
    } else {
      let temp = this.recentlyUsedColorPalette.splice(this.recentlyUsedColorPalette.findIndex(c => c == color), 1)
    }
    this.recentlyUsedColorPalette.unshift(color)
  }

  // saves the current page related information
  saveContext(){
    this.savedPageContext = {
      pageMeta: this.pageMeta,
      dataModel: this.dataModel,
      pageModel: this.pageModel,
      selectedLayout: this.selectedLayout,
      selectedRow: this.selectedRow,
      selectedPanel: this.selectedPanel,
      selectedWidget: this.selectedWidget,
      currentPageCode: this.currentPageCode.value,  // observable
      dataModelSubValue: this.dataModelSub.value, // observable
      pageModelSubValue: this.pageModelSub.value // observable
    }
  }

  restoreContext() {
    if (this.savedPageContext) {
      this.pageMeta = this.savedPageContext.pageMeta;
      this.dataModel = this.savedPageContext.dataModel;
      this.pageModel = this.savedPageContext.pageModel;
      this.selectedLayout = this.savedPageContext.selectedLayout;
      this.selectedRow = this.savedPageContext.selectedRow;
      this.selectedPanel = this.savedPageContext.selectedPanel;
      this.selectedWidget = this.savedPageContext.selectedWidget;

      this.currentPageCode.next(this.savedPageContext.currentPageCode);
      this.dataModelSub.next(this.savedPageContext.dataModelSubValue);
      this.pageModelSub.next(this.savedPageContext.pageModelSubValue);
    }
    console.log("context restored: pageMeta", this.pageMeta, "code", this.currentPageCode.value)
  }

  setContext(context: Partial<PageInfoBlock>) {
    this.saveContext()
    console.log("context saved", this.savedPageContext)

    if (context.pageMeta !== undefined) this.pageMeta = context.pageMeta;
    if (context.dataModel !== undefined) this.dataModel = context.dataModel;
    if (context.pageModel !== undefined) this.pageModel = context.pageModel;
    if (context.selectedLayout !== undefined) this.selectedLayout = context.selectedLayout;
    if (context.selectedRow !== undefined) this.selectedRow = context.selectedRow;
    if (context.selectedPanel !== undefined) this.selectedPanel = context.selectedPanel;
    if (context.selectedWidget !== undefined) this.selectedWidget = context.selectedWidget;
    if (context.currentPageCode !== undefined) this.currentPageCode.next(context.currentPageCode);
    if (context.dataModelSubValue !== undefined) this.dataModelSub.next(context.dataModelSubValue);
    if (context.pageModelSubValue !== undefined) this.pageModelSub.next(context.pageModelSubValue);

    console.log("new context set, code", this.currentPageCode.value)
    console.log("new context set, meta", this.pageMeta)
  }

  // to be called before navigating to a new page
  resetContext() {
    this.pageMeta = null;
    this.dataModel = null;
    this.pageModel = null;
    this.selectedLayout = null;
    this.selectedRow = null;
    this.selectedPanel = null;
    this.selectedWidget = null;
    this.currentPageCode.next(null);
    this.dataModelSub.next(null);
    this.pageModelSub.next(null);
  }

  /**
   * The purpose of this function includes:
   *     1. Correcting deprecated properties/naming conventions in widget meta
   *     2. clearing the injected values of widgets in form and details panels and setting them to default
   * @param pageMeta
   * @returns pageMeta
   */
  dataCorrectPageMeta(pageMeta: any){
    // console.log("data correct hit: pageMeta", pageMeta)
    pageMeta?.panels?.forEach((panel, i) => {
      // console.log("panelMeta", panel)
      if(['regular', 'searchpanel', 'detailspanel', 'formpanel'].includes(panel.type)){
        panel.layoutMap?.list?.forEach(col => {
          panel.layoutMap[col]?.list?.forEach(row => {
            panel.layoutMap[col][row]?.elements?.forEach(wid => {
              // console.log("wid", wid)

              if(['select', 'input', 'textarea', 'connection', 'connection-list'].includes(wid.type)){
                // console.log("type matched", wid)
                if(wid.config.appearance){
                  if(['default', 'outlined', 'filled', 'rounded'].includes(wid.config.appearance?.value)){
                    wid.config.appearance.value = 'standard'
                  }
                  if(wid.config.appearance.availableTypes){
                    delete wid.config.appearance.availableTypes
                  }
                }else{
                  let newWid = WidgetManager.getWidget(wid.type)
                  wid.config.props.push('appearance')
                  wid.config['appearance'] = newWid.config.appearance
                }
              }
              if(['chips'].includes(wid.type)){
                if(!wid.config.inputMode || !wid.config.inputMode.hasOwnProperty('value')) return
                console.log("will correct widget meta chips", JSON.parse(JSON.stringify(wid)))
                let newWid = WidgetManager.getWidget("chips")
                wid.config.props = newWid.config.props // this will remove "inputMode" from props array and add "viewOnly"
                wid.config['viewOnly'] = newWid.config["viewOnly"] // assign viewOnly object
                if(wid.config.inputMode.value == true){
                  wid.config['viewOnly'].value = false
                } else {
                  wid.config['viewOnly'].value = true
                }
                delete wid.config["inputMode"]
              }
              if(['datetime', 'input'].includes(wid.type)){
                if(!wid.config.noEditable || !wid.config.noEditable.hasOwnProperty('value')) return
                console.log("will correct widget meta datetime", JSON.parse(JSON.stringify(wid)))
                let newWid = WidgetManager.getWidget("datetime")
                wid.config.props = newWid.config.props // this will remove "noEditable" from props array and add "viewOnly"
                wid.config['viewOnly'] = newWid.config["viewOnly"] // assign viewOnly object
                wid.config['viewOnly'].value = wid.config.noEditable.value
                delete wid.config["noEditable"]
              }
              // console.log("wid corrected", JSON.parse(JSON.stringify(wid)))

              let widgetConfig = WidgetManager.getWidget(wid.type);
              if(widgetConfig.config.hidden){
                if(!wid.config.hidden){
                  wid.config.hidden = widgetConfig.config.hidden;
                }
                if(wid.config.hidden && wid.config.props.indexOf("hidden") == -1){
                  wid.config.props.push("hidden");
                }
              }

              if(widgetConfig.config.required){
                if(!wid.config.required){
                  wid.config.required = widgetConfig.config.required;
                }
                if(wid.config.required && wid.config.props.indexOf("required") == -1){
                  wid.config.props.push("required");
                }
              }
            })
          })
        });
      }
    })
    return pageMeta
  }


  resetBloomData(){

    // this.pageMeta = null;
    // this.dataModel = {}
    this.prevContextWidgetId = null;
    this.selectedLayout = null;
    this.selectedRow = null;
    this.hoveredLayout= null;
    this.hoveredRow = null;
    this.actionMode = "";

    this.hoveredWidgetId = null;
    // this.pageModel = {}
    this.isPointerInCanvas = false;
    this.hoveredPanelId = null;

    this.selectedPanel = null;
    this.selectedWidget = null;
    this.widgetSelected = false;
    this.panelSelected = false;
    this.copiedMeta = null;
    this.copiedType = null;
    this.isToolCutted = false;
    this.copiedPanelId = null;
    this.cuttedPageMetaId = null;
    this.isPastedOnce = true;
    this.previousUrl = "";
    this.widgetReadyForDrag = false;
    this.selectedDragMap = { layoutId: null, rowId: null, panelId: null, widgetId: null };
    // this.createCopiedContentRequest.unsubscribe()
  }

  resetDragMap(){
    this.selectedDragMap = { layoutId: null, rowId: null, panelId: null, widgetId: null }
    this.hoveredLayout = null
    this.hoveredPanelId = null
    this.hoveredRow = null
    this.hoveredWidgetId = null
  }

  resetHoverStates(){
    this.hoveredLayout = null
    this.hoveredPanelId = null
    this.hoveredRow = null
    this.hoveredWidgetId = null
  }

  isDragging(){
    let isDragging = false
    Object.keys(this.selectedDragMap)?.forEach(k => {
      if(this.selectedDragMap[k]) isDragging = true
    })
    return isDragging
  }

  resetCopiedData(){
    this.copiedType = null;
    this.copiedMeta = null
  }

  copyWidget(){
    this.copiedType = "panel";
    this.copiedMeta = this.selectedPanel;

    console.log("this.widgetSelected", this.widgetSelected);
    console.log("this.panelSelected", this.panelSelected)
    if(this.widgetSelected) {
      this.copiedType = "widget";
      this.copiedMeta = this.selectedWidget;
      this.copiedPanelId = this.hoveredPanelId;
    } else if (!this.widgetSelected && this.panelSelected){
      this.copiedType = "panel";
      this.copiedMeta = this.selectedPanel;
    }
  }

  cutWidget(){
    this.isToolCutted = true;
    this.cuttedPageMetaId = this.pageMeta._id;
    this.copyWidget();
    let option = {
      copiedMeta: this.copiedMeta,
      copiedType: this.copiedType
    }
    this.toolCutRequest.next(option);
  }

  async pasteWidget(){
    if(!this.copiedMeta) return;
    let option = {
      hoveredPanelId: this.hoveredPanelId,
      copiedMeta: this.copiedMeta,
      copiedType: this.copiedType
    }
    this.toolPasteRequest.next(option);
  }

  async createCopiedContent(content, type?){
    let data = {
      content: content,
      type: type
    }
    this.createCopiedContentRequest.next(data);
  }

  clonePanel(panel){
    var layout = panel.layoutMap;
    var newLayout:any = JSON.parse(JSON.stringify(layout));
    newLayout.list = [];
    layout.list.forEach((e, i )=> {
      if(!newLayout.list) newLayout.list = [];
      let newId = new Date().setMilliseconds(new Date().getMilliseconds() + i).valueOf();
      newLayout.list.push(newId);
      newLayout[newId] = this.cloneLayoutColumn(layout[e]);
      delete newLayout[e]
    })
    panel.layoutMap = newLayout;
    return panel;
  }

  cloneLayoutColumn(columnMap){
    var newObj:any = JSON.parse(JSON.stringify(columnMap));
    newObj.list = [];
    columnMap.list.forEach((e, i )=> {
      if(!newObj.list) newObj.list = [];
      let newId = new Date().setMilliseconds(new Date().getMilliseconds() + i).valueOf();
      newObj.list.push(newId)
      newObj[newId] = columnMap[e];
      delete newObj[e];
    })
    return newObj;
  }

  widgetSelectedForDrag(e){
    this.widgetReadyForDrag = true;
    this.selectedDragMap = {
      layoutId: this.hoveredLayout,
      rowId: this.hoveredRow,
      panelId: this.hoveredPanelId,
      widgetId: this.hoveredWidgetId || e?.widgetId,
    }
    console.log("INIT DragMap", JSON.parse(JSON.stringify(this.selectedDragMap)))
  }

  widgetReleaseFromDrag(e){
    this.widgetReadyForDrag = false;
    // this.selectedDragMap = null
    this.resetDragMap()
  }

  getWidgetsFromPanel(panelMeta){
    let layoutMap = panelMeta.layoutMap;
    let widgets = [];
    if(!layoutMap) return widgets;
    layoutMap?.list?.forEach(layout => {
      layoutMap[layout]?.list?.forEach(row => {
        let rowMap = layoutMap[layout][row];
        if(rowMap?.type == 'elements' && rowMap?.elements?.length > 0) {
          widgets = widgets.concat(rowMap.elements);
        }
      });
    });

    return widgets;
  }

  /**
   * generates a data model based on connections information in pageMeta
   * structure of dataModel given above
   * @param pageMeta
   */
  generateDataModel(pageMeta: any) {
    // console.log("genrate data model called with page meta", pageMeta)
    if (!pageMeta.panels?.length) {
      this.dataModel = {}
      return
    }
    //flush the data model if creating for different page
    if (this.dataModel._pageId !== pageMeta.id) {
      this.dataModel = {}
    } else if (Object.keys(this.dataModel).length) {
      //if already exists, dont generate afresh. append should be called
      return
    }
    this.dataModel = {}
    this.dataModel['_pageId'] = pageMeta._id
    pageMeta.panels.forEach(panelMeta => {
      if (panelMeta.type == 'regular') {
        let widgets = this.getWidgetsFromPanel(panelMeta);
        widgets.forEach(widget => {
          if (widget.dataBindConfig) {
            let connId = widget.dataBindConfig.connectionId
            let boxObjectId = widget.dataBindConfig.boxObjectId
            let boxAttributeId = widget.dataBindConfig.boxAttributeId

            if (!this.dataModel[connId]) {
              this.saveConnectionInDM(connId)
            }
            if (!this.dataModel[connId][boxObjectId]) {
              this.saveBoxObjectInDM(connId, boxObjectId)
            }
            if (!this.dataModel[connId][boxObjectId][boxAttributeId]) {
              this.saveAttributeInDM(connId, boxObjectId, {
                __id: boxAttributeId
              })
            }
          }
        });
      }else if(panelMeta.type == 'detailspanel'){
        this.saveConnectionInDM(panelMeta.connectionId)
        this.saveBoxObjectInDM(panelMeta.connectionId, panelMeta.boxObjectId)

        panelMeta.detailsAttributes.forEach(attr => {
          this.saveAttributeInDM(panelMeta.connectionId, panelMeta.boxObjectId, {
            __id: attr.__id,
            dataType: attr.dataType,
          })
        });
      }
    });
    this.dataModelSub.next(this.dataModel)
    // console.log("data model generated", this.dataModel)
  }

  getDataModel(pageMeta: any) {
    let dataModel = {}
    pageMeta.panels.forEach(panelMeta => {
      if (panelMeta.type == 'regular') {
        let widgets = this.getWidgetsFromPanel(panelMeta);
        widgets.forEach(widget => {
          if (widget.dataBindConfig) {
            let connId = widget.dataBindConfig.connectionId
            let boxObjectId = widget.dataBindConfig.boxObjectId
            let boxAttributeId = widget.dataBindConfig.boxAttributeId

            if (!dataModel[connId]) {
              dataModel[connId] = {}
              dataModel[connId][boxObjectId] = {}
              dataModel[connId][boxObjectId][boxAttributeId] = {
                value: '',
                dataType: 'string'
              }
            } else if (!dataModel[connId][boxObjectId]) {
              dataModel[connId][boxObjectId] = {}
              dataModel[connId][boxObjectId][boxAttributeId] = {
                value: '',
                dataType: 'string'
              }
            } else if (!dataModel[connId][boxObjectId][boxAttributeId]) {
              dataModel[connId][boxObjectId][boxAttributeId] = {
                value: '',
                dataType: 'string'
              }
            }
          }
        });
      }
    });
    console.log("data model ready", dataModel)
    return dataModel
  }

  /**
   *
   * @param connId
   * @param boxObject
   * @param data: array of objects
   * {
   *    attrId1: { __id: string, dataType?: string, value?: any},
   *    attrId2: { __id: string, dataType?: string, value?: any},
   *    attrId3: { __id: string, dataType?: string, value?: any},
   * }
   */
  injectDataModel(connId: string, boxObject: string, data: any[]) {
    if(!this.dataModel[connId]) this.saveConnectionInDM(connId)
    if(!this.dataModel[connId][boxObject]) this.saveBoxObjectInDM(connId, boxObject)

    if (this.dataModel[connId] && this.dataModel[connId][boxObject]) {
      data.forEach((attr: any) => {
        this.saveAttributeInDM(connId, boxObject, {__id: attr.__id, dataType: attr.dataType, value: attr.value})
      })
      console.log("data model injected", this.dataModel)
      this.dataModelSub.next(this.dataModel)
    }
  }

  /**
   * appends the dataModel to include connectionId if not already present
   * @param connectionId
   * @retuns NA.
   */
  saveConnectionInDM(connectionId: string) {
    if(!this.dataModel.hasOwnProperty(connectionId) && !this.dataModel[connectionId]){
      this.dataModel[connectionId] = {}
    }
  }

  /**
   * appends the dataModel to include boxObjectId if not already present
   * if it already exists, no change
   * @param connectionId
   * @param boxObjectId
   * @param deep: [boolean] whether to create connectionId if not already exists
   * @retuns boolean
   */
  saveBoxObjectInDM(connectionId: any, boxObjectId: any, deep: boolean = true) {
    if (!this.dataModel.hasOwnProperty(connectionId)) {
      if(!deep) return false
      this.dataModel[connectionId] = {}
    }
    if(!this.dataModel[connectionId][boxObjectId]){
      this.dataModel[connectionId][boxObjectId] = {}
      return true
    }
  }

  /**
   * appends the dataModel to include attributes and its value (if provided)
   * @param connectionId
   * @param boxObjectId
   * @param attribute: [Object] {
   *    __id: string,
   *    dataType?: string,
   *    value?: any
   * }
   * @retuns NA. saves in dataModel corresponding to available keys, fianl structure should look like
   * connId -> boxObjectId -> attributeId : {...}
   * }
   */
  saveAttributeInDM(connectionId: any, boxObjectId: any, attribute: any, deep: boolean = true) {
    // console.log("attribute in saveAttributeInDM", attribute)
    this.saveConnectionInDM(connectionId)
    this.saveBoxObjectInDM(connectionId, boxObjectId)

    if(!this.dataModel[connectionId][boxObjectId][attribute.__id] || !Object.keys(this.dataModel[connectionId][boxObjectId][attribute.__id]).length){
      this.dataModel[connectionId][boxObjectId][attribute.__id] = {
        value: attribute.value || '',
        dataType: attribute.dataType || 'string'
      }
    }else{
      this.dataModel[connectionId][boxObjectId][attribute.__id]['value'] = attribute.value || ''
      this.dataModel[connectionId][boxObjectId][attribute.__id]['dataType'] = attribute.dataType || 'string'
    }
    return true
  }

  /**
   * scans through the current page meta and generates a page model
   * @param pageMeta
   * @returns page model object will have same hierarchy as panels and widgets
   */
  generatePageModel(pageMeta: any) {

    // console.log("[PAGE SERVICE] generatePageModel(): pageMeta:", pageMeta)
    pageMeta.panels?.forEach(panelMeta => {
      // console.log("loop:", panelMeta)
      if (panelMeta.layoutMap?.list?.length) {
        let panelId = panelMeta.id
        this.pageModel[panelId] = {}
        let widgets = this.getWidgetsFromPanel(panelMeta);
        widgets.forEach(widgetMeta => {
          // console.log("looping on", JSON.parse(JSON.stringify(widgetMeta || {error: true})))
          this.pageModel[panelId][widgetMeta.id] = {
            widgetId: widgetMeta.id,
            widgetName: widgetMeta.name,
            widgetType: widgetMeta.type,
            value: this.getWidgetValue(widgetMeta),
            validity: true,
            panelType: panelMeta.type
          }
        });
      }
    });
    this.pageModelSub.next(this.pageModel)
    console.log("pageModel generated", JSON.parse(JSON.stringify(this.pageModel)))
  }

  resetPageModel(){
    console.log("reset page model hit", JSON.parse(JSON.stringify(this.pageModel)))
    this.pageModel = {}
    this.pageModelSub.next(this.pageModel)
  }


  getWidgetValue(widgetMeta){
    console.log("getWidgetValue")
    let wid = WidgetManager.getWidget(widgetMeta.type)
    Object.keys(widgetMeta).forEach(prop => {
      wid[prop] = widgetMeta[prop]
    })

    let val = wid.getValue()
    // console.log("widget value of", widgetMeta.id, "is", val)
    return val || ""
  }

  //fired when value is changed somewhere and need to be reflected in page model
  updatePageModel(data: any) {
    // console.log("hit update page model", data)
    Object.keys(this.pageModel).forEach(panelId => {
      if (panelId == data.panelId) {
        Object.keys(this.pageModel[panelId]).forEach(widgetId => {
          if (widgetId == data.widgetId) {
            this.pageModel[panelId][widgetId].value = (data.value == '${reset}' ? '' : data.value)
            if(data.hasOwnProperty('validity')){
              this.pageModel[panelId][widgetId]['validity'] = data.validity
            }
          }
        })
      }
    })
    this.pageModelSub.next(this.pageModel)
    // console.log("pageModel updated", this.pageModel)
  }

  clearPanelFromPageModel(panelId){
    Object.keys(this.pageModel).forEach(pId => {
      if (pId == panelId) {
        delete this.pageModel[pId]
      }
    })
    this.pageModelSub.next(this.pageModel)
  }

  /**
   *
   * @param widgetData // {panelId, widgetId, widgetType, widgetName, value}
   * @returns
   */
  insertIntoPageModel(widgetData: any){
    // console.log("pageModel now", JSON.parse(JSON.stringify(this.pageModel)))
    // console.log("insertIntpPageModel hit", widgetData)
    if(!widgetData.panelId || !widgetData.widgetId) return
    if(!this.pageModel[widgetData.panelId] || !this.pageModel[widgetData.panelId]?.[widgetData.widgetId]){
      // console.log("will add new")
      // add new
      this.pageModel[widgetData.panelId] = this.pageModel[widgetData.panelId] || {}
      this.pageModel[widgetData.panelId][widgetData.widgetId] = widgetData

      this.pageModelSub.next(this.pageModel)
    }else{
      this.updatePageModel(widgetData)
    }
    // console.log("inserted in page model", this.pageModel)
  }

  /**
   * @param widgetType [string]
   * @param pageMeta
   * @param activePanelId [number] panelId | null
   * @returns appended pageMeta
   */
  async createPanel(widgetType: string, pageMeta: any, activePanelId: number, content?: any, allowCreateInForm?: boolean, defaultValue?: any, options?: any, insertAtIndex?: number | undefined ) {
    let hostPanel: any;
    let updatedPageMeta: any
    this.newlyCreatedWidgetMap = null;
    console.log("active panel", activePanelId)

    // console.log("allowCreateInFrom", allowCreateInForm)

    let createNewPanel = false;
    if (activePanelId ) { //&& content
      pageMeta.panels.forEach(async (panel) => {
        if ((panel.id == activePanelId)) {
          if(['searchpanel', 'listpanel'].includes(panel.type)){
            createNewPanel = true;
          }
          // else if (panel.type == 'formpanel' && !allowCreateInForm){
          //   createNewPanel = true
          // }
        }
      })
    }
    console.log("createNewPanel", createNewPanel)
    //DETERMINE IF THE HOST PANEL IS AN EXISTING PANEL OR A NEW PANEL
    //if a panel is active, search in panels array by id and add widget to it
    if (activePanelId && !createNewPanel) {
      for(let i=0; i<pageMeta.panels.length; i++){
        if (pageMeta.panels[i].id == activePanelId) {
          let widgetName = `${widgetType.split('|')?.[0] || 'widget'}_${pageMeta.panels[i].widgets.length + 1}`
          let widgetId = String(new Date().setSeconds(new Date().getSeconds() + (options?.addseconds || 0)).valueOf());
          console.log("new widget creation")
          let newWidget = WidgetManager.getWidget(widgetType, widgetId, widgetName)
          console.log("new widget created", newWidget)
          if(content) {
            if(newWidget.type == 'image'){
              this.spinner = true;
              let path = `bloom/image-input/${widgetId}`
              let file = await this.adminService.generateFileObject(content, path);
              console.log("filefile", file)
              content = await this.adminService.fileUpload(file);
              this.spinner = false;
            }
            newWidget.setValue(content, false);
          }

          if(['image', 'link', 'embed', 'chart', 'connection', 'connection-list'].includes(newWidget.type)) {
            let destinationMap = {
              hoveredLayout: this.hoveredLayout,
              hoveredRow: this.hoveredRow,
              hoveredPanelId: activePanelId,
              hoveredWidgetId: this.hoveredWidgetId,
            }
            destinationMap = JSON.parse(JSON.stringify(destinationMap));
            let res = await this.openDialog(newWidget.type, newWidget, content)
            console.log('[PAGE SERVICE] createPanel(): new widget res', res, this.widgetReadyForDrag);
            if (res) {
              if(this.widgetReadyForDrag){
                this.dropWidget(res, pageMeta, destinationMap);
              } else this.addWidgetToChoosenLayout(pageMeta.panels[i], this.hoveredWidgetId, res)

              this.insertIntoPageModel({
                panelId: pageMeta.panels[i].id,
                widgetId: res.id,
                widgetType: res.type,
                widgetName: res.name,
                value: this.getWidgetValue(res) || ''
              })
              this.newlyCreatedWidgetMap = {
                panel: pageMeta.panels[i],
                widget: res
              }
            }
            updatedPageMeta = pageMeta
          }else{
            if(this.widgetReadyForDrag){
              this.dropWidget(newWidget, pageMeta);
            } else this.addWidgetToChoosenLayout(pageMeta.panels[i], this.hoveredWidgetId, newWidget)
            this.insertIntoPageModel({
              panelId: pageMeta.panels[i].id,
              widgetId: newWidget.id,
              widgetType: newWidget.type,
              widgetName: newWidget.name,
              value: this.getWidgetValue(newWidget) || ''
            })
            this.newlyCreatedWidgetMap = {
              panel: pageMeta.panels[i],
              widget: newWidget
            }
            updatedPageMeta = pageMeta
          }
        }
      }
    } else {
      // Initalilize widget
      // console.log("no active panel, will create new", widgetType.split('|')?.[0])
      let widgetName = `${widgetType.split('|')?.[0] || 'widget'}_${1}`
      let widgetId =  String(new Date().setSeconds(new Date().getSeconds() + (options?.addseconds || 0)).valueOf());
      console.log("create panel: else")
      let newWidget = WidgetManager.getWidget(widgetType, widgetId, widgetName)
      if(content) {
        if(newWidget.type == 'image'){
          this.spinner = true;
          let path = `bloom/image-input/${widgetId}`
          let file = await this.adminService.generateFileObject(content, path);
          content = await this.adminService.fileUpload(file);
          this.spinner = false;
        }
        newWidget.setValue(content, false);
      }

      if(defaultValue){
        newWidget.setValue(defaultValue)
      }
      // console.log("checkign hostPanel.widgets", hostPanel.widgets)

      if(['image', 'link', 'embed', 'chart', 'connection', 'connection-list'].includes(newWidget.type) && (!content && !defaultValue)){
        let res = await this.openDialog(newWidget.type, JSON.parse(JSON.stringify(newWidget)))
        if (res) {
          // if no panel is active, create a new panel
          hostPanel = new Panel(Date.now(), 'panel_' + (pageMeta.panels.length + 1), res);
          this.insertIntoPageModel({
            panelId: hostPanel.id,
            widgetId: res.id,
            widgetType: res.type,
            widgetName: res.name,
            value: this.getWidgetValue(res) || ''
          })
          this.newlyCreatedWidgetMap = {
            panel: hostPanel,
            widget: res
          }

          // hostPanel.widgets.push(res);
          if(insertAtIndex){
            pageMeta.panels.splice(insertAtIndex, 0, hostPanel)
          } else {
            pageMeta['panels'].push(hostPanel);
          }
          updatedPageMeta = pageMeta
        }
      }else{
        hostPanel = new Panel(Date.now(), 'panel_' + (pageMeta.panels.length + 1), newWidget);

        this.insertIntoPageModel({
          panelId: hostPanel.id,
          widgetId: newWidget.id,
          widgetType: newWidget.type,
          widgetName: newWidget.name,
          value: this.getWidgetValue(newWidget) || ''
        })
        this.newlyCreatedWidgetMap = {
          panel: hostPanel,
          widget: newWidget
        }

        // hostPanel.widgets.push(newWidget);
        // pageMeta['panels'].push(hostPanel);
        if(insertAtIndex){
          pageMeta.panels.splice(insertAtIndex, 0, hostPanel)
        } else {
          pageMeta['panels'].push(hostPanel);
        }
        updatedPageMeta = pageMeta
      }
    }
    console.log("returning appended pageMeta", updatedPageMeta)
    return updatedPageMeta
  }

  dropWidget(widget, pageMeta, destinationMap:any = {}) {
    let hoveredLayout = this.hoveredLayout || destinationMap.hoveredLayout;
    let hoveredRow = this.hoveredRow || destinationMap.hoveredRow;
    let hoveredPanelId = this.hoveredPanelId && this.hoveredPanelId != -1 ? this.hoveredPanelId : destinationMap.hoveredPanelId;
    let hoveredWidgetId = this.hoveredWidgetId && this.hoveredWidgetId != -1 ? this.hoveredWidgetId : destinationMap.hoveredWidgetId;

    if(this.widgetReadyForDrag){

      if(hoveredPanelId){
        pageMeta.panels.forEach(panel => {
          if(panel.id == hoveredPanelId){
            let draggedWidget = widget; //delete origin place
            let layout;
            if(hoveredLayout) layout = panel.layoutMap[hoveredLayout]
            let gridXLeft = 0;
            let takenGridX = 0;
            if(hoveredLayout && hoveredRow){
              layout[hoveredRow]?.['elements']?.forEach(wid => {
                takenGridX += Number(wid?.gridX || 0)
              });
              gridXLeft = 12 - takenGridX;
            }

            if(hoveredLayout && (hoveredRow && gridXLeft > 0)){
              draggedWidget.gridX = gridXLeft;
              if(layout[hoveredRow]?.type != 'elements') return;
              let index = panel.layoutMap[hoveredLayout][hoveredRow]['elements'].findIndex(item => item.id === hoveredWidgetId);
              let indexToInsert = index + 1;
              panel.layoutMap[hoveredLayout][hoveredRow]['elements'].splice(indexToInsert, 0, draggedWidget);
            } else if (hoveredRow && hoveredRow != 1 && layout) {
              let rowId = new Date().valueOf();
              let elements = [widget];
              let index = layout['list'].findIndex(item => item === hoveredRow);
              if(index > 0){
                layout.list.splice(index + 1, 0, rowId);
              } else {
                layout.list.push(rowId);
              }
              layout[rowId] = {
                  type: "elements",
                  elements: elements
              }
            } else if (layout) {
              let rowId = new Date().valueOf();
              let elements = [draggedWidget];
              layout.list.push(rowId);
              layout[rowId] = {
                  type: "elements",
                  elements: elements
              }
            }
          }
        })
      }
    }

    this.widgetReadyForDrag = false;
  }


  addWidgetToChoosenLayout(panel, hoveredWidgetId, widget){
    // console.log("add widget to layout", panel, widget)
    let layoutMap = panel?.layoutMap;
    let layout = null

    let selectedRow = this.selectedRow;

    if(this.selectedLayout){
      layout = layoutMap?.[this.selectedLayout];
    } else {
      let layoutId = layoutMap.list[layoutMap.list.length - 1]
      layout = layoutMap?.[layoutId];
    }
    // console.log("hoveredWidgetId", hoveredWidgetId)
    // console.log("layout", layout)
    // console.log("this.selectedRow", this.selectedRow)
    // console.log("layout rpw", layout?.[this.selectedRow]);

    if(!layout) {
      let newLayoutId = new Date().setMilliseconds(new Date().getMilliseconds() + 1).valueOf();
      panel.layoutMap = {
        list: [newLayoutId],
        [newLayoutId]: {
          gridX: 12,
          list: []
        }
      }
      let layoutId = layoutMap.list[layoutMap.list.length - 1]
      layout = layoutMap?.[layoutId];
    }


    let gridXLeft = 0;
    let takenGridX = 0;

    let widgetRow;
    if(!this.selectedRow) {
      if(this.selectedWidget?.id){
        layout.list.forEach(i => {
          layout[i].elements.forEach(ele => {
            if(ele.id == this.selectedWidget.id) widgetRow = i;
          })
        });
      }
      selectedRow = widgetRow;
    }

    if(layout && selectedRow) {
      layout[selectedRow]?.['elements']?.forEach(wid => {
        takenGridX += Number(wid?.gridX || 0)
      });
      gridXLeft = 12 - takenGridX;
    }

    // console.log("gridXLeft",gridXLeft )
    // console.log("takenGridX", takenGridX)

    //if layout and row selected and grid size left
    if(layout && (selectedRow && gridXLeft > 0)){
      widget.gridX = gridXLeft;
      if(layout[selectedRow]?.type != 'elements') return;
      layout[selectedRow]?.['elements'].push(widget);
    } else if (layout) {
      let rowId = new Date().valueOf();
      // console.log("rowId", rowId)
      // console.log("layout", layout)
      let elements = [widget];

      let selectedRowIndex = layout.list.findIndex(id => id == selectedRow)
      if(selectedRowIndex > -1){
        layout.list.splice(selectedRowIndex + 1, 0, rowId) // insert after selected row index
      }else {
        if(panel.type == 'formpanel'){
          layout = this.insertBeforeSubmit(panel, rowId, layout)
        }else{
          layout.list.push(rowId);
        }
      }
      layout[rowId] = {
          type: "elements",
          elements: elements
      }
      // console.log("layout[this.selectedRow]", layout)
    }

  }


  /**
   * inserts into layout
   * @param panelMeta
   * @param rowIdToInsert
   * @param layout
   * @returns updated layout
   */
  insertBeforeSubmit(panelMeta, rowIdToInsert, layout){
    let submitRowIndex;
    panelMeta.layoutMap?.list?.forEach(layoutId => {
      panelMeta.layoutMap[layoutId]?.list?.forEach((rId, rowIndex) => {
        panelMeta.layoutMap[layoutId]?.[rId]?.elements?.forEach(wid => {
          let parts = wid.id.split("_")
          if(parts[parts.length - 1] == "submit"){
            submitRowIndex = rowIndex
          }
        })
      })
    })
    if(submitRowIndex){
      layout.list.splice(submitRowIndex, 0, rowIdToInsert)  // inserts before submit row
    }
    return layout
  }


  /**Widget Dialog*/
  async openDialog(widgetType: string, widget: any, content?: any) {
    if(content) return widget;
    let result: boolean = false;
    switch (widgetType) {
      case 'image':
      case 'link':
      case 'embed':
      case 'chart':
      case 'connection':
      case 'connection-list':
        let dialog = this._dialog.open(WidgetDialogComponent, {
          minWidth: '50vw',
          minHeight: '40vh',
          data: {
            widgetConfig: widget.config,
            widgetMeta: JSON.parse(JSON.stringify(widget))
            // minWidth: 600,
            // minHeight: 300
          }
        });
        let promise = dialog.afterClosed().toPromise();
        result = await promise.then(res => {
          if (res) {
            console.log(res)
            widget.config = res;
            return widget;
          }
          return res
        })
        break;

      default:
        return widget;
    }
    return result
  }

  /**
   * creates a widget and returns
   * @param widgetType type of the widget to create
   */
  createWidget(widgetType, value?: any, widgetName?: any) {
    if(!widgetName) widgetName = widgetType + '_0';
    let widget = new widgetMap[widgetType](Date.now().toString() + Math.random().toString(16).slice(8), widgetName)
    if(value){
      if (widgetType == 'label') {
        widget.config.labelText.value = value
      } else if (widgetType == 'image') {
        widget.config.src.value = value
      } else if (widgetType == 'link') {
        widget.config.linkUrl.value = value
        widget.config.linkText.value = value
      }
    }
    return widget
  }

  /**
   * creates a basic pageMeta
   * @param pageCode
   * @param pageName
   * @returns pageMeta
   */
  createPageMeta(pageCode: string, pageName: string, panels?: any, addDefaultContent: boolean = false) {
    let page = {
      //_id will be auto generated on insertion to DB
      code: pageCode,
      name: pageName,
      panels: panels || this.defaultContentService.getSecondaryPageDefaultContent(pageName).panels || [],
    };
    return page
  }


  /**
   * checks options to collect and returns the options array
   */
  checkOptionsToCollect(fn: any) {
    console.log("function", fn)
    let options: any[] = []
    if (!fn) return options
    if (
      fn.input?.list?.find(input => input == 'options')
      && Array.isArray(fn.input['options']['list'])
      && fn.input['options']['list'].length
    ) {
      console.log("options exists inside input")
      fn.input['options']['list'].forEach((optionItem: string) => {
        fn.input['options'][optionItem]['value'] = fn.input['options'][optionItem]['defaultValue'] || ''
        options.push(fn.input['options'][optionItem])
        // this.isOptionsToCollect = true
      });
      console.log("options to collect for function", fn.__id, options)
    }
    return options
  }

  getPageModel(){
    return this.pageModel
  }

  /**
   * injects the widget in the specified panel id in the current page
   * @param widgetMeta
   * @param panelId
   */
  updateWidgetInPage(widgetMeta, panelId){
    let isForm: boolean = false
    if(this.authService?.subproduct == "form") isForm = true
    console.log("panel id", panelId)


    // console.log("updateWidgetInPage called", widgetMeta)
    // console.log("metaService pageMeta", this.metaService.pageMeta.value)
    if(!panelId) return
    let pageMeta = isForm ? this.formService.formMeta.value : this.metaService.pageMeta.value;

    if(isForm){
      pageMeta = this.formService.selectedPage;
    }

    // console.log("pageMeta to update", pageMeta)
    let panelIndex = pageMeta.panels.findIndex(panel => panel.id == panelId)
    let panelMeta = pageMeta.panels[panelIndex]

    let matchedColId, matchedRowId, matchedElementIndex
    outerLoop: for (let i = 0; i < panelMeta?.layoutMap?.list?.length; i++) {
      let columnId = panelMeta.layoutMap.list[i]
      let column = panelMeta.layoutMap[columnId]
      // console.log("column", column)
      for (let j = 0; j < column?.list?.length; j++) {
        let rowId = column.list[j];
        let row = column[rowId]
        // console.log("row", row)
        for (let k = 0; k < row?.elements?.length; k++) {
          let element = row.elements[k];
          if(element?.id == widgetMeta.id){
            matchedColId = columnId
            matchedRowId = rowId
            matchedElementIndex = k
            // console.log("matched", element)
            break outerLoop
          }
        }
      }
    }

    if(matchedColId != undefined && matchedRowId != undefined && matchedElementIndex != undefined){
      panelMeta.layoutMap[matchedColId][matchedRowId].elements[matchedElementIndex] = JSON.parse(JSON.stringify(widgetMeta))
    }
    // panelMeta.layoutMap
    if(!isForm) this.pageMeta.panels[panelIndex] = panelMeta


    if(!isForm){
      this.metaService.pageMeta.next(pageMeta)
      this.metaService.userMadeChanges.next(true);
    }else{
      this.formService.formMeta.next(this.formService.formMeta.value)
      console.log("form pageMeta", JSON.parse(JSON.stringify(this.formService.formMeta.value)))
      this.formService.userMadeChanges.next(true);
    }
  }

}
