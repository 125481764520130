import { Injectable } from "@angular/core";
import { MetaService } from "src/app/bloom/services/meta-service";
import { BaseExpression } from "./BaseExpression";


@Injectable({
  providedIn: 'root',
})
export class BloomLoggedUserExpression extends BaseExpression {

  config: any = {}

  constructor(private metaService?: MetaService){
    super()
    this.config.id = '__bloomuseremail__';
    this.config.name = "Bloom Logged In email";
    this.config.description = "Returns bloom current logged in  email address";
    this.config.parameters = {
      paramIds: [],
    }
  }

  evaluateExpression(config?: any) {
    if(config?.id !== this.config.id) return ''
    let currentBloom = this.metaService?.bloomUserMap;
    console.log("current bloom meta", currentBloom)
    if(!currentBloom) currentBloom = {};
    return currentBloom.email || 'anonymous';
  }

  getBloomUser(config?: any){
    if(config?.id !== this.config.id) return ''
    let currentBloom = this.metaService?.bloomUserMap;
    console.log("current bloom meta", currentBloom)
    return currentBloom;
  }

  getExpressionDetail() {
    return this.config
  }
}
