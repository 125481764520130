<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<div >
  
  <div fxLayout="row wrap" fxLayoutAlign="center center">

    <div class="card-custom">
    <mat-card appearance="outlined" style="height: 95px;" [ngClass]="panelMap.searchPanel.checked ? 'card-color' : ''"> 
      <!-- <mat-card-content style="border: 1px solid red">
        
      </mat-card-content> -->
      <div fxLayout="row" fxLayoutAlign="start start" style="margin: -8px 0px -13px 0px;"> 
        <mat-checkbox [checked]="panelMap.searchPanel.checked" (change)="panelMap.searchPanel.checked = $event.checked" class="example-margin"></mat-checkbox> 
      </div>
      <div style="height: 50px;" fxLayout="row" class="panelCard" mat-stroked-button color="primary"
        fxLayoutAlign="center center">
          <mat-icon aria-hidden="false"  color="primary" aria-label="button icon">search</mat-icon>
      </div>
      <div fxLayout="row" class="panelCard" mat-stroked-button color="primary"
        fxLayoutAlign="center center">
          <span>Search Panel</span>
      </div>
    </mat-card>
    </div>

    <div class="card-custom">
    <mat-card appearance="outlined" style="height: 95px;" [ngClass]="panelMap.listPanel.checked ? 'card-color' : ''"> 
      <div fxLayout="row" fxLayoutAlign="start start" style="margin: -8px 0px -13px 0px;"> 
        <mat-checkbox [checked]="panelMap.listPanel.checked" (change)="panelMap.listPanel.checked = $event.checked" class="example-margin"></mat-checkbox> 
      </div>
      <div style="height: 50px;" fxLayout="row" class="panelCard" mat-stroked-button color="primary"
        fxLayoutAlign="center center">
          <mat-icon aria-hidden="false" color="primary" aria-label="button icon">format_list_bulleted</mat-icon>
      </div>
      <div fxLayout="row" class="panelCard" mat-stroked-button color="primary"
        fxLayoutAlign="center center">
          <span>List Panel</span>
      </div>
    </mat-card>
    </div>

    <div class="card-custom">
    <mat-card appearance="outlined" style="height: 95px;" [ngClass]="panelMap.detailsPanel.checked ? 'card-color' : ''"> 
        <div fxLayout="row" fxLayoutAlign="start start"  style="margin: -8px 0px -13px 0px;"> 
          <mat-checkbox [checked]="panelMap.detailsPanel.checked" (change)="panelMap.detailsPanel.checked = $event.checked" class="example-margin"></mat-checkbox> 
        </div>
        <div style="height: 50px;" fxLayout="row" class="panelCard" mat-stroked-button color="primary"
        fxLayoutAlign="center center">
          <mat-icon aria-hidden="false" color="primary" aria-label="button icon">article</mat-icon>
        </div>
        <div fxLayout="row" class="panelCard" mat-stroked-button color="primary"
          fxLayoutAlign="center center">
            <span>Details Panel</span>
        </div>
    </mat-card>
    </div>

    <div class="card-custom">
    <mat-card appearance="outlined" style="height: 95px;" [ngClass]="panelMap.formPanel.checked ? 'card-color' : ''"> 
      <div fxLayout="row" fxLayoutAlign="start start"  style="margin: -8px 0px -13px 0px;"> 
        <mat-checkbox [checked]="panelMap.formPanel.checked" (change)="panelMap.formPanel.checked = $event.checked" class="example-margin"></mat-checkbox> 
      </div>
      <div style="height: 50px;" fxLayout="row" class="panelCard" mat-stroked-button color="primary"
        fxLayoutAlign="center center">
          <mat-icon aria-hidden="false" color="primary" aria-label="button icon">assignment</mat-icon>
      </div>
      <div fxLayout="row" class="panelCard" mat-stroked-button color="primary"
        fxLayoutAlign="center center">
          <span>Form Panel</span>
      </div>
    </mat-card>
    </div>
  </div>

  <br/>

  <div fxLayout="row"  fxLayoutAlign="center center">
    <button style="cursor:pointer;margin-bottom: 0.5rem;" mat-raised-button color="primary" class="" (click)="createPanels()">
      <span>Create {{getButtonName(panelMap)}} {{getButtonName(panelMap).indexOf(',') > -1 ? 'Panels' : 'Panel'}} for {{data?.name}} Object</span>
    </button>
  </div>


</div>

