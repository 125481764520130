import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { FlowService } from '../../flow.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
// import {Location} from '@angular/common'

@Component({
  selector: 'flow-publish-dialog',
  templateUrl: './flow-publish-dialog.component.html',
  styleUrls: ['./flow-publish-dialog.scss']
})
export class FlowPublishDialogComponent implements OnInit {

  publishSuccess: boolean = false;
  flow: any;
  disabled: boolean = false
  publishSpinner: boolean = false
  publishDisabled: boolean = false
  ready: boolean = false;

  isPublishTemplate: boolean = false
  template_data: any;
  publishedFlow: any;
  newDraftId: any;
  isFirstPublish: boolean;
  boxEvents: any[] = []
  publishHit: boolean = false
  isPublishSupported: boolean = true
  isBrowser: any;
  isAllStepsValid: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FlowPublishDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private router: Router,
    private flowService: FlowService,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
  }

  ngOnInit(): void {
    // this.spinnerService.show()
    console.log("[publish dialog] flow", this.data.flow)
    // this.publishedUrl = '/form/p/' + this.data.form.code
    this.flow = this.data.flow

    if(!this.flow.code){  // for old flows that do not need publish
      this.isPublishSupported = false
    }else{
      this.boxEvents = this.data.boxEvents

      if(this.flow.published_flow_id){
        this.getPublishedFlow(this.flow)
      }else{
        this.isFirstPublish = true
        console.log("first publish made true", this.isFirstPublish)
        this.ready = true
      }
    }
    this.isAllStepsValid = this.stepsValidation();
  }

  stepsValidation(){
    let isValid = true;
    for (let index = 0; index < this.flow.steps.length; index++) {
      const element = this.flow.steps[index];
      this.flowService.validateStep(element, index)
      if(isValid && !this.flowService.stepOptionMap[index].valid) isValid = false;
    }
    return isValid;
  }

  async getPublishedFlow(flow){
    try{
      this.publishSpinner = true
      this.publishedFlow = await this.flowService.getFlow(flow.published_flow_id)
      console.log("published flow fetched", this.publishedFlow)

      if(this.publishedFlow?.template_status){
        this.template_data = this.publishedFlow.template_data
        if(this.publishedFlow.template_status == 'published'){
          this.isPublishTemplate = true
        }
      }

      this.publishSpinner = false
      this.ready = true
    }catch(e){
      console.error("published flow could not be fetched", e)
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  publishAsTemplateChanged(event){
    console.log("publishAsTemplateChanged", event)
    this.isPublishTemplate = event.checked
  }

  templateDataReceived(data){
    console.log("template_dataReceived", data)
    this.template_data = data
  }

  async publishClicked(){
    this.publishHit = true
    this.publishSuccess = false
    this.publishSpinner = true
    console.log("publish clicked", this.publishedFlow)
    console.log("template data", this.template_data)

    if (this.publishedFlow) {

      let toBePublished = JSON.parse(JSON.stringify(this.flow))

      if(toBePublished.trigger_type == 'schedule' && this.publishedFlow['trigger']?.['schedulers']?.[0]?.['job']){
        toBePublished['trigger']['schedulers'][0]['job'] = this.publishedFlow['trigger']['schedulers'][0]['job'];
      }
      toBePublished['_id'] = this.publishedFlow['_id']
      toBePublished['created_by'] = this.publishedFlow['created_by']
      toBePublished['modified_by'] = this.publishedFlow['modified_by']
      toBePublished['created_at'] = this.publishedFlow['created_at']
      delete toBePublished['published_flow_id']
      toBePublished['is_published'] = true
      toBePublished['isActive'] = this.publishedFlow.isActive

      console.log("new published", JSON.parse(JSON.stringify(toBePublished)))

      // this.publishedFlow['steps'] = JSON.parse(JSON.stringify(this.flow['steps']))
      toBePublished['published_at'] = new Date().toISOString() + "|date";
      toBePublished['modified_at'] = new Date().toISOString() + "|date";
      // this.publishedFlow['is_published'] = true;
      if(this.template_data && this.isPublishTemplate){
        toBePublished['template_data'] = this.template_data
        toBePublished['template_status'] = 'published';
        toBePublished['template'] = true;
      }else{
        delete toBePublished['template_status']
      }
      console.log("published flow ready to save", toBePublished)
      try{

        if(toBePublished.trigger_type == 'app_event') {
          toBePublished.trigger.webhook = await this.setupTrigger(toBePublished);
        }
        // if(this.publishedFlow.trigger_type == 'schedule') await this.scheduleJobs(this.publishedFlow);

        console.log("newpublished after scheduled", JSON.parse(JSON.stringify(toBePublished)))

        let response = await this.flowService.saveFlow(toBePublished)
        console.log("flow is published", response)
        console.log("draft is", this.flow)
        this.publishSuccess = true
      }catch(e){
        console.log("flow updates could not be published", e)
      }
    }

    this.publishSpinner = false
    this.disabled = false
    this.publishDisabled = false
    // this.publishSuccess = true

    let successMessage = this.flow.name + " is published"
    this._snackBar.open(successMessage, "", {duration: 1000 })
  }


  async setupTrigger(flow: any) {
    console.log("setup trigger hit", flow)
    // if(!flow.trigger.event) {
    //   this.snackBar.open("Choose app event.", "", {duration: 3000})
    //   throw new Error("Event not chosen")
    // }
    console.log('boxEvents', this.boxEvents)
    let event = this.boxEvents.find(
      (event) => event.__id == flow.trigger.event
    );
    console.log("event ---->: ", event)
    return await this.flowService.setupTrigger(flow, event, this.publishedFlow)
  }

  async scheduleJobs(flow: any){
    console.log("schedule jobs hit", flow)
    let schedulers = flow.trigger.schedulers;
    var data = {
      flowId: flow._id
    }
    console.log("schedulers", schedulers)
    for(var i = 0; i < schedulers.length; i++){
      var schedule = schedulers[i];
      console.log("schedule", schedule)
      var res = await this.flowService.scheduleJob(schedule, data);
      console.log("res-->", res)
      if(res && !schedule.job) {
        schedule.job = {};
        schedule.job._id = res._id;
      }
    }
  }

  navigateToExecutionPage() {
    if(this.flow.published_flow_id){  // for new published flows
      window.open(`/flow/instance/${this.publishedFlow._id}`, '_blank');
    }
  }

  cancel(){
    console.log("isFirstPublish", this.isFirstPublish)
    console.log("publish success", this.publishSuccess)
    // if(this.isFirstPublish && this.publishSuccess){
    //   // this.dialogRef.close({ navigateTo: this.newDraftId });
    //   console.log("will navigate to", this.newDraftId)
    //   this.location.replaceState(`/${this.newDraftId}`)
    // }else {
    // }
    this.dialogRef.close(false);
  }

}
