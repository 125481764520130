import { Panel } from "./basePanel";

interface listAttribute{
  attributeId: string,
  dataType: string,
  sortable: boolean,
}

export class ListPanel extends Panel{

  connectionId = ''
  boxName = ''
  boxId = ''
  boxObjectId = ''
  // listAttributes: listAttribute[] = []
  listAttributes: any = []
  aditionalAttributesAllowed: boolean = false
  pageSize = 20
  loadInitialData: boolean = false;

  constructor(id: number, name: string){
    super(id, name)
    this.type = 'listpanel'
  }
}
