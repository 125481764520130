import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor() { }

   /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(userToken){
    if(!environment.production) {
      mixpanel.init(environment.MIXPANEL_KEY, {debug: true});
    } else {
      mixpanel.init(environment.MIXPANEL_KEY);
    }
    mixpanel.identify(userToken);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action?: any){
    if(action) mixpanel?.track(id, action);
    else mixpanel?.track(id);
  }

  setPeople(properties: any = {}){
    mixpanel.people.set(properties);
  }
  
  //return mixpanel
  mixpanel(): typeof mixpanel{
    if(!environment.production) {
      mixpanel.init(environment.MIXPANEL_KEY, {debug: true});
    } else {
      mixpanel.init(environment.MIXPANEL_KEY);
    }
    return mixpanel
  }
}
