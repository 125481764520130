import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-page-delete-dialog',
  templateUrl: './page-delete-dialog.component.html',
  styleUrls: ['./page-delete-dialog.component.css']
})
export class PageDeleteDialogComponent implements OnInit {

  ngOnInit(): void {
  }

  constructor(
    public dialogRef: MatDialogRef<PageDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  delete(){
    this.dialogRef.close(true);
  }

  dontDelete(){
    this.dialogRef.close(false);
  }

}
