<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<div class="row" *ngIf="!isCreatePrivilage">
  <div class="column" style="margin: 10px">
    <mat-card appearance="outlined" fxLayout="column">
      <header fxLayoutAlign="start center" fxLayout="row" fxLayout-xs="column" fxLayoutGap="15px">
        <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="5px">
          <mat-icon [inline]="true" [ngStyle]="{'font-size': '75px'}">gavel</mat-icon>
          <h2>Privileges</h2>
        </div>

        <mat-form-field style="width: 70px;" >
            <mat-label>  </mat-label>
            <mat-select [(ngModel)] = "filterResType" (selectionChange)="listPrivilege()">
              <mat-option [value]="filter" *ngFor="let filter of filterResTypes">
                {{ filter }}</mat-option>
            </mat-select>
          </mat-form-field>
        <!-- <mat-form-field appearance="outline" style="min-width: 20%;width: 100%;" [fxHide.xs]="!searchMobile">
          <button mat-icon-button matSuffix>
            <mat-icon [ngClass.xs]="{'icon-button-small': true}">search
            </mat-icon>
          </button>

          <mat-label [ngStyle.xs]="{'font-size':'x-small'}">
            <span>&nbsp;&nbsp;Search</span>
          </mat-label>
          <input matInput [(ngModel)]="privilegeSearchField" fxFlexFill />
        </mat-form-field>
        <button mat-button color="primary" [fxShow.xs]="!searchMobile" fxHide (click)="searchMobile=!searchMobile">
          <mat-icon>search</mat-icon>SEARCH
        </button> -->
      </header>

      <div fxLayout="row" *ngIf="privileges.length > 0" >
        <div fxLayout="column" fxFlex="100">
            <div   *ngFor="let privilege of privileges;let i = index">
                <mat-card   appearance="outlined"   class="list-card" fxLayout="row wrap" 
                 layout-wrap fxLayoutAlign="start center" 
                   style="word-break: break-all;width: 100%" >
                <div fxLayout="column wrap" style="text-align: center;word-break: break-word;" fxFlex="5">
                    {{i + 1}}
                 </div>
                <div fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                    {{privilege.name}} 
                </div>
                <div fxFlex="25" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                    {{privilege.code}} 
                </div>
                <div fxFlex="20" fxLayoutAlign="center start" fxLayout="column wrap" style="word-break: break-word;">
                </div>
                <div matTooltip="Created by" fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                    {{privilege.created_by}} 
                </div>
                <div fxFlex="5" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                  <mat-icon style="margin: 0px;cursor: pointer;"  matTooltip="Edit role"  
                  (click)="editPrivilege(privilege)" *ngIf="resource_type == privilege.resource_type">
                  edit</mat-icon>
                </div>
                <div fxFlex="5" fxLayout="column wrap">
                  <mat-icon *ngIf="resource_type == privilege.resource_type" style="margin: 0px;cursor: pointer;"  matTooltip="Delete role"  
                  (click)="deletePrivilege(privilege)">
                  delete</mat-icon>
                </div>
              </mat-card>
            </div>
        </div>
      </div>

      <!-- <div fxLayout="end start" class="mat-elevation-z2">
        <mat-paginator style="width: 100%" [pageSizeOptions]="[5, 10, 20]" pageSize="20" #privilegePaginator>
        </mat-paginator>
      </div> -->
      <div fxLayout="end start" *ngIf="filterResType == 'Bloom'">
        <button mat-stroked-button color="primary" style="margin-top: 10px" >
          <mat-icon>add</mat-icon><span style="margin-top: 10px" (click)="isCreatePrivilage = true">Create</span>
        </button>
      </div>
    </mat-card>
  </div>
</div>

<div *ngIf="isCreatePrivilage" style="width: 100%;">
    <app-privilege-create [privilageConfig]="privilageConfig" [privilage]="" (listPrivilage)="listPrivilege()" > </app-privilege-create>
</div>
