import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { StarchComponent } from './starch.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { StarchBaseModule } from './starch-base/starch-base.module';
import { StarchRoutingModule } from './starch-routing.module';
import { DisplayBannerModule } from '../shared/display-banner/display-banner.module';
import { workspaceModule } from '../shared/workspace/workspace.module';
import { StarchCollaborationComponent } from './starch-collaboration/starch-collaboration.component';
import { CommonMemberModule } from '../shared/common-member/common-member.module';
import { ResourceCollaborationModule } from '../shared/resource-collaboration/resource-collaboration.module';
import { ListPanelModule } from '../bloom/specialized-panels/list-panel/list-panel.module';
import { StarchBaseManageComponent } from './starch-base-manage/starch-base-manage.component';
import { StarchAddBaseModule } from './starch-add-base/starch-add-base.module';

@NgModule({
  declarations: [StarchComponent, StarchCollaborationComponent, StarchBaseManageComponent],
  imports: [
    CommonModule,
    SharedModule,
    SpinnerModule,
    CdkTreeModule,
    StarchBaseModule,
    StarchRoutingModule,
    DisplayBannerModule,
    workspaceModule,
    CommonMemberModule,
    ResourceCollaborationModule,
    ListPanelModule,
    StarchAddBaseModule
  ],
  exports: [StarchComponent]
})
export class StarchModule { }
