import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";

export class Paragraph extends Widget {
  type = 'para';
  gridX = 12
  minGridX = 4
  config = {
    props: [
      "paraText",
      "alignment",
    ],
    paraText: {
      displayName: "Paragraph Text",
      value: "This is the default paragraph text. Double-click to change the text.",
      type: 'longtext'
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      type: 'app-alignment-settings'
    }
  }

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))

    // add widget specific text formatting if any

  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.paraText.value",
      effect: {
        styles: ["background-color"],
        "background-color": {
            id: "background-color",
            name: "Background Color",
            inputType: "color-palette"
        }
      }
    }
    map = this.mergeEffectStyle(map)
    return map;
  }
}
