import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DATE_FORMAT, TIME_FORMAT } from 'src/app/shared/date-time-format-config/datetimeFormats';
import { formatDate } from '@angular/common';


interface DateTimeFormatConfig {
  dateFormat: string,
  timeFormat: string
}

@Component({
  selector: 'date-time-format-config',
  templateUrl: './date-time-format-config.component.html',
  styleUrls: ['./date-time-format-config.component.css']
})
export class DateTimeFormatConfigComponent implements OnInit {

  /**
   * either widgetMeta or dateTimeFormat config will be passed
   * widgetMeta is passed when used in widget display config
   * dateTimeFormatConfig is passed when used from settings
   */
  @Input() widgetMeta;
  @Input() dateTimeFormatConfig: DateTimeFormatConfig;
  @Output() dateTimeFormatChanged: EventEmitter<any> = new EventEmitter();

  config: any = {}
  dateFormats: any = DATE_FORMAT
  timeFormats: any = TIME_FORMAT
  selectedDateFormat: string = ''
  selectedTimeFormat: string = ''
  selectedCustomDateFormat: string = ''
  selectedCustomTimeFormat: string = ''
  displayDate: any = ''
  displayTime: any = ''
  customDisplayDate: any = ''
  customDisplayTime: any = ''

  isEnabled: boolean = false
  isCustomDate: boolean = false
  isCustomTime: boolean = false
  isSeparatePreview: boolean = false

  constructor(
    
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.widgetMeta?.currentValue){
      console.log("widgetMeta received in date-time-format-config", this.widgetMeta)
      this.config = this.widgetMeta?.config

      this.isEnabled = this.widgetMeta.config.customOutputFormat.enabled
      this.isCustomDate = this.widgetMeta.config.customOutputFormat.customDate
      this.isCustomTime = this.widgetMeta.config.customOutputFormat.customTime

      if(['date', 'datetime', 'time'].includes(this.widgetMeta.type)){
        this.initCustomOutputFormat()
      }
    } else if(changes.dateTimeFormatConfig?.currentValue) {
      this.isEnabled = true
      this.isCustomDate = true
      this.isCustomTime = true
      this.isSeparatePreview = true
      this.initCustomOutputFormat()
    }
  }

  initCustomOutputFormat(){
    if(!this.isEnabled) return
    if(this.isCustomDate){
      this.selectedDateFormat = this.widgetMeta?.config?.customOutputFormat?.customDateFormat || this.dateTimeFormatConfig.dateFormat
      if(!this.dateFormats.availableFormats.find(format => format == this.selectedDateFormat)){
        this.selectedCustomDateFormat = JSON.parse(JSON.stringify(this.selectedDateFormat))
        this.selectedDateFormat = 'custom'
      }
      this.dateformatSelected(this.selectedDateFormat)
    }
    if(this.isCustomTime){
      this.selectedTimeFormat = this.widgetMeta?.config?.customOutputFormat?.customTimeFormat || this.dateTimeFormatConfig.timeFormat
      if(!this.timeFormats.availableFormats.find(format => format == this.selectedTimeFormat)){
        this.selectedCustomTimeFormat = JSON.parse(JSON.stringify(this.selectedTimeFormat))
        this.selectedTimeFormat = 'custom'
      }
      this.timeformatSelected(this.selectedTimeFormat)
    }
    this.emitOutput()
  }

  dateformatSelected(event?: any) {
    console.log("EVENT dateFormatSelected : ",event);
    this.selectedDateFormat = event
    if (this.selectedDateFormat != 'custom' ){
      this.selectedCustomDateFormat = undefined;
      var currDate = new Date();
      this.displayDate = formatDate(currDate, this.selectedDateFormat, 'en');
      console.log("DATE IS IN FORMAT : ",this.selectedDateFormat, "TODAY'S DATE : ",this.displayDate);
      // this.widgetMeta.config.customOutputFormat.customDateFormat = this.selectedDateFormat
    } else{
      this.displayDate = undefined;
      this.selectedCustomDateFormat = this.selectedCustomDateFormat || 'MM-dd-YYYY';
      this.customDisplayDate = formatDate(new Date(), this.selectedCustomDateFormat, 'en');
    }
    this.emitOutput()
  }

  customDateformatSelected(event: any) {
    console.log(this.selectedCustomDateFormat);
    console.log("EVENT customDateFormatSelected : ",event);
    var currDate = new Date();
    this.customDisplayDate = formatDate(currDate, this.selectedCustomDateFormat, 'en');
    console.log("DATE IS IN FORMAT : ",this.selectedCustomDateFormat, "TODAY'S DATE : ",this.customDisplayDate);
    // this.widgetMeta.config.customOutputFormat.customDateFormat = this.selectedCustomDateFormat
    this.emitOutput()
  }

  clearCustomDate() {
    this.selectedCustomDateFormat = undefined;
    this.selectedDateFormat = this.dateFormats.availableFormats.find(format => this.dateFormats.availableFormats[format].default);
    this.displayDate = formatDate(new Date(), this.selectedDateFormat || 'MM-dd-YYYY', 'en');
    console.log(this.displayDate);
    this.emitOutput()
  }

  timeformatSelected(event?: any) {
    console.log("EVENT dateFormatSelected : ",event);
    this.selectedTimeFormat = event
    if (this.selectedTimeFormat != 'custom' ){
      this.selectedCustomTimeFormat = undefined;
      var currDate = new Date();
      this.displayTime = formatDate(currDate, this.selectedTimeFormat, 'en');
      console.log("DATE IS IN FORMAT : ",this.selectedTimeFormat, "TODAY'S DATE : ",this.displayTime);
      // this.widgetMeta.config.customOutputFormat.customTimeFormat = this.selectedTimeFormat
    } else{
      this.displayTime = undefined;
      this.selectedCustomTimeFormat = this.selectedCustomTimeFormat ? this.selectedCustomTimeFormat : 'HH:mm';
      this.customDisplayTime = formatDate(new Date(), this.selectedCustomTimeFormat, 'en');
    }
    this.emitOutput()
  }

  customTimeformatSelected(event: any) {
    console.log(this.selectedCustomTimeFormat);
    console.log("EVENT customDateFormatSelected : ",event);
    var currDate = new Date();
    this.customDisplayTime = formatDate(currDate, this.selectedCustomTimeFormat, 'en');
    console.log("DATE IS IN FORMAT : ",this.selectedCustomTimeFormat, "TODAY'S DATE : ",this.customDisplayTime);
    // this.widgetMeta.config.customOutputFormat.customTimeFormat = this.selectedCustomTimeFormat
    this.emitOutput()
  }

  clearCustomTime() {
    this.selectedCustomTimeFormat = undefined;
    this.selectedTimeFormat = this.timeFormats.availableFormats.find(format => this.timeFormats.availableFormats[format].default);
    this.displayTime = formatDate(new Date(), this.selectedTimeFormat || 'HH:mm', 'en');
    console.log(this.displayTime);
    this.emitOutput()
  }

  isEnabledChanged(enabled: any){
    this.isEnabled = enabled
    if(this.widgetMeta){
      this.widgetMeta.config.customOutputFormat.enabled = this.isEnabled
    }
  }

  emitOutput(){
    if(this.widgetMeta){
      this.widgetMeta.config.customOutputFormat.customDateFormat = this.selectedDateFormat == 'custom' ? this.selectedCustomDateFormat : this.selectedDateFormat
      this.widgetMeta.config.customOutputFormat.customTimeFormat = this.selectedTimeFormat == 'custom' ? this.selectedCustomTimeFormat : this.selectedTimeFormat

      this.dateTimeFormatChanged.emit(this.widgetMeta)
    } else if (this.dateTimeFormatConfig){
      this.dateTimeFormatChanged.emit({
        dateFormat: this.selectedDateFormat == 'custom' ? this.selectedCustomDateFormat : this.selectedDateFormat,
        timeFormat: this.selectedTimeFormat == 'custom' ? this.selectedCustomTimeFormat : this.selectedTimeFormat
      })
    }
  }

}
