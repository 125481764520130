<mat-spinner *ngIf="spinner" [diameter]="50" class="centerOfAll" color="primary"></mat-spinner>

<h2 *ngIf="!publishResponse" mat-dialog-title>{{ "Publish " + boxObject + " LIST" }}</h2>
<!-- <h2 *ngIf="publishResponse" mat-dialog-title>{{ "YOUR LIST IS PUBLISHED" }}</h2> -->
<mat-dialog-content class="mat-typography">
    <div>
        <h2 *ngIf="errorResponse">{{ "Could not publish list" }}</h2>
        <div style="text-align:left;" *ngIf="errorResponse && errorResponse.error">
            <h2>Result</h2>
            {{ errorResponse.error ? errorResponse.error : (errorResponse | json) }}
        </div>
    </div>
    

    <div *ngIf="!publishResponse" fxLayout="column" fxLayoutAlign="start start" style="width: 100%;">

        <mat-form-field appearance="outline" style="width: 100%; margin-top: .5rem">
            <mat-label>List name</mat-label>
            <input matInput [(ngModel)]="listName">
        </mat-form-field>
        <div fxHide.lt-md style="height: 2rem"></div>
        <div style="width: 100%; max-height: 300px;">
            <shared-parameter-inputs
                style="width: 100%;"
                [isSupportQuery]="true"
                [queryInputs]="{ 
                    subComponentsToShow: { 
                        filter: true, 
                        sort: true, 
                        attributes: true, 
                    },
                    retainSubComponentOutput: true,
                    recommendAttributes: true
                }"
                [action]="action"
                [getAttributesFunction]="dialogData.getAttributesFunction"
                (enableExecute)="triggerExecute($event)"
                (getAttributesResponse)="attributeRecevied($event)"
                (inputParameter)="inputRecevied($event)"
                [connection]="connection"
            ></shared-parameter-inputs>
        </div>
    </div>

    <div class="publishSuccessBlock" *ngIf="publishResponse"
     fxLayout="column" fxLayoutAlign="center stretch"
     fxLayout.xs="column" fxLayoutAlign.xs="center stretch">

    <div class="subSection" fxLayout="row" fxLayoutAlign="space-around center">
        <div class="left" fxLayout="row" fxLayoutAlign="flex-end center" fxFlex="15">
            <mat-icon class="successIcon">verified</mat-icon>
        </div>
        <div class="right successHeading" fxLayout="row" fxLayoutAlign="center center" fxFlex="70">
            {{ "List is published!" }}
        </div>
        <div fxFlex="15"></div>
    </div>

    <div fxHide.lt-md style="height: 1rem;"></div>

    <div class="subSection" fxLayout="row" fxLayoutAlign="center center"
         fxLayout.xs="column" fxLayoutAlign.xs="center stretch">
        <div class="blockTitle" fxFlex="30" fxLayout="row" fxLayoutAlign="center center">Published URL</div>
        <div class="publishedUrlBlock" fxFlex="70" fxLayout="row" fxLayoutAlign="space-between center"
             fxLayout.xs="column" fxLayoutAlign.xs="center center">
            <div class="left publishedUrlText" fxFlex="70" fxLayout="row" fxLayoutAlign="center center">
                {{ fullPublishedUrl }}
            </div>
            <div class="iconContainer" fxFlex="30" fxLayout="row" fxLayoutAlign="center center">
                <div class="iconBlock" fxFlex="50" fxLayout="row" fxLayoutAlign="center center">
                    <button mat-icon-button color="primary" matTooltip="Copy URL"
                            [cdkCopyToClipboard]="fullPublishedUrl"
                            (cdkCopyToClipboardCopied)="copySuccessful($event)"
                            class="copyIcon">
                        <mat-icon>content_copy</mat-icon>
                    </button>
                </div>
                <div class="iconBlock" fxFlex="50" fxLayout="row" fxLayoutAlign="center center">
                    <button mat-icon-button color="primary" matTooltip="View published list"
                            (click)="navigateToNewPublished()">
                        <mat-icon>open_in_new</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="closeDialog()" fxLayoutAlign="center center" mat-raised-button>
        <mat-icon>close</mat-icon>
        {{ (errorResponse || publishResponse) ? "Close" : "Cancel" }}
    </button>
    <button 
        *ngIf="enableExecute && !publishResponse" 
        [disabled]="!enablePublish || spinner"
        fxLayoutAlign="center center" mat-raised-button color="primary"
        (click)="publish()"
    >
        Publish
    </button>
    <!-- <button *ngIf="publishResponse" fxLayoutAlign="center center" color="primary" mat-raised-button (click)="checkOldPublished()">
        <mat-icon>list</mat-icon>
        All published lists
    </button> -->
    
</mat-dialog-actions>


