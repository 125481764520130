
<h2 mat-dialog-title>{{dataInput.title || 'New Bloom'}}</h2>

<mat-dialog-content>
    <div style="min-height: 100px;background-color: transparent;" fxLayout="row wrap" fxLayoutAlign="center center">
        <br/>
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <form [formGroup]="formGroup" >
                <div fxLayout="row" style="margin-top: 10px;" fxLayoutAlign="center center" >
                    
                    <div fxLayout="column" class="bloom-field" fxFlex="80">
                        
                        <mat-form-field appearance="outline" style="width: 80%;">
                            <mat-label> Bloom name </mat-label> 
                            <input required formControlName="name" matInput placeholder="Bloom name">
                            
                            <mat-error fxLayoutAlign="start center" style="color: brown" *ngIf="formGroup.controls.name.invalid && (formGroup.controls.name.dirty || formGroup.controls.name.touched)">
                                <div class="err-msg" *ngIf="formGroup.controls.name.errors?.required">Bloom name is required.</div>
                                <div class="err-msg" *ngIf="formGroup.controls.name.errors?.minlength">Bloom name must be at least 3 characters long.</div>
                            </mat-error>
                        </mat-form-field>
                        
                    </div>
                    <div fxLayout="column" fxFlex="20">
                        <!-- Name   [(ngModel)]="bloomMap.name" -->
                    </div>
                </div>
                <div fxLayout="row" style="margin-top: 10px;"  fxLayoutAlign="center center" >
                    <!-- <div fxLayout="column" fxFlex="40">
                        code (ngModelChange)="onChangeInput($event)"
                    </div> -->
                    <div fxLayout="column" class="bloom-field" fxFlex="80">
                        
                        <mat-form-field appearance="outline" style="width: 80%;"> 
                            <mat-label> Bloom code </mat-label>
                            <!-- <input (keyup)="onKeySearch($event)" -->
                            <input (keyup)="onKeySearch($event)" formControlName="code" id="code" name="code" required  matInput placeholder="Bloom code">
                            <mat-icon color="accent" matSuffix style="cursor:pointer;" (click)="checkAndGetRandomCode(bloomMap.code)">refresh</mat-icon>
                            <mat-hint *ngIf="!formGroup.controls.code.invalid && !isCodeExists">Bloom code can have lowercase letters, digits or hyphens. This can't be changed later.</mat-hint>
                            <mat-error fxLayoutAlign="start center" *ngIf="formGroup.controls.code.invalid && (formGroup.controls.code.dirty || formGroup.controls.code.touched)">
                                <div class="err-msg" *ngIf="formGroup.controls.code.errors?.minlength">
                                    Bloom code must be at least 6 characters long.
                                </div>
                                <div class="err-msg" *ngIf="formGroup.controls.code.errors?.required">
                                    Bloom code is required.
                                </div>
                                <div class="err-msg" *ngIf="formGroup.controls.code.errors?.pattern">
                                    Only lowercase letters, numbers and hyphens are allowed.
                                </div>
                            </mat-error>
                        </mat-form-field>
                        <div class="err-msg" *ngIf="isCodeExists">
                            Bloom code is not available.
                        </div>

                        <!-- <mat-error fxLayoutAlign="start center" style="color: brown" *ngIf="formGroup.controls.code.errors?.required && formGroup.controls.code.invalid && (formGroup.controls.code.dirty || formGroup.controls.code.touched)">
                            <mat-icon class="error-icon">error</mat-icon>Bloom code is required.
                        </mat-error>
                        <mat-error fxLayoutAlign="start center" style="color: brown" *ngIf="formGroup.controls.code.errors?.pattern && formGroup.controls.code.errors?.touched">
                            <mat-icon>error</mat-icon>Only alphabets, numbers and hyphens are allowed.
                        </mat-error> -->
                        
                    </div>
                    <mat-spinner  [diameter]="20" *ngIf="spinner"></mat-spinner>
                    <div fxLayout="column" fxFlex="20">
                    </div>
                </div>
                <!-- <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isCodeExists" >
                    <div style="color: brown" fxLayout="column" class="bloom-field" fxFlex="80">
                        <mat-icon class="error-icon">error</mat-icon> Bloom code is not available.
                    </div>
                    <div fxLayout="column" fxFlex="20">
                    </div>
                </div> -->
                <!-- <div fxLayout="row" fxLayoutAlign="center center" >
                    <div style="font-size: small;" fxLayout="column" class="bloom-field" fxFlex="80">
                        Bloom code can have lowercase letters, digits or hyphens. This can't be changed later.
                    </div>
                    <div fxLayout="column" fxFlex="20">
                    </div>
                </div> -->
            </form>
        </div>
    <div fxLayout="column" fxFlex="5"></div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button  [mat-dialog-close]="false" color="primary"  type="button">
        Cancel
    </button>
    <button mat-flat-button (click)="createBloom()" [disabled]="formGroup.invalid || spinner || isCodeExists"  color="primary" type="button">
        {{dataInput.buttonText || 'Create'}}
    </button>
</mat-dialog-actions>

