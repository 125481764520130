import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import  io from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class WebSocketService {
  private socket: any// SocketIOClient.Socket;
  public serverEvent$ = new Subject<any>();;

  constructor() {
  }

  setEvent(data: any){
    this.serverEvent$.next(data);
  }
}
