
<div class="header" [class.disabled] = "disabled" fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title>Publish configuration</div>
  <button #closeButton mat-button mat-dialog-close style="cursor: pointer;">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-spinner *ngIf="publishSpinner" diameter="50" [strokeWidth]="4" color="primary" style="margin: auto auto;"></mat-spinner>

<mat-dialog-content>
  <div class="wrap" [class.disabled] = "disabled" fxLayout="column" fxLayoutAlign="center center">

    <!-- <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFLex="50" class="subheading">Bloom Code</div>
      <div fxFlex="50" fxLayout fxLayoutAlign="flex-start center" class="content">{{ bloomMeta.code }}</div>
    </div> -->

    <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFLex="35" class="subheading">Published Bloom</div>
      <div fxFlex="65" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap=".5rem" class="content">
        <span fxFlex="30"><strong>{{ bloomMeta.latestVersion || bloomMeta.version || 'NA'}}</strong></span>
        <span fxFlex="30"><strong>{{ bloomMeta.code }}</strong></span>
        <a fxFlex="30" class="viewButton" href="{{bloomMeta.publishedUrl}}" [disabled]="!bloomMeta.publishedUrl" target="_blank" mat-stroked-button color="primary">
          <div fxLayout fxLayoutAlign="center center" fxLayoutGap=".5rem">
            <span><mat-icon fxLayout fxLayoutAlign="center center">visibility</mat-icon></span>
            <span>View</span>
          </div>
        </a>
      </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFLex="35" class="subheading">Bloom Name *</div>
      <div fxFlex="65">
        <mat-form-field style="width: 100%;">
          <input #nameInput class="content" matInput [(ngModel)]="bloomMeta.name" [required]="true" style="min-width: 100%;">
          <mat-error *ngIf="!nameInput.value" style="color: red;">
            Name can not be empty
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFLex="35" class="subheading">Bloom Description</div>
      <div fxFlex="65" fxLayout fxLayoutAlign="flex-start center">
        <mat-form-field fxFlexFill>
          <div fxLayout fxLayoutAlign="flex-start center" fxFlexFill>
            <textarea class="content" matInput [(ngModel)]="bloomMeta.description"></textarea>
          </div>
        </mat-form-field>
      </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFLex="35" class="subheading">Release Note</div>
      <div fxFlex="65" fxLayout fxLayoutAlign="flex-start center">
        <mat-form-field fxFlexFill>
          <div fxLayout fxLayoutAlign="flex-start center" fxFlexFill>
            <textarea class="content" matInput [(ngModel)]="releaseNote"></textarea>
          </div>
        </mat-form-field>
      </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFLex="35" class="subheading">Release Type</div>
      <div fxFlex="65" fxLayout fxLayoutAlign="flex-start center">
        <mat-radio-group (change)="releaseTypeChanged($event)" [value]="releaseType" color="primary">
          <mat-radio-button class="radioItem" value="major">Major</mat-radio-button>
          <mat-radio-button class="radioItem" value="minor">Minor</mat-radio-button>
          <mat-radio-button class="radioItem" value="patch">Patch</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="section" fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 1rem;">
      <div fxFLex="35" class="subheading"></div>
      <div class="c65tent" fxFlex="50" fxLayout fxLayoutAlign="flex-start center" fxLayoutGap=".5rem" style="color: #444; font-size: 1.1rem;"
      >
        <span><strong>{{ bloomMeta.name }}</strong></span>
        <span><strong>{{ '(v' + newVersion + ')' }}  </strong></span>
      </div>
    </div>

    <div style="height: 2rem;"></div>
    <fieldset class="templateStatusBlock" fxLayout="column" fxLayoutAlign="space-between start">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="45">
          <mat-checkbox
            class="subheading"
            color="primary"
            [checked]="bloomMeta.template"
            (change)="templatePublishCheckedEvent($event)"
          >
            <b>Publish as template</b>
          </mat-checkbox>
        </div>
        <div fxFlex="45" *ngIf="lastPublishedTemplate" fxLayout="row wrap" fxLayoutAlign="center center">
          <!-- <div style="font-style: italic;">Last published template&nbsp;:&nbsp;</div>
          <div>{{ lastPublishedTemplate }}</div> -->
        </div>
      </div>
      <div *ngIf="bloomMeta.template" style="width: 100%; box-sizing: border-box;">
        <app-collect-template-data
          [bloomMeta]="bloomMeta"
          (emitTemplateData)="templateDataReceived($event)"
        ></app-collect-template-data>
      </div>
    </fieldset>

    <div style="height: 2rem;"></div>
    <div class="publishStatusBlock" *ngIf="isPublishedUrl && !publishSuccess" fxLayout="column" fxLayoutAlign="center stretch" style="width: 100%">
      <div class="subSection" fxLayout fxLayoutAlign="space-around center">
        <div class="left" fxLayout fxLayoutAlign="flex-end center" fxFlex="15">
          <mat-icon class="successIcon">verified</mat-icon>
        </div>
        <div class="right successHeading" fxLayout fxLayoutAlign="center center" fxFlex="70">Latest Published Bloom</div>
        <div fxFlex="15"></div>
      </div>

      <div style="height: 1rem;"></div>
      <div class="subSection" fxLayout fxLayoutAlign="center center">
        <div class="publishedUrlBlock" fxFlex="100">
          <div class="left publushedUrlText" fxLayout fxLayoutAlign="center center" fxFlex="70">{{ currentBaseUrl + oldPublishedUrl }}</div>
          <div class="right copyIcon" fxLayout fxLayoutAlign="center center" fxFlex="15">
            <button mat-icon-button color="primary" matTooltip="Copy URL" [cdkCopyToClipboard]="currentBaseUrl + oldPublishedUrl" (cdkCopyToClipboardCopied)="copySuccessful($event)">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
          <div class="right navigateIcon" fxLayout fxLayoutAlign="center center" fxFlex="15">
            <button mat-icon-button color="primary" matTooltip="View published bloom" (click)="navigateToOldPublished()">
              <mat-icon>open_in_new</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div style="height: 2rem;"></div>
  <div class="publishSuccessBlock" *ngIf="publishSuccess" fxLayout="column" fxLayoutAlign="center stretch">

    <div class="subSection" fxLayout fxLayoutAlign="space-around center">
      <div class="left" fxLayout fxLayoutAlign="flex-end center" fxFlex="15">
        <mat-icon class="successIcon">verified</mat-icon>
      </div>
      <div class="right successHeading" fxLayout fxLayoutAlign="center center" fxFlex="70">Your bloom is successfully published.</div>
      <div fxFlex="15"></div>
    </div>

    <div style="height: 1rem;"></div>

    <div class="subSection" fxLayout fxLayoutAlign="center center">
      <div class="blockTitle" fxFlex="30" fxLayout fxLayoutAlign="center center">Published URL</div>
      <div class="publishedUrlBlock" fxFlex="70">
        <div class="left publushedUrlText" fxLayout fxLayoutAlign="center center" fxFlex="70">{{ currentBaseUrl + publishedUrl }}</div>
        <div class="right copyIcon" fxLayout fxLayoutAlign="center center" fxFlex="15">
          <button mat-icon-button color="primary" matTooltip="Copy URL" [cdkCopyToClipboard]="currentBaseUrl + publishedUrl" (cdkCopyToClipboardCopied)="copySuccessful($event)">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
        <div class="right navigateIcon" fxLayout fxLayoutAlign="center center" fxFlex="15">
          <button mat-icon-button color="primary" matTooltip="View published bloom" (click)="navigateToPublished()">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div style="height: 1rem;"></div>

    <div class="subSection" fxLayout fxLayoutAlign="center center">
      <div class="blockTitle" fxFlex="30" fxLayout fxLayoutAlign="center center">Draft URL</div>
      <div class="draftUrlBlock" fxFlex="70">
        <div class="left draftUrlText" fxLayout fxLayoutAlign="center center" fxFlex="70">{{ currentBaseUrl + '/p/' + bloomMeta.code + '?v=draft' }}</div>
        <div class="right copyIcon" fxLayout fxLayoutAlign="center center" fxFlex="15">
          <button mat-icon-button color="primary" matTooltip="Copy URL" [cdkCopyToClipboard]="currentBaseUrl + '/p/' + bloomMeta.code + '?v=draft'" (cdkCopyToClipboardCopied)="copySuccessful($event)">
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
        <div class="right navigateIcon" fxLayout fxLayoutAlign="center center" fxFlex="15">
          <button mat-icon-button color="primary" matTooltip="View draft bloom" (click)="navigateToDraft()">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </div>
      </div>
    </div>

  </div>
  <div style="height: 2rem;"></div>

</mat-dialog-content>


<mat-dialog-actions align="end" [class.disabled] = "disabled">
  <button mat-raised-button mat-dialog-close>{{ publishSuccess ? 'Close' : 'Cancel' }}</button>
  <button mat-raised-button color="primary" (click)="publishClicked()" [disabled]="publishDisabled || publishSuccess" cdkFocusInitial>Publish Now</button>
</mat-dialog-actions>
