export class ConnectionCache {

    static connectionObject:any = {};
    static setConnectionsCache(connections:any, isCheckToken: boolean = false){
      console.log("[CACHEDCON] set cons", connections)
      if(isCheckToken) connections = ConnectionCache.handleConnectionsToken(connections);
      if(connections.length){
            for(var i=0 ; i < connections.length; ++i ){
                let entity:any = connections[i]
                // let object = {}
                // const { __id,  name, code, status, connectorType, authentication_map, categories } = entity;
                // object =  { __id,  name, code, status, connectorType, authentication_map, categories }
                this.connectionObject[entity["_id"]] = entity;
            }
      }
      console.log("[CACHEDCON] after set", this.connectionObject)
    }

    static getCacheMap(): any {
      return this.connectionObject;
    }

    static handleConnectionsToken(cons: any[]){
      let result = [];
      if(cons.length){
        for(var i=0 ; i < cons.length; ++i ){
            let con:any = cons[i];
            result.push(con);
        }
      }
      return result;
    }

    static checkAndStoreTokenTTL(con){
      if(con?.authentication_map?.auth_type == "oauth2" && con?.authentication_map?.tokens?.token_map?.ttl){
        if(con.authentication_map.tokens.token_map.last_status == "REFRESHED"){
          con.authentication_map.tokens.token_map.next_refresh_time = new Date();
          con.authentication_map.tokens.token_map.is_refresh_called = false;
        }
      }
      return con;
    }

    static checkAndGetValidConnection(id) {
      let connection = this.connectionObject[id] || null;
      if (!connection) return;
      if (connection?.authentication_map?.auth_type == "oauth2") {

        if (connection?.box_config?.accesstoken && connection?.authentication_map?.tokens?.expires_in_time) {
          const expiresInTimeUTC = new Date(connection.authentication_map.tokens.expires_in_time);
          // const expiresInTimeLocal = new Date(expiresInTimeUTC.toLocaleString());
          // Directly compare the UTC time
          console.log("checkAndGetValidConnection", connection, expiresInTimeUTC);
          if (Date.now() < expiresInTimeUTC.getTime()) return connection;
        }
        return null;
      }
      return connection;
    }
}
