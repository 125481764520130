import { ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import * as e from 'cors';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PublishConfigComponent } from 'src/app/bloom/builder/publish-config/publish-config.component';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from '../services/auth-service.service';
import { SpinnerService } from '../spinner/spinner.service';
import { CreateBloomPopupComponent } from './create-bloom-popup/create-bloom-popup.component';
import { QoutaPopupComponent } from '../qouta-popup/qouta-popup.component';
import { SmsService } from '../services/sms-service.service';
import { BoxService } from '../services/box.service';
import { BoxService as BloomBoxService } from '../../bloom/services/box-service.service';
import { CloneService } from './clone.service';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { DeviceInfoService } from '../services/device-info.service';
import { BloomDefaultContentService } from '../../bloom/services/bloom-default-content.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { BloomContentService } from '../../bloom/bloom-content-service'

const uuid = require('uuid');

interface filterConfig {
  key: string,
  value: any,
  dataType: string
}


@Component({
  selector: 'app-create-bloom',
  templateUrl: './create-bloom.component.html',
  styleUrls: ['./create-bloom.component.scss']
})
export class CreateBloomComponent implements OnInit {

  spinner: boolean = false
  disabled: boolean = false
  creationSpinner: boolean = false
  createDisabled: boolean = false
  templateList: any[] = []
  templateBloomCount = 0
  fetchingTemplateSpinner: boolean = false
  loadingTemplateText = 'Collecting templates for you.....'
  loadingTemplateLetters: any[] = []
  templatesLoaded: boolean = false
  templateUnderPreview: any
  previewOpened: boolean = false
  connectionsUsed: any[] = []
  replacementMap: any = {};
  loadingConnectionsSpinner: boolean = false
  connectionsLoaded: boolean = false
  selectedTemplateIndex: number = -1
  isTemplateSelected: boolean = false
  error = false;
  authError: boolean = false
  templateSearchPanel: any
  templatePanel: any
  linear: boolean = true;
  pageSize: number = 10
  searchString: string = ''
  searchConfigs: filterConfig[] = []
  searchingTemplateSpinner: boolean = false;
  replacementConnections: any[] = []
  newBloomName: string = ''
  showTemplates: boolean = false
  createBlankSelected: boolean = false

  inputValue = new Subject<string>();
  trigger = this.inputValue.pipe(
    debounceTime(600),
    distinctUntilChanged()
  );
  currentBaseUrl: string
  stepperOptions: any = {
    step1: true,
    step2: false,
    step3: false,
    completed1: false,
    completed2: false,
    completed3: false
  }

  queryParams: any  // if template selection data comes from query params
  @ViewChild('stepper') stepper: MatStepper
  isBrowser: any;
  connectionSelected: boolean;

  constructor(
    // public dialogRef: MatDialogRef<PublishConfigComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private metaService: MetaService,
    private pageService: PageService,
    private authService: AuthServiceService,
    private connectionService: ConnectionService,
    private router: Router,
    private ngZone: NgZone,
    private spinnerService: SpinnerService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public smsService: SmsService,
    private boxService: BoxService,
    private bloomBoxService: BloomBoxService,
    private cloneService: CloneService,
    public deviceInfo: DeviceInfoService,
    private bloomDefaultContentService: BloomDefaultContentService,
    public BloomContentService: BloomContentService,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    this.spinnerService.show()

    this.loadingTemplateLetters = []
    for (let i = 0; i < this.loadingTemplateText.length; i++) {
      if (this.loadingTemplateText[i] == ' ') {
        for (let i = 0; i < 7; i++) {
          this.loadingTemplateLetters.push(' ')
        }
      } else {
        this.loadingTemplateLetters.push("" + this.loadingTemplateText[i] + "")
      }
    }
   }

  ngOnInit(): void {
    if(!this.isBrowser) return;
    if(this.deviceInfo.isSmallSize) return;
    this.currentBaseUrl = window.location.protocol + '//' + window.location.hostname
    this.currentBaseUrl = window.location.port ? this.currentBaseUrl + ":" + window.location.port : this.currentBaseUrl

    this.route.queryParams.subscribe(params => {
      console.log("query params", params)
      this.queryParams = params
    });

    if (!this.connectionService.selectedWorkSpace && !this.authService.loggedIn) {
      this.spinner = true;
      this.authService.authCheck()
      this.authService.authCheckPositive.subscribe(authStatus => {
        //if logged in
        if (authStatus) {
          console.log("logged in")
          this.initialize()
          this.spinner = false;
        } else {
          this.error = true;
          this.authError = true
          this.spinner = false;
          this._snackBar.open('Apologies, the login attempt failed. Please reload the page and try logging in again.', 'ok', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          // redirect to login page
          this.ngZone.run(() => {
            this.router.navigate(['../'])
          })

          return
        }
      })
    } else if (this.connectionService.selectedWorkSpace) {
      // this.getTemplateBlooms(1, this.pageSize, [])
      this.initialize()
    }

    this.trigger.subscribe(searchString => {
      console.log("in trigger", searchString)
      this.searchString = searchString
      this.searchByTemplateName()
    })
  }

  onStepChange(event: StepperSelectionEvent) {
    console.log("step event", event.selectedIndex)
    if (event.selectedIndex === 1) {
      console.log("step event", event);
      // this.formtemplateComponent?.initActions(this.templateActionMaps);
      this.stepperOptions.step1 = false;
      this.stepperOptions.step3 = false;
      this.stepperOptions.completed1 = false;
      this.stepperOptions.completed3 = false;
    } else if(event.selectedIndex === 0){
      this.stepperOptions.step2 = false;
      this.stepperOptions.step3 = false;
      this.stepperOptions.completed1 = false;
      this.stepperOptions.completed2 = false;
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    console.log("[AFTER VIEW INIT], stepper", this.stepper)
  }

  async initialize() {
    this.connectionService.getPreAuthenticatedToken();

    // get workspace id
    let workspaceId = await this.connectionService.getWorkSpaceId()
    console.log("workspace id received", workspaceId, "calling getBloomByWorkspace")

    if(Object.keys(this.queryParams).length){
      console.log("inside queryparams true")
      let bloomCode = this.queryParams['bloomCode']
      // let bloomVersion = this.queryParams['bloomVersion']
      let filters = [
        {key: 'code', value: bloomCode, dataType: 'string'},
        {key: 'templateStatus', value: 'published', dataType: 'string'}
        // {key: 'template', value: true, dataType: 'string'},
      ]
      // let templateList = await this.getTemplateBlooms(1, this.pageSize, filters) // pageSize should be 1
      // if(templateList[0]){
      //   this.templateSelected(templateList[0], 0, undefined)  // select the first template
      //   console.log("selected template bloom meta fetched", this.templateUnderPreview)
      //   console.log("steps in stepper", this.stepper.steps)
      //   this.stepper.selectedIndex = 1
      // }else{
      //   console.log("calling plain")
      //   this.getTemplateBlooms(1, this.pageSize, [])
      // }
    }else{
      console.log("else")
      let filters = [
        {key: 'templateStatus', value: 'published', dataType: 'string'}
      ]
      console.log("filters array before calling", filters)
      this.getTemplateBlooms(1, this.pageSize, filters)
    }
    this.templatePanel = this.BloomContentService.getTemplatesPanel()
    this.templateSearchPanel = this.BloomContentService.createSearchMeta()
    this.stepperOptions.step2 = false;
    this.stepperOptions.step3 = false;
    setTimeout(() => {
      console.log("this.stepper", this.stepper);
      this.stepper.selectedIndex = 1;
    }, 0);
  }

  async getTemplateBlooms(pageNumber: number, pageSize: number, filters: filterConfig[]){
    this.templatePanel = this.BloomContentService.getTemplatesPanel()
    this.templateSearchPanel = this.BloomContentService.createSearchMeta()
    // if(!this.templatesLoaded){
    //   this.fetchingTemplateSpinner = true
    // }else{
    //   this.searchingTemplateSpinner = true
    // }
    console.log("getTemplateBlooms filters", filters)
    let response: any
    try{
      response = await this.metaService.getTemplateBlooms(pageNumber, pageSize, filters)
      this.templateList = response.data
      this.templateBloomCount = response.totalCount
      this.fetchingTemplateSpinner = false
      console.log("template blooms fetched", this.templateList)
      this.templatesLoaded = true
    }catch(err){
      console.error("template blooms could not be fetched", err)
    }
    this.fetchingTemplateSpinner = false
    this.searchingTemplateSpinner = false
    return this.templateList
  }

  async createNewBloom() {
    let eligibility = this.smsService.checkEligibility("BLOOM");
    if(!eligibility.isEligible){
      let dialogRef = this.dialog.open(QoutaPopupComponent, {
            width: "500px",
            data: {
              type: "BLOOM"
            },
          });
      return ;
    }
    let randomCode: any;
    console.log('new bloom clicked');

    var dialog = this.dialog.open(CreateBloomPopupComponent, {
      minWidth: "60%",
      data: { randomCode: this.getRandomCode()}
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("do", diologResult)
    if(!diologResult){
      this.createBlankSelected = false
      return;
    } 

    let bloomNameMap = diologResult;
    this.spinner = true
    let defaultContent: any = this.bloomDefaultContentService.getDefaultPageContent(bloomNameMap.name)
    console.log("default content", defaultContent)
    let page = this.pageService.createPageMeta('homepage', 'homepage', defaultContent.panels)

    // create page in db
    let createResponse
    try{
      createResponse = await this.metaService.create(page);
    }catch(e){
      console.log("page creation failed", e)
      this.spinner = false
      this._snackBar.open("could not create page", "Ok");
      return
    }
    console.log('page created now', createResponse);
    let id = createResponse._id;
    let page_structure = {
      pages: ['homepage'],
      homepage: {
        code: 'homepage',
        name: 'homepage',
        id: id,
      },
      homePageCode: 'homepage',
    };

    // create page structure in db
    let response
    try{
      response = await this.metaService.create_page_structure(page_structure);
    }catch(e){
      console.log("page structure creation failed", e)
      this.spinner = false
      this._snackBar.open("could not create page structure", "Ok");
      return
    }
    console.log('page structure created', response);

    let bloom = this.createBloomMeta(response._id, bloomNameMap)

    console.log("bloom meta", bloom)

    let bloomCreationResponse
    try{
      bloomCreationResponse = await this.metaService.create_bloom(bloom);
    }catch(e){
      console.log("bloom creation failed", e)
      this.spinner = false
      this._snackBar.open("could not create bloom", "Ok");
      return
    }

    console.log('bloom created', bloomCreationResponse);
    this.spinner = false
    this.router.navigate(
      ['bloom/' + bloom.code + '/' + page_structure.homePageCode],
      {
        fragment: 'edit',
      }
    );
    // this.dialogRef.close({
    //   success: true,
    //   bloom: bloomCreationResponse
    // })
  }

  getRandomCode(){
    let randomCode = uuid.v4().substring(0, 8);
    if(!randomCode){
      randomCode = Date.now()
    }
    return randomCode;
  }

  createBloomMeta(pageStructureId, bloomNamMap?){
    let randomCode = String(Date.now()).substring(6);

    let logoUrl = this.getLogoUrl()
    console.log("logo url", logoUrl)

    console.log("random code", randomCode)
    let bloom = {
      code: bloomNamMap?.code || 'bloom-' + randomCode,
      name: bloomNamMap?.name || 'bloom-' + randomCode,
      created_at: new Date().toISOString(),
      modified_at: new Date().toISOString(),
      created_by: this.authService.profile.email,
      modified_by: this.authService.profile.email,
      page_structure_id: pageStructureId,
      workspace_id: this.connectionService.selectedWorkSpace,
      logoUrl: logoUrl,
      version: 'draft',
      access_type:  "private",
      require_login: true
    };
    return bloom
  }

  // generate random logo url
  getLogoUrl() {
    let randomLogoCode: any = 10 + Math.floor(Math.random() * 10)
    randomLogoCode = randomLogoCode + 1
    // if (randomLogoCode < 10) {
    //   randomLogoCode = '0' + randomLogoCode
    // }

    let logoUrl = environment.BLOOM_LOGO_BASE_URL + randomLogoCode + '.png'
    return logoUrl
  }

  templateSelected(template, i, stepper){
    this.templateUnderPreview = template
    this.selectedTemplateIndex = i
    this.isTemplateSelected = true
    console.log("template selected", this.templateUnderPreview)
    this.showTemplateDetails(stepper)
  }

  selectedRowData(e, stepper){
    this.connectionSelected = false;
    console.log(e, stepper)
    this.stepperOptions.step2 = false;
    this.templateSelected(e.rowDataRaw, e.i, stepper)
    this.stepperOptions.step2 = true;
    this.stepperOptions.step3 = false;
    this.stepperOptions.completed1 = true;
    this.stepperOptions.completed2 = false;
    this.stepper.next()
  }

  async showTemplateDetails(stepper: MatStepper){
    this.connectionsLoaded = false
    // this.templateUnderPreview = template
    this.previewOpened = true

    this.loadingConnectionsSpinner = true
    console.log("showing details for", this.templateUnderPreview)

    this.connectionsUsed = []
    let bloomConnections: any
    try{
      bloomConnections = await this.metaService.getBloomConnections(this.templateUnderPreview.page_structure_id)
      console.log("bloom connections fetched", bloomConnections)
      bloomConnections = bloomConnections.filter(bloomConn => bloomConn.connectionId)
    }catch(e){
      console.error("could not fetch bloom connections", e)
    }

    bloomConnections.forEach(async conn => {
      let box
      try{
        box = await this.boxService.getBox(conn.boxId, conn.connectionId)
        console.log("box fetched", box)
        if(!box) throw new Error("box not found")

        // todo: will remove later
        // if(box.__id == 'googlesheets') box['supports'].push('clone')

        if(box.supports.includes('clone')){
          conn['box'] = box
        }
      }catch(e){
        console.log("could not fetch box", e)
      }
    });

    this.loadingConnectionsSpinner = false
    this.connectionsUsed = bloomConnections
    this.connectionsLoaded = true

    console.log("opening details for template",  this.templateUnderPreview)
  }

  async searchByTemplateName(){
    let config = {
      key: 'name',
      value: this.searchString,
      dataType: 'string'
    }
    let i = this.searchConfigs.findIndex((conf: filterConfig) => conf.key == 'name')
    if(i >= 0){
      this.searchConfigs[i] = config
    }else{
      this.searchConfigs.push(config)
    }
    this.getTemplateBlooms(1, this.pageSize, this.searchConfigs)
  }

  onSearchInput(searchString){
    this.inputValue.next(searchString)
  }

  async paginateTemplates(pageEvent: PageEvent){
    console.log("page event", pageEvent)
    try{
      await this.getTemplateBlooms(pageEvent.pageIndex + 1, this.pageSize, [])
    }catch(e){
      console.log("error occurred", e)
    }
  }

  boxSelected(box, index){
    console.log("box selected for index", index, "box is:", box)
    this.replacementConnections[index] = box
    if(this.connectionsUsed[index].boxId !== box.box_id){
      console.log("box type mismatch")
      return
    }else{
      // this.connectionsUsed[index].replacmentId = box._id
      // console.log("connection data edited", this.connectionsUsed[index])
      let key = this.connectionsUsed[index].connectionId
      // let value = box._id
      this.replacementMap[key] = box
      console.log("value entered in replacement map", this.replacementMap)
    }
    this.connectionSelected = true;
    this.stepperOptions.step3 = true;
    this.stepperOptions.step2 = false;
    this.stepperOptions.completed2 = true;
    this.stepperOptions.completed3 = false;
    this.stepper.next()
    this.cdr.detectChanges();
  }

  boxSelectionError(error){
  }

  async cloneNow(){
    var dialog = this.dialog.open(CreateBloomPopupComponent, {
      minWidth: "60%",
      data: {
        title: "Clone Bloom",
        buttonText: "Clone"
      }
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("do", diologResult)
    if(!diologResult) return;
    console.log("clone now hit", this.connectionsUsed)

    // check and clone underlying spreadsheets/databases
    try{
      let res = await this.checkAndClone()
      console.log("clone result", res)
    }catch(e){
      console.log("clone failed", e)
      throw new Error("could not clone")
    }

    let response: any
    this.disabled = true
    this.spinner = true
    try{
      response = await this.metaService.replaceBloomConnections(
          this.replacementMap,
          this.templateUnderPreview,
          this.connectionService.selectedWorkSpace,
          this.authService.profile.email,
          diologResult.name,
          diologResult.code
        )
        this.disabled = false
        this.spinner = false

        console.log("cloned bloom is", response)
        // this.dialogRef.close({
        //   success: true,
        //   bloom: response
        // })
        this._snackBar.open("New bloom is created! Opening shortly", '', { duration: 3000 })
        // this.router.navigate(['bloom', response.code, 'homepage'], {fragment: 'edit'})

        this.openBloom(response.code, response.page_structure_id, false)
      }catch(e){
        this.disabled = false
        this.spinner = false
        console.error("could not replace connections", e)
    }
  }


  async checkAndClone(){
    this.connectionsUsed.forEach(async (conn, i) => {
      console.log("dealing conn", conn)
      console.log("replacement map", this.replacementMap)
      if(conn.box.supports.includes('clone')){
        // 1. fetch the clone function
        let cloneFun = await this.fetchCloneFuntion(conn.boxId, this.replacementMap[conn.connectionId]?.box_token)
        if(!cloneFun) throw new Error("clone function not found")

        // fetch source connection
        let sourceConnection = await this.connectionService.getConnection(conn.connectionId)
        console.log("source connection fetched", sourceConnection)

        // 3. check and replace templates for inputs
        let clonePayload = this.cloneService.createClonePayload(cloneFun, sourceConnection, this.replacementMap[conn.connectionId])
        console.log("clone fn payload", clonePayload)

        // 3. trigger clone operation
        let cloneRes
        try{
          cloneRes = await this.boxService.executeBoxFunction(this.replacementMap[conn.connectionId]._id, conn.boxId, 'clone', clonePayload)
          console.log("clone result", cloneRes)
        }catch(e){
          console.log("clone failed", e)
        }
      }
    })
  }

  async fetchCloneFuntion(boxId: string, connectionId: string){
    let functions = await this.bloomBoxService.getBoxFunctions(boxId, connectionId)
    let cloneFun = functions.find(fn => fn.__id == 'clone')
    console.log("clone function", cloneFun)
    return cloneFun
  }


  openBloom(bloomCode: any, pageStructureId: any, isCollaborated?: boolean) {
    this.spinner = true
    console.log('need to open bloom:', bloomCode);

    //get the page structure
    this.metaService.getPageStructure(pageStructureId)
      .then((pageStructure) => {
        let homePageCode = pageStructure['homePageCode'];
        this.spinner = false

        this.router.navigate(['bloom', bloomCode, homePageCode], {
          fragment: 'edit',
          queryParams: {
            collaborated_bloom: isCollaborated
          }
        });
      })
      .catch(err => {
        this.spinner = false
        console.log("error in getting page structure", err)
      })
  }

  openViewTemplate(event, i){
    event.stopPropagation()
    console.log("navigate to details page of", this.templateList[i])
    window.open(
      // `${this.currentBaseUrl}/p/bloom-templates/template-details?destination-page-code=template-details&_id=${this.templateList[i]._id}`,
      `${this.currentBaseUrl}/p/bloom-templates/template-details?_id=${this.templateList[i]._id}`,
      '_blank')
  }

  // onStepChange(event: StepperSelectionEvent) {
  //   if (event.selectedIndex === 1) {
  //     console.log("step event", event);
  //     this.formtemplateComponent?.initActions(this.templateActionMaps);
  //   } else if(event.selectedIndex === 0){
  //     this.stepperOptions.step2 = false;
  //     this.stepperOptions.step3 = false;
  //     this.stepperOptions.completed1 = false;
  //     this.stepperOptions.completed2 = false;
  //   }
  // }
}
