<head>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
</head>
<mat-accordion *ngIf="isError;else infoMessage">
  <mat-expansion-panel class="mat-elevation-z0"
    [ngClass]="{bgcolorblack: !isDarkTheme(), bgcolorwhite:isDarkTheme()}"
    (opened)="panelOpenState = true" (closed)="panelOpenState = false" expanded hideToggle>
    <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight" [expandedHeight]="customExpandedHeight">
      <mat-panel-title>

      </mat-panel-title>
      <mat-panel-description class="infoMessagecenter" fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
        <div *ngIf="data.iconname" fxLayout fxFlex="15" fxLayoutAlign="start start">
          <mat-icon>{{ data.iconname }}</mat-icon>
        </div>
        <div fxFlex="5" *ngIf="data.iconname"></div>
        <div [fxFlex]="data.iconname ? '80' : '100'">
          <span class="has-spoiler">{{data?.message||""}} <span class="spoiler-span">
              <details>
                <summary (click)="$event.stopPropagation();">
                </summary>
                <p>{{data.title}}: {{data.description}}</p>
              </details>
            </span>
          </span>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div [ngClass]="{errorMessageblack: !isDarkTheme(),errorMessagewhite:isDarkTheme()}">
      {{data?.recommendation||""}}
    </div>
  </mat-expansion-panel>
</mat-accordion>
<button class="iconButtonColor" *ngIf="snackBarRef.containerInstance.snackBarConfig.duration==undefined || !data?.duration"
  [ngClass]="{'icon-button-small-close': !panelOpenState,'icon-button-small-open':panelOpenState}" mat-icon-button
  (click)="snackBarRef.dismiss()">
  <mat-icon>close</mat-icon>
</button>
<ng-template #infoMessage>
  <div class="infoMessagecenter" fxLayout="row" fxLayoutGap="10px">
    <div>
      <mat-icon>{{ data.iconname }}</mat-icon>
    </div>
    <p>{{ data.message }}</p>
  </div>
</ng-template>
