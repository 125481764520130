import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ConnectionService } from '../../modules/organization/connection.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { FlowService } from "../../flow/flow.service";
import { FormService } from '../../form/form.service';
import { StarchService } from 'src/app/shared/services/starch.service';

interface snackBarValues {
  snackBarMessage: string;
  snackBarIcon: string;
  snackBarError: boolean;
  snackBarDuration: number;
}

/**
 * OUTPUT DATA WHEN CLOSING DIALOG BOX THROUGH dialogRef.close(<data>)
 * data : {
 *   resource: 'connection' | 'bloom' | 'Flow' | 'form' | 'Starch',
 *   deletionStatus: 'error' | 'success' | 'inprogress',
 *   error?: any  // error message/object if delete failed
 *   deletedRecord?: any  // deleted record if delete successful
 *   isLocalDelete?: boolean  // if local delete is allowed while API call in progress for UX
 *   recordToRevert?: any  // record to add back if delete failed
 * }
 */

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {
  snackBarObj: any;
  spinner:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public connectionService: ConnectionService,
    public metaService: MetaService,
    public fs: FlowService,
    public formService: FormService,
    public starchService: StarchService) {}

    
  ngOnInit(): void {
    console.log("delete confirm hit", this.data);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  delete(){
    this.dialogRef.close(true);
  }

  dontDelete(){
    this.dialogRef.close(false);
  }

}
