import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ConnectionService } from '../../organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, SEMICOLON, SPACE } from '@angular/cdk/keycodes';
import { MailService } from 'src/app/shared/services/mail.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from '../../admin/admin.service';

@Component({
  selector: 'app-workspace-invitation-diolog',
  templateUrl: './workspace-invitation-diolog.component.html',
  styleUrls: ['./workspace-invitation-diolog.component.scss']
})
export class WorkspaceInvitationDiologComponent implements OnInit {

  workspace: any;
  emailLength: number = 0;

  invitationMap: any = [
    {
      validEmails: [],
      role: ''
    }
  ]

  failedChipPasteInput: string;
  isValidEmail: boolean = true;
  role:any
  baseUrl: string= environment.BASE_URL;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, SPACE];
  availableRoles: any = [];
  emails:any = [];
  failedIndex: number;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dataInput: any,
    public dialog: MatDialogRef<WorkspaceInvitationDiologComponent>,
    public spinner: SpinnerService,
    public connectionService: ConnectionService,
    public authService: AuthServiceService,
    public mailService: MailService,
    public resourcePermissoinService: ResourcePermissionService,
    public snackBar: MatSnackBar,
    public adminService: AdminService
  ) { }

  async ngOnInit() {
    this.spinner.hide();
    if(this.dataInput.workspaceMap) {
      this.workspace = this.dataInput.workspaceMap;
      var payload = {
        filter: "resource_type=default|string,name%workspace;name%owner"
      }
    }

    var roles = await this.adminService.listRole(1, 500, this.connectionService.preAuthenticatedToken, payload)
    this.availableRoles = roles.data;
  }


  async sendWorkspaceInvitation(){
    this.spinner.show();
    let userProfile = this.authService.profile;
    let data = {
      profile: userProfile,
      workspace: this.workspace,
      acceptUrl: "",
      denyUrl: ""
    }
    let mailOptions = {
      fromEmail: "${profile.email}",
      toEmail: "",
      body: this.mailService.template("workspace_invitation"),
      subject: "Invitation to collaborate on ${workspace.name} - AppiWorks"
    }

    for(var j = 0; j < this.invitationMap.length; j++) {
      for(var i = 0; i < this.invitationMap[j].validEmails.length; i++){
        mailOptions.toEmail = this.invitationMap[j].validEmails[i];
        var userMap = await this.authService.getWorkspaceUser(mailOptions.toEmail);
        if(!userMap) userMap = await this.authService.createNewUserWithEmail(mailOptions.toEmail);

        let permissionMap = {
          user_id: userMap._id,
          workspace_id: this.workspace._id,
          resource_id: this.workspace._id,
          resource_type: "workspace",
          status: "Invited",
          created_by: this.authService.profile.email,
          modified_by: this.authService.profile.email,
          roles: this.invitationMap[j]?.role ? [ this.invitationMap[j]?.role ] : []
        }

        var permission = await this.resourcePermissoinService.checkAndCreatePermission(permissionMap);
        var url = `${this.baseUrl}/workspace/approval?p=${permission.permission._id}&u=${this.authService.profile._id}&e=workspace`

        console.log("permission", permission.permission)
        data.acceptUrl = `${url}&a=accept`;
        data.denyUrl = `${url}&a=reject`
        await this.mailService.sendSesEmail(mailOptions, data);
      }
    }

    this.snackBar.open("Invitations sent successfully")._dismissAfter(3000);
    this.spinner.hide();
    this.dialog.close();
  }


  addInvitation(){
    this.invitationMap.push(
      {
        validEmails: [],
        role: this.role
      }
    )
  }

  deleteInvitationRow(map : any){
    if(this.invitationMap.length > 1){
      var index = this.invitationMap.indexOf(map);
      if(map.validEmails.length){
        this.emailLength = this.emailLength - map.validEmails.length;
      }
      this.invitationMap.splice(index,1)
    }
  }

    /*paste values for domain and email
  splits the values by seperator and valid
  refer https://medium.com/@sriram_in/angular-material-chips-add-chip-on-paste-event-ebce64fcfabd
  @param event evet
  @param type access type
  */
  pasteChipValue(event: ClipboardEvent, validEmails: any): void {
    console.log("event", event)
    this.failedChipPasteInput = "";
    var failedInput = [];
    event.preventDefault();
    event.clipboardData
    .getData('Text')
    .split(/[\s,;:\n\t]+/) // seprates with comma,colon,semicolon,space,tab,new line
    // .split(/;|,|:|\n| /)
    .forEach(value => {
      if(value.trim()){
        let inputValue = value.trim();
          var isValid = CheckIsValidEmail(inputValue)
          if(!isValid) this.isValidEmail = isValid;
          if (isValid) {
            inputValue = inputValue.toLowerCase();
            validEmails.push(inputValue);
            // this.emails = this.validEmails.join(",");
          } else {
            failedInput.push(inputValue)
          }
        }
    })
    if(failedInput.length > 0) this.failedChipPasteInput = failedInput.join(",")
  }

  addChipValue(event: MatChipInputEvent, validEmails: any, index: number): void {
    this.failedChipPasteInput = "";
    const input = event.input;
    const value = event.value;
    console.log("event", event)
    // Add our value
    if ((value || '').trim()) {
      var inputValue = value.trim();
        var isValid = CheckIsValidEmail(inputValue)
        this.isValidEmail = isValid;
        if (isValid) {
          inputValue = inputValue.toLowerCase();
          validEmails.push(inputValue);
          this.emailLength++;
          // this.emails = this.validEmails.join(",");
          inputValue = "";
        }
        else{
          this.failedIndex=index
        }
    }
    // set input value
    if (input) {
      input.value = inputValue;
    }
  }

  removeChipValue(input: any, validEmails: any): void {
      const index = validEmails.indexOf(input);
      if (index >= 0) {
        validEmails.splice(index, 1);
        this.emailLength--;
        // this.emails = this.validEmails.join(",");
      }
  }

}

function CheckIsValidEmail(email) {
  var mailformat = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  return email.match(mailformat);
}
