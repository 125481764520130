import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  spinner: boolean = false;
  constructor() {
    this.generateLoaderConfigs()
  }

  //show spinner
  show() {
    this.spinner = true;
  }
  //hide spinner
  hide() {
    this.spinner = false;
  }
  
  private presets: { [key: string]: any[] } = {
    'page-loader': [
      { cols: 6, rows: 6, shape: "circle" },
      { cols: 18, rows: 1, shape: "rectangle" },
      { cols: 17, rows: 1, shape: "rectangle" },
      { cols: 16, rows: 1, shape: "rectangle" },
      { cols: 15, rows: 1, shape: "rectangle" },
      { cols: 14, rows: 1, shape: "rectangle" },
      { cols: 13, rows: 1, shape: "rectangle" },
      { cols: 24, rows: 1, shape: "rectangle" },
      { cols: 4, rows: 4, shape: "image" },
      { cols: 20, rows: 1, shape: "rectangle" },
      { cols: 20, rows: 1, shape: "rectangle" },
      { cols: 20, rows: 1, shape: "rectangle" },
      { cols: 20, rows: 1, shape: "rectangle" },
    ],
    'page-loader-2': [
      { cols: 24, rows: 2, shape: "spacer" },
      { cols: 2, rows: 6, shape: "spacer" },
      { cols: 6, rows: 6, shape: "image" },
      { cols: 1, rows: 6, shape: "spacer" },
      { cols: 13, rows: 1, shape: "rectangle" },
      { cols: 13, rows: 1, shape: "spacer" },
      { cols: 11, rows: 1, shape: "rectangle" },
      { cols: 11, rows: 1, shape: "spacer" },
      { cols: 9, rows: 1, shape: "rectangle" },
      { cols: 9, rows: 1, shape: "spacer" },
      { cols: 24, rows: 1, shape: "spacer" },
    ],
    'list-table-view-loader': [ 
      // repetitive structure, generated in function
    ],
    // 'list-card-view-loader': [
      
    // ],
    'form-loader': [
      { cols: 1, rows: 24, shape: "spacer" },
      { cols: 23, rows: 1, shape: "spacer" },
      { cols: 23, rows: 3, shape: "rectangle" },
      { cols: 6, rows: 1, shape: "rectangle" },
      { cols: 17, rows: 1, shape: "spacer" },
      { cols: 23, rows: 1, shape: "rectangle" },
      { cols: 4, rows: 1, shape: "rectangle" },
      { cols: 7, rows: 1, shape: "spacer" },
      { cols: 4, rows: 1, shape: "rectangle" },
      { cols: 8, rows: 1, shape: "spacer" },
      { cols: 10, rows: 1, shape: "rectangle" },
      { cols: 1, rows: 1, shape: "spacer" },
      { cols: 8, rows: 1, shape: "rectangle" },
      { cols: 5, rows: 1, shape: "rectangle" },
      { cols: 18, rows: 1, shape: "spacer" },
      { cols: 23, rows: 2, shape: "rectangle" },
      { cols: 9, rows: 1, shape: "rectangle" },
      { cols: 3, rows: 1, shape: "spacer" },
      { cols: 6, rows: 3, shape: "rectangle" },
      { cols: 9, rows: 1, shape: "rectangle" },
      { cols: 6, rows: 1, shape: "rectangle" },
      { cols: 17, rows: 1, shape: "spacer" },
      { cols: 23, rows: 1, shape: "rectangle" },
      { cols: 4, rows: 1, shape: "rectangle" },
      { cols: 7, rows: 1, shape: "spacer" },
      { cols: 4, rows: 1, shape: "rectangle" },
      { cols: 8, rows: 1, shape: "spacer" },
      { cols: 10, rows: 1, shape: "rectangle" },
      { cols: 1, rows: 1, shape: "spacer" },
      { cols: 8, rows: 1, shape: "rectangle" },
      { cols: 5, rows: 1, shape: "rectangle" },
      { cols: 18, rows: 1, shape: "spacer" },
      { cols: 23, rows: 2, shape: "rectangle" },
      { cols: 9, rows: 1, shape: "rectangle" },
      { cols: 3, rows: 1, shape: "spacer" },
      { cols: 6, rows: 3, shape: "rectangle" },
      { cols: 9, rows: 1, shape: "rectangle" },
    ],
    'details-loader': [
      { cols: 1, rows: 20, shape: "spacer" },
      { cols: 5, rows: 4, shape: "spacer" },
      { cols: 11, rows: 2, shape: "rectangle" },
      { cols: 11, rows: 1, shape: "spacer" },
      { cols: 11, rows: 1, shape: "rectangle" },
      { cols: 23, rows: 1, shape: "spacer" },
      { cols: 7, rows: 5, shape: "image" },
      { cols: 1, rows: 5, shape: "spacer" },
      { cols: 13, rows: 1, shape: "rectangle" },
      // { cols: 13, rows: 1, shape: "spacer" },
      { cols: 9, rows: 1, shape: "rectangle" },
      { cols: 6, rows: 1, shape: "spacer" },
      { cols: 6, rows: 1, shape: "rectangle" },
      { cols: 9, rows: 1, shape: "spacer" },
      { cols: 23, rows: 1, shape: "spacer" },
      { cols: 7, rows: 2, shape: "rectanagle" },
      { cols: 1, rows: 1, shape: "spacer" },
      { cols: 15, rows: 1, shape: "rectangle" },
      { cols: 1, rows: 1, shape: "spacer" },
      { cols: 12, rows: 1, shape: "rectangle" },
      { cols: 23, rows: 2, shape: "rectangle" },
    ],
    'default': [
      
    ],
  };


  generateLoaderConfigs(){
    
    // list-table-view-loader
    for (let i = 0; i < 40; i++) {
      if (i < 8) this.presets['list-table-view-loader'].push({ cols: 3, rows: 2, shape: "rectangle"})
      else this.presets['list-table-view-loader'].push({ cols: 3, rows: 1, shape: "rectangle"})
    }

    // page-loader-2
    let str = JSON.parse(JSON.stringify(this.presets['page-loader-2']))
    this.presets['page-loader-2'].push(...str)
    this.presets['page-loader-2'].push(...str)
  }

  getPresetgridMap(presetCode: string){
    let gridStructure = this.presets[presetCode] || this.presets['default']
    console.log("returning grid structure", gridStructure)
    return gridStructure
  }
}
