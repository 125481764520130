import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormService } from '../form.service';
import { BoxCache } from 'src/app/core/boxInstances/BoxCache';


@Component({
  selector: 'app-form-template-popup',
  templateUrl: './form-template-popup.component.html',
  styleUrls: ['./form-template-popup.component.scss']
})
export class FormTemplatePopupComponent implements OnInit {

  spinner:boolean = false;
  replacedActionMaps: any[];
  @Input() templateActionMaps: any;
  @Output() actionMapped = new EventEmitter<any>();
  constructor(
    public formService: FormService,
  ) {}

  async ngOnInit() {

    let templateActionMaps = JSON.parse(JSON.stringify(this.templateActionMaps));
    this.customInit(templateActionMaps);
  }

  async customInit(templateActionMaps){
    this.replacedActionMaps = []
    for (let index = 0; index < templateActionMaps.length; index++) {
      const element = templateActionMaps[index];
      let obj = element.actionMap;
      obj.index = index;
      if(index != 0 && obj.connection){
        obj.boxMap = BoxCache.boxObject[obj.boxId];
        this.replacedActionMaps.push(obj)
      }
    }
    console.log("replacedActionMaps", this.replacedActionMaps);
    if(this.replacedActionMaps?.length == 0)this.actionMapped.emit(this.templateActionMaps);
  }

  async initActions(templateActionMaps){
    this.templateActionMaps = templateActionMaps;
    await this.customInit(this.templateActionMaps);
  }

  // nameChanged(e){
  //   console.log("name", e);
  //   this.nameMap = e;
  // }

  connectionSelected(e, action){
    action.replacedConnection = e._id;
    console.log("connectionSelected", e._id, action, this.replacedActionMaps);
    let templateActionMaps = this.templateActionMaps;
    for (let index = 0; index < templateActionMaps.length; index++) {
      let element = templateActionMaps[index];
      if(index == action.index && element.actionMap?.connection){
        element.actionMap.connection = e._id
      }
    }
    console.log("this.dataInput.templateActionMaps", this.templateActionMaps);

    let valid = true;
    this.replacedActionMaps.forEach(element => {
      if(!element.replacedConnection) valid = false;
    });
    if(valid) this.actionMapped.emit(this.templateActionMaps);
  }
}
