import { Component, Injectable, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StarchService } from 'src/app/shared/services/starch.service';
import { StarchBaseDiologComponent } from '../starch-base-diolog/starch-base-diolog.component';
import { FlatTreeControl} from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { StarchBasePopupComponent } from '../starch-base-popup/starch-base-popup.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { StarchCollaborationComponent } from '../starch-collaboration/starch-collaboration.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { PermissionService } from 'src/app/core/services/permission.service';

export class BaseNode {
  name: string;
  code?:any; _id?:any
  children?: BaseNode[];
}

export class ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  code?:any; _id?:any
}

@Component({
  selector: 'starch-base',
  templateUrl: './starch-base.component.html',
  styleUrls: ['./starch-base.component.scss'],
  providers: []
})
export class StarchBaseComponent implements OnInit {


  formGroup: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    name: new UntypedFormControl('', Validators.required)
  });

  timeout: any = null;
  isNameExists: boolean;
  spinner: boolean;
  sugestBaseCode: any;
  addNewBase: boolean = false;
  baseMap: any = {};
  allBases: any = []
  addNewObject:boolean = false;
  objectMap: any = {}
  sharedStarch: any[] = []


  private _transformer = (node: BaseNode, level: number) => {
    let flatNode = {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      nodeMap: node,
      level: level,
    };
    this.flatNodeMap.set(flatNode, node);
    return flatNode;
  }

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  flatNodeMap: Map<ExampleFlatNode, BaseNode> = new Map<ExampleFlatNode, BaseNode>();
  nestedNodeMap: Map<BaseNode, ExampleFlatNode> = new Map<BaseNode, ExampleFlatNode>();

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  showSearch: boolean = false;
  pageIndex = 0;
  pageLimit = 10;
  baseSearchField: string = "";

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar,
    public starchService: StarchService,
    public widgetService: WidgetService,
    public authService: AuthServiceService,
    public resourcePermissionService: ResourcePermissionService,
    public permission: PermissionService

  ) { }

  async ngOnInit() {
    await this.getAllBases()
    await this.getSharedBases()
  }


  ngAfterViewInit(): void {

  }
  mouseover(e, node){
    node.hovered = true;
  }

  mouseout(e, node){
    let target = e.relatedTarget.localName;
    if(target != 'mat-icon')node.hovered = false;
  }

  async openStarchEditor(node){
    let object = node;
    let base = node?.nodeMap?.baseMap;
    console.log("node", node)

    var dialog = this.dialog.open(StarchBaseDiologComponent, {
      minWidth: "70%",
      data: { base: base, object: object}
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("do", diologResult)
    if(!diologResult) return;
  }


  async getAllBases(){
    console.log("getting all bases")
    this.spinner = true;
    this.allBases = await this.getAllBaseTree();
    console.log("starch list", this.allBases);
    this.dataSource.data =  this.allBases;
    if(!this.showSearch && this.starchService.baseLength > this.pageLimit) this.showSearch = true;
    console.log("this.dataSource.data", this.dataSource.data)
    this.spinner = false;
  }

  async getSharedBases(){
    this.sharedStarch = await this.resourcePermissionService.getSharedResources(
      this.authService.profile._id,
      'starch'
    );
    console.log("shared starch : ",this.sharedStarch)
  }

  async shareStarchBase(base){
    console.log("Starch to be shared : ",base);
    let dialogRef = this.dialog.open(StarchCollaborationComponent, {
      width: '90vw',
      height: '90vh',
      panelClass: "collabdiolog",
      data: {
        resource: base?.nodeMap,
        resourceType: 'starch'
      },
    });
    var diologResult = await dialogRef.afterClosed().toPromise();
  }

  setDefaultObjectName(node, objectMap){
    objectMap.object = 'Object ' + (node?.nodeMap?.baseMap?.children?.length)
  }

  editObjectNameFocusOut(node){
    node.isEdit = false;
  }


  async getAllBaseTree(){

    // this.spinner = true;
    let filter = "";
    if(this.baseSearchField)filter = `name%${this.baseSearchField}`;
    let workspaceId = this.permission.isSharedBloom ? this.permission.sharedWorkspaceId : null;
    let bases = await this.starchService.getStarchBases(this.pageIndex + 1, this.pageLimit, filter, workspaceId);
    let result = [];

    for (let index = 0; index < bases.length; index++) {
      const element = bases[index];
      element.children = [{load: true}]
      result.push(element)
    }
    // this.spinner = false;
    return result;
  }

  async loadAndSetObject(node){
    if(!this.treeControl.isExpanded(node))return;
    console.log("loaded 2", node)
    this.spinner = true;
    let nodeMap = node?.nodeMap;
    let objects = await this.starchService.getBaseObjects(nodeMap)
    let bases = []

    for (let i = 0; i < this.allBases.length; i++) {
      const element = this.allBases[i];
      if(element._id == nodeMap._id){
        objects?.forEach(ele => {
            ele.baseMap = element
        });
        objects.push({name: "__add_object", baseMap: element })
        element.children = objects
      }
      bases.push(element)
    }
    this.dataSource.data =  bases;
    let index = this.treeControl.dataNodes.findIndex((x:any) => x.nodeMap?._id === nodeMap._id );;
    this.treeControl.expand(this.treeControl.dataNodes[index])
    this.spinner = false;

  }

  async updateBase(node){
    this.spinner = true;
    let name = node.name;
    let children = node.nodeMap.children;
    let baseMap = node.nodeMap;
    delete baseMap.children;
    baseMap.name = name;
    await this.starchService.updateStarchBase(baseMap);
    if(node?.nodemap?.name) node.nodemap.name = name;
    if(baseMap) baseMap.children = children;
    this.spinner = false;
  }

  async deleteBase(node){
    console.log("node", node)
    var dialog = this.dialog.open(StarchBasePopupComponent, {
      // minWidth: "60%",
      data: { data: node, type:"base"}
    });
    var diologResult = await dialog.afterClosed().toPromise();
    if(diologResult){
      this.spinner = true;
      let nodeMap = node?.nodeMap;
      await this.starchService.deleteStarchBase(nodeMap);

      let bases = [];
      for (let index = 0; index < this.dataSource.data.length; index++) {
        const element = this.dataSource.data[index];
        if(nodeMap._id != element._id ){
          bases.push(element)
        }
      }
      this.dataSource.data = bases;
      this.spinner = false;
    }
  }

  async deleteBaseObject(node){
    console.log("node", node)
    var dialog = this.dialog.open(StarchBasePopupComponent, {
      // minWidth: "60%",
      data: { data: node, type:"object"}
    });
    var diologResult = await dialog.afterClosed().toPromise();
    if(diologResult){
      this.spinner = true;
      let baseMap = node?.nodeMap?.baseMap;
      let object = node?.nodeMap?.__id;
      await this.starchService.deleteBaseObject(baseMap, object)
      let bases = [];
      let nodee;
      for (let index = 0; index < this.dataSource.data.length; index++) {
        const element = this.dataSource.data[index];
        if(baseMap._id == element._id ){
          let childIndex = element.children.findIndex((x:any) => x?.__id === object );
          console.log("childIndex", childIndex)
          element.children.splice(childIndex, 1)
          nodee = element;
        }
        element.children.forEach((ele:any) => {
          ele.baseMap = element
        });
        bases.push(element)
      }

      this.dataSource.data = bases;
      let index = this.treeControl.dataNodes.findIndex((x:any) => x.nodeMap?._id === nodee._id );
      this.treeControl.expand(this.treeControl.dataNodes[index])
      this.spinner = false;
    }
  }

  async createObjectAndSetTree(objectMap, node){
    let bases:any = []
    let nodee;
    this.spinner = true;
    let object = await this.createObject(objectMap, node?.nodeMap?.baseMap);
    for (let index = 0; index < this.dataSource.data.length; index++) {
      const element = this.dataSource.data[index];
      if(node.nodeMap.baseMap._id == element._id ){
        var position = element.children.length - 1;
        element.children.splice(position, 0, object)
        nodee = element;
      }
      element.children.forEach((ele:any) => {
        ele.baseMap = element
      });
      bases.push(element)
    }
    console.log("this.dataSource.data", this.treeControl)
    this.dataSource.data = bases;

    let index = this.treeControl.dataNodes.findIndex((x:any) => x.nodeMap?._id === nodee._id );
    this.treeControl.expand(this.treeControl.dataNodes[index])
    this.spinner = false;
    let openObject = bases[index].children[position];
    let openNode:any = {
      expandable: false,
      hovered: false,
      name: openObject.name,
      nodeMap: openObject
    }
    openNode.addNewAttributes = false;
    openNode.attributes = [];
    this.openStarchEditor(openNode)
  }

  async createObject(objectMap, baseMap){
    //todo - enhance to generate dynamicaly when more storage types comesin
    // let functions = this.boxFunctions.filter((e) => e.__id == "createobject");
    // let functionMap = functions[0];

    this.spinner = true;
    let payload = {
      object: this.objectMap.object
    }
    let object = await this.starchService.createBaseObject(baseMap, payload);
    this.spinner = false;
    return object;
    // await this.getBaseObjects();

  }

  async pageChanged($event) {
    console.log($event);
    this.pageIndex = $event.pageIndex;
    this.pageLimit = $event.pageSize;
    await this.getAllBases();
    await this.getSharedBases();
  }

  dropWidget(event){
    console.log("event", event)
    this.widgetService.starchDragNotifier(event)
  }

}


