<div class="cover" style="width: 100%">

  <div class="section">
    <fieldset *ngIf="config.dataSourceType === 'dynamic'" class="dataPointsFieldset">
      <div *ngIf="isAttributesReady" fxLayout="column" fxLayoutAlign="center stretch">

        <!-- DIMENSION -->
        <!-- <div>{{ widgetMeta.config.dataSource.dimensions | json }}</div> -->
        <ng-container *ngIf="widgetMeta.config.dataSource && widgetMeta.config.dataSource.dimensions && widgetMeta.config.dataSource.dimensions.length">
          <div style="height: 1rem"></div>
          <div class="subHeading">Dimension</div>
          <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="1rem" style="width: 100%">
            <mat-form-field class="noPaddingBottom" appearance="outline">
              <mat-label>Attribute</mat-label>
              <input
                #nameAttributeInput
                type="text"
                aria-label="attribute autocomplete"
                matInput
                [formControl]="nameAttributeControl"
                [matAutocomplete]="nameAttributeInputAuto"
              >
              <button *ngIf="nameAttributeInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="nameAttributeControl.patchValue('')">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #nameAttributeInputAuto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="nameAttributeSelected($event); ">
                <mat-option *ngFor="let attr of filteredNameAttributes | async" [value]="attr">
                  <span>{{attr.__id}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <div *ngIf="advancedModeOpenName">
              <mat-form-field class="nameForm noPaddingBottom" appearance="outline">
                <mat-label>Path</mat-label>
                <input matInput type="text" [(ngModel)]="namePath">
              </mat-form-field>
            </div>

            <button mat-icon-button [matTooltip]="advancedModeOpenName ? 'Close Advanced Mode' : 'Open Advanced Mode'" (click)="advancedModeOpenName = !advancedModeOpenName">
              <mat-icon>engineering</mat-icon>
            </button>
          </div>

          <div *ngIf="widgetMeta?.config?.dataSource?.dimensions[0]?.attribute?.dataType === 'date'">
            <div >  </div>
              <div >
              <mat-form-field class="custom_select example-full-width" appearance="outline">
                <mat-label>Format</mat-label>
                <mat-select (selectionChange)="formatSelection($event)" [(ngModel)] = 'widgetMeta.config.dataSource.dimensions[0].attribute.format' name="role" >
                <mat-option *ngFor="let type of formatMap[widgetMeta.config.dataSource.dimensions[0].attribute.dataType].options"  [value]="type.value">
                 <span>{{type.name}}</span> </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </ng-container>


        <!-- METRIC -->
        <ng-container *ngIf="widgetMeta.config.dataSource && widgetMeta.config.dataSource.metric">
          <div style="height: 1rem"></div>
          <div class="subHeading">Metric</div>
          <div fxLayout="row" fxLayoutAlign="flex-start center" style="width: 100%">
            <mat-form-field class="noPaddingBottom" appearance="outline" style="margin-right: 1rem">
              <mat-label>Attribute</mat-label>
              <input
                #valueAttributeInput
                type="text"
                aria-label="attribute autocomplete"
                matInput
                [formControl]="valueAttributeControl"
                [matAutocomplete]="valueAttributeInputAuto"
              >
              <button *ngIf="valueAttributeInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="valueAttributeControl.patchValue('')">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #valueAttributeInputAuto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="valueAttributeSelected($event); ">
                <mat-option *ngFor="let attr of filteredValueAttributes | async" [value]="attr">
                  <span>{{attr.__id}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <!-- <div *ngIf="advancedModeOpenValue">
              <mat-form-field class="nameForm noPaddingBottom" appearance="outline">
                <mat-label>Value Path</mat-label>
                <input matInput type="text" [(ngModel)]="valuePath">
              </mat-form-field>
            </div> -->

            <!-- <button mat-icon-button [matTooltip]="advancedModeOpenValue ? 'Close Advanced Mode' : 'Open Advanced Mode'" (click)="advancedModeOpenValue = !advancedModeOpenValue">
              <mat-icon>engineering</mat-icon>
            </button> -->

            <mat-form-field appearance="outline" class="noPaddingBottom">
              <mat-label>Operation</mat-label>
              <mat-select [(value)]="selectedMetricOperation" (selectionChange)="metricOperationSelected($event)">
                <mat-option [value]="op.value" *ngFor="let op of OPERATIONS">{{ op.viewValue }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="noPaddingBottom"
              appearance="outline"
              *ngIf="widgetMeta.config.precision && ['sum', 'average', 'no_op'].includes(selectedMetricOperation)"
            >
              <mat-label>{{ widgetMeta.config.precision.displayName }}</mat-label>
              <input type="text" matInput [(ngModel)]="widgetMeta.config.precision.value">
            </mat-form-field>

          </div>
        </ng-container>
        
        <!-- SORT -->
        <div *ngIf="widgetMeta.config.dataSource && widgetMeta.config.dataSource.chartOrder">
          <div>
            <div style="height: 1rem"></div>
            <div class="subHeading">Sort by</div>

            <mat-radio-group [(ngModel)]="widgetMeta.config.dataSource.chartOrder.field" aria-label="Select an sort">
              <mat-radio-button value="none">None</mat-radio-button>
              <mat-radio-button value="dimension">Dimension</mat-radio-button>
              <mat-radio-button value="value">Value</mat-radio-button>
            </mat-radio-group>

            <div *ngIf="['dimension', 'value'].includes(widgetMeta.config.dataSource.chartOrder.field)">
              <mat-radio-group [(ngModel)]="widgetMeta.config.dataSource.chartOrder.order" aria-label="Select an order">
                <mat-radio-button value="ASC">Ascending</mat-radio-button>
                <mat-radio-button value="DESC">Descending</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>

        <!-- FILTER -->
        <ng-container *ngIf="widgetMeta.config.dataSource && widgetMeta.config.dataSource.filter">
          <!-- <app-filter-config
            [filter]="config.filter"
            [boxObjectAttributes]="attributes"
            [supportApplyButton]="false"
            [hideToggle]="false"
            [lockFilterType]="true"
            (selectedFilter)="filterSelected($event)"
          >
          </app-filter-config> -->
          <app-filter-component
          [filter]="config.filter"
            [boxObjectAttributes]="attributes"
            [supportApplyButton]="false"
            [hideToggle]="false"
            [lockFilterType]="true"
            (selectedFilter)="filterSelected($event)"
          ></app-filter-component>
        </ng-container>
        <!-- [maskOperator]="true" -->
      </div>
    </fieldset>
  </div>

  <div style="height: 1rem;"></div>
</div>
