import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
// import { BuilderToolbarComponent } from './builder-toolbar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { DeleteDialogComponent } from './delete-dialog.component';
import { FormsModule } from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
// import { BuilderMenuToolsComponent } from './builder-menu-tools.component';
// import { SharedModule } from 'src/app/shared/shared.module';
// import { ConnectionPanelListModule } from 'src/app/connection-panel/connection-panel-list/connection-panel-list.module';
// import { StarchBaseModule } from 'src/app/starch/starch-base/starch-base.module';
import { BloomComponent } from './bloom-listing.component';
import { ListPanelModule } from '../../specialized-panels/list-panel/list-panel.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { workspaceModule } from 'src/app/shared/workspace/workspace.module';
import { DisplayBannerModule } from 'src/app/shared/display-banner/display-banner.module';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { SnackbarModule } from 'src/app/shared/snackbar/snackbar.module';


@NgModule({
  declarations: [BloomComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    FormsModule,
    ListPanelModule,
    workspaceModule,
    DisplayBannerModule,
    MatCardModule,
    RouterModule,
    TranslateModule,
    SpinnerModule,
    MatPaginatorModule,
    SnackbarModule
    // SharedModule,
    // ConnectionPanelListModule,
    // StarchBaseModule,
  ],
  exports: [BloomComponent]
})
export class BloomListingModule { }
