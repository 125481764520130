
<spinner *ngIf="spinner" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div fxLayout="row" *ngIf="authService.loggedIn"><app-workspace (dataRefresh)="refreshList()" [parentPage]="'starch'"></app-workspace></div>

<div>
  <display-banner
    [logo] = "'form-builder/aw-form-builder-logo.png'"
    [Title]="'Elevate data collection effortlessly with Forms'"
    [Tagline]="'Build and publish powerful custom forms quickly with seamless integration'">
  </display-banner>
</div>


<!-- <div style="height: 2rem;"></div> -->

<!-- <div *ngIf="workspaceReady && authService.loggedIn" fxLayout="column" fxLayoutAlign="center center">

  <div fxFlex="100" fxLayoutAlign="flex-start center" fxLayoutGap="2.5rem" style="padding: 1rem 0;">

    <mat-card class="card" (click)="createBlank()" matTooltip="Create Blank Form">
      <div fxLayout="column">
        <div><span fxLayout fxLayoutAlign="center" class="cardTitle">Create New Form</span></div>
        <div fxLayout fxLayoutAlign="center center">
          <img class="plusLogo" src="https://storage.googleapis.com/jivrus-web-images/avatar/plus-icon.png" alt="new bloom" style="opacity: 50%;">
        </div>
      </div>
    </mat-card>
  </div>
  <div fxFlex="10"></div>
</div> -->


<div fxLayout="row" *ngIf="!authService.loggedIn" fxLayoutAlign="center center">
  <div  class="build_button_div" (click)="navigateToSignUp()">
    <div class="build_button" fxLayout="row">
      <div fxLayout="column" fxLayoutAlign="center start">
        <div style="font-size: large; font-weight: bold;" fxLayout="row"
        fxLayoutAlign="start center"> Build Now</div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="authService.loggedIn && panelMetaReady">
  <div fxFlex="2"></div>
  <div fxFlex="96" fxLayoutAlign="space-between center">
    <button
      *ngIf="authService.loggedIn"
      mat-button
      fxLayoutAlign="start start"
      routerLink="/home"
      color="primary"
    >
      <mat-icon color="primary">arrow_back</mat-icon>
      <span style="margin-top: 10px; margin-left: 4px">Back to Home</span>
    </button>

    <button mat-flat-button color="primary" style="border-radius: 12px;" (click)="onNewFormClicked()">
      {{'Create new Form'}}
    </button>
  </div>
  <div fxFlex="2"></div>
</div>


<h1 class="heading" *ngIf="authService.loggedIn && panelMetaReady"><b>List of Forms</b></h1>

<!-- <div>{{ "isFormsLoaded" + isFormsLoaded }}</div> -->
<mat-card appearance="outlined" class="listingWrapper" *ngIf="authService.loggedIn">
  <div fxLayout="column" fxLayoutAlign="stretch start" fxFlexFill>

    <!-- <div fxFlexAlign="end" fxLayoutAlign="end center" fxFlexFill class="bloomviewicon" *ngIf="!error">
      <button mat-stroked-button color="primary" class="smallButton" (click)="refreshList()">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span><mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center" style="margin-right: .3em;">refresh</mat-icon></span>
          <span>Refresh</span>
        </div>
      </button>

      <button mat-icon-button (click)="connectionListToggle()">
        <mat-icon matTooltip="{{showTip}}" style="margin: 0 0 0 0" color="primary">{{ unselectedView }}</mat-icon>
      </button>
    </div>

    <div fxFlexFill *ngIf="selectedConnectionView == 'view_list'" [hidden]="error">
      <div class="listWrapper">
        <div class="header" fxLayoutAlign="start center">
          <div fxFlex="5" fxLayoutAlign="start center">#</div>
          <div fxFlex="75" fxLayoutAlign="start center">Form Name</div>
          <div fxFlex="10" fxLayoutAlign="start center"></div>
          <div fxFlex="5"></div>
        </div>
        <div class="formItem" *ngFor="let form of forms; let i = index">
          <div fxFlex="5" fxLayoutAlign="start center">{{ i + 1 }}</div>
          <div fxFlex="75" fxLayoutAlign="start center" class="formName">{{ form.name }}</div>
          <div fxFlex="10" fxLayoutAlign="start center"><mat-icon class="openIcon" fxLayoutAlign="center center" (click)="openForm(form)" matTooltip="Open form">login</mat-icon></div>
          <div fxFlex="5" fxLayoutAlign="start center"><mat-icon class="openIcon" fxLayoutAlign="center center" (click)="onDelete(form)" matTooltip="Delete form">delete</mat-icon></div>
        </div>
      </div>
    </div>

    <mat-paginator
      *ngIf="!error"
      [ngClass]="{ bloomWrap: selectedConnectionView == 'view_list' }"
      [pageSizeOptions]="[5, 10, 20, 50, 100, 200]"
      [pageIndex]="pageIndex"
      [length]="forms.length"
      pageSize="5"
      (page)="pageChanged($event)"
      [disabled]="isPaginator"
      [hidden]="!forms.length"
      #bloomPaginator
    ></mat-paginator> -->


    <app-list-panel
      style="width: 100%"
      *ngIf="authService.loggedIn && panelMetaReady"
      [builderMode]="false"
      [panelMeta]="formsListPanelMeta"
      [refreshData]="refreshPanel"
      (rowData)="selectedRowData($event)"
    >
    </app-list-panel>

  </div>
</mat-card>


<div class="auth-error" *ngIf="authError">
    <mat-card appearance="outlined">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem" style="width: 100%;">
        <div style="text-align: center; width: 100%;">Workspace not loaded. Try signing in.</div>
        <button mat-raised-button class="signin-button" [routerLink]="['/']">
            Sign in
        </button>
      </div>
    </mat-card>
  </div>

  <div *ngIf="authService.loggedIn && panelMetaReady">
    <div fxFlex="2"></div>
    <div fxLayout fxLayoutAlign="start start" fxFlex="98" >
      <button mat-flat-button color="primary" style="border-radius: 12px; margin-top: 5px;" (click)="onNewFormClicked()">
        {{'Create new Form'}}
      </button>
    </div>
  </div>
