import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout'

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

import { SpinnerModule } from '../spinner/spinner.module';
import { BaseSelectionComponent } from './base-selection.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BaseSelectionPopupComponent } from './base-selection-popup/base-selection-popup.component';
import { StarchAddBaseModule } from 'src/app/starch/starch-add-base/starch-add-base.module';


@NgModule({
  declarations: [
    BaseSelectionComponent, BaseSelectionPopupComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    SpinnerModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    StarchAddBaseModule
  ],
  exports: [
    BaseSelectionComponent, BaseSelectionPopupComponent
  ]
})
export class BaseSelectionModule { }
