import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
  selector: 'app-privilege-create',
  templateUrl: './privilege-create.component.html',
  styleUrls: ['./privilege-create.component.scss']
})
export class PrivilegeCreateComponent implements OnInit {

  constructor(
    private route: ActivatedRoute, 
    private adminService: AdminService, 
    private spinnerService: SpinnerService,
    private connectionService: ConnectionService, 
  ) { }

  @Input() privilage: any;
  @Input() privilageConfig: any;
  @Output() "listPrivilage" = new EventEmitter<any>();

  workspaceId: string;
  preAuthenticatedToken: string;
  isNewPrivilege: boolean = true;

  privilegeFormGroup: UntypedFormGroup = new UntypedFormGroup({
    _id: new UntypedFormControl(''),
    created_at: new UntypedFormControl(''),
    created_by: new UntypedFormControl(''),
    modified_at: new UntypedFormControl(''),
    modified_by: new UntypedFormControl(''),
    code: new UntypedFormControl('', Validators.required),
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
    description: new UntypedFormControl('', Validators.required),
    resource_type: new UntypedFormControl(''),
    resource_id: new UntypedFormControl('')
  });

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.workspaceId = params.workspaceid || this.connectionService?.workSpaceId ;
      this.preAuthenticatedToken = params.authorization ? decodeURIComponent(params.authorization) : this.connectionService?.preAuthenticatedToken;
      this.isNewPrivilege = this.privilage ? false : true;
    });
  }

  createPrivilege() {

    var resourceOption = {
      resource_type: "default", 
      resource_id: "default"
    }

    if(this.privilageConfig){
      resourceOption = {
        resource_type: this.privilageConfig.resource_type, 
        resource_id: this.privilageConfig.resource_id
      }
    }
    this.privilegeFormGroup.patchValue(resourceOption)
    console.log("inside create", this.privilegeFormGroup.value)
    if (this.privilegeFormGroup.valid) {
      
      this.spinnerService.show();
      this.adminService.createPrivilege(this.preAuthenticatedToken, this.privilegeFormGroup.value).then((res) => {
        this.spinnerService.hide();
      })
      this.backToListing()
    } else { console.log("inside create else") }
  }

  updatePrivilege() {
    if (this.privilegeFormGroup.valid) {
      this.spinnerService.show();
      this.adminService.updatePrivilege(this.preAuthenticatedToken, this.privilegeFormGroup.value).then((res) => {
        console.log(res)
        this.spinnerService.hide();
      })
      this.backToListing()
    }
  }

  backToListing(){
    this.listPrivilage.emit();
  }

}
