import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
/** 12 or 24 */
declare type MccTimerPickerFormat = '12' | '24';

@Component({
  selector: '[datetimepicker]',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss'],
  exportAs: 'datetimepicker'
})
export class DateTimePickerComponent implements OnInit {

  private _timeFormat: MccTimerPickerFormat = '12';
  get timeFormat() { return this._timeFormat }
  /**Accepts Only 12 or 24 */
  @Input() set timeFormat(v: MccTimerPickerFormat) {
    this._timeFormat = v;
  };
  DateTime: UntypedFormGroup = new UntypedFormGroup({
    date: new UntypedFormControl(new Date()),
    time: new UntypedFormControl('')
  });
  get date() { return this.DateTime.controls['date'].value }
  @Input() set date(v) { this.DateTime.controls['date'].setValue(v) }
  @Output() valueChange = new EventEmitter<string>();

  constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.DateTime.controls['date'].valueChanges.subscribe((e) => {
      console.log(e)
      this.selectionChange();
    })
    this.DateTime.controls['time'].valueChanges.subscribe((e) => {
      console.log(e)
      this.selectionChange();
    })
  }

  selectionChange() {
    let time = this.DateTime.controls['time'].value.split(":");
    let hour = +time[0];
    if (this.DateTime.controls['date'].value && this.DateTime.controls['time'].value) {
      let result = [this.datePipe.transform(this.DateTime.controls['date'].value, 'YYYY-MM-dd'), this.DateTime.controls['time'].value];
      if (hour < 10 && hour > 0) {
        this.valueChange.emit(result.join("T0"));
        return
      }
      this.valueChange.emit(result.join("T"));
    }
  }

}
