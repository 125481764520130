<spinner class="spinner" *ngIf="spinner" [selfSpin]="true" size="la-2x" color="grey" type="ball-fussion"></spinner>
<div >
  <div fxLayout="row" *ngIf="showSearch" fxLayoutAlign="center center" style="margin-top: -30px;">
    <div fxLayout="column" fxFlex="5"></div>
    <div fxLayout="column" fxFlex="70">
    <mat-form-field floatLabel="never" style="min-width: 20%;width: 100%;" >
      <mat-icon  matPrefix style="margin-bottom: 14.25px">search
      </mat-icon>
      <mat-label>
        <span>&nbsp;&nbsp;Search</span>
      </mat-label>
      <input matInput [(ngModel)]="baseSearchField" fxFlexFill />
      <button class='icon-button-small' matSuffix mat-icon-button
        (click)="baseSearchField=''"  >
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
    </div>
    <div fxLayout="column" fxFlex="20" >
      <button matTooltip="Search Bases" mat-icon-button color="primary"  style="margin-top:-13px;"
       (click)="getAllBases()">
      <mat-icon>search</mat-icon>
    </button>
    </div>
    <div fxLayout="column" fxFlex="5"></div>
  </div>
    <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: -20px;">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <mat-list role="list">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                  <!-- use a disabled button to provide padding for tree leaf -->
                  <mat-list-item *ngIf="node.name != '__add_object'" cdkDropList (cdkDropListDropped)="dropWidget(node)">
                     <div fxLayout="row" style="width: 100%;"
                      (mouseover)="mouseover($event, node)" (mouseout)="mouseout($event, node)" >
                      <!-- <div fxLayout="column" fxFlex="5"></div> -->
                        <div cdkDrag fxLayout="column" fxFlex="80">
                        <div fxLayout="row"  class="border">
                          <div fxLayout="column" fxFlex="10">
                            <mat-icon *ngIf="node.hovered" matTooltip="Drag to build visual on {{node.name}}" style="cursor: pointer;margin-left: -10px;margin-top: -3px;color: #565656;"
                            (click)="openStarchEditor(node)"  > drag_indicator </mat-icon>
                          </div>
                          <div fxLayout="column" fxFlex="10">
                            <mat-icon style="margin-left: -8px;;margin-top: -1px;    font-size: 20px;"
                               matTooltip="" color="accent"
                              >table_rows</mat-icon>
                          </div>
                          <div fxFlex="80" matTooltip="Drag to build visual on {{node.name}}" style="cursor: pointer"
                           > {{node.name}}</div>
                        </div>

                        </div>
                        <div fxLayout="column" fxFlex="10">
                          <mat-icon *ngIf="node.hovered" style="cursor: pointer;color: #3c3c3c;" matTooltip="Edit attributes"
                          (click)="openStarchEditor(node)">settings</mat-icon>
                        </div>
                        <div fxLayout="column" fxFlex="10">
                          <mat-icon *ngIf="node.hovered" style="cursor: pointer;" color="warn"
                          matTooltip="Delete base object" (click)="deleteBaseObject(node)">delete</mat-icon>
                        </div>
                      </div>
                    <!-- <mat-icon fxFlex="5" *ngIf="node.children">add_box</mat-icon>-->
                  </mat-list-item>


                  <div *ngIf="!node.addNewObject && node.name == '__add_object'">
                    <button mat-button color="primary" (click)="node.addNewObject = true;setDefaultObjectName(node, objectMap)">
                        <mat-icon color="primary">add</mat-icon>  New object</button>
                  </div>

                  <div *ngIf="node.addNewObject">
                    <div style="min-height: 100px;background-color: transparent;" fxLayout="row wrap" fxLayoutAlign="center center">
                        <div fxLayout="column">
                            <div fxLayout="row" style="margin-top: 10px;" fxLayoutAlign="center center" >
                                <div fxLayout="column" class="base-field" fxFlex="100">
                                    <mat-form-field appearance="outline" style="width: 100%;">
                                        <mat-label> Object </mat-label>
                                        <input required [(ngModel)]="objectMap.object" matInput placeholder="Object">
                                    </mat-form-field>
                                </div>
                                <div fxLayout="column" fxFlex="20">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center center">
                        <div fxLayout="column">
                            <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: -15px;margin-bottom: 5px;">
                                <div fxLayout="column" fxFlex="45">
                                    <button class="base-button" mat-stroked-button (click)="node.addNewObject = false" [mat-dialog-close]="false" color="primary"  type="button">
                                        Cancel
                                      </button>
                                </div>
                                <div fxFlex="10"></div>
                                <div fxLayout="column" fxFlex="45">
                                    <button  class="base-button" mat-flat-button (click)="node.addNewObject = false;createObjectAndSetTree(objectMap, node)" color="primary" type="button">
                                        Create
                                      </button>
                                </div>
                           </div>
                        </div>
                    </div>
                  </div>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                   <mat-list-item>

                    <div fxLayout="row" (mouseover)="mouseover($event, node)" (mouseout)="mouseout($event, node)"
                    style="width: 100%;margin: top -20px !important;;margin: bottom -20px !important;" fxLayoutAlign="start center">
                      <div fxLayout="column" fxFlex="10">
                        <!-- <button mat-icon-button > -->
                          <mat-icon style="cursor: pointer;"  matTreeNodeToggle matTooltip="{{treeControl.isExpanded(node) ? '': 'Expand Base to see Objects'}}"
                          color="primary" class="mat-icon-rtl-mirror" [attr.aria-label]="'toggle ' + node.name" (click)="loadAndSetObject(node)">
                            {{treeControl.isExpanded(node) ? 'indeterminate_check_box' : 'add_box'}}
                          </mat-icon>
                        <!-- </button> -->
                      </div>
                      <div fxLayout="column" fxFlex="10">
                        <mat-icon matTooltip="" color="accent"
                        >dataset</mat-icon>
                      </div>
                      <div fxLayout="column" fxFlex="60" fxLayoutAlign="center start">
                        <span  style="overflow: hidden;white-space: nowrap;width: 100%" *ngIf="!node.isEdit" matTooltip="{{node.name}}">{{node.name}}</span>
                        <mat-form-field floatLabel="never" *ngIf="node.isEdit" style="width: 100%;">
                          <input required (blur)="editObjectNameFocusOut(node)" [(ngModel)]="node.name" matInput placeholder="base name">
                        </mat-form-field>
                      </div>

                      <div fxLayout="column" fxFlex="10">
                        <mat-icon *ngIf="!node.isEdit && node.hovered" (click)="node.isEdit = true" style="cursor: pointer;"
                         matTooltip="Rename the Base" color="accent"
                        >edit</mat-icon>
                        <mat-icon *ngIf="node.isEdit" (click)="node.isEdit = false;updateBase(node)"
                        matTooltip="Confirm" style="cursor: pointer;" color="accent"
                        >check</mat-icon>
                      </div>
                      <div fxLayout="column" fxFlex="10">
                        <mat-icon (click)="shareStarchBase(node)" *ngIf="node.hovered" style="cursor: pointer;" color="primary"
                        matTooltip="Share base">share</mat-icon>
                      </div>
                      <div fxLayout="column" fxFlex="10">
                        <mat-icon (click)="deleteBase(node)" *ngIf="node.hovered" style="cursor: pointer;" color="warn"
                        matTooltip="Delete base">delete</mat-icon>
                      </div>
                     </div>
                  <mat-spinner diameter="20" *ngIf="node.spin"></mat-spinner>
                  </mat-list-item>
                </mat-tree-node>
            </mat-tree>

            </mat-list>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
      </div>
</div>

<mat-paginator style="width: 100%" [pageIndex]="pageIndex" [pageSize]="pageLimit" (page)="pageChanged($event)"
[length]="starchService.baseLength || 1000" [hidePageSize]="true"   #connectionListPaginator></mat-paginator>

<div *ngIf="!allBases || allBases?.length == 0"  fxLayoutAlign="center center">
    <div fxLayout="column">Create your first Starch Base</div>
</div>

<div *ngIf="!addNewBase">
    <div fxLayout="column" fxFlex="5"></div>
    <button mat-stroked-button color="primary" (click)="addNewBase = true;">
        <mat-icon color="primary">add</mat-icon>  New base</button>
</div>


<div *ngIf="addNewBase">
  <div fxLayout="column" fxFlex="5"></div>
  <div fxLayout="column" fxFlex="90">
    <starch-add-base [allBases]="allBases" (baseCreated)="getAllBases();addNewBase = false" (cancelled)="addNewBase = false"> </starch-add-base>
  </div>
  <div fxLayout="column" fxFlex="5"></div>

</div>

<diV *ngIf="authService.loggedIn">
  <div fxLayout="row" *ngIf="sharedStarch.length > 0" >
    <div fxLayout="column" fxFlex="2"></div>
    <div fxLayout="column" fxFlex="96">
      <h1 style="margin-bottom: 0px;"><b> Shared with me</b></h1>
    </div>
    <div fxLayout="column" fxFlex="2"></div>
  </div>

  <div fxLayout="row"   *ngIf="sharedStarch.length > 0" >
      <div fxLayout="column" fxFlex="2"></div>
      <div fxLayout="column" fxFlex="96">
          <mat-card   appearance="outlined"   class="list-card-head" fxLayout="row wrap"
               layout-wrap fxLayoutAlign="start center"
                 style="word-break: break-all;width: 100%" >
              <div fxLayout="column wrap" style="text-align: center;word-break: break-word;" fxFlex="5">
                  #
               </div>
               <div fxFlex="5" fxLayout="column wrap" style="word-break: break-word;">

                  </div>
              <div fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                  Name
              </div>
              <div fxFlex="25" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
              </div>
              <div fxFlex="20" fxLayoutAlign="center start" fxLayout="column wrap" style="word-break: break-word;">
              </div>
              <div fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
              </div>
              <div fxFlex="5" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
              Action
              </div>
            </mat-card>
      </div>
      <div fxLayout="column" fxFlex="2"></div>
    </div>

  <div fxLayout="row" *ngIf="sharedStarch.length > 0" >
      <div fxLayout="column" fxFlex="2"></div>
      <div fxLayout="column" fxFlex="96">
          <div   *ngFor="let starch of sharedStarch;let i = index">
            <mat-card appearance="outlined" class="list-card" fxLayout="row wrap"
               layout-wrap fxLayoutAlign="start center"
                 style="word-break: break-all;width: 100%" >
              <div fxLayout="column wrap" style="text-align: center;word-break: break-word;" fxFlex="5">
                  {{i + 1}}
               </div>
              <div fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                {{ starch.name}}
              </div>
              <div fxFlex="25" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
              </div>
              <div  fxFlex="20" fxLayoutAlign="center start" fxLayout="column wrap" style="word-break: break-word;">
              </div>
              <div matTooltip="Status" fxFlex="20" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
              </div>
              <div fxFlex="5" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                <!-- <mat-icon style="margin: 0px;cursor: pointer;" (click)="exploreConnection(connection)">visibility</mat-icon> -->
              </div>
            </mat-card>
          </div>
      </div>
      <div fxLayout="column" fxFlex="2"></div>
    </div>
</diV>
