import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { Component, NgZone, OnInit, ChangeDetectorRef, ViewChild, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { environment } from 'src/environments/environment';
import { EventEmitter } from 'stream';

@Component({
  selector: 'menu-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class MenuResourcesComponent implements OnInit {

  baseURL: any = environment.LOGO_BASE_URL
  isBrowser: any;
  constructor(
    private authService: AuthServiceService,
    private themeService: ThemeService,
    private connectionService: ConnectionService,
    private cd: ChangeDetectorRef,
    public router: Router,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
  }

  resourcesMap :any = {
    list: ["articles", "docs","support"],
    "articles": {
      name:"Articles",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/appiworks-logo.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.ARTICLE}/articles`,
      type: 'external-bloom'
    },
    "docs": {
      name:"Docs",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/appiworks-logo.png',
      link: `https://appiworks.jivrus.com`,
      type: 'site'
    },
    "support":{
      name:"Support",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/appiworks-logo.png',
      link: `https://appiworks.jivrus.com/support`,
      type: 'site'
    }
  }


  resourcesMap1 :any = {
    list: ["gwa", "glsc","ze"],
    "gwa": {
      name:"Google Workspace add-on",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/appiworks-logo.png',
      link: `https://appiworks.jivrus.com/client-platforms/google/google-add-on`,
      type: 'external-bloom'
    },
    "glsc": {
      name:"Google Looker Studio Connector",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/appiworks-logo.png',
      link: `https://appiworks.jivrus.com/client-platforms/google/looker-studio-connector`,
      type: 'site'
    },
    "ze":{
      name:"Zoho Extensions",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/appiworks-logo.png',
      link: `https://appiworks.jivrus.com/client-platforms/zoho`,
      type: 'site'
    }
  }

  ngOnInit(): void {

  }


  openConnections(){
    this.themeService.closeMenu.next(null)
  }

  navigateToResouce(resource){
    window.open(`${resource.link}`, "_blank")
    this.themeService.closeMenu.next("resource")
  }
}
