<div>
      <div>
        <div fxLayout="row" fxLayoutAlign="start center">

          <mat-icon color="primary" > find_in_page </mat-icon>
          <form class="w-100">
            <mat-form-field class="w-100">
              <input type="text"
                     placeholder="Choose a navigation page OR Provide URL"
                     matInput
                     [formControl]="myControl"
                     [(ngModel)]="navigationValue"
                     [matAutocomplete]="auto"
                     (input)="navigationRecieved($event.target.value)">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="navigationRecieved($event.option.value)">
                <mat-option value="prev_page">Previous Page</mat-option>
                <mat-option *ngFor="let option of metaService.loadedPageStructure.pages" [value]="option">
                  {{ metaService.loadedPageStructure[option].name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </div>
        <div *ngIf="noValidnavigation" style="color: red">
          {{validateMessage}}
        </div>
      </div>

      <div fxLayout="row" *ngFor="let data of actionConfig.actionMap.parameters;let i = index"  fxLayoutAlign="start center"> 
        <div fxLayout="column" fxFlex="40">
            <mat-form-field class="example-full-width" >
                <mat-label>Attribute</mat-label>
                <input (input)="setAttributeId(data.keyField)" matInput [matTooltip]="data.keyField.__id" [(ngModel)] = 'data.keyField.__id' >
            </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="50">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary"> widgets </mat-icon>
            <mat-form-field class="wide-select" [matTooltip]="data.sourceField.name">
              <mat-select (selectionChange)="onWidgetActionSelected()" [(ngModel)] = "data.sourceField">
                <mat-option [value]="option" *ngFor="let option of sourceFields">
                  {{ option.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="column" fxFlex="5"> 
            <mat-icon style="cursor: pointer;" color="warn" (click)="removePayloadAttribute(actionConfig.actionMap.parameters, i)">
            delete
         </mat-icon></div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center"> 
      <button style="cursor: pointer;" (click)="addNewAttribute()"  color="primary" mat-stroked-button matTooltip="Add new attribute">
          <mat-icon >
              add_circle_outline
           </mat-icon>
           Add attribute
      </button>
  </div>
</div>
