<spinner [selfSpin]="spinner"  [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div>
  <mat-dialog-content>
    <div *ngIf="config.type">
        <!-- <div fxLayoutAlign="center center"> <img style="height: 50px;width: auto;" src="https://tenor.com/en-IN/view/sad-face-zedd-griff-rolling-frown-face-sad-gif-19295148"> </div> -->
        <div fxLayoutAlign="center center">
            <h3 style="color: red;">{{ (msgMap[config.product || 'APPIWORKS'][config.type]?.title) || 'Product Limit Reached!' }}</h3>
        </div>
        <div fxLayoutAlign="center center">
            <h2>  {{ (msgMap[config.product || 'APPIWORKS'][config.type]?.subTitle) || 'You\'re at full capacity 🚧' }}</h2>
        </div>
        <div>
            {{ (msgMap[config.product || 'APPIWORKS'][config.type]?.msg) || 'Please clear some space or upgrade your account to access more features.' }}
        </div>
    </div>
    <br>
    <diV fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="column" fxFlex="10"></div>
        <div fxLayout="column" fxFlex="80" fxLayoutAlign="center center">
            <button mat-flat-button color="primary" style="border-radius: 12px;" (click)="upgradePlan()">
                Upgrade
            </button>
        </div>
        <div fxLayout="column" fxFlex="10"></div>
        <div fxLayout="column" fxFlex="80" fxLayoutAlign="center center">
            <div class="container">
                <button mat-stroked-button color="primary" style="border-radius: 12px;" (click)="handlePayment()">I Paid Now</button>
                <div class="message-container" fxLayoutAlign="end center">
                    <!-- <p *ngIf="paymentStatusMessage" class="payment-status-message">{{ paymentStatusMessage }}</p> -->
                    <p *ngIf="paymentStatusMessage" class="payment-status-message" [innerHTML]="paymentStatusMessage"></p>
                </div>
            </div>
        </div>
    </diV>
  </mat-dialog-content>

</div>
