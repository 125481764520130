<div
  *ngIf="builderMode"
  class="outerCover" #cover fxLayout="row" fxLayoutAlign="center center" fxFlexFill
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow" (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()" (click)="onClick($event)" cdkDragHandle
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>

  <div>

    <div fxLayoutAlign="center center" ><h3>{{widgetMeta.config?.title?.value || 'Connection'}}</h3>  </div>
    <button fxLayoutAlign="center center" mat-button color="primary"
     matTooltip="create connection" (click)="pickBox()" *ngIf="!isWhiteLabeled">
     <mat-icon class="iconInButton">add</mat-icon>
     Create connection
   </button>

    <mat-card appearance="outlined" *ngIf="isWhiteLabeled" fxLayout="row" >
    <img *ngIf="whiteLabeledConnection?.options?.box_logo_url" class="image" [src]="whiteLabeledConnection.options.box_logo_url"
     >
     Connected to {{whiteLabeledConnection?.options?.box_name}}
      <mat-icon color="accent" (click)="editConnection()" style="cursor: pointer;"> edit </mat-icon>
      <mat-icon color="warn" (click)="deleteConnection()" style="cursor: pointer;"> delete </mat-icon>
    </mat-card>
  </div>

</div>



<div
  *ngIf="!builderMode && (!widgetMeta.config.hidden || !widgetMeta.config.hidden.value)"
  class="outerCover" #cover fxLayout="row" fxLayoutAlign="center center" fxFlexFill
  (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
<div>

  <div fxLayoutAlign="center center" ><h3>{{widgetMeta.config?.title?.value || 'Connection'}}</h3>  </div>
  <button fxLayoutAlign="center center" mat-button color="primary"
   matTooltip="create connection" (click)="pickBox()" *ngIf="!isWhiteLabeled">
   <mat-icon class="iconInButton">add</mat-icon>
   Create connection
 </button>

  <mat-card appearance="outlined" *ngIf="isWhiteLabeled" fxLayout="row" >
  <img *ngIf="whiteLabeledConnection?.options?.box_logo_url" class="image" [src]="whiteLabeledConnection.options.box_logo_url"
   >
   Connected to {{whiteLabeledConnection?.options?.box_name}}
   <div *ngIf="!widgetMeta.config?.viewOnly?.value">
    <mat-icon color="accent" (click)="editConnection()" style="cursor: pointer;"> edit </mat-icon>
    <mat-icon color="warn" (click)="deleteConnection()" style="cursor: pointer;"> delete </mat-icon>
  </div>
  </mat-card>
</div>

</div>
