import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { DragnDropfileComponent } from './dragndropfile.component';
import { FilePickerDirective } from './file-picker.directive';
import { SelectedFilesTableComponent } from './selected-files-table/selected-files-table.component';
import { MatToolbarModule } from '@angular/material/toolbar';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatToolbarModule
  ],
  declarations: [
    DragnDropfileComponent,
    FilePickerDirective,
    SelectedFilesTableComponent
  ],
  exports: [
    DragnDropfileComponent,
    FilePickerDirective,
    SelectedFilesTableComponent
  ],
  providers: []
})
export class DragnDropFileModule { }

