import { Component, Input, OnInit, ViewChild, Inject, Output, EventEmitter, OnChanges, SimpleChanges, Directive, HostListener, AfterViewInit, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router'
import { MatMenuTrigger } from '@angular/material/menu';

import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ActionManager } from 'src/app/bloom/models/Action/ActionManager';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { Subject } from 'rxjs';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';

@Directive({ selector: '[outerCover]' })
export class resize {
  @HostListener('mousedown', ['$event'])
  onClick(target) {
    console.log("target clicked", target)
  }
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent extends BaseWidgetComponent implements OnInit, OnChanges, AfterViewInit {

  @Output() onExecuteAction = new EventEmitter<any>();
  @Output() raiseButtonClicked = new EventEmitter<any>();

  buttonStyle: string = ''
  contextMenuActions: any = [];
  styles: any;
  element: any;
  parent: any;
  mouseDownPos: any = 0;
  initialWidth: any = 0;
  securityMap: any = {}

  spinner: boolean = false

  private destroy:any = new Subject();

  //ViewChid
  @ViewChild('buttonEditInput', { static: false }) private _buttonEditInput: ElementRef;
  @ViewChild('menuTrigger', { static: false }) private _buttonTrigger: MatMenuTrigger;

  //flags
  buttonEditMode: boolean = false;
  hoveredNow: boolean = false;
  // grabber: boolean = false;
  // oldX: any = 0;
  // x: any = 0;
  // width: any = 0

  constructor(public router: Router,
    public dialog: MatDialog,
    public tokenUtil: TokenUtil,
    public http: HttpClient,
    public elementRef: ElementRef,
    public actionManager: ActionManager,
    public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)
    // console.log("button constructor hit", this.widgetMeta)
  }

  ngAfterViewInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }

    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
  }

  ngOnInit(): void {
    this.widgetMeta.config.spin = false;
    super.ngOnInit();
    this.securityMap = super.checkAccess();
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  @ViewChild('menuTrigger') buttonMenuTrigger: MatMenuTrigger
  @ViewChild('outerCover') outerCover: ElementRef;


  //----------------------------METHODS-------------------------

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "bold",
        "underline",
        "italic",
        "color",
        // "backgroundColor",
        "fontSize",
        "fontFamily",
        "buttonType",
        "buttonColorType",
        "edit",
      ],
      buttonType: {
        value: this.widgetMeta?.config.buttonType.value,
        type: this.widgetMeta?.config.buttonType.type
      },
      buttonColorType: {
        value: this.widgetMeta?.config.buttonColorType.value,
        type: this.widgetMeta?.config.buttonColorType.type
      }
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    switch (event.actionType) {
      case "delete":
        this.onDelete()
        break;
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = event.data;
          super.generateStyles()
          // this.newWidgetMeta.emit(this.widgetMeta)
        }
        break;
      case "bindData":
        console.log("reached widget componenet, will update widget meta")
        this.widgetMeta.dataBindConfig = event.returnData
        // this.newWidgetMeta.emit(this.widgetMeta)
        this.buttonMenuTrigger?.closeMenu();
        break;
      case "executeBinding":
        this.executeBinding()
        break;
      // case "customPropertyUpdate":
      //   console.log("RETURNED FROM MENU", event)
      //   this.widgetMeta.config[event.propertyName].value = event.data
      //   // this.newWidgetMeta.emit(this.widgetMeta)
      //   break;
      default:
        break;
    }
  }

  onOuterClick() {
    console.log("onOuterClick", Date.now())
    if (this.builderMode) {
      console.log("calling from onOuterClick")
      this.toggleWidgetSelection()
    }
  }

  mouseEnter(e) {
    // this.executeAction(e)
  }

  mouseLeave(e) {
    // this.executeAction(e)
  }

  async onClick(event: Event) {
    // event.stopPropagation()
    console.log("button clicked", event, this.widgetMeta)
    // this.onOuterClick()
    
    this.spinner = true
    this.widgetMeta.config.spin = true;
    let res = await this.executeAction(event);
    this.widgetMeta.config.spin = false;
    this.spinner = false
    //emit upon completion
    this.onExecuteAction.emit(res);
    
    this.raiseButtonClicked.emit(event);
  }

  async executeAction(e) {
    if(!this.widgetMeta.actionConfig || !this.widgetMeta.actionConfig.actions) return
    if (!this.builderMode){
      console.log("button widget", JSON.parse(JSON.stringify(this.widgetMeta)))
      let res = await this.actionManager.executeActions(this.widgetMeta.actionConfig.actions, e);
      return res
    }
    //  this.actionManager.getAction(this.widgetMeta.actionConfig.action).doAction(this.widgetMeta.actionConfig, e)
  }

  toggleWidgetSelection() {
    // console.log("toggle widget selection called")
    // toggle widget selection
    if (this.selectedWidgetId == this.widgetMeta.id) {
      console.log("unselecting")
      this.widgetSelection.emit(-1);
      this.selectedWidgetId = -1
    } else {
      console.log("selecting")
      this.widgetSelection.emit(this.widgetMeta.id)
      this.selectedWidgetId = this.widgetMeta.id
    }
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.buttonMenuTrigger?.closeMenu();
  }

  executeBinding() {
    console.log('execute binding hit, will hit url', this.widgetMeta.dataBindConfig.url)
    console.log("config", this.widgetMeta.dataBindConfig.boxConfig)
    let bloomCode = 'bloom_1131'
    let token = this.tokenUtil.getStatelessToken()
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`)
      .set('boxConfigToken', this.widgetMeta.dataBindConfig.boxConfigToken)

    let payload = {
      parameters: {
        "query": {
          "attributes": "",
          "filter": "code=" + bloomCode + "|string",
          "sort": "id=ASC",
          "page": "1|10|1000"
        }
      }
    }

    let url = this.widgetMeta.dataBindConfig.url

    this.http.post(url, payload, { headers: headers }).subscribe(
      (response: any) => {
        console.log("execute data binding response", response);
      },
      (error: any) => {
        console.log('error occured in executing data binding', error)
      })
  }

  timer: any = 0;
  delay = 200;
  prevent = false;

  onDoubleButtonClicked() {
    console.log('Double Click');
    if (!this.builderMode) {
      this.buttonEditMode = false;
      return;
    }
    clearTimeout(this.timer);
    this.prevent = true;
    this.turnOnEditMode();
  }

  turnOnEditMode() {
    this.buttonEditMode = true;
    setTimeout(() => { this._buttonEditInput.nativeElement.focus(); }, 0);
  }

  onFocusOut(event: any) {
    this.buttonEditMode = false;
    this.newWidgetMeta.emit(this.widgetMeta)
  }

}
