import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from './auth-service.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {

  constructor(
    public cs: ConnectionService,
    public http: HttpClient,
    public authServiceService: AuthServiceService
  ) { }

  async checkAndCreatePermission(body){
    console.log("permission", body)
    let url = `${environment.SERVER_BASE_URL}/workspace-permission/checkandcreate`;
    var result: any = {};
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken
      }
      var response: any = await this.http.post(url, body, { headers: headers }).toPromise();
      console.log("response", response);
      result = response.data;
    } catch (e) {
      console.log("Error while getting permission", e)
    }
    return result;
  }

  async getPermission(id){
    let url = `${environment.SERVER_BASE_URL}/workspace-permission/${id}`;
    var result: any = {};
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken
      }
      var response: any = await this.http.get(url, { headers: headers }).toPromise();
      console.log("response", response);
      result = response.data;
    } catch (e) {
      console.log("Error while getting permission", e)
    }
    return result;
  }

  async getPermissions(workspaceid){
    let url = `${environment.SERVER_BASE_URL}/workspace-permission/list/${workspaceid}`;
    var result: any = {};
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken
      }
      var response: any = await this.http.get(url, { headers: headers }).toPromise();
      console.log("response", response);
      result = response.data;
    } catch (e) {
      console.log("Error while getting permissions", e)
    }
    return result;
  }

  async deletePermission(id){
    let url = `${environment.SERVER_BASE_URL}/workspace-permission/${id}`;
    var result: any = {};
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken
      }
      var response: any = await this.http.delete(url, { headers: headers }).toPromise();
      console.log("response", response);
      result = response.data;
    } catch (e) {
      console.log("Error while deleting permission", e)
    }
    return result;
  }

  async getPermissionForUser(userId, workspaceid){
    let url = `${environment.SERVER_BASE_URL}/workspace-permission/list/${workspaceid}/${userId}`;
    var result: any = {};
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken
      }
      var response: any = await this.http.get(url, { headers: headers }).toPromise();
      console.log("response", response);
      result = response;
    } catch (e) {
      console.log("Error while getting permission for user", e)
    }
    return result;
  }

  async getUserbyIds(ids){
    let url = `${environment.SERVER_BASE_URL}/user/getbyids`;
    var result: any = null;
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken
      }
      var response: any = await this.http.post(url, {"ids": ids}, { headers: headers }).toPromise();
      console.log("response", response);
      result = response?.data;
    } catch (e) {
      console.log("Error while getting permissions", e)
    }
    return result;
  }

  async getUserbyId(id){
    let url = `${environment.SERVER_BASE_URL}/user/id/${id}`;
    var result: any = null;
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken
      }
      console.log("url", url)
      var response: any = await this.http.get(url, { headers: headers }).toPromise();
      console.log("response", response);
      result = response;
    } catch (e) {
      console.log("Error while getting permissions", e)
    }
    return result;
  }

  async getWorkspace(id, ownerEmail?){
    let url = `${environment.SERVER_BASE_URL}/workspace/id/${id}`;
    var result: any = {};
    console.log("this.cs", this.cs)
    console.log("authServiceService", this.authServiceService)
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.cs.getPreAuthenticatedTokenForEmail(ownerEmail)
      }
      var response: any = await this.http.get(url, { headers: headers }).toPromise();
      console.log("response", response);
      result = response.data;
    } catch (e) {
      console.log("Error while getting permission", e)
    }
    return result;
  }

  async getWorkspaceFromQuery(query, ownerEmail?){
    let url = `${environment.SERVER_BASE_URL}/workspace/list`;
    var result: any = {};
    console.log("this.cs", this.cs)
    console.log("authServiceService", this.authServiceService)
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.cs.getPreAuthenticatedTokenForEmail(ownerEmail)
      }
      let body = {
        query: query
      }
      var response: any = await this.http.post(url, body, { headers: headers }).toPromise();
      console.log("response", response);
      result = response.data;
    } catch (e) {
      console.log("Error while getting workspace", e)
    }
    return result;
  }

  //Update Permission API
  async updatePermission(permission:any) {
    let url = `${environment.SERVER_BASE_URL}/workspace-permission`;
    var result: any = {};
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.cs.preAuthenticatedToken
      }
      var response: any = await this.http.put(url, permission, { headers: headers }).toPromise();
      console.log(response)
      result = response?.data?.[0] || null;
      console.log("[permission] update: ", result);
    } catch (e) {
      console.log("Error while updating permission", e);
    }
    return result;
  }

  async updateWorkspace(workspace:any, ownerEmail?) {
    let url = `${environment.SERVER_BASE_URL}/workspace`;
    var result: any = {};
    try {
      var headers = {
        'Authorization': 'PreAuthenticatedToken ' + this.cs.getPreAuthenticatedTokenForEmail(ownerEmail)
      }
      var response: any = await this.http.put(url, workspace, { headers: headers }).toPromise();
      console.log(response)
      result = response?.data?.[0] || null;
      console.log("[workspace] update: ", result);
    } catch (e) {
      console.log("Error while updating workspace", e);
    }
    return result;
  }
}
