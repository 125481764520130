import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { StarchAddBaseComponent } from './starch-add-base.component';
import { CdkTreeModule } from '@angular/cdk/tree';

@NgModule({
  declarations: [StarchAddBaseComponent],
  imports: [
    CommonModule,
    SharedModule,
    SpinnerModule,
    CdkTreeModule
  ],
  exports: [StarchAddBaseComponent]
})
export class StarchAddBaseModule { }
