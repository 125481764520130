import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectModule } from './mat-select/mat-select.module';
import { InputModule } from './mat-input/mat-input.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatSelectModule,
    MatFormFieldModule,
    SelectModule
  ],
  exports: [SelectModule, InputModule]
})
export class matComponentsModule { }
