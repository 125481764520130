import { Injectable } from '@angular/core';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';

@Injectable({
  providedIn: 'root'
})
export class CloneService {

  constructor() { }

  replacer(templateObject, sourceConnection, destinationConnection){
    console.log("replace template", templateObject, "with", sourceConnection, "and", destinationConnection)
    let te = new TemplateEngine()
    let replacedObj: any
    // console.log("templateObject.valueMap", templateObject.valueMap)
    if(templateObject.valueMap.lookupPath){
      replacedObj = te.fillTemplateObject({value: '${' + templateObject.valueMap.lookupPath + '}'}, {sourceConnection: sourceConnection, destinationConnection: destinationConnection})
      // console.log("replaced obj", replacedObj)
    }
    return replacedObj?.value
  }

  createClonePayload(cloneFunction: any, sourceConnection, destinationConnection){
    console.log("creating payload for", cloneFunction)
    let payload = {}
    cloneFunction.input?.list?.forEach(ip => {
      let temp = this.replacer(cloneFunction['input'][ip], sourceConnection, destinationConnection)
      payload[ip] = temp
    })
    return { parameters: payload }
  }

}
