import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ListPanelModule } from "src/app/bloom/specialized-panels/list-panel/list-panel.module"
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { AttributeParameterInputsModule } from 'src/app/shared/input-parameters/attribute-parameter-inputs/attribute-parameter-inputs.module';
import { ConnectionPanelListComponent } from './connection-panel-list.component';
import { ConnectionDialogModule } from 'src/app/shared/dialog/connection-dialog/connection.dialog.module';
import { ConnectionSelectionModule } from 'src/app/shared/connection-selection/connection-selection.module';
import { AppsPopupModule } from 'src/app/shared/apps-popup/apps.popup.module';

@NgModule({
  declarations: [ConnectionPanelListComponent],
  imports: [
    CommonModule,
    SharedModule,
    ListPanelModule,
    SpinnerModule,
    AttributeParameterInputsModule,
    ConnectionDialogModule,
    ConnectionSelectionModule,
    AppsPopupModule
  ],
  exports: [ConnectionPanelListComponent]
})
export class ConnectionPanelListModule { }
