import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-page-create-dialog',
  templateUrl: './page-create-dialog.component.html',
  styleUrls: ['./page-create-dialog.component.css']
})
export class PageCreateDialogComponent implements OnInit {

  newName: string = ''
  pageStructure: any
  pageCreationSpinner: boolean = false;
  pageCreateError: any
  isError: boolean = false


  constructor(
    public dialogRef: MatDialogRef<PageCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    console.log("data", this.data)
    if(this.data?.isCloning){
      this.newName = "Copy of " + this.data.name
    }
    if(this.data?.pageStructure){
      this.pageStructure = this.data.pageStructure
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  create(e?: any){
    this.isError = false
    if(e && e.key !== "Enter"){
      console.log(e)
      return;
    }
    if(this.newName){
      let found: boolean = false;
      this.pageStructure.pages.forEach((pageCode: string) => {
        if (this.pageStructure[pageCode].name == this.newName) {
          found = true
        }
      });
      if (this.newName == 'pages' || this.newName == 'homePageCode') {
        // console.log("page names reserved, use different name")
        this.pageCreationSpinner = false;
        this.isError = true;
        this.pageCreateError = "Page name reserved, please use different name!!"
        return
      }
      if (found) {
        this.pageCreationSpinner = false;
        this.isError = true;
        this.pageCreateError = "Same name already exits, please use different name!!"
        // console.log("same name already exits")
        return
      }
      this.dialogRef.close({
        name: this.newName
      });
    }
  }

  cancel(){
    this.dialogRef.close(false);
  }
}
