import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;
  editingHeading: boolean = false

  @ViewChild('menuTrigger') sliderMenuTrigger: MatMenuTrigger
  private destroy:any = new Subject();

  constructor(public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)

  }

  ngOnInit(): void {
    super.ngOnInit()
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.selectedWidgetId && changes.selectedWidgetId.currentValue !== this.widgetMeta.id){
      if(this.sliderMenuTrigger?.menuOpen){
        this.sliderMenuTrigger?.closeMenu()
      }
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }

  //------------------------------------ METHODS -------------------------------------

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "bold",
        "underline",
        "italic",
        "color",
        "backgroundColor",
        "fontSize",
        "fontFamily",
        "class",
        "edit",
      ]
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)
    switch (event.actionType) {
      case "delete":
        this.onDelete()
        break;
      case 'updateStyles':
        if (event?.data) {
          this.widgetMeta = event.data;
          this.generateStyles();
          // this.newWidgetMeta.emit(this.widgetMeta)
        }
        break;

      default:
        break;
    }
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.sliderMenuTrigger.closeMenu();
  }

  onClick(event) {

    if (!this.builderMode) {
      this.sliderMenuTrigger.closeMenu()
      return
    }

    console.log("slider clicked", event)
    if (!this.builderMode) {
      this.sliderMenuTrigger.closeMenu();
    }
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  saveNewTitle(newHeaderRef: any){
    if(newHeaderRef.value == ''){
      console.log("emoty text")
      //show snackbar
      return
    }
    console.log("new title will be saved", newHeaderRef.value)
    this.widgetMeta.config.title.value = newHeaderRef.value

    this.newWidgetMeta.emit(this.widgetMeta)
  }

  formatLabel(value: number): string {
    return value + "";
  }

  sliderChanged(event) {
    console.log("sliderChanged", event.value)

    this.widgetMeta.config.value.value = event.value

    if(this.builderMode){
      this.newWidgetMeta.emit(this.widgetMeta)
    }

    let emitterData: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: this.widgetMeta.config?.value?.value
    }
    this.userInputReceived.emit(emitterData)
  }


}
