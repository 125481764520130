import { AfterViewInit, Component, DoCheck, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BoxService } from 'src/app/bloom/services/box-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { AutomationService } from 'src/app/bloom/services/automation.service';


interface DETAIL_PANEL_FILTERS {
  filterEnabled?: boolean,
  filterItems?: FILTER_ITEM[]
}
interface FILTER_ITEM {
  attribute: any,
  operator: string,
  value: any,
  dataType: string,
  filterType: string
}
@Component({
  selector: 'app-details-panel-dialog',
  templateUrl: './details-panel-dialog.component.html',
  styleUrls: ['./details-panel-dialog.component.scss']
})
export class DetailsPanelDialogComponent implements OnInit {

  pageMeta: any;
  panelMeta: any;
  filteredAttributes: any;
  selectedConnectionId: any;
  selectedBoxName: any;
  selectedBoxId: any;
  selectedBoxObjectId: any;
  isBoxObjectSelected: boolean = false;
  isReadyToSave: boolean = false;
  isAttributeReady: boolean = false
  boxObjects: any;
  boxObjectAttributes: any[] = []
  boxObjectAttributesBackup: any[] = []
  isReceivedBoxObjects: boolean = false;
  canGetBoxObjects: boolean = false;
  isOptionsToCollect: boolean = false;
  attributeOptions: any[] = []
  getFnOptions: any[] = []
  isGetOptionsToCollect: boolean = false;
  attributeOptionsError: boolean = false;
  faultyAttrOptions: any[] = []
  getOptionsError: boolean = false;
  faultyGetOptions: any[] = []
  boxFunctions: any[] = []
  firstHit: boolean = false
  configChanged: boolean = false;   // this flag tells whether the existing configuration is changed (applies when !firstHit)
  attributeSpinner: boolean = false
  boxFunctionSpinner: boolean = false
  gettingObjFunSpinner: boolean = false
  saveDisabled: boolean = false
  disableBoxObjectInput: boolean = false
  refreshObjects: boolean = false
  boxConfigToken: any
  hoveredIndex: number = -1
  submitButtonTitle: string = 'Submit'

  terminationError: boolean = false;
  terminationErrorMessage: string;
  lockLoadInitialData: boolean = false;
  boxAttributes: any;

  isBoxConfigError: boolean = false;
  isBoxObjectConfigError: boolean = false;
  isAttributeError: boolean = false;
  boxConfigError: any = ''
  boxObjectConfigError: any = {};
  attributeError: any = '';

  detailsAttributes: any[] = []
  detailsPanelTitle: string = ''
  unselectedDetailsAttributes: any[] = []

  primaryAttribute: any
  isPrimaryExists: boolean = false
  primaryAttributeName: string
  // usageMode: string = 'createandupdate'

  isAppSelected: boolean = false
  actionConfig: any
  actionFields: any = [];

  showLabels: boolean = false

  filter: DETAIL_PANEL_FILTERS = {
    filterEnabled: false,
    filterItems: []
  }
  availableInputParams: any = {
    options: {}
  }

  selectAttributeControl = new UntypedFormControl();
  @ViewChild('attributeMenu') attrMenu: MatMenuTrigger

  widgetTypeMap: any = {
    widgets: ['label', 'image', 'date', 'datetime', 'time', 'richtext', 'link', 'embed', 'object', 'array', "chips", "tags","autocomplete", "connection"],
    label: { name: 'Text', icon: 'text_fields'},
    link: { name: 'Link', icon: 'link'},
    embed: {name: "Embed", icon: "magnification_large"},
    image: { name: 'Image', icon: 'image'},
    date: { name: 'Date', icon: 'calendar_today'},
    time: { name: 'Time', icon: 'schedule'},
    datetime: { name: 'Date + Time', icon: 'event'},
    object: { name: 'Object', icon: 'data_object'},
    array: { name: 'List', icon: 'list'},
    richtext: { name: 'Rich Text', icon: 'wysiwyg'},
    chips: {name: 'chips', icon: 'developer_board'},
    tags: {name: "Tags", icon: "sell"},
    autocomplete: {name: "Autocomplete", icon: "manage_search"},
    connection: {name: "Connection", icon: "cable"},
  }
  detailsMetaOld: any
  noFilterData: boolean = false;
  filterInfo: any;
  showFilterInfo: boolean = false;

  advancedPaneOpenIndex: number = -1

  collectAttrOptions: boolean = false
  collectGetOptions: boolean = false
  getAttrFn: any
  getFn: any
  objectFuntions: any[] = []
  boxObjectChanged: boolean = false
  boxChanged: boolean = false

  attributeDisplayName: string;

  constructor(
    public dialogRef: MatDialogRef<DetailsPanelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private MetaService: MetaService,
    private boxService: BoxService,
    private pageService: PageService,
    private automationService: AutomationService
  ) {
    this.panelMeta = JSON.parse(JSON.stringify(data.panelMeta))
    this.pageMeta = data.pageMeta

  }

  ngOnInit(): void {
    if (this.data.firstHit) {
      this.firstHit = true
      console.log("first hit! building new details panel...")
    } else {
      this.firstHit = false;
      console.log("panel meta received in details panel onInit:", this.panelMeta)
      this.detailsMetaOld = JSON.parse(JSON.stringify(this.panelMeta)
      )
      this.detailsAttributes = this.panelMeta.detailsAttributes

      this.selectedBoxId = this.panelMeta.boxId
      this.selectedBoxName = this.panelMeta.boxName
      this.selectedConnectionId = this.panelMeta.connectionId
      this.selectedBoxObjectId = this.panelMeta.boxObjectId
      this.boxConfigToken = this.panelMeta.boxConfigToken
      this.showLabels = this.panelMeta.showLabels || false

      this.filter = this.panelMeta?.filter || this.filter
      console.log("filter", this.filter)

      this.isAppSelected = true

      // this.findPrimaryAttribute()

      this.detailsPanelTitle = this.panelMeta.detailsPanelTitle || ''

      if(this.panelMeta.attributeOptions && this.panelMeta.attributeOptions.length){
        this.attributeOptions = this.panelMeta.attributeOptions
        // this.isOptionsToCollect = true
      }
      if(this.panelMeta.getFnOptions && this.panelMeta.getFnOptions.length){
        this.getFnOptions = this.panelMeta.getFnOptions
        // this.isGetOptionsToCollect = true
      }
      this.isBoxObjectSelected = true
      // this.isAttributeReady = true

      // this.getAttributes()
    }
    this.getFilterInfo(this.filter?.filterEnabled)
  }

  getFilterInfo(filter){
    if(filter){
      this.filterInfo = "Advanced option for setting up customized filters."
    }else{
      this.filterInfo = "Automatically forms filter conditions from page navigation parameters."
    }
  }

  async getAttributes() {
    this.attributeSpinner = true
    console.log("attributeOptions", this.attributeOptions)
    let response
    try{

      let boxId = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
      let conType = this.selectedBoxId == 'starch' ? "token": '';
      let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken : this.selectedConnectionId;

      let payloadOptions:any = {}
      if(this.selectedBoxId == 'starch'){
        payloadOptions = {
          relationObject: "starch_relationship"
        }
      }
      response = await this.boxService.getAttributes(conKey, boxId, this.selectedBoxObjectId, this.attributeOptions, null, conType, payloadOptions)

    }catch(err){
      console.error("details-panel-dialog component: getAttributes()", err)
      this.isAttributeError = true
      this.attributeError = JSON.stringify(err)
      return
    }

    this.boxObjectAttributes = response.result
    console.log("attributes received:", this.boxObjectAttributes)

    this.processAttributes()
    console.log("returned from processAttributes")
    this.attributeSpinner = false
    return
  }

  processAttributes(){
    console.log("boxOBjectAttrs in process attributes", this.boxObjectAttributes)
    // add extra fields
    this.boxObjectAttributes.forEach(attr => {
      attr['enabled'] = this.firstHit ? true : false
      attr['widgetType'] = 'label'
      attr['isDrillDown'] = false
      attr['nestedProperties'] = [{ path: '', widgetType: 'label' }]
    })

    console.log("augmented attributes", this.boxObjectAttributes)

    // if config has changed
    if(this.configChanged){
      this.detailsAttributes = [...this.boxObjectAttributes]

      //check if at least one list attribute found
      if(this.detailsAttributes.length){
        this.isReadyToSave = true
        this.saveDisabled = false
      } else {
        this.isAttributeError = true
        this.attributeError = 'no attributes found for selected box'
      }
    } else{
      this.detailsAttributes = [...this.panelMeta.detailsAttributes]
      console.log("details attributes assigned", this.detailsAttributes)
      this.isReadyToSave = true
      this.saveDisabled = false
    }

    console.log("detailsattrs", this.detailsAttributes)

    if(!this.firstHit && !this.configChanged){
      // merge detailsAttributes and non-listed attributes for a master list
      this.detailsAttributes = this.mergeAttributes(this.boxObjectAttributes, this.detailsAttributes)
    }else if (this.firstHit){
      this.detailsAttributes = this.boxObjectAttributes
    }

    console.log("details attributes", this.detailsAttributes)
    this.isAttributeReady = true;
    return
  }


  mergeAttributes(boxObjectAttrs: any[], detailsAttributes: any[]){
    let masterList: any[] = detailsAttributes
    boxObjectAttrs.forEach(attr => {
      let detailsIndex = detailsAttributes.findIndex(detailsAttr => detailsAttr.__id == attr.__id)
      if(detailsIndex == -1){
        masterList.push(attr)
      }
    })
    return masterList
  }

  /**
   * creates a new attributes list by excluding already selected from all attributes
   */
   excludeSelected() {
    let arr = []
    arr = JSON.parse(JSON.stringify(this.detailsAttributes))

    let unselected: any = []
    this.boxObjectAttributes.forEach(attr => {
      // console.log("dealing ", attr)
      let selected: boolean = false
      arr.forEach(detailsAttr => {
        if (detailsAttr.__id == attr.__id) {
          // console.log("found selected")
          selected = true
        }
      });
      if (!selected) {
        unselected.push(attr)
      }
    })
    // console.log("unselected array:", unselected)
    this.unselectedDetailsAttributes = unselected
  }

  appSelected(e){
    console.log("appselected", e);
    this.selectedBoxId = e.__id;
    if(this.selectedBoxId){
      this.isAppSelected = true

      this.boxService.setBoxObjectDisplayNames(this.selectedBoxId)
      this.attributeDisplayName = this.boxService.attributeDisplayName;
    }
  }

  actionSelected(e){
    console.log("actionSelected", e);
    this.actionFields = e;
    this.boxObjectAttributes = e
    this.boxObjectAttributesBackup = JSON.parse(JSON.stringify(e))
    this.processAttributes()
  }

  getSelectedConnectionId(box){
    if(box != 'starch') return this.selectedConnectionId;
    return
  }

  getSelectedBoxId(){
    let box = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
    return box;
  }


  baseSelected(base){
    if('starch' !== this.selectedBoxId){
      this.configChanged = true
      this.isGetOptionsToCollect = false
      this.isOptionsToCollect = false
      this.saveDisabled = true
      this.refreshObjects = true
      this.disableBoxObjectInput = true
    }

    this.selectedBoxId = 'starch';
    this.panelMeta.baseMap = {
      box_id: base.storage_box,
      base_id: base._id
    }
    this.panelMeta.baseId = base._id;
    this.boxConfigToken = base.storage_token;
    this.getBoxFunctions(this.panelMeta.baseMap.box_id);
  }

  /** --------------------------------------boxSelected---------------------------
   * fires when a box is selected in box-select component
   * @param box the selected box will come from boxSelect component's output boxInput
   */
   boxSelected(box: any) {
    console.log("selected box", box)

    // check if config changed and keep flag
    if(box.box_id !== this.selectedBoxId){
      this.configChanged = true
      this.boxChanged = true
      this.isGetOptionsToCollect = false
      this.isOptionsToCollect = false
      this.saveDisabled = true
      this.refreshObjects = true
      this.disableBoxObjectInput = true
    }

    this.selectedBoxName = box.name
    this.selectedBoxId = box.box_id
    this.boxConfigToken = box.box_token
    this.selectedConnectionId = box._id

    // this.configChanged = true

    this.getBoxFunctions()
  }

  async getBoxFunctions(boxId?:any){
    this.boxFunctionSpinner = true
    let res = await this.boxService.getBoxFunctions(boxId || this.selectedBoxId, this.boxConfigToken)
    console.log("box functions received", res)
    this.boxFunctionSpinner = false
    this.boxFunctions = res

    // if boxFuntions has getobjects, enable/render boxObject selection component (*ngIf on isBoxSelected)
    if(!this.boxFunctions.find(fn => fn.__id == 'getobjects')){
      console.log("can NOT get boxObjects")
      this.canGetBoxObjects = false
      this.isBoxObjectConfigError = true
      this.boxObjectConfigError['error'] = {}
      this.boxObjectConfigError['error']['message'] = "'getobjects' not available in box functions"
    }else if(!this.boxFunctions.find(fn => fn.__id == 'getattributes')){
      this.canGetBoxObjects = false
      this.isBoxObjectConfigError = true
      this.boxObjectConfigError['error'] = {}
      this.boxObjectConfigError['error']['message'] = "'getattributes' not available in box functions"
    }else{
      console.log("can get boxObjects, will enable box object selection component")
      this.canGetBoxObjects = true
      this.isBoxObjectConfigError = false
      this.disableBoxObjectInput = false
    }
  }

  /**
   * fires when a boxObject is selected from boxObject autocomplete
   * saves the boxObjectId in this.selectedBoxObjectId
   * also calls the getAttributes() after boxObject is saved
   * @param boxObject
   */
   async boxObjectSelected(boxObject: any) {
    console.log("box object selected", boxObject.__id)

    // check if config changed and keep flag
    if(boxObject.__id !== this.selectedBoxObjectId){
      this.configChanged = true
      this.boxObjectChanged = true
      this.isGetOptionsToCollect = false
      this.isOptionsToCollect = false
      this.isAttributeReady = false
      this.saveDisabled = true
    }

    this.selectedBoxObjectId = boxObject.__id
    this.isBoxObjectSelected = true

    // update details panel title
    this.detailsPanelTitle = this.detailsPanelTitle ? this.detailsPanelTitle : `${this.selectedBoxName || "Starch"} - ${this.selectedBoxObjectId} - Details`

    this.gettingObjFunSpinner = true;

    let boxId = this.selectedBoxId == 'starch' ? this.panelMeta.baseMap?.box_id : this.selectedBoxId;
    let conType = this.selectedBoxId == 'starch' ? "token": '';
    let conKey = this.selectedBoxId == 'starch' ? this.boxConfigToken : this.selectedConnectionId;
    this.objectFuntions = await this.boxService.getBoxObjectFuntions(boxId, this.selectedBoxObjectId, conKey, conType)
    this.gettingObjFunSpinner = false
    console.log("box object functions received", this.objectFuntions)

    // // find the 'get' object function
    // let getFn: any = objectFuntions.find(fn => fn.__id == 'get')
    // let getAttributesFunction = this.boxFunctions.find(fn => fn.__id == 'getattributes')

    // if(getFn && getFn.options && !getFn.options.filter){
    //   this.panelMeta.loadInitialData = true
    //   this.lockLoadInitialData = true
    //   console.log("filter not available. locked loadInitialData to true, getFn:", getFn)
    //   this.terminationError = false

    //   // check options for getAttribute
    //   let fn = this.boxFunctions.find(fn => fn.__id == 'getattributes')
    //   this.getAttrFn = fn
    //   if(fn){
    //     let options = this.pageService.checkOptionsToCollect(fn)
    //     if(options && Array.isArray(options)){
    //       this.attributeOptions = options
    //       this.isOptionsToCollect = true
    //     }
    //   }else{
    //     this.attributeError = 'getting attributes not available on this connection and object'
    //   }

    //   // check options for get function
    //   let getFnOptions = this.pageService.checkOptionsToCollect(getFn)
    //   if(getFnOptions && Array.isArray(getFnOptions)){
    //     this.getFnOptions = getFnOptions
    //     this.isGetOptionsToCollect = true
    //   }

    //   // if there are no options to collect, directly get attributes and move ahead
    //   // otherwise wait for user to give the options and then call getAttributes
    //   if(!this.attributeOptions.length || !this.isOptionsToCollect){
    //     this.getAttributes()
    //   }
    // } else if(!getFn){
    //   console.log("get function not found")
    //   this.terminationError = true
    //   this.terminationErrorMessage = `'get' function not found on ${this.selectedBoxObjectId} of ${this.selectedBoxId}.`
    // }  if(!getAttributesFunction){
    //   console.log("getAttributes function not found")
    //   this.terminationError = true
    //   this.terminationErrorMessage = `'getAttributes' function not found on ${this.selectedBoxObjectId} of ${this.selectedBoxId}.`
    // } else{
    //   this.terminationError = false

    //   // check options for getAttribute
    //   let fn = this.boxFunctions.find(fn => fn.__id == 'getattributes')
    //   let options = this.pageService.checkOptionsToCollect(fn)
    //   if(options && Array.isArray(options)){
    //     this.attributeOptions = options
    //     this.isOptionsToCollect = true
    //   }

    //   // check options for get fn
    //   let getOptions = this.pageService.checkOptionsToCollect(getFn)
    //   if(getOptions && Array.isArray(getOptions)){
    //     this.getFnOptions = getOptions
    //     this.isGetOptionsToCollect = true
    //   }

    //   // if there are no options to collect, directly get attributes and move ahead
    //   // otherwise wait for user to give the options and then call getAttributes
    //   if(!this.attributeOptions.length || !this.isOptionsToCollect){
    //     this.getAttributes()
    //   }
    // }


    this.constructAttrOptions()
  }


  async constructAttrOptions(){

    console.log("[CONSTRUCT ATTR OPTIONS]")
    this.getAttrFn = this.boxFunctions.find(fn => fn.__id == 'getattributes')

    if(this.firstHit) {
      console.log("[CONSTRUCT ATTR OPTIONS] - if 1")
      this.attributeOptions = this.pageService.checkOptionsToCollect(this.getAttrFn)
      this.attributeOptions = this.attributeOptions.filter(op => !op.hidden)

      // keep availableInputParams in sync with attribute Options
      this.attributeOptions.forEach(op => {
        this.availableInputParams.options[op.__id || op.name] = op.value
        // if(op?.hidden == true) this.attributeOptions.splice(this.attributeOptions.indexOf(op),1);
      })

      if(this.attributeOptions?.length){
        this.collectAttrOptions = true
      }else{
        // await this.getAttributes();
        this.constructGetOptions()
      }

    } else if(!this.firstHit && (this.boxChanged || this.boxObjectChanged)){
      console.log("[CONSTRUCT ATTR OPTIONS] - if 2")
      this.attributeOptions = this.pageService.checkOptionsToCollect(this.getAttrFn)
      // keep availableInputParams in sync with attribute Options
      this.attributeOptions.forEach(op => {
        this.availableInputParams.options[op.__id || op.name] = op.value
        // if(op?.hidden == true) this.attributeOptions.splice(this.attributeOptions.indexOf(op),1);
      })
      this.attributeOptions = this.attributeOptions.filter(op => !op.hidden)

      if(this.attributeOptions?.length){
        this.collectAttrOptions = true
      }else{
        // await this.getAttributes();
        this.constructGetOptions()
      }

    } else {  // default flow for 2nd hit when options available
      console.log("[CONSTRUCT ATTR OPTIONS] - if 3")
      this.attributeOptions.forEach(op => {
        this.availableInputParams.options[op.__id || op.name] = op.value
        if(op?.hidden == true) this.attributeOptions.splice(this.attributeOptions.indexOf(op),1);
      })
      // await this.getAttributes();
      if(this.attributeOptions?.length){
        this.collectAttrOptions = true
      }
      this.constructGetOptions()
    }

  }



  // calculates if input parameters needed for get fn
  constructGetOptions(){

    console.log("[CONSTRUCT GET OPTIONS]")

    this.getFn = this.objectFuntions.find(fn => fn.__id == 'get')

    if(this.firstHit){
      console.log("[CONSTRUCT GET OPTIONS] if (1)")
      this.getFnOptions = this.pageService.checkOptionsToCollect(this.getFn)
      this.getFnOptions = this.getFnOptions.filter(op => !op.hidden)
      if(!this.getFnOptions?.length) {
        this.getAttributes()
        return
      }
      this.getFnOptions?.forEach(el => {
        el.value = this.availableInputParams.options[el.__id] || el.defaultValue || ""
      })
      this.collectGetOptions = true

    } else if(!this.firstHit && (this.boxChanged || this.boxObjectChanged)){
      console.log("[CONSTRUCT GET OPTIONS] if (2)")
      this.getFn = this.objectFuntions.find(fn => fn.__id == 'get')
      this.getFnOptions = this.pageService.checkOptionsToCollect(this.getFn)
      if(!this.getFnOptions?.length) {
        this.getAttributes()
        return
      }
      this.getFnOptions = this.getFnOptions.filter(op => !op.hidden)
      this.getFnOptions?.forEach(el => {
        el.value = this.availableInputParams.options[el.__id] || el.defaultValue || ""
      })

      this.collectGetOptions = true

    } else{
      console.log("[CONSTRUCT GET OPTIONS] if (3)")
      if(!this.getFnOptions?.length){
        console.log("no options to collect, calling constructActionOptions() and return")
        this.getAttributes()
        return
      }
      this.getFnOptions.forEach(el => {
        // if some option already has value in availableInputParams, use it
        if(this.availableInputParams.options.hasOwnProperty(el.__id)){
          el.value = this.availableInputParams.options[el.__id]
        }else{ // if a new property, keep back in availableInputParams for further use in actionOptions
          this.availableInputParams.options[el.__id] = el.value
        }
      })
      console.log("availableInputParams", this.availableInputParams)

      console.log("making collectGetOptions true")
      this.collectGetOptions = true
      this.getAttributes()
    }
  }

  /**
   * bound with event emitter to catch input parameters for other getFn functions
   * @param getFnInputParams
   */
  getOptionInputsRecevied(getFnInputParams){
    console.log("getFn input params received", getFnInputParams)
    if(!getFnInputParams?.options) return
    Object.keys(getFnInputParams.options).forEach(optionId => {
      this.getFnOptions.forEach(getOption => {
        if(getOption.__id == optionId){
          getOption.value = getFnInputParams.options[optionId]
        }
      })
    })
    console.log("get options", this.getFnOptions)
  }

  boxSelectionError(event){
    console.log("box selection error", event)
    this.isBoxConfigError = true
    this.boxConfigError = event
  }

  boxObjectSelectionError(event){
    console.log("box object selection error", event)
    this.isBoxObjectConfigError = true
    this.boxObjectConfigError = event
  }

  attributeOptionChanged(event, index){
    this.attributeOptions[index]['value'] = event.srcElement.value
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.detailsAttributes, event.previousIndex, event.currentIndex);
  }

  widgetMenuClosed(event: any){
    console.log("widget menu closed", event)
  }

  widgetTypeSelected(attrIndex: any, widgetType: string){
    console.log("widgetType selected", widgetType)
    this.detailsAttributes[attrIndex]['widgetType'] = widgetType
    console.log("modified list attributes", this.detailsAttributes)
  }

  enabledChanged(checked: any, i: number){
    console.log("enable toggle changed for", i, "value:", checked, this.detailsAttributes[i])
    this.detailsAttributes[i].enabled = checked
  }

  filterChanged(event){
    console.log("filter changed", event)
    this.panelMeta.filter = event;
  }

  /**
   * bound with event emitter to catch input parameters for getAttribute functions
   * @param attrInputParams
   */
  attrOptionInputsRecevied(attrInputParams){
    console.log("attribute input params received", attrInputParams)
    if(!attrInputParams.options) return
    Object.keys(attrInputParams.options).forEach(optionId => {
      this.attributeOptions.forEach(attrOp => {
        if(attrOp.__id == optionId){
          attrOp.value = attrInputParams.options[optionId]
        }
      })
      // update availableInputParams for further use
      this.availableInputParams['options'][optionId] = attrInputParams.options[optionId]
    })
    console.log("availableInputParams updated", this.availableInputParams)
    console.log("attribute options", this.attributeOptions)
  }

  // check if all required getAttributes options provided
  checkAttributeOptionsProvided(){
    this.faultyAttrOptions = []
    this.attributeOptions.forEach((option: any) => {
      if(option.required && !option['value']){
        console.log("value for ", option.name, "not provided")
        this.attributeOptionsError = true
        this.faultyAttrOptions.push(option)
      }
    })
    if(!this.faultyAttrOptions.length) this.attributeOptionsError = false
    console.log("missing attribute options", this.faultyAttrOptions)
  }

  // check if all required options provided for get function
  checkGetOptionsProvided(){
    this.faultyGetOptions = []
    this.getFnOptions.forEach((option: any) => {
      if(option.required && !option['value']){
        console.log("value for ", option.name, "not provided")
        this.getOptionsError = true
        this.faultyGetOptions.push(option)
      }
    })
    if(!this.faultyGetOptions.length) this.getOptionsError = false
    console.log("missing get options", this.faultyGetOptions)
  }

  showLabelChanged(event){
    console.log("show labels changed", event)
    this.showLabels = event.checked
  }

  getOptionChanged(event, index){
    this.getFnOptions[index]['value'] = event.srcElement.value
    console.log("this.getFnOptions", this.getFnOptions)
  }

  drillDownToggle(i){
    console.log("drill down toggle for ", this.detailsAttributes[i])
    if(!this.detailsAttributes[i].isDrillDown) this.detailsAttributes[i]['isDrillDown'] = true
    else this.detailsAttributes[i].isDrillDown = false
  }

  nestedValueWidgetSelected(widgetType, i, j){
    let attr = this.detailsAttributes[i]
    console.log(widgetType, "for", attr.__id, ".", attr['nestedProperties']?.[j]?.['path'])
    attr.nestedProperties[j]['widgetType'] = widgetType
    console.log("attr", attr)
  }

  addNestedproperty(i){
    let attr = this.detailsAttributes[i]
    if(!attr.nestedProperties){
      attr['nestedProperties'] = []
    }
    attr.nestedProperties.push({
      path: '',
      widgetType: 'label'
    })
    console.log("attr now", attr)
  }

  deleteNestedProperty(i, j){
    let attr = this.detailsAttributes[i]
    attr.nestedProperties.splice(j, 1)
    console.log("attr now", attr)
  }

  async saveConfig() {
    // console.log("Save config hit at list panel", this.panelMeta)

    this.checkAttributeOptionsProvided()
    this.checkGetOptionsProvided()

    if(this.attributeOptionsError || this.getOptionsError) return

    console.log("filter", this.panelMeta.filter)
    if(this.panelMeta.filter?.filterEnabled && (!this.panelMeta.filter.filterItems?.length || this.panelMeta.filter.filterItems[0].attribute == "")){
      console.log("if")
      this.noFilterData = true;
      return;
    }

    this.panelMeta.name = this.detailsPanelTitle

    this.detailsAttributes = this.detailsAttributes.filter(attr => attr.enabled)

    this.panelMeta.connectionId = this.selectedConnectionId
    this.panelMeta.boxId = this.selectedBoxId
    this.panelMeta.boxName = this.selectedBoxName
    this.panelMeta.boxObjectId = this.selectedBoxObjectId
    this.panelMeta.boxConfigToken = this.boxConfigToken
    this.panelMeta.attributeOptions = this.attributeOptions
    this.panelMeta.getFnOptions = this.getFnOptions
    this.panelMeta.detailsPanelTitle = this.detailsPanelTitle || `${this.selectedBoxName} - ${this.selectedBoxObjectId} - Details`
    this.panelMeta.submitButtonTitle = this.submitButtonTitle
    this.panelMeta.primaryAttribute = this.boxObjectAttributes.find(attr => attr.primary)

    this.panelMeta.detailsAttributes = this.detailsAttributes
    this.panelMeta.showLabels = this.showLabels

    this.panelMeta.layoutMap = await this.automationService.generateDetailsWidgets(this.panelMeta);
    this.panelMeta.widgets = [];
    // this.panelMeta.submitButtonMeta = this.submitButtonMeta

    this.data = this.panelMeta
    console.log("save config in details panel dialog: will return data:", this.data)

    this.dialogRef.close(JSON.parse(JSON.stringify(this.data)))
  }

  hideTitleChanged(event){
    console.log("hide title changed", event)
    this.panelMeta['hideTitle'] = event.checked
  }

}
