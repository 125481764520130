import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ListPanelModule } from "src/app/bloom/specialized-panels/list-panel/list-panel.module"
import { StarchBaseComponent } from './starch-base.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { StarchBaseDiologComponent } from '../starch-base-diolog/starch-base-diolog.component';
import { AttributeParameterInputsModule } from 'src/app/shared/input-parameters/attribute-parameter-inputs/attribute-parameter-inputs.module';
import { StarchAddBaseModule } from '../starch-add-base/starch-add-base.module';
import { StarchBasePopupComponent } from '../starch-base-popup/starch-base-popup.component';
import { StarchAtrributesSidePanelComponent } from '../starch-attibute-side-panel/starch-attibute-side-panel.component';
import { autocompleteModule } from 'src/app/shared/mat-autocomplete/mat-autocomplete.module';

@NgModule({
  declarations: [StarchBaseComponent, StarchBaseDiologComponent, StarchBasePopupComponent, StarchAtrributesSidePanelComponent],
  imports: [
    CommonModule,
    SharedModule,
    ListPanelModule,
    SpinnerModule,
    AttributeParameterInputsModule,
    StarchAddBaseModule,
    autocompleteModule
  ],
  exports: [StarchBaseComponent, StarchBaseDiologComponent, StarchBasePopupComponent,StarchAtrributesSidePanelComponent]
})
export class StarchBaseModule { }
