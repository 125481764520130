import * as cryptoJs from 'crypto-js';
import { environment } from 'src/environments/environment';

export class SMSTokenUtil {

 static getStatelessToken(){
    const SECRET_KEY = environment.SMS_SECRET_KEYS.SECRET_KEY;
    const COMPARE_KEY = environment.SMS_SECRET_KEYS.COMPARE_KEY;
    var payload = {
      key: COMPARE_KEY
    }
    const expireInMin = 1; //Set the expiration time is 1 minute
    return this.createJwt(SECRET_KEY,expireInMin, payload)
  }

  static createJwt(privateKey:string, expiresInMinutes: number, payload:any){

    var header = {
      "alg": "HS256",
      "typ": "JWT"
    };

    const now = Date.now();
    const expires = new Date(now);
    expires.setMinutes(expires.getMinutes() + expiresInMinutes);

    var stringifiedHeader = cryptoJs.enc.Utf8.parse(JSON.stringify(header));
    var encodedHeader = this.base64url(stringifiedHeader);

    var data:any = {
      exp: Math.round(expires.getTime() / 1000),
      iat: Math.round(now / 1000),
    };

    Object.keys(payload).forEach(function(key){
      data[key] = payload[key]
    })

    var stringifiedData = cryptoJs.enc.Utf8.parse(JSON.stringify(data));
    var encodedData = this.base64url(stringifiedData);

    var token = encodedHeader + "." + encodedData;

    var signature:any = cryptoJs.HmacSHA256(token, privateKey);
    signature = this.base64url(signature);

    var signedToken = token + "." + signature;
    return signedToken;
  }

  static base64url(source:any) {
    // Encode in classical base64
    var encodedSource = cryptoJs.enc.Base64.stringify(source)//CryptoJS.enc.Base64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }
}
