<div [class.disabled]="!editMode">
  <div>
    Configuration
  </div>
  <br/>
  <div style="padding: 0px 10px 0px 10px;" class="container-with-thin-scrollbar scrollable-container">

    <div *ngIf="editMode;">
      <div fxLayout="row">
          <mat-form-field style="width: 100%;" appearance="outline">
              <mat-label>Flow name</mat-label>
              <input matInput placeholder="Flow Name" [(ngModel)]="flow.name">
          </mat-form-field>
      </div>
      <div fxLayout="row">
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label>Flow description</mat-label>
              <textarea matInput placeholder="Description" [(ngModel)]="flow.description"></textarea>
          </mat-form-field>
      </div>
    </div>
    <div *ngIf="!editMode;">
      <br/>
      <div>
          <div fxLayout="row" style="width: 100%;" fxLayoutAlign="start center">
              {{ flow.name }}
          </div>
          <div fxLayout="row"  fxLayoutAlign="start center">
              <div style="width: 100%;">
                  {{ flow.description }}
              </div>
          </div>
      </div>
    </div>
  </div>
</div>

