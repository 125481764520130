<div fxLayout="row" fxLayoutAlign="center center">
  <h3>Object Configuration</h3>
</div>


<br/>

<div fxLayout="row" fxLayoutAlign="center center" style="margin-top: -20px;">
  <div fxLayout="column" fxFlex="5"></div>
  <div fxLayout="column" fxFlex="90">
      <mat-list role="list">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <mat-list-item *ngIf="node.name !== '__add_object'" >
              <!-- cdkDropList (cdkDropListDropped)="dropWidget(node)" -->
               <div fxLayout="row" style="width: 100%;"
                (mouseover)="mouseover($event, node)" (mouseout)="mouseout($event, node)" >
                  <div  fxLayout="column" fxFlex="80">
                    <!-- cdkDrag -->
                  <div fxLayout="row"  class="border">
                    <div fxLayout="column" fxFlex="10">
                    </div>
                    <!-- <div fxLayout="column" fxFlex="10">
                      <mat-icon *ngIf="node.hovered" matTooltip="Drag to build visual on {{node.name}}" style="cursor: pointer;margin-left: -10px;margin-top: -3px;color: #565656;"
                      (click)="openStarchEditor(node)"  > drag_indicator </mat-icon>
                    </div> -->
                    <div fxLayout="column" fxFlex="10">
                      <mat-icon style="margin-left: -8px;;margin-top: -1px;    font-size: 20px;"
                         matTooltip="" color="accent"
                        >table_rows</mat-icon>
                    </div>
                    <div fxFlex="70" matTooltip="Drag to build visual on {{node.name}}" style="cursor: pointer"
                     > {{node.name}}</div>
                  </div>

                  </div>
                  <!-- <div fxLayout="column" fxFlex="10">
                    <mat-icon style="cursor: pointer;color: #3c3c3c;" matTooltip="Visualize table"
                    (click)="navigateToExplorer(node)">visibility</mat-icon>
                  </div> -->
                  <div fxLayout="column" fxFlex="10">
                    <mat-icon style="cursor: pointer;color: #3c3c3c;" matTooltip="Edit attributes"
                    (click)="openStarchEditor(node)">settings</mat-icon>
                    <!-- *ngIf="node.hovered" -->
                  </div>
                  <div fxLayout="column" fxFlex="10">
                    <mat-icon style="cursor: pointer;" color="warn"
                    matTooltip="Delete base object" (click)="deleteBaseObject(node)">delete</mat-icon>
                    <!-- *ngIf="node.hovered" -->
                  </div>
                </div>
            </mat-list-item>


            <div *ngIf="!node.addNewObject && node.name === '__add_object'">
              <button mat-button color="primary" (click)="node.addNewObject = true;setDefaultObjectName(node, objectMap)">
                  <mat-icon color="primary">add</mat-icon>  New object</button>
            </div>

            <div *ngIf="node.addNewObject">
              <div style="min-height: 100px;background-color: transparent;" fxLayout="row wrap" fxLayoutAlign="center center">
                  <div fxLayout="column">
                      <div fxLayout="row" style="margin-top: 10px;" fxLayoutAlign="center center" >
                          <div fxLayout="column" class="base-field" fxFlex="100">
                              <mat-form-field appearance="outline" style="width: 100%;">
                                  <mat-label> Object </mat-label>
                                  <input required [(ngModel)]="objectMap.object" matInput placeholder="Object">
                              </mat-form-field>
                          </div>
                          <div fxLayout="column" fxFlex="20">
                          </div>
                      </div>
                  </div>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="center center">
                  <div fxLayout="column">
                      <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: -15px;margin-bottom: 5px;">
                          <div fxLayout="column" fxFlex="45">
                              <button class="base-button" mat-stroked-button (click)="node.addNewObject = false" [mat-dialog-close]="false" color="primary"  type="button">
                                  Cancel
                                </button>
                          </div>
                          <div fxFlex="10"></div>
                          <div fxLayout="column" fxFlex="45">
                              <button  class="base-button" mat-flat-button (click)="node.addNewObject = false;createObjectAndSetTree(objectMap, node)" color="primary" type="button">
                                  Create
                                </button>
                          </div>
                     </div>
                  </div>
              </div>
            </div>
          </mat-tree-node>
          <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
             <mat-list-item>

              <div fxLayout="row" (mouseover)="mouseover($event, node)" (mouseout)="mouseout($event, node)"
              style="width: 100%;margin: top -20px !important;;margin: bottom -20px !important;" fxLayoutAlign="start center">
                <!-- <div fxLayout="column" fxFlex="10">

                </div> -->
                <div fxLayout="column" fxFlex="10">
                  <mat-icon style="margin-left: 2px;"
                   matTooltip="" color="accent"
                  >dataset</mat-icon>
                </div>
                <div fxLayout="column" fxFlex="70" fxLayoutAlign="center start">
                  <span  style="overflow: hidden;white-space: nowrap;width: 100%" *ngIf="!node.isEdit" matTooltip="{{node.name}}">{{node.name}}</span>
                  <mat-form-field floatLabel="never" *ngIf="node.isEdit" style="width: 100%;">
                    <input required (blur)="editObjectNameFocusOut(node)" [(ngModel)]="node.name" matInput placeholder="base name">
                  </mat-form-field>
                </div>

                <div fxLayout="column" fxFlex="10">
                </div>
                <div fxLayout="column" fxFlex="10">
                </div>
                <div fxLayout="column" fxFlex="10">
                </div>
               </div>
            <mat-spinner diameter="20" *ngIf="node.spin"></mat-spinner>
            </mat-list-item>
          </mat-tree-node>
      </mat-tree>

      </mat-list>
  </div>
  <div fxLayout="column" fxFlex="5"></div>
</div>
