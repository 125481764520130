import { Injectable } from '@angular/core';
import * as cryptoJs from 'crypto-js';
import { AuthServiceService } from '../shared/services/auth-service.service';
import { environment } from 'src/environments/environment';

const DEFAULT_KEY = 'DuolcMroftalpSurvij';
class logReport {
  apiUrl: string;
  dateTime: any;
  response: any;
  requestType: string;
  request?: any;
  statuscode?: any;
  isError?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class PlaygroundService {
  boxUrl: string = environment.BOX_URL;
  boxConfigToken: string;
  boxResult: any[];
  selectedBox: any;
  spinner: boolean = false;
  storeLogData: logReport[] = [];
  storeLogDataFunction(Response?: any, Request?: any) {
    var storeLogDataObj: logReport = {
      apiUrl: Request.apiUrl,
      dateTime: Date(),
      response: Response.response,
      requestType: Request.requestType,
      request: Request.request,
      statuscode: Response.statusCode,
      isError: Response.isError,
    };
    this.storeLogData.push(storeLogDataObj);
  }
  getUserEmail() {
    if (this.authService.profile.length > 0) {
      return this.authService.profile.email;
    } else {
      return '';
    }
  }
  // generate AES email set it into PreAuthenticatedToken
  getPreAuthenticatedToken(email: string) {
    try {
      return cryptoJs.AES.encrypt(email, DEFAULT_KEY).toString();
    } catch (e) {
      console.log(e);
    }
  }
  constructor(private authService: AuthServiceService) {}
}
