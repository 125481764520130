import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
  selector: 'app-test-webhook',
  templateUrl: './test-webhook.component.html',
  styleUrls: ['./test-webhook.component.scss']
})
export class TestWebhookComponent implements OnInit {
  urlQueryString: string = "";
  queryBody: any = {};
  isBrowser: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogRef: any,
    public dialog: MatDialogRef<TestWebhookComponent>,
    public http: HttpClient,
    private spinner: SpinnerService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
   }

  flow: any = this.dialogRef?.flowMap;
  headerKey: any = "Authorization";
  headerValue: any;
  body: any = {};
  webhookUrl: any = '';
  isErrorOnWebhook: boolean = false;
  response: any;
  statusCode: number | undefined;
  ngOnInit(): void {
    if(!this.isBrowser) return;
    if (this.flow?.trigger?.is_authenticate) this.headerValue = JSON.parse(JSON.stringify(this.flow?.trigger?.authenticate_map?.secret_key));
    if (this.flow?.trigger?.webhook_url) this.webhookUrl = this.flow?.trigger?.webhook_url;
    if (this.flow?.trigger?.payload_data.length > 0) {
      for (var i = 0; i < this.flow?.trigger?.payload_data.length; i++) {
        this.body[this.flow?.trigger?.payload_data[i]?.__id] = "";
      }
    }
    if (this.flow?.trigger?.query_data.length > 0) {
      let length = this.flow?.trigger?.query_data.length;
      for (var i = 0; i < this.flow?.trigger?.query_data.length; i++) {
        let joiner = i > 0 ? "&" : i == 0 ? "?" : "";
        console.log("joiner i", i, joiner)
        this.urlQueryString = this.urlQueryString + joiner + this.flow?.trigger?.query_data[i]?.["__id"] + "=${" + this.flow?.trigger?.query_data[i]?.["__id"] + "}";
        this.queryBody[this.flow?.trigger?.query_data[i]?.__id] = "";
      }
    }
  }

  onChangeQueryValue() {

  }

  async sendWebhook() {
    console.log("body", this.body)
    console.log("header", this.headerKey, this.headerValue)
    this.spinner.show();

    var te = new TemplateEngine()
    if (this.flow?.trigger?.query_data.length > 0) {
      this.urlQueryString = te.fillAny(this.urlQueryString, this.queryBody)
    }

    this.webhookUrl = this.webhookUrl + this.urlQueryString;

    try {
      var options: any = {};
      if (this.flow?.trigger?.is_authenticate) {
        options.headers = {
          Authorization: this.headerValue
        }
      }

      var res: any;
      if (this.flow?.trigger?.method == "GET") {
        res = await this.http.get(this.webhookUrl, options).toPromise();
      } else if (this.flow?.trigger?.method == 'PUT') {
        res = await this.http.put(this.webhookUrl, this.body, options).toPromise();
      } else {
        res = await this.http.post(this.webhookUrl, this.body, options).toPromise();
      }

      this.response = res;
      this.statusCode = res?.status;
      console.log("[TEST HOOK] response: ", res.status, this.response);

    } catch (e) {
      console.log("[TEST HOOK] error: ", e)
      this.isErrorOnWebhook = true;
      this.response = e;
      throw e;
    }
   // Scroll to the "Webhook executed" section
   setTimeout(() => {
    const executionDetails = this.document.getElementById('executionDetails');
    if (executionDetails) {
      executionDetails.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, 0);

  this.spinner.hide();
}

  closePopup() {
    this.dialog.close();
  }

  deleteQueryParameter(index: number): void {
    this.flow.trigger.query_data.splice(index, 1);
  }

  deleteBodyParameter(index: number): void {
    this.flow.trigger.payload_data.splice(index, 1);
  }

  addNewQueryParameter(): void {
    this.flow.trigger.query_data.push({
      dataType: "string",
      name: "",
      __id: ""
    });
  }

  addNewBodyParameter(): void {
    this.flow.trigger.payload_data.push({
      dataType: "string",
      name: "",
      __id: ""
    });
  }
// headers: { key: string, value: string }[] = [];

// addNewHeader() {
//   this.headers.push({ key: '', value: '' });
// }

// deleteHeader(index: number) {
//   this.headers.splice(index, 1);
// }
headers: { key: string; value: string }[] = [];

addNewHeader() {
  if (this.headerKey.trim() && this.headerValue.trim()) {
    this.headers.push({ key: this.headerKey.trim(), value: this.headerValue.trim() });
    this.headerKey = '';
    this.headerValue = '';
  }
}

deleteHeader(index: number) {
  this.headers.splice(index, 1);
}
}
