import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { WidgetRegistry } from 'src/app/bloom/models/WidgetRegistry';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';

interface ExpressionConfiguration {
  id: string,
  parameters: {
    [key: string]: ParamData[]  // paramId --> array of paramValueObject
  }
}

interface ParamData {
  value: any,
  dynamic?: boolean,
  dynamicSource?: string,
  [key: string]: any
}

@Component({
  selector: 'app-expression-configuration',
  templateUrl: './expression-configuration.component.html',
  styleUrls: ['./expression-configuration.component.scss']
})
export class ExpressionConfigurationComponent implements OnInit {

  paramsData = {}
  pageMeta: any
  dynamicPageValueOptions: any[] = []
  idDynamicOptionsGenerated: boolean = false  // to indicate whether pageMeta was parsed to generate options or not

  @Input() selectedExpression: any
  @Input() widgetExpressionConfig: any
  @Output() raiseExpressionConfig = new EventEmitter<ExpressionConfiguration>()

  constructor(private metaService: MetaService, public widgetService: WidgetService) { }

  ngOnInit(): void {

    console.log("this.widgetExpressionConfig", this.widgetExpressionConfig)
    this.pageMeta = this.metaService.pageMeta.value
    // console.log("[expression configuration]", changes)
    this.generateDynamicPageValueOptions()

    // ---------- initialize paramsData --------------
    this.paramsData = {}
    this.selectedExpression.parameters.paramIds.forEach(paramId => {
      this.paramsData[paramId] = {
        currentValues: [],
        max: this.selectedExpression.parameters[paramId].maxCount,
        min: this.selectedExpression.parameters[paramId].minCount,
      }

      if(this.widgetExpressionConfig?.[paramId]?.length){
        this.paramsData[paramId].currentValues = this.widgetExpressionConfig?.[paramId]
      }else{
        for (let i = 0; i < this.selectedExpression.parameters[paramId].minCount; i++) {
          let temp: ParamData = {
            value: this.selectedExpression.parameters[paramId].defaultValue,
            dynamic: false,
            dynamicSource: '',
            widget: undefined,
            typeSelectionWidget: undefined,
            dynamicInputWidget: undefined
          }
          this.paramsData[paramId].currentValues.push(temp)
        }
      }

      // create widgets for param input
      this.paramsData[paramId].currentValues.forEach(paramValueObj => {

        // 1. value input
        let widget = WidgetRegistry.getWidget(this.selectedExpression.parameters[paramId].widgetType)
        if(widget.isOptionBasedWidget()){
          let options = this.selectedExpression.parameters[paramId].availableOptions
          options.forEach(op => {
            op['defualt'] = op.hasOwnProperty('default') ? op['default'] : false
          });
          paramValueObj.widget = this.createInputWidget(paramId, this.selectedExpression, options);
        }else{
          paramValueObj.widget = this.createInputWidget(paramId, this.selectedExpression, paramValueObj.value);
        }


        // 2. attach value type widget and dynamic value selection widget
        if(this.selectedExpression.parameters[paramId].dynamicAllowed){
          paramValueObj.typeSelectionWidget = this.createValueTypeSelectionWidget()
          paramValueObj.dynamicInputWidget = this.createDynamicInputWidget()
        }
      })
    });
    console.log("paramsData prepared", this.paramsData)
    this.emitExpressionData()
    // -----------------------------------------------
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  /**
   *
   * @returns
   */
  createValueTypeSelectionWidget(){
    let valueTypeSelectionWidget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget('select')))
    // console.log("TYPE SELECTION widget", valueTypeSelectionWidget.config.availableOptions.staticOptions)
    // valueTypeSelectionWidget.config.availableOptions.staticOptions = null
    let options: any[] = [
      {name: 'Static', value: 'static', default: true},
      {name: 'Dynamic', value: 'dynamic', default: false}
    ]
    // console.log("options to set", options)
    // valueTypeSelectionWidget.setValue(options, true)
    valueTypeSelectionWidget.config.availableOptions.staticOptions = options
    // console.log("valueTypeSelectionWidget injected:", valueTypeSelectionWidget)

    return valueTypeSelectionWidget
  }

  /**
   *
   * @returns
   */
  createDynamicInputWidget(){
    let widget = JSON.parse(JSON.stringify(WidgetRegistry.getWidget('select')))
    if(!widget) return undefined
    // console.log("DYNAMIC SELECTION widget", JSON.parse(JSON.stringify(widget)))
    let options = JSON.parse(JSON.stringify(this.dynamicPageValueOptions))
    options.forEach(op => op['default'] = false);
    // console.log("dynamic options", options)
    widget.config.availableOptions.staticOptions = options
    // widget.setValue(JSON.parse(JSON.stringify(options)), true)
    // console.log("dynamic selection widget injected", JSON.parse(JSON.stringify(widget)))
    return widget
  }



  /**
   * creates suitable input widget and keeps in this.paramsWidgets
   * @param paramId
   * @param selectedExpression
   * @param value : current value of the param
   */
   createInputWidget(paramId: string, selectedExpression: any, value){
    let widgetType = selectedExpression.parameters[paramId].widgetType

    let widget = WidgetRegistry.getWidget(widgetType)
    // console.log("input widget", widget)
    if(!widget){
      console.error("specified widget type not available for param input")
      throw new Error("specified widget type not available for param input")
    }

    if(widget.isOptionBasedWidget()){
      widget = JSON.parse(JSON.stringify(widget))
      widget.config.availableOptions.staticOptions = value
    }else{
      widget.setValue(value)
    }
    // console.log("widget injected", widget)
    return widget
  }


  generateDynamicPageValueOptions(){
    this.dynamicPageValueOptions = []
    let pageMeta = this.metaService.pageMeta.value
    pageMeta.panels.forEach(panelMeta => {
      let widgets = this.widgetService.getWidgetsFromPanel(panelMeta);
      widgets.forEach(widgetMeta => {
        this.dynamicPageValueOptions.push({
          name: `${panelMeta.name} : ${widgetMeta.name}`,
          value: `${panelMeta.id}.${widgetMeta.id}`
        })
      })
    })
    // console.log("this.dynamicPageValueOptions", JSON.parse(JSON.stringify(this.dynamicPageValueOptions)))
  }

  expressionValueReceived(value, paramId, i){
    console.log("VALUE-------------")
    console.log("value", value.value)
    console.log("paramId", paramId)
    console.log("i", i)
    this.paramsData[paramId].currentValues[i].value = value.value
    console.log("paramsData", this.paramsData)
    this.emitExpressionData()
  }

  valueTypeChanged(type, paramId, i){
    console.log("TYPE-------------")
    console.log("type", type.value)
    console.log("paramId", paramId)
    console.log("i", i)
    if(type.value == 'dynamic'){
      this.paramsData[paramId].currentValues[i].dynamic = true
    }else{
      this.paramsData[paramId].currentValues[i].dynamic = false
    }
    console.log("paramsData", this.paramsData)
  }

  dynamicValueSelected(value, paramId, i){
    console.log("DYNAMIC VALUE-------------")
    console.log("value", value.value)
    console.log("paramId", paramId)
    console.log("i", i)
    this.paramsData[paramId].currentValues[i].dynamicSource = value.value
    this.emitExpressionData()
  }

  removeItem(paramId, i){
    if((this.paramsData[paramId].currentValues.length - 1) >= this.paramsData[paramId].min){
      this.paramsData[paramId].currentValues.splice(i, 1)
      console.log("item removed", this.paramsData)
    }else{
      console.log("can not remove, min value count reached")
    }
    this.emitExpressionData()
  }

  addParamItem(paramId){
    let paramValueObj = {
      value: this.selectedExpression.parameters[paramId].defaultValue,
      dynamic: false,
      dynamicSource: '',
      widget: undefined,
      typeSelectionWidget: undefined,
      dynamicInputWidget: undefined
    }

    // 1. value input
    paramValueObj.widget = this.createInputWidget(paramId, this.selectedExpression, paramValueObj.value);

    // 2. attach value type widget and dynamic value selection widget
    if(this.selectedExpression.parameters[paramId].dynamicAllowed){
      paramValueObj.typeSelectionWidget = this.createValueTypeSelectionWidget()
      paramValueObj.dynamicInputWidget = this.createDynamicInputWidget()
    }

    this.paramsData[paramId].currentValues.push(paramValueObj)
    console.log("paramsData", this.paramsData)
    this.emitExpressionData()
  }

  emitExpressionData(){

    let parameters: any = {}
    Object.keys(this.paramsData).forEach(paramId => {
      parameters[paramId] = []
      this.paramsData[paramId].currentValues.forEach(paramValObj => {
        parameters[paramId].push({
          value: paramValObj.value,
          dynamic: paramValObj.dynamic,
          dynamicSource: paramValObj.dynamicSource
        })
      });
    })
    console.log("expression parameters", parameters)

    // let expressionConfig: ExpressionConfiguration = {
    //   id: this.selectedExpression.id,
    //   parameters: parameters
    // }

    this.widgetExpressionConfig.id = this.selectedExpression.id;
    this.widgetExpressionConfig.parameters = parameters
    this.raiseExpressionConfig.emit(this.widgetExpressionConfig)
  }
}
