import { BaseRouterURL } from "./BaseRouterURL";
import { RouterUtility } from "./RouterUtility";

export class DefaultURL extends BaseRouterURL {


  public getBaseUrls(env: any, type: any, options?: any) {
    const filteredUrls = [];
    let config = RouterUtility.getConfig(env);
    for (const key in config) {
      if (key !== "list" && config?.hasOwnProperty(key)) {
        const obj = config[key];
        if (obj && obj.fallback === true) {
          filteredUrls.push(obj.url);
        }
      }
    }
    return filteredUrls;
  }


}
