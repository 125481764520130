import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectComponent } from './mat-select.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@NgModule({
  declarations: [MatSelectComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatSelectModule,
    MatFormFieldModule
  ],
  exports: [MatSelectComponent]
})
export class SelectModule { }
