<div
  *ngIf="builderMode"
  class="outerCover" #cover fxLayout="row" fxLayoutAlign="center center" fxFlexFill
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow" (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()" (click)="onClick($event)" cdkDragHandle
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>

  <div>
   
    <div fxLayoutAlign="center center">
      <div fxLayoutAlign="center center" ><h3>{{widgetMeta.config?.title?.value || 'Connection List'}}</h3>  </div>
    </div>

    <div *ngIf="whiteLabeledConnections?.length">
      <div  *ngFor="let con of whiteLabeledConnections;let i = index">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxFlex="10">
              <img *ngIf="con?.options?.['box_logo_url']"  matTooltip="{{con?.options?.['box_name']}}"
                mat-card-image src="{{ con?.options?.['box_logo_url'] }}"
                style="height: 20px; width: 20px; margin: 5px 0px 5px 0px" />
          </div>
          <div fxLayout="column" fxFlex="80" fxLayoutAlign="center start" matTooltip="{{con.name}}">
            <div fxLayout="row" style="overflow: hidden;white-space: nowrap;width: 100%">{{con.name}}</div> 
          </div>
          <div fxLayout="column" fxFlex="5">
            <mat-icon
           (click)="editConnection(con, i)" style="cursor: pointer;margin-left: -6px;" color="accent"
             matTooltip="Edit Connection">edit</mat-icon>
          </div>
          <div fxLayout="column" fxFlex="5">
            <mat-icon (click)="deleteConnection(con, i)" style="cursor: pointer;" color="warn"
            matTooltip="Delete Connection">delete</mat-icon>
          </div>
         </div>
        </div>
      </div>

    <div>
      <button fxLayoutAlign="start center" mat-button color="primary"
        matTooltip="Add new connection" (click)="pickBox()">
        <mat-icon class="iconInButton">add</mat-icon>
        New connection
      </button>
    </div>
  </div>

</div>



<div
  *ngIf="!builderMode"
  class="outerCover" #cover fxLayout="row" fxLayoutAlign="center center" fxFlexFill
  (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>

<div>
   
  <div fxLayoutAlign="center center">
    <div fxLayoutAlign="center center" ><h3>{{widgetMeta.config?.title?.value || 'Connection List'}}</h3>  </div>
  </div>

  <div *ngIf="whiteLabeledConnections?.length">
    <div  *ngFor="let con of whiteLabeledConnections;let i = index">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="10">
            <img *ngIf="con?.options?.['box_logo_url']"  matTooltip="{{con?.options?.['box_name']}}"
              mat-card-image src="{{ con?.options?.['box_logo_url'] }}"
              style="height: 20px; width: 20px; margin: 5px 0px 5px 0px" />
        </div>
        <div fxLayout="column" fxFlex="80" fxLayoutAlign="center start" matTooltip="{{con.name}}">
          <div fxLayout="row" style="overflow: hidden;white-space: nowrap;width: 100%">{{con.name}}</div> 
        </div>
        <div fxLayout="column" fxFlex="5">
          <mat-icon
         (click)="editConnection(con, i)" style="cursor: pointer;margin-left: -6px;" color="accent"
           matTooltip="Edit Connection">edit</mat-icon>
        </div>
        <div fxLayout="column" fxFlex="5">
          <mat-icon (click)="deleteConnection(con, i)" style="cursor: pointer;" color="warn"
          matTooltip="Delete Connection">delete</mat-icon>
        </div>
       </div>
      </div>
    </div>

  <div>
    <button fxLayoutAlign="start center" mat-button color="primary"
      matTooltip="Add new connection" (click)="pickBox()">
      <mat-icon class="iconInButton">add</mat-icon>
      New connection
    </button>
  </div>
</div>


<!-- <div>

  <div fxLayoutAlign="center center" ><h3>{{widgetMeta.config?.title?.value || 'Connection'}}</h3>  </div>
  <button fxLayoutAlign="center center" mat-button color="primary"
   matTooltip="create connection" (click)="pickBox()" *ngIf="!isWhiteLabeled">
   <mat-icon class="iconInButton">add</mat-icon>
   Create connection
 </button>

  <mat-card *ngIf="isWhiteLabeled" fxLayout="row" >
  <img *ngIf="whiteLabeledConnection?.options?.box_logo_url" class="image" [src]="whiteLabeledConnection.options.box_logo_url"
   >
   Connected to {{whiteLabeledConnection?.options?.box_name}}
   <div *ngIf="!widgetMeta.config?.viewOnly?.value">
    <mat-icon color="accent" (click)="editConnection()" style="cursor: pointer;"> edit </mat-icon>
    <mat-icon color="warn" (click)="deleteConnection()" style="cursor: pointer;"> delete </mat-icon>
  </div>
  </mat-card>
</div> -->

</div>
