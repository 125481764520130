<div>
    <div class="ribbon-event" *ngIf="!hideRibbon">
      Trigger
    </div>
    <div fxLayout="row" class="mat-h1" fxLayoutAlign="center center">
      <mat-icon fxLayoutAlign="center center">webhook</mat-icon> &nbsp;
      <span fxLayoutAlign="center center">{{ getWebhookName() }}</span>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column">
        <div class="mat-h2">Webhook Link</div>
        <div fxLayout="row" fxLayout.xs="column" *ngIf="flowMap.trigger.webhook_url">
          <mat-car style="font-size: smaller;" appearance="outlined" class="card-1" fxLayout="row wrap" fxLayout.xs="column wrap">
            {{flowMap.trigger.webhook_url}}
          </mat-car>
        </div>

      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <button (click)="copyKeyButton = 'Copied'"
      [cdkCopyToClipboard]="flowMap.trigger.webhook_url" mat-stroked-button color="accent">{{copyKeyButton}}</button>
    </div>

    <div *ngIf="!flowMap.trigger.webhook_url" fxLayout="row" fxLayoutAlign="start center">
      <button (click)="getWebhookUrl()" mat-stroked-button color="accent">Show Webhook URL</button>
    </div>
    <br>

    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-label>Method</mat-label>
      <div *ngIf="!editMode"> :&nbsp; {{flowMap.trigger.method}} </div>
    </div>
    <div fxLayout="row wrap" fxLayout.xs="column wrap" fxLayoutAlign="start center" *ngIf="editMode">
        <mat-radio-group [(ngModel)]="flowMap.trigger.method" name="method" style="display: flex;">
          <mat-radio-button value="GET" style="margin-right: 5px;">GET</mat-radio-button>
          <mat-radio-button value="POST" style="margin-right: 5px;">POST</mat-radio-button>
          <mat-radio-button value="PUT" style="margin-right: 5px;">PUT</mat-radio-button>
          <mat-radio-button value="PATCH" style="margin-right: 5px;">PATCH</mat-radio-button>
        </mat-radio-group>
      </div>

    <div *ngIf="editMode" fxLayout="row" style="padding: 15px 0px 5px 0px">
      <mat-slide-toggle color="warn" [(ngModel)]="flowMap.trigger.is_authenticate">
        <span class="mat-h2">Authentication</span>
      </mat-slide-toggle>
    </div>
    <div style="margin-bottom: -18px;" fxLayout="row" *ngIf="flowMap.trigger.is_authenticate && editMode">
      <mat-form-field class="example-full-width" fxLayout="column" fxFlex="100">
        <mat-label>Secret key</mat-label>
        <input matInput placeholder="Provide secret key" [(ngModel)]="flowMap.trigger.authenticate_map.secret_key">
      </mat-form-field>
    </div>
    <div style="font-style: italic;color: #484848;font-size: smaller;" fxLayout="row" *ngIf="flowMap.trigger.is_authenticate">
      Provide the secret key as Authorization header
    </div>

    <div fxLayout="row" *ngIf="editMode" style="margin-bottom: 0px" class="mat-h2">Query Parameters</div>
    <mat-card *ngIf="editMode" appearance="outlined">
      <div fxLayout="row" *ngFor="let data of flowMap.trigger.query_data; let i = index" fxLayoutAlign="start center">
        <div fxLayout="column" fxFlex="45">
          <mat-form-field class="example-full-width">
            <mat-label>Attribute</mat-label>
            <input (input)="setAttributeId(data)" matInput placeholder="Text" [(ngModel)]='data.name'>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="5">
          <mat-icon style="cursor: pointer;" color="warn" (click)="removeQueryAttribute(flowMap.trigger.query_data, i)">
            delete
          </mat-icon>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button style="cursor: pointer;" (click)="addNewQueryAttribute()" color="primary" mat-stroked-button matTooltip="Add new attribute">
          <mat-icon>add_circle_outline</mat-icon>
          Add attribute
        </button>
      </div>
    </mat-card>

    <div *ngIf="flowMap.trigger.method != 'GET' && editMode" fxLayout="row" style="margin-bottom: 0px" class="mat-h2">Body Parameters</div>
    <mat-card appearance="outlined" *ngIf="flowMap.trigger.method != 'GET'">
      <div fxLayout="row" *ngFor="let data of flowMap.trigger.payload_data; let i = index" fxLayoutAlign="start center">
        <div fxLayout="column" fxFlex="45">
          <mat-form-field class="example-full-width">
            <mat-label>Attribute</mat-label>
            <input (input)="setAttributeId(data)" matInput placeholder="Text" [(ngModel)]='data.name'>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="45">
          <mat-form-field style="width: 100%;" class="example-full-width">
            <mat-label>Data type</mat-label>
            <mat-select [(ngModel)]='data.dataType' name="Data Type">
              <mat-option *ngFor="let type of ['string', 'number']" [value]="type">
                <span>{{ type }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="5">
          <mat-icon style="cursor: pointer;" color="warn" (click)="removePayloadAttribute(flowMap.trigger.payload_data, i)">
            delete
          </mat-icon>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button style="cursor: pointer;" (click)="addNewAttribute()" color="primary" mat-stroked-button matTooltip="Add new attribute">
          <mat-icon>add_circle_outline</mat-icon>
          Add attribute
        </button>
      </div>
    </mat-card>

    <div style="height: 5px"></div>
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="editMode">
      <button style="cursor: pointer;" (click)="testWebhook()" color="primary" mat-stroked-button matTooltip="Add new attribute">
        <mat-icon>play_arrow</mat-icon>
        Test Webhook
      </button>
    </div>
  </div>
