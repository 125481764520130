import { Panel } from "./basePanel";

export class DetailsPanel extends Panel{

  connectionId = ''
  boxName = ''
  boxId = ''
  boxObjectId = ''
  detailsAttributes: any = []

  constructor(id: number, name: string){
    super(id, name)
    this.type = 'detailspanel'
  }
}
