import { Injectable } from '@angular/core';
import { SystemFieldsService } from './system-fields.service';

@Injectable({
  providedIn: 'root'
})
export class ContextDataService {
  constructor(public systemFieldService: SystemFieldsService) {}

   formPanelLoadedData: any;

   async initialiZeSystemField(){
    let systemFields = await this.systemFieldService.getSystemFields();
    return systemFields
   }

   async getContextData(pageModel?:any){
    let systemFields =await this.initialiZeSystemField()
    let allSystemFields = systemFields?.fields
    let obj = {}
    for(let i = 0; i < allSystemFields.length; i++){
      obj[allSystemFields[i]] = systemFields[allSystemFields[i]].value
    }
    pageModel.systemField = obj;
    pageModel.old = this.formPanelLoadedData || {};
    console.log("[API-EXECUTION] PAGEMODEL PREPARED: ",pageModel)
    return pageModel;
   }
}
