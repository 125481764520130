<mat-dialog-content>
<div fxLayout="row">

    <div fxLayout="column" fxFlex="10"></div>
    <div fxLayout="column" fxFlex="80">
        <div fxLayout="row" style="height: 10vh;"></div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon fxFlex="10" fxLayoutAlign="end center" style="color: brown; ">error_outline</mat-icon>
            <div fxLayout="row" fxFlex="90" style="color:brown; font-size: 1.4rem;" fxLayoutAlign="center center">
              {{ 'This bloom is not accessible to ' + userMap.email }}
            </div>
        </div>
        <div fxLayout="row" style="height: 3vh;"></div>
        <div fxLayout="row" style="color:brown; font-size: 1.1rem;" fxLayoutAlign="center center">
          Please contact the Bloom Administrator.
        </div>
        <div fxLayout="row" style="height: 5vh;"></div>
    </div>
    <div fxLayout="column" fxFlex="10"></div>

</div>
</mat-dialog-content>
