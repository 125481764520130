<div fxLayout="row" >
    <div fxLayout="column" fxFlex="100">
        <div fxLayout="row">
            <!-- <div fxLayout="column" fxFlex="10" *ngIf="supportDrag">
                <mat-icon class="grabbable" style="color: grey" cdkDragHandle>
                    drag_indicator
                 </mat-icon>
            </div> *ngIf="!supportDrag" -->
            <div fxLayout="column" fxFlex="2" ></div>
            <div  fxLayout="column" fxFlex="88">
                <div fxLayout="row"  fxLayoutAlign="start center" >
                    <mat-icon style="    font-weight: bold;color:#ff1818" *ngIf="step.box_logo_url && !step.box_logo_url.includes('https://')">
                        {{step.box_logo_url}} </mat-icon>
                    <img *ngIf="step.box_logo_url && step.box_logo_url.includes('https://')" [src]="step.box_logo_url" class="trigger-image" />&nbsp;
                    <h4 *ngIf=" step.box_name" style="margin: 0px;" fxLayoutAlign="center center">{{ step.box_name || step?.action}}</h4> &nbsp;
                    <h4 style="margin: 0px;"
                    fxLayoutAlign="center center" *ngIf="!step.box_id && step?.step_type != 'processor'">
                      {{stepTypeDisplay?.[step?.step_type] || 'Control'}}</h4> &nbsp;

                    <h4 style="margin: 0px;"
                    fxLayoutAlign="center center" *ngIf="step?.step_type == 'processor'">
                       {{  step?.processor_type || stepTypeDisplay?.[step?.step_type]  | titlecase }}</h4> &nbsp;
                </div>
            </div>
            <div  fxLayout="column" fxFlex="10"></div>
            <!-- <div  fxLayout="column" fxFlex="5">
              <div fxLayout="row" fxLayoutAlign="end center">
                  <button *ngIf="editMode" style="cursor: pointer;"  (click)="$event.stopPropagation(); editStepIndex()"
                      mat-icon-button color="primary"  matTooltip="Edit Step">
                      <mat-icon >
                        edit
                      </mat-icon>
                   </button>
              </div>
          </div>
            <div  fxLayout="column" fxFlex="5">
                <div fxLayout="row" fxLayoutAlign="end center">
                    <button *ngIf="editMode" style="cursor: pointer;"  (click)="$event.stopPropagation(); deleteStep()"
                        mat-icon-button color="warn"  matTooltip="Delete Step">
                        <mat-icon >
                        delete
                        </mat-icon>
                     </button>
                </div>
            </div> -->
        </div>
    </div>

</div>
