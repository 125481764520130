<div
  *ngIf="builderMode"
  fxLayout
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <!-- [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger" -->
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover" (click)="onClick($event)" (mousedown)="$event.stopPropagation()">
    <div fxFlexFill>
      <ng-container *ngIf="!editingHeading; else elseTemplate">
        <div class="editHeadingBlock" fxFlexFill fxLayout="row wrap" [style.justify-content]="widgetMeta?.config?.alignment?.value" fxLayoutGap=".5rem">
          <div [class.blurred]="!widgetMeta.config.showTitle.value" [ngStyle]="styles">{{ widgetMeta.config.title.value }}</div>
          <mat-icon [class.blurred]="!widgetMeta.config.showTitle.value" class="icon editHeadingIcon" (click)="editingHeading = widgetMeta.config.showTitle.value ? true : false; $event.stopPropagation()" matTooltip="Edit Title">edit</mat-icon>
          <mat-icon class="icon" *ngIf="widgetMeta.config.showTitle.value" (click)="titleViewToggle(false); $event.stopPropagation()" matTooltip="Hide Title">visibility_off</mat-icon>
          <mat-icon class="icon" *ngIf="!widgetMeta.config.showTitle.value" (click)="titleViewToggle(true); $event.stopPropagation()" matTooltip="Show Title">visibility</mat-icon>
        </div>
      </ng-container>
      <ng-template #elseTemplate>
        <mat-form-field class="editHeadingBlock" style="width: 100%">
          <mat-label>Change Title</mat-label>
          <input matInput #newHeading type="text" [value]="widgetMeta.config.title.value" (click)="$event.stopPropagation()">
          <mat-icon matSuffix class="icon saveHeadingIcon" (click)="editingHeading = false; saveNewHeading(newHeading); $event.stopPropagation()">done</mat-icon>
        </mat-form-field>
      </ng-template>
    </div>

    <mat-form-field style="width: 100%;">
      <mat-chip-grid #chipList multiple="true">
        <mat-chip-row
          *ngFor="let chip of availableItems; let i = index"
          [removable]="true"
          (removed)="remove(chip, i)"
        >
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="builderMode && chip.type == 'static'">cancel</mat-icon>
        </mat-chip-row>
        <input
          matInput
          [placeholder]="widgetMeta.config.placeholder ? widgetMeta.config.placeholder.value : 'Type and press enter to add...'"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event)"
        >
      </mat-chip-grid>
    </mat-form-field>

    <div style="height: 1rem;"></div>
  </div>
</div>


<div
  *ngIf="!builderMode" fxLayout fxLayoutAlign="flex-start center" class="outerCover" (click)="onClick($event)"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover" (click)="onClick($event)" (mousedown)="$event.stopPropagation()">
    <div *ngIf="widgetMeta.config.showTitle.value">
      <div [ngStyle]="styles">{{ widgetMeta.config.title.value }}</div>
      <div style="height: .5rem;"></div>
    </div>
    <ng-container *ngIf="!widgetMeta.config.viewOnly.value; else nonInputChipsInView">
      <mat-form-field style="width: 100%;">
        <mat-chip-grid #chipListView multiple="true" (mousedown)="$event.stopPropagation()">
          <mat-chip-row
            *ngFor="let chip of availableItems; let i = index"
            [removable]="widgetMeta.config.viewOnly.value ? false : true"
            (removed)="!widgetMeta.config.viewOnly.value ? remove(chip, i) : false"
          >
            {{chip.name}}
            <mat-icon matChipRemove *ngIf="!widgetMeta.config.viewOnly.value">cancel</mat-icon>
          </mat-chip-row>
          <input
            *ngIf="!widgetMeta.config.viewOnly.value"
            matInput
            [placeholder]="widgetMeta.config.placeholder ? widgetMeta.config.placeholder.value : 'Type and press enter to add...'"
            [matChipInputFor]="chipListView"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="add($event)"
          >
        </mat-chip-grid>
      </mat-form-field>
    </ng-container>
    <ng-template #nonInputChipsInView>
      <mat-chip-grid #chipListView multiple="true" (mousedown)="$event.stopPropagation()">
        <mat-chip-row *ngFor="let chip of availableItems; let i = index">
          {{chip.name}}
        </mat-chip-row>
      </mat-chip-grid>
    </ng-template>

  </div>
</div>
