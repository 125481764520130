import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateFormDetailComponent } from './create-form-detail.component';

@NgModule({
  declarations: [CreateFormDetailComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [CreateFormDetailComponent]
})
export class CreateFormDetailModule { }
