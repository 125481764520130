import { Widget } from "./baseWidget";
import { DEFAULT_VALUE } from "./dynamicDefaultValues";
import { CommonConfig } from "./commonWidgetProps";
import { TextFormatting } from './textFormat'


export class TextInput extends Widget {
  type = 'input';
  gridX = 6
  minGridX = 4

  config = {
    // hidden: false,
    // editable: true,
    props: [
      'placeholder',
      'textContent',
      // 'defaultValue',
      'hidden',
      'tooltip',
      'nofloat',
      'appearance',
      'required',
      'viewOnly',
      'prefix',
      'suffix'
    ],
    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
    placeholder: {
      displayName: 'Input Placeholder',
      value: "Enter your text here",
      resetValue: '',
      type: 'text'
    },
    textContent: {
      displayName: "Text Content",
      value: '',
      resetValue: '',
      type: 'text',
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    nofloat: {
      displayName: 'Floating label',
      type: 'boolean',
      value: false,
    },
    required: {
      displayName: 'Required',
      type: 'boolean',
      value: false,
    },
    hidden: {
      displayName: 'Hidden',
      type: 'boolean',
      value: false,
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not edit the value',
      value: false,
      type: 'boolean',
      resetValue: false
    },
    prefix: {
      displayName: 'Prefix',
      type: 'text',
      value: '',
    },
    suffix: {
      displayName: 'Suffix',
      type: 'text',
      value: '',
    },

    // defaultValue: DEFAULT_VALUE,
    // appearance: {
    //   displayName: 'Appearance',
    //   value: 'default',
    //   type: 'select',
    //   options: ['default', 'rounded', 'outlined']
    // }
  }
  textFormat = undefined;

  constructor(id: number, name: string) {
    super(id, name)

    this.attachCommonConfig(this)
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }

  public getMappingConfig() {
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  public getWidgetConfig() {
    let map: any = {
      valuePath: "config.textContent.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map);

    const appearance = this.config.appearance.value;
    if (appearance === 'rounded') {
      map.effect.styles.push('rounded');
    } else if (appearance === 'outlined') {
      map.effect.styles.push('outlined');
    }

    return map;
  }
}
