
import { Injectable } from '@angular/core';
import { FormService } from './form.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormContentService {
  currentTheme: any;
  selectedPage: any;

  constructor(
    private formService: FormService
  ) { }

  getDefaultIntroductionPannels(form?) {
    let url = form?.logoUrl || "https://storage.googleapis.com/jivrus-web-images/products/appiworks/form-builder/aw-form-builder-logo.png";
    let result = [
      {
        "type": "regular",
        "alignment": "flex-start",
        "layoutCount": "1",
        "widgets": [],
        "id": 1721619758915,
        "name": "panel_1",
        "layoutMap": {
          "list": [
            1721619753915
          ],
          "1721619753915": {
            "gridX": 12,
            "list": [
              1721619754914,
              1721628889214
            ],
            "1721619754914": {
              "type": "elements",
              "elements": [
                {
                  "effectStyleMap": {
                    "list": [
                      "color",
                      "font-size"
                    ],
                    "color": {
                      "id": "color",
                      "name": "Color",
                      "inputType": "color-palette"
                    },
                    "font-size": {
                      "id": "font-size",
                      "name": "Font Size",
                      "inputType": "string"
                    }
                  },
                  "id": "1721619811324",
                  "name": "image_1",
                  "type": "image",
                  "gridX": 3,
                  "gridY": 3,
                  "minGridX": 3,
                  "minGridY": 3,
                  "initialPixelHeight": 0,
                  "config": {
                    "props": [
                      "src",
                      "tooltip",
                      "width",
                      "backgroundColor"
                    ],
                    "src": {
                      "displayName": "Source URL",
                      "value": `${url}`,
                      "resetValue": "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/common-assets/image-placeholder.png",
                      "type": "image-picker"
                    },
                    "tooltip": {
                      "displayName": "Tooltip",
                      "value": "",
                      "type": "text",
                      "enabled": true
                    },
                    "width": {
                      "displayName": "Width",
                      "value": "100%",
                      "type": "select"
                    },
                    "backgroundColor": {
                      "displayName": "Background Color",
                      "type": "color-picker",
                      "value": ""
                    }
                  },
                  "initialGridY": 6,
                  "initialGridX": 6
                },
                {
                  "effectStyleMap": {
                    "list": [
                      "color",
                      "font-size"
                    ],
                    "color": {
                      "id": "color",
                      "name": "Color",
                      "inputType": "color-palette"
                    },
                    "font-size": {
                      "id": "font-size",
                      "name": "Font Size",
                      "inputType": "string"
                    }
                  },
                  "id": "1721619753914",
                  "name": "label_1",
                  "type": "label",
                  "gridX": 9,
                  "minGridX": 3,
                  "config": {
                    "props": [
                      "labelText",
                      "prefixText",
                      "alignment",
                      "defaultValue",
                      "tooltip",
                      "hidden",
                      "backgroundColor"
                    ],
                    "labelText": {
                      "displayName": "Label Text",
                      "value": `Welcome to Your New AppiWorks Form ${form?.name || ''}!`,
                      "resetValue": "",
                      "type": "longtext"
                    },
                    "prefixText": {
                      "displayName": "Prefix Text",
                      "value": "",
                      "type": "text"
                    },
                    "alignment": {
                      "displayName": "Text Alignment",
                      "value": "flex-start",
                      "resetValue": "flex-start",
                      "type": "app-alignment-settings"
                    },
                    "tooltip": {
                      "displayName": "Tooltip",
                      "value": "",
                      "type": "text",
                      "enabled": true
                    },
                    "hidden": {
                      "displayName": "Hidden",
                      "type": "boolean",
                      "value": false
                    },
                    "defaultValue": {
                      "displayName": "Default Value",
                      "type": "defaultValue",
                      "enabled": false,
                      "dynamic": false,
                      "value": ""
                    },
                    "backgroundColor": {
                      "displayName": "Background Color",
                      "type": "color-picker",
                      "value": ""
                    }
                  },
                  "textFormat": {
                    "props": [
                      "class",
                      "fontFamily",
                      "fontSize",
                      "bold",
                      "italic",
                      "underline",
                      "color",
                      "backgroundColor"
                    ],
                    "class": {
                      "displayName": "Class",
                      "value": "Title"
                    },
                    "fontFamily": {
                      "displayName": "Font Family",
                      "value": "Verdana"
                    },
                    "fontSize": {
                      "displayName": "Font Size",
                      "value": 28
                    },
                    "bold": {
                      "displayName": "Bold",
                      "value": true
                    },
                    "italic": {
                      "displayName": "Italic",
                      "value": false
                    },
                    "underline": {
                      "displayName": "Underline",
                      "value": false
                    },
                    "color": {
                      "displayName": "Font Color",
                      "value": "#356100"
                    },
                    "backgroundColor": {
                      "displayName": "Background Color",
                      "value": ""
                    }
                  },
                  "actionConfig": {
                    "actions": [
                      {
                        "event": "click",
                        "action": "application",
                        "actionMap": {
                          "boxId": "",
                          "mapping": []
                        }
                      }
                    ]
                  },
                  "securityConfig": {
                    "securities": []
                  }
                }
              ]
            },
            "background": {
              "image": {
                "url": "",
                "opacity": 1
              },
              "color": "#A8C3A0"
            },
            "1721628889214": {
              "type": "elements",
              "elements": [
                {
                  "effectStyleMap": {
                    "list": [
                      "color",
                      "font-size"
                    ],
                    "color": {
                      "id": "color",
                      "name": "Color",
                      "inputType": "color-palette"
                    },
                    "font-size": {
                      "id": "font-size",
                      "name": "Font Size",
                      "inputType": "string"
                    }
                  },
                  "id": 1721628864738,
                  "name": "separator_1",
                  "type": "separator",
                  "gridX": 12,
                  "minGridX": 3,
                  "config": {
                    "props": []
                  }
                }
              ]
            }
          }
        },
        "selectedNow": true,
        "background": {
          "color": "#A8C3A0",
          "image": {
            "url": "",
            "opacity": 50
          }
        },
        "margin": {
          "left": 0,
          "right": 0
        }
      },
      {
        "type": "regular",
        "alignment": "flex-start",
        "layoutCount": "1",
        "widgets": [],
        "id": 1721619763917,
        "name": "panel_2",
        "layoutMap": {
          "list": [
            1721619753917
          ],
          "1721619753917": {
            "gridX": 12,
            "list": [
              1721620067724
            ],
            "1721620067724": {
              "type": "elements",
              "elements": [
                {
                  "effectStyleMap": {
                    "list": [
                      "color",
                      "font-size"
                    ],
                    "color": {
                      "id": "color",
                      "name": "Color",
                      "inputType": "color-palette"
                    },
                    "font-size": {
                      "id": "font-size",
                      "name": "Font Size",
                      "inputType": "string"
                    }
                  },
                  "id": "1721620067723",
                  "name": "label_1",
                  "type": "label",
                  "gridX": 12,
                  "minGridX": 3,
                  "config": {
                    "props": [
                      "labelText",
                      "prefixText",
                      "alignment",
                      "defaultValue",
                      "tooltip",
                      "hidden",
                      "backgroundColor"
                    ],
                    "labelText": {
                      "displayName": "Label Text",
                      "value": "Welcome! Begin by providing a title and a brief description of your form. This will help clarify its purpose for both you and your users, ensuring a clear and effective experience.",
                      "resetValue": "",
                      "type": "longtext"
                    },
                    "prefixText": {
                      "displayName": "Prefix Text",
                      "value": "",
                      "type": "text"
                    },
                    "alignment": {
                      "displayName": "Text Alignment",
                      "value": "flex-start",
                      "resetValue": "flex-start",
                      "type": "app-alignment-settings"
                    },
                    "tooltip": {
                      "displayName": "Tooltip",
                      "value": "",
                      "type": "text",
                      "enabled": true
                    },
                    "hidden": {
                      "displayName": "Hidden",
                      "type": "boolean",
                      "value": false
                    },
                    "defaultValue": {
                      "displayName": "Default Value",
                      "type": "defaultValue",
                      "enabled": false,
                      "dynamic": false,
                      "value": ""
                    },
                    "backgroundColor": {
                      "displayName": "Background Color",
                      "type": "color-picker",
                      "value": ""
                    }
                  },
                  "textFormat": {
                    "props": [
                      "class",
                      "fontFamily",
                      "fontSize",
                      "bold",
                      "italic",
                      "underline",
                      "color",
                      "backgroundColor"
                    ],
                    "class": {
                      "displayName": "Class",
                      "value": "Normal"
                    },
                    "fontFamily": {
                      "displayName": "Font Family",
                      "value": "Arial"
                    },
                    "fontSize": {
                      "displayName": "Font Size",
                      "value": 15
                    },
                    "bold": {
                      "displayName": "Bold",
                      "value": false
                    },
                    "italic": {
                      "displayName": "Italic",
                      "value": false
                    },
                    "underline": {
                      "displayName": "Underline",
                      "value": false
                    },
                    "color": {
                      "displayName": "Font Color",
                      "value": ""
                    },
                    "backgroundColor": {
                      "displayName": "Background Color",
                      "value": ""
                    }
                  },
                  "actionConfig": {
                    "actions": [
                      {
                        "event": "click",
                        "action": "application",
                        "actionMap": {
                          "boxId": "",
                          "mapping": []
                        }
                      }
                    ]
                  },
                  "securityConfig": {
                    "securities": []
                  }
                }
              ]
            },
            "background": {
              "image": {
                "url": "",
                "opacity": 1
              },
              "color": "#CFDECA"
            }
          }
        },
        "selectedNow": false,
        "background": {
          "color": "#CFDECA",
          "image": {
            "url": "",
            "opacity": 50
          }
        },
        "margin": {
          "left": 0,
          "right": 0
        }
      },
      {
        "type": "regular",
        "alignment": "flex-start",
        "layoutCount": "1",
        "widgets": [],
        "id": 1721627662920,
        "name": "panel_6",
        "layoutMap": {
          "list": [
            1721627662921
          ],
          "1721627662921": {
            "gridX": 12,
            "list": [
              1721627705902,
              1721627738621,
              1721629964045
            ],
            "background": {
              "image": {
                "url": "",
                "opacity": 1
              },
              "color": "#CFDECA"
            },
            "1721627705902": {
              "type": "elements",
              "elements": [
                {
                  "effectStyleMap": {
                    "list": [
                      "color",
                      "font-size"
                    ],
                    "color": {
                      "id": "color",
                      "name": "Color",
                      "inputType": "color-palette"
                    },
                    "font-size": {
                      "id": "font-size",
                      "name": "Font Size",
                      "inputType": "string"
                    }
                  },
                  "id": 1721627662922,
                  "name": "label_1",
                  "type": "label",
                  "gridX": 12,
                  "minGridX": 3,
                  "config": {
                    "props": [
                      "labelText",
                      "prefixText",
                      "alignment",
                      "defaultValue",
                      "tooltip",
                      "hidden",
                      "backgroundColor"
                    ],
                    "labelText": {
                      "displayName": "Label Text",
                      "value": "Instructions",
                      "resetValue": "",
                      "type": "longtext"
                    },
                    "prefixText": {
                      "displayName": "Prefix Text",
                      "value": "",
                      "type": "text"
                    },
                    "alignment": {
                      "displayName": "Text Alignment",
                      "value": "flex-start",
                      "resetValue": "flex-start",
                      "type": "app-alignment-settings"
                    },
                    "tooltip": {
                      "displayName": "Tooltip",
                      "value": "",
                      "type": "text",
                      "enabled": true
                    },
                    "hidden": {
                      "displayName": "Hidden",
                      "type": "boolean",
                      "value": false
                    },
                    "defaultValue": {
                      "displayName": "Default Value",
                      "type": "defaultValue",
                      "enabled": false,
                      "dynamic": false,
                      "value": ""
                    },
                    "backgroundColor": {
                      "displayName": "Background Color",
                      "type": "color-picker",
                      "value": ""
                    }
                  },
                  "textFormat": {
                    "props": [
                      "class",
                      "fontFamily",
                      "fontSize",
                      "bold",
                      "italic",
                      "underline",
                      "color",
                      "backgroundColor"
                    ],
                    "class": {
                      "displayName": "Class",
                      "value": ""
                    },
                    "fontFamily": {
                      "displayName": "Font Family",
                      "value": "Arial"
                    },
                    "fontSize": {
                      "displayName": "Font Size",
                      "value": 16
                    },
                    "bold": {
                      "displayName": "Bold",
                      "value": true
                    },
                    "italic": {
                      "displayName": "Italic",
                      "value": false
                    },
                    "underline": {
                      "displayName": "Underline",
                      "value": false
                    },
                    "color": {
                      "displayName": "Font Color",
                      "value": ""
                    },
                    "backgroundColor": {
                      "displayName": "Background Color",
                      "value": ""
                    }
                  },
                  "actionConfig": {
                    "actions": [
                      {
                        "event": "click",
                        "action": "application",
                        "actionMap": {
                          "boxId": "",
                          "mapping": []
                        }
                      }
                    ]
                  },
                  "securityConfig": {
                    "securities": []
                  }
                }
              ]
            },
            "1721627738621": {
              "type": "elements",
              "elements": [
                {
                  "effectStyleMap": {
                    "list": [
                      "color",
                      "font-size"
                    ],
                    "color": {
                      "id": "color",
                      "name": "Color",
                      "inputType": "color-palette"
                    },
                    "font-size": {
                      "id": "font-size",
                      "name": "Font Size",
                      "inputType": "string"
                    }
                  },
                  "id": 1721627721718,
                  "name": "label_1",
                  "type": "label",
                  "gridX": 12,
                  "minGridX": 3,
                  "config": {
                    "props": [
                      "labelText",
                      "prefixText",
                      "alignment",
                      "defaultValue",
                      "tooltip",
                      "hidden",
                      "backgroundColor"
                    ],
                    "labelText": {
                      "displayName": "Label Text",
                      "value": "Guidelines for your form users go here. customize this text to suit your needs.\n\n* Carefully read and respond to each question in the form.\n* Navigate through the form sections using the \"Next\" and \"Back\" buttons to move forward or review previous sections.\n* Ensure your answers are accurate and complete before moving to the next section.\n* Once you reach the final page, click the \"Submit\" button to send your responses.",
                      "resetValue": "",
                      "type": "longtext"
                    },
                    "prefixText": {
                      "displayName": "Prefix Text",
                      "value": "",
                      "type": "text"
                    },
                    "alignment": {
                      "displayName": "Text Alignment",
                      "value": "flex-start",
                      "resetValue": "flex-start",
                      "type": "app-alignment-settings"
                    },
                    "tooltip": {
                      "displayName": "Tooltip",
                      "value": "",
                      "type": "text",
                      "enabled": true
                    },
                    "hidden": {
                      "displayName": "Hidden",
                      "type": "boolean",
                      "value": false
                    },
                    "defaultValue": {
                      "displayName": "Default Value",
                      "type": "defaultValue",
                      "enabled": false,
                      "dynamic": false,
                      "value": ""
                    },
                    "backgroundColor": {
                      "displayName": "Background Color",
                      "type": "color-picker",
                      "value": ""
                    }
                  },
                  "textFormat": {
                    "props": [
                      "class",
                      "fontFamily",
                      "fontSize",
                      "bold",
                      "italic",
                      "underline",
                      "color",
                      "backgroundColor"
                    ],
                    "class": {
                      "displayName": "Class",
                      "value": ""
                    },
                    "fontFamily": {
                      "displayName": "Font Family",
                      "value": "Arial"
                    },
                    "fontSize": {
                      "displayName": "Font Size",
                      "value": 14
                    },
                    "bold": {
                      "displayName": "Bold",
                      "value": false
                    },
                    "italic": {
                      "displayName": "Italic",
                      "value": false
                    },
                    "underline": {
                      "displayName": "Underline",
                      "value": false
                    },
                    "color": {
                      "displayName": "Font Color",
                      "value": ""
                    },
                    "backgroundColor": {
                      "displayName": "Background Color",
                      "value": ""
                    }
                  },
                  "actionConfig": {
                    "actions": [
                      {
                        "event": "click",
                        "action": "application",
                        "actionMap": {
                          "boxId": "",
                          "mapping": []
                        }
                      }
                    ]
                  },
                  "securityConfig": {
                    "securities": []
                  }
                }
              ]
            },
            "1721629964045": {
              "type": "elements",
              "elements": [
                {
                  "effectStyleMap": {
                    "list": [
                      "color",
                      "font-size"
                    ],
                    "color": {
                      "id": "color",
                      "name": "Color",
                      "inputType": "color-palette"
                    },
                    "font-size": {
                      "id": "font-size",
                      "name": "Font Size",
                      "inputType": "string"
                    }
                  },
                  "id": "1721629964044",
                  "name": "label_1",
                  "type": "label",
                  "gridX": 12,
                  "minGridX": 3,
                  "config": {
                    "props": [
                      "labelText",
                      "prefixText",
                      "alignment",
                      "defaultValue",
                      "tooltip",
                      "hidden",
                      "backgroundColor"
                    ],
                    "labelText": {
                      "displayName": "Label Text",
                      "value": "Thank you for your participation. Let's get started!",
                      "resetValue": "",
                      "type": "longtext"
                    },
                    "prefixText": {
                      "displayName": "Prefix Text",
                      "value": "",
                      "type": "text"
                    },
                    "alignment": {
                      "displayName": "Text Alignment",
                      "value": "flex-start",
                      "resetValue": "flex-start",
                      "type": "app-alignment-settings"
                    },
                    "tooltip": {
                      "displayName": "Tooltip",
                      "value": "",
                      "type": "text",
                      "enabled": true
                    },
                    "hidden": {
                      "displayName": "Hidden",
                      "type": "boolean",
                      "value": false
                    },
                    "defaultValue": {
                      "displayName": "Default Value",
                      "type": "defaultValue",
                      "enabled": false,
                      "dynamic": false,
                      "value": ""
                    },
                    "backgroundColor": {
                      "displayName": "Background Color",
                      "type": "color-picker",
                      "value": ""
                    }
                  },
                  "textFormat": {
                    "props": [
                      "class",
                      "fontFamily",
                      "fontSize",
                      "bold",
                      "italic",
                      "underline",
                      "color",
                      "backgroundColor"
                    ],
                    "class": {
                      "displayName": "Class",
                      "value": ""
                    },
                    "fontFamily": {
                      "displayName": "Font Family",
                      "value": "Arial"
                    },
                    "fontSize": {
                      "displayName": "Font Size",
                      "value": 14
                    },
                    "bold": {
                      "displayName": "Bold",
                      "value": false
                    },
                    "italic": {
                      "displayName": "Italic",
                      "value": false
                    },
                    "underline": {
                      "displayName": "Underline",
                      "value": false
                    },
                    "color": {
                      "displayName": "Font Color",
                      "value": ""
                    },
                    "backgroundColor": {
                      "displayName": "Background Color",
                      "value": ""
                    }
                  }
                }
              ]
            }
          }
        },
        "selectedNow": false,
        "background": {
          "color": "#CFDECA",
          "image": {
            "url": "",
            "opacity": 50
          }
        },
        "margin": {
          "left": 0,
          "right": 0
        }
      },
    ]
    return result;
  }

  createSearchMeta(){
    return {
      searchAttributes: [
        {
          "dataType": "string",
          "filterable": true,
          "isColumnSelected": true,
          "name": "Search by name",
          "sortable": true,
          "widgetType": "input",
          "__id": "name"
        }
      ],
      pageSize: 10,
      hideTitle: true,
      searchButtonText: "Search",
      alignment: "flex-start",
      baseId: "",
      boxObjectId: "forms",
      name: "searchpanel_1",
      outputListPanelId: "forms-templates-listing",
      type: "searchpanel",
    }
  }

  getTemplatesPanel(){
    let result =
      {
        "type": "listpanel",
        "alignment": "flex-start",
        "layoutCount": "1",
        "widgets": [],
        "id": "forms-templates-listing",
        "name": "listPanel_1",
        boxId: 'starch',
        boxObjectId: 'forms',
        connectionId: "",
        baseMap: {
          "box_id": "mongodb",
          "base_id": ""
        } ,
        "listAttributes": [
          {
            "__id": "name",
            "name": "name",
            "dataType": "string",
            "filterable": true,
            "sortable": true,
            "fieldType": "attribute",
            "widgetType": "label",
            "isColumnSelected": true
          },
          {
            "__id": "template_data",
            "name": "template_data",
            "dataType": "object",
            "filterable": false,
            "sortable": false,
            "fieldType": "attribute",
            "widgetType": "label",
            "isColumnSelected": true,
            "isDrillDown": true,
            "nestedProperties": [
              // {
              //   "path": "name",
              //   "widgetType": "label",
              //   "fieldType": "attribute"
              // },
              {
                "path": "bannerImage",
                "widgetType": "image",
                "fieldType": "attribute"
              },
              {
                "path": "tagline",
                "widgetType": "label",
                "fieldType": "attribute"
              },
              {
                "path": "description",
                "widgetType": "label",
                "fieldType": "attribute"
              }
            ]
          }
        ],
        "aditionalAttributesAllowed": false,
        "pageSize": 20,
        "loadInitialData": true,
        "dataSource": "app",
        "paginationEnabled": true,
        "paginationType": "pagebypage",
        "defaultListSize": 10,
        "noDataMessage": "There is no data to be shown here",
        "navigationSettings": {
          "enabled": true,
          "navFilterAttributes": [],
          "pageCode": "",
          "tooltip": "",
          "type": ""
        },
        "boxConfigToken": environment.DB_BOX_TOKEN,
        "attributeOptions": [],
        "getFnOptions": [],
        "listPanelTitle": "",
        hideTitle: true,
        "viewTypes": {
          "views": [
            "table",
            "card",
            "board"
          ],
          "defaultView": "card",
          "boardStatusColumn": "",
          "userCanChoose": false,
          "table": {
            "name": "table",
            "displayName": "Table",
            "userCanChoose": true,
            "icon": "list"
          },
          "card": {
            "name": "card",
            "displayName": "Card",
            "userCanChoose": true,
            "icon": "grid_on"
          },
          "board": {
            "name": "board",
            "displayName": "Board",
            "userCanChoose": false,
            "icon": "view_kanban"
          }
        },
        "primaryAttribute": {
          "__id": "_id",
          "name": "_id",
          "dataType": "string",
          "filterable": true,
          "sortable": true,
          "primary": true,
          "writable": false,
          "fieldType": "attribute",
          "widgetType": "label",
          "isColumnSelected": false
        },
        "filter": {
          "filterEnabled": true,
          "filterItems": [
            {
              "attribute": "template",
              "operator": "=",
              "value": "true",
              "dataType": "boolean",
              "filterType": "static_filter"
            },
            {
              "attribute": "template_status",
              "operator": "=",
              "value": "published",
              "dataType": "string",
              "filterType": "static_filter"
            }
          ]
        },
        "sort": {
          "sortEnabled": false,
          "sortAttributes": []
        },
        "listWidgetSet": {
          "name": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "1721634761824762e067",
            "name": "label_0",
            "type": "label",
            "gridX": 6,
            "minGridX": 3,
            "config": {
              "props": [
                "labelText",
                "prefixText",
                "alignment",
                "defaultValue",
                "tooltip",
                "hidden",
                "backgroundColor"
              ],
              "labelText": {
                "displayName": "Label Text",
                "value": "Default label text. Double-click to change it.",
                "resetValue": "",
                "type": "longtext"
              },
              "prefixText": {
                "displayName": "Prefix Text",
                "value": "",
                "type": "text"
              },
              "alignment": {
                "displayName": "Text Alignment",
                "value": "flex-start",
                "resetValue": "flex-start",
                "type": "app-alignment-settings"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "hidden": {
                "displayName": "Hidden",
                "type": "boolean",
                "value": false
              },
              "defaultValue": {
                "displayName": "Default Value",
                "type": "defaultValue",
                "enabled": false,
                "dynamic": false,
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            },
            "textFormat": {
              "props": [
                "class",
                "fontFamily",
                "fontSize",
                "bold",
                "italic",
                "underline",
                "color",
                "backgroundColor"
              ],
              "class": {
                "displayName": "Class",
                "value": ""
              },
              "fontFamily": {
                "displayName": "Font Family",
                "value": "Arial"
              },
              "fontSize": {
                "displayName": "Font Size",
                "value": 20
              },
              "bold": {
                "displayName": "Bold",
                "value": true
              },
              "italic": {
                "displayName": "Italic",
                "value": false
              },
              "underline": {
                "displayName": "Underline",
                "value": false
              },
              "color": {
                "displayName": "Font Color",
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "value": ""
              }
            }
          },
          // "template_data.name": {
          //   "effectStyleMap": {
          //     "list": [
          //       "color",
          //       "font-size"
          //     ],
          //     "color": {
          //       "id": "color",
          //       "name": "Color",
          //       "inputType": "color-palette"
          //     },
          //     "font-size": {
          //       "id": "font-size",
          //       "name": "Font Size",
          //       "inputType": "string"
          //     }
          //   },
          //   "id": "1721634761824762e067",
          //   "name": "label_0",
          //   "type": "label",
          //   "gridX": 6,
          //   "minGridX": 3,
          //   "config": {
          //     "props": [
          //       "labelText",
          //       "prefixText",
          //       "alignment",
          //       "defaultValue",
          //       "tooltip",
          //       "hidden",
          //       "backgroundColor"
          //     ],
          //     "labelText": {
          //       "displayName": "Label Text",
          //       "value": "Default label text. Double-click to change it.",
          //       "resetValue": "",
          //       "type": "longtext"
          //     },
          //     "prefixText": {
          //       "displayName": "Prefix Text",
          //       "value": "",
          //       "type": "text"
          //     },
          //     "alignment": {
          //       "displayName": "Text Alignment",
          //       "value": "flex-start",
          //       "resetValue": "flex-start",
          //       "type": "app-alignment-settings"
          //     },
          //     "tooltip": {
          //       "displayName": "Tooltip",
          //       "value": "",
          //       "type": "text",
          //       "enabled": true
          //     },
          //     "hidden": {
          //       "displayName": "Hidden",
          //       "type": "boolean",
          //       "value": false
          //     },
          //     "defaultValue": {
          //       "displayName": "Default Value",
          //       "type": "defaultValue",
          //       "enabled": false,
          //       "dynamic": false,
          //       "value": ""
          //     },
          //     "backgroundColor": {
          //       "displayName": "Background Color",
          //       "type": "color-picker",
          //       "value": ""
          //     }
          //   },
          //   "textFormat": {
          //     "props": [
          //       "class",
          //       "fontFamily",
          //       "fontSize",
          //       "bold",
          //       "italic",
          //       "underline",
          //       "color",
          //       "backgroundColor"
          //     ],
          //     "class": {
          //       "displayName": "Class",
          //       "value": ""
          //     },
          //     "fontFamily": {
          //       "displayName": "Font Family",
          //       "value": "Arial"
          //     },
          //     "fontSize": {
          //       "displayName": "Font Size",
          //       "value": 14
          //     },
          //     "bold": {
          //       "displayName": "Bold",
          //       "value": false
          //     },
          //     "italic": {
          //       "displayName": "Italic",
          //       "value": false
          //     },
          //     "underline": {
          //       "displayName": "Underline",
          //       "value": false
          //     },
          //     "color": {
          //       "displayName": "Font Color",
          //       "value": ""
          //     },
          //     "backgroundColor": {
          //       "displayName": "Background Color",
          //       "value": ""
          //     }
          //   }
          // },
          "template_data.bannerImage": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "1721634761824d6a2f7b",
            "name": "image_0",
            "type": "image",
            "gridX": 6,
            "gridY": 0,
            "minGridX": 3,
            "minGridY": 3,
            "initialPixelHeight": 0,
            "config": {
              "props": [
                "src",
                "tooltip",
                "width",
                "backgroundColor"
              ],
              "src": {
                "displayName": "Source URL",
                "value": "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/common-assets/image-placeholder.png",
                "resetValue": "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/common-assets/image-placeholder.png",
                "type": "image-picker"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "width": {
                "displayName": "Width",
                "value": "100%",
                "type": "select"
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            }
          },
          "template_data.tagline": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "1721634761824e00b7ec",
            "name": "label_0",
            "type": "label",
            "gridX": 6,
            "minGridX": 3,
            "config": {
              "props": [
                "labelText",
                "prefixText",
                "alignment",
                "defaultValue",
                "tooltip",
                "hidden",
                "backgroundColor"
              ],
              "labelText": {
                "displayName": "Label Text",
                "value": "Default label text. Double-click to change it.",
                "resetValue": "",
                "type": "longtext"
              },
              "prefixText": {
                "displayName": "Prefix Text",
                "value": "",
                "type": "text"
              },
              "alignment": {
                "displayName": "Text Alignment",
                "value": "flex-start",
                "resetValue": "flex-start",
                "type": "app-alignment-settings"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "hidden": {
                "displayName": "Hidden",
                "type": "boolean",
                "value": false
              },
              "defaultValue": {
                "displayName": "Default Value",
                "type": "defaultValue",
                "enabled": false,
                "dynamic": false,
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            },
            "textFormat": {
              "props": [
                "class",
                "fontFamily",
                "fontSize",
                "bold",
                "italic",
                "underline",
                "color",
                "backgroundColor"
              ],
              "class": {
                "displayName": "Class",
                "value": ""
              },
              "fontFamily": {
                "displayName": "Font Family",
                "value": "Arial"
              },
              "fontSize": {
                "displayName": "Font Size",
                "value": 14
              },
              "bold": {
                "displayName": "Bold",
                "value": false
              },
              "italic": {
                "displayName": "Italic",
                "value": false
              },
              "underline": {
                "displayName": "Underline",
                "value": false
              },
              "color": {
                "displayName": "Font Color",
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "value": ""
              }
            }
          },
          "template_data.description": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "17216347618240a8681b",
            "name": "label_0",
            "type": "label",
            "gridX": 6,
            "minGridX": 3,
            "config": {
              "props": [
                "labelText",
                "prefixText",
                "alignment",
                "defaultValue",
                "tooltip",
                "hidden",
                "backgroundColor"
              ],
              "labelText": {
                "displayName": "Label Text",
                "value": "Default label text. Double-click to change it.",
                "resetValue": "",
                "type": "longtext"
              },
              "prefixText": {
                "displayName": "Prefix Text",
                "value": "",
                "type": "text"
              },
              "alignment": {
                "displayName": "Text Alignment",
                "value": "flex-start",
                "resetValue": "flex-start",
                "type": "app-alignment-settings"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "hidden": {
                "displayName": "Hidden",
                "type": "boolean",
                "value": false
              },
              "defaultValue": {
                "displayName": "Default Value",
                "type": "defaultValue",
                "enabled": false,
                "dynamic": false,
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            },
            "textFormat": {
              "props": [
                "class",
                "fontFamily",
                "fontSize",
                "bold",
                "italic",
                "underline",
                "color",
                "backgroundColor"
              ],
              "class": {
                "displayName": "Class",
                "value": ""
              },
              "fontFamily": {
                "displayName": "Font Family",
                "value": "Arial"
              },
              "fontSize": {
                "displayName": "Font Size",
                "value": 14
              },
              "bold": {
                "displayName": "Bold",
                "value": false
              },
              "italic": {
                "displayName": "Italic",
                "value": false
              },
              "underline": {
                "displayName": "Underline",
                "value": false
              },
              "color": {
                "displayName": "Font Color",
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "value": ""
              }
            }
          }
        },
        "getFn": {
          "__id": "get",
          "name": "Get",
          "dataFlow": "pull",
          "crudType": "R",
          "functionName": "get",
          "options": {
            "filter": true,
            "sort": true,
            "maxPageSize": 1000
          },
          "input": {
            "list": [
              "query",
              "options"
            ],
            "options": {
              "name": "options",
              "dataType": "object",
              "list": [
                "relationObject"
              ],
              "relationObject": {
                "dataType": "string",
                "name": "Relation Object",
                "__id": "relationObject",
                "hidden": true,
                "value": ""
              }
            },
            "query": {
              "name": "query",
              "dataType": "query",
              "required": true
            }
          }
        }
      }

    return result;
  }

}
