import { Injectable } from "@angular/core";
import { TemplateEngine } from "src/app/core/common/TemplateEngine";
import { ExpressionUtility } from "src/app/shared/built-in-expression/expressionUtility";
import { ContextDataService } from "src/app/shared/services/context-data.service";
import { SpinnerService } from "src/app/shared/spinner/spinner.service";
import { PageService } from "../../services/page-service.service";

@Injectable({
    providedIn: 'root',
  })

export class ActionServiceUtility {

    constructor(
      public expressionUtility: ExpressionUtility,
      public spinnerService: SpinnerService,
      public contextDataService: ContextDataService,
      public pageService: PageService,
    ){}


     isEventMatch(onMouseEvent, event){

        var isMatched = true;
        if(["mouseenter", "mouseleave"].includes(event.type)){
            if( onMouseEvent != "hover") isMatched = false;
            console.log("1")
        } else if(onMouseEvent != event.type){
            console.log("2")
            isMatched = false;
        }
        // console.log("onMouseEvent, event, isMatched", onMouseEvent, event.type, isMatched)
        return isMatched;
    }

    prepareAllHash(fieldMap: string | any[], fieldValueMapObj: any) {
        let te = new TemplateEngine();
        let payload: any = {};
        fieldValueMapObj = this.convertDataWithDots(fieldValueMapObj);
        for (let i = 0; i < fieldMap.length; i++) {
          let field = fieldMap[i];
          let appField: string = field.appField.__id || field.appField.name;
          let appFieldType: string = field.appField.dataType;
          let mapType: string = field.mappingType;
          let sourceField: string = field.sourceField?.__id || field.sourceField;
          let resultValue: any
          if (mapType == 'templateField') {
            resultValue = te.fillAny(sourceField, fieldValueMapObj);
          } else if (mapType == 'conditional') {
            resultValue = this.getConditionalFieldValue(field.conditional, fieldValueMapObj);
            resultValue = this.formatValue(resultValue, field)
          } else {
            resultValue = this.getObjectValue(sourceField, fieldValueMapObj);
            resultValue = this.formatValue(resultValue, field)
          }
          // console.log("result value", resultValue)
          payload = this.fillIntoPayload(payload, appField, resultValue)
          console.log("[ACTION] appField value", appField, resultValue, payload[appField], JSON.parse(JSON.stringify(payload)))
        }
        console.log("payload after replace", JSON.parse(JSON.stringify(payload)))
        return payload;
      }

      private formatValue(value: any, field: any){
        let appFieldType = field.appField.dataType;
        if(appFieldType == "date" && value) {
          value = value +'|date';
        }
        if(appFieldType == "number" && value) {
          value = Number(value);
        }
        if(appFieldType == "datetime" && value) {
          let timeZone = field?.appField?.timeZone;
          value = timeZone ? value+'|datetime'+`|${timeZone}` : value +'|datetime';
        }
        return value
      }

      private fillIntoPayload(obj, path, value) {
        // console.log("")
        // console.log("obj", JSON.parse(JSON.stringify(obj, null, 2)))
        // console.log("path", path)
        // console.log("value", JSON.parse(JSON.stringify(value, null, 2)))

        let effectiveValue
        if(Array.isArray(value)){
          effectiveValue = JSON.parse(JSON.stringify(value))
        }else{
          effectiveValue = value
        }

        var schema = obj;  // a moving reference to internal objects within obj
        var pList = path.split('.');
        var len = pList.length;
        for(var i = 0; i < len-1; i++) {
            var elem = pList[i];
            if( !schema[elem] ) schema[elem] = {}
            schema = schema[elem];
        }

        schema[pList[len-1]] = effectiveValue;
        return obj;
      }



      getObjectValue(string: any, data: any) {
        console.log('string', string);

        data = this.convertDataWithDots(data);
        console.log('data', data);

        var nameArr = string.split('|');
        var result = null;
        var name = nameArr[0];

        // if there is a dot, then drill down to the object
        var dotIndex = name.indexOf('.');
        while (dotIndex != -1) {
          var parent = name.substring(0, dotIndex);
          name = name.substring(dotIndex + 1);
          data = data[parent];
          if (data == null) break;
          else dotIndex = name.indexOf('.');
        }

        if (data != null) {
          var contructedValue = data[name];
        }
        result = contructedValue;

        console.log('result', result);
        return result;
      }

      convertDataWithDots(data: any): any {
        const result: any = {};

        function assignValue(obj: any, key: string, value: any) {
          const keys = key.split('.');
          const lastKey = keys.pop();

          keys.reduce((nestedObj, currentKey) => {
            nestedObj[currentKey] = nestedObj[currentKey] || {};
            return nestedObj[currentKey];
          }, obj)[lastKey] = value;
        }

        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const value = data[key];
            if (key.includes('.')) {
              assignValue(result, key, value);
            } else {
              result[key] = typeof value === 'object' ? this.convertDataWithDots(value) : value;
            }
          }
        }

        return result;
      }

      async checkActionCondition(action, options){
        let bool = true;
        if(!action?.condition || !action?.condition?.conditions || action?.condition?.conditions?.length == 0) return bool;
        console.log("action----->", action, options);
        let pageModel = JSON.parse(JSON.stringify(this.pageService.getPageModel()))
        pageModel = this.expressionUtility.resolvePageModelExpressions(pageModel)
        pageModel = await this.contextDataService.getContextData(pageModel);
        console.log("pageModel----->", pageModel);
        let conditions = action.condition.conditions;
        let conditionOperator = action.condition.conditionOperator.operator || "&&";
        let consolidatedData = this.convertDataWithDots(pageModel);
        let te = new TemplateEngine();
        for (let j = 0; j < conditions.length; j++) {
          const firstFieldValue = te.fillAny(conditions[j].attribute || '', consolidatedData);
          const secondFieldValue = te.fillAny(conditions[j].value || '', consolidatedData);
          const operator = conditions[j].operator || '==';
          const type = conditions[j].type || 'templated';
          const conditionParams = {
            param1: firstFieldValue,
            param2: secondFieldValue,
            operator: operator,
            type: type,
          };

          if (this.checkCondition(conditionParams)) {
            bool = true;
          } else bool = false;

          if(conditionOperator == "&&") {
            if(!bool) break;
          } else if(bool) break;
        }
        return bool;
      }

      getConditionalFieldValue(conditionalConfig: any, consolidatedData: any): any {
        console.log("conditionalConfig---------------------->", conditionalConfig, "/n cosolidatedData----------------------->", consolidatedData);
        let result: any = null;
        let isConditionSatisfy = true;
        let expression: string | null = null;
        let template: string | null = null;

        let te = new TemplateEngine();
        let payload: any = {};
        consolidatedData = this.convertDataWithDots(consolidatedData);

        try {
          // attaching value field from custom expressions
          for (let i = 0; i < conditionalConfig.conditionSets.length; i++) {
            isConditionSatisfy = true;

            const conditionObj = conditionalConfig.conditionSets[i];
            const conditions = conditionObj.conditions;

            for (let j = 0; j < conditions.length; j++) {
              const firstFieldValue = te.fillAny(conditions[j].attribute || '', consolidatedData);
              const secondFieldValue = te.fillAny(conditions[j].value || '', consolidatedData);
              const operator = conditions[j].operator || '==';
              const type = conditions[j].type || 'templated';
              const conditionParams = {
                param1: firstFieldValue,
                param2: secondFieldValue,
                operator: operator,
                type: type,
              };

              if (!this.checkCondition(conditionParams)) {
                isConditionSatisfy = false;
                break;
              }

              conditions[j].firstFieldValue = firstFieldValue;
              conditions[j].secondFieldValue = secondFieldValue;
            }

            if (isConditionSatisfy) {
              if (!conditionObj.resultType) conditionObj.resultType = 'calculated';
              template = conditionObj.resultField || '';

              expression = te.fillAny(template, consolidatedData);

              if (conditionObj.resultType == 'calculated') {
                result = this.evaluate(expression);
              } else {
                result = expression;
              }
              break;
            }
          }

          if (!result) {
            const defaultFieldValue = te.fillAny(conditionalConfig.defaultField || '', consolidatedData);
            template = conditionalConfig.defaultField || '';
            if (!conditionalConfig.defaultType) conditionalConfig.defaultType = 'calculated';
            expression = te.fillAny(template, consolidatedData);

            if (conditionalConfig.defaultType == 'calculated') {
              result = this.evaluate(expression);
            } else {
              result = expression;
            }
          }

          console.log("conditionalConfig----------------------> result", conditionalConfig, result)
          return result;
        } catch (err) {
          console.warn(err);
          throw err;
        }
      }

      checkCondition(conditionParams: { param1: any; param2?: any; operator: string; type: string }): boolean {
        console.log('inside checking condition...', conditionParams);
        const { param1, param2 = "", operator, type } = conditionParams;

        if (type == 'calculated') {
          if (operator == '∅' || operator == '!∅' || operator == '~' || operator == '!~') {
            if (operator == '∅' || operator == '~') {
              if (!param1) return true;
              else return false;
            } else {
              if (param1) return true;
              else return false;
            }
          } else {
            return this.evaluate(param1 + operator + param2);
          }
        } else {
          console.log("checking condition:[ %s, %s, %s ]", param1, operator, param2);
          switch (operator) {
            case "==":
            case "=":
              return param1 == param2;
            case "!=":
              return param1 != param2;
            case ">":
              return param1 > param2;
            case "<":
              return param1 < param2;
            case ">=":
              return param1 >= param2;
            case "<=":
              return param1 <= param2;
            case "∅":
              if (!param1) return true;
              else return false;
            case "!∅":
              if (param1) return true;
              else return false;
            case "~":
              if (!param1) return true;
              else return false;
            case "!~":
              if (param1) return true;
              else return false;
            case "∈":
              if (param1.indexOf(param2) > -1) return true;
              else return false;
            case "!∈":
              if (param1.indexOf(param2) == -1) return true;
              else return false;
            default:
              throw new Error("Unsupported operator: " + operator);
          }
        }

      }

      // Function to evaluate a custom expression
      evaluate(expression: string): any {
        let result: any = null;

        try {
          // Use the JavaScript eval function to evaluate the expression
          result = eval(expression);
        } catch (err) {
          console.warn("Invalid custom expression: %s, %s, %s ", expression, err, err.stack);
          throw new Error("Invalid custom expression: " + expression);
        }

        return result;
      }

      removeEmptyProperties(obj: { [key: string]: any }): { [key: string]: any } {
        const result: { [key: string]: any } = {};

        for (const key in obj) {
          if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
            result[key] = obj[key];
          }
        }

        return result;
      }



}
