import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges, Inject, PLATFORM_ID } from '@angular/core';
import { ChartService } from '../../services/chart.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

declare var google: any;

// interface chartDimension {
//   attribute: {
//     __id: string,
//     name: string,
//     dataType?: string
//   }
// }

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnInit, OnChanges {

  @Input() records: any[];
  @Input() widgetMeta: any;
  @Input() chartDimensions: any
  @Input() builderMode: any
  // @Input() chartConfig: CHART_CONFIG

  colorsArray: string[] = []
  chartData: any

  skimmedData: any = []
  isBrowser: any;

  constructor(
    private chartService: ChartService,
    private widgetService: WidgetService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    }

  ngOnInit(): void {
    if(!this.isBrowser) return;
    console.log("BAR CHART COMPONENT", this.records)
    google.charts.load('current', { packages: ['corechart'] });
    this.chartService.builderMode = this.builderMode
    this.colorsArray = this.chartService.colorPalette

    this.buildChart(true)

    this.widgetService.$widgetDragStarted.subscribe(data => {
      // console.log("widget move start alert", data)
      this.chartService.setChartDataCache(this.widgetMeta.id, this.skimmedData)
      // if(data.id == this.widgetMeta.id){
      // }
    })
  }

  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.chartDimensions?.currentValue && !changes.chartDimensions?.firstChange){
      // console.log("###### DIMENSION CHANGE", changes.chartDimensions?.currentValue)
      this.buildChart()
    }
    if(changes.widgetMeta?.currentValue && !changes.widgetMeta?.firstChange){
      // console.log("###### META CHANGE", changes.widgetMeta.currentValue)
      this.buildChart()
    }
    if(changes.records?.currentValue && !changes.records?.firstChange){
      // console.log("####### RECORDS CHANGE", changes.records.currentValue)
      this.buildChart(true)
    }
  }

  ngDoCheck(){}

  formatChartData(data?: any[]): any[] {
    const formattedData: any[] = [['Date', 'Created', 'Resolved', 'Closed']];
    data = [
      {"resolved":"2023-12-28T09:01:00+05:30","closed":"2023-12-29T12:45:00+05:30","created":"2023-12-27T16:32:00+05:30","status":"In Progress","type":"Bug"},
      {"resolved":"2023-12-29T10:30:00+05:30","closed":"2023-12-29T16:45:00+05:30","created":"2023-12-28T11:15:00+05:30","status":"Resolved","type":"Feature Request"},
      {"resolved":"2023-12-29T16:15:00+05:30","closed":"2023-12-29T16:30:00+05:30","created":"2023-12-29T15:00:00+05:30","status":"Closed","type":"Enhancement"}
    ]
    // Iterate through the data and extract relevant information
    data.forEach(item => {
      formattedData.push([
        item.created.split('T')[0],  // Extract date from created timestamp
        new Date(item.created).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
        new Date(item.resolved).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
        new Date(item.closed).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
      ]);
    });

    // console.log("formattedData", formattedData)
    return formattedData;
  }

  async buildChart(rebuild?: boolean) {
    var func = async (chart: any) => {
      this.chartData = new google.visualization.DataTable();
      let dataSource = this.widgetMeta?.config?.dataSource
      this.chartData.addColumn('string', dataSource?.dimensions?.[0]?.attribute?.name || "Item");
      this.chartData.addColumn('number', dataSource?.metric?.attribute?.name || "Amount");

      this.chartService.setPrecision(this.widgetMeta.config.precision?.value)

      // this.chartData = await this.chartService.prepareBarData(this.chartData, this.records, this.widgetMeta?.config?.dataSource)

      if(rebuild){
        // console.log("---------REBUILDING-------")

        if (this.records?.[0]?.["__USECACHED__"]) {
          this.skimmedData = this.chartService.getChartDataCache(this.widgetMeta.id)
          this.chartService.clearChartCacheForWidget(this.widgetMeta.id)
          return
        } else {
          let pageData: any
          let isGrouped: boolean = this.records[0]?.__GROUPED__ || false
          if(this.records[0]?.__START__){
            this.clearCache()
            pageData = this.records.shift()
          }
          let rawRecords: any[] = []
          this.records.forEach(item => {
            if(item !== '__END__') rawRecords.push(item)
          })
        // console.log("this.records", this.records)

          let res = this.chartService.processBarData(dataSource, rawRecords, isGrouped)
          // console.log("res", res)
          this.skimmedData = this.chartService.merge(this.skimmedData, res, dataSource, pageData?.pageNumber || 1, pageData?.pageSize || res.length, rawRecords.length)
        }
      }
      // console.log("skimmed data for chart", JSON.parse(JSON.stringify(this.skimmedData)))
      this.chartData.addRows(this.skimmedData)


      let yAxisTitle = ''
      // console.log("widgetMeta", this.widgetMeta)
      if (this.widgetMeta.config.yAxisTitle?.value) {
        yAxisTitle = this.widgetMeta.config.yAxisTitle?.value
      } else{
        if (this.widgetMeta.config.dataSource.metric.operation == 'no_op'){
          yAxisTitle = this.widgetMeta.config.dataSource.metric?.attribute?.name
        } else{
          yAxisTitle = this.widgetMeta.config.dataSource.metric.operation + " of " + this.widgetMeta.config.dataSource.metric?.attribute?.name
        }
      }

      var options = {
        title: this.widgetMeta.config.title?.value || `${dataSource.dimensions?.[0]?.attribute?.name}`,
        titleTextStyle: {
          color: this.widgetMeta.textFormat?.color?.value || '#444',    // any HTML string color ('red', '#cc00cc')
          fontName: this.widgetMeta.textFormat?.fontFamily?.value || 'Arial', // i.e. 'Times New Roman'
          fontSize: this.widgetMeta.textFormat?.fontSize?.value || 18, // 12, 18 whatever you want (don't specify px)
          bold: this.widgetMeta.textFormat?.bold?.value || false,    // true or false
          italic: this.widgetMeta.textFormat?.italic?.value || false  // true of false
        },
        colors: this.colorsArray,
        backgroundColor: this.widgetMeta.config.backgroundColor?.value || '#FFFFFF',
        width: this.chartDimensions.width || 250,
        height: this.chartDimensions.height || 150,
        chartArea: { 'bottom': '20%', 'top': '20%', 'left': '20%', 'right': '10%', 'width': '100%', 'height': '100%'},
        legend: {'position': 'none'},
        vAxis: {
          title: yAxisTitle,
          textStyle: {fontSize: 12}
        }
      };
      // console.log("chartData data for chart", this.chartData)
      // this.chartData = this.formatChartData();
      // console.log("test chartData data for chart", this.chartData)
      // console.log("chart options", options)
      chart().draw(this.chartData, options);
    }



    var chart = () => new google.visualization.ColumnChart(this.document.getElementById(this.widgetMeta.id));

    var callback = () => func(chart);

    google.charts.setOnLoadCallback(callback);
  }

  clearCache(){
    this.skimmedData = []
  }
  isDelimitor(){
    // console.log("last item", this.records[this.records.length - 1])
    if(this.records[this.records.length - 1] == '__END__') return true
    else return false
  }

}
