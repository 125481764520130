import { Component, Input, OnInit } from '@angular/core';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { WidgetManager } from '../../../WidgetManager';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { SystemFieldsService } from 'src/app/shared/services/system-fields.service';

@Component({
  selector: 'app-widget-action',
  templateUrl: './widget-action.component.html',
  styleUrls: ['./widget-action.component.scss']
})
export class WidgetActionComponent implements OnInit {

  @Input() "actionConfig": any;
  @Input() "widget": any;
  config: any;
  widgets: any[];

  availableStyleMap: any = {}
  systemFields: any;

  constructor(
    public metaService: MetaService,
    public widgetService: WidgetService,
    public systemFieldsService: SystemFieldsService

  ) { }

  widgetActions:any = [
    {
      id: "set_value",
      name: "Set value"
    },
    {
      id: "effects",
      name: "Effects"
    }
  ]

  events: any = [
    {
      id: "hover",
      name: "Hover"
    },
    {
      id: "click",
      name: "Click"
    }
  ]

  widgetManager: any = WidgetManager;

  ngOnInit(): void {

    if(this.actionConfig.action && !this.actionConfig.actionMap) {
      this.actionConfig.actionMap = { };
    } 

    let widgetConfig = WidgetManager.getWidget(this.widget.type).getWidgetConfig();
    this.availableStyleMap = widgetConfig?.effect || {};
    console.log("widgetConfig", widgetConfig)

    if(!this.actionConfig.actionMap.effectStyles) {
      this.actionConfig.actionMap.effectStyles = [];
    } 

    let te = new TemplateEngine();
    this.metaService.pageMeta.subscribe(pageMeta => {
      this.widgets = [];
      // this.pageMeta = pageMeta
      console.log("---> pagemeta", pageMeta)
      pageMeta.panels.forEach(panelMeta => {
        let panel = panelMeta;
        let widgets = this.widgetService.getWidgetsFromPanel(panel);
        widgets.forEach((widget ) => {
          if(WidgetManager.getWidget(widget.type)?.getWidgetConfig()){
            let config = {
                __id: "${panel.id}.${widget.id}",
                name: "${panel.name} ${widget.name}"
            }//WidgetManager.getWidget(widget.type)?.getMappingConfig();
            // if(config.__id.includes(".value")) config.__id = config.__id.replace(".value", '');
            let data = {
              panel: panel,
              widget: widget
            }
            this.widgets.push(te.fillTemplateObject(config, data))
          }
        })
      })

      console.log("this.widgets", this.widgets);
    })
  }

  onWidgetSelected(){
    console.log("this.actionConfig", this.actionConfig)
  }

  onWidgetActionSelected(){
    console.log("this.actionConfig", this.actionConfig)
  }

  insertFilterCursor(event){
    console.log("insertFilterCursor", event)
  }

  async getSystemFields(){
    let res = await this.systemFieldsService.getSystemFields();
    for (let index = 0; index < res?.fields?.length; index++) {
      const field = res?.fields[index];
      this.systemFields.push(res[field])
    }
    console.log("SYSTEM FIELDS: ",this.systemFields);
  }

  onEffectChanged(){
    if(!this.actionConfig.actionMap.effectStyles) {
      this.actionConfig.actionMap.effectStyles = [];
    } 
    
  }

  addNewAttribute(){
    this.actionConfig.actionMap.effectStyles.push({
      attribute: "",
      value: ""
    })
  }

  removeAttribute(i){
    this.actionConfig.actionMap.effectStyles.splice(i, 1);
  }

  onAttributeSelected(style){

  }

  setAttributeId(style){

  }

}
