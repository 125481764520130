import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { BallAtomSpinnerComponent } from '../ball-atom-spinner/ball-atom-spinner.component';
import { SpinnerService } from '../spinner.service';

@Component({
  selector: 'app-loading-text-spinner',
  templateUrl: './loading-text.component.html',
  styleUrls: ['./loading-text.component.scss'],
})
export class LoadingTextSpinnerComponent extends BallAtomSpinnerComponent implements OnInit {
  constructor(
    public spinnerService: SpinnerService,
    public themeService: ThemeService
  ) { super(spinnerService, themeService); }

  @Input() loadingText: string

  ngOnInit(): void { }
}
