import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-attribute-mapping',
  templateUrl: './attribute-mapping.component.html',
  styleUrls: ['./attribute-mapping.component.css']
})
export class AttributeMappingComponent implements OnInit {

  @Input() sourceAttributes;
  @Input() destAttributes;
  @Input() selfMap;
  @Output() mappingSelected = new EventEmitter;
  @Output() cancelAdd = new EventEmitter;

  allowedDataTypes: string[] = ['boolean', 'number', 'string', 'date']

  sourceAttrControl = new UntypedFormControl();
  destAttrControl = new UntypedFormControl();

  filteredSourceAttrs: any;
  filteredDestAttrs: any;
  selectedSourceAttr: any;
  selectedDestAttr: any;
  isSelectedSource: boolean = false
  isSelectedDest: boolean = false

  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.filteredSourceAttrs = this.sourceAttrControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        return typeof value === 'string' ? value : value.__id
      }),
      map(value => this._sourceAttrFilter(value))
    );

    this.filteredDestAttrs = this.destAttrControl.valueChanges.pipe(
      startWith(''),
      map((value: any) => {
        return typeof value === 'string' ? value : value.__id
      }),
      map(value => this._destAttrFilter(value))
    );
  }

  private _sourceAttrFilter(value: any): string[] {
    if (this.sourceAttributes && !this.sourceAttributes.length) return []
    const filterValue = value.toLowerCase();
    return this.sourceAttributes.filter(attr => attr.__id.toLowerCase().includes(filterValue));
  }

  private _destAttrFilter(value: any): string[] {
    if (this.destAttributes && !this.destAttributes.length) return []
    const filterValue = value.toLowerCase();
    return this.destAttributes.filter(attr => attr.__id.toLowerCase().includes(filterValue));
  }

  displayFnAttribute(attribute) {
    return attribute && attribute.name ? attribute.name : ''
  }

  sourceAttributeSelected(event){
    console.log("source attribute selected", event.option.value)
    this.selectedSourceAttr = event.option.value
    this.isSelectedSource = true
  }

  destinationAttributeSelected(event){
    console.log("destination attribute selected", event.option.value)
    this.selectedDestAttr = event.option.value
    this.isSelectedDest = true
  }

  mapAttrAdd(){
    if(this.selfMap){
      if(!this.isSelectedSource){
        console.log("link attribute not selected")
        return
      } else {
        console.log("mapAttrAdd for index", this.selectedSourceAttr)
        this.mappingSelected.emit({
          sourceAttr: this.selectedSourceAttr,
        })
      }
    } else {
      if(!this.isSelectedSource){
        console.log("source attribute not selected")
        return
      } else if (!this.isSelectedDest){
        console.log("dest attribute not selected")
        return
      }
      console.log("mapAttrAdd for index", this.selectedSourceAttr, this.selectedDestAttr)

      // let sourceTypeAllowed = this.allowedDataTypes.findIndex(type => type == this.selectedSourceAttr.dataType) > -1 ? true : false
      // let destTypeAllowed = this.allowedDataTypes.findIndex(type => type == this.selectedDestAttr.dataType) > -1 ? true : false

      // if(this.selectedSourceAttr.dataType !== this.selectedDestAttr.dataType){
      //   this.openSnackBar("Selected attributes have different data types", "Ok")
      // } else if(!sourceTypeAllowed){
      //   this.openSnackBar("Data type of source attribute is not allowed", "Ok")
      // } else if(!destTypeAllowed){
      //   this.openSnackBar("Data type of destination attribute is not allowed", "Ok")
      // } else {
      //   this.mappingSelected.emit({
      //     sourceAttr: this.selectedSourceAttr,
      //     destAttr: this.selectedDestAttr
      //   })
      // }
      this.mappingSelected.emit({
        sourceAttr: this.selectedSourceAttr,
        destAttr: this.selectedDestAttr
      })

    }
  }

  cancel(){
    this.cancelAdd.emit(true)
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
