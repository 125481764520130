
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { FlowService } from '../../flow.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConnectionDialogComponent } from "../../../shared/dialog/connection-dialog/connection.dialog";
import { ThemeService } from 'src/app/shared/services/theme.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { FlowPublishDialogComponent } from '../flow-publish-dialog/flow-publish-dialog.component';
import { PLATFORM_ID } from '@angular/core';
import { Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import panzoom from 'panzoom';
import { WebSocketService } from 'src/app/core/services/SocketService';
import { ScrollService } from 'src/app/core/services/ScrollService';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { FlowContentService } from '../../flow-content.service';
import { FlowTemplateComponent } from '../flow-template/flow-template.component';
import { CreateFlowDetailComponent } from '../create-flow-detail/create-flow-detail.component';
import { environment } from 'src/environments/environment';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
const uuid = require('uuid');

@Component({
  selector: 'app-creation',
  templateUrl: './creation.component.html',
  styleUrls: ['./creation.component.scss']
})
export class CreationComponent implements OnInit {

  isBrowser: any;
  isBlankSelected: boolean;
  isTemplateSelected: boolean;
  templateUnderPreview: any;
  stepperOptions: any = {
    step1: true,
    step2: false,
    step3: false,
    completed1: false,
    completed2: false,
    completed3: false
  }
  templateCode: any;
  spinner: boolean;
  error: boolean;
  authError: boolean;
  isReady: boolean;
  templateSteps: null;
  @ViewChild('stepper') public stepper: MatStepper;
  @ViewChild('flowtemplateComponent') flowtemplateComponent: FlowTemplateComponent;
  @ViewChild('flowdetailComponent') flowdetailComponent: CreateFlowDetailComponent;
  templateSearchPanel: { searchAttributes: { dataType: string; filterable: boolean; isColumnSelected: boolean; name: string; sortable: boolean; widgetType: string; __id: string; }[]; pageSize: number; hideTitle: boolean; searchButtonText: string; alignment: string; baseId: string; boxObjectId: string; name: string; outputListPanelId: string; type: string; };
  templatePanel: any;
  nameMap: any;

  constructor(
    public fs: FlowService,
    public router: Router,
    public snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) platformId: Object,
    private scrollService: ScrollService,
    public authService: AuthServiceService,
    public connectionService: ConnectionService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private pageService: PageService,
    private flowContentService: FlowContentService,
    private flowService: FlowService

    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
  }

  async ngOnInit() {
    this.authService.subproduct = "flow";
    this.route.queryParamMap.subscribe(async (params: any) => {
      console.log("form new route", params);
      if(params?.params?.code) this.templateCode = params?.params?.code;
    })
    if (!this.connectionService.selectedWorkSpace && !this.authService.loggedIn) {
      this.spinner = true;
      this.authService.authCheck()
      this.authService.authCheckPositive.subscribe(async (authStatus) => {
        //if logged in
        if (authStatus) {
          console.log("logged in")
          if (!this.connectionService.selectedWorkSpace) {
            await this.connectionService.getWorkSpaceId();
          };
          this.initialize()
          this.spinner = false;
        } else {
          this.error = true;
          this.authError = true
          this.spinner = false;
          this._snackBar.open('Apologies, the login attempt failed. Please reload the page and try logging in again.', 'ok', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          // redirect to login page
          this.ngZone.run(() => {
            this.router.navigate(['../'])
          })

          return
        }
      })
    } else if (this.connectionService.selectedWorkSpace) {
      this.initialize()
    }
  }

  ngOnDestroy() {

  }

  async initialize(){
    this.isReady = true;
    if(this.fs.flowCreateConfiguration.creationMode == 'template'){
      this.createFromTemplate();
    } else if(this.fs.flowCreateConfiguration.creationMode == 'blank'){
      this.createBlank();
    }
  }

  ngAfterViewChecked(): void {

  }

  ngAfterViewInit(): void {

  }

  createBlank(){
    this.templateUnderPreview = null;
    this.isBlankSelected = true;
    this.isTemplateSelected = false;
    this.router.navigate(
      ['flow/create']
    );
  }

  createFromTemplate(){
    this.spinner = true;
    this.resetDragInputs();
    this.templatePanel = this.flowContentService.getTemplatesPanel();
    this.templatePanel.listWidgetSet = this.getWidgetSet(this.templatePanel);
    this.templateSearchPanel = this.flowContentService.createSearchMeta();
    if(this.fs.flowCreateConfiguration.templateFilters?.length){
      this.templatePanel.filter.filterItems = this.templatePanel.filter.filterItems.concat(this.fs.flowCreateConfiguration.templateFilters);
    }
    this.isTemplateSelected = !this.isTemplateSelected;
    this.isBlankSelected = false;
    this.stepperOptions.step2 = false;
    this.stepperOptions.step3 = false;
    this.spinner = false;
  }

  getWidgetSet(meta){
    let widgetSet = {}
    for (let i = 0; i < meta.listAttributes.length; i++){
      const attr = meta.listAttributes[i];
      if(!attr.isDrillDown){
        let widgetType: string = attr.widgetType == 'NA' ? attr.fieldType : attr.widgetType;
        widgetSet[attr.__id] = WidgetManager.getWidget(widgetType || 'label')
      }else {
        attr.nestedProperties.forEach(prop => {
          let widgetType: string = prop.widgetType == 'NA' ? prop.fieldType : prop.widgetType;
          widgetSet[attr.__id + '.' + prop.path] = WidgetManager.getWidget(widgetType || 'label')
        })
      }
    }
    return widgetSet
  }

  onStepChange(event: StepperSelectionEvent) {
    if (event.selectedIndex === 1) {
      this.flowtemplateComponent?.initActions(this.templateSteps);
    } else if(event.selectedIndex === 0){
      this.stepperOptions.step2 = false;
      this.stepperOptions.step3 = false;
      this.stepperOptions.completed1 = false;
      this.stepperOptions.completed2 = false;
    }
  }

  async createTemplateFlow(){
    let flowMap = JSON.parse(JSON.stringify(this.templateUnderPreview));
    this.spinner = true;
    flowMap.name = this.nameMap.name;
    flowMap.code = uuid.v4();
    flowMap.workspace_id = this.connectionService.workSpaceId;
    flowMap.created_by = this.authService.profile?.email;
    flowMap.modified_by = this.authService.profile?.email;
    flowMap.isActive = false;
    flowMap.created_at = flowMap.created_at + "|date";
    flowMap.modified_at = flowMap.modified_at + "|date";

    //save published
    delete flowMap._id;
    delete flowMap.template_data;
    delete flowMap.template;
    delete flowMap.template_status;

    delete flowMap.last_execution_time;


    delete flowMap.published_at;

    console.log("this.flowMap", flowMap);
    let createdFlow = await this.flowService.createFlow(flowMap);
    console.log("createdFlow", createdFlow)

    let publishedFlow = JSON.parse(JSON.stringify(flowMap));
    publishedFlow._id = createdFlow;
    //update trigger setup with published id

    let draftFlow = JSON.parse(JSON.stringify(flowMap));
    this.setupTrigger(publishedFlow._id, draftFlow, publishedFlow);

    draftFlow.published_flow_id = publishedFlow._id;
    delete draftFlow.is_published;

    console.log("draftFlow", draftFlow);
    console.log("publishedFlow", publishedFlow);
    //save darft
    let createdDraftFlow = await this.flowService.createFlow(draftFlow);
    await this.flowService.saveFlow(publishedFlow, true);
    setTimeout(() => {
      this.spinner = false;
      this.router.navigate(
        ['flow/edit/' + createdDraftFlow]
      );
    }, 1000);

  }

  setupTrigger(pId, draftFlow, publishedFlow){
    if (draftFlow.trigger) {
      draftFlow.trigger.webhook_url = `${environment.SERVER_BASE_URL}/flow/webhook/${pId}`;
      publishedFlow.trigger.webhook_url = `${environment.SERVER_BASE_URL}/flow/webhook/${pId}`;
    }

    if (draftFlow.trigger?.schedulers?.length > 0) {
      delete draftFlow.trigger.schedulers[0].job;
      delete publishedFlow.trigger.schedulers[0].job;
    }
  }

  async selectedRowData(e){
    console.log("selected row data", e);
    this.stepperOptions.step2 = false;
    this.templateSteps = null;
    let template = e.rowDataRaw;
    this.templateUnderPreview = template;
    this.templateSteps = template.steps;
    this.flowdetailComponent?.setName(template.name);
    this.stepperOptions.step2 = true;
    this.stepperOptions.step3 = false;
    this.stepperOptions.completed1 = true;
    this.stepperOptions.completed2 = false;
    this.stepper.next();
    this.cdr.detectChanges();
  }

  async resetDragInputs(){
    this.pageService.widgetReadyForDrag = false;
    this.pageService.selectedRow = null;
    this.pageService.selectedLayout = null;
  }

  async actionMapped(e){
    console.log("actionMapped", e)
    this.templateSteps = e;
    this.ngZone.run(() => {
      this.stepperOptions.step3 = true;
      this.stepperOptions.completed2 = true;
      this.cdr.detectChanges();
    })
    console.log("stepperOptions", this.stepperOptions)

  }


  templateNameChanged(e){
    this.nameMap = e;
  }

}

