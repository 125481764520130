import { Component, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges, AfterViewInit, ElementRef, DoCheck } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';

@Component({
  selector: 'app-textinput',
  templateUrl: './textinput.component.html',
  styleUrls: ['./textinput.component.css']
})
export class TextinputComponent extends BaseWidgetComponent implements OnInit, OnChanges, AfterViewInit, DoCheck {

  contextMenuActions: any = {};

  hoveredNow: boolean = false;


  // @ViewChild('menuTrigger') inputMenuTrigger: MatMenuTrigger;
  @ViewChild('input') input: ElementRef;
  destroy: any;
  oldValue: any;
  fc = new FormControl({ value: '', disabled: true })

  validationSubscription: any

  constructor(
    public expressionUtility: ExpressionUtility,
    public metaService: MetaService,
    public validationService: ValidationService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if (contextActions && this.widgetMeta.id == contextActions?.widgetId) {
        this.action(contextActions);
      }
    });

    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      // console.log("validation subscription", data)
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        this.fc.markAsTouched()
      }
    })
    super.generateStyles()
    if (this.isDisabled) {
      this.fc.disable();  // Disable the form control
  } else {
      this.fc.enable();   // Enable the form control
  }
  }

  ngOnDestroy(): void {
    this.destroy?.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.widgetMeta?.config?.focus?.value) {
      this.input?.nativeElement?.focus();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetMeta && changes.widgetMeta.currentValue) {
      this.widgetMeta = changes.widgetMeta.currentValue;
      if (this.widgetMeta.config?.expressionConfig?.id) {
        let value = this.expressionUtility.resolveExpression(this.widgetMeta.config?.expressionConfig);
        this.widgetMeta.config.textContent.value = value;
      }
      this.initForm()
      this.emitUserInput();
      this.setContextActions();
    }

    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      // if (this.inputMenuTrigger && this.inputMenuTrigger.menuOpen) {
      //   this.inputMenuTrigger.closeMenu();
      // }
    }
    if (changes.contextActions?.currentValue) {
      this.action(changes.contextActions.currentValue);
    }
  }

  ngDoCheck(): void {
    if(this.oldValue != this.widgetMeta?.config?.textContent?.value){
      this.oldValue = this.widgetMeta?.config?.textContent?.value || ""
      // console.log("SELECT doCheck", this.oldValue)
      this.initForm()
      // console.log("calling from doCheck", this.oldValue)
      setTimeout(() => {
        this.userInputDetected()
      }, 200);
    }
  }

  initForm(){
    if(this.widgetMeta.config?.required?.value){
      this.fc.addValidators(Validators.required);
    }
    this.fc.patchValue(this.widgetMeta.config?.textContent?.value || '')
    // this.fc.markAsTouched()
    this.fc.updateValueAndValidity()
  }

  setContextActions() {
    this.contextMenuActions = {
      actions: [
        "appearance",
        "nofloat",
        "edit",
      ],
      appearance: {
        value: this.widgetMeta?.config?.appearance?.value,
        // availableTypes: this.widgetMeta?.config.appearance.availableTypes,
        type: this.widgetMeta?.config?.appearance?.type
      },
      nofloat: {
        value: this.widgetMeta?.config.nofloat?.value
      }
    };
    if(this.widgetMeta.textFormat){
      this.contextMenuActions.actions.unshift(...[
        "bold",
        "underline",
        "italic",
        "color",
        "fontSize",
        "fontFamily",
      ])
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions);
  }

  action(event: any) {
    if (event.actionType == "delete") {
      this.onDelete();
    }
    if(event.actionType == "updateStyles"){
      if (event?.data) {
        this.widgetMeta = JSON.parse(JSON.stringify(event.data));
        console.log("localMeta changed", this.widgetMeta)
        this.newWidgetMeta.emit(this.widgetMeta)
        // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        super.generateStyles();
        // console.log("styles re-generated", this.styles)
      }
    }
    if (event.actionType == "customPropertyUpdate" && event.propertyName === "appearance") {
      this.updateAppearance(event.data);
    }
  }

  onClick() {
    if (!this.builderMode) {
      return;
    }
    this.widgetSelection.emit(this.widgetMeta.id);
  }

  onDelete() {
    this.widgetDeletion.emit(this.widgetMeta.id);
    // this.inputMenuTrigger.closeMenu();
  }

  userInputDetected() {
    setTimeout(() => {
      this.emitUserInput();
    }, 200);
  }

  emitUserInput() {
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: this.widgetMeta.config?.textContent?.value,
      validity: this.fc.valid
    }

    if (this.widgetMeta.hasOwnProperty('propName')) {
      userInput.propName = this.widgetMeta.propName;
    }

    // console.log("emitting output", userInput)
    this.userInputReceived.emit(userInput);
  }

  getTextInputAppearance(): string {
    if (this.widgetMeta?.config?.appearance?.value) {
      if (this.contextMenuActions.nofloat.value) {
        return ''; // Empty string to disable floating label
      } else {
        return this.widgetMeta?.config?.appearance?.value;
      }
    }
    return '';
  }

  updateAppearance(appearanceValue: any) {
    console.log("appearance value", JSON.parse(JSON.stringify(appearanceValue)))
    this.widgetMeta.config.appearance.value = appearanceValue.config?.appearance?.value || this.widgetMeta.config.appearance.value;
    this.setContextActions();
  }

  getFormFieldClass(): string {
    if (this.widgetMeta.config?.appearance?.value) {
      switch (this.widgetMeta.config.appearance.value) {
        case 'default':
          return 'default-appearance-class';
        case 'rounded':
          return 'rounded-appearance-class';
        case 'outlined':
          return 'outlined-appearance-class';
      }
    }
    return '';
  }
}
