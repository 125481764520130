import { Widget } from "./baseWidget";
import { AvailableOptions } from "./optionsConfig";
import { TextFormatting } from "./textFormat";

interface item{
  content: string,
  disabled?: boolean,
  selected?: boolean
}

export class Choice extends Widget{
  type = 'choice';
  gridX = 12
  minGridX = 3
  selectedContent = ''   //will hold the content of selected item
  selectedIndex = -1   //will hold the index of selected item in items array

  config = {
    props: [
      'title',
      'availableOptions',
      'showTitle',
      'alignment',
      'orientation',
      'tooltip',
      'viewOnly'
    ],
    title: {
      displayName: 'Title',
      value: 'Choose an option',
      type: 'text',
    },
    availableOptions: AvailableOptions,
    showTitle: {
      displayName: 'Show Title',
      value: true,
      type: 'boolean'
    },
    orientation: {
      displayName: 'Orientation',
      value: 'horizontal',   //or vertical
      type: 'radio',
      availableItems: [
        {
          displayName: 'Horizontal',
          value: 'horizontal'
        },
        {
          displayName: 'Vertical',
          value: 'vertical'
        }
      ]
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      type: 'app-alignment-settings'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not change the selection',
      value: false,
      type: 'boolean',
      resetValue: false
    }
  };

  //text formatting properties
   textFormat;

  constructor(id: number, name: string){
    super(id, name)
    this.attachCommonConfig(this)

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))

    // add widget specific text formatting if any

  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    return map;
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  /**
   * to indicate this widget is a selectable widget with options
   */
  public isOptionBasedWidget(){
    return true
  }

  public setOptions(values){
    if(!values){
      this.config.availableOptions.staticOptions.forEach(opt => {
        if(opt.default) opt.default = false
      });
    }
    return values;
  }
}
