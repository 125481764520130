import { Widget } from "./baseWidget";


export class Card extends Widget{
  type = 'card';
  gridX = 6
  minGridX = 2
  structure = {
    title: {
      isPresent: true,
      text: 'sample mat card title'
    },
    subtitle: {
      isPresent: true,
      text: 'sample mat card subtitle'
    },
    avatar: {
      isPresesent: true,
      url: 'https://material.angular.io/assets/img/examples/shiba1.jpg'
    },
    sideImage: {
      isPresent: false,
      url: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
      size: 'sm'    //sm/md/lg/xl
    },
    centralImage: {
      isPresent: true,
      url: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
      height: 200,  //in px
      width: 200,   //in px
    },
    content: {
      isPresent: true,
      text: 'this is sample card text. this will be put in a paragraph'
    },
    actions: {
      isPresent: true,
      alignment: 'start'   //or end, (maybe center, have to test if center works)
    },
    actionButtons: [
      {
        buttonText: 'like',
        function: ''
      },
      {
        buttonText: 'share',
        function: ''
      }
    ],
    footer: {
      isPresent: true,
      text: 'footer text'   //could be anything, currently limiting to text
    }

  };
  config = {
    props: [
      'height',
      'width'
    ]
  };

  //text formatting properties
  //  textFormat = Object.assign({}, textFormatting);

  constructor(id: number, name: string){
    super(id, name)

    //initialize the styles with default values
    // this.textFormat = initStyles(this.textFormat)

    // add widget specific text formatting if any

  }
}
