import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChildren, QueryList, ElementRef, AfterViewInit, OnDestroy, SimpleChanges, SimpleChange, ViewChild, ChangeDetectorRef, Renderer2, NgZone } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { distinctUntilChanged, fromEvent, Observable, Subscription, throttleTime } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { PanelConfigurationComponent } from '../panel-configuration/panel-configuration.component';
import { PageService } from '../../services/page-service.service';
import { ResizeService } from './service/resize.service';
import { Panel } from '../../models/panelClasses/basePanel';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WidgetService } from '../../services/widget-service.service';
import { PLATFORM_ID } from '@angular/core';
import { Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { FormService } from 'src/app/form/form.service';
import { WidgetManager } from '../../models/WidgetManager';
import { MetaService } from '../../services/meta-service';
// import { PanelSettingsDialog2Component } from '../panel-settings-dialog2/panel-settings-dialog2.component';
@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() pageMeta;
  @Input() panelMeta;
  @Input() builderMode;
  // @Input() selectedWidgetId;
  @Input() selectedPanelId;
  @Input() hoveredPanelId;
  @Input() inputRowData;
  @Input() clearNewPanelPlaceholder;
  @Input() parentType: string;  // bloom | form | ""

  @Output() onPanelSelect = new EventEmitter<number>();
  @Output() onExecuteAction = new EventEmitter<any>();
  @Output() onPanelMouseenter = new EventEmitter<number>();
  @Output() onPanelMouseleave = new EventEmitter<number>();
  @Output() widgetSelection = new EventEmitter<number>();
  @Output() onPanelDelete = new EventEmitter<number>();
  @Output() newPanelMeta = new EventEmitter<number>();
  @Output() widgetDeletion = new EventEmitter<any>();
  @Output() userInputReceived = new EventEmitter<any>();
  @Output() executeActionResponse = new EventEmitter<any>();
  @Output() raiseSelectionChanged = new EventEmitter<any>();
  @Output() newWidgetCreated = new EventEmitter<any>();

  panelMetaSubscription: Subscription
  resizedWidgetMetaSub: Subscription

  runSpinnerForPanelId: any;

  selected: boolean = false;
  hovered: boolean = false;
  // hoveredWidgetId: any = '';

  ready: boolean = false

  @ViewChild('container') panelContainer: ElementRef

  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  yAxisLocked: boolean = true
  indicatePanelExit: boolean = false
  lastRecordedDistance: number
  eventOffsetInLastCheck: number
  currentEventOffset: number

  panelWidth: number;

  // xResizeableTypes = []
  yResizeableTypes = ['image', 'embed', 'chart', 'space']
  zResizeableTypes = ['image', 'chart']

  keyPressed: boolean = false;
  copiedType: string;

  windowLoaded: boolean = false
  // panelBackground: any;
  margin: any;
  // isShowBottomLineForRow: any = null;
  // isShowTopLineForRow: any = null;
  // hoveredWidget: any;

  @ViewChildren('widgets') widgetElements: QueryList<any>;
  isRowInsertPossibile: boolean;
  isBrowser: any;

  pageModelSub: any
  pageModel: any = {}
  dropCreateToRow: boolean;
  backgroundStyles: any = {}

  newWidDragMoveSub: any
  newWidDragEndSub: any

  runSpinnerForPanelIds: any[] = []
  overideStyle: any
  // lastRecordedDragMovePosition: any;

  // showNewPanelPlaceholder: boolean = false

  // newPanelPlaceholderTop: boolean = false
  // newPanelPlaceholderBottom: boolean = false

  @ViewChild('panelWrap') panelWrap: ElementRef;
  // isListDisabled: boolean = false;
  // isdragging: boolean = false

  skeletonSpinnerTypeMap: any = {
    'detailspanel': 'details-loader',
    'formpanel': 'form-loader'
  }

  constructor(
    public sps?: SpinnerService,
    private _ngZone?: NgZone,
    private el?: ElementRef,
    private renderer?: Renderer2,
    public resizeService?: ResizeService,
    public myPageService?: PageService,
    private settingsDialog?: MatDialog,
    private snackBar?: MatSnackBar,
    public widgetService?: WidgetService,
    private myMetaService?: MetaService,
    public myFormService?: FormService,
    public cdr?: ChangeDetectorRef,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;

  }

  migrateExistingPanelMeta(){
    let newLayout = { list: [], "gridX": 12};
    let id = new Date().valueOf();
    this.panelMeta?.widgets?.forEach((element, i) => {
      let rowId = new Date().setSeconds(new Date().getSeconds() + i).valueOf();
      newLayout.list.push(rowId)
      newLayout[rowId] = {
          type: "elements",
          elements: [element]
        }
    });
    let layout = {
      "list": [id],
      [id] : newLayout
    }
    this.panelMeta['layoutMap'] = layout;
    this.panelMeta.widgets = [];
    console.log("layout", layout)
  }

  ngOnInit(): void {
    if(this.panelMeta?.widgets?.length > 0){
        this.migrateExistingPanelMeta()
    }
    this.margin = this.panelMeta?.margin
    this.resizedWidgetMetaSub = this.resizeService.$resizedWidgetMeta.subscribe(widgetMeta => {
      this.raiseNewWidgetMeta(widgetMeta)
    })

    this.panelMetaSubscription = this.myPageService.$panelMetaChange.subscribe(panelMeta => {
      if (panelMeta.id == this.panelMeta.id) {
        this.raiseNewPanelMeta(panelMeta)
      }
    })

    this.widgetElements?.changes.subscribe(data => {
      // console.log("change in widgetElements", data)
    })

    this.pageModelSub = this.myPageService.$pageModelSub.subscribe(pageModel => {
      // console.log("page model subscription", JSON.parse(JSON.stringify(pageModel)))
      this.pageModel = JSON.parse(JSON.stringify(pageModel))
      this.handleTemplates()
    })

    this.myFormService.themeSubject.subscribe(theme => {
      if(!theme) return;
      this._ngZone.run(() => {
        this.applyStyles(theme);
        this.cdr?.detectChanges();
      })

    });

    // setDropListIds(dropListIds);
    // set droplist ids
    // const rowIds = []
    // this.panelMeta.layoutMap.list.forEach(colId => {
    //   this.panelMeta.layoutMap[colId].list.forEach( rowId => {
    //     rowIds.push(rowId)
    //   })
    // })
    // console.log("[rowIdAddition] rowIds added", rowIds, "in panel", this.panelMeta.id)
    // this.myPageService.setDropListIds(rowIds, this.panelMeta.id)

    // handle window resize
    // this.resizeObservable$ = fromEvent(window, 'resize')
    // this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
    //   console.log('window resize event: ', evt)
    // })

    this.newWidDragMoveSub = this.myPageService.$newWidgetDragMove.subscribe(receivedEvent => {
      // console.log("in panel", this.panelMeta.id)
      // console.log("hoveredPanelId", this.myPageService.hoveredPanelId)
      if(this.panelMeta.id !== this.myPageService.hoveredPanelId) return
      if(!this.myPageService.lastRecordedDragMovePosition){
        // console.log("no last recorded position: panel in", this.panelMeta.id, "is", this.myPageService.lastRecordedDragMovePosition)
        this.myPageService.lastRecordedDragMovePosition = JSON.stringify(receivedEvent.pointerPosition)
        this.dragMove(receivedEvent, true)
      }else{
        // console.log("last recorded position is: panel in", this.panelMeta.id, "is", this.myPageService.lastRecordedDragMovePosition)
        let isPositionSame = this.myPageService.lastRecordedDragMovePosition === JSON.stringify(receivedEvent.pointerPosition)
        if(!isPositionSame){
          // console.log("isPositionSame: panelId:", this.panelMeta.id, isPositionSame)
          this.myPageService.lastRecordedDragMovePosition = JSON.stringify(receivedEvent.pointerPosition)
          this.dragMove(receivedEvent, true)
        }
      }
    })

    this.newWidDragEndSub = this.myPageService.$newWidgetDragEnd.subscribe(dragEndEvent => {
      this.dropWidget(dragEndEvent)
    })
  }

  trackByFn(index, item) {
    return index;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("[PANEL] onChanges:", changes)

    // if (changes.selectedWidgetId && changes.selectedWidgetId.currentValue) {
    //   console.log("selected widget is", changes.selectedWidgetId.currentValue)
    // }

    // if (changes.clearNewPanelPlaceholder && changes.clearNewPanelPlaceholder.currentValue) {
    //   console.log("clearNewPanelPlaceholder", changes.selectedWidgetId.currentValue, "======================>")
    //   this.newPanelPlaceholderTop = false
    //   this.newPanelPlaceholderBottom = false
    // }

    if (changes?.panelMeta?.currentValue) {
      // console.log("[PANEL] onChanges: panelMeta changed", changes.panelMeta.currentValue)
      this.setBackgroundStyles()
      if(this.myFormService?.formMeta?.value?.theme?.selectedTheme) this.applyStyles(this.myFormService?.formMeta?.value?.theme?.selectedTheme);
    }
  }

  ngAfterViewInit() {
    this.panelWidth = this.panelContainer?.nativeElement?.offsetWidth;
    if(this.myFormService?.formMeta?.value?.theme?.selectedTheme) this.applyStyles(this.myFormService?.formMeta?.value?.theme?.selectedTheme);
  }

  manageSpinnerForPanel(data: { panelId: string; isSpinning: boolean }) {
    console.log("runSpinnerFor", data)
    if(data.isSpinning) this.runSpinnerForPanelIds.push(data.panelId)
    else this.runSpinnerForPanelIds = this.runSpinnerForPanelIds.filter(id => id != data.panelId)
  }

  getCombinedStyles(): { [key: string]: string } {
    let frameStyles = this.myFormService?.formMeta?.value?.theme?.selectedTheme?.frameconfig?.style || {};
    const backgroundStyles = this.panelMeta?.background?.image?.url
      ? {
          'background-image': 'url(' + this.panelMeta?.background?.image?.url + ')',
          'background-repeat': 'no-repeat',
          'background-size': 'cover'
        }
      : this.panelMeta?.background?.color
        ? { 'background-color': this.panelMeta.background.color }
        : { 'background-color': 'white' };

    let style = {
      ...frameStyles,
      ...backgroundStyles
    };
    return style;
  }


  //for builder mode
  applyStyles(theme){
    if(this.panelMeta?.background && this.panelMeta?.background?.color && this.panelMeta?.background?.color != "#fff") return;
    this.overideStyle = theme.frameconfig?.style;

    const element = this.el.nativeElement.querySelector('.panelWrap');
    if (element && theme) {
      Object.keys(theme).forEach(style => {
        this.renderer.setStyle(element, style, this.overideStyle?.[style]);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.panelMetaSubscription) {
      this.panelMetaSubscription.unsubscribe()
    }
    if (this.resizedWidgetMetaSub) {
      this.resizedWidgetMetaSub.unsubscribe()
    }
    if (this.newWidDragMoveSub) {
      this.newWidDragMoveSub.unsubscribe()
    }

    this.newWidDragEndSub?.unsubscribe()
  }

  //-------------------------------------- METHODS ---------------------------

  /**
   * replace templated values
   */
  handleTemplates(){
    // if(this.builderMode) return
    // if(this.panelMeta.type !== 'regular') return
    let replacementMap = {}
    Object.keys(this.pageModel).forEach(panelId => {
      Object.keys(this.pageModel[panelId]).forEach(widId => {
        replacementMap[panelId] = replacementMap[panelId] || {}
        replacementMap[panelId][widId] = this.isTemplateString(this.pageModel[panelId][widId].value) ? "" : this.pageModel[panelId][widId].value
      })
    })
    // console.log("")
    // console.log("template", JSON.parse(JSON.stringify(this.panelMeta.background?.image?.url || "")))
    // console.log("replacement map created", replacementMap)
    let te = new TemplateEngine()
    let newVal = te.fillAny(this.panelMeta.background?.image?.url || "", replacementMap, true)
    // console.log("replaced", newVal)
    if(this.panelMeta?.['background']?.['image']?.['url']){
      this.panelMeta['background']['image']['url'] = newVal
    }

    this.panelMeta.layoutMap?.list?.forEach((colId, i) => {
      let col = this.panelMeta.layoutMap[colId]
      if (col.background?.image?.url) {
        col.background.image.url = te.fillAny(col.background.image.url || "", replacementMap, true)
      }
    });
  }

  isTemplateString(val: string){
    if (typeof val !== 'string') return
    if(val.match(/\$\{[^\}]+\}/g)?.length) return true
    return false
  }


  deletePanel() {
    this.onPanelDelete.emit(this.panelMeta.id)
    this.myPageService.selectedPanel = null
    this.myPageService.selectedLayout = null
    this.myPageService.selectedRow = null
    this.deselectWidget()
  }

  // raisePanelSelect(event: any, panelId?:any) {
  //   event.stopPropagation()
  //   console.log("raisePanelSelect: event", event)
  //   this.myPageService.selectedRow = null
  //   // this.myPageService.selectedLayout = null
  //   this.myPageService.selectedWidget = null
  //   // this.widgetSelection()
  //   //consider it clicking outside of widget, so unselect any widget that is selected
  //   this.selectedWidgetId = -1
  //   this.myPageService.selectedWidget = null
  //   // this.widgetSelection.emit(-1)
  //   // event.stopPropagation();
  //   this.onPanelSelect.emit(this.panelMeta.id);
  //   // if(this.panelMeta.id == this.myPageService?.selectedPanel?.id){
  //   //   this.myPageService.selectedLayout = null;
  //   //   this.myPageService.selectedRow = null;
  //   // }
  // }


  onPanelSelection(event, panelId?: number) {
    if(!this.builderMode) return
    console.log("on panel selection")
    if(!this.widgetService.checkIfEventBubbling(event)) {
      // this.myPageService.selectedWidget = null
      this.deselectWidget()
      this.myPageService.selectedRow = null
      this.myPageService.selectedLayout = null
    }

    this.onPanelSelect.emit(panelId);
    //searches through the panels array by id and updates selectedNow property.
    // console.log("paneId", this.panelMeta.id)
    // console.log("this.pageMeta", this.pageMeta )
    // this.pageMeta.panels.forEach((panel) => {
    //   if (panel.id == this.panelMeta.id) {
    //     console.log("inn")
    //     panel.selectedNow = true;
    //     this.myPageService.selectedLayout = null;
    //     this.myPageService.selectedRow = null;
    //     this.myPageService.selectedPanel = panel;
    //     this.myPageService.widgetSelected = false;
    //     this.myPageService.panelSelected = true;
    //     this.selectedPanelId = panel.id;
    //   } else {
    //     panel.selectedNow = false;
    //   }
    // });
  }
/**
 * Can be a direct click on layout from DOM or manually called from child
 * @param event : null if elevated
 * @param layoutId
 * @param panelId
 * @param elevated : true if elevated from child click manually
 */
  onLayoutSelect(event, layoutId, panelId, elevated: boolean = false): void {
    console.log("onLayoutSelect", event, layoutId)
    // if(!elevated && event){
    //   event.stopPropagation();
    //   this.myPageService.selectedRow = null
    // }
    this.myPageService.selectedLayout = layoutId;
    if(!this.widgetService.checkIfEventBubbling(event)) {
      this.myPageService.selectedRow = null
      // this.myPageService.selectedWidget = null
      this.deselectWidget()
    }
    // this.selectPanel()
    // if(panelId == this.myPageService?.selectedPanel?.id){
    //   if(!this.myPageService.selectedLayout || this.myPageService.selectedLayout != layoutId){
    //     this.myPageService.selectedRow = null;
    //     this.myPageService.selectedLayout = layoutId;
    //   } else {
    //     this.myPageService.selectedLayout = null;
    //     this.myPageService.selectedRow = null;
    //   }
    // }
    // if (!elevated) this.myPageService.selectedRow = null
    // console.log("layout id set in page service", this.myPageService.selectedLayout)

    // TEST IF CLICK IS BUBBLED UP FROM CHILD OR DIRECTLY ON THIS ROW. IF DIRECT CLICK, DESELECT CHILDREN
    // const currentTarget = event.currentTarget as HTMLElement;
    // const target = event.target as HTMLElement;

    // if (target === currentTarget) {
    //   console.log("direct click, deselect children of selected layout", this.myPageService.selectedLayout)
    //   this.widgetService.widgetDeselected.next(true)
    //   this.raiseWidgetSelection(-1, this.myPageService.selectedLayout, this.myPageService.selectedRow, this.panelMeta.id)

    //   this.myPageService.selectedRow = null;
    // }
    // this.selectPanel()
  }



  layoutMouseEnter(event, layout){
    if(this.myPageService.panelReorderDragging) return
    if(!this.myPageService.isDragging()) return
    console.log("layoutMouseenter in layout", layout)
    this.myPageService.hoveredLayout = layout;
  }

  layoutMouseleave(event, layout){
    if(!this.myPageService.isDragging()) return
    // console.log("layoutMouseLeave from layout", layout)
    this.myPageService.hoveredLayout = null;
    this.myPageService.hoveredRow = null
    this.myPageService.hoveredWidgetId = null
    this.clearVisibilityFlagsExcept()
  }

  checkIfRowInsertPosible(){
    // console.log("check if row insert possible")
    // console.log("dragmap", this.myPageService.selectedDragMap)
    let hoveredLayout = this.myPageService.hoveredLayout;
    let hoveredRow = this.myPageService.hoveredRow;
    let hoveredPanelId = this.myPageService.hoveredPanelId;
    let result = false;
    if(hoveredPanelId){
      // console.log("hoveredPanelId present", hoveredPanelId)
      // this.pageMeta.panels.forEach(panel => {
      //   console.log("dealing panel")
      //   if(panel.id == hoveredPanelId){

      //   }
      // })
      let panel = this.pageMeta.panels.find(p => p.id == hoveredPanelId)
      if(!panel?.layoutMap) {
        console.log("panel not found")
        return result
      };
      let layout;
      if(hoveredLayout) layout = panel.layoutMap[hoveredLayout]
      let gridXLeft = 0;
      let takenGridX = 0;
      if(hoveredLayout && hoveredRow){
        // console.log("hoveredLayout and hoveredRow found: elements", layout?.[hoveredRow]?.['elements'])
        layout?.[hoveredRow]?.['elements']?.forEach(wid => {
          takenGridX += Number(wid?.gridX || 0)
        });
        // console.log("takenGridX", takenGridX)
        gridXLeft = 12 - takenGridX;
      }
      if(hoveredLayout && (hoveredRow && gridXLeft > 0)){
        result = true;
      }
    }
    this.isRowInsertPossibile = result;
    // console.log("is row insert possible", result)
    return result;
  }

  // isShowNewRowLine(panelMeta){
  //   let res = false;
  //   if(!this.myPageService?.widgetReadyForDrag) return res;
  //   if((!this.dropNextToWigdet && !this.checkIfRowInsertPosible() && !this.myPageService.hoveredRow && !this.myPageService.hoveredLayout) && panelMeta.id == this.hoveredPanelId) {
  //     // console.log("panelMeta in drag", panelMeta,layout)
  //     res = true;
  //   }
  //   this.dropCreateToRow = res;
  //   return res;
  // }
  // isShowTopLine(layout, panelMeta){
  //   let res = false;
  //   if(!this.myPageService?.widgetReadyForDrag) return res;
  //   if(!this.dropNextToWigdet && !this.checkIfRowInsertPosible() && !this.myPageService.hoveredRow && (this.myPageService.hoveredLayout && layout) && panelMeta.id == this.hoveredPanelId) {
  //     // console.log("panelMeta in drag", panelMeta,layout)
  //     res = true;
  //   }
  //   return res;
  // }

  checkRightLineVisibility(widgetId){
    // console.log("checkRightLineVisibility", widgetId)
    // console.log("selected drag map", JSON.parse(JSON.stringify(this.myPageService?.selectedDragMap || {})))
    // let res = false;
    if(!this.myPageService?.widgetReadyForDrag) return false;
    if(!this.checkIfRowInsertPosible()) return false;
    if(this.myPageService?.selectedDragMap?.widgetId == widgetId) return false; // same widget hover
    // widget?.id == this.myPageService.hoveredWidgetId &&

    this.myPageService.isShowRightLineForWidget = widgetId;
    // console.log("right line set", this.myPageService.isShowRightLineForWidget)
    // console.log("returning true");
    return true
  }

  checkRowBottomLineVisibility(row){
    let res = false;
    if(!this.myPageService.isDragging()) return
    // if(!this.myPageService.hoveredWidget) return res;

    if (row == this.myPageService.hoveredRow && !this.checkIfRowInsertPosible() && this.myPageService?.widgetReadyForDrag) {
      res = true;
      this.myPageService.isShowBottomLineForRow = row;
    }
    // if(!this.checkIfRowInsertPosible()) res = true;
    // if (row == this.myPageService.hoveredRow && (!this.hoveredWidgetId || this.hoveredWidgetId == -1) && this.myPageService?.widgetReadyForDrag) {
    //   res = true;
    // }

    // else if((!this.hoveredWidgetId || this.hoveredWidgetId == -1) && this.dropNextToWigdet){
    //   res = true;
    // }

    // else if (this.hoveredWidget.id == this.hoveredWidgetId && (this.myPageService?.selectedDragMap?.widgetId != this.hoveredWidget.id) && this.myPageService?.widgetReadyForDrag && !this.checkIfRowInsertPosible()){
    //   res = true;
    // }

    return res;
  }

  getLayoutAlignment(layoutMap, panelAlignment){
    let result = 'start';
    if(!layoutMap.alignment && panelAlignment) result = panelAlignment;
    else result = layoutMap.alignment;
    return result
  }

  rowMouseEnter(event, row){
    // console.log("rowMouseEnter row", row)
    if(this.myPageService.panelReorderDragging) return
    this.myPageService.hoveredRow = row;
  }

  rowMouseleave(event, row){
    this.myPageService.hoveredRow = null;
  }

/**
 * can be a direct click from DOM or an elevated function call from child click manually
 * @param event : null if manually elevated from child
 * @param rowId
 * @param layoutId
 * @param panelId
 * @param elevated : if triggered manually from child, this should be true
 */
  onRowSelect(event, rowId, layoutId, panelId, elevated: boolean = false){
    console.log("onRowSelect", event, rowId, layoutId)
    // if(!elevated && event) {
    //   event.stopPropagation();
    //   this.deselectWidget()
    // }
    this.myPageService.selectedRow = rowId;
    if(!this.widgetService.checkIfEventBubbling(event)) {
      // this.myPageService.selectedWidget = null
      this.deselectWidget()
    }
    // this.myPageService.selectedLayout = layoutId;
    // this.onLayoutSelect(null, layoutId, panelId, true)
  }

  deselectWidget(){
    this.myPageService.selectedWidget = null
    this.widgetService.widgetDeselected.next(true)
  }

  raisePanelMouseenter(event: any) {
    // console.log("panel mouseenter in", this.panelMeta.id, "isDragging", this.myPageService.isDragging())
    event.stopPropagation();
    this.myPageService.hoveredPanelId = this.panelMeta.id
    // console.log("this.myPageService.hoveredPanelId", this.myPageService.hoveredPanelId)
    this.onPanelMouseenter.emit(this.panelMeta.id)
    // if(!this.myPageService.isDragging()) {
    //   // this.myPageService.hoveredPanelId = this.panelMeta.id
    //   // console.log("panel mouseenter called")
    //   return
    // }

    // if cursor entered inside a panel, reset new panel placeholder. New panel placeholder is only shown when hovering outside a panel
    this.myPageService.dragNewPanelPlaceholder = {
      panelId: '',
      direction: '',
      newIndex: -1
    }
  }

  raisePanelMouseleave(event: any, panelId: number, leavingDirection?: string) {
    event.stopPropagation();

    this.myPageService.resetHoverStates()
    // console.log("calling clear visibility: RAISE PANEL MOUSELEAVE")

    this.clearVisibilityFlagsExcept()
    // console.log("panel Mouseleave called")
    this.onPanelMouseleave.emit(this.panelMeta.id)


    // console.log("isDragging", this.myPageService.isDragging())
    if(!this.myPageService.isDragging()) return
    let dir = this.checkPanelMouseleaveDirection(event, this.panelWrap)
    // console.log("leaving from", dir)
    let currIndex = this.pageMeta.panels.findIndex(p => p.id == this.panelMeta.id)
    if(dir == 'top'){
      this.myPageService.dragNewPanelPlaceholder = {
        panelId: this.panelMeta.id,
        direction: 'top',
        newIndex: currIndex
      }
      console.log("new panel placeholder", this.myPageService.dragNewPanelPlaceholder)
    } else if (dir == 'bottom') {
      this.myPageService.dragNewPanelPlaceholder = {
        panelId: this.panelMeta.id,
        direction: 'bottom',
        newIndex: currIndex + 1
      }
      console.log("new panel placeholder", this.myPageService.dragNewPanelPlaceholder)
    }
  }

  checkPanelMouseleaveDirection(event, boundingElement: ElementRef<any>){
    // console.log("event", event)
    const boundingRect = boundingElement?.nativeElement?.getBoundingClientRect();

    const mouseY = event.clientY || event.y;
    const topBoundary = boundingRect.top;
    const bottomBoundary = boundingRect.bottom;
    // console.log("topBoundary", topBoundary)
    // console.log("bottomBoundary", bottomBoundary)
    // console.log("mousey", mouseY)
    let res
    // let res = mouseY >= topBoundary && mouseY <= topBoundary + 20;
    if (Math.abs(mouseY - topBoundary) < Math.abs(mouseY - bottomBoundary)){
      res = 'top'
    } else {
      res = 'bottom'
    }
    return res
  }


  widgetMouseleave(event: any, widget: any) {
    this.myPageService.hoveredWidgetId = null;
    // console.log("widgetMouseleave", widget.id, "cleared hoverstate", this.myPageService.hoveredWidgetId)
    // this.hoveredWidgetId = ''
    // this.hoveredWidget = null;
    // this.hoveredWidgetId = null;
    event.stopPropagation();
    this.onPanelMouseenter.emit(this.panelMeta.id)

    if(!this.myPageService.isDragging()) return
    // console.log("widget mouseleave called, widget:", widget)
    // console.log("widgetMouseleave DragMap", JSON.parse(JSON.stringify(this.myPageService.selectedDragMap)))
  }

  widgetMouseenter(event: any, widget: any, widRef?: any, row?: any) {
    // this.hoveredWidget = widget;
    // this.hoveredWidgetId = widget.id;
    if (this.myPageService.panelReorderDragging) {
      // console.log("reorder dragging, returning")
      return
    }
    this.myPageService.hoveredWidgetId = widget.id;
    event.stopPropagation();
    this.onPanelMouseenter.emit(this.panelMeta.id)

    if(!this.myPageService.isDragging()) return
    // console.log("widget mouseenter, event:", event, "widget", widget, "ref", widRef, "row", row)
    // console.log("widgetMouseenter DragMap", JSON.parse(JSON.stringify(this.myPageService.selectedDragMap)))
  }

  dragMove(event: any, isNewWidget: boolean = false) {
    // console.log("-----------> DRAG MOVE fired", event, "isNew", isNewWidget)
    // let draggedWidgetId = this.myPageService.selectedDragMap.widgetId
    // let rowId = this.myPageService.selectedDragMap.rowId
    // let layoutId = this.myPageService.selectedDragMap.layoutId
    let hoveredLayout = this.myPageService.hoveredLayout
    let hoveredRow = this.myPageService.hoveredRow

    // console.log("dragged widget is", draggedWidgetId)

    // console.log("dragMove", event, "on widget", draggedWidgetId)
    // this.hoveredWidget = widget;
    // this.hoveredWidgetId = widget.id;
    // this.myPageService.hoveredWidgetId = event;
    // event.stopPropagation();
    // this.onPanelMouseenter.emit(this.panelMeta.id)

    if(!this.myPageService.isDragging()) return


    if(['searchPanel', 'listPanel', 'detailsPanel', 'formPanel'].includes(event.source?.data?.newWidgetType)) {
      console.log("cant create inside another panel; returning")
      this.clearVisibilityFlagsExcept()
      return
    }

    // console.log("HOVERED LAYOUT", this.myPageService.hoveredLayout)
    // console.log("layout", this.myPageService.hoveredLayout, "row", this.myPageService.hoveredRow, "widget", this.myPageService.hoveredWidgetId)
    // event.stopPropagation();
    // console.log("move: panel", this.myPageService.hoveredPanelId, "layout:", this.myPageService.hoveredLayout, "row", this.myPageService.hoveredRow, "widget", this.myPageService.hoveredWidgetId)
    // console.log("widgetMouseMove DragMap", JSON.parse(JSON.stringify(this.myPageService.selectedDragMap)))
    // console.log("widget mousemove, event:", event, "widget", widget, "ref", widRef, "row", row)
    const srcElement = event.event.srcElement as HTMLElement;
    // const parentElement = srcElement.parentElement;
    // console.log("parentElement", parentElement)

    // check if hovering over first row in a layout
    if (this.myPageService.hoveredPanelId && this.myPageService.hoveredLayout && this.myPageService.hoveredRow) {
      // console.log("block - 1")
      let panel = this.pageMeta.panels.find(p => p.id == this.myPageService.hoveredPanelId)
      if(!panel) return
      // let layoutObj = panel.layoutMap[this.myPageService.hoveredLayout]

      // if(layoutObj['list']?.[0] == this.myPageService.hoveredRow) {
      //   console.log("hovering top row")


      // } else {
      //   console.log("not hovering over top row")
      // }

      let parent = this.findParentWithClass(srcElement, 'row')
      let isCloserToTop = this.checkIfCloserToBoundary(event, parent, 'top')
      let isCloserToBottom = this.checkIfCloserToBoundary(event, parent, 'bottom')

      if (isCloserToTop){
        // this.isShowTopLineForLayout = layoutId
        this.myPageService.isShowTopLineForRow = this.myPageService.hoveredRow
        this.clearVisibilityFlagsExcept('top')
        return
      } else if (isCloserToBottom) {
        this.myPageService.isShowBottomLineForRow = this.myPageService.hoveredRow
        this.clearVisibilityFlagsExcept('bottom')
        return
        // this.isShowTopLineForLayout = null
      } else {
        if (this.checkIfRowInsertPosible()) {
          let panel = this.pageMeta.panels.find(panel => panel.id == this.myPageService.hoveredPanelId)
          let elements = panel.layoutMap[hoveredLayout][hoveredRow].elements
          let lastWidgetInRow = elements[elements.length - 1]
          this.myPageService.isShowRightLineForWidget = lastWidgetInRow?.id

          this.clearVisibilityFlagsExcept('right')
        } else {
          this.myPageService.isShowBottomLineForRow = this.myPageService.hoveredRow
          this.clearVisibilityFlagsExcept('bottom')
        }
      }
    }


    // when hovering over widget, calculate line visibility with respect to widget
    // if (this.myPageService.hoveredLayout && this.myPageService.hoveredRow && this.myPageService.hoveredWidgetId) {
    //   console.log("block - 2")
    //   if (!parentElement) return
    //   const rect = parentElement.getBoundingClientRect();
    //   const mouseX = event.event.clientX - rect.left;
    //   const mouseY = event.event.clientY - rect.top;

    //   const distances = {
    //     top: mouseY,
    //     right: rect.width - mouseX,
    //     bottom: rect.height - mouseY,
    //     left: mouseX
    //   };
    //   // console.log("top", distances.top)
    //   // console.log("right", distances.right)
    //   // console.log("bottom", distances.bottom)
    //   // console.log("left", distances.left)
    //   const leastDistance = Math.min(distances.top, distances.right, distances.bottom, distances.left);

    //   // TOP
    //   if(leastDistance == distances.top) {
    //     console.log("top is least", leastDistance)
    //   }
    //   // RIGHT
    //   else if(leastDistance == distances.right) {
    //     console.log("right is least")
    //     this.checkRightLineVisibility(this.myPageService.hoveredWidgetId)

    //     this.clearVisibilityFlagsExcept('right')

    //   }
    //   // BOTTOM
    //   else if(leastDistance == distances.bottom) {

    //     this.checkRowBottomLineVisibility(rowId)
    //     this.clearVisibilityFlagsExcept('bottom')
    //     // console.log("isShowBottomLine", this.isShowBottomLineForRow)

    //   }
    //   // LEFT
    //   else {
    //     // console.log("left is least", leastDistance)
    //   }
    // }

    // // when not overing on widget but inside row, check if row insert possible
    // else if (this.myPageService.hoveredLayout && this.myPageService.hoveredRow && !this.myPageService.hoveredWidgetId) {
    //   console.log("block - 3")
    //   this.checkIfRowInsertPosible()
    //   // console.log("isRowInsertPossibile", this.isRowInsertPossibile)
    //   if (this.isRowInsertPossibile) {
    //     let elements = this.panelMeta.layoutMap[layoutId][rowId].elements
    //     let adjascentWidget = elements[elements.length - 1]
    //     // console.log("adjascent widget", adjascentWidget)
    //     this.myPageService.isShowRightLineForWidget = adjascentWidget.id

    //     this.clearVisibilityFlagsExcept('right')
    //     // console.log("showRightLineFor", this.isShowRightLineForWidget)
    //   } else {
    //     // console.log("show bottom line for row", rowId)

    //     this.isShowBottomLineForRow = rowId
    //     this.clearVisibilityFlagsExcept('bottom')
    //   }
    // }

    // when not inside row, but inside layout
    else if (this.myPageService.hoveredLayout && !this.myPageService.hoveredRow) { //&& !this.isShowBottomLineForRow && !this.isShowRightLineForWidget
      console.log("block - 4")
      this.myPageService.isShowNewRowLineForLayout = this.myPageService.hoveredLayout
      this.clearVisibilityFlagsExcept('new-row')
      // console.log("need to highlight new dummy row for layout", this.myPageService.hoveredLayout, "type", typeof this.myPageService.isShowNewRowLineForLayout)
    }
  }

  checkIfCloserToBoundary(event, boundingElement: HTMLElement, boundaryDirection: string){
    // console.log("checkIfCloserToBoundary hit, event", event)
    const boundingRect = boundingElement?.getBoundingClientRect();
    if (!boundingRect) return false
    const mouseY = event.event.clientY || event.y || event.clientY;
    // console.log("client Y", mouseY)

    let boundaryPosition: number, result: boolean = false;
    if(boundaryDirection == 'top'){
      boundaryPosition = boundingRect.top;
      result = mouseY >= boundaryPosition && mouseY <= boundaryPosition + 10; // closer to top boundary within 20 px towards bottom
      // console.log("closer to top boundary ?", result);
    } else if (boundaryDirection == 'bottom') {
      boundaryPosition = boundingRect.bottom;
      result = mouseY <= boundaryPosition && mouseY >= boundaryPosition - 10; // closer to bottom boundary within 20 px towards top
      // console.log("closer to bottom boundary ?", result);
    }

    // console.log("topBoundary", boundingRect.top)
    // console.log("mousey", mouseY)
    return result
  }

  findParentWithClass(element: HTMLElement, className: string): HTMLElement | null {
    let currentElement: HTMLElement | null = element;
    while (currentElement) {
      if (currentElement.classList.contains(className)) {
        return currentElement;
      }
      currentElement = currentElement.parentElement;
    }
    return null;
  }


  clearVisibilityFlagsExcept(toPreserve?: string){
    toPreserve !== 'bottom' ? this.myPageService.isShowBottomLineForRow = null : ''
    toPreserve !== 'right' ? this.myPageService.isShowRightLineForWidget = null : ''
    // toPreserve !== 'left' ? this.isShowLeftLineFor = null : ''
    toPreserve !== 'top' ? this.myPageService.isShowTopLineForRow = null : ''
    toPreserve !== 'new-row' ? this.myPageService.isShowNewRowLineForLayout = null : ''
  }

  // widgetHover(event: any, widget?: any) {
  //   // console.log("emitted hoveredNow", event)
  //   // this.hoveredWidget = widget;
  //   // this.hoveredWidgetId = event;
  //   this.myPageService.hoveredWidgetId = widget.id;
  //   // console.log("widget hover", event)
  // }

  applyImageHeight(height: any, widget: any, widRef: any) {
    widRef.style.height = height + 'px'
  }

  applyWidgetHeight(height: any, widget: any, widRef: any) {
    widRef.style.height = height + 'px'
  }

  // raiseWidgetSelection(event: any, layoutId?:any, rowId?: any, panelId?:any) {

  //   // if(panelId == this.myPageService?.selectedPanel?.id && this.myPageService.selectedLayout == layoutId && this.myPageService.selectedRow != rowId){
  //   //   this.myPageService.selectedRow = rowId;
  //   // }
  //   console.log("raiseWidgetSelection for id", event)
  //   this.widgetService.widgetDeselected.next(true) // to hide sidebar menu for previously selected widget
  //   // console.log("selectedLayout", this.myPageService.selectedLayout)
  //   this.selectedWidgetId = event
  //   this.widgetSelection.emit(event)

  //   this.onRowSelect(null, rowId, layoutId, panelId, true)
  //   // this.myPageService.selectedRow = rowId;
  //   // this.myPageService.selectedLayout = layoutId
  //   // this.selectPanel()
  // }

  handleMouseenter(event: any) {
    event.stopPropagation()
    this.hovered = true
  }

  widgetClick(event: any, widgetMeta: any, rowId?, layoutId?) {
    console.log("widget click", event, rowId, layoutId, widgetMeta)
    // event.stopPropagation();
    // this.myPageService.selectedRow = rowId
    // this.myPageService.selectedLayout = layoutId
    // this.selectPanel()
    this.myPageService.selectedWidget = widgetMeta.id
    this.widgetService.openWidgetSidebarSettings.next({
      panelId: this.panelMeta.id,
      widgetMeta: widgetMeta
    })
    // this.selectedWidgetId = widgetMeta.id
    // this.selectPanel(this.panelMeta.id);
    // // this.onPanelSelect.emit(this.panelMeta.id)
  }

  widgetDrop(event: CdkDragDrop<[]>) {
    moveItemInArray(this.panelMeta.widgets, event.previousIndex, event.currentIndex);
  }


  /**
   * triggered when moving a widget from one panel to another
   * the event is pushed into observable so that the drop event is caught in any panel wherever the widget is dropped
   * (not only in the droplist of which widget is part of)
   * @param $event
   */
  dropDetectedInCanvas($event){
    console.log("widget drag end detected in panel, nexting into observable", $event)
    this.myPageService.newWidgetDragEnd.next($event)
  }

  dropWidget(event) {
    console.log("[--- DROP WIDGET ---] : this panel", JSON.parse(JSON.stringify(this.panelMeta)))
    
    // console.log("id", this.panelMeta.id, "this panel", JSON.parse(JSON.stringify(this.panelMeta)))
    // console.log("page", JSON.parse(JSON.stringify(this.pageMeta)))
    console.log("this.myPageService.hoveredPanelId", this.myPageService.hoveredPanelId)
    // console.log("this.myPageService.dragNewPanelPlaceholder", JSON.parse(JSON.stringify(this.myPageService.dragNewPanelPlaceholder)))
    console.log("hovered layoutId", this.myPageService.hoveredLayout)
    console.log("all panels", JSON.parse(JSON.stringify(this.pageMeta.panels)))
    
    this.myPageService.lastRecordedDragMovePosition = null
    let layoutId = this.myPageService.hoveredLayout;
    let rowId = this.myPageService.hoveredRow;
    // console.log("this.myPageService.footerInFocus.value", this.myPageService.footerInFocus.value)
    if(this.pageMeta.code == "__FOOTER__" && !this.myPageService.footerInFocus.value){
      console.log("panel belongs to footer but footer not in focus")
      return
    }
    if(this.pageMeta.code !== '__FOOTER__' && this.myPageService.footerInFocus.value){
      console.log("panel does not belong to footer but footer in focus")
      return
    }
    if((this.myPageService.hoveredPanelId && this.myPageService.hoveredPanelId !== -1) && this.myPageService.hoveredPanelId !== this.panelMeta.id) {
      console.log("hoveredPanelId exists but drop panel and hovered panel not same")
      return
    }
    if(this.myPageService.hoveredPanelId !== this.panelMeta.id && (typeof this.myPageService.dragNewPanelPlaceholder.newIndex !== 'number' || this.myPageService.dragNewPanelPlaceholder.newIndex < 0)) {
      console.log("hovered panel & this panel are different but new panel placeholder is not set")
      return
    }

    let selectedDragMap = this.myPageService.selectedDragMap

    // let panelMeta = this.pageMeta.panels.find(p => p.id == panelId)

    // console.log("dropWidget", event, "rowId", rowId, "layoutId", layoutId, "panelMeta", panelMeta)
    // console.log("selectedDragMap", JSON.parse(JSON.stringify(selectedDragMap)))
    // this.isListDisabled = false
    // let hoveredLayout = this.myPageService.hoveredLayout;
    // let hoveredWidgetId = this.hoveredWidgetId;

    // let hoveredLayout = layoutId;
    // let hoveredRow = rowId;
    // let hoveredPanelId = panelMeta.id;
    let draggedWidget = event.item.data;
    // console.log("draggedWidget", draggedWidget)
    if(draggedWidget.hasOwnProperty('newWidgetType') && !['listPanel', 'searchPanel', 'detailsPanel', 'formPanel'].includes(draggedWidget.newWidgetType)){
      let newWidget = WidgetManager.getWidget(draggedWidget.newWidgetType)
      // console.log("new widget meta generated", newWidget)
      draggedWidget = newWidget
    }
    let hoveredWidgetId = this.myPageService.hoveredWidgetId;

    // console.log("this.myPageService.widgetReadyForDrag", this.myPageService.widgetReadyForDrag)
    // console.log("hoveredLayout", layoutId)
    // console.log("hoveredRow", rowId)
    // console.log("hoveredPanelId", panelId)
    // console.log("hoveredWidgetId", hoveredWidgetId);
    // console.log("dropCreateToRow", this.dropCreateToRow)
    // console.log("draggedWidget", draggedWidget)

    if (this.myPageService.dragNewPanelPlaceholder.newIndex >= 0) {

      if(['listPanel', 'searchPanel', 'detailsPanel', 'formPanel'].includes(draggedWidget.newWidgetType)) {
        this.widgetService.widgetNotifier(draggedWidget.newWidgetType)
      } else {
        // CREATE NEW PANEL
        let newPanel = {
          "type": "regular",
          "alignment": "flex-start",
          "layoutCount": "1",
          "widgets": [],
          "id": Date.now(),
          "name": `panel_${this.myPageService.dragNewPanelPlaceholder.newIndex}`,
          "selectedNow": false,
          "background": {
            "color": "#fff",
            "image": {
                "url": "",
                "opacity": 50
            }
          },
          "margin": {
            "left": 0,
            "right": 0
          },
          layoutMap: {
            list: []
          }
        }
        let layoutId = new Date().setSeconds(new Date().getSeconds() + 1).valueOf();
        newPanel.layoutMap.list.push(layoutId);
        newPanel.layoutMap[layoutId] = {
          gridX: 12,
          list: []
        }
        let rowId = new Date().valueOf();
        let elements = [draggedWidget];
        newPanel.layoutMap[layoutId][rowId] = {
          type: "elements",
          elements: elements
        }
        newPanel.layoutMap[layoutId].list.unshift(rowId);

        if (this.parentType == 'form' && this.myPageService.dragNewPanelPlaceholder.newIndex > this.pageMeta.panels.length - 1) {
          console.log("create panel before last panel")
          this.pageMeta.panels.splice(this.myPageService.dragNewPanelPlaceholder.newIndex - 1, 0, newPanel)
        } else {
          this.pageMeta.panels.splice(this.myPageService.dragNewPanelPlaceholder.newIndex, 0, newPanel)
        }
        
        this.deleteSelectedDragedWidget(selectedDragMap);
        console.log("page meta updated", JSON.parse(JSON.stringify(this.pageMeta)))

        this.myPageService.dragNewPanelPlaceholder = {
          newIndex: -1,
          panelId: '',
          direction: ''
        }
        console.log("dragNewPanelPlaceholder reset", JSON.parse(JSON.stringify(this.myPageService.dragNewPanelPlaceholder)))
      }


    } else {
      console.log("selectedDragMap", selectedDragMap, "this.myPageService.widgetReadyForDrag", this.myPageService.widgetReadyForDrag)
      if(['listPanel', 'searchPanel', 'detailsPanel', 'formPanel'].includes(draggedWidget.newWidgetType)) return
      if(!selectedDragMap || !this.myPageService.widgetReadyForDrag) { console.log("no selectedDragMap or !widget ready for drag"); return }
      if(!this.myPageService.hoveredPanelId) { console.log("no panelId"); return }
      let panel = this.pageMeta.panels.find(p => p.id == this.myPageService.hoveredPanelId)
      if(!panel) { console.log("no panelId"); return }
      else { console.log("panel found", JSON.parse(JSON.stringify(panel))) }

      let layout;
      if(layoutId) layout = panel.layoutMap[layoutId]
      let remainingGridX = 0;
      let takenGridX = 0;
      if(layoutId && rowId){
        console.log("layout", JSON.parse(JSON.stringify(layout || {})), "rowId", rowId)
        layout[rowId]?.['elements']?.forEach(wid => {
          takenGridX += Number(wid?.gridX || 0)
        });
        remainingGridX = 12 - takenGridX;
      }

      // if(layoutId && (rowId && remainingGridX > 0)){  // can place inside hovered row
      if(this.myPageService.isShowRightLineForWidget){  // can place inside hovered row
        draggedWidget.gridX = remainingGridX;
        if(layout[rowId]?.type != 'elements') return;
        let index = panel.layoutMap[layoutId][rowId]['elements'].findIndex(item => item.id === hoveredWidgetId);
        if(index == -1) {
          // console.log("will push")
          panel.layoutMap[layoutId][rowId]['elements'].push(draggedWidget)
        }else{
          let indexToInsert = index + 1;
          // console.log("index decided", indexToInsert, "in array", JSON.parse(JSON.stringify(panel.layoutMap[layoutId][rowId]['elements'])))
          panel.layoutMap[layoutId][rowId]['elements'].splice(indexToInsert, 0, draggedWidget);
        }
        // console.log("after insert", JSON.parse(JSON.stringify(panel.layoutMap[layoutId][rowId]['elements'])))
        this.deleteSelectedDragedWidget(selectedDragMap);

      // } else if(rowId && layout) {  //
      } else if(rowId && layout) {  //
        let newRowId = new Date().valueOf();

        let indexOfHoveredRow = layout.list.findIndex(rid => rid == rowId)
        // // let parent = this.findParentWithClass(event.item.element.nativeElement, 'layoutContent')
        // let parent = this.findParentWithClass(event.event.srcElement, 'layoutContent')
        // console.log("parent", parent)
        // let isCloser = this.checkIfCloserToBoundary(event.event, parent, 'top')
        // if(indexOfHoveredRow > 0) {
        //   layout.list.splice(indexOfHoveredRow + 1, 0, newRowId)

        // } else if (indexOfHoveredRow == 0 && isCloser) {
        //   // console.log("drop at top line")
        //   layout.list.unshift(newRowId);

        // } else {
        //   layout.list.push(newRowId)
        // }

        if (this.myPageService.isShowTopLineForRow == rowId) {
          if (indexOfHoveredRow == 0) { // insert as first element
            layout.list.unshift(newRowId);
          } else if (indexOfHoveredRow > 0) { // insert in place of hovered row, move hovered row ahead
            layout.list.splice(indexOfHoveredRow, 0, newRowId);
          }
        } else if (this.myPageService.isShowBottomLineForRow == rowId) {
          if (indexOfHoveredRow == layout.list.length - 1) {  // if hovering over last row, push new row
            layout.list.push(newRowId);
          } else { // insert after hovered row
            layout.list.splice(indexOfHoveredRow + 1, 0, newRowId);
          }
        }

        let elements = [draggedWidget];
        layout[newRowId] = {
            type: "elements",
            elements: elements
        }
        this.deleteSelectedDragedWidget(selectedDragMap);

      } else if (layout) {
        // console.log("isShowTopLine", this.isShowTopLineForLayout)

        // let parent = this.findParentWithClass(event.item.element.nativeElement, 'layoutContent')
        let parent = this.findParentWithClass(event.event.srcElement, 'layoutContent')
        let isCloser = this.checkIfCloserToBoundary(event, parent, 'top')
        if(isCloser){
          let rowId = new Date().valueOf();
          let elements = [draggedWidget];
          layout[rowId] = {
            type: "elements",
            elements: elements
          }
          layout.list.unshift(rowId);
        } else {
          let rowId = new Date().valueOf();
          let elements = [draggedWidget];
          layout[rowId] = {
            type: "elements",
            elements: elements
          }
          layout.list.push(rowId);
        }
        this.deleteSelectedDragedWidget(selectedDragMap);
      }
    }

    // this.pageMeta.panels.forEach(panel => {
    //   if(panel.id == hoveredPanelId){
    //     // let draggedWidget = this.getSelectedDragedWidget(); //delete origin place
    //   }
    // })
    // if(hoveredPanelId){ // && !this.dropCreateToRow
    // }
    //if(this.myPageService.selectedDragMap && this.myPageService.widgetReadyForDrag){
      // else if( hoveredPanelId && this.dropCreateToRow){
      //   this.pageMeta.panels.forEach(panel => {
      //     if(panel.id == hoveredPanelId){
      //       let draggedWidget = this.getSelectedDragedWidget(); //
      //       let layout;
      //       if(hoveredLayout) layout = panel.layoutMap[hoveredLayout]
      //       else {
      //         let layoutId = panel.layoutMap.list[0];
      //         layout = panel.layoutMap[layoutId]
      //       }
      //       let rowId = new Date().valueOf();
      //       let elements = [draggedWidget];
      //       layout.list.unshift(rowId);
      //       layout[rowId] = {
      //           type: "elements",
      //           elements: elements
      //       }
      //       this.deleteSelectedDragedWidget();
      //     }
      //   })
      // }
    //}

    // when drag ends, check through all layouts and delete single empty row if any
    // this.panelMeta.layoutMap.list.forEach((colId, i) => {
    //   this.panelMeta.layoutMap[colId]?.list?.forEach(rowId => {
    //     if (this.panelMeta.layoutMap[colId][rowId]?.elements?.length == 0) {
    //       delete this.panelMeta.layoutMap[colId][rowId]
    //       this.panelMeta.layoutMap[colId].list.splice(this.panelMeta.layoutMap[colId].list.indexOf(rowId), 1)
    //     }
    //   });
    // });
    // this.myPageService.widgetReadyForDrag = false;
    this.myMetaService.userMadeChanges.next(true);
    this.myFormService.userMadeChanges.next(true)
    this.clearVisibilityFlagsExcept()

    this.myPageService.resetDragMap()
    this.myPageService.widgetReleaseFromDrag(event)

    this.newWidgetCreated.emit({
      widget: draggedWidget,
      panel: this.panelMeta
    })

    this.newPanelMeta.emit(this.panelMeta)
  }

  // getSelectedDragedWidget(){
  //   let result;
  //   let selectedLayout = this.myPageService.selectedDragMap.layoutId;
  //   let selectedRow = this.myPageService.selectedDragMap.rowId
  //   let selectedWidget = this.myPageService.selectedDragMap.widgetId;
  //   this.pageMeta.panels.forEach(panel => {
  //     if(panel.id == this.myPageService.selectedDragMap.panelId){
  //       let elements = panel.layoutMap[selectedLayout][selectedRow]['elements'];
  //       elements.forEach(element => {
  //         if(element.id == selectedWidget) {
  //           result = element;
  //         }
  //       });
  //     }
  //   });
  //   return result;
  // }

  deleteSelectedDragedWidget(selectedDragMap: any){
    let result;
    let selectedLayout = selectedDragMap.layoutId;
    let selectedRow = selectedDragMap.rowId
    let selectedWidget = selectedDragMap.widgetId;
    let selectedPanelId = selectedDragMap.panelId

    if(selectedWidget == '__NEW__') return  // no old container, nothing to delete

    // console.log("pageMeta", JSON.parse(JSON.stringify(this.pageMeta)))
    this.pageMeta.panels.forEach(panel => {
      if(panel.id == selectedPanelId){
        console.log("panel", JSON.parse(JSON.stringify(panel)))
        // console.log("layout", JSON.parse(JSON.stringify(panel.layoutMap)))
        // console.log("layoutId", selectedLayout)
        const index = panel.layoutMap[selectedLayout][selectedRow]['elements'].findIndex(item => item.id === selectedWidget);
        panel.layoutMap[selectedLayout][selectedRow]['elements'].splice(index, 1);

        //delete row
        if(panel.layoutMap[selectedLayout][selectedRow]['elements'].length == 0){
          const rowIndex = panel.layoutMap[selectedLayout]?.list.findIndex(item => item === selectedRow);
          panel.layoutMap[selectedLayout]['list'].splice(rowIndex, 1);
          delete panel.layoutMap[selectedLayout][selectedRow]
        }
      }
    });
    return result;
  }

  drop(event) {
    if(this.indicatePanelExit){
      this.moveWidgetInNewPanel(event.container.data[event.previousIndex])
      this.indicatePanelExit = false;
      this.yAxisLocked = true;
      return
    }
    this.indicatePanelExit = false;
    this.yAxisLocked = false;

    if (typeof event.item.data == 'string') {
      // console.log("could be widget creation", event.item.data)
      return
    }


    if(this.myPageService.hoveredPanelId && this.myPageService.hoveredPanelId == -1){
      this.moveWidgetInNewPanel(event.container.data[0])
    } else if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    // console.log("new panel meta", this.panelMeta)
    this.newPanelMeta.emit(this.panelMeta)
    //check for empty panel and call delete method
    // console.log("prev cont:", event.previousContainer.data.length == 0)
    // panelDelete()
  }

  moveWidgetInNewPanel(widget){
    // console.log("moveIntoNewPanel: widget to move", widget)
    // let updatedPageMeta: any = this.myPageService.createPanel(widget.type, this.pageMeta, this.panelMeta.id);
    // console.log("updatedPageMeta", updatedPageMeta)
    // this.metaService.update(this.currentPageMeta)

    // //push into observables
    // this.metaService.pageMeta.next(updatedPageMeta)
    // this.metaService.userMadeChanges.next(true);
    let hostPanel = new Panel(Date.now(), `panel_${this.pageMeta.panels.length + 1}`);
    hostPanel.widgets.push(widget)
    // console.log("new panel", hostPanel)

    let widgetIndex = this.panelMeta.widgets.findIndex(wid => wid.id == widget.id)
    this.panelMeta.widgets.splice(widgetIndex, 1)
    // console.log("updated widget meta", this.panelMeta)

    let currentIndex = this.pageMeta.panels.findIndex(panel => panel.id == this.panelMeta.id)
    this.pageMeta.panels.splice(currentIndex + 1, 0, hostPanel)
    // console.log("new pageMeta", this.pageMeta)
  }


  raiseNewWidgetMeta(metaReceived) {
    // console.log("[PANEL] raising new widget meta in panel", metaReceived)
    let matchedColId, matchedRowId, matchedElementIndex
    outerLoop: for (let i = 0; i < this.panelMeta?.layoutMap?.list?.length; i++) {
      let columnId = this.panelMeta.layoutMap.list[i]
      let column = this.panelMeta.layoutMap[columnId]
      // console.log("column", column)
      for (let j = 0; j < column?.list?.length; j++) {
        let rowId = column.list[j];
        let row = column[rowId]
        // console.log("row", row)
        for (let k = 0; k < row?.elements?.length; k++) {
          let element = row.elements[k];
          if(element?.id == metaReceived.id){
            matchedColId = columnId
            matchedRowId = rowId
            matchedElementIndex = k
            // console.log("matched", element)
            break outerLoop
          }
        }
      }
    }
    console.log("panelMeta updated", this.panelMeta)
    this.newPanelMeta.emit(this.panelMeta)
  }

  raiseOnExecuteAction(data){
    this.executeActionResponse.emit(data)
  }

  raiseDeleteWidget(widgetId, layoutId?, layoutRowId?) {
    // console.log("in panel:", widgetId)
    let deletionPayload = {
      panelId: this.panelMeta.id,
      widgetId: widgetId,
      layoutId: layoutId,
      layoutRowId: layoutRowId
    }
    this.widgetDeletion.emit(deletionPayload)
  }
  userInputHandler(event: any) {
    let userInput = event

    //append panel id in user input
    userInput.panelId = this.panelMeta.id

    // console.log("In panel component, UserInputReceived", userInput)
    this.userInputReceived.emit(userInput)
  }
  selectionChanged(event: any) {
    // console.log("selection received in panel component", event)
    this.raiseSelectionChanged.emit(event)
  }

  raiseExecuteAction(event: any) {
    console.log("execute action received in panel", event)
    this.onExecuteAction.emit(event)
  }

  raiseNewPanelMeta(meta) {
    console.log("raising new panel meta output", JSON.parse(JSON.stringify(meta)))
    this.panelMeta = meta
    this.newPanelMeta.emit(meta)
  }


  async openPanelSettings() {
    if(!this.myPageService.selectedPanel) this.onPanelSelection(null, this.panelMeta.id);
    // console.log('opening panel settings');
    let dialogRef: any;
    dialogRef = this.settingsDialog.open(PanelConfigurationComponent, {
      maxHeight: '750px',
      minWidth: '650px',
      data: this.panelMeta,
    })

    dialogRef.afterClosed().subscribe(data => {
      if (!data) {
        // console.log("panel settings closed unexpectedly")
      } else {
        console.log("panel settings finished", data)

        // updates from panel configuration are coming via observable
        // hence, do not need to catch the data that is coming back from dialog
      }
    })
  }

  clonePanel() {
    console.log("clone panel")
    let newPanel = JSON.parse(JSON.stringify(this.panelMeta));
    newPanel.id = Date.now();
    newPanel.name = ('panel_' + (this.pageMeta.panels.length + 1));

    let widgets = this.myPageService.getWidgetsFromPanel(newPanel)
    widgets.forEach((widget, i) => {
      widget.id =  new Date().setMilliseconds(new Date().getMilliseconds() + i).valueOf();
    });
    newPanel = this.myPageService.clonePanel(newPanel);

    let index;
    for(let i=0; i<this.pageMeta.panels.length; i++){
      if(this.pageMeta.panels[i].id == this.panelMeta.id){
        index = i
      }
    }
    this.pageMeta.panels.splice(index + 1, 0 , newPanel);


    // this.pageMeta.panels.push(newPanel);
    console.log(newPanel, this.pageMeta)
    this.snackBar.open('Panel is succesfully cloned', "", {duration: 3000})
  }

  getWidgetFlexWidth(widget){
    let flexWidth = widget.gridX * 8.3;
    if(widget.type == 'button' && window.innerWidth < 600) flexWidth = widget.gridX * 2 * 8.3
    if(widget.type == 'chart'){
      if(window.innerWidth <= 900 && window.innerWidth > 650){
        flexWidth = Math.min(12 * 8.3, widget.gridX * 2 * 8.3)
      }else if(window.innerWidth <= 650 && window.innerWidth > 400){
        flexWidth = Math.min(12 * 8.3, widget.gridX * 3 * 8.3)
      }else if(window.innerWidth <= 400){
        flexWidth = 12 * 8.3
      }
    }
    if(this.resizeService.resizingWidgetMeta.id == widget.id && this.resizeService.resizingWidgetMeta.gridX){
      flexWidth = this.resizeService.resizingWidgetMeta.gridX * 8.3
    }
    return Math.floor(flexWidth);
  }

  setBackgroundStyles() {
    console.log("panel", this.panelMeta)
    if(this.panelMeta.type == 'listpanel') return
    this.panelMeta.layoutMap?.list?.forEach((layoutId, i) => {
      const layout = this.panelMeta.layoutMap[layoutId];
      console.log("layout", layout)
      if (layout?.background?.image?.url) {
        this.backgroundStyles[layoutId] = {
          'background-image': 'url(' + layout.background.image.url + ')',
          'opacity': layout.background.opacity + "%" || "100%"
        }
      } else if (layout?.background?.color) {
        // return { 'background-color': layout.background.color };
        this.backgroundStyles[layoutId] = { 'background-color': layout.background.color };

      } else {
        // return { 'background-color': 'white' };
        this.backgroundStyles[layoutId] = { 'background-color': 'inherit' };
      }
      Object.assign(this.backgroundStyles[layoutId], {"background-repeat": 'no-repeat', "background-size": 'cover'})
    })

    console.log("background styles set", this.backgroundStyles)
  }


  /**
   * when a drag enters a widget or row or layout or panel
   * @param widget
   * @param row
   * @param layout
   * @param panelMeta
   */
  dragEntered(widget?: any, row?: any, layout?: any, panelMeta?: any){
    console.log("drag entered: wid => ", widget, "row", row, "layout", layout, "panel", panelMeta)
  }

  widgetDragStarted(event, widget, rowId, layoutId){
    console.log("-------------- WIDGET DRAG STARTED -------------------------")
    console.log("widgetMeta", widget, "row", rowId, "layout", layoutId, "panelMeta", this.panelMeta)
    console.log("widget drag started DragMap", JSON.parse(JSON.stringify(this.myPageService.selectedDragMap)))
    // this.isListDisabled = true
    this.myPageService.hoveredRow = rowId
    this.myPageService.hoveredLayout = layoutId
    this.myPageService.hoveredPanelId = this.panelMeta.id
    this.myPageService.widgetSelectedForDrag(event)
    // {
      // console.log("html elem", event.source?.element?.nativeElement)
      // this.renderer.setStyle(event.source?.element?.nativeElement, 'position', 'absolute');
      // console.log("this.panelMeta", this.panelMeta)

      // as soon as drag starts, check through all layouts in that panel; add an empty row if no row present
      // it creates a drop container array for material drag-drop to use
      // this.panelMeta.layoutMap.list.forEach((colId, i) => {
      //   console.log("colId", colId, "column data", JSON.parse(JSON.stringify(this.panelMeta.layoutMap[colId])))
      //   if (this.panelMeta.layoutMap[colId]?.list?.length == 0) {
      //     console.log("no rows")
      //     let rowId = new Date().valueOf();
      //     this.panelMeta.layoutMap[colId].list = [rowId];
      //     this.panelMeta.layoutMap[colId][rowId] = {
      //         type: "elements",
      //         elements: []
      //     }
      //     console.log("empty row added", JSON.parse(JSON.stringify(this.panelMeta.layoutMap[colId])), "in layout index", i)
      //   }
      // });
      // this.widgetService.widgetDragging.next()
    // }
    this.widgetService.widgetDragStart.next(widget)
  }

  // widgetDragEnded(event, widget){
  //   console.log("widget drag ended", event, "for", widget, this.panelMeta)
  //   // this.isListDisabled = false

  //   // console.log("html elem", event.source?.element?.nativeElement)
  //   // this.renderer.setStyle(event.source?.element?.nativeElement, 'position', 'absolute');
  //   this.widgetService.widgetDragEnd.next(widget)
  //   // this.myPageService.resetDragMap()
  //   console.log("calling from widgetDragEnded")
  //   this.clearVisibilityFlagsExcept()
  // }
}
