import { Panel } from "./basePanel";
import { Label } from "../widgetClasses/label";

export class SearchPanel extends Panel{

  headingExists: boolean = true
  heading: Label
  connectionId = ''
  boxName = ''
  boxId = ''
  boxObjectId = ''
  searchAttributes: string[] = []
  userSelectSearchAtrribute = false
  sortAttributes: string[] = []
  userSelectSortAtrribute = false
  pageSize = 20
  outputListPanelId = ''

  constructor(id: number, name: string){
    super(id, name)
    this.type = 'searchpanel'
  }
}
