<div class="menuPageWrap" id="menu_tool_id" fxLayout="column">
  <div *ngIf="pageCreationSpinner" class="spinner" fxLayoutAlign="center center">
    <mat-progress-bar color="warn" mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="newPageButton" matTooltip="New Page" fxLayout fxLayoutAlign="center center">
    <button [disabled]="pageCreationSpinner" class="newPageButtonCore" color="customColor" mat-fab (click)="newPage()">
      <mat-icon class="newPageButtonCoreIcon">add</mat-icon>
    </button>
  </div>

  <!---------------------------- THIS IS THE TREE NODE TEMPLATE FOR LEAF NODES ------------------------------->
  <mat-tree
    [class.blurred]="pageCreationSpinner"
    #tree [dataSource]="dataSource"
    [treeControl]="treeControl"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
  >
    <mat-tree-node
      #page
      *matTreeNodeDef="let node" matTreeNodePadding
      cdkDrag [cdkDragData]="node"
      (mouseenter)="turnOnVisibility(node)"
      (mouseleave)="turnOffVisibility(node)"
    >
      <mat-icon
        *ngIf="node.interfaceType === 'page'"
        class="dragIcon" style="cursor: move;"
        cdkDragHandle
        [style.visibility]="(pageEditViz && pageEditVizId === node.id) && (editingPageId !== node.id) ? 'visible' : 'hidden'"
      >
        drag_indicator
      </mat-icon>

      <!-- THIS BUTTON IS FOR PADDING, MAKING IT CONSISTENT WITH EXPANDABLE PAGE NODES -->
      <mat-icon *ngIf="node.interfaceType === 'page'" class="invisible padding">arrow_right</mat-icon>

      <!-- FOR PAGE IN VIEW MODE -->
      <ng-container *ngIf="editingPageId !== node.id && node.interfaceType === 'page'">
        <!-- <div>{{ node.code + (node.code == currentPageCode ? 'yes' : 'no') }}</div> -->
        <a class="pageLink" [ngClass]="{'activePage': node.code === currentPageCode}" (click)="click(node)" (dblclick)="doubleClick(node)">
          <span fxLayoutAlign="start center" fxLayoutGap=".5rem" [ngStyle]="{opacity: node.hidden ? '.5' : '1'}">
            <mat-icon class="icon">description</mat-icon>
            <span class="nodeName">{{node.name}}</span>
          </span>
        </a>
        <!-- [routerLink]="['/bloom/' + bloomCode + '/' + pageStructure[node.code].code]"
          [fragment]="'edit'" -->

        <button
          *ngIf="node.code==='homepage' else regularPageMenuTrigger"
          mat-icon-button [matMenuTriggerFor]="homePageMenu"
          [style.visibility]="pageEditViz && pageEditVizId === node.id ? 'visible' : 'hidden'"
        >
          <mat-icon class="icon">more_vert</mat-icon>
        </button>
        <ng-template #regularPageMenuTrigger>
          <button
            mat-icon-button [matMenuTriggerFor]="regularPageMenu"
            [style.visibility]="pageEditViz && pageEditVizId === node.id ? 'visible' : 'hidden'"
          >
            <mat-icon class="icon">more_vert</mat-icon>
          </button>
        </ng-template>

        <mat-menu #regularPageMenu class="builder-menu-pages">

          <button mat-menu-item matTooltip="Rename" (click)="turnOnEditMode(node)">
            <mat-icon>edit</mat-icon>Rename
          </button>
          <button mat-menu-item matTooltip="Clone" (click)="clonePage(node)">
            <mat-icon>content_copy</mat-icon>Clone
          </button>
          <button mat-menu-item [matTooltip]="node.hidden === true ? 'Show' : 'Hide'" (click)="togglePageView(node)">
            <div *ngIf="node.hidden === true"><mat-icon>visibility</mat-icon>Show</div>
            <div *ngIf="node.hidden === false"><mat-icon>visibility_off</mat-icon>Hide</div>
          </button>
          <button mat-menu-item matTooltip="Remove" (click)="deletePageConfirmation(node)">
            <mat-icon>delete</mat-icon>Remove
          </button>
        </mat-menu>

        <mat-menu #homePageMenu class="builder-menu-pages">
          <button mat-menu-item matTooltip="Rename" (click)="turnOnEditMode(node)">
            <mat-icon>edit</mat-icon>Rename
          </button>
          <button mat-menu-item matTooltip="Clone" (click)="clonePage(node)">
            <mat-icon>content_copy</mat-icon>Clone
          </button>
        </mat-menu>
      </ng-container>

      <!-- FOR PAGE IN EDIT MODE -->
      <div class="editMode" fxLayout="column" *ngIf="editingPageId === node.id && node.interfaceType === 'page'">
        <div>
          <mat-icon>description</mat-icon>
          <mat-form-field class="edit-name-form custom-form-field">
            <input #pageName matInput type="text" [(ngModel)]="editingPageName" (keyup.enter)="savePageName(node)">
          </mat-form-field>
        </div>
        <div style="height: .5rem;"></div>
        <div fxLayoutAlign="end center" fxLayoutGap="1rem">
          <button class="small-mat-button" matSuffix mat-stroked-button color="primary" *ngIf="editingPageName" (click)="savePageName(node);">
            <mat-icon class="zero-margin">done</mat-icon>
            Save
          </button>
          <button class="small-mat-button" matSuffix mat-stroked-button (click)="editingPageId = ''">
            <mat-icon class="icon-red-color zero-margin">close</mat-icon>
            Cancel
          </button>
        </div>
      </div>
    </mat-tree-node>


    <!-------------------------------- THIS IS THE TREE NODE TEMPLATE FOR EXPANDABLE NODES ------------------------------>
    <mat-tree-node #page cdkDrag [cdkDragDisabled]="node.interfaceType !== 'page'" [cdkDragData]="node" class="expandable"
      *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding
      (mouseenter)="node.interfaceType === 'page'? turnOnVisibility(node) : true"
      (mouseleave)="node.interfaceType === 'page'? turnOffVisibility(node): true">
      <!------------- DRAG HANDLE -------->
      <mat-icon cdkDragHandle class="dragIcon" [style.visibility]="pageEditViz && pageEditVizId === node.id ? 'visible' : 'hidden'"
        *ngIf="node.interfaceType === 'page'" style="cursor: move;">
        drag_indicator
      </mat-icon>

      <!------------ CHEVRON ------------->
      <mat-icon class="mat-icon-rtl-mirror" matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
        [ngStyle]="{'font-size': '20px',cursor: 'pointer'}" [inline]="true">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>

      <!----------- FOR PANEL NODES ---------->
      <!-- <div *ngIf="node.interfaceType == 'panel'" (click)="panelSelected(node.id)" fxLayoutAlign="start center"
        style="cursor: pointer;">
        <mat-icon style="margin-right: .5rem;">view_day</mat-icon>
        <span>{{node.name}}</span>
      </div> -->

      <!-- FOR PAGE IN VIEW MODE -->
      <ng-container *ngIf="editingPageId !== node.id && node.interfaceType === 'page'">
        <a class="pageLink" [ngClass]="{activePage: node.code === currentPageCode}" (click)="click(node)" (dblclick)="doubleClick(node)">
          <div fxLayoutAlign="start center" [ngStyle]="{opacity: node.hidden ? '.5' : '1'}">
            <mat-icon>description</mat-icon>
            <span>{{node.name}}</span>
          </div>
        </a>
        <!-- [routerLink]="['/bloom/' + bloomCode + '/' + pageStructure[node.code].code]"
        [fragment]="'edit'" -->

        <!-- <mat-icon *ngIf="node.interfaceType == 'page'"
          [style.visibility]="pageEditViz && pageEditVizId == node.id ? 'visible' : 'hidden'" class="editIcon"
          (click)="turnOnEditMode(node)">
          edit
        </mat-icon> -->
        <ng-container *ngIf="node.code==='homepage' else nonHomePageMenuTrigger">
          <button mat-icon-button [matMenuTriggerFor]="homePageMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </ng-container>
        <ng-template #nonHomePageMenuTrigger>
          <button mat-icon-button [matMenuTriggerFor]="pageShow">
            <mat-icon>more_vert</mat-icon>
          </button>
        </ng-template>

        <!-- menu for non homepage -->
        <mat-menu #pageShow class="builder-menu-pages">
          <button mat-menu-item matTooltip="Rename" (click)="turnOnEditMode(node)">
            <mat-icon>edit</mat-icon>Rename
          </button>
          <button mat-menu-item matTooltip="Clone" (click)="clonePage(node)">
            <mat-icon>content_copy</mat-icon>Clone
          </button>
          <button mat-menu-item [matTooltip]="node.hidden ? 'Show' : 'Hide'" (click)="togglePageView(node)">
            <ng-container *ngIf="node.hidden"><mat-icon>visibility</mat-icon>Show</ng-container>
            <ng-container *ngIf="!node.hidden"><mat-icon>visibility_off</mat-icon>Hide</ng-container>
          </button>
          <button mat-menu-item matTooltip="Remove" (click)="deletePageConfirmation(node)">
            <mat-icon>delete</mat-icon>Remove
          </button>
        </mat-menu>

        <!-- menu for homepage -->
        <mat-menu #homePageMenu class="builder-menu-pages">
          <button mat-menu-item matTooltip="Rename" (click)="turnOnEditMode(node)">
            <mat-icon>edit</mat-icon>Rename
          </button>
          <button mat-menu-item matTooltip="Clone" (click)="clonePage(node)">
            <mat-icon>content_copy</mat-icon>Clone
          </button>
        </mat-menu>
      </ng-container>

      <!-- FOR PAGE IN EDIT MODE -->
      <ng-container class="editMode" *ngIf="editingPageId === node.id && node.interfaceType === 'page'">
        <mat-icon>description</mat-icon>
        <mat-form-field class="form-field">
          <input #pageName matInput type="text" (keyup.enter)="savePageName(node)" [(ngModel)]="editingPageName">
          <button matSuffix mat-icon-button color="primary" *ngIf="editingPageName" (click)="savePageName(node);">
            <mat-icon class="zero-margin">done</mat-icon>
          </button>
          <button matSuffix mat-icon-button (click)="editingPageId = ''">
            <mat-icon class="icon-red-color zero-margin">close</mat-icon>
          </button>
        </mat-form-field>
      </ng-container>
    </mat-tree-node>
  </mat-tree>



</div>
