<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<mat-card appearance="outlined" style="margin-right: 10px; margin-left: 10px">
  <h2 fxLayoutAlign="center start">Role</h2>
  <div>
    <form [formGroup]="roleFormGroup" class="full-width" fxLayoutAlign="center stretch" fxLayout="column" flex="100"
      (ngSubmit)="isNewRole ? createRole() : updateRole()">
      <mat-form-field>
        <mat-label>Role Code</mat-label>
        <input matInput formControlName="code" required />
        <mat-error *ngIf="roleFormGroup.controls.code.errors?.required">
          Please Enter Role Code !!
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Role Name</mat-label>
        <input matInput formControlName="name" required />
        <mat-error *ngIf="roleFormGroup.controls.name.errors?.required">
          Please Enter Role Name !!
        </mat-error>
        <mat-error *ngIf="roleFormGroup.controls.name.errors?.minlength">
          Role Name must be at least 3 characters long !!
        </mat-error>
        <mat-error *ngIf="roleFormGroup.controls.name.errors?.maxlength">
          Role Name mustn't exceed 20 characters !!
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" required></textarea>
        <mat-error *ngIf="roleFormGroup.controls.description.errors?.required">
          Please Enter Description !!
        </mat-error>
      </mat-form-field>
      <mat-form-field  *ngIf="privileges">
        <mat-label>Privileges</mat-label>
        <mat-chip-grid #chipList aria-label="">
          <mat-chip-row *ngFor="let privilege of roleFormGroup.get('privileges').value"
          (removed)="removePrivilege(privilege)">
            {{privilege}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          <input
            placeholder="Add privilege"
            #fruitInput
            (focus)="setFilterOptions()"
            [matAutocomplete]="autoBoxEvent"
            [matChipInputFor]="chipList"
            >
        </mat-chip-grid>
        <mat-autocomplete 
          #autoBoxEvent="matAutocomplete" 
          [displayWith]="displayFunction"
          (optionSelected)="selectChange($event.option.value)">
          <mat-option *ngFor="let option of privilegeOptions | async" [value]="option">
            {{ option.code }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
     
      <div fxLayout="row" class="full-width" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button mat-stroked-button type="submit" color="primary"><span style="margin-top: 10px">Save</span>
        </button>
        <button mat-stroked-button type="button" (click)="backToRoleListing()"><span
            style="margin-top: 10px">Back</span>
        </button>
      </div>
    </form>
  </div>

</mat-card>
