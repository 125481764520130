
export let DATE_FORMAT: any = {
  availableFormats: [
    'MM-dd-YYYY',
    'MMM dd, YYYY',
    'MMMM d, y',
    'EEEE, MMMM dd, YYYY',
    'custom'
  ],
  'MM-dd-YYYY': { name: 'Short Date - MM-dd-YYYY', value: 'MM-dd-YYYY', default: true},
  'MMM dd, YYYY': { name: 'Medium Date - MMM dd, YYYY', value: 'MMM dd, YYYY'},
  'MMMM d, y': { name: 'Long Date - MMMM d, y', value: 'MMMM d, y'},
  'EEEE, MMMM dd, YYYY': { name: 'Full Date - EEEE, MMMM dd, YYYY', value: 'EEEE, MMMM dd, YYYY'},
  'custom': { name: 'Custom Date Format', value: 'custom' }
}
export let TIME_FORMAT: any = {
  availableFormats: [
    'HH:mm',
    'hh:mm a',
    'hh:mm:ss a',
    'h:mm:ss a zzzz',
    'custom'
  ],
  'HH:mm': { name: 'Short Time - HH:mm', value: 'HH:mm', default: true },
  'hh:mm a': { name: 'Medium Time - hh:mm a', value: 'hh:mm a' },
  'hh:mm:ss a': { name: 'Long Time - hh:mm:ss a', value: 'hh:mm:ss a' },
  'h:mm:ss a zzzz': { name: 'Full Time - h:mm:ss a zzzz', value: 'h:mm:ss a zzzz' },
  'custom': { name: 'Custom Time Format', value: "custom" }
}



