import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'mapping-drill-down',
  templateUrl: './mapping-drill-down.dialog.html',
  styleUrls: ['./mapping-drill-down.dialog.css'],
})
export class MappingDrillDownDialog {
  availableBoxTypes: string[] = ["string", "number", "date", "datetime", "boolean"];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

}
