import { Panel } from "./basePanel";

export class FormPanel extends Panel{

  connectionId = ''
  boxName = ''
  boxId = ''
  boxObjectId = ''
  formAttributes: any = []
  mode: string = 'update'  // create/update

  constructor(id: number, name: string){
    super(id, name)
    this.type = 'formpanel'
  }
}
