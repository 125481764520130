import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ConnectionDialogComponent } from './connection.dialog';


@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule
  ],
  declarations: [
    ConnectionDialogComponent
  ],
  exports: [
    ConnectionDialogComponent
  ]
})
export class ConnectionDialogModule { }
