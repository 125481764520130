import { Widget } from "./baseWidget";

export class Separator extends Widget{
  type = 'separator';
  gridX = 12
  minGridX = 3
  config = {
    props: []
  }
}
