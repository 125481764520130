<div class="wrapper" fxLayout="column" (mouseover)="mouseover()" (mouseout)="mouseout()">
  <div style="position: relative" [ngClass]="{ 'blurred': pageService.footerInFocus.value }">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <div *ngIf="pageService.footerInFocus.value" class="overlay" (click)="defocusFooter()"></div>
  </div>
  

  <div class="footer-handle-wrapper" *ngIf="builderMode && showFooter">
    <div class="footer-handle" (click)="configureFooter($event)" fxLayoutAlign="space-between center">
      <div class="divider" fxFlex="35"></div>
      <div fxFlex="25" fxLayoutAlign="center center">
        <button mat-flat-button color="basic" class="thin-button" fxFlex="100">
          <mat-icon>add_box</mat-icon>
          {{ pageService.footerInFocus.value ? "Save footer" : "Edit footer" }}
        </button>
      </div>
      <div class="divider" fxFlex="35"></div>
    </div>
  </div>

  <div class="footer-wrapper" *ngIf="showFooter" [ngClass]="{ 'blurred': !pageService.footerInFocus.value }">
    <app-page [footerConfig]="{ 'isFooterPage': true, 'changeContext': pageService.footerInFocus.value }" [_builderMode]="true"></app-page>
    <div *ngIf="!pageService.footerInFocus.value" class="overlay" (click)="configureFooter($event)"></div>
  </div>
</div>
