export const PieChartConfig: any = {
  dataSource: {
    dimensions: [
      {
        attribute: {},
        drillDownPath: '',
      }
    ],
    sort: {
      sortEnabled: false,
      sortAttributes: []
    },
  },
  otherProps: {
    props: ['title'],   // 'legendPosition'
    // legendPosition: {
    //   displayName: "Position of legend",
    //   type: "select",
    //   value: "bottom",
    //   availableOptions: ["top", "right", "bottom", "left"]
    // },
    title: {
      displayName: "Pie chart title",
      type: "text",
      value: "",
    },
    precision: {
      displayName: "Decimal point precision",
      type: "text",
      value: 2,
    }
  },
}
