<!-- <spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner> -->

  <mat-dialog-content>
    <div fxLayout="row" style="margin-top: 10px;"  fxLayoutAlign="center center" >
      <!-- <div fxLayout="column" fxFlex="40">
          code (ngModelChange)="onChangeInput($event)"
      </div> -->
      <div fxLayout="column" class="bloom-field" fxFlex="80">
          <mat-form-field appearance="outline" style="width: 80%;">
              <mat-label> Section name </mat-label>
              <input
              id="name" name="name" required  matInput placeholder="Section name" [(ngModel)]="nameMap.name">
          </mat-form-field>
      </div>
      <mat-spinner  [diameter]="20" *ngIf="spinner"></mat-spinner>
      <div fxLayout="column" fxFlex="20">
      </div>
  </div>
  </mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="center center" style="padding-bottom: 10px;">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div fxLayout="column" fxFlex="30">
                </div>

                <div fxLayout="column" fxFlex="30">
                    <button mat-stroked-button  [mat-dialog-close]="false" color="primary"  type="button">
                        Cancel
                      </button>
                </div>
                <div fxFlex="2"></div>
                <!-- isNameExists ||  -->
                <div fxLayout="column" fxFlex="30">
                    <button mat-flat-button (click)="createForm()" [disabled]="spinner"  color="primary" type="button">
                      {{dataInput?.buttonText ? dataInput.buttonText : 'Create'}}
                      </button>
                </div>

           </div>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>



