import { Widget } from "./baseWidget";
import { AvailableOptions } from "./optionsConfig";

export class Autocomplete extends Widget {
  type = 'autocomplete';
  gridX = 6
  minGridX = 3
  value: any = '';

  config = {
    props: [
      "label",
      "placeholder",
      "availableOptions",
      'tooltip',
      'value',
      'hidden',
      'required',
      'viewOnly',
      'appearance'
    ],
    label: {
      displayName: "Autocomplete Label",
      value: 'default autocomplete label',
      type: 'text'
    },
    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
    placeholder: {
      displayName: "Autocomplete Placeholder",
      value: 'pick one',
      type: 'text'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: this.type,
      type: 'text',
      enabled: true
    },
    value: {
      displayName: 'Value',
      value: "",
      type: 'text'
    },
    required: {
      displayName: 'Required',
      type: 'boolean',
      value: false,
    },
    hidden: {
      displayName: 'Hidden',
      type: 'boolean',
      value: false,
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not change the value',
      value: false,
      type: 'boolean',
      resetValue: false
    },
    availableOptions: AvailableOptions,
  }

  // textFormat = Object.assign({}, textFormatting);

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
  }

  public getEvents(){
    let events = [
      {
        id: "select",
        name: "Select",
        function: "select"
      },
      {
        id: "hover",
        name: "Hover",
        function: "hover"
      }
    ]

    return events;
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    return map;
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  /**
   * to indicate this widget is a selectable widget with options
   */
  public isOptionBasedWidget(){
    return false
    // return true
  }
}
