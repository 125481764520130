import { environment } from 'src/environments/environment';
import { URLManager } from './urlList/URLManager';
import { BoxCache } from './BoxCache';
import { RouterUtility } from './urlList/RouterUtility';

export class BoxInstance {


  static async getInstance(endpoint: any) {
    // console.log("[GET-INSTANCE] endpoint", endpoint)
    endpoint = "/box" + endpoint;
    //here is a middleware to return the absolure url
    if(BoxInstance.isAbsoluteUrl(endpoint)){
      return endpoint;
    }

    const match = endpoint.match(/\/box\/([^/]+)/);
    const boxId = match && match[1];
    // console.log("[GET-INSTANCE] match", match);
    // console.log("[GET-INSTANCE] boxId", boxId);
    let result;
    let env = environment.env;
    let category = BoxInstance.getCategory(boxId);
    let baseUrls = [];
    // console.log("[GET-INSTANCE] category", category);

    let boxUrls = URLManager.getRouter('box').getBaseUrls(env, boxId);
    // console.log("[GET-INSTANCE] final boxUrls", JSON.stringify(boxUrls));

    let categoryUrls = [];
    if(boxUrls.length == 0){
      categoryUrls = URLManager.getRouter('category').getBaseUrls(env, category);
      // console.log("[GET-INSTANCE] final categoryUrls", JSON.stringify(categoryUrls));
    }

    baseUrls = [...baseUrls, ...categoryUrls, ...boxUrls];

    // console.log("[GET-INSTANCE] final baseUrls", JSON.stringify(baseUrls));

    if(baseUrls.length == 0){
      baseUrls = RouterUtility.getBaseUrls(env, null)
      // console.log(`[GET-INSTANCE] [GETBASEURLS] fallback filteredUrls: ${baseUrls}`);
    }

    result = baseUrls[0];
    // console.log(`[GET-INSTANCE] [FINAL-URL] result: ${result}`);
    return result;
  }

  static async getInstances(endpoint: any) {
    // console.log("[GET-INSTANCE] endpoint", endpoint)
    const match = endpoint.match(/\/box\/([^/]+)/);
    const boxId = match && match[1];
    // console.log("[GET-INSTANCE] boxId", boxId);

    let env = environment.env;
    let category = BoxInstance.getCategory(boxId);
    // console.log("[GET-INSTANCE] category", category);
    let baseUrls = URLManager.getRouter('category').getBaseUrls(env, category);
    // console.log("[GET-INSTANCE] final baseUrls", JSON.stringify(baseUrls));
    return baseUrls;
  }

  static getCategory(box: any) {
    let category = null;
    let cacheBoxMap: any = BoxCache.getCacheMap();

    if (!cacheBoxMap) cacheBoxMap = {};
    let boxMap = cacheBoxMap[box] || {};
    if (boxMap?.categories?.length) category = boxMap?.categories[0] || null;
    return category;
  }

  static isAbsoluteUrl(url: string): boolean {
    const pattern = /^(?:\w+:)?\/\//; // Matches protocol part of the URL
    return pattern.test(url);
  }
}
