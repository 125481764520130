
<mat-spinner
  *ngIf="spinner"
  color="warn"
  class="centerOfAll"
  [diameter]="50"
  fxLayoutAlign="center center"
></mat-spinner>
<div class="cover">
  <mat-dialog-content class="mat-typography">
    <div class="header" fxLayout="row" fxLayoutAlign="space-between start">
      <div mat-dialog-title>Panel settings</div>
      <button #closeButton mat-button mat-dialog-close style="cursor: pointer;">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div>
      <div class="heading">Layout</div>
      <div style="padding-left: 5%">
        <mat-radio-group [value]="panelMeta.layoutCount"  (change)="onLayoutChange($event)" aria-label="Layout">
          Column: &nbsp;
          <mat-radio-button value="1"> <div fxLayoutAlign="center center">
            <mat-icon>crop_5_4</mat-icon> One</div></mat-radio-button>  &nbsp;
          <mat-radio-button value="2"> <div fxLayoutAlign="center center">
            <mat-icon fontIcon="splitscreen" style="transform: rotate(90deg);"></mat-icon> Two </div></mat-radio-button> &nbsp;
          <mat-radio-button value="3"> <div fxLayoutAlign="center center">
            <mat-icon  fontIcon="view_week" class="material-symbols-outlined"></mat-icon> Three </div></mat-radio-button> &nbsp;
          <mat-radio-button value="4"> <div fxLayoutAlign="center center">
            <mat-icon>calendar_view_week</mat-icon> Four </div></mat-radio-button>
        </mat-radio-group>
  
        <div style="height: 1rem"></div>
        <div class="sub-heading">Column appearances</div>
        <div style="height: .5rem;"></div>
        <div fxLayout="row" fxLayoutGap="1rem" style="padding-left: 4rem;">
          <div *ngFor="let layoutId of panelMeta.layoutMap?.list; let i = index" style="margin-bottom: .5rem">
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1rem">
              <!-- <div>Column {{ i + 1 }}</div> -->
              <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu" style="width: 8rem;">
                <div fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                  <span class="selectedWidget">{{ panelMeta.layoutMap[layoutId]?.appearance || "None"}}</span>
                  <mat-icon style="opacity: 60%;">arrow_drop_down</mat-icon>
                </div>
              </button>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="setAppearance(layoutId, 'card')">Card View</button>
              <button mat-menu-item (click)="setAppearance(layoutId, 'outline')">Outlined</button>
              <button mat-menu-item (click)="setAppearance(layoutId, 'none')">None</button>
            </mat-menu>
          </div>
        </div>
  
        <div style="height: 1rem"></div>
        <div class="sub-heading">Column backgrounds</div>
        <div style="height: .5rem;"></div>
        <!-- click on below trigger will toggle the visibility of bg config for layout of index i -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3rem" style="padding-left: 4rem;">
          <div *ngFor="let layoutId of panelMeta.layoutMap?.list; let i = index" style="width: fit-content" fxLayout="column">
            <ng-template *ngTemplateOutlet="bgTrigger; context: { layoutIndex: i, colorVal: panelMeta['layoutMap']?.[panelMeta['layoutMap']['list'][i]]?.background?.color, uriVal: panelMeta['layoutMap']?.[panelMeta['layoutMap']['list'][i]]?.background?.image?.url }"></ng-template>
          </div>
        </div>
  
        <div *ngIf="layoutImgBgConfigOpenFor >= 0" style="margin-left: 2rem;">
          <div style="height: 1rem"></div>
          <div style="color: #666">{{ "Background image for column " + (layoutImgBgConfigOpenFor + 1) }}</div>
          <ng-template *ngTemplateOutlet="imgBgConfig; context: { isLayout: true, oldVal: panelMeta['layoutMap']?.[panelMeta['layoutMap']['list'][layoutImgBgConfigOpenFor]]?.background?.image?.url }"></ng-template>
        </div>
  
  
        <div style="height: 1rem"></div>
        <div class="sub-heading">Background opacity</div>
        <div style="height: .5rem;"></div>
        <!-- click on below trigger will toggle the visibility of bg config for layout of index i -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3rem" style="padding-left: 4rem;">
          <div *ngFor="let layoutId of panelMeta.layoutMap?.list; let i = index" style="width: fit-content" fxLayout="column">
            <mat-slider
              class="slider"
              [max]="100"
              [min]="0"
              [step]="1"
              [discrete]="true"
              [showTickMarks]="true"
              color="primary"
            >
            <input matSliderThumb [ngModel]="panelMeta['layoutMap']?.[panelMeta['layoutMap']['list'][i]]?.background?.opacity || 100" (valueChange)="updateOpacity($event, i)" #slider>
          </mat-slider>
          </div>
        </div>
      </div>
    </div>

    <div style="height: 2.5rem"></div>

    <div fxLayoutAlign="start start">
      <div fxFlex="50">
        <div class="heading">Alignment</div>
        <app-alignment-settings [alignment]="panelMeta.alignment" (newAlignment)="alignmentChanged($event)"></app-alignment-settings>
        <div style="height: 2.5rem"></div>
      </div>

      <div fxFlex="50">
        <div class="heading">Panel background</div>
        <div fxLayout="column">
          <div style="height: .5rem"></div>
          <ng-container *ngTemplateOutlet="bgTrigger; context: { layoutIndex: null, colorVal: panelMeta?.background?.color, uriVal: background?.image?.url || '' }"></ng-container>
          <div style="height: .5rem"></div>
          <div *ngIf="panelImgBgConfigOpen" class="bg-config-block" fxLayout="row" fxLayoutGap="1rem">
            <ng-container *ngTemplateOutlet="imgBgConfig; context: { isLayout: false, oldVal: background.image.url }"></ng-container>
          </div>
        </div>
      </div>
    </div>

    <div style="height: 1rem"></div>
    <div fxLayout="column">
      <div class="heading">
        Panel Margin
      </div>
      <div fxLayout fxLayoutAlign="space-around center">
        <div fxLayoutAlign="center center" fxFlex="15">{{ "Left " + margin.left + "%" }}</div>
        <mat-slider 
          fxFlex="70"
          class="slider hide-start-thumb hide-end-thumb"
          color="primary"
          [displayWith]="displayThumbText.bind(this)"
          [step]="1"
          [showTickMarks]="true"
          [discrete]="true"
          [max]="100"
          [min]="0"
        >
          <input [ngModel]="margin.left || 0" matSliderStartThumb (dragStart)="dragStart($event, 'left')" (valueChange)="marginChange($event, 'left')">
          <input [ngModel]="margin.right || 100" matSliderEndThumb (dragStart)="dragStart($event, 'right')" (valueChange)="marginChange($event, 'right')">
        </mat-slider>
        <div fxLayoutAlign="center center" fxFlex="15">{{ "Right " + (margin.right ? (100 - (margin.right || 0)) : 0) + "%" }}</div>
      </div>
    </div>

    <!-- this block is trigger for opening image background config -->
    <ng-template #bgTrigger let-layoutIndex="layoutIndex" let-colorVal="colorVal" let-uriVal="uriVal">
      <div class="bg-trigger" fxLayout="row" fxLayoutGap=".5rem">
        <div appearance="standard" fxLayoutAlign="center center" fxLayoutGap=".3rem" matTooltip="Solid color" class="colorInputWrap">
          <mat-icon class="formatIcon" fontIcon="format_color_fill"></mat-icon>
          <div
            #bgColorInput class="colorInput" name="bgColor"
            ngx-colors-trigger
            (input)="colorChanged($event, layoutIndex)"
            [style.background-color]="colorVal || 'transparent'"
            [ngModel]="colorVal"
            [colorsAnimation]="'popup'"
            [palette]="pageService.recentlyUsedColorPalette"
          ></div>
          <!-- <span>
            <input #colorInput class="colorInput" matInput type="color" name="color" [value]="colorVal"
              (input)="colorChanged($event.target, layoutIndex)">
          </span> -->
        </div>
        <div (click)="toggleBgImgConfig(layoutIndex)" fxLayout="row" fxLayoutGap=".3rem" fxLayoutAlign="center center" class="colorInputWrap">
          <mat-icon class="formatIcon" fontIcon="imagesmode" matTooltip="Background image"></mat-icon>
          <mat-icon *ngIf="uriVal?.length" style="font-size: .9rem; color: green;" fxLayoutAlign="center center" fontIcon="done"></mat-icon>
        </div>
        <div (click)="resetBackground(layoutIndex)" fxLayout="row" fxLayoutGap=".3rem" fxLayoutAlign="center center" class="colorInputWrap" style="border: none;">
          <mat-icon class="formatIcon" fontIcon="close" matTooltip="Clear background"></mat-icon>
        </div>
        <div ></div>
      </div>
    </ng-template>
  </mat-dialog-content>


  <!-- this is actual image background config; can be used for individual layout bg or panel bg as a whole -->
  <ng-template #imgBgConfig let-isLayout="isLayout" let-oldVal="oldVal">
    <div fxFlexFill fxLayout="column" fxLayoutAlign="start center" style="padding: .5rem; border: 1px solid gainsboro">
      <div style="height: .7rem"></div>
      <mat-form-field appearance="outline" class="edit-name-form" style="width: 100%">
        <mat-label>Image URI</mat-label>
        <input matInput #urlValue [ngModel]="oldVal" (click)="cursorPlaced($event)" (change)="backgroundImageChanged($event.target, isLayout ? layoutImgBgConfigOpenFor : null)">
        <button *ngIf="oldVal?.length" matSuffix mat-icon-button aria-label="Clear" (click)="backgroundImageChanged({ 'target': { 'value': '' } }, isLayout ? layoutImgBgConfigOpenFor : null)"><mat-icon>close</mat-icon></button>
        <app-field-picker
          matSuffix
          class="fieldPicker"
          [inputFieldMap]="pageReferenceMap"
          (selectedFields)="templateValueSelected($event, urlValue.value, isLayout ? layoutImgBgConfigOpenFor : null)"
        ></app-field-picker>
      </mat-form-field>

      <!-- <div fxLayoutAlign="start center" fxLayoutGap=".5rem">
        <span style="width: 100%; text-align: center; margin: .5rem 0">Choose from this page</span>
        <mat-icon>arrow_right</mat-icon>
        <app-field-picker
          class="fieldPicker"
          [inputFieldMap]="pageReferenceMap"
          (selectedFields)="backgroundImageChanged($event, isLayout ? layoutImgBgConfigOpenFor : null)"
        ></app-field-picker>
      </div> -->

      <span style="width: 100%; text-align: center">Or</span>

      <dropzone type="button" [displayText]="'Upload image'" [noPreview]="true" (fileChange)="imageSelectionChange($event, isLayout ? layoutImgBgConfigOpenFor : null)"></dropzone>
    </div>
  </ng-template>

  <!---------------------------- SAVE CONFIG BUTTON ----------------------------------->
  <mat-dialog-actions fxLayout fxLayoutAlign="center center" fxFlexFill>
    <button
      fxLayout
      fxLayoutAlign="center center"
      mat-raised-button
      color="primary"
      (click)="saveConfig()"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
