import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';

import { ContextMenuComponent } from '../widget-configuration/context-menu/context-menu.component';
import { WidgetConfigurationComponent } from '../widget-configuration/widget-configuration/widget-configuration.component';
import { WidgetDisplayConfigComponent } from '../widget-configuration/widget-display-config/widget-display-config.component';
import { WidgetDataConfigComponent } from '../widget-configuration/widget-data-config/widget-data-config.component';
import { WidgetActionConfigComponent } from '../widget-configuration/widget-action-config/widget-action-config.component';
import { ConfigureSourceMapComponent } from '../widget-configuration/configure-source-map/configure-source-map.component';
import { ConfigForSelectionWidgetsComponent } from '../widget-configuration/config-for-selection-widgets/config-for-selection-widgets.component';

import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { BoxConfigInputsModule } from '../box-config-inputs/box-config-inputs.module';
import { GoogleiconPickerModule } from 'src/app/shared/googleicon-picker/googleicon-picker.module';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { ActionSelectionModule } from 'src/app/shared/action-selection/action-selection.module';
import { AppSelectionModule } from 'src/app/shared/app-selection/app-selection.module';
import { ConnectionSelectionModule } from 'src/app/shared/connection-selection/connection-selection.module';
import { AppsPopupModule } from 'src/app/shared/apps-popup/apps.popup.module';
import { ApplicationComponent } from '../models/Action/config/application/application.component';
import { NavigationComponent } from '../models/Action/config/navigation/navigation.component';
import { WidgetActionComponent } from '../models/Action/config/widget-action/widget-action.component';
import { MappingSetupModule } from 'src/app/shared/mapping-setup/mapping-setup.module';
import { SecurityConfigModule } from 'src/app/shared/security/config/security-config/security-config.module';
import { BuiltInExpressionModule } from 'src/app/shared/built-in-expression/Built-in-expression.module';
import { DragnDropFileModule } from '../../shared/dropfile/dragndropfile.module';
import { ParameterInputsModule } from 'src/app/shared/parameter-inputs/parameter-inputs.module';
import { ChartDataConfigModule } from './chart-data-config/chart-data-config.module';
import { DateTimeFormatConfigModule } from 'src/app/shared/date-time-format-config/date-time-format-config.module';
import { WidgetDataSourceBoxConfigModule } from 'src/app/shared/widget-data-source-box-config/widget-data-source-box-config.module';
import { BaseSelectionModule } from 'src/app/shared/base-selection/base-selection.module';
import { autocompleteModule } from 'src/app/shared/mat-autocomplete/mat-autocomplete.module';
import { AppFilterConfigModule } from 'src/app/shared/app-filter-config/app-filter-config.module';
import { FlowActionComponent } from '../models/Action/config/flow/flow.component';
import { FieldPickerModule } from 'src/app/shared/field-picker/field-picker.module';
import { FilterConfigModule } from 'src/app/shared/query-components/filter-config/filter-config.module';
import { ActionConditionComponent } from './widget-action-config/action-condition-popup/action-condition-popup.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxColorsModule } from 'ngx-colors';
import { ButtonTypeSelectionComponent } from './button-type-selection/button-type-selection.component';
import { FilterComponentModule } from 'src/app/shared/query-components/filter-component/filter-component.module';


@NgModule({
  declarations: [
    ContextMenuComponent,
    WidgetConfigurationComponent,
    WidgetActionConfigComponent,
    WidgetDataConfigComponent,
    WidgetDisplayConfigComponent,
    ConfigureSourceMapComponent,
    ConfigForSelectionWidgetsComponent,
    NavigationComponent,
    ApplicationComponent,
    WidgetActionComponent,
    FlowActionComponent,
    ActionConditionComponent,
    ButtonTypeSelectionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SpinnerModule,
    BoxConfigInputsModule,
    GoogleiconPickerModule,
    CommonComponentsModule,
    AppSelectionModule,
    ActionSelectionModule,
    ConnectionSelectionModule,
    AppsPopupModule,
    CommonComponentsModule,
    MappingSetupModule,
    FilterComponentModule,
    SecurityConfigModule,
    BuiltInExpressionModule,
    DragnDropFileModule,
    ParameterInputsModule,
    ChartDataConfigModule,
    DateTimeFormatConfigModule,
    WidgetDataSourceBoxConfigModule,
    BaseSelectionModule,
    autocompleteModule,
    AppFilterConfigModule,
    FieldPickerModule,
    FilterConfigModule,
    MatProgressSpinnerModule,
    NgxColorsModule,
  ],
  exports: [
    ContextMenuComponent,
    WidgetConfigurationComponent,
    WidgetActionConfigComponent,
    WidgetDataConfigComponent,
    WidgetDisplayConfigComponent,
    ConfigureSourceMapComponent,
    ApplicationComponent
  ]
})
export class WidgetConfigurationModule { }
