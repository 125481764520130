import { Injectable } from '@angular/core';
import convert from 'convert-units';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FlowContentService {
  constructor() {}

  triggers: any = [
    {
      id: "schedule",
      title: "Schedule",
      icon: "schedule"
    },
    {
      id: "app_event",
      title: "App Event",
      icon: "event"
    },
    {
      id: "webhook",
      title: "Webhook",
      icon: "webhook"
    }
  ]


  systemFields: any = [
    {
        __id: "systemField.lastExecutionTime",
        name: "Last execution datetime",
        dataType: "string",
        value: ""
    },
    {
      __id: "systemField.currentTime",
      name: "Current datetime",
      dataType: "string",
      value: ""
  }
  ]

  formatterMap: any = {
    list: ['datetime'],
    datetime: {
      name: 'Datetime',
      __id: 'datetime',
      icon: 'calendar_month',
      parameterInput: true,
      input: {
        list: ["datetime", "format"],
        datetime: {
          __id: "datetime",
          name: "Datetime",
          datatype: "datetime"
        },
        format: {
          __id: "format",
          name: "Format",
        }
      },
      output: {
        list: ["datetime"],
        datetime: {
          __id: "datetime",
          name: "Datetime",
          dataType: "string",
        }
      }
    },
  };


  aggregatorMap: any = {
    list: ['sum', 'min', 'max'],
    sum: {
      name: 'Sum',
      __id: 'sum',
      icon: 'ac_unit',
      input: {
        list: ["numbers"],
        numbers: {
          __id: "numbers",
          name: "Numbers",
          datatype: "string",
          widget: "input"
        }
      },
      output: {
        list: ["aggregated_value"],
        aggregated_value: {
          __id: "aggregated_value",
          name: "Aggregated value",
          dataType: "string",
        }
      }
    },
    min: {
      name: 'min',
      __id: 'min',
      icon: 'ac_unit',
      input: {
        list: ["numbers"],
        numbers: {
          __id: "numbers",
          name: "Numbers",
          datatype: "string",
          widget: "input"
        }
      },
      output: {
        list: ["aggregated_value"],
        aggregated_value: {
          __id: "aggregated_value",
          name: "Aggregated value",
          dataType: "string",
        }
      }
    },
    max: {
      name: 'Max',
      __id: 'max',
      icon: 'ac_unit',
      input: {
        list: ["numbers"],
        numbers: {
          __id: "numbers",
          name: "Numbers",
          datatype: "string",
          widget: "input"
        }
      },
      output: {
        list: ["aggregated_value"],
        aggregated_value: {
          __id: "aggregated_value",
          name: "Aggregated value",
          dataType: "string",
        }
      }
    },
  }

  convertorMap: any = {
    list: ['unit'],
    unit: {
      name: 'Unit',
      __id: 'unit',
      icon: 'ac_unit',
      input: {
        list: ["expression", "value", "from", "to"],
        expression: {
          __id: "expression",
          name: "Expression",
          datatype: "string",
          widget: "select",
          options: this.getMeasurementTypes()
        },
        value: {
          __id: "value",
          name: "Value",
          datatype: "string",
          widget: "input"
        },
        from: {
          __id: "from",
          name: "From",
          datatype: "string",
          requiredParams: ["expression"],
          param: "expression", //todo - improve the meta
          widget: "select",
          options: [],
          optionFun: this.getMeasurementTypes
        },
        to: {
          __id: "to",
          name: "To",
          datatype: "string",
          requiredParams: ["expression"],
          param: "expression", //todo - improve the meta
          widget: "select",
          options: [],
          optionFun: this.getMeasurementTypes
        }
      },
      output: {
        list: ["converted_value"],
        converted_value: {
          __id: "converted_value",
          name: "Converted value",
          dataType: "string",
        }
      }
    },
  };


  processorTypes: any = {
    list: ['formatter', 'converter', 'aggregator'],
    formatter: {
      name: 'Formatter',
      __id: 'formatter',
      icon: 'transform',
      map: this.formatterMap
    },
    converter: {
      name: 'Converter',
      __id: 'converter',
      icon: 'convert_to_text',
      map: this.convertorMap
    },
    aggregator: {
      name: 'Aggregator',
      __id: 'aggregator',
      icon: 'convert_to_text',
      map: this.aggregatorMap
    }
  };


  getMeasurementTypes(type?: any): any[] {
    let array: any[] = [];
    let list;
    if(type) list = convert().possibilities(type);
    else list = convert().measures();
    list.forEach(element => {
      array.push({
        __id: element,
        name: toTitleCase(element)
      })
    });;
    return array;
  }

  createSearchMeta(){
    return {
      searchAttributes: [
        {
          "dataType": "string",
          "filterable": true,
          "isColumnSelected": true,
          "name": "Search by name",
          "sortable": true,
          "widgetType": "input",
          "__id": "name"
        }
      ],
      pageSize: 10,
      hideTitle: true,
      searchButtonText: "Search",
      alignment: "flex-start",
      baseId: "",
      boxObjectId: "flow",
      name: "searchpanel_1",
      outputListPanelId: "flow-templates-listing",
      type: "searchpanel",
    }
  }

  getTemplatesPanel(){
    let result =
      {
        "type": "listpanel",
        "alignment": "flex-start",
        "layoutCount": "1",
        "widgets": [],
        "id": "flow-templates-listing",
        "name": "listPanel_1",
        boxId: 'starch',
        boxObjectId: 'flow',
        connectionId: "",
        baseMap: {
          "box_id": "mongodb",
          "base_id": ""
        } ,
        "listAttributes": [
          {
            "__id": "name",
            "name": "name",
            "dataType": "string",
            "filterable": true,
            "sortable": true,
            "fieldType": "attribute",
            "widgetType": "label",
            "isColumnSelected": true
          },
          {
            "__id": "template_data",
            "name": "Template Data",
            "dataType": "object",
            "filterable": false,
            "sortable": false,
            "fieldType": "attribute",
            "widgetType": "label",
            "isColumnSelected": true,
            "isDrillDown": true,
            "nestedProperties": [
              {
                "path": "name",
                "widgetType": "label",
                "fieldType": "attribute"
              },
              {
                "path": "bannerImage",
                "widgetType": "image",
                "fieldType": "attribute"
              },
              {
                "path": "tagline",
                "widgetType": "label",
                "fieldType": "attribute"
              },
              {
                "path": "description",
                "widgetType": "label",
                "fieldType": "attribute"
              }
            ]
          }
        ],
        "aditionalAttributesAllowed": false,
        "pageSize": 20,
        "loadInitialData": true,
        "dataSource": "app",
        "paginationEnabled": true,
        "paginationType": "pagebypage",
        "defaultListSize": 10,
        "noDataMessage": "There is no data to be shown here",
        "navigationSettings": {
          "enabled": true,
          "navFilterAttributes": [],
          "pageCode": "",
          "tooltip": "",
          "type": ""
        },
        "boxConfigToken": environment.DB_BOX_TOKEN,
        "attributeOptions": [],
        "getFnOptions": [],
        "listPanelTitle": "",
        hideTitle: true,
        "viewTypes": {
          "views": [
            "table",
            "card",
            "board"
          ],
          "defaultView": "card",
          "boardStatusColumn": "",
          "userCanChoose": false,
          "table": {
            "name": "table",
            "displayName": "Table",
            "userCanChoose": true,
            "icon": "list"
          },
          "card": {
            "name": "card",
            "displayName": "Card",
            "userCanChoose": true,
            "icon": "grid_on"
          },
          "board": {
            "name": "board",
            "displayName": "Board",
            "userCanChoose": false,
            "icon": "view_kanban"
          }
        },
        "primaryAttribute": {
          "__id": "_id",
          "name": "_id",
          "dataType": "string",
          "filterable": true,
          "sortable": true,
          "primary": true,
          "writable": false,
          "fieldType": "attribute",
          "widgetType": "label",
          "isColumnSelected": false
        },
        "filter": {
          "filterEnabled": true,
          "filterItems": [
            {
              "attribute": "template_status",
              "operator": "=",
              "value": "published",
              "dataType": "string",
              "filterType": "static_filter"
            }
          ]
        },
        "sort": {
          "sortEnabled": false,
          "sortAttributes": []
        },
        "listWidgetSet": {
          "templateDataU+FF0Ename": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "1721634761824762e067",
            "name": "label_0",
            "type": "label",
            "gridX": 6,
            "minGridX": 3,
            "config": {
              "props": [
                "labelText",
                "prefixText",
                "alignment",
                "defaultValue",
                "tooltip",
                "hidden",
                "backgroundColor"
              ],
              "labelText": {
                "displayName": "Label Text",
                "value": "Default label text. Double-click to change it.",
                "resetValue": "",
                "type": "longtext"
              },
              "prefixText": {
                "displayName": "Prefix Text",
                "value": "",
                "type": "text"
              },
              "alignment": {
                "displayName": "Text Alignment",
                "value": "flex-start",
                "resetValue": "flex-start",
                "type": "app-alignment-settings"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "hidden": {
                "displayName": "Hidden",
                "type": "boolean",
                "value": false
              },
              "defaultValue": {
                "displayName": "Default Value",
                "type": "defaultValue",
                "enabled": false,
                "dynamic": false,
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            },
            "textFormat": {
              "props": [
                "class",
                "fontFamily",
                "fontSize",
                "bold",
                "italic",
                "underline",
                "color",
                "backgroundColor"
              ],
              "class": {
                "displayName": "Class",
                "value": ""
              },
              "fontFamily": {
                "displayName": "Font Family",
                "value": "Arial"
              },
              "fontSize": {
                "displayName": "Font Size",
                "value": 14
              },
              "bold": {
                "displayName": "Bold",
                "value": false
              },
              "italic": {
                "displayName": "Italic",
                "value": false
              },
              "underline": {
                "displayName": "Underline",
                "value": false
              },
              "color": {
                "displayName": "Font Color",
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "value": ""
              }
            }
          },
          "templateDataU+FF0EbannerImage": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "1721634761824d6a2f7b",
            "name": "image_0",
            "type": "image",
            "gridX": 6,
            "gridY": 0,
            "minGridX": 3,
            "minGridY": 3,
            "initialPixelHeight": 0,
            "config": {
              "props": [
                "src",
                "tooltip",
                "width",
                "backgroundColor"
              ],
              "src": {
                "displayName": "Source URL",
                "value": "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/common-assets/image-placeholder.png",
                "resetValue": "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/common-assets/image-placeholder.png",
                "type": "image-picker"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "width": {
                "displayName": "Width",
                "value": "100%",
                "type": "select"
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            }
          },
          "templateDataU+FF0Etagline": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "1721634761824e00b7ec",
            "name": "label_0",
            "type": "label",
            "gridX": 6,
            "minGridX": 3,
            "config": {
              "props": [
                "labelText",
                "prefixText",
                "alignment",
                "defaultValue",
                "tooltip",
                "hidden",
                "backgroundColor"
              ],
              "labelText": {
                "displayName": "Label Text",
                "value": "Default label text. Double-click to change it.",
                "resetValue": "",
                "type": "longtext"
              },
              "prefixText": {
                "displayName": "Prefix Text",
                "value": "",
                "type": "text"
              },
              "alignment": {
                "displayName": "Text Alignment",
                "value": "flex-start",
                "resetValue": "flex-start",
                "type": "app-alignment-settings"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "hidden": {
                "displayName": "Hidden",
                "type": "boolean",
                "value": false
              },
              "defaultValue": {
                "displayName": "Default Value",
                "type": "defaultValue",
                "enabled": false,
                "dynamic": false,
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            },
            "textFormat": {
              "props": [
                "class",
                "fontFamily",
                "fontSize",
                "bold",
                "italic",
                "underline",
                "color",
                "backgroundColor"
              ],
              "class": {
                "displayName": "Class",
                "value": ""
              },
              "fontFamily": {
                "displayName": "Font Family",
                "value": "Arial"
              },
              "fontSize": {
                "displayName": "Font Size",
                "value": 14
              },
              "bold": {
                "displayName": "Bold",
                "value": false
              },
              "italic": {
                "displayName": "Italic",
                "value": false
              },
              "underline": {
                "displayName": "Underline",
                "value": false
              },
              "color": {
                "displayName": "Font Color",
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "value": ""
              }
            }
          },
          "templateDataU+FF0Edescription": {
            "effectStyleMap": {
              "list": [
                "color",
                "font-size"
              ],
              "color": {
                "id": "color",
                "name": "Color",
                "inputType": "color-palette"
              },
              "font-size": {
                "id": "font-size",
                "name": "Font Size",
                "inputType": "string"
              }
            },
            "id": "17216347618240a8681b",
            "name": "label_0",
            "type": "label",
            "gridX": 6,
            "minGridX": 3,
            "config": {
              "props": [
                "labelText",
                "prefixText",
                "alignment",
                "defaultValue",
                "tooltip",
                "hidden",
                "backgroundColor"
              ],
              "labelText": {
                "displayName": "Label Text",
                "value": "Default label text. Double-click to change it.",
                "resetValue": "",
                "type": "longtext"
              },
              "prefixText": {
                "displayName": "Prefix Text",
                "value": "",
                "type": "text"
              },
              "alignment": {
                "displayName": "Text Alignment",
                "value": "flex-start",
                "resetValue": "flex-start",
                "type": "app-alignment-settings"
              },
              "tooltip": {
                "displayName": "Tooltip",
                "value": "",
                "type": "text",
                "enabled": true
              },
              "hidden": {
                "displayName": "Hidden",
                "type": "boolean",
                "value": false
              },
              "defaultValue": {
                "displayName": "Default Value",
                "type": "defaultValue",
                "enabled": false,
                "dynamic": false,
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "type": "color-picker",
                "value": ""
              }
            },
            "textFormat": {
              "props": [
                "class",
                "fontFamily",
                "fontSize",
                "bold",
                "italic",
                "underline",
                "color",
                "backgroundColor"
              ],
              "class": {
                "displayName": "Class",
                "value": ""
              },
              "fontFamily": {
                "displayName": "Font Family",
                "value": "Arial"
              },
              "fontSize": {
                "displayName": "Font Size",
                "value": 14
              },
              "bold": {
                "displayName": "Bold",
                "value": false
              },
              "italic": {
                "displayName": "Italic",
                "value": false
              },
              "underline": {
                "displayName": "Underline",
                "value": false
              },
              "color": {
                "displayName": "Font Color",
                "value": ""
              },
              "backgroundColor": {
                "displayName": "Background Color",
                "value": ""
              }
            }
          }
        },
        "getFn": {
          "__id": "get",
          "name": "Get",
          "dataFlow": "pull",
          "crudType": "R",
          "functionName": "get",
          "options": {
            "filter": true,
            "sort": true,
            "maxPageSize": 1000
          },
          "input": {
            "list": [
              "query",
              "options"
            ],
            "options": {
              "name": "options",
              "dataType": "object",
              "list": [
                "relationObject"
              ],
              "relationObject": {
                "dataType": "string",
                "name": "Relation Object",
                "__id": "relationObject",
                "hidden": true,
                "value": ""
              }
            },
            "query": {
              "name": "query",
              "dataType": "query",
              "required": true
            }
          }
        }
      }

    return result;
  }


}

function toTitleCase(str: string): string {
  return str.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
