<!-- <spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner> -->

  <mat-dialog-content>
    <app-create-form-detail [input]="dataInput" (nameChanged)="nameChanged($event)"></app-create-form-detail>
  </mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="center center" style="padding-bottom: 10px;">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div fxLayout="column" fxFlex="30">
                </div>

                <div fxLayout="column" fxFlex="30">
                    <button mat-stroked-button  [mat-dialog-close]="false" color="primary"  type="button">
                        Cancel
                      </button>
                </div>
                <div fxFlex="2"></div>
                <!-- isNameExists ||  -->
                <div fxLayout="column" fxFlex="30">
                    <button mat-flat-button (click)="createForm()" [disabled]="spinner"  color="primary" type="button">
                      {{dataInput?.buttonText ? dataInput.buttonText : 'Create'}}
                      </button>
                </div>

           </div>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>



