export class TextFormatting {
  private  static _textFormatting = {
    props: [
      'class',
      'fontFamily',
      'fontSize',
      'bold',
      'italic',
      'underline',
      'color',
      'backgroundColor' // Add this property for text background color
    ],
    class: {
      displayName: 'Class',
      value: ''   //string
    },
    fontFamily: {
      displayName: 'Font Family',
      value: 'Arial'  //string
    },
    fontSize: {
      displayName: 'Font Size',
      value: 14     //number
    },
    bold: {
      displayName: 'Bold',
      value: false    //boolean
    },
    italic: {
      displayName: 'Italic',
      value: false    //boolean
    },
    underline: {
      displayName: 'Underline',
      value: false    //boolean
    },
    color: {
      displayName: 'Font Color',
      value: '#000000'
    },
    backgroundColor: {
      displayName: 'Background Color', // Display name for background color
      value: '#ffffff' // Default background color
    }
  }

  public static textFormatting (){
    return TextFormatting._textFormatting
  }

  public static initStyles(textFormat: any) {
    if (textFormat.color?.value) textFormat.color.value = ''
    if (textFormat.underline?.value) textFormat.underline.value = false
    if (textFormat.bold?.value) textFormat.bold.value = false
    if (textFormat.fontFamily?.value) textFormat.fontFamily.value = 'Arial'
    if (textFormat.fontSize?.value) textFormat.fontSize.value = 14
    if (textFormat.italic?.value) textFormat.italic.value = false
    if (textFormat.backgroundColor?.value) textFormat.backgroundColor.value = '' // Reset background color
    return textFormat
  }
}
