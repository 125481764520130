import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { ConnectionDialogComponent } from 'src/app/shared/dialog/connection-dialog/connection.dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
  selector: 'app-role-view',
  templateUrl: './role-view.component.html',
  styleUrls: ['./role-view.component.scss']
})
export class RoleViewComponent implements OnInit {

  workspaceId: string;
  preAuthenticatedToken: string;
  roles:any = [];
  isCreateRole: boolean = false;
  roleConfig: any = {};
  filterResType:any = "Default";
  filterResTypes: any = ["Default"]

  @Input() resource_type: any;
  @Input() resource_id: boolean;

  constructor(
    private route: ActivatedRoute,
    private connectionService: ConnectionService,
    private spinnerService: SpinnerService,
    private adminService: AdminService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {

      this.workspaceId = params.workspaceid|| this.connectionService?.workSpaceId;

      if (!this.workspaceId) {
        this.workspaceId = await this.connectionService.getWorkSpaceId();
      }

      if (!params.authorization) {
        this.preAuthenticatedToken = this.connectionService.preAuthenticatedToken;
      } else {
        this.preAuthenticatedToken = decodeURIComponent(params.authorization);
      }

      // if (params.get('workspaceid') && decodeURIComponent(params.get('authorization')) != 'null') {
      //   this.connectionService.workSpaceId = params.get('workspaceid');
      //   this.connectionService.preAuthenticatedToken = decodeURIComponent(params.get('authorization'));
      // }
    });

    this.roleConfig = {
      resource_type: this.resource_type,
      resource_id: this.resource_id
    }

    if(this.resource_type == "bloom"){
      this.filterResTypes.push("Bloom");
      this.filterResType = "Bloom"
    }
  }

  async ngAfterViewInit() {
    if (this.preAuthenticatedToken) {
      await this.listRole();
    }
  }

  async deleteRole(roleObj: any) {
    let dialogRef = this.dialog.open(ConnectionDialogComponent, {
      data: { name: roleObj.name },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.spinnerService.show();
        await this.adminService.deleteRole(this.preAuthenticatedToken, roleObj._id);
        await this.listRole();
        this.roles = result.data;
        this.spinnerService.hide();
      }
    })
  }

  editRole(roleObj: any) {
    console.log(roleObj)

  }

  async listRole(){
    this.isCreateRole = false;
    this.spinnerService.show();
      let payload = {
        filter: "resource_type=default|string"
      }

      if(this.filterResType == "Bloom") payload.filter = `resource_type=bloom|string,resource_id=${this.resource_id}|string`;

      var res:any = await this.adminService.listRole(1, 20, this.preAuthenticatedToken, payload)
      this.roles = res.data;
      this.spinnerService.hide();
  }

}

