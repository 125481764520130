<mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="start center">
        <h2 fxLayoutAlign="center center">{{dialogData.box.name}} {{dialogData.object}} table</h2>
        <div fxLayout="column" fxLayoutAlign="start start" >

            <div *ngIf="show">
                <shared-parameter-inputs  
                    [action]="dialogData.action"
                    (inputParameter)="inputRecevied($event)"
                    [connection]="dialogData.connection"
                    [isOnlyOptions]="true"
                    [attributesFetched]="dialogData.getAttributesData">
                </shared-parameter-inputs>
                <button fxLayoutAlign="center center" mat-raised-button color="primary" style="margin-top: 2rem; margin-left: 1rem; width: 20%;" (click)="constructMeta()">
                    Next
                </button>
            </div>

            <div *ngIf="!show && panelMeta.type == 'formpanel'" class="fullWidth">
                <app-panel [panelMeta]="panelMeta" [builderMode]="false" [inputRowData]="dialogData.selectedRowData" (executeActionResponse)="executionResponse($event)">
                </app-panel>
            </div>

            <div *ngIf="!show">
                <div fxLayoutAlign="center center" fxFlex="100%" >
                    <button fxLayoutAlign="center center" mat-raised-button color="warn" style="margin-top: 2rem; margin-left: 1rem; width: 10%;" (click)="close()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>



