import { Widget } from "./baseWidget";
import { AvailableOptions } from "./optionsConfig";
import { TextFormatting } from './textFormat'

interface item {
  content: string,
  checked: boolean,
  disabled?: boolean
}

export class Checkbox extends Widget {
  type = 'checkbox';
  gridX = 12
  minGridX = 3
  config = {
    props: [
      'title',
      'showTitle',
      'availableOptions',
      'alignment',
      'orientation',
      'tooltip',
      'viewOnly'
    ],
    title: {
      displayName: 'Title',
      value: 'Checklist',
      type: 'text',
    },
    showTitle: {
      displayName: 'Show Title',
      value: true,
      type: 'boolean'
    },
    availableOptions: AvailableOptions,
    orientation: {
      displayName: 'Orientation',
      value: 'horizontal',   //or vertical
      type: 'radio',
      availableItems: [
        {
          displayName: 'Horizontal',
          value: 'horizontal'
        },
        {
          displayName: 'Vertical',
          value: 'vertical'
        }
      ]
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      type: 'app-alignment-settings'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not edit the values',
      value: false,
      type: 'boolean',
      resetValue: false
    }
  };

  // textFormat = Object.assign({}, textFormatting);
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.availableOptions.staticOptions",
      effect: {
        styles: []
      }
    }
    return map;
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  /**
   * to indicate this widget is a selectable widget with options
   */
  public isOptionBasedWidget(){
    return true
  }

  public setOptions(values){
    return values;
  }
}
