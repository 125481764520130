import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DurationPickerComponent } from './duration-picker.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule
  ],
  declarations: [
    DurationPickerComponent,
  ],
  exports: [
    DurationPickerComponent,
    MatButtonModule
  ],
})
export class DurationPickerModule { }
