  import { AfterViewInit, Component, DoCheck, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BoxService } from 'src/app/bloom/services/box-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { ListPanelService } from '../../bloom/specialized-panels/list-panel/list-panel.service';

interface BOX_CONFIG {
  boxId: string,
  boxObjectId: string,
  connectionId: string,
  boxName: string,
  attributeOptions: any[],
  getFnOptions: any[],
  getFn?: any
}

enum FilterType {
  static_filter = "Static Filter",
  page_filter = "Page Filter",
  navigation_filter = "Navigation filter"
}

interface LIST_PANEL_FILTERS {
  filterEnabled?: boolean,
  filterItems?: FILTER_ITEM[]
}

interface FILTER_ITEM {
  attribute: any,
  operator: string,
  value: any,
  dataType: string,
  filterType: string
}

@Component({
  selector: 'app-list-panel-popup',
  templateUrl: './list-panel-popup.component.html',
  styleUrls: ['./list-panel-popup.component.css']
})
export class ListPanelPopupComponent implements OnInit, OnDestroy, DoCheck {
  isSubPanel: boolean;


  constructor(
    public dialogRef: MatDialogRef<ListPanelPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private MetaService: MetaService,
    private boxService: BoxService,
    private pageService: PageService,
    private WidgetUtilityService: WidgetUtilityService,
    public listPanelService: ListPanelService
  ) {
  }


  ngOnInit(): void {
    console.log("this.listPanelService.subPanelMetas", this.listPanelService.subPanelMetas);
    }



  ngDoCheck(): void {
    // when grouping attribute input is ready, set previous value in case of 2nd time config opening

  }

  ngOnDestroy(): void {
  }

  createSubListPanel(input){
    let array = []
    let data = input.data;

    if(input.type != 'arrayofobjects'){
      for(let key in data){
        let obj = {};
        if(input.type == 'arrayofprimitives') obj['values'] = data[key];
        else {
          obj['key'] = key;
          obj['value'] = data[key];
        }
        array.push(obj);
      }
    } else array = data;

    if(array && array.length > 0) {
      let subPanelMeta:any = {} ;
      subPanelMeta['panelMeta'] = this.listPanelService.createSubPanelMeta(array);
      this.listPanelService.processSubData(array, subPanelMeta);
      subPanelMeta.isInputData = true;
      subPanelMeta.key = input?.key;

      this.listPanelService.handleSubListView(subPanelMeta);
      subPanelMeta.isSubPanel = true;
      this.listPanelService.subPanelMetas.push(subPanelMeta);
    }


    if(this.listPanelService.subPanelMetas?.length > 0){
      this.listPanelService.subPanelMeta = this.listPanelService.subPanelMetas[this.listPanelService.subPanelMetas.length - 1];
    }
  }

  closeSubPanel(){
    this.listPanelService.subPanelMetas.splice(-1);
    if(this.listPanelService.subPanelMetas.length == 0){
      this.listPanelService.subPanelMeta = {};
      this.isSubPanel = false;
      this.dialogRef.close()
    } else if(this.listPanelService.subPanelMetas.length > 0){
      this.listPanelService.subPanelMeta = this.listPanelService.subPanelMetas[this.listPanelService.subPanelMetas.length - 1];
      this.isSubPanel = true;
    }
  }

}
