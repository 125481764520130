import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from './auth-service.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FrontendEventService {
  eventHistory: any[] = [];
  redoStack: any[] = [];
  historyPointer: number = -1;
  onThisAction: boolean;

  constructor(
  ) { }

  captureEvent(data: any): void {

    if(!data) return;
    // console.log("this.onThisAction", this.onThisAction)
    if(this.onThisAction) {
      this.onThisAction = false;
      return;
    }
    this.redoStack.length = 0;
     // Clear the redo stack when adding a new event
    let event = this.clone(data);
    let curEvent = JSON.stringify(event);
    let prevEvent = JSON.stringify(this.eventHistory[this.eventHistory.length - 1]);
    if(curEvent == prevEvent) {
      // console.log("[FrontendEventService] curEvent captured historyPointer", JSON.parse(curEvent), JSON.parse(prevEvent))
      return;
    }
    this.eventHistory.push(event);
    this.historyPointer++;
  }

  undo(): any  { //'z'
    // console.log("[FrontendEventService] undo historyPointer", this.historyPointer)
    if (this.historyPointer > 0) {
      this.onThisAction = true;
      this.redoStack.push(this.clone(this.eventHistory[this.historyPointer]));
      this.historyPointer--;
      return this.eventHistory[this.historyPointer];
    }
    return null;
  }

  redo():any { //'y'
    // console.log("[FrontendEventService] redo historyPointer", this.historyPointer)
    if (this.redoStack.length > 0) {
      this.onThisAction = true;
      this.historyPointer++;
      this.eventHistory.push(this.clone(this.redoStack.pop()));
      return this.eventHistory[this.historyPointer];
    }
    return null;
  }

  clearHistory(): void {
    this.eventHistory = [];
    this.historyPointer = -1;
    console.log("[FrontendEventService] history cleared", this.eventHistory, this.historyPointer)
  }

  clone(data: any){
    return JSON.parse(JSON.stringify(data));
  }

}
