import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout'

import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { BoxConfigInputsModule } from '../../box-config-inputs/box-config-inputs.module';
import { WidgetsModule } from '../../widgets/widgets.module';
import { AppSelectionModule } from 'src/app/shared/app-selection/app-selection.module';
import { ConnectionSelectionModule } from 'src/app/shared/connection-selection/connection-selection.module';
import { ActionSelectionModule } from 'src/app/shared/action-selection/action-selection.module';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';


// import { OrganizationModule } from 'src/app/modules/organization/organization.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ListPanelComponent } from './list-panel.component';
import { ListPanelDialogComponent } from './list-panel-dialog/list-panel-dialog.component';
// import { DataTableComponent, NavigateConfirmationComponent } from './data-table/data-table.component';
import { NavigationPanelComponent } from './navigation-panel/navigation-panel.component';
import { AttributeMappingComponent } from './attribute-mapping/attribute-mapping.component';
import { ListDataTableModule } from './data-table/data-table.module';
import { SecurityConfigModule } from 'src/app/shared/security/config/security-config/security-config.module';
import { BaseSelectionModule } from 'src/app/shared/base-selection/base-selection.module';
// import { WidgetConfigurationModule } from '../../widget-configuration/widget-configuration.module';
import { AttributeSelectionModule } from 'src/app/shared/query-components/attribute-selection/attribute-selection.module';
import { FilterConfigModule } from 'src/app/shared/query-components/filter-config/filter-config.module';
import { SortConfigModule } from 'src/app/shared/query-components/sort-config/sort-config.module';
import { GoogleiconPickerModule } from 'src/app/shared/googleicon-picker/googleicon-picker.module';
// import { SharedSpecializedComponentsModule } from '../shared-components/shared-components.module';
import { ParameterInputsModule } from 'src/app/shared/parameter-inputs/parameter-inputs.module';
import { ListPanelPopupComponent } from 'src/app/shared/list-panel-popup/list-panel-popup.component';
import { SnackbarModule } from 'src/app/shared/snackbar/snackbar.module';
import { ChartDynamicAttributeConfigModule } from '../../widget-configuration/chart-dynamic-attribute-config/chart-dynamic-attribute-config.module';
import { WidgetBuilderModule } from '../../widget-builder/widget-builder.module';
import { FieldPickerModule } from 'src/app/shared/field-picker/field-picker.module';
import { FilterComponentComponent } from "../../../shared/query-components/filter-component/filter-component.component";


@NgModule({
    declarations: [
        ListPanelComponent,
        ListPanelDialogComponent,
        // DataTableComponent,
        // NavigateConfirmationComponent,
        NavigationPanelComponent,
        AttributeMappingComponent,
        ListPanelPopupComponent
        // PanelFilterConfigComponent
    ],
    imports: [
    CommonModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    // OrganizationModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    SpinnerModule,
    SharedModule,
    BoxConfigInputsModule,
    WidgetsModule,
    AppSelectionModule,
    ConnectionSelectionModule,
    ActionSelectionModule,
    ListDataTableModule,
    SecurityConfigModule,
    BaseSelectionModule,
    AttributeSelectionModule,
    FilterConfigModule,
    SortConfigModule,
    GoogleiconPickerModule,
    ParameterInputsModule,
    SnackbarModule,
    ChartDynamicAttributeConfigModule,
    WidgetBuilderModule,
    FieldPickerModule,
    FilterComponentComponent
],
    exports: [
        ListPanelComponent,
        ListPanelDialogComponent,
        // DataTableComponent,
        NavigationPanelComponent,
        AttributeMappingComponent,
        ListPanelPopupComponent
    ]
})
export class ListPanelModule { }
