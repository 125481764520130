<spinner *ngIf="spinner" [selfSpin]="true" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<div class="outerWrap" fxLayout="column" fxLayoutGap=".5rem" fxLayoutAlign="start center" (click)="panelDeselected()">
  <div style="height: 1rem;"></div>
  <div class="body" (click)="panelDeselected()" *ngIf="page?.panels">
    <ng-container *ngIf="formService.builderMode; then thenBodyTemplate; else elseBodyTemplate"></ng-container>

    <ng-template #thenBodyTemplate>
      <div *ngIf="isFormReady" class="page-wrap" cdkDropList (cdkDropListDropped)="panelReorder($event)">
        <div fxLayout="row wrap" fxLayoutAlign="start center" cdkDropListGroup>
          <div fxFlex="100%" *ngFor="let panel of page.panels" class="panel" cdkDrag [cdkDragDisabled]="!formService.builderMode || panel.noDrag">
            <app-panel
              (onPanelSelect)="onPanelSelect($event)"
              (onPanelMouseenter)="onPanelMouseenter($event)"
              (onPanelMouseleave)="onPanelMouseleave($event)"
              (onPanelDelete)="panelDelete($event)"
              (newPanelMeta)="newPanelMeta($event)"
              (widgetDeletion)="widgetDeletion($event)"
              (userInputReceived)="userInputHandler($event)"
              (newWidgetCreated)="newWidgetCreated($event)"
              [selectedPanelId]="activePanel"
              [hoveredPanelId]="hoveredPanelId"
              [panelMeta]="panel"
              [builderMode]="formService.builderMode"
              [pageMeta]="page"
              [parentType]="'form'"
              >            
            </app-panel>
            <!-- [selectedWidgetId]="formService.selectedWidget" -->
          <!-- (widgetSelection)="widgetSelection($event)" -->
          </div>

        </div>
      </div>
      <br/><br/>
    </ng-template>

    <ng-template #elseBodyTemplate>
      <div *ngIf="isFormReady" class="page-wrap">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <div fxFlex="100%" *ngFor="let panel of page.panels" class="panel">
            <app-panel [panelMeta]="panel" [builderMode]="formService.builderMode" (userInputReceived)="userInputHandler($event)">
            </app-panel>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
