<ng-container *ngIf="widgetMeta && widgetMeta.config">
  <div
    #outerCover
    *ngIf="builderMode"
    class="outerCover"
    [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
    [class.selectedNow]="selectedWidgetId === widgetMeta.id"
    [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow"
    (mouseenter)="widgetMouseenter()"
    (mouseleave)="widgetMouseleave()"
    (click)="onOuterClick()"
    [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
    [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
  >
    <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>


    <div
      class="innerCover"
      fxLayout fxLayoutAlign="center center"
    >
    <img class="loginimg" src="https://storage.googleapis.com/jivrus-web-images/signin/google/google_signin_dark_pressed.png" alt="new bloom" style="opacity: 90%;">
    </div>
  </div>


  <div
    #outerCover
    *ngIf="!builderMode"
    [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
    class="outerCover"
    [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
    [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
  >


    <div
      class="innerCover"
      fxLayout fxLayoutAlign="center center"
    >
    <div fxLayoutAlign="center center" id="buttonDiv"> </div>
    </div>
  </div>
</ng-container>
