import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';

import { WidgetBuilderComponent } from './widget-builder/widget-builder.component';

import { WidgetsModule } from '../widgets/widgets.module';
import { WidgetConfigurationModule } from '../widget-configuration/widget-configuration.module';
import { WidgetsWrapperModule } from '../widget-wrapper/widget-wrapper.module';


@NgModule({
  declarations: [
    WidgetBuilderComponent
  ],
  imports: [
    CommonModule,
    WidgetsModule,
    WidgetConfigurationModule,
    MatMenuModule,
    WidgetsWrapperModule
  ],
  exports: [
    WidgetBuilderComponent
  ]
})
export class WidgetBuilderModule { }
