<div>
  <br />
  <div fxLayout="row">
    <div fxLayout="column" fxFlex="5"></div>
    <div fxLayout="column" fxFlex="90" fxLayoutAlign="center center">
      <h2><b>Starch Sharing and collaboration</b></h2>
    </div>
    <div fxLayout="column" fxFlex="5"></div>
  </div>
  <div *ngIf="starch" fxLayout="column" fxLayoutAlign="space-around">
    <div fxLayout="column" fxLayoutAlign="space-around center">
      <div
        fxLayout="row"
        fxLayoutAlign="space-around center"
        style="padding: 20px; text-align: center"
      >
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-around center"
        style="padding: 15px; text-align: center"
      >
        <div style="margin-top: 4%">
          <mat-card-subtitle>Starch: {{ starch.name }}</mat-card-subtitle>
        </div>
      </div>
    </div>
  </div>
</div>
<br />

<mat-tab-group style="max-height: 50vh">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">admin_panel_settings</mat-icon>
      Starch Members
    </ng-template>
    <app-common-member
      [restoreInput]="restoreInput"
      [resourceType]="dialogData.resourceType"
      [resourceMap]="dialogData.resource"
    ></app-common-member>
  </mat-tab>
</mat-tab-group>
<br />
<div fxLayout="row" fxLayoutAlign="stretch" *ngIf="!isShowInvite">
  <button
    mat-stroked-button
    color="primary"
    (click)="isShowInvite = true"
    type="button"
  >
    Invite Members
  </button>
</div>
<div fxLayout="row" class="mat-h4" *ngIf="isShowInvite">
  <h3 style="margin-bottom: 0px"><b>Invite Members</b></h3>
</div>
<div *ngIf="isShowInvite">
  <app-resource-collaboration
    (cancel)="isShowInvite = false"
    (emailSent)="isShowInvite = false; resetLoading()"
    [resourceMap]="dialogData.resource"
    [resourceType]="dialogData.resourceType"
    [workspaceMap]="dialogData.workspaceMap"
  >
  </app-resource-collaboration>
</div>
