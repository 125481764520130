import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { AppSelectionModule } from 'src/app/shared/app-selection/app-selection.module';
import { ConnectionSelectionModule } from 'src/app/shared/connection-selection/connection-selection.module';
import { ParameterInputsModule } from 'src/app/shared/parameter-inputs/parameter-inputs.module';
import { BoxConfigInputsModule } from 'src/app/bloom/box-config-inputs/box-config-inputs.module';

import { DataSourceBoxConfig } from './widget-data-source-box-config.component'
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { BaseSelectionModule } from '../base-selection/base-selection.module';


@NgModule({
    declarations: [
        DataSourceBoxConfig
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatInputModule,
        MatButtonModule,
        SpinnerModule,
        AppSelectionModule,
        ConnectionSelectionModule,
        ParameterInputsModule,
        BoxConfigInputsModule,
        BaseSelectionModule
    ],
    exports: [
        DataSourceBoxConfig
    ]
})
export class WidgetDataSourceBoxConfigModule { }
