import { Injectable, Injector } from '@angular/core';
import { WidgetManager } from '../models/WidgetManager';
import { WidgetService } from './widget-service.service';
import { WidgetAction } from '../models/Action/WidgetAction';
import { WidgetUtilityService } from './widget-utility.service';

@Injectable({
  providedIn: 'root'
})
export class PageUtilityService {
  constructor(
    private myWidgetService: WidgetService,
  ) { }

   /**
   * clears the injected values of widgets in all form and details panels present in the page
   * @param pageMeta 
   */
   clearPageValues(pageMeta: any) {
    pageMeta.panels.forEach((panel, i) => {
      if(['detailspanel', 'formpanel'].includes(panel.type)) {
        let p = this.clearPanelWidgetValues(JSON.parse(JSON.stringify(panel)))
        pageMeta.panels[i] = JSON.parse(JSON.stringify(p))
      }
    })
    console.log("page values cleared", JSON.parse(JSON.stringify(pageMeta)))
    return pageMeta
  }
  
  clearPanelWidgetValues(panelMetaIncoming){
    let panelMeta = JSON.parse(JSON.stringify(panelMetaIncoming))
    console.log("clearing widget values hit", JSON.parse(JSON.stringify(panelMeta)))
    // clear widget values
    // let widgets = this.myWidgetService.getWidgetsFromPanel(this.panelMeta)
    let layoutMap = JSON.parse(JSON.stringify(panelMeta.layoutMap))
    layoutMap.list?.forEach(layout => {
      layoutMap[layout]?.list?.forEach(row => {
        let rowMap = layoutMap[layout][row];
        if(rowMap?.type == 'elements' && rowMap?.elements?.length > 0) {
          rowMap.elements.forEach((w, i) => {
            if(w.id.split('-').length > 1) { // only if it a widget associated with attribute

                let widget: any = this.myWidgetService.createNewWidgetInstance(w);
                //   let widget = WidgetManager.getWidget(w.type)
                //   Object.keys(w).forEach(prop => widget[prop] = JSON.parse(JSON.stringify(w[prop])))
                
                let action
                if(widget.type == 'image') {
                action = this.myWidgetService.createWidgetAction(widget.type, "${reset}", String(widget.id), panelMeta.id)
                } else if(['date', 'time', 'datetime'].includes(widget.type)) {
                  action = this.myWidgetService.createWidgetAction(widget.type, "", String(widget.id), panelMeta.id)
                } else {
                  action = this.myWidgetService.createWidgetAction(widget.type, "${" + widget.id.split("-")[1] + "}", String(widget.id), panelMeta)
                }
                //   let widgetAction = this.injector.get(WidgetAction);
                //   widgetAction.doAction(action, {type: 'click', widgetMap: widget}, true)
                
              
                widget.setValue(action.actionMap.value)

                layoutMap[layout][row].elements[i] = widget
                // console.log("widget value reset", layoutMap[layout][row].elements[i])
            }
          })
          // console.log("row widgets handled", JSON.parse(JSON.stringify(layoutMap[layout][row].elements)))
        }
      });
      // console.log("layout widgets handled", JSON.parse(JSON.stringify(layoutMap[layout])))
    });
    
    panelMeta['layoutMap'] = JSON.parse(JSON.stringify(layoutMap))
    // console.log("layoutMap", JSON.parse(JSON.stringify(layoutMap)))
    // console.log("widget values cleared: panelMeta", JSON.parse(JSON.stringify(panelMeta.layoutMap)))
    return panelMeta
  }
}