import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StarchService } from 'src/app/shared/services/starch.service';

@Component({
  selector: 'starch-base-popup',
  templateUrl: './starch-base-popup.component.html',
  styleUrls: ['./starch-base-popup.component.scss']
})
export class StarchBasePopupComponent implements OnInit {

  data: any;
  type: any;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dataInput: any,
    public dialog: MatDialogRef<StarchBasePopupComponent>,
    public starchService: StarchService

  ) { }



  async ngOnInit() {

    this.data = this.dataInput?.data;
    console.log("this.dataInput", this.dataInput);
    this.type = this.dataInput?.type;

  }

  onNoClick(): void {
    this.dialog.close(false);
  }

  delete(){
    this.dialog.close(true);
  }

  dontDelete(){
    this.dialog.close(false);
  }



}


