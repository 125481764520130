import { Injectable } from '@angular/core';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { WorkspaceService } from 'src/app/shared/services/workspace.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {


  availableRoles: any = [];
  availablePrivileges: any = [];
  roleMap: any = {};
  roleInheritMap:any = {
    bloom: {
      owner: ["owner", "bloom.admin"],
      "workspace.admin": ["bloom.admin"],
      "workspace.editor": ["bloom.editor"],
      "workspace.viewer": ["bloom.viewer"]
    }
  }
  isSharedBloom: boolean = false;
  sharedWorkspaceId: any;


  constructor(
    public adminService: AdminService,
    public workspaceService: WorkspaceService,
    public connectionService: ConnectionService
  ) { }


  async setWorkspacePrivilages() {
    var userId = this.connectionService?.userProfile._id;
    var workSpaceId = this.connectionService?.workSpaceId;
    var roleMap = await this.workspaceService.getPermissionForUser(userId, workSpaceId);
    var roles = await this.adminService.getRolesbyIds(roleMap?.roles, this.connectionService?.preAuthenticatedToken);

    roles.forEach((e: any) => {
      // this.availablePrivileges = this.availablePrivileges.concat(e.privileges);
      e.privileges?.forEach(element => {
        if(!this.availablePrivileges.includes(element)) this.availablePrivileges.push(element);
      });

      if(e.privileges?.length == 0) {
        if (!this.availableRoles.includes(e.code)) {
          this.availableRoles.push(e.code);
        }
      }
    })

    console.log("[WORKSPACE-PERMISSION] availablePrivileges", this.availablePrivileges)
    console.log("[WORKSPACE-PERMISSION] availableRoles", this.availableRoles)
  }


  //get and set the rolemap
  async listRole(){
      let payload = {
        filter: "resource_type=default|string"
      }
      var res:any = await this.adminService.listRole(1, 100, this.connectionService?.preAuthenticatedToken, payload)
      var roles = res.data;
      roles.forEach(element => {
        this.roleMap[element.code] = element._id;
      });
  }

  //get and set the rolemap
  getRoleIdMap(){
    let newObj: any = {};
    for (let key in this.roleMap) {
      if (this.roleMap.hasOwnProperty(key)) {
          newObj[this.roleMap[key]] = key;
      }
    }
    return newObj;
  }


  hasAccess(neededPrivileges: any): boolean {
    let result = false;
    // console.log("availablePrivileges", this.availablePrivileges)
    // console.log("availableRoles", this.availableRoles)
    // console.log("neededPrivileges", neededPrivileges)

    // if(this.availableRoles.length > 0 && this.availablePrivileges.length == 0 ) {

    //   if(neededPrivileges.includes("super.admin")){
    //     if(this.availableRoles.includes("super.admin")){
    //       result = true;
    //       return result;
    //     }
    //   }

    //   this.availableRoles.every(role => {
    //     if((role == 'owner' || role == 'admin')){
    //       result = true;
    //       return result;
    //     }
    //   })

    // } else

    if(this.availablePrivileges.length > 0){
       result = this.checkPrivilege(neededPrivileges);
    }

    return result
  }

  checkPrivilege(neededPrivileges:any){
    var result = false;
    neededPrivileges.every(privilege =>{
      if(this.availablePrivileges.includes(privilege)){
        result = true;
        return result;
      }
    })
    return result;
  }


}
