import { SharedModule } from '../../app-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { StepProcessorComponent } from './step-processor.component';
import { ParameterInputsModule } from '../../parameter-inputs/parameter-inputs.module';
import { matComponentsModule } from '../../mat-components/mat-components.module';
import { FieldPickerModule } from '../../field-picker/field-picker.module';

const routes: Routes = [
  {
    path: '', component: StepProcessorComponent
  }]

@NgModule({
  declarations: [StepProcessorComponent],
  imports: [
    CommonModule,
    SharedModule,
    ParameterInputsModule,
    matComponentsModule,
    FieldPickerModule,
    RouterModule.forChild(routes)
  ],
  exports: [StepProcessorComponent]
})
export class StepProcessorModule { }
