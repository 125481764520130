import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'display-banner',
  templateUrl: './display-banner.component.html',
  styleUrls: ['./display-banner.component.scss']
})
export class DisplayBannerComponent implements OnInit {
  environment = environment
  @Input() logo: any
  @Input() Title: string;
  @Input() Tagline: string;
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) { }



  ngOnInit(): void {

  }

}

