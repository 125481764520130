import { NgModule, forwardRef, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParameterInputsComponent } from './parameter-inputs.component';
import { SharedModule } from '../shared.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { WidgetsModule } from 'src/app/bloom/widgets/widgets.module';
import { MatChipsModule } from '@angular/material/chips';
import { QueryComponentsModule } from '../query-components/query-components.module';
import { WidgetsWrapperModule } from 'src/app/bloom/widget-wrapper/widget-wrapper.module';
import { FieldPickerModule } from '../field-picker/field-picker.module';
import { ParameterWidgetsComponent } from './parameter-widgets/parameter-widgets.component';

@NgModule({
  declarations: [ParameterInputsComponent, ParameterWidgetsComponent],
  imports: [
    CommonModule,
    SharedModule,
    SpinnerModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatPaginatorModule,
    MatCardModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    WidgetsModule,
    MatChipsModule,
    QueryComponentsModule,
    FieldPickerModule
    // WidgetsWrapperModule

  ],
  exports: [ParameterInputsComponent]
})
export class ParameterInputsModule { }
