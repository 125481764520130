import { BaseWidget } from "./BaseWidget";
import { Widget } from "./widgetClasses/baseWidget";
import { WidgetRegistry } from "./WidgetRegistry";

export class WidgetManager {
    static getWidget(type: string, id?: string, name?: string): any {
        let widget = WidgetRegistry.getWidget(type, id, name)
        // console.log("[MANAGER]", widget)
        return widget
    }
}
