import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout'
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import { MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import {MatChipsModule} from '@angular/material/chips';

import { GroupConfigComponent } from './group-config.component';
import { SecurityConfigModule } from '../../security/config/security-config/security-config.module';

@NgModule({
    declarations: [
        GroupConfigComponent
    ],
    exports: [
        GroupConfigComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatIconModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatInputModule,
        MatButtonModule,
        MatTooltipModule,
        MatSelectModule,
        MatMenuModule,
        MatCardModule,
        MatRadioModule,
        SecurityConfigModule,
        MatChipsModule
    ]
})
export class GroupConfigModule { }
