<mat-spinner *ngIf="!box" diameter="50" class="spinner" color="primary"></mat-spinner>
<div *ngIf="box" fxLayout="column" fxLayoutAlign="space-around">

    <div class="title-block">
        <div class="section top-action-wrap" fxLayoutAlign="center center">
            <a mat-raised-button extended (click)="goBack()">Back<mat-icon>west</mat-icon></a>
        </div>
        <div>
            <div class="title-text">{{ connection ? "Connection" : (starch ? "Starch" : "") }} Explorer</div>
            <div style="height: 1rem;"></div>
            <div class="description-text">Gateway to visualize your {{ connection ? "connection" : (starch ? "starch" : "") }} and explore all the possibilities</div>
        </div>
    </div>

    <connection-info-header [box]="box" [starch]="starch" [connection]="connection"></connection-info-header>

    <div style="height: 1.5rem;"></div>
    
    <objects-listing style="width: 100%;" [starch]="starch" [connection]="connection" [box]="box"></objects-listing>
    <!-- <div style="height: 1.5rem;"></div> -->
    
    <actions-listing *ngIf="connection" style="width: 100%;" [connection]="connection" [box]="box"></actions-listing>
    
    <events-listing *ngIf="connection" style="width: 100%;" [connection]="connection" [box]="box"></events-listing>

    <div class="auth-error" *ngIf="authError">
        <mat-card appearance="outlined">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem" style="width: 100%;">
            <div style="text-align: center; width: 100%;">Workspace not loaded. Try signing in.</div>
            <button mat-raised-button class="signin-button" [routerLink]="['/']">
                Sign in
            </button>
          </div>
        </mat-card>
      </div>
</div>

<!-- <div *ngIf="tempListPanel" style="width: 80%; border: 1px solid red">
    <app-list-panel 
        [builderMode]="false"
        [panelMeta]="tempListPanel"
    >
    </app-list-panel>
</div> -->