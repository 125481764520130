import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild, ElementRef, } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { BoxService } from '../../services/box-service.service';

@Component({
  selector: 'app-select-box-object',
  templateUrl: './select-box-object.component.html',
  styleUrls: ['./select-box-object.component.css']
})
export class SelectBoxObjectComponent implements OnInit {

  @Input() oldValue: any;   // this is for capturing an existing config value, only applicable when editing settings
  @Input() boxId: any;
  @Input() disableBoxObjectInput: any;
  @Input() connectionId: any;
  @Input() boxConfigToken: any;
  @Input() "objectType": any;
  @Output() boxObjectInput = new EventEmitter()
  @Output() boxObjectSelectionError: EventEmitter<any> = new EventEmitter()

  boxObjects: any[] = [];
  selectBoxObjectControl = new UntypedFormControl();
  filteredBoxObjects: any;
  spinner: boolean = false

  objectDisplayName: string;

  @ViewChild('boxObjectInput', {static: false}) boxObjectInputElement: ElementRef;

  constructor(private BoxService: BoxService) { }

  ngOnInit(): void {
    console.log("select box object onInit", this.boxId)
    this.spinner = true;

    this.BoxService.setBoxObjectDisplayNames(this.boxId)
    this.objectDisplayName = this.BoxService.objectDisplayName;

    //FILTERS FOR BOX OBJECTS AUTOCOMPLETE
    this.filteredBoxObjects = this.selectBoxObjectControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        console.log("boxObjects value changes", value)
        return value
      }),
      map(value => (typeof value === 'string' ? value : (value ? value.name : ''))),
      map(value => this._boxObjectFilter(value))
    );

    this.getBoxObjects()
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("changed props in select-box-object:", changes);
    if(changes.disableBoxObjectInput?.currentValue == true){
      console.log("disable box object input")
      this.selectBoxObjectControl.patchValue('')
      this.oldValue = undefined;
      this.selectBoxObjectControl.disable()
      this.boxObjects = []
      this.getBoxObjects()
    }
    // in first change, it will be handled by ngOnInit
    // if not first change, then boxId selection changed, need to refetch objects
    if(changes.boxId && !changes.boxId.firstChange){
      console.log("box changed")
      this.selectBoxObjectControl.patchValue('')
      this.selectBoxObjectControl.disable()
      this.boxObjects = []
      this.getBoxObjects()
    }
  }

  //filter for box names autocomplete from the connections
  private _boxObjectFilter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.boxObjects.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  displayFnBox(boxObject){
    return boxObject && boxObject.name ?  boxObject.name : ''
  }

  async getBoxObjects() {
    this.spinner = true
    try{

      let key = this.connectionId || this.boxConfigToken;
      let conType = !this.connectionId ? "token": '';
      console.log("key, conType", key, conType)
      let boxObjects: any = await this.BoxService.getBoxObjects(key, conType, {box_id: this.boxId});
      this.boxObjects = boxObjects;
      this.selectBoxObjectControl.enable()
      if(this.oldValue) {
        this.boxObjectInputElement.nativeElement.value = this.oldValue;
        let existingObject = this.boxObjects.find(object => object.__id == this.oldValue)
        if(existingObject) this.boxObjectInput.emit(existingObject)
      }
    } catch (e){
      console.log("error occurred in fetching box objects", e)
      this.boxObjectSelectionError.emit(e)
    }
    this.spinner = false
  }

  refreshBoxObjects(){
    this.selectBoxObjectControl.disable()
    this.getBoxObjects()
  }

  boxObjectSelected(event: any){
    console.log("boxObject selected in boxObjectSelect: ", event)
    this.boxObjectInput.emit(event.option.value)
  }

}
