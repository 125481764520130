import { Widget } from "./baseWidget";
import { DEFAULT_VALUE } from "./dynamicDefaultValues";
import { TextFormatting } from './textFormat'

export class TextArea extends Widget {
  type = 'textarea';
  gridX = 12
  minGridX = 6

  config = {
    props: [
      "placeholder",
      "textContent",
      "tooltip",
      "hidden",
      "rows",
      "appearance",
      "viewOnly"
    ],
    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
    placeholder: {
      displayName: "Input Placeholder",
      value: "Type your answer here",
      type: "longtext"
    },
    textContent: {
      displayName: "Text Content",
      value: "",
      resetValue: "",
      type: "text"
    },
    hidden: {
      displayName: 'Hidden',
      type: 'boolean',
      value: false,
    },
    tooltip: {
      displayName: "Tooltip",
      value: "",
      type: "text",
      enabled: true
    },
    rows: {
      displayName: "Number of Rows",
      value: 3, // Default value for number of rows
      type: "number"
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not edit the value',
      value: false,
      type: 'boolean',
      resetValue: false
    },
    defaultValue: DEFAULT_VALUE
  }

  textFormat: undefined
  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))

  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }
  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.textContent.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map)

    const appearance = this.config.appearance.value;
    if (appearance === 'rounded') {
      map.effect.styles.push('rounded');
    } else if (appearance === 'outlined') {
      map.effect.styles.push('outlined');
    }

    return map;
  }
}
