<div class="formCover">
  <div class="margin" [class.margin-left]="margin?.left" [fxFlex]="margin?.left || 0"></div>

  <div fxLayout fxLayoutAlign="space-between center" style="margin: 0 0 .5rem 0;"
    [fxFlex]="92 - ((margin?.left || 0) + (margin?.right ? (100 - margin.right) : 0))">

    <div fxFlex="5%" fxLayout fxLayoutAlign="center center"></div>
    <div fxFlex="90%" fxLayout fxLayoutAlign="center center" class="form-panel-title">
      <span *ngIf="!panelMeta.hideTitle">{{ panelMeta.detailsPanelTitle || "Details Panel" }}</span>
    </div>
    <div fxFlex="5%" fxLayout fxLayoutAlign="center center">
      <mat-icon *ngIf="builderMode" style="cursor:pointer" class="listActionIcon" (click)="openSettings()">settings</mat-icon>
    </div>
  </div>

</div>
<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

