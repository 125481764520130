<div (mouseleave)="mouseLeaveMenu()" class="contextMenuCover" (click)="$event.stopPropagation()" fxLayout fxLayoutAlign="center center">
  <mat-spinner *ngIf="spinner" color="accent" [diameter]="40" class="centerOfAll"></mat-spinner>
  <ng-container *ngFor="let action of actionConfig.actions">
    <ng-container [ngSwitch]="action">
      <mat-icon class="smallMenuItem mat-icon"  matTooltip="Settings" *ngSwitchCase="'edit'" (click)="onEdit()">settings</mat-icon>
      
      <div class="smallMenuItem mat-icon" [ngClass]="{ 'checked': widgetMeta?.textFormat?.bold.value }" 
        *ngSwitchCase="'bold'" (click)="boldChanged($event);"
        name="fontStyle" aria-label="Bold" matTooltip="Bold">
        <mat-icon>format_bold</mat-icon>
        <!-- <mat-button-toggle [checked]="widgetMeta?.textFormat?.bold.value" value="bold" matTooltip="Bold">
        </mat-button-toggle> -->
      </div>

      <div class="smallMenuItem mat-icon" [ngClass]="{ 'checked': widgetMeta?.textFormat?.italic.value }" 
        *ngSwitchCase="'italic'" (click)="italicChanged($event);"
        name="fontStyle" aria-label="Italic" matTooltip="Italic">
        <mat-icon>format_italic</mat-icon>
        <!-- <mat-button-toggle [checked]="widgetMeta?.textFormat?.italic.value" value="italic" matTooltip="Italic">
        </mat-button-toggle> -->
      </div>

      <div class="smallMenuItem mat-icon" [ngClass]="{ 'checked': widgetMeta?.textFormat?.underline?.value }" 
        *ngSwitchCase="'underline'" (click)="underlineChanged($event);"
        multiple name="fontStyle" aria-label="Underline" matTooltip="Underline">
        <mat-icon>format_underlined</mat-icon>
        <!-- <mat-button-toggle [checked]="widgetMeta?.textFormat?.underline?.value" value="underline" matTooltip="Underlined">
        </mat-button-toggle> -->
      </div>

      <ng-container *ngSwitchCase="'color'">
        <div
          appearance="standard"
          class="colorInputWrap"
          matTooltip="Text color"
          [title]="widgetMeta?.textFormat?.color?.value ? widgetMeta.textFormat.color.value : 'unset'"
        >
          <span class="colorIcon">A</span>
          <div
            #bgColorInput class="colorInput" name="fontColor"
            ngx-colors-trigger
            (input)="colorChanged($event)"
            [style.background-color]="widgetMeta.textFormat?.color?.value || 'black'"
            [ngModel]="widgetMeta.textFormat?.color?.value || 'black'"
            [colorsAnimation]="'popup'"
            [palette]="pageService.recentlyUsedColorPalette"
          ></div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'backgroundColor'">
        
        <div class="colorInputWrap">
          <mat-icon class="colorIcon" matTooltip="Background color">format_color_fill</mat-icon>
          <div 
            #bgColorInput class="colorInput" name="bgColor"
            ngx-colors-trigger
            (input)="backgroundColorChanged($event)"
            [style.background-color]="widgetMeta.config.backgroundColor?.value || 'white'"
            [ngModel]="widgetMeta.config.backgroundColor?.value || 'white'"
            [colorsAnimation]="'popup'"
            [acceptLabel]="'CONFIRM'"
            [palette]="pageService.recentlyUsedColorPalette"
          ></div>

          <div class="reset-color" matTooltip="Reset background color" (click)="backgroundColorChanged('transparent')"></div>
        </div>
      </ng-container>


      <ng-container *ngSwitchCase="'fontFamily'">
        <button mat-button [matMenuTriggerFor]="fontFamilyMenu" #fontFamilyMenuTrigger="matMenuTrigger" matTooltip="Font" class="font-family-trigger" fxLayout="row">
          <div fxFlex="70" fxLayoutAlign="center center">{{ widgetMeta?.textFormat?.fontFamily?.value?.length <= 10 ? widgetMeta?.textFormat?.fontFamily.value : (widgetMeta?.textFormat?.fontFamily?.value?.substring(0,8) + "...") }}</div>
          <div fxFlex="30"><mat-icon fxLayoutAlign="center center">arrow_drop_down</mat-icon></div>
        </button>
        
        <mat-menu #fontFamilyMenu="matMenu">
          <button mat-menu-item *ngFor="let font of fontFamilies" (click)="$event.stopPropagation(); fontStyleChanged(font)">
            {{ font }}
          </button>
        </mat-menu>
      </ng-container>


      <ng-container *ngSwitchCase="'fontSize'">
        <div class="font-size-selector">
          <button mat-button class="font-size-btn" matTooltip="Decrease font size" (click)="decreaseFontSize()">−</button>
          <input
            type="number"
            [ngModel]="widgetMeta?.textFormat?.fontSize.value"
            class="font-size-input"
            (input)="fontSizeChanged($event)"
            min="8"
            max="60"
          />
          <button mat-button class="font-size-btn" matTooltip="Increase font size" (click)="increaseFontSize()">+</button>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'class'">
        <button mat-button [matMenuTriggerFor]="fontClassMenu" #fontClassMenuTrigger="matMenuTrigger" matTooltip="Styles" class="font-family-trigger" fxLayout="row">
          <div fxFlex="70" fxLayoutAlign="center center">{{ widgetMeta?.textFormat?.class.value || "None" }}</div>
          <div fxFlex="30"><mat-icon fxLayoutAlign="center center">arrow_drop_down</mat-icon></div>
        </button>
        
        <mat-menu #fontClassMenu="matMenu">
          <button mat-menu-item *ngFor="let class of fontClasses" (click)="$event.stopPropagation(); fontClassChanged(class)">
            {{ class }}
          </button>
        </mat-menu>
      </ng-container>

      <!------------------------ INPUTS FOR WIDGET SPECIFIC PROPERTIES ARE CUSTOM WRITTEN BELOW ------------------------>
      <!-- ---------------------------------------------------------------------------------------------------------- -->


      <div *ngSwitchCase="'addOption'" fxLayout fxLayoutAlign="flex-start center">
        <!-- add options for select widget -->
        <div>
          <mat-form-field appearance="outline" class="thin-outline-field">
            <input #input (click)="$event.stopPropagation()" matInput type="text" [(ngModel)]="newOption" placeholder="New Option...">
            <mat-icon matSuffix matTooltip="Add option" (click)="onAddOption(); input.value = ''" style="cursor: pointer">done</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <!-- <div *ngSwitchCase="'stopIterativeDataLoad'" fxLayoutAlign="flex-start center">
        <div (click)="stopIterativeDataLoad(action)" fxLayoutAlign="center center" matTooltip="Force stop further data load" style="border: 1px solid red; border-radius: 10%; margin: .5rem;">
          <mat-icon fxLayoutAlign="center center" style="font-size: 1.3rem; font-weight: 550; color: red; cursor: pointer; opacity: 1;">search_off</mat-icon>
        </div>
      </div> -->

      <ng-container *ngSwitchDefault>
        <ng-container [ngSwitch]="actionConfig[action]?.type">
          <ng-container *ngSwitchCase="'text'">
            <ng-container *ngTemplateOutlet="input"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'inputMode'">
            <ng-container *ngTemplateOutlet="inputMode"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'password'">
            <ng-container *ngTemplateOutlet="input"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'number'">
            <ng-container *ngTemplateOutlet="inputnumber"></ng-container>
          </ng-container>
          <!-- button type dropdown -->
          <ng-container *ngSwitchCase="'select'">
            <ng-container [ngSwitch]="action">
              <app-button-type-selection *ngSwitchCase="'buttonType'" [prop]="action" [widgetMeta]="widgetMeta" [isContextMenu]="true"></app-button-type-selection>
    
              <mat-form-field class="dropdown thin-outline-field" *ngSwitchDefault appearance="outline" style="padding-bottom: 0;"
                (click)="$event.stopPropagation();$event.preventDefault();">
                <mat-label>{{ widgetMeta.config[action]?.displayName||"" }}</mat-label>
                <!-- <mat-label>{{ actionConfig.buttonType.availableTypes | json }}</mat-label> -->
                <mat-select [value]="widgetMeta.config[action]?.value" (selectionChange)="customPropertyChanged($event,action)">
                  <mat-option [value]="type" *ngFor="let type of optionsMap[action]||[]">{{ type }}</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </ng-container>
          <!---------------------------- BUTTON COLOR SELECTION ---------------------------->
          <ng-container *ngSwitchCase="'buttonColor'">
            <div fxLayout="row" fxLayoutGap="8px">
              <mat-form-field class="dropdown thin-outline-field" flex="95%" appearance="outline"
                (click)="$event.stopPropagation();$event.preventDefault();">
                <mat-label>{{widgetMeta.config[action].displayName}}</mat-label>
                <mat-select [value]="widgetMeta.config[action].value"
                  (selectionChange)="buttonColorTypeChanged($event)">
                  <mat-option [value]="type" *ngFor="let type of optionsMap[action]">
                    {{ type | titlecase }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            @if (widgetMeta.config[action].value === "custom") {
              <div class="colorInputWrap">
                <mat-icon class="colorIcon" matTooltip="Button color">format_color_fill</mat-icon>
                <div 
                  class="colorInput" name="btnColor"
                  ngx-colors-trigger
                  (input)="buttonColorChanged($event)"
                  [style.background-color]="widgetMeta.config.buttonColorType?.customValue || 'white'"
                  [ngModel]="widgetMeta.config.buttonColorType?.customValue || 'white'"
                  [colorsAnimation]="'popup'"
                  [acceptLabel]="'CONFIRM'"
                  [palette]="pageService.recentlyUsedColorPalette"
                ></div>
      
                <div class="reset-color" matTooltip="Reset button color" (click)="buttonColorChanged('transparent')"></div>
              </div>
            }
          </ng-container>
          <!-- change icon -->
          <ng-container *ngSwitchCase="'google-icon-picker'">
            <app-googleicon-picker class="form-field-padding" [label]="widgetMeta?.config[action]?.displayName"
              [value]="widgetMeta?.config?.code?.value" (iconSelectionChanged)="onChangeIcon($event)" appearance="outline">
            </app-googleicon-picker>
          </ng-container>
          <ng-container *ngSwitchCase="'image-picker'">
            <mat-form-field appearance="outline" class="thin-outline-field">
              <mat-label>
                {{ widgetMeta.config[action]?.displayName }}
              </mat-label>
              <input matInput [type]="widgetMeta.config[action]?.type" [id]="action"
              [(ngModel)]="widgetMeta.config[action].value" (change)="customPropertyChanged($event.target,action)">
            </mat-form-field>
            <dropzone type="change_button" (fileChange)="imageSelectionChange($event,action)"></dropzone>

          </ng-container>
          <ng-container *ngSwitchCase="'reset-skew'">
            <button mat-icon-button (click)="resetSkew(actionConfig[action]?.type)" matTooltip="Remove skewing"><mat-icon>aspect_ratio</mat-icon></button>
          </ng-container>
          <ng-container *ngSwitchCase="'reset-resize'">
            <button mat-icon-button (click)="resetResize(actionConfig[action]?.type)" matTooltip="Reset to original size"><mat-icon>photo_size_select_large</mat-icon></button>
          </ng-container>
          <ng-container *ngSwitchCase="'app-alignment-settings'">
            <app-alignment-settings
              [disableSpaceBetween]="true"
              [alignment]="actionConfig[action].value"
              (newAlignment)="alignmentChanged($event)"
            >
            </app-alignment-settings>
          </ng-container>
          <ng-container *ngSwitchDefault></ng-container>
          <ng-template #input>
            <mat-form-field appearance="outline" class="form-field-padding thin-outline-field">
              <!-- <mat-icon matPrefix color="primary">
              {{ descriptor.icon }}
            </mat-icon> -->
              <mat-label>
                {{ widgetMeta.config[action].displayName }}
              </mat-label>
              <input matInput [type]="widgetMeta.config[action].type" name="value"
                [value]="widgetMeta.config[action].value" (change)="customPropertyChanged($event.target, action)"
                maxlength="255" />
            </mat-form-field>
          </ng-template>
          <ng-template #inputMode>
            <!-- <mat-button-toggle-group class="smallMenuItem mat-icon" (change)="customPropertyChanged($event, action)" style="margin: 0 1rem;">
              <mat-button-toggle [checked]="action.value" [matTooltip]="widgetMeta.config[action] ? (widgetMeta.config[action].description || widgetMeta.config[action].displayName) : ''">
                <mat-icon>edit_square</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group> -->
            <mat-slide-toggle
              [checked]="widgetMeta.config[action].value"
              (change)="customPropertyChanged({value: $event.checked}, action)"
              color="primary"
              [matTooltip]="widgetMeta.config[action].displayName || ''"
              style="margin: 0 .5rem;"
            >
              {{widgetMeta.config[action].displayName}}
            </mat-slide-toggle>
          </ng-template>
          <ng-template #inputnumber>
            <mat-form-field appearance="outline" class="form-field-padding thin-outline-field">
              <mat-label>{{widgetMeta.config[action].displayName}}</mat-label>
              <input matInput [type]="'number'" name="value"
                [value]="widgetMeta.config[action].value"
                (change)="customInputNumberPropertyChanged($event,action)" />
            </mat-form-field>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <mat-icon class="smallMenuItem mat-icon" *ngIf="widgetMeta.noClone !== true" matTooltip="Clone" (click)="cloneWidget()">content_copy</mat-icon>
  <mat-icon class="smallMenuItem mat-icon" *ngIf="widgetMeta.noDelete !== true" matTooltip="Delete" (click)="onDelete()">delete</mat-icon>

</div>
