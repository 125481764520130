interface Filter {
  attribute: string;
  operator: '=' | '!=' | '%';
  value: any;
}

interface Sort {
  attribute: string;
  order: 'ASC' | 'DESC';
}

interface Pagination {
  page: number;
  size: number;
}

interface QueryParams {
  filter?: Filter[];
  sort?: Sort[];
  page?: Pagination;
}


export class QueryResolver {
  constructor() {
  }

  static getPage(pageStr:any){
    let page:any = {};
    let pageArray = pageStr?.split("|");
    page.page = Number(pageArray[0]) || 1;
    page.size = Number(pageArray[1]) || null;
    page.total  = Number(pageArray[2]) || null;
    page.nextPageToken = (pageArray[3]) || null;
    return page;
  }

  static getOrder(sortStr: any){
      let sort:any = [];
      let sortArray: any = sortStr?.split(",");
      sortArray?.forEach((att: string) => {
      var fieldArr = att.split("=")
      var attribute = fieldArr[0];
      var order: any = fieldArr[1];
      sort.push({
        attribute: attribute,
        order: order
      })
    });
    return sort;
  }

  static getFilter(filterStr: any){
    // let sort:any = [];
    let regexAttr = /(?:<=|>=|<|!%|%|!=|>|=|#|!~|~)+/;
    const ops = ["<=", ">=", "<", "!%", "%", "!=", ">", "=", "#", "!~", "~"];
    let filter:any = [];
    let filterArray: any = filterStr?.split(",");
    filterArray?.forEach((att: string) => {
      let fieldArr = att ? att.split(regexAttr) : [];
      var attribute = fieldArr[0];
      var value: any = fieldArr[1];
      if(value) filter.push({
        attribute: attribute,
        value: value,
        operator: this.findSeparatorsInString(att, ops)[0]
      })
    })
    return filter;
  }

  static findSeparatorsInString(input: string, separators: string[]): string[] {
    const separatorPattern: any = new RegExp((`${separators.map(str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|')}`), 'g');
    return input.match(separatorPattern) || [];
  }

  static applyFilters(data: any[], filters: Filter[]): any[] {
    return data.filter(app => {
      return filters.every(filter => {
        const { attribute, operator, value } = filter;
        const appValue = app[attribute as keyof any];

        switch (operator) {
          case '=':
            return Array.isArray(appValue)
              ? appValue.includes(value)
              : appValue === value;
          case '!=':
            return Array.isArray(appValue)
              ? !appValue.includes(value)
              : appValue !== value;
          case '%':
            return typeof appValue === 'string' && appValue.toLowerCase().includes(value.toLowerCase());
          default:
            return true;
        }
      });
    });
  }

  static applySorting(data: any[], sorts: Sort[]): any[] {
    return data.sort((a, b) => {
      for (const sort of sorts) {
        const { attribute, order } = sort;
        const aValue = a[attribute as keyof any];
        const bValue = b[attribute as keyof any];

        if (aValue < bValue) return order === 'ASC' ? -1 : 1;
        if (aValue > bValue) return order === 'ASC' ? 1 : -1;
      }
      return 0;
    });
  }

  static applyPagination(data: any[], pagination: Pagination): any[] {
    const { page, size } = pagination;
    const startIndex = (page - 1) * size;
    return data.slice(startIndex, startIndex + size);
  }

  static queryApps(data: any[], params: QueryParams): any[] {
    let result = [...data];

    if (params.filter) {
      result = this.applyFilters(result, params.filter);
    }

    if (params.sort) {
      result = this.applySorting(result, params.sort);
    }

    if (params.page) {
      result = this.applyPagination(result, params.page);
    }

    return result;
  }



}
