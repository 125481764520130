import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SMSTokenUtil } from './SMSTokenUtil';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(private http: HttpClient) { }

  authenticatedUser: any = {};
  planMap:any = null;

  pricingUrl:any = environment.SMS_PORTAL_URL + "pricing/plan";

  qoutaMap = {
    "Basic": {
      "APP": 2,
      "CONNECTION": 3,
      "BLOOM": 3,
      "FLOW": 3,
      "STARCH": 3
    },
    "Lite": {
      "APP": 3,
      "CONNECTION": 5,
      "BLOOM": 5,
      "FLOW": 5,
      "STARCH": 5
    },
    "Pro": {
      "APP": 10,
      "CONNECTION": 20,
      "BLOOM": 20,
      "FLOW": 20,
      "STARCH": 20
    },
    "Premium": {
      "APP": 20,
      "CONNECTION": 40,
      "BLOOM": 40,
      "FLOW": 40,
      "STARCH": 40
    },
    "Business": {
      "APP": 50,
      "CONNECTION": 100,
      "BLOOM": 100,
      "FLOW": 100,
      "STARCH": 100
    },
    "Enterprise": {
      "APP": -1,
      "CONNECTION": 500,
      "BLOOM": 1000,
      "FLOW": 1000,
      "STARCH": 1000
    }
  }

  // Hyperlist quota map for different subscription plans
  readonly hyperlistQuotaMap = {
    "Basic": {
      "interaction": 25,
      "dataunit": 2500
    },
    "Pro": {
      "interaction": 1000,
      "dataunit": 100000
    },
    "Premium": {
      "interaction": 5000,
      "dataunit": 500000
    },
    "Business": {
      "interaction": 20000,
      "dataunit": 2000000
    }
  };
  

  /**
   * This method authenticates a user for a given platform, retrieves their associated
   * usage metrics, and processes the quota map based on the user's plan. The method
   * stores the generated quota map and the authenticated user details for further use.
  */
  async getSMSAuthentication(userProfile: any, product: string = "") {
    const authenticatedUser: any = await this.authenticateUser(userProfile, product);
    
    let usedMap: any = {};
  
    if (product === 'HYPERLIST') {
      const { metric: usedMapFromHyperlist, plan } = authenticatedUser;
      usedMap = usedMapFromHyperlist;
      const quotaMap = this.convertToObjectWithLimitAndUsed(this.hyperlistQuotaMap[plan], usedMap);
      this.planMap = quotaMap;
    } else {
      let connectionMap = await this.getConnectionsOfEmail(authenticatedUser.email, null, null, userProfile);
      let boomMap = await this.getBloomsOfEmail(authenticatedUser.email, null, null, userProfile);
      let flowMap = await this.getFlowsOfEmail(authenticatedUser.email, null, null, userProfile);
      let baseMap = await this.getBasesOfEmail(authenticatedUser.email, null, null, userProfile);
  
      usedMap = {
        "APP": 50,
        "CONNECTION": connectionMap.totalCount || 0,
        "BLOOM": boomMap.totalCount || 0,
        "FLOW": flowMap.totalCount || 0,
        "STARCH": baseMap.totalCount || 0
      };
  
      const quotaMap = this.convertToObjectWithLimitAndUsed(this.qoutaMap[authenticatedUser.plan], usedMap);
      this.planMap = quotaMap;
    }
  
    authenticatedUser.qoutaMap = this.planMap;
    this.authenticatedUser = authenticatedUser;
  }
  

  checkEligibility(type: string, product: string = "") {
    var result = {
      isEligible: true,
      errorMap: {}
    };

    if (product === 'HYPERLIST') {
      return this.checkHyperlistEligibility(result);
    } else {
      return this.checkServiceEligibility(type, result);
    }
  }
  
  /**
   * This function checks if the user is eligible for the HYPERLIST service.
   * It checks if the user has exceeded their interaction and dataunit limits based on their current plan.
   */
  checkHyperlistEligibility(result:any) {
    const { interaction, dataunit } = this.authenticatedUser?.metric || {};
    const interactionCount = interaction?.count || 0;
    const dataunitCount = dataunit?.count || 0;
  
    const userPlan = this.authenticatedUser?.plan || 'Basic';
    const planLimits = this.hyperlistQuotaMap[userPlan] || this.hyperlistQuotaMap['Basic'];
  
    const exceededInteractionLimit = interactionCount > planLimits.interaction;
    const exceededDataunitLimit = dataunitCount > planLimits.dataunit;
  
    if (exceededInteractionLimit || exceededDataunitLimit) {
      result.isEligible = false;
      result.errorMap.message = "Quota limit for the HYPERLIST fetch records service has been reached.";
      result.errorMap.details = "You have exceeded the maximum allowed usage for HYPERLIST. Please upgrade your plan or contact support for assistance.";
      result.errorMap.plan_details = {
        plan: this.authenticatedUser.plan,
        upgrade_url: `${this.pricingUrl}/hyperlist`
      };
    }
  
    return result;
  }

  
  
  /**
   * This function checks if the user is eligible for a specific service type other than "HYPERLIST".
   * It checks if the user has exceeded the usage limits for that service based on their plan.
   */
  checkServiceEligibility(type: string, result:any) {

    // Check if the planMap exists and has the expected service type with usage exceeding the limit
    if (this.planMap?.[type] && this.planMap[type].limit <= this.planMap[type].used) {

      result.isEligible = false;
      result.errorMap.message = "Quota limit for the service has been reached.";
      result.errorMap.details = `You have exceeded the maximum allowed usage for ${type}. Please upgrade your plan or contact support for assistance.`;
      result.errorMap.plan_details = {
        plan: this.authenticatedUser.plan,
        upgrade_url: `${this.pricingUrl}/appiworks`
      };

    }
    return result;
  }

    // Function to check eligibility based on metric and userQuota objects
    // static checkEligibility(metric?: any, userQuota?: any): any {
    //   if(!metric && this.authenticatedUser) metric = this.authenticatedUser.metric || {};
    //   if(!userQuota) userQuota = this.qoutaMap[this.authenticatedUser.plan || "Premium"] || {};

    //   let result:any = {
    //     isEligible:  true,
    //     errorMap: {}
    //   }
    //   // Loop through each key in the userQuota object
    //   for (const key in userQuota) {
    //     if (userQuota.hasOwnProperty(key)) {
    //       if (metric.hasOwnProperty(key)) {
    //         if (metric?.[key]?.count > userQuota?.[key]) {
    //           result.isEligible = false;
    //           result.errorMap.message = "Quota limit for the service has been reached.";
    //           result.errorMap.details = `You have exceeded the maximum allowed usage for ${key}. Please upgrade your plan or contact support for assistance.`;
    //           result.errorMap.quota_type = key;
    //           result.errorMap["current_usage"] = metric?.[key]?.count,
    //           result.errorMap["max_quota"] = userQuota?.[key],
    //           result.errorMap.plan_details = {
    //             plan: this.authenticatedUser.plan,
    //             "upgrade_url": ""
    //           }
    //           break;
    //         }
    //       } else {
    //         // If the key exists in userQuota but not in metric, return false
    //         result.isEligible = true;
    //       }
    //     }
    //   }

    //   // If all keys in userQuota have corresponding metric values that are less than or equal, return true
    //   return result;
    // }

  convertToObjectWithLimitAndUsed(data, usedMap) {
    const result = {};
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const limitValue = data[key];
        const usedValue = usedMap[key] || 0;
        result[key] = { limit: limitValue, used: usedValue };
      }
    }
    return result;
  }

  async getConnectionsOfEmail(email, page: number = 1, limit: number = 500, userProfile?: any) {
    let that = this;
    let url = `${environment.SERVER_BASE_URL}/connection/connections/owner/email/${email}?page=${page}&limit=${limit}`;

    const headers = {
      'Authorization': 'Basic ' + userProfile.user_api_key
    };
    let res: any = await that.http.get(url, { headers }).toPromise();
    return res;
  }

  async getBloomsOfEmail(email, page: number = 1, limit: number = 500, userProfile?: any) {
    let that = this;
    let url = `${environment.SERVER_BASE_URL}/bloom/blooms/owner/email/${email}?page=${page}&limit=${limit}`;

    const headers = {
      'Authorization': 'Basic ' + userProfile.user_api_key
    };
    let res: any = await that.http.get(url, { headers }).toPromise();
    return res;
  }

  async getFlowsOfEmail(email, page: number = 1, limit: number = 500, userProfile?: any) {
    let that = this;
    let url = `${environment.SERVER_BASE_URL}/flow/flows/owner/email/${email}?page=${page}&limit=${limit}`;

    const headers = {
      'Authorization': 'Basic ' + userProfile.user_api_key
    };
    let res: any = await that.http.get(url, { headers }).toPromise();
    return res;
  }

  async getBasesOfEmail(email, page: number = 1, limit: number = 500, userProfile?: any) {
    let that = this;
    let url = `${environment.SERVER_BASE_URL}/starch/bases/owner/email/${email}?page=${page}&limit=${limit}`;

    const headers = {
      'Authorization': 'Basic ' + userProfile.user_api_key
    };
    let res: any = await that.http.get(url, { headers }).toPromise();
    return res;
  }

    /*
  Authenticates User brings back product user and subscripton information.
  If user is not there, it will get created in SMS
  @param user - object with email, firstName, lastName, photoUrl
  @param product - string product id
  @param options - {object} multiple_metric(boolean)
  @returns user object with email, firstName, lastName, photoUrl, plan, validUpto, metric, subscription object, metricHistory
  */
   async authenticateUser(user:any, product:string, options:any = {}) {

    // fall back to "APPIWORKS"
    product = product || "APPIWORKS";

    var userObj = {};
    var result:any ={};
    

    //console.log("authenticateUser",user)
    var userMap: any = {
      email : user.email,
      first_name : user["first_name"] || null,
      last_name : user["last_name"] || null,
      photo : user.image_url || null,
      product : product
    }
    if(options && options.multiple_metric) userMap.multiple_metric = true;

    var url = "productuser/authenticate"
    try{
      result = await this.fetchDataFromUrls(url, userMap, 'post');
      //console.log("[authenticateUser]response from sms url:", result)
    } catch(error){
      console.warn("error in getting authentication")
      var currentDate = new Date()
      var tempValidUpto = currentDate.setDate(currentDate.getDate() + 1);
      var tempDate = new Date(tempValidUpto).toISOString();
      result = {
        email : userMap.email,
        first_name : "Default user",
        subscription : {
          plan_type : "Premium",
          valid_upto: tempDate
        }
      }
    }
    userObj = this.createAuthenticateUserMap(result);
    console.log("userObj",userObj);
    return userObj;
  }

    /*
  create user map from authenticate api response
  @param response
  */
  createAuthenticateUserMap(response){

    var result: any = {
      "email" : response.email,
      "firstName" : response.first_name,
      "lastName" : response.last_name || "",
      "photoUrl" : response.photo,
      "product" : response.product
    };

    result.metric = (response.metric) ? response.metric : null;
    result.metricHistory = (response.metric_history) ? response.metric_history : null;
    var subscription = (response.subscription) ? response.subscription : null;
    if(subscription){
      if(subscription.id) delete subscription.id;
      result.plan =  subscription.plan_type;
      result.validUpto = subscription.valid_upto;
    } else result.plan = 'Basic';

    result.subscription = subscription;
    return result;
  }



  async handelSubscription(subscription:any, plan:any, options?: any){
    let result:any;
    let body = {
      subscriberObj: subscription,
      planData: plan,
      options: options || {}
    }
    try{
      var url = "paymentgateway/handle-subscription"
      result = await this.fetchDataFromUrls(url, body, 'post');
      console.log("[subscription] hande response from sms url:", result)
    } catch(error){
      console.warn("error in handling subscription")

    }
  }


    /*
  fetch data from multiple urls
  If 1st url fails call with next url
  @param urlPath url path
  @param obj payload obj
  @param method rest call method
  */
  async fetchDataFromUrls(urlPath, obj, method){
    let that = this;
    // var config = getSMSConfig();
    var urls = environment.SMS_BASE_URLS;
    obj.key = environment.SMS_AUTH_KEY;
    var result = null;
    for(var i = 0; i < urls.length; i++){
      var url = urls[i] + urlPath;
      try{
        console.log("fetch data from url: ", url)
        result = await that.fetchDataFromUrl(url, obj, method);
        if(result) break; // break the loop on success call
      } catch(err){
        console.error("Error in fetch data for url %s and %s error", url, err);
        if(i == (urls.length - 1)) throw err; //throw error if last call fails
      }
    }
    return result;
  }

    /*
  fetch data from given url
  @param url url
  @param obj object
  @param method
  */
  async fetchDataFromUrl(url, obj, method){
    var result = null;
    const token = SMSTokenUtil.getStatelessToken();
    const headers = {
        Authorization: `Bearer ${token}`
    };
    if(method == "put"){
      var promise = this.http.put(url, obj, {headers}).toPromise();
    } else {
      var promise = this.http.post(url, obj, {headers}).toPromise();
    }
    await promise.then((data: any)=>{
      //console.log("[fetchDataFromUrl] Promise resolved with data:", data);
      result = data;
    }).catch((error)=>{
      console.log("error in getting data for url %s and error %s", url, error)
      throw error;
    });
    return result;
  }

}
