import { SharedModule } from './../shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GooglepickerComponent } from './googlepicker.component';

@NgModule({
  declarations: [GooglepickerComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [GooglepickerComponent]
})
export class GooglepickerModule { }
