import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, Inject, LOCALE_ID } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { formatDate } from '@angular/common';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css']
})
export class DateComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: {};
  @Output() dateReceived: EventEmitter<any> = new EventEmitter();
  setDateformat: any;
  value = "";
  hoveredNow: boolean = false;
  displayValue: any;
  oldMeta: any
  private destroy:any = new Subject();

  @ViewChild('menuTrigger', { static: false }) dateMenuTrigger: MatMenuTrigger

  constructor(
    public metaService: MetaService, 
    @Inject(LOCALE_ID)
    private locale: string,
    public themeService: ThemeService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService,
    public expressionUtility: ExpressionUtility,
  ) {
    super(metaService, pageService, resourcePermissionService)
    console.log("in date constructor", this.widgetMeta)
  }

  ngOnInit(): void {

    super.ngOnInit()
    console.log("in date onInit", this.widgetMeta)
    this.initValue()
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
        this.initValue()
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.dateMenuTrigger && this.dateMenuTrigger.menuOpen) {
        this.dateMenuTrigger.closeMenu();
      }
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
      this.initValue()
    }
  }
  ngDoCheck(): void {
    // console.log("do check fired: old", JSON.parse(JSON.stringify(this.oldMeta || {})), "new", JSON.parse(JSON.stringify(this.widgetMeta || {})))
    if((!this.oldMeta && this.widgetMeta) || (JSON.stringify(this.oldMeta) != JSON.stringify(this.widgetMeta))){
      // console.log("change detected in widgetMeta")
      this.oldMeta = JSON.parse(JSON.stringify(this.widgetMeta))
      this.initValue()
    }
  }


  //----------------------------------- FUNCTIONS ---------------------------------------

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "appearance",
        "edit",
      ],
      appearance: {
        value: this.widgetMeta?.config?.appearance?.value,
        type: this.widgetMeta?.config?.appearance?.type
      },
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event: any) {
    console.log("action received", event)
    if (event.actionType == "delete") {
      this.onDelete()
    }
  }

  onClick() {
    if (!this.builderMode) {
      return;
    }

    console.log("date widget clicked in widget")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.dateMenuTrigger.closeMenu();
  }

  inputDetected(event: any) {
    console.log("date change detected", event.value)
    this.value = event.value
    this.emitOutput()
    // let userInput: any = {
    //   dataBindConfig: this.widgetMeta?.dataBindConfig,
    //   widgetId: this.widgetMeta.id,
    //   value: `${new Date(event.value).toISOString()}|date`
    // }
    // if (this.value !== '') {
    //   this.userInputReceived.emit(userInput);
    // }

    // if(this.value !== ''){
    //   this.dateReceived.emit(dateInput)
    // }
  }

  initValue(){
    console.log("initValue: widgetMeta", JSON.parse(JSON.stringify(this.widgetMeta)))
    let timeZone = this.themeService.currentLocale.timeZone;
    this.setDateformat = this.themeService.currentLocale.dateFormat
    // this.setTimeformat = this.themeService.currentLocale.timeFormat

    if(!this.setDateformat) this.setDateformat = "MMM-dd-yyyy";
    // if(!this.setTimeformat) this.setTimeformat = "hh:mm a";

    if (this.widgetMeta.config?.expressionConfig?.id == '__currentdatetime__') {
      this.value = this.expressionUtility.resolveExpression(this.widgetMeta.config?.expressionConfig);
    } else {
      this.value = JSON.parse(JSON.stringify(this.widgetMeta?.config?.dateValue?.value || ""));
      if(this.value?.endsWith("|date")) this.value = this.value.slice(0, -5);    // discard the '|date' if exists
      this.value = this.value.substring(0, 16)  // discard the seconds and miliseconds from iso date string
    }
    console.log("value", this.value)
    
    if (this.widgetMeta.config.viewOnly.value && this.value) {
      if(!this.builderMode) {
        let isoDate = new Date(this.value).toISOString()
        let datePart;
        datePart = formatDate(isoDate, this.setDateformat, this.locale, timeZone)
        this.displayValue = datePart 
      } else {
        this.displayValue = this.formatDateForDateInput(new Date(this.value).toISOString())
      }
    } else {
      this.value = new Date().toISOString()
      this.displayValue = this.formatDateForDateInput(new Date(this.value).toISOString())
      this.emitOutput()
    }
    // console.log("this.displayValue", this.displayValue)
    return this.displayValue
  }

  formatDateForDateInput(inputDate: string): string {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  emitOutput(){
    console.log("hit emitOutput: value", this.value)
    if(!this.value) return
    let dateInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: ``
    }
    if(this.widgetMeta.config.customOutputFormat?.enabled){
      let isoDate = new Date(this.value).toISOString()
      console.log("ISO date", isoDate)
      let formattedDate = formatDate(isoDate, this.widgetMeta.config.customOutputFormat.customDateFormat, this.locale)
      dateInput.value = formattedDate
      console.log("formatted date is", formattedDate)
    }else{
      console.log("no custom output format, will emit ISO")
      dateInput.value = `${new Date(this.value).toISOString()}|date`
    }
    console.log("emitting data", dateInput)
    this.userInputReceived.emit(dateInput);
  }

}
