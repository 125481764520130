<spinner  size="la-1x" [selfSpin]="true" center="true" *ngIf="spinner" style="margin: auto auto;"></spinner>

<div class="wrap" *ngIf="fs.flowCreateConfiguration?.creationMode == 'all'">
  <h1 class="heading">Create New Flow</h1>
  <div>
    <div fxFlex="10"></div>
    <div fxFlex="80" fxLayoutAlign="start">How do you want to create a new flow?</div>
    <div fxFlex="10"></div>
  </div>
  <div style="height: 1rem;"></div>
  <div *ngIf="isReady && authService.loggedIn" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="10"></div>
    <div fxFlex="80">
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div  fxFlex="30" fxLayout="column">
          <mat-card  fxLayout="column" appearance="outlined" (click)="createBlank()"
          class="listcard" matTooltip="Create Blank Flow" fxLayoutAlign="center center"
          [ngClass]="isBlankSelected ? 'listcard-1-selected' : ''">
            <mat-card-content>
              <span fxLayout="row" class="subheading-1"> Blank Flow </span>
              <div  fxLayoutAlign="center center" fxLayout="row"  >
                <mat-icon class="createIcon" color="primary">add</mat-icon>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="5"></div>
        <div fxFlex="30" fxLayout="column">
          <mat-card appearance="outlined" (click)="createFromTemplate()"
          class="listcard" matTooltip="Create Form from template" fxLayoutAlign="center center"
          [ngClass]="isTemplateSelected ? 'listcard-1-selected' : ''">
          <mat-card-content>
            <span fxLayout="row" class="display-1"> From Template </span>
            <div fxLayoutAlign="center center" fxLayout="row">
              <mat-icon class="createIcon" color="primary">gamepad</mat-icon>
            </div>
          </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <div fxFlex="10"></div>
  </div>
  <div style="height: 1rem;"></div>
</div>

<div fxLayout="row" *ngIf="isTemplateSelected">
  <div fxLayout="column" fxFlex="10"></div>
  <div fxLayout="column" fxFlex="80"> <mat-divider></mat-divider></div>
  <div fxLayout="column" fxFlex="10"></div>
</div>


<div fxLayout="row" *ngIf="isTemplateSelected">
  <div fxLayout="column" fxFlex="10"></div>
  <div fxLayout="column" fxFlex="80">
    <mat-stepper orientation="vertical" [linear]="true" #stepper (selectionChange)="onStepChange($event)">
      <mat-step step1 [completed]="stepperOptions.completed1">
        <ng-template matStepLabel>Choose Template</ng-template>
        <ng-template matStepContent>
          <div>
            <mat-card class="stepperCard">
              <app-search-panel *ngIf="templateSearchPanel"
                style="width: 100%;margin-top: -25px;"
                [builderMode]="false"
                [panelMeta]="templateSearchPanel"
              >
              </app-search-panel>
              <app-list-panel *ngIf="templatePanel"
                #bloomListPanel
                style="width: 100%;margin-top: -55px;"
                [builderMode]="false"
                [panelMeta]="templatePanel"
                (rowData)="selectedRowData($event)"
              >
              </app-list-panel>
            </mat-card>
            <div style="height: 8px"></div>
          </div>

          <button mat-raised-button [disabled]="!stepperOptions.step2" color="primary" mat-button matStepperNext>Next</button>
        </ng-template>
      </mat-step>
      <mat-step [completed]="stepperOptions.completed2" [editable]="stepperOptions.step2" step2>
        <ng-template matStepLabel>Connect with Apps</ng-template>
        <ng-template matStepContent>
          <div *ngIf="templateSteps">
            <mat-card class="stepperCard">
            <app-flow-template #flowtemplateComponent [templateSteps]="templateSteps" (actionMapped)="actionMapped($event)"></app-flow-template>
            </mat-card>
            <div style="height: 8px"></div>
          </div>
          <button mat-button color="primary" matStepperPrevious>Back</button>
          <button mat-raised-button color="primary" matStepperNext [disabled]="!stepperOptions.step3" (click)="this.stepper.next();">Next</button>
        </ng-template>
      </mat-step>
      <mat-step [editable]="stepperOptions.step3" step3>
        <ng-template matStepLabel>Finalize {{stepperOptions.step2 ? " - Flow configuration": ''}} </ng-template>
        <div *ngIf="templateUnderPreview">
          <mat-card class="stepperCard">
          <app-create-flow-detail #formdetailComponent
          [input]="{name: templateUnderPreview.name, title: 'Flow configuration'}" [hideHeader]="true" (nameChanged)="templateNameChanged($event)"></app-create-flow-detail>
          </mat-card>
          <div style="height: 8px"></div>
        </div>
        <button mat-button color="primary" matStepperPrevious>Back</button>
        <button mat-raised-button color="primary" (click)="createTemplateFlow()">Create Flow</button>
      </mat-step>
    </mat-stepper>
  </div>
  <div fxLayout="column" fxFlex="10"></div>
</div>
