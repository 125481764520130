import { Button } from "./widgetClasses/button";
import { TextInput } from "./widgetClasses/input";
import { Label } from "./widgetClasses/label";
import { Paragraph } from "./widgetClasses/paragraph";
import { TextArea } from "./widgetClasses/textarea";
import { Image } from "./widgetClasses/image";
import { Autocomplete } from "./widgetClasses/autocomplete";
import { Card } from "./widgetClasses/card";
import { Checkbox } from "./widgetClasses/checkbox";
import { Chips } from "./widgetClasses/chips";
import { DateInput } from "./widgetClasses/date";
import { DateTime } from "./widgetClasses/datetime";
import { Duration } from "./widgetClasses/duration";
import { Embed } from "./widgetClasses/embed";
import { Icon } from "./widgetClasses/icon";
import { ImageInput } from "./widgetClasses/imageinput";
import { Link } from "./widgetClasses/link";
import { NumberInput } from "./widgetClasses/numberinput";
import { Period } from "./widgetClasses/period";
import { RichTextInput } from "./widgetClasses/richText";
import { Select } from "./widgetClasses/select";
import { Separator } from "./widgetClasses/separator";
import { Slider } from "./widgetClasses/slider";
import { Time } from "./widgetClasses/time";
import { Table } from "./widgetClasses/table";
import { Choice } from "./widgetClasses/choice";
import { Star } from "./widgetClasses/star";
import { Tags } from "./widgetClasses/tags";
import { Chart } from "./widgetClasses/chart"
import { Space } from "./widgetClasses/space";
import { Connection } from "./widgetClasses/connection";
import { ConnectionList } from "./widgetClasses/connection-list";
import { Login } from "./widgetClasses/login";

export class WidgetRegistry {
  constructor(){}
  static registry: any = {
      "label": function (id?: any, name?:any) {
          return new Label(id, name);
      },
      "button": function (id?: any, name?:any) {
          return new Button(id, name);
      },
      "input": function (id?: any, name?: any) {
          return new TextInput(id, name)
      },
      "textarea": function(id?: any, name?: any){
          return new TextArea(id, name)
      },
      "para": function(id?: any, name?: any){
          return new Paragraph(id, name)
      },
      "image": function(id?: any, name?: any){
          return new Image(id, name)
      },
      "autocomplete": function(id?: any, name?: any){
          return new Autocomplete(id, name)
      },
      "card": function(id?: any, name?: any){
          return new Card(id, name)
      },
      "checkbox": function(id?: any, name?: any){
          return new Checkbox(id, name)
      },
      "choice": function(id?: any, name?: any){
          return new Choice(id, name)
      },
      "chips": function(id?: any, name?: any){
          return new Chips(id, name)
      },
      "date": function(id?: any, name?: any){
          return new DateInput(id, name)
      },
      "datetime": function(id?: any, name?: any){
          return new DateTime(id, name)
      },
      "duration": function(id?: any, name?: any){
          return new Duration(id, name)
      },
      "embed": function(id?: any, name?: any){
          return new Embed(id, name)
      },
      "icon": function(id?: any, name?: any){
          return new Icon(id, name)
      },
      "imageinput": function(id?: any, name?: any){
          return new ImageInput(id, name)
      },
      "link": function(id?: any, name?: any){
          return new Link(id, name)
      },
      "numberinput": function(id?: any, name?: any){
          return new NumberInput(id, name)
      },
      "period": function(id?: any, name?: any){
          return new Period(id, name)
      },
      "richtext": function(id?: any, name?: any){
          return new RichTextInput(id, name)
      },
      "select": function(id?: any, name?: any){
          return new Select(id, name)
      },
      "separator": function(id?: any, name?: any){
          return new Separator(id, name)
      },
      "slider": function(id?: any, name?: any){
          return new Slider(id, name)
      },
      "star": function(id?: any, name?: any){
          return new Star(id, name)
      },
      "table": function(id?: any, name?: any){
          return new Table(id, name)
      },
      "time": function(id?: any, name?: any){
          return new Time(id, name)
      },
      "tags": function(id?: any, name?: any){
        return new Tags(id, name)
      },
      "chart": function(id?: any, name?: any, chartType?: string){
        return new Chart(id, name, chartType)
      },
      "space": function(id?: any, name?: any){
        return new Space(id, name)
      },
      "connection": function(id?: any, name?: any){
        return new Connection(id, name)
      },
      "connection-list": function(id?: any, name?: any){
        return new ConnectionList(id, name)
      },
      "login": function(id?: any, name?: any){
        return new Login(id, name)
      },

  }
  static getWidget(type: string, id?: any, name?:any, options?: any) {
    // console.log("get widget hit---", type)
    if(!type) throw "widget type not provided:" + type;
    let subType = ''
    if(type.split('|').length > 1) {
      subType = type.split('|')[1]
      type = type.split('|')[0]
    }
    id = id ? id : Date.now().toString() + Math.random().toString(16).slice(8)
    name = name ? name : type + '_0'
    let widget = this.registry[type];
    if (!widget) throw new Error("No widget available: " + type);
    // console.log('[REGISTRY] raw widget', widget)
    // console.log('[REGISTRY] raw widget', JSON.parse(JSON.stringify(widget)))
    let widgetObj = widget(id, name, subType)
    // console.log("[ERGISTRY] widgetObj", JSON.parse(JSON.stringify(widgetObj)))
    return widgetObj;
  }
}
