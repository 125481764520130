import { AfterViewInit, Component, ViewChild, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, ViewChildren, Inject, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BoxService } from 'src/app/bloom/services/box-service.service'
import { MatSnackBar } from '@angular/material/snack-bar';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';
import { WIDGET_OPTIONS, WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListPanelService } from '../list-panel.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ApplicationAction } from 'src/app/bloom/models/Action/ApplicationAction';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { PopupEditViewComponent } from 'src/app/shared/popup-edit-view/popup-edit-view.component';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { SkeletonElement } from 'src/app/shared/spinner/skeleton/skeleton.component';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { ResourceDeletionService } from 'src/app/shared/services/resource-deletion.service';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent implements OnInit, AfterViewInit, OnChanges {
  DEFAULT_IMAGE_SIZE: number = 5
  MAX_IMAGE_SIZE: number = 20
  MIN_IMAGE_SIZE: number = 4
  imageResizeStep: number = 1

  widgetContextMenuActions: any = {}

  @Input() panelMeta: any;
  @Input() processedData: any;
  @Input() displayedColumns: any = ['loading'];   //give a default value and wait for input
  @Input() totalCount: any = 0
  @Input() pageSize: any = 5
  @Input() pageNumber: any = 1
  @Input() runSpinner: any = false
  // @Input() loadingData: any = false
  @Input() error: any;
  @Input() builderMode: any;
  @Input() viewType: any;
  @Input() rawBoxData: any;
  @Input() availableAttributes:any;
  @Output() raisePageEvent = new EventEmitter()
  @Output() metaChange = new EventEmitter()

  @Output() subPanel = new EventEmitter()
  @Output() columnSelectionChanged = new EventEmitter();
  @Output() customOrderChanged = new EventEmitter();
  @Output() selectedRowData = new EventEmitter();
  @Output() checkboxSelected = new EventEmitter();

  @Input() isSubPanel: boolean;
  @Input() isInputData:boolean;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChildren('cell') cells;
  @ViewChildren('dataCard') dataCard;
  @ViewChildren('imageWidgetWrap') imageWidgetWrap;

  selectedAttributes:any = []//new FormControl('');
  // skeletonSpinnerGridMap: SkeletonElement[] = []
  dataSource: any = [];
  dataLength: any = 0;
  gotResponse: boolean = false
  defaultPageSize: number = 5
  defaultPageNumber: number = 1;
  dataBindConfigResult: any;

  tableData: any = undefined;
  selectedRows = {};
  widgetMetaMap: any
  attributeIds: string[] = []
  columnData: any[] = []
  colWidth: number;
  navMap: any = {};
  bloomCode: string;
  pageCode: string;
  isFragment: boolean = false;
  fragment: string;
  errorOccurred: boolean = false;
  ready: boolean = false;

  groupValues: string[] = []
  groupedData = {
    groupValues: this.groupValues
  }
  currentViewType: string
  newViewType: string
  maxImageHeight: number
  widgetOptions: WIDGET_OPTIONS = {
    dragDrop: false,
    textEdit: false,
    deleteEnabled: false,
    configurationEnabled: true,
    widgetSelectionEnabled: false,
    cardImage: true
  }
  contextChangedSub:any
  prevContextWidgetId:any
  @ViewChild('menuTrigger') contextMenuTrigger: MatMenuTrigger

  mouseOverFullText: string = ""
  isBrowser: any;
  screenWidth: number;
  getPageMetaSubscription: any
  // pageMeta: any

  cardSizeMap: any = {
    xs: { large: "75", medium: "40", small: "40"}, // 0 - 600
    sm: { large: "40", medium: "30", small: "20"}, // 600 - 900
    md: { large: "30", medium: "28", small: "20"}, // 900 - 1200
    lg: { large: "23", medium: "15", small: "10"}, // 1200 - 1536
    xl: { large: "15", medium: "10", small: "8"}, // > 1536
  }

  
  /**
   * queue of records deleted locally, waiting for success response from server
   */
  inProgressDeletedRecords: any[] = [] 

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  // displayedColumns = ["loading"];

  constructor(
    private metaService: MetaService,
    public pageService: PageService,
    private route: ActivatedRoute,
    private router: Router,
    private boxService: BoxService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public listPanelService: ListPanelService,
    public applicationAction: ApplicationAction,
    public automationService: AutomationService,
    private resourceDeletionService: ResourceDeletionService,
    private cdr: ChangeDetectorRef,
    // public dialogRef: MatDialogRef<NavigateConfirmationComponent>
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    }

  raiseContextMenuActions(event, rowIndex, attrId){
    // console.log("event", event, "rowIndex", rowIndex, "attrId", attrId)
    if(!this.widgetContextMenuActions[rowIndex]) this.widgetContextMenuActions[rowIndex] = {}
    this.widgetContextMenuActions[rowIndex][attrId] = event;
    // console.log("raiseContextMenuActions[rowIndex][attrId]", this.widgetContextMenuActions[rowIndex][attrId])
    // console.log("raiseContextMenuActions", this.widgetContextMenuActions)
  }


  ngOnChanges(changes: SimpleChanges) {
    console.log("[DATA TABLE] ngOnChanges() changes", changes);
    if(changes.rawBoxData && changes.rawBoxData.currentValue){
      console.log("rawBoxData received in dataTable", this.rawBoxData)
    }

    if(changes.processedData && changes.processedData.currentValue){
      this.processedDataChanged(changes.processedData.currentValue)
    }
    if(changes.error && changes.error.currentValue){
      this.errorOccurred = true
      this.cdr.detectChanges();
    }else{
      this.errorOccurred = false
    }

    /**
     * check if viewType has changed, this case occurs when user re-configures the list panel
     * and changes the default view type
    */
    if(changes.panelMeta && changes.panelMeta.currentValue){
      let newMeta = changes.panelMeta['currentValue']
      // console.log("[data-table onChanges panelMeta changed", newMeta)
      // console.log("[data-table onChanges processed data", this.processedData)
      this.newViewType = newMeta?.viewTypes?.defaultView || "table"
    }

    /**
     * check if viewType changes from toggle button
     */
    if(changes.viewType && changes.viewType.currentValue){
      // console.log("[data-table onChanges] view type changed from toggle", changes.viewType)
      this.newViewType = changes.viewType.currentValue

      if(this.newViewType !== this.currentViewType){
        this.currentViewType = this.newViewType;
        this.decideViewTypeForScreenSize();
      }

      if(this.currentViewType == 'board'){
        this.widgetMetaMap = {
          groupValues: []
        }
        this.groupDataForBoard(this.tableData, this.panelMeta)
        this.createWidgetMetaMap(this.currentViewType)
      }else{
        this.widgetMetaMap = []
        this.createWidgetMetaMap(this.currentViewType)
      }
    }

    if(!this.currentViewType) {
      this.currentViewType = this.panelMeta.viewTypes?.defaultView || "table";
      // console.log("view type set to", this.currentViewType)
    }
    
    this.decideViewTypeForScreenSize();
    if(!this.widgetMetaMap) {
      // console.log("calling from onChanges() outside; no widget meta map now")
      this.createWidgetMetaMap(this.currentViewType)
    }
    this.createNavigationMap()
  }

  processedDataChanged(newProcessedData: any[]){
    this.tableData = newProcessedData
    console.log(this.tableData)
    console.log("[DATA TABLE]: processedData changed", this.tableData, "calling createWidgetMetaMap")

    this.generateAttributeIds()

    this.tableInit()

    this.groupDataForBoard(this.tableData, this.panelMeta)
    this.createWidgetMetaMap(this.currentViewType)
  }

  onColumnsSelected(e){
    console.log("inn", this.selectedAttributes);
    this.panelMeta.listAttributes = this.selectedAttributes
    this.columnSelectionChanged.emit(this.panelMeta)
  }

  customOrder(attribute, order){
    attribute.order = order;
    console.log("attribute", attribute)
    this.customOrderChanged.emit(attribute)
  }

  ngOnInit(){
    // this.dialogRef.updatePosition({
    //   top: '100px',
    //   right: '100px'
    // })
    // console.log("init data table", this.panelMeta)
    // var exisitngAttributes = this.panelMeta.listAttributes;
    // this.selectedAttributes = exisitngAttributes;
    // console.log("this.selectedAttributes ", this.selectedAttributes )
    // console.log("availableAttributes", this.availableAttributes)

    // for (let i = 0; i < 40; i++) {
    //   if (i < 8) this.skeletonSpinnerGridMap.push({ cols: 3, rows: 2, shape: "rectangle"})
    //   else this.skeletonSpinnerGridMap.push({ cols: 3, rows: 1, shape: "rectangle"})
    // }

    // this.getPageMetaSubscription = this.metaService.pageMeta.subscribe((meta) => {
    //   console.log("page meta subscription in data-table", JSON.parse(JSON.stringify(meta || "")))
    //   if (this.pageService.currentPageCode.value !== meta.code) return
    //   this.pageMeta = meta
    // })

    // this.widgetOptions = {
    //   dragDrop: false,
    //   textEdit: false,
    //   deleteEnabled: false,
    //   configurationEnabled: true,
    //   widgetSelectionEnabled: false,
    //   cardImage: true
    // }
    if(!this.isBrowser) return;

    this.createNavigationMap()
    this.screenWidth = window.innerWidth;

    if(this.isSubPanel){
      this.generateAttributeIds()

      this.tableInit()

      this.createWidgetMetaMap(this.currentViewType);
      this.newViewType = this.panelMeta.viewTypes.defaultView;
      this.currentViewType = this.newViewType
      this.decideViewTypeForScreenSize();
    }

    if(!this.isInputData){
      this.bloomCode = this.router?.url?.split('/')?.[2]
      this.pageCode = this.router?.url?.split('/')?.[3]?.split('#')?.[0]
    }


    if(!this.isInputData && this.router?.url?.split('/')[1] == 'bloom'){
      this.route.fragment.subscribe(fragment => {
        if(fragment){
          this.isFragment = true
          this.fragment = fragment
          console.log("fragment detected", fragment)
        }
      })
    }else{
      this.isFragment = false;
    }
  }

  getCurrentViewType(){
    if(this.screenWidth < 440) {
      return "card";
    } else return this.currentViewType || this.panelMeta.viewTypes?.defaultView || "table";
  }

  contextChanged(contextActions){
    console.log("context changed", contextActions)
    for(var key in this.panelMeta.listWidgetSet){
      console.log("key", key)
      let widget = this.panelMeta.listWidgetSet[key];
      console.log("proto widget is", widget)
      if(widget.id == contextActions.widgetId){
        console.log("id matched")
        this.updateWidgetPrototype(widget, key, contextActions['propertyName'] || '')
        // this.createWidgetMetaMap(this.currentViewType)
      }
    }
  }

  menuOpened(widget){
    console.log("opend", widget);
    console.log("opend id", this.prevContextWidgetId, widget.id);
    if(this.prevContextWidgetId && this.prevContextWidgetId !== widget.id){
      if (this.contextMenuTrigger && this.contextMenuTrigger.menuOpen) {
        this.contextMenuTrigger.closeMenu()
      }
    }
    // this.metaChange.emit(this.panelMeta)

    this.prevContextWidgetId = widget.id;
  }

  ngDoCheck(){

  }

  ngAfterViewInit(): void {
    // this.maxImageHeight = this.dataCard[0].nativeElement.clientWidth
  }

  generateAttributeIds(){
    this.attributeIds = [];
    console.log("panelMeta", JSON.parse(JSON.stringify(this.panelMeta)))
    this.panelMeta.listAttributes.forEach(attr => {
      if(attr.isColumnSelected){
        if(attr.isDrillDown){
          attr.nestedProperties.forEach(nestedProp => {
            if(nestedProp.path) this.attributeIds.push(attr.__id + '.' + nestedProp.path)
          })
        }else{
          this.attributeIds.push(attr.__id)
        }
      }
    })
    console.log("[DATA TABLE] attribute ids", this.attributeIds);
  }

  tableInit(){
    this.columnData = []
    let iconColumnCount: number = this.panelMeta.listAttributes.filter(attr => attr.fieldType == 'icon').length
    let autoColWidth = Math.floor((100 - (iconColumnCount * 5)) / (this.attributeIds.length - iconColumnCount))
    this.panelMeta.listAttributes.forEach(attr => {
      if((attr.fieldType == 'attribute') && attr.isColumnSelected){
        if(attr.isDrillDown){
          attr.nestedProperties.forEach(nestedProp => {
            if(nestedProp.path){
              this.columnData.push({
                headerWidget: this.getHeaderWidget(nestedProp.headerTitle || (attr.name + " > " + nestedProp.path)),
                colWidth: autoColWidth
              })
            }
          })
        }else{
          this.columnData.push({
            headerWidget: this.getHeaderWidget(attr.name),
            colWidth: autoColWidth
          })
        }
      }else if(attr.fieldType == 'label' || attr.fieldType == 'button'){
        this.columnData.push({
          headerWidget: this.getHeaderWidget(attr.columnName),
          colWidth: autoColWidth
        })
      } else if(attr.fieldType == 'icon'){
        this.columnData.push({
          headerWidget: this.getHeaderWidget(attr.columnName),
          colWidth: 5
        })
      } else if(attr.fieldType == 'checkbox') {
        this.columnData.push({
          headerWidget: this.getHeaderWidget(attr.columnName),
          colWidth: 2
        })
      }
    });
  }

  getHeaderWidget(columnName: string){
    let newWidget: any = WidgetManager.getWidget('label')
    newWidget.setValue(columnName?.toString()?.length > 15 ? columnName?.toString()?.slice(0, 15) + '...' : columnName?.toString() || "")
    newWidget.config.tooltip.value = columnName;
    return newWidget
  }


  paginatorEvent(event: any){
    console.log("paginator event hit", event)
    this.raisePageEvent.emit(event)
  }

  createNavigationMap(){
    let navMap: any = {}
    if(this.panelMeta.navigationSettings?.enabled) return
    this.panelMeta?.listAttributes?.forEach(attr => {
      if(!attr.isDrillDown && attr.navigationSettings && attr.navigationSettings.enabled){
        navMap[attr.__id] = attr.navigationSettings
      }
    });
    this.navMap = navMap
    // console.log("navigation map:", this.navMap)
  }

  cardClicked(event, rowIndex){
    console.log("card clicked")
    if(!this.builderMode && this.panelMeta.navigationSettings?.enabled){
      console.log("card level nav enabled", this.panelMeta.navigationSettings)
      if(this.panelMeta.navigationSettings?.type == 'internal'){
        this.navigateInternal(rowIndex, undefined, undefined, true)
      }else if(this.panelMeta.navigationSettings?.type == 'external'){
        this.navigateExternal(rowIndex, undefined, true)
      }else if(this.panelMeta.navigationSettings?.type == 'relative'){
        this.navigateRelative(rowIndex, undefined, true)
      };

      let item = {
        actionId: null,
        data: this.tableData[rowIndex],
        index: rowIndex,
        rowDataRaw: this.rawBoxData?.[rowIndex]
      }
      this.selectedRowData.emit(item);
    }
  }

  rowCheckboxSelected(userInput: any, rowIndex?: number) {
    console.log('USERINPUT', userInput, 'ROW:', rowIndex)
    if (userInput.value) {
      this.selectedRows[rowIndex] = this.rawBoxData?.[rowIndex];
    } else {
      delete this.selectedRows[rowIndex];
    }
  }

  emitCheckboxChange() {
    this.checkboxSelected.emit(this.selectedRows);
  }

  headerCheckboxChange(data) {
    for (let i = 0; i < this.widgetMetaMap.length; i++) {
      const rowWidgets = this.widgetMetaMap[i];
      const checkboxWidget = rowWidgets.__checkbox__;
      if (checkboxWidget?.config?.availableOptions?.staticOptions?.[0]) {
        checkboxWidget.config.availableOptions.staticOptions[0].default = data.value;
      }
      this.rowCheckboxSelected(data, i)
    }
    this.emitCheckboxChange();
  }

  async doOnClickAction(rowIndex: number, attrId: string, groupVal?: any){
    console.log("rawBoxData", this.rawBoxData?.[rowIndex])
    if (attrId == '__checkbox__') return;
    let item = {
      actionId: attrId,
      data: this.tableData[rowIndex],
      index: rowIndex,
      rowDataRaw: this.rawBoxData?.[rowIndex],
      attribute: this.panelMeta.listAttributes.find(attr => attr.__id == attrId)
    }
    // if(attrId == 'delete'){
    //   item.actionId = "deleteById"
    // }
    this.selectedRowData.emit(item)

    let currentAttr = this.panelMeta.listAttributes.find(attr => {
      return attr.__id == attrId
    })
    console.log(currentAttr)
    if(!currentAttr) {
      console.log("no click action for nested attributes")
      return
    }

    if(!currentAttr.eventType || currentAttr.eventType == "navigation"){
       this.doNavigate(rowIndex, attrId, groupVal);
       
    } else if (currentAttr.eventType == "action") {
      if (currentAttr.actionConfig.action == 'application') {
        console.log("this.rawBoxData[rowIndex];", this.rawBoxData[rowIndex])
       
        let dialogRef = this.dialog.open(DeleteDialogComponent, {
          width: '600px',
          data: { 
            resourceName: "record",   // TODO: use softcoded record name for respective app; row|line|record etc
            resource: this.panelMeta.boxObjectId,
            dataObj: item.rowDataRaw,
            isAppData: true
          },
        });
        dialogRef.afterClosed().subscribe(async (deleteConfirmed) => {

          if (deleteConfirmed) {

            try {
              this.inProgressDeletedRecords.unshift(item)
              console.log("pushed into buffer", JSON.parse(JSON.stringify(this.inProgressDeletedRecords)))

              let arrAfterRemove = this.processedData.filter((row, index) => index != item.index)  // delete from processed data
              // this.processedData = JSON.parse(JSON.stringify(arrAfterRemove));
              this.processedDataChanged(JSON.parse(JSON.stringify(arrAfterRemove)))

              // this.runSpinner = true;
              if(currentAttr?.actionConfig?.actionMap)currentAttr.actionConfig.actionMap.valueMap = this.rawBoxData[rowIndex];
              await this.applicationAction.doAction(currentAttr.actionConfig, {type: "click"});
              // this.runSpinner = false;
              this.raisePageEvent.emit({})
              console.log("deleted from processed data", JSON.parse(JSON.stringify(this.processedData)))

            } catch (error) {
              
              console.log("Could not delete record", error)
              // revert the deletion locally
              let deletedRecord = this.inProgressDeletedRecords.find(recordBackupObj => recordBackupObj.index == item.index)
              let i = deletedRecord.index
              let newArr = this.processedData.slice(0, i).concat(deletedRecord.data, this.processedData.slice(i))
              this.processedDataChanged(newArr)
              console.log("reverted deletion", JSON.parse(JSON.stringify(this.processedData)))
              // throw error

            } finally {

              // remove from in progress buffer
              this.inProgressDeletedRecords.splice(this.inProgressDeletedRecords.findIndex(row => row.index == item.index), 1)
              console.log("removed from buffer", JSON.parse(JSON.stringify(this.inProgressDeletedRecords)))
            }
          }
        })

      } else if (currentAttr.actionConfig.action == 'resource-deletion') {
        console.log("resource deletion", this.rawBoxData[rowIndex])
        await this.deleteResource(item)
      } 
    } else if (currentAttr.eventType.startsWith('popup')) {
      console.log("popup action", currentAttr)
      this.handlePopupAction(rowIndex, currentAttr)
    }
  }


  /**
   * connection, bloom, forms and starch listings deletes are handled here
   */
  async deleteResource(data){
    data['resource'] = this.panelMeta.boxObjectId // attach resource type 
    console.log("data to delete", data)
    
    let dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '600px',
      data: { 
        resourceName: data.rowDataRaw.name,
        resource: this.panelMeta.boxObjectId,
        dataObj: data.rowDataRaw
      },
    });
    dialogRef.afterClosed().subscribe(async (deleteConfirmed) => {
      if (!deleteConfirmed) return
      console.log("delete confirmed")
      // delete the resource locally to reflect instantly
      // keep a queue of locally deleted records (in case it needs to be reverted)
      this.inProgressDeletedRecords.unshift(data)
      console.log("pushed into buffer", JSON.parse(JSON.stringify(this.inProgressDeletedRecords)))

      let arrAfterRemove = this.processedData.filter((row, index) => index != data.index)  // delete from processed data
      // this.processedData = JSON.parse(JSON.stringify(arrAfterRemove));
      this.processedDataChanged(JSON.parse(JSON.stringify(arrAfterRemove)))

      try {
        let deleteResponse = await this.resourceDeletionService.delete(data)
        console.log("delete response", deleteResponse)
      
      } catch(e) {
        console.error("delete failed: error received", e)

        // revert the deletion locally
        let deletedRecord = this.inProgressDeletedRecords.pop()
        let i = deletedRecord.index
        let newArr = this.processedData.slice(0, i).concat(deletedRecord.data, this.processedData.slice(i))
        this.processedDataChanged(newArr)
        // console.log("added back", JSON.parse(JSON.stringify(this.processedData)))
      } finally {

        // delete from queue
        this.inProgressDeletedRecords.splice(this.inProgressDeletedRecords.findIndex(row => row.index == data.index), 1)
        console.log("removed from buffer", JSON.parse(JSON.stringify(this.inProgressDeletedRecords)))
      }
    })
  }

  async handlePopupAction(rowIndex: number, attr: any){
    // console.log("handle popup action", attr)
    // console.log("rowIndex", rowIndex)
    // console.log("data", this.rawBoxData)
    if(attr.eventType == 'popup-edit'){
      this.runSpinner = true
      // console.log("form panel", JSON.parse(JSON.stringify(formPanel)))
      let formPanel: any = await this.automationService.generatePopupForm(this.panelMeta, this.rawBoxData[rowIndex], attr.updateFnOptions)
      formPanel['actionFnOptions'] = attr.updateFnOptions
      formPanel = this.automationService.generateFormWidgets(formPanel.formAttributes, formPanel)
      // console.log("form panel after widgets", JSON.parse(JSON.stringify(formPanel))
      this.runSpinner = false
      this.automationService.openPopupDialog(formPanel)

    } else if (attr.eventType == 'popup-view') {
      this.runSpinner = true
      let detailsPanel: any = await this.automationService.generatePopupDetails(this.panelMeta, this.rawBoxData[rowIndex])
      console.log("details panel created", JSON.parse(JSON.stringify(detailsPanel)))
      detailsPanel['layoutMap'] = this.automationService.generateDetailsWidgets(detailsPanel)
      console.log("details panel after widgets addition", detailsPanel)
      this.runSpinner = false
      this.automationService.openPopupDialog(undefined, detailsPanel)

    } else if (attr.eventType == 'popup-view-edit') {
      this.runSpinner = true
      let formPanel: any = await this.automationService.generatePopupForm(this.panelMeta, this.rawBoxData[rowIndex], attr.updateFnOptions)
      formPanel['actionFnOptions'] = attr.updateFnOptions
      formPanel = this.automationService.generateFormWidgets(formPanel.formAttributes, formPanel)
      let detailsPanel: any = await this.automationService.generatePopupDetails(this.panelMeta, this.rawBoxData[rowIndex])
      detailsPanel['layoutMap'] = this.automationService.generateDetailsWidgets(detailsPanel)
      this.runSpinner = false
      this.automationService.openPopupDialog(formPanel, detailsPanel)
    }
  }

  doNavigate(rowIndex: number, attrId: string, groupVal?: any){
    // console.log("nav map for attr", rowIndex, attrId)

    // console.log("nav map", this.navMap)
    // console.log("nav map for attr", this.navMap[attrId])
    if(!this.navMap[attrId] || !this.navMap[attrId].enabled || !this.navMap[attrId].type) {
      // console.log("no navigation exists for this attribute!!")
      return
    }

    if(!this.builderMode){
      if(this.navMap[attrId].type == 'internal' || this.navMap[attrId].type == 'relative'){
        this.navigateInternal(rowIndex, attrId, groupVal)
      }else if(this.navMap[attrId].type == 'external'){
        this.navigateExternal(rowIndex, attrId)
      }
    }
  }

  navigateRelative(rowIndex, attrId, groupValue?: any, fullCardNav?: boolean){

  }

  decideViewTypeForScreenSize(){
    this.currentViewType = this.screenWidth < 440 ? "card" : this.currentViewType;
    return this.currentViewType
  }

  navigateInternal(rowIndex, attrId, groupValue?: any, fullCardNav?: boolean){
    // console.log("rowIndex", rowIndex, "attrId", attrId)
    // console.log("navigate internal hit, navigation settings", this.panelMeta)
    let isRelative: boolean
    let relativeUrl: string = ""
    let navFilterAttributes
    let destinationPageCode
    if(!fullCardNav){
      let currentAttr = this.panelMeta.listAttributes.find(attr => attr.__id == attrId)
      // console.log(currentAttr)
      navFilterAttributes = currentAttr.navigationSettings.navFilterAttributes
      destinationPageCode = this.navMap[attrId]['pageCode']
      isRelative = currentAttr.navigationSettings.type == 'relative' ? true : false
      relativeUrl = currentAttr.navigationSettings.type == 'relative' ? currentAttr.navigationSettings.linkUrl : ""
      // console.log("navFilterAttributes", currentAttr.navigationSettings.navFilterAttributes)
    }else{
      navFilterAttributes = this.panelMeta.navigationSettings?.navFilterAttributes
      destinationPageCode = this.panelMeta.navigationSettings?.['pageCode']
      isRelative = this.panelMeta.navigationSettings.type == 'relative' ? true : false
      relativeUrl = this.panelMeta.navigationSettings.type == 'relative' ? this.panelMeta.navigationSettings.linkUrl : ""
    }

    // let rowData: any = this.processedData[rowIndex]
    let data = []
    let queryParams:any = {
      // _destinationPageCode: destinationPageCode
    };

    let pathParams = "";
    navFilterAttributes.forEach(attr => {
      // console.log("nav attr", attr, "rawBoxdata", this.rawBoxData)

      let value;
      if(this.currentViewType == 'board' && groupValue){
        let groupColumn = this.panelMeta.viewTypes.boardStatusColumn.__id
        // this.rawBoxData
        let filteredList = this.rawBoxData.filter(obj => obj[groupColumn] == groupValue)
        // console.log("filteredList", filteredList)
        value = filteredList[rowIndex][attr.__id]
        // console.log("value:", value)
      }else{
        value = this.rawBoxData[rowIndex][attr.__id]
      }

      // data.push({
      //   attributeId: attr.__id,
      //   parameter: attr.parameter || attr.__id,
      //   value: value,
      //   dataType: attr.dataType
      // })

      // queryParams[encodeURIComponent(attr.parameter || attr.__id)] = encodeURIComponent(value);
      pathParams = pathParams + "/" + encodeURIComponent(attr.parameter || attr.__id) + "/" + encodeURIComponent(value);
      // queryParams["destination-page-code"] = destinationPageCode;
    });

    // let dataToSend = {
    //   subscriptionId: Date.now(),
    //   filters: JSON.parse(JSON.stringify(data)),
    //   _sourcePageCode: this.pageCode,
    //   "destination-page-code": destinationPageCode
    // };

    // console.log("dataToSend", dataToSend)
    console.log("destination page code", destinationPageCode)
    console.log("destination url", '/bloom/' + this.bloomCode + '/' + destinationPageCode)

    // this.pageService.navigationData.next(dataToSend)

    this.pageService.savePageRequest.next(true)

    let version: string
    let latestVersion = false;
    this.metaService.get_bloomMeta.subscribe((bloomMeta: any) => {
      version = bloomMeta.version;
      latestVersion = bloomMeta.latest
    })

    if(isRelative){
      // console.log("current route", this.router.url)
      // let parts = this.router.url.split("/")
      // parts = parts.filter(p => p.length)
      // console.log("parts", parts)
      // console.log("last part", parts[parts.length - 1])
      // if(relativeUrl.startsWith(".")) relativeUrl = relativeUrl.substring(1)
      // if(relativeUrl.startsWith("/")) relativeUrl = relativeUrl.substring(1)
      // // if(queryString) relativeUrl += queryString

      // // relativeUrl = "/" + parts[parts.length - 1] + "/" + relativeUrl
      // let urlTree = parts.concat([relativeUrl])

      // console.log("final url tree for relative navigation", urlTree)
      // let url = ""
      // urlTree.forEach(path => url = url + "/" + path)
      // console.log("navigating to", url)
      // this.router.navigateByUrl(url)
    }
    if(this.isFragment){
      this.router.navigate(['/bloom/' + this.bloomCode + '/' + destinationPageCode], {fragment: this.fragment});
    } else{
      if(!latestVersion && version != "latest") {
        queryParams.v = version;
        pathParams = pathParams + "/v/" + version;
      }
      if(pathParams) pathParams = "/q/" + pathParams;
      this.router.navigate(['/p/' + this.bloomCode + '/' + destinationPageCode + pathParams]);
    }
  }


  navigateExternal(rowIndex, attrId, fullCardNav: boolean = false){
    if(fullCardNav){
      window.open(this.panelMeta.navigationSettings.linkUrl, "_blank");
    }else{
      console.log("data", this.rawBoxData[rowIndex])
      console.log("nav map for attr", this.navMap[attrId])
      let te = new TemplateEngine()
      let finalLink = te.fill(this.navMap[attrId].linkUrl, this.rawBoxData[rowIndex])
      window.open(finalLink, "_blank");
    }
  }


  openListPanel(obj, key) {
    this.runSpinner = true;
    if(obj && typeof obj == 'string'&& obj.length > 20) {
      return;
    }

    // open dialog to collect options
    let data: any = {
      data: obj,
      key: key
    }

    if (Array.isArray(obj)) {
      let firstElem = obj[0];
      if(firstElem instanceof Object){
        data.type = "arrayofobjects";
      } else {
        data.type = "arrayofprimitives";
      }
    } else if(obj instanceof Object){
      data.type = "object";
    }

    // this.listPanelService.subPanelMetas = [];
    this.listPanelService.subPanelMeta = {};
    this.runSpinner = false;
    this.subPanel.emit(data);
  }

  getNumberOfFields(value){
    let result:any = {text: "", tooltext: ""};

    if (Array.isArray(value)) {
      let firstElem = value[0];
      if(firstElem instanceof Object){
        result.text = `[ ${value?.length} ]`;
        result.tooltext = `${value?.length} objects. Click to see details`
      } else {
        let elements = value.slice(0, 5);
        let cus = [];
        elements.forEach(e => {
          if(e != typeof 'string')e = JSON.stringify(e);
          if(e.length > 5){
            cus.push(e.substring(0, 5) + "..");
          } else cus.push(e)
        })
        result.text = `[${cus}]`;
        result.tooltext = `${value?.length} elements. Click to see details`
      }

    } else if(value instanceof Object){
      result.text = `{ ${Object.keys(value).length} }`;
      result.tooltext = `${Object.keys(value).length} fields. Click to see details`
    }
    // else if(value && typeof value == 'string'&& value.length > 20 && value.indexOf("https://") == -1) {
    //   result.text = `${value.substring(0, 20)}...`
    //   result.tooltext = value
    // }
    return result;
  }

  isObjectValue(value){
    let result = false;
    if(value && value instanceof Object || value instanceof Array){
      result = true;
    }
    // else if (value && typeof value == 'string' && value.length > 20 && value.indexOf("https://") == -1){
    //   result = true;
    // }
    return result;
  }


  trackByFn(index, item) {
    return index;
  }


  // creates widgets and puts it in an appropriate data structure
  createWidgetMetaMap(viewType: string){
    console.log("create widget meta map called, table data", JSON.parse(JSON.stringify(this.tableData || [])))
    // this.ready = false
    if(!viewType) viewType = this.panelMeta.viewTypes?.defaultView
    if(viewType == 'table' || viewType == 'card'){
      this.widgetMetaMap = []
      if(!this.tableData) return;
      for (let i = 0; i < this.tableData.length; i++) {
        let rowData = this.tableData[i]
        let rowMetaMap = {}

        // let attrIds = Object.keys(rowData)
        let attrIds = []
        let attributes = this.panelMeta.listAttributes.filter(attr => attr.isColumnSelected)
        console.log("attributes selected to show", JSON.parse(JSON.stringify(attributes)))
        attributes.forEach(attr => {
          // if(rowData[attr.__id] == undefined || rowData[attr.__id] == '') return
          if(!attr.isDrillDown){
            attrIds.push(attr.__id)
            // console.log("added to list", attr.__id)
          }else{
            // if(typeof(rowData[attr.__id]) != 'object' || !Object.keys(rowData[attr.__id]).length) return
            attr.nestedProperties.forEach(nestedProp => {
              attrIds.push(`${attr.__id}.${nestedProp.path}`)
              // let val = this.widgetUtilityService.getDeepObjectValue(rowData[attr.__id], nestedProp.path)
              // if(val != undefined && val != '') attrIds.push(`${attr.__id}.${nestedProp.path}`)
            });
          }
        })
        for (let j = 0; j < attrIds.length; j++) {
          const attrId = attrIds[j];
          // if _recordId, no need to create widgetMeta
          if(attrId == '__recordId'){
            rowMetaMap[attrId] = rowData[attrId]
            continue
          }

          if(this.panelMeta.listWidgetSet && this.panelMeta.listWidgetSet[attrId]){
            let widgetProto = this.panelMeta?.listWidgetSet[attrId]
            // console.log("widget proto found", widgetProto)
            // let widgetType = this.getWidgetType(attrId)
            let newWidget: any = WidgetManager.getWidget(rowData[attrId]?.widgetType || 'label')
            Object.keys(widgetProto).forEach(prop => {
              if(prop !== 'id') {
                newWidget[prop] = JSON.parse(JSON.stringify(widgetProto[prop]))
              }
            })
            // console.log("after prop copy", newWidget)

            if(newWidget.type == 'label') {
              if(!newWidget.config.tooltip) {
                let labelWid = WidgetManager.getWidget('label')
                newWidget.config.props.push('tooltip')
                newWidget.config.tooltip = JSON.parse(JSON.stringify(labelWid.config.tooltip))
              }
              if(typeof rowData[attrId]?.value == 'string' && rowData[attrId]?.value?.length > 500){
                newWidget.config.tooltip.value =  rowData[attrId]?.value?.substring(0, 500)
              } else {
                newWidget.config.tooltip.value = rowData[attrId]?.value
              }
              if(rowData[attrId]?.value?.length && rowData[attrId]?.value?.length >= 500) rowData[attrId].value += '...'
            }

            if(newWidget.type == 'tags'){
              if(!rowData[attrId]?.value) rowData[attrId].value = [];
            }
            // console.log("will set value", JSON.parse(JSON.stringify(rowData[attrId]?.value || "")))

            // SET THE VALUE INTO WIDGET
            if (newWidget.type == 'image'){
              newWidget.setValue(rowData[attrId]?.value?.toString() || newWidget.config.src.resetValue || '')
            } else if (newWidget.type == 'tags') {
              newWidget.setValue(rowData[attrId]?.value)
            } else {
              if (rowData[attrId]?.value?.toString()?.length > 50){
                newWidget.setValue(rowData[attrId]?.value?.toString()?.slice(0, 50) + '...')
              } else {
                newWidget.setValue(rowData[attrId]?.value?.toString() || '')
              }
            }

            // newWidget.type !== 'image'
            //   ? newWidget.setValue(rowData[attrId]?.value?.toString()?.length > 50 ? rowData[attrId]?.value?.toString()?.slice(0, 50) + '...' : rowData[attrId]?.value?.toString() || '')
            //   : newWidget.setValue(rowData[attrId]?.value?.toString() || '')
            rowMetaMap[attrId] = newWidget
            // console.log("widget created", rowMetaMap[attrId])
          }
          if(rowData[attrId]?.widgetType == 'link'){
            rowMetaMap[attrId]['config']['tooltip']['value'] = rowData[attrId]?.value || "";

            rowMetaMap[attrId]['config']['linkText']['value'] = "Click to navigate";
            console.log("link widget created", rowMetaMap[attrId])
          }
          // console.log("rowMetaMap", rowMetaMap)
          if(rowData[attrId]?.widgetType == 'datetime'){
            if(rowMetaMap[attrId]['config']['viewOnly']){
              rowMetaMap[attrId]['config']['viewOnly']['value'] = true;
            }else{
              let wid = WidgetManager.getWidget('datetime')
              rowMetaMap[attrId].config.props.push('viewOnly')
              rowMetaMap[attrId]['config']['viewOnly'] = wid.config.viewOnly
              rowMetaMap[attrId]['config']['viewOnly']['value'] = true;
            }
            // console.log(rowMetaMap[attrId]['config']['viewOnly'])
            rowMetaMap[attrId].config.placeholder.value = '';
            if(!rowData[attrId].value) rowData[attrId].value = null;
          }

          if(rowData[attrId]?.widgetType == 'checkbox'){
            rowMetaMap[attrId]['config']['showTitle']['value'] = "";
            rowMetaMap[attrId]['config']['availableOptions']['staticOptions'] = [{
              name: '',
              value: '',
              default: false,
              type: 'static'
            }];
          }

          if(rowData[attrId]?.widgetType == 'tags'){
            rowMetaMap[attrId]['config']['tagType']['value'] = "Stroked";
          }

          if(!rowData[attrId]?.value && !['image', 'checkbox'].includes(rowData[attrId]?.widgetType)){
            rowMetaMap[attrId]?.setValue('${reset}')
          }

          
          if(rowData[attrId]?.widgetType == 'icon'){
            // console.log("rowMetaMap", JSON.parse(JSON.stringify(rowMetaMap)))
            let attr = attributes.find(a => a.__id == attrId)
            // console.log("attr", attr, "rowData", rowData)
            if (attr['conditionalConfig']?.enabled) {
            rowMetaMap[attrId]['config']['conditionalConfig'] = attr['conditionalConfig']
            }
            
            if (!rowMetaMap[attrId]?.['config']?.['alignment']) {
              console.log("inside if", rowMetaMap[attrId])
              let newWid = WidgetManager.getWidget('icon')
              rowMetaMap[attrId]['config']['props'].push('alignment')
              rowMetaMap[attrId]['config']['alignment'] = newWid['config']['alignment']
            }
            rowMetaMap[attrId]['config']['alignment']['value'] = 'start'
            // console.log("icon widget ammended", rowMetaMap[attrId])
          }
          // console.log("meta created", rowMetaMap[attrId])
        }
        // console.log("row meta created", JSON.parse(JSON.stringify(rowMetaMap || {})))

        this.widgetMetaMap[i] = rowMetaMap
      }
    }else if(viewType == 'board'){
      console.log("[createWidgetMetaMap] board view")
      console.log("groupedData", this.groupedData)
      this.widgetMetaMap = {
        groupValues: []
      }

      for (let i = 0; i < this.groupedData.groupValues.length; i++) {
        let groupVal: string = this.groupedData.groupValues[i]

        this.widgetMetaMap['groupValues'].push(groupVal)
        this.widgetMetaMap[groupVal] = []

        for (let i = 0; i < this.groupedData[groupVal].length; i++) {
          let rowData = this.groupedData[groupVal][i]
          let rowMetaMap = {}

          let attrIds = Object.keys(rowData)
          for (let j = 0; j < attrIds.length; j++) {
            const attrId = attrIds[j];
            if(attrId == '__recordId'){
              rowMetaMap[attrId] = rowData[attrId]
              continue
            }
            if(rowData[attrId].type == 'attribute'){
              // rowMetaMap[attrId] = this.pageService.createWidget(rowData[attrId].widgetType, rowData[attrId].value)
              let wid = WidgetManager.getWidget(rowData[attrId].widgetType)
              wid.setValue(rowData[attrId].value || "")
              rowMetaMap[attrId] = wid
            }else if(rowData[attrId].type == 'icon'){
              let newWidget = this.pageService.createWidget(rowData[attrId].widgetType, rowData[attrId].value)
              newWidget.type !== 'image'
                ? newWidget.setValue(rowData[attrId]?.value?.toString()?.length > 50 ? rowData[attrId]?.value?.toString()?.slice(0, 50) + '...' : rowData[attrId]?.value?.toString() || '')
                : newWidget.setValue(rowData[attrId]?.value?.toString() || '')
                rowMetaMap[attrId] = newWidget
              console.log("icon created", rowMetaMap[attrId])
            }
          }
          this.widgetMetaMap[groupVal][i] = rowMetaMap
        }
      }
    }

    console.log("viewType", this.getCurrentViewType())
    console.log("widget meta map created", JSON.parse(JSON.stringify(this.widgetMetaMap || {})))
    console.log("attributeIds", this.attributeIds)
    this.ready = true
    // this.cdr.detectChanges()
  }

  getWidgetType(attrId){
    let parts = attrId.split('.')
    if(parts.length > 1) {  // means composite attribute id: <attrid>.<path>, drill down exists
      let attrId = parts[0]
      let path = parts.slice(1).join('.')
      let attr = this.panelMeta.listAttributes.find(a => a.__id == attrId)
      if(!attr || !attr.isDrillDown || !attr.nestedProperties.length) return
      return attr.nestedProperties.find(np => np.path == path)?.widgetType
    }else{  // no drill down
      return this.panelMeta.listAttributes.find(a => a.__id == attrId)?.widgetType
    }
  }

  /**
   * ONLY APPLICABLE FOR BOARD VIEW
   * @param rowData
   * @returns
   */
  recreateRowMetaMap(rowData: any){
    let rowMetaMap = {}

    let attrIds = Object.keys(rowData)
    for (let j = 0; j < attrIds.length; j++) {
      const attrId = attrIds[j];
      if(attrId == '__recordId'){
        rowMetaMap[attrId] = rowData[attrId]
      }
      if(rowData[attrId].type == 'attribute'){
        rowMetaMap[attrId] = this.pageService.createWidget(rowData[attrId].widgetType, rowData[attrId].value)
      }
    }
    return rowMetaMap
  }

  /**
 * ONLY APPLICABLE FOR BOARD VIEW
 * @param tableData
 * @param panelMeta
 * @returns
 */
  groupDataForBoard(tableData, panelMeta){
    // console.log("boardStatusColumn", panelMeta.viewTypes.boardStatusColumn)
    // console.log("currentViewType", this.currentViewType)
    // console.log("panelMeta", panelMeta)
    // console.log("tableData", tableData)
    if(!panelMeta?.viewTypes){
      return tableData
    }
    if(!tableData?.length) return []
    if(panelMeta.viewTypes.defaultView !== 'board' && this.currentViewType !== 'board'){
      return tableData
    }
    console.log("will group")
    this.groupedData = {
      groupValues: []
    }
    let groupAttrId = panelMeta.viewTypes.boardStatusColumn.__id
    for (let i = 0; i < tableData?.length; i++) {
      const row = tableData[i];

      let rowGroupValue = row[groupAttrId] ? row[groupAttrId].value : ''
      if(row[groupAttrId] && rowGroupValue){
        if(this.groupedData.groupValues.findIndex(v => v == rowGroupValue) == -1){
          this.groupedData.groupValues.push(rowGroupValue)
          this.groupedData[rowGroupValue] = []
        }
        this.groupedData[rowGroupValue].push(row)
        // console.log("after push", this.groupedData)
      }else if(row[groupAttrId] && !row[groupAttrId].value){
        if(this.groupedData.groupValues.findIndex(v => v == '_ungrouped') == -1){
          this.groupedData.groupValues.push('_ungrouped')
          this.groupedData['_ungrouped'] = []
        }
        this.groupedData['_ungrouped'].push(row)
      }
    }
    console.log("grouped data ------>", this.groupedData)
  }

  /**
   * ONLY APPLICABLE FOR BOARD VIEW
   * @param event
   * @returns
   */
  async drop(event: CdkDragDrop<string[]>) {
    if(!event.item.data.__recordId){
      console.log("unique id not present, can not update")
      this._snackBar.open("unique id not present, can not update", "",  {duration: 2000});
      return
    }

    // console.log("widgetMetaMap:", this.widgetMetaMap)
    console.log("item moved", event)
    let sourceGroupVal = event.previousContainer.id
    let targetGroupVal = event.container.id
    let sourceIndex = event.previousIndex
    let targetIndex = event.currentIndex

    // shift elements in widgetMetaMap
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }

    // shift elements in groupedData
    if (sourceGroupVal === targetGroupVal) {
      moveItemInArray(this.groupedData[sourceGroupVal], event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        this.groupedData[sourceGroupVal],
        this.groupedData[targetGroupVal],
        event.previousIndex,
        event.currentIndex,
      );
    }

    let recordId = event.item.data.__recordId
    let field = this.panelMeta.viewTypes.boardStatusColumn.__id
    console.log("find __recordId", event.item.data.__recordId, "in tableData")
    console.log("change value of", this.panelMeta.viewTypes.boardStatusColumn, "from", sourceGroupVal, "to", targetGroupVal)
    console.log("groupedData", this.groupedData)

    console.log("rawBoxData", this.rawBoxData)
    console.log("primaryAttributeId", this.panelMeta.primaryAttribute)
    let primaryAttributeId = this.panelMeta.primaryAttribute.__id

    let updatedRecord
    let index: number
    for (let i = 0; i < this.tableData.length; i++) {
      if(this.tableData[i].__recordId == recordId){
        index = i
        this.tableData[i][field]['value'] = targetGroupVal
        updatedRecord = this.tableData[i][field]
      }
    }
    console.log("changed tableData", this.tableData)

    // check if update possible
    let isUpdateFnFound: boolean
    isUpdateFnFound = await this.boxService.getUpdateFuntion(this.panelMeta.connectionId, this.panelMeta.boxId, this.panelMeta.boxObjectId)
    console.log("update fn true", isUpdateFnFound)

    console.log("update fn true check outside", isUpdateFnFound)
    if(!isUpdateFnFound){
      this._snackBar.open("VALUE CHANGE NOT SUPPORTED", "",  {duration: 2000});
      return
    }

    // find and update raw box data for backend update
    let updateIndex
    for (let i = 0; i < this.rawBoxData.length; i++) {
      const record = this.rawBoxData[i];
      if(record[primaryAttributeId] == event.item.data.__recordId){
        this.rawBoxData[i][field] = targetGroupVal
        updateIndex = i
        console.log("value updated in raw data", record)
      }
    }

    // fire update request to backend
    let updateRes: any
    (async () => {
      updateRes = await this.boxService.updateRecord(this.panelMeta.connectionId, this.panelMeta.boxId, this.panelMeta.boxObjectId, this.rawBoxData[updateIndex])
    })()

    let newMetaMap = this.recreateRowMetaMap(this.tableData[index])
    console.log("newMetaMap", newMetaMap)

    this.widgetMetaMap[targetGroupVal][event.currentIndex] = newMetaMap
  }

  /**
   *
   * @param attrId
   * @param type: '+' | '-'
   * @returns
   */
  resizeImage(attrId: string, type: any){
    if(!this.builderMode) return
    // console.log("zoom in for attr:", attrId, "type:", type)
    // console.log('list attributes', this.panelMeta.listAttributes)
    let attr = this.panelMeta.listAttributes.find(attr => attr.__id == attrId)
    let oldDimension = parseInt(attr.imageDimension)
    // console.log('old dimension', oldDimension)
    let newDimension: number
    if(type == '+'){
      // console.log('entered add block')
      newDimension = oldDimension + this.imageResizeStep
    }else if(type == '-'){
      // console.log('entered minus block')
      newDimension = oldDimension - this.imageResizeStep
    }else{
      return
    }
    // console.log('new Dimension', newDimension)
    // if(this.imageWidgetWrap[0].nativeElement.clientWidth >= this.dataCard[0].nativeElement.clientWidth){
    //   return
    // }

    if(type == '+' && newDimension && newDimension > this.MAX_IMAGE_SIZE){
      console.log('cant increase more')
      return
    }
    if(type == '-' && newDimension && newDimension < this.MIN_IMAGE_SIZE){
      console.log('cant decrease more')
      return
    }

    // console.log('will assign new dimension', newDimension)
    this.tableData.forEach(rowData => {
      // console.log('adding to ', rowData)
      if(rowData[attrId] && rowData[attrId]['styles']){
        // console.log('addition valid for attrId', attrId)
        let styles: any = {
          'height': newDimension + 'rem',
          'width': newDimension + 'rem'
        }
        rowData[attrId]['styles'] = styles
      }
    })

    console.log('updated local styles in tableData')
    // update and raise updated panelMeta
    this.panelMeta.listAttributes.find(attr => attr.__id == attrId).imageDimension = newDimension
    this.metaChange.next(this.panelMeta)
  }

  /**
   *
   * @param newProto : is the new widget meta
   * @param attrId attribute id whose widget prototype needs to be changed
   */
  updateWidgetPrototype(newProto: any, attrId: any, propertyName?){
    console.log("update widget prototype for ", attrId, " new widget", newProto)
    let oldProto = this.panelMeta.listWidgetSet[attrId]
    console.log("old proto", JSON.parse(JSON.stringify(oldProto)))
    if(!oldProto) {
      console.log("no old proto")
      return
    }

    // only update if text formatting changed or alignment changed
    if(JSON.stringify(oldProto.textFormat) !== JSON.stringify(newProto.textFormat)){
      console.log("text styling changed", newProto.textFormat)
      this.panelMeta.listWidgetSet[attrId]['textFormat'] = newProto.textFormat
      // this.panelMeta.listWidgetSet[attrId].generateStyles()
    }else if(oldProto.config.alignment && (oldProto.config.alignment.value !== newProto.config.alignment.value)){
      console.log("alignment changed", newProto.config.alignment.value)
      this.panelMeta.listWidgetSet[attrId].config.alignment.value = newProto.config.alignment.value
    }else if (oldProto.type == 'image' && oldProto.config.width.value !== newProto.config.width.value){
      console.log("image width changed", newProto.config.width)
      this.panelMeta.listWidgetSet[attrId].config.width.value = newProto.config.width.value
    }else if (oldProto.type == 'image' && oldProto.config.imageAlignment.value !== newProto.config.imageAlignment.value){
      console.log("image alignment changed", newProto.config.imageAlignment)
      this.panelMeta.listWidgetSet[attrId].config.imageAlignment.value = newProto.config.imageAlignment.value
    }else if(oldProto.config.backgroundColor !== newProto.config.backgroundColor){
      console.log("background color changed", newProto.config.backgroundColor)
      this.panelMeta.listWidgetSet[attrId].config.backgroundColor.value = newProto.config.backgroundColor.value
    }else{
      console.log("prop changed", propertyName)
      if(this.panelMeta.listWidgetSet[attrId].config[propertyName]){
        this.panelMeta.listWidgetSet[attrId].config[propertyName].value = newProto.config?.[propertyName]?.value
      }

      // this.panelMeta.listWidgetSet[attrId] = newProto
      // Object.keys(oldProto.config).forEach(prop => {
      //   console.log("checking prop", prop)
      //   if(JSON.stringify(oldProto.config[prop]) !== JSON.stringify(newProto.config[prop])) {
      //     console.log("prop changed is", prop)
      //     oldProto.config[prop].value = newProto.config[prop].value
      //     console.log("widget proto changed", JSON.parse(JSON.stringify(this.panelMeta.listWidgetSet[attrId])))
      //   }
      // })
    }
    console.log("widget prototype changed for ", attrId, " current proto", JSON.parse(JSON.stringify(this.panelMeta.listWidgetSet[attrId])))
    this.metaChange.emit(this.panelMeta)

    this.createWidgetMetaMap(this.currentViewType)
  }

  /**
   * triggered when mouseenter on an element.
   * sets the tooltip text if needed
   */
  setFullText(labelWrap: any, data: any){
    let target = labelWrap.srcElement.firstChild
    this.mouseOverFullText = ""
    if(target.scrollWidth > target.offsetWidth || data?.value?.length > 50) this.mouseOverFullText = data?.value
    // console.log("text set", this.mouseOverFullText)
  }

  getCardWidth(){
    let cardSize = this.panelMeta.viewTypes?.card?.cardSize || "large" //"medium"  small | medium(default) | large
    if (this.screenWidth < 600) { // xs
      return this.cardSizeMap['xs'][cardSize]
    } else if (this.screenWidth >= 600 && this.screenWidth < 900) { // sm
      return this.cardSizeMap['sm'][cardSize]
    } else if (this.screenWidth >= 900 && this.screenWidth < 1200) { // md
      return this.cardSizeMap['md'][cardSize]
    } else if (this.screenWidth >= 1200 && this.screenWidth < 1536) { // lg
      return this.cardSizeMap['lg'][cardSize]
    } else {  // xl
      return this.cardSizeMap['xl'][cardSize]
    }
  }

}


/**
 * ---------------- navigate confirmation dialog for builderMode
 */
@Component({
  selector: 'navigateConfirmation',
  template: `
    <div>Navigation enabled for this field.</div>
    <div style="font-size: 1.2rem"> Test navigation?</div>
    <div style="height: 1rem"></div>
    <div mat-dialog-actions align="end">
      <button mat-stroked-button [mat-dialog-close]="false">Later</button>
      <button mat-stroked-button [mat-dialog-close]="true">Navigate and Test</button>
    </div>
  `,
})
export class NavigateConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<NavigateConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
