import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoxService } from 'src/app/shared/services/box.service';


@Component({
  selector: 'app-inputparameter-dialog',
  templateUrl: './inputparameter-dialog.component.html',
  styleUrls: ['./inputparameter-dialog.component.scss']
})
export class InputparameterDialogComponent implements OnInit {
  list: any;
  action: any;
  connection: any;
  boxObjectFunctionInputLists: any;
  executionResponse: any;
  spinner: boolean = false;
  inputParams:any
  availableAttr:any
  enableExecute: boolean = true;
  attributeResponse: any;

  attributeEmitter = new EventEmitter()
  localDialogData: any;
  allAction: any;
  availableInputParams: { options: { object: any; }; };
  isObjectFnSupported: any;
  isOnlyOptions: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any, public dialogRef: MatDialogRef<InputparameterDialogComponent>,
    private boxService: BoxService) {
    console.log("DIALOGREF", dialogData);
    this.availableInputParams = {options : {object: dialogData?.objectId}}
    this.localDialogData = {};
    this.localDialogData = JSON.parse(JSON.stringify(dialogData))
    var data = dialogData;
    this.action = data.action;
    this.allAction = dialogData?.allfn
    this.isObjectFnSupported = dialogData?.isObjectFnSupported
    this.isOnlyOptions = dialogData?.isOnlyOptions;
    this.availableAttr = data?.availableAttributes;
    this.connection = data.connection;
  }

  ngOnInit(): void {
    // this.list = this.action?.input?.list || [];
    // this.boxObjectFunctionInputLists = [];

    // for (var i = 0; i < this.list.length; i++) {
    //   var obj = {};
    //   if (this.action.input[this.list[i]].list) {
    //     var subList = this.action.input[this.list[i]].list;
    //     for (var j = 0; j < subList.length; j++) {
    //       obj ={};
    //       obj['name'] = subList[j];
    //       obj['value'] = '';
    //       this.boxObjectFunctionInputLists.push(obj);
    //     }

    //   } else {
    //     obj['name'] = this.list[i];
    //     obj['value'] = '';
    //     this.boxObjectFunctionInputLists.push(obj);
    //   }

    // }
    // console.log(
    //   'boxObjectFunctionInputLists',
    //   this.boxObjectFunctionInputLists
    // );
    // if (this.boxObjectFunctionInputLists.length == 0) {
    //   this.executeBoxObjectFunction();
    // }

  }

  inputRecevied(event){
    console.log("[INPUT RECEIVED] event:", event)
    if(event.query){
      event.query = JSON.parse(JSON.stringify(event.query));
    }
    console.log("event", event);
    this.inputParams = event;
  }

  attributeRecevied(event){
    console.log("[INPUT PARAMETER DIALOG] attributes received", event)
    this.attributeEmitter.emit(event)
    //send attribute array to connection-explorer.ts for further use
    this.attributeResponse = event
  }

  columnSelectionChanged(event){
    console.log("Input Event : ",event)
    if(this.action?.input?.list?.includes('data') && this.action?.input?.data?.dataType == 'array'){
      let data = [];
      if (Array.isArray(event)){
        data = event
      } else {
        data.push(event)
      }
      if(!this.inputParams) this.inputParams = {}
      this.inputParams['data'] = data;
    }
  }

  triggerExecute(event){
    console.log("CHECK TRIGGER VALUE", event);
    this.enableExecute = event;
  }

  async executeBoxObjectFunction() {
    this.spinner = true;
    var payload = { parameters: {} };
    console.log("boxObjectFunctionInputLists", this.boxObjectFunctionInputLists)
    if (this.inputParams) {
      payload.parameters = this.inputParams;
      // for (var i = 0; i < this.boxObjectFunctionInputLists.length; i++) {
      //   var list = this.boxObjectFunctionInputLists[i];
      //   let value: any = list.value || '';
      //   if (value) {
      //     console.log('list.value', value);
      //     value = JSON.parse(value);
      //   }
      //   payload.parameters[list.name] = value;
      // }
      if(payload?.parameters?.['requiredInputFields']){
        delete payload.parameters['requiredInputFields']
      }
      console.log('payload', JSON.stringify(payload));
    }

    var response = await this.boxService.executeBoxObjectFunction(this.connection._id, this.connection.box_id, this.action.__id, payload)
    this.spinner = false;
    console.log("[BOX-HTTP] execution response: ", JSON.parse(JSON.stringify(response)));
    if(response.data){
      this.executionResponse = response.data;
    }else{
      this.executionResponse = response;
    }


    console.log(JSON.parse(JSON.stringify(this.executionResponse)));
    if(!this.executionResponse.error) {
      if(this.action?.__id?.split("/")[1]?.includes("get")) this.executionResponse.push(this.attributeResponse)
    // console.log(JSON.parse(JSON.stringify(this.executionResponse)));
      this.dialogRef.close(this.executionResponse);
    }
  }

}

