import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-collect-fn-options',
  templateUrl: './collect-fn-options.component.html',
  styleUrls: ['./collect-fn-options.component.scss']
})
export class CollectFnOptions implements OnInit {
  
  fn: any
  fnOptions: any
  getAttributesFunction: any
  executeEnabled: any;
  connection: any;
  isOnlyOptions: any
  isSupportQuery: any;
  allAction: any;
  attributeEmitter = new EventEmitter()
  availableInputParams: any;
  inputParamsRecevied: any;
  dependancyMap: any;
  requiredInputFields: any = []
  errorNoteShow: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any, public dialogRef: MatDialogRef<CollectFnOptions>) {
    this.availableInputParams = {options : {object: dialogData?.objectId}}
    console.log("[COLLECT GET FUNCTION INPUTS] Dialog data", dialogData);
    this.fn = dialogData.fn
    this.allAction = dialogData?.allfn
    this.getAttributesFunction = dialogData.getAttributesFunction
    this.connection = dialogData.connection
    this.fnOptions = dialogData.fnOptions
    this.isOnlyOptions = dialogData.isOnlyOptions
    this.isSupportQuery = dialogData.isSupportQuery
    this.dependancyMap = dialogData.dependency
    for(let ele of this.fnOptions){
      if(ele?.required) this.requiredInputFields.push(ele.__id)
    }
  }

  ngOnInit(): void { }

  optionInputsRecevied(getFnInputParams){
    console.log("getFn input params received", getFnInputParams)
    if(!getFnInputParams) return
    this.inputParamsRecevied = getFnInputParams;
    this.enableExecute(getFnInputParams);
    Object.keys(getFnInputParams.options || getFnInputParams).forEach(optionId => {
      this.fnOptions.forEach(getOption => {
        if(getOption.__id == optionId){
          getOption.value = getFnInputParams?.options?.[optionId] || getFnInputParams?.[optionId]
        }
      })
    })
    console.log("get options", this.fnOptions)
  }

  enableExecute(event){
    if(!event?.requiredInputFields?.length && this.requiredInputFields?.length) event['requiredInputFields'] = this.requiredInputFields;
    if (Object.keys(event).length < 1) return;
    var count = 0
    var uniqueRequiredFields = []
    if(event?.requiredInputFields?.length > 0){
      if(event?.root?.length && event?.[event?.root].length){
        var list = event?.[event?.root];
      }
      for(let inputKey of event.requiredInputFields){
        if(!uniqueRequiredFields.includes(inputKey)) uniqueRequiredFields.push(inputKey)
        if(event[inputKey] && event[inputKey] != '') count++
      }
      if(event?.options && this.fn?.isSteppedInputRequired){
          let keys = Object.keys(event.options)
          for(let eachRequiredField of event.requiredInputFields){
            if(keys.includes(eachRequiredField) && event?.options?.[eachRequiredField] && event.options[eachRequiredField] != '') count++
          }
        }
      if(event?.options && !this.fn?.isSteppedInputRequired) {
        let keys = Object.keys(event.options)
          for(let eachRequiredField of event.requiredInputFields){
            if(keys.includes(eachRequiredField) && event?.options?.[eachRequiredField] && event.options[eachRequiredField] != '') count++
          }
      }
      if(uniqueRequiredFields && uniqueRequiredFields.length){
        for(let uniqueKey of uniqueRequiredFields){
          if(list && list?.length){
            for(const obj of list){
              if(obj[uniqueKey] && obj[uniqueKey] !=''){
                count++;
              }
            }
          }
        }
      }
      this.executeEnabled = event.requiredInputFields.length == count ? true : false;
    } else {
      this.executeEnabled = true;
    }
  }

  returnInputs(){
    if(!this.executeEnabled){
      this.errorNoteShow = true
      return
    }
    console.log("returning from collect get fn inputs")
    let dialogData = {
      fnOptions: this.fnOptions,
      dependencyMap: this.inputParamsRecevied?.dependencyMap
    }
    this.dialogRef.close(dialogData)
  }

  closeDialog(){
    this.dialogRef.close({});
  }

}

