<div>
  <!-- <app-bloom-toolbar [bloomMeta]="bloomMap" [pages]="" [currentBloomCode]="currentBloomCode" -->
  <!-- [version]="" [isLoggedIn]=""> </app-bloom-toolbar> -->
  <spinner [center]="true"  [selfSpin]="spinner" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
  <div fxLayout="row" >
    <div fxLayout="column" fxFlex="10"></div>
    <div fxLayout="column" fxFlex="80">
        <div fxLayout="row" style="height: 5vh;"></div>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="bloomMap">
            Login to bloom {{bloomMap.name}}
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="bloomMap">
            <img style="height:200px;width:auto" src="{{bloomMap.logoUrl}}">
        </div>
        <div fxLayout="row" style="height: 5vh;"></div>
        <div fxLayoutAlign="center center" id="buttonDiv"> </div>
    </div>
    <div fxLayout="column" fxFlex="10"></div>

</div>
</div>

