import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CacheService } from './CacheService';
import { isPlatformBrowser } from '@angular/common';

interface chOptions {
  clearCache?: boolean;
  isLocalStorage?: boolean;
  ttl?: number,
  keyPrefix?: string,
  avoidAuthKey?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class HttpCacheService {
  isBrowser: any;
  constructor(
    private http: HttpClient,
    private cs: CacheService,
    @Inject(PLATFORM_ID) platformId?: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  listPanelLoadDataMeta: any

  getBoxMethodFromEndpoint(endpoint: string): string | null {
    const segments = endpoint.split('/');
    return segments.length >= 4 ? segments[3] : null;
  }

  handleChOptions(chOptions?: chOptions, cacheKey?: string){
    if (!chOptions || !cacheKey || !this.isBrowser) return;

    if (chOptions?.clearCache) {
      this.cs.clearCacheKey(cacheKey);
      this.deleteCacheBasedOnKeyPrefix(chOptions.keyPrefix);
    }

    // this.cs.setLocalStorage(chOptions.isLocalStorage);

    if (chOptions.ttl) {
      this.cs.setTimeToLive(chOptions.ttl);
    }
  }

  deleteCacheBasedOnKeyPrefix(keyPrefix: string){
    this.cs.deleteCacheBasedOnKeyPrefix(keyPrefix);
  }

  async get(url: string, options?: any, chOptions?: chOptions): Promise<any> {
    let cacheKey = null;
    if(this.isBrowser) cacheKey = this.cs.getCacheKey(url, options, null, chOptions);
    this.handleChOptions(chOptions, cacheKey);

    // console.log("[CACHE] chOptions", chOptions)
    if (this.isBrowser && this.cs.isValidData(cacheKey)) {
      console.log('[CACHE] get', this.cs.getData(cacheKey));
      return this.cs.getData(cacheKey, chOptions);
    } else {
      try {
        if(!options) options = {};
        if(chOptions?.clearCache){
          if(!options.headers) options.headers = {};
          options.headers["Cache-Control"] = 'no-cache'
        }
        const response = await this.http.get(url, options).toPromise();
        try {this.cs.setData(cacheKey, response, chOptions); } catch (e){};
        // console.log('[CACHE] set', this.cs.getData(cacheKey));
        return response;
      } catch (error) {
        throw error;
      }
    }
  }

  async post(url: string, body: any, options?: any,chOptions?: chOptions): Promise<any> {
    let cacheKey = null;
    if(this.isBrowser) cacheKey = this.cs.getCacheKey(url, options, body, chOptions);
    // console.log("[CACHE] chOptions", chOptions, body, this.isBrowser)
    // this.cs.isLocalStorage = true;
    this.handleChOptions(chOptions, cacheKey);
    // console.log('[CACHE] vkey', cacheKey);
    if (this.isBrowser && this.cs.isValidData(cacheKey)) {
      console.log('[CACHE] get', this.cs.getData(cacheKey));
      return this.cs.getData(cacheKey);
    } else {
      try {
        if(!options) options = {};
        if(chOptions?.clearCache){
          if(!options.headers) options.headers = {};
          options.headers["Cache-Control"] = 'no-cache'
        }
        console.log('[CACHE] options', options);
        const response = await this.http.post(url, body, options).toPromise();
        try {this.cs.setData(cacheKey, response)} catch(e) {};
        console.log('[CACHE] set', this.cs.getData(cacheKey));
        this.listPanelLoadDataMeta = this.cs.getData(cacheKey)
        console.log("[CACHE] response", response);
        return response;
      } catch (error) {
        console.log("cache error : ",error)
        throw error;
      }
    }
  }
}
