import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { AppSelectionModule } from 'src/app/shared/app-selection/app-selection.module';
import { ConnectionSelectionModule } from 'src/app/shared/connection-selection/connection-selection.module';
import { ParameterInputsModule } from 'src/app/shared/parameter-inputs/parameter-inputs.module';
import { BoxConfigInputsModule } from '../../box-config-inputs/box-config-inputs.module';

import { ChartDynamicAttributeConfig } from './chart-dynamic-attribute-config.component'
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { WidgetDataSourceBoxConfigModule } from 'src/app/shared/widget-data-source-box-config/widget-data-source-box-config.module';
import { FilterConfigModule } from 'src/app/shared/query-components/filter-config/filter-config.module';
import { SortConfigModule } from 'src/app/shared/query-components/sort-config/sort-config.module';
import { FilterComponentModule } from 'src/app/shared/query-components/filter-component/filter-component.module';

@NgModule({
    declarations: [
        ChartDynamicAttributeConfig
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatRadioModule,
        FlexLayoutModule,
        SpinnerModule,
        AppSelectionModule,
        ConnectionSelectionModule,
        ParameterInputsModule,
        BoxConfigInputsModule,
        WidgetDataSourceBoxConfigModule,
        FilterConfigModule,
        FilterComponentModule,
        SortConfigModule
    ],
    exports: [
        ChartDynamicAttributeConfig
    ]
})
export class ChartDynamicAttributeConfigModule { }
