<div class="outerCover rich-text-div" *ngIf="builderMode" fxLayout
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id ? false : hoveredNow" (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()" (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>

  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>

  <quill-editor *ngIf="isBrowser"
    #editor
    (mousedown)="$event.stopPropagation()"
    (onContentChanged)="contentChange($event)"
    (onBlur)="onBlur(editor)"
    (onEditorCreated)="getEditorInstance($event)"
    fxFlexFill
    [placeholder]="widgetMeta.config.placeholder.value"
    [(ngModel)]="value"
  >
    <div quill-editor-toolbar>
      <span class="ql-formats">
        <select class="ql-header" [title]="'Header'">
          <option selected></option>
          <option value="1"></option>
          <option value="2"></option>
          <option value="3"></option>
          <option value="4"></option>
          <option value="5"></option>
          <option value="6"></option>
        </select>
      </span>

      <span class="ql-formats">
        <select class="ql-font" [title]="'Font Family'">
          <option value="mirza" selected></option>
          <option value="roboto"></option>
          <option value="aref"></option>
          <option value="serif"></option>
          <option value="sansserif"></option>
          <option value="monospace"></option>
        </select>
        <select class="ql-size" [title]="'Font Size'" style="width: 64px">
          <option value="10px" selected></option>
          <option value="12px"></option>
          <option value="14px"></option>
          <option value="16px"></option>
          <option value="18px"></option>
          <option value="20px"></option>
          <option value="22px"></option>
          <option value="24px"></option>
          <option value="30px"></option>
        </select>
      </span>

      <span class="ql-formats">
        <button class="ql-bold" [title]="'Bold'"></button>
        <button class="ql-italic" [title]="'Italic'"></button>
        <button class="ql-underline" [title]="'Underline'"></button>
        <select class="ql-color" [title]="'Color picker'">
          <option></option>
          <option value="#e60000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#008a00"></option>
          <option value="#0066cc"></option>
          <option value="#9933ff"></option>
          <option value="#ffffff"></option>
          <option value="#facccc"></option>
          <option value="#ffebcc"></option>
          <option value="#ffffcc"></option>
          <option value="#cce8cc"></option>
          <option value="#cce0f5"></option>
          <option value="#ebd6ff"></option>
          <option value="#bbbbbb"></option>
          <option value="#f06666"></option>
          <option value="#ffc266"></option>
          <option value="#ffff66"></option>
          <option value="#66b966"></option>
          <option value="#66a3e0"></option>
          <option value="#c285ff"></option>
          <option value="#888888"></option>
          <option value="#a10000"></option>
          <option value="#b26b00"></option>
          <option value="#b2b200"></option>
          <option value="#006100"></option>
          <option value="#0047b2"></option>
          <option value="#6b24b2"></option>
          <option value="#444444"></option>
          <option value="#5c0000"></option>
          <option value="#663d00"></option>
          <option value="#666600"></option>
          <option value="#003700"></option>
          <option value="#002966"></option>
          <option value="#3d1466"></option>
        </select>
        <input id="color-picker" type="color" (change)="onColorChanged($event)" [title]="'Custom Color Picker'"
          [(value)]="selectedcolor" />
      </span>

      <span class="ql-formats">
        <button class="ql-link"></button>
      </span>

      <span class="ql-formats">
        <button class="ql-image"></button>
     </span>

      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
      </span>

      <span class="ql-formats">
        <button class="ql-clean"></button>
      </span>
    </div>
  </quill-editor>

</div>

<div
  class="outerCover rich-text-div" *ngIf="!builderMode" fxLayout (click)="onClick($event)"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <ng-container *ngIf="widgetMeta.config.viewOnly.value == false; else viewOnlyMode">
    <quill-editor *ngIf="isBrowser" #editor (mousedown)="$event.stopPropagation()" (click)="$event.stopPropagation(); onClick($event);"
      (onContentChanged)="contentChange($event)" (onBlur)="onBlur(editor)" [(ngModel)]="widgetMeta.config.contentHtml.value"
      (onEditorCreated)="getEditorInstance($event)" fxFlexFill>
      <div quill-editor-toolbar>
        <span class="ql-formats">
          <select class="ql-header" [title]="'Header'">
            <option selected></option>
            <option value="1"></option>
            <option value="2"></option>
            <option value="3"></option>
            <option value="4"></option>
            <option value="5"></option>
            <option value="6"></option>
          </select>
        </span>

        <span class="ql-formats">
          <select class="ql-font" [title]="'Font Family'">
            <option value="mirza" selected></option>
            <option value="roboto"></option>
            <option value="aref"></option>
            <option value="serif"></option>
            <option value="sansserif"></option>
            <option value="monospace"></option>
          </select>
          <select class="ql-size" [title]="'Font Size'" style="width: 64px">
            <option value="10px" selected></option>
            <option value="12px"></option>
            <option value="14px"></option>
            <option value="16px"></option>
            <option value="18px"></option>
            <option value="20px"></option>
            <option value="22px"></option>
            <option value="24px"></option>
            <option value="30px"></option>
          </select>
        </span>

        <span class="ql-formats">
          <button class="ql-bold" [title]="'Bold'"></button>
          <button class="ql-italic" [title]="'Italic'"></button>
          <button class="ql-underline" [title]="'Underline'"></button>
          <select class="ql-color" [title]="'Color picker'">
            <option></option>
            <option value="#e60000"></option>
            <option value="#ff9900"></option>
            <option value="#ffff00"></option>
            <option value="#008a00"></option>
            <option value="#0066cc"></option>
            <option value="#9933ff"></option>
            <option value="#ffffff"></option>
            <option value="#facccc"></option>
            <option value="#ffebcc"></option>
            <option value="#ffffcc"></option>
            <option value="#cce8cc"></option>
            <option value="#cce0f5"></option>
            <option value="#ebd6ff"></option>
            <option value="#bbbbbb"></option>
            <option value="#f06666"></option>
            <option value="#ffc266"></option>
            <option value="#ffff66"></option>
            <option value="#66b966"></option>
            <option value="#66a3e0"></option>
            <option value="#c285ff"></option>
            <option value="#888888"></option>
            <option value="#a10000"></option>
            <option value="#b26b00"></option>
            <option value="#b2b200"></option>
            <option value="#006100"></option>
            <option value="#0047b2"></option>
            <option value="#6b24b2"></option>
            <option value="#444444"></option>
            <option value="#5c0000"></option>
            <option value="#663d00"></option>
            <option value="#666600"></option>
            <option value="#003700"></option>
            <option value="#002966"></option>
            <option value="#3d1466"></option>
          </select>
          <input id="color-picker" type="color" (change)="onColorChanged($event)" [title]="'Custom Color Picker'"
            [(value)]="selectedcolor" />
        </span>

        <span class="ql-formats">
          <button class="ql-link"></button>
        </span>

        <span class="ql-formats">
          <button class="ql-link"></button>
        </span>

        <span class="ql-formats">
          <select class="ql-align" [title]="'Alingment'">
            <option selected></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span>

        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
        </span>

        <span class="ql-formats">
          <button class="ql-clean"></button>
        </span>
      </div>
    </quill-editor>
  </ng-container>
  <ng-template #viewOnlyMode>
    <quill-view [content]="widgetMeta.config.contentHtml.value" style="width: 100%;"></quill-view>
  </ng-template>

</div>
