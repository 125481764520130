import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'autocomplete',
  templateUrl: './mat-autocomplete.component.html',
  styleUrls: ['./mat-autocomplete.component.scss'],
})
export class MatAutocompleteComponent implements OnInit {

  @Output() result = new EventEmitter<{ key: string, data: Array<string> }>();

  @Input() placeholder: string = 'Select Data';
  @Input() data: Array<any> = [];
  @Input() key: string = '';

  selectControl = new UntypedFormControl();

  rawData: Array<any> = [];
  @Input() selectedValue: any = null;
  @Input() isSetData: boolean = false;
  @Input() "focus": boolean = false;

  filteredData: Observable<Array<any>>;
  filterString: string = '';
  @ViewChild('autoInput') inputField;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  constructor() {
    // this.getFilteredOptions()
  }

  ngOnInit(): void {

    console.log("data selectedValue", this.data, this.selectedValue)
    this.getDataFn();
    if(this.selectedValue) this.setDataFn()
      //

  }

  async ngAfterViewInit(){
    if(this.focus && !this.selectedValue)  {
      setTimeout(() => {
        this.inputField?.nativeElement.focus();
        const currentValue = this.inputField.nativeElement.value;
        this.filteredData = of(this.filterData(currentValue));
        this.autocompleteTrigger.openPanel();
      }, 100);
    }
  }

  filterData(value: string): any[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.data.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  getFilteredOptions(){
    this.filteredData = this.selectControl.valueChanges.pipe(
      startWith<string>(''),
      map((value: any) => typeof value === 'string' ? value : value?.name || ''),
      map(filter => this.filter(filter))
    );
  }

  ngOnChanges(){
    console.log("data selectedValue on changes", this.data, this.selectedValue)
    this.getDataFn();
    if(this.selectedValue) this.setDataFn()
  }

  getDataFn() {
    this.rawData = [];
    this.data.forEach((item: any) => {
      this.rawData.push(item);
    });
  }

  setDataFn() {
    this.selectControl.setValue(this.selectedValue);
    this.emitAdjustedData(this.selectedValue);
  }

  filter = (filter: any): Array<any> => {
    let filterValue;
    if (typeof filter == 'string') {
      filterValue = filter.toLowerCase();
    } else {
      filterValue = filter.name.toLowerCase();
      console.log(filterValue);
    }
    return this.rawData.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  };

  displayFn(subject: any) {
    return subject ? subject.name : undefined;
  }

  optionClicked = (event: Event, data: any): void => {
    event.stopPropagation();
    console.log(event, data)
    this.toggleSelection(data);
  };

  toggleSelection = (data: any): void => {
    console.log("data--->", data)
    this.emitAdjustedData(data?.option?.value);
  };

  emitAdjustedData(data) {
    this.result.emit(data);
  };

}
