import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

export interface CustomRoute extends Route {
  data?: {
    preload?: boolean;
  };
}

@Injectable({
  providedIn: 'root'
})
export class CustomPreloadingService implements PreloadingStrategy {
  preload(route: CustomRoute, fn: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload) {
      // Replace with a more structured logging solution or remove in production
      console.log('Preloading:', route.path);
      return fn();
    } else {
      return of(null);
    }
  }

  constructor() { }
}
