import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
  selector: 'datatype-selection',
  templateUrl: './datatype-selection.component.html',
  styleUrls: ['./datatype-selection.component.scss']
})
export class DatatypeSelectionComponent implements OnInit {
  spinner: boolean;

  constructor(
    public con: ConnectionService,
    private dialog: MatDialog,
    // private spinner: SpinnerService
  ) { }


  dataTypes: any[] = [
    {
      name: "Number",
      __id: "number",
      icon: "123"
    },
    {
      name: "String",
      __id: "string",
      icon: "text_fields"
    },
    {
      name: "Date",
      __id: "date",
      icon: "calendar_today"
    },
    {
      name: "Time",
      __id: "time",
      icon: "schedule"
    },
    {
      name: "Datetime",
      __id: "datetime",
      icon: "event"
    },
    {
      name: "Timestamp",
      __id: "timestamp",
      icon: "timer"
    },
    {
      name: "Object",
      __id: "object",
      icon: "data_object"
    },
    {
      name: "Boolean",
      __id: "boolean",
      icon: "radio_button_checked"
    },
    {
      name: "Array",
      __id: "array",
      icon: "data_array"
    },
    {
      name: "Password",
      __id: "password",
      icon: "password"
    },
    {
      name: "Url",
      __id: "url",
      icon: "link"
    }
  ]

  selDatatype: any = "string";

  @Input() "existingType": any;
  @Input() "appearance": any = "outline";
  @Input() "float": any = "never";
  @Input() isDisabled: any;
  @Output() "selectedType" = new EventEmitter<any>();


  async ngOnInit() {
    this.spinner = true;

    if(this.existingType){
      this.selDatatype = this.existingType;
    }
    this.selectedType.emit(this.selDatatype);
    this.spinner = false;
  }

  selectedDatatype(type){
    this.selectedType.emit(this.selDatatype);
  }

  async ngAfterViewInit(){

  }

}
