import { Component, OnInit, OnChanges, ViewChild, SimpleChanges, AfterViewInit, ElementRef, EventEmitter, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs/internal/Subject';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ActionManager } from 'src/app/bloom/models/Action/ActionManager';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css'],
  // encapsulation: ViewEncapsulation.ShadowDom
})
export class LabelComponent extends BaseWidgetComponent implements OnInit, OnChanges, AfterViewInit {
  @Output() onExecuteAction = new EventEmitter<any>();

  // @Input() inputText: string = ''
  labelName;
  contextMenuActions: any;
  styles: any;
  textEditMode: boolean = false;
  dataBindValue: any;
  @ViewChild('labelInput', { static: false }) public label: ElementRef
  pageModelSub: any
  pageModel: any
  panelMeta: any;

  showConfig = false;
  labelText: string = ''
  lines: number = 1;
  private destroy:any = new Subject();
  modeSwitchTransition: boolean = false

  @ViewChild('labelInput') labelInput: ElementRef<HTMLTextAreaElement>;

  constructor(public pageService: PageService, public metaService: MetaService, public actionManager: ActionManager,
    public resourcePermissionService: ResourcePermissionService, private renderer: Renderer2, widgetService: WidgetService
  ) {
    // super call should be the first statement in any derived class constructor
    //if property injections from parent needs to be referenced, (same copy), it needs to be passed into super. e.g. super(router)
    super(metaService, pageService, resourcePermissionService, widgetService)

    // console.log("widgetMeta", this.widgetMeta)
    // console.log("widgetMeta.config", this.widgetMeta.config)
  }

  ngAfterViewInit() {
  }

  autoResize(): void {
    console.log("autoResize")
    const textarea = this.labelInput.nativeElement;

    // Reset the height to 'auto' so it shrinks when needed
    textarea.style.height = 'auto';

    // Set the height to the scrollHeight to fit the content
    textarea.style.height = textarea.scrollHeight + 'px';
    console.log("applied scroll height", textarea.scrollHeight)
  }

  calculateLines(){
    this.lines = this.widgetMeta?.config?.labelText?.value?.split('\n')?.length || 1
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
      // if(this.widgetOptions.deleteEnabled !== false) this.contextMenuActions.actions.push('delete')
      if(this.widgetOptions?.configurationEnabled !== false) this.contextMenuActions.actions.push('edit')
      // this.contextMenuActions.actions.push('copy')
    }

    if(changes.contextActions?.currentValue){
      console.log("context changed", changes.contextActions.currentValue)
      this.action(changes.contextActions.currentValue)
    }
    // console.log("changes in label", changes)
    super.generateStyles()

    // detect input change and use text
    // if(changes['inputText'].currentValue){
    //   this.widgetMeta.config.labelText.value = changes['inputText'].currentValue
    // }
  }

  ngOnInit(): void {
    // console.log("LABEL widget ngOnInit()", this.widgetMeta)
    super.ngOnInit()
    // console.log("label onInit", this.widgetMeta)
    // console.log("styles in label", this.styles)
    this.panelMeta = this.pageMeta?.panels?.find(p => p.id == this.panelId)
    
    this.pageService.$dataModelSub.subscribe((dataModel: any) => {
      // console.log("data model received in Label", dataModel)
      // console.log("dataBindConfig", this.widgetMeta.dataBindConfig)
      if (this.widgetMeta?.dataBindConfig) {
        let connId = this.widgetMeta.dataBindConfig['connectionId']
        let boxObject = this.widgetMeta.dataBindConfig['boxObjectId']
        let boxAttribute = this.widgetMeta.dataBindConfig['boxAttributeId']

        if (
          dataModel[connId]
          && dataModel[connId][boxObject]
          && dataModel[connId][boxObject][boxAttribute]
          && dataModel[connId][boxObject][boxAttribute].hasOwnProperty('value')
          // && typeof dataModel[connId][boxObject][boxAttribute]['value'] == 'string'
        ) {
          this.dataBindValue = dataModel[connId][boxObject][boxAttribute]['value']

          this.widgetMeta.config.labelText.value = this.dataBindValue
        }
      }
    })

    this.destroy = this.metaService?.$contextChanged.subscribe((contextActions: any) => {
      // console.log("context changed subscription", JSON.parse(JSON.stringify(contextActions || {})))
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })

    this.pageModelSub = this.pageService.$pageModelSub.subscribe(pageModel => {
      if(this.panelMeta?.type == "regular"){
        // console.log("page model subscription", JSON.parse(JSON.stringify(pageModel)))
        this.pageModel = JSON.parse(JSON.stringify(pageModel))
        this.handleTemplates()
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy?.unsubscribe();
    this.pageModelSub.unsubscribe();
  }

  //------------------METHODS-----------------------


  /**
   * replace templated values
   */
  handleTemplates(){
    if(this.builderMode) return
    if(this.panelMeta.type !== 'regular') return
    let replacementMap = {}
    Object.keys(this.pageModel).forEach(panelId => {
      Object.keys(this.pageModel[panelId]).forEach(widId => {
        replacementMap[panelId] = replacementMap[panelId] || {}
        replacementMap[panelId][widId] = this.isTemplateString(this.pageModel[panelId][widId].value) ? "" : this.pageModel[panelId][widId].value
      })
    })
    // console.log("template", JSON.parse(JSON.stringify(this.widgetMeta.config.labelText.value)))
    console.log("replacement map created", replacementMap)
    let te = new TemplateEngine()
    let newVal = te.fillAny(this.widgetMeta.config.labelText.value, replacementMap, true)
    console.log("replaced", newVal)
    this.widgetMeta.config.labelText.value = newVal
  }


  isTemplateString(val: string){
    if(String(val)?.match(/\$\{[^\}]+\}/g)?.length) return true
    return false
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "bold",
        "underline",
        "italic",
        "color",
        "backgroundColor",
        "fontSize",
        "fontFamily",
        "class",
        "alignment",
      ],
      alignment: {
        value: this.widgetMeta.config.alignment.value ? this.widgetMeta.config.alignment.value : 'flex-start',
        type: this.widgetMeta?.config.alignment.type
      }
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", JSON.parse(JSON.stringify(event)))
    switch (event.actionType) {
      case "delete":
        this.onDelete();
        break;
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = JSON.parse(JSON.stringify(event.data));
          console.log("localMeta changed", JSON.parse(JSON.stringify(this.widgetMeta)))
          this.newWidgetMeta.emit(this.widgetMeta)
          // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
          super.generateStyles();
          // console.log("styles re-generated", this.styles)
        }
        break;

      default:
        break;
    }
  }

  timer: any = 0;
  delay = 200;

  async onClick(event) {
    console.log("onclick event", event)
    if(this.builderMode){
      // this.onOuterClick(event)
      if(this.widgetOptions?.textEdit == false) return
      this.widgetSelection.emit(this.widgetMeta.id)
      if(!this.textEditMode) { this.turnOnEditMode() }
    } else {
      let res = await this.executeAction(event)
      this.onExecuteAction.emit(res);
    }
    
  }

  async executeAction(e) {
    if(!this.widgetMeta.actionConfig || !this.widgetMeta.actionConfig.actions) return
    if (!this.builderMode){
      let res = await this.actionManager.executeActions(this.widgetMeta.actionConfig.actions, e);
      return res
    }
    //  this.actionManager.getAction(this.widgetMeta.actionConfig.action).doAction(this.widgetMeta.actionConfig, e)
  }

  turnOnEditMode() {
    console.log("turn on edit mode")
    this.modeSwitchTransition = true;
    let lastInnerCoverHeight = this.labelInput.nativeElement.parentElement.offsetHeight
    console.log("last applied height", lastInnerCoverHeight)
    this.labelInput.nativeElement.parentElement.style.height = lastInnerCoverHeight + "px";
    this.textEditMode = true;
    
    setTimeout(() => { 
      // console.log("setTimeout, textarea", this.labelInput.nativeElement)
      this.labelInput.nativeElement.focus(); 
      const event = new InputEvent('input', { data: '' })
      
      // Dispatch the event
      this.labelInput.nativeElement.dispatchEvent(event);
      // console.log("dispatch event: ", event)
      this.autoResize()
      setTimeout(() => {
        this.modeSwitchTransition = false
      this.labelInput.nativeElement.parentElement.style.height = "auto";
      }, 5)
    }, 5);
    this.widgetService.openWidgetSidebarSettings.next({
      widgetMeta: this.widgetMeta,
      panelId: this.panelId
    })
  }

  onFocusOut(event: any) {
    console.log("onFocusOut", event)
    // this.autoResize()
    this.calculateLines()
    this.textEditMode = false
    console.log("onFocusOut called. new edited text", this.widgetMeta.config.labelText.value)
    this.newWidgetMeta.emit(this.widgetMeta)
    // this.widgetService.widgetDeselected.next(true)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
  }

  onInput(event){
    console.log("onInput called")
    this.autoResize()
  }
}
