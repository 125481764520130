import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { BallAtomSpinnerComponent } from '../ball-atom-spinner/ball-atom-spinner.component';
import { SpinnerService } from '../spinner.service';

@Component({
  selector: 'app-ball-clip-rotate-spinner',
  templateUrl: './ball-clip-rotate-spinner.component.html',
  styleUrls: ['./ball-clip-rotate-spinner.component.scss'],
})
export class BallClipRotateSpinnerComponent extends BallAtomSpinnerComponent implements OnInit {
  constructor(
    public spinnerService: SpinnerService,
    public themeService: ThemeService
  ) {
    super(spinnerService, themeService);
  }

  ngOnInit(): void {
  }
}
