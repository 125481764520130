import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormHomeComponent } from './form-home/form-home.component';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { FormPageComponent } from './form-page/form-page.component';
import { FormViewerCanvasComponent } from './form-viewer-canvas/form-viewer-canvas.component';
import { ResponsesComponent } from './responses/responses.component';
import { SuccessPageConfigComponent } from './success-page-config/success-page-config.component';
import { NewFormComponent } from './new-form/new-form.component';

const routes: Routes = [
  {
    path: '',
    component: FormHomeComponent,
    data: { title: 'Forms - AppiWorks', description: 'Explore your Forms' }
  },
  {
    path: 'new',
    component: NewFormComponent,
    data: { title: 'Form - New - AppiWorks', description: 'Create new Form' }
  },
  {
    path: 'build',
    component: FormBuilderComponent,
    children: [
      {
        path: ':formcode', component: FormPageComponent,
        data: { title: 'Form - Build - AppiWorks', description: 'Build the Form' }
      },
      { path: ':formcode/success', component: SuccessPageConfigComponent},
    ],
  },
  {
    path: 'p',
    component: FormViewerCanvasComponent,
    children: [
      { path: ':formcode', component: FormPageComponent},
      { path: ':formcode/success', component: SuccessPageConfigComponent},
    ],
  },
  {
    path: 'responses/:formcode',
    component: ResponsesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormRoutingModule { }
