<spinner
  [center]="true"
  [selfSpin]="true"
  *ngIf="spinner"
  size="la-2x"
  color="grey"
  type="ball-triangle-path"
></spinner>


<div>
  <br />
  <div fxLayout="row">
    {{errorMsg}}
  </div>
</div>
