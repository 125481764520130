<div style="width: 100%; padding: .5rem; box-sizing: border-box;">
  <div *ngIf="!hideToggle">
    <mat-slide-toggle
      [checked]="group.groupEnabled"
      (change)="groupToggleChanged($event)"
      color="primary"
    > Apply Group </mat-slide-toggle>
    <div style="height: 1rem"></div>
  </div>

  <form *ngIf="group.groupEnabled" style="width: 100%;">
    <mat-form-field style="width: 100%;" appearance="standard">
      <mat-label>Group Attributes</mat-label>
      <mat-chip-grid #chipList multiple="true">
        <mat-chip-row *ngFor="let attr of group.attributes" (removed)="removeGroup(attr)">
          {{attr.name}}
          <button matChipRemove [attr.aria-label]="'remove '">
            <mat-icon style="margin: -6px -13px;">cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input placeholder="Choose Attribute"  (focus)="setGroupList()" #fruitInput [formControl]="groupAttriCtrl"
          [matChipInputFor]="chipList" [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addGroup($event)"/>
        <mat-autocomplete [displayWith]="displayFn" #auto="matAutocomplete" (optionSelected)="selectedGroup($event)">
          <mat-option *ngFor="let att of attributesForGroup | async" [value]="att">
            {{att.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-chip-grid>
    </mat-form-field>
  </form>
</div>
