import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
// import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { MixpanelService } from './mixpanel.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { mode, pad, enc, AES } from 'crypto-js';
import { SmsService } from './sms-service.service';
import { HttpCacheService } from 'src/app/core/services/HttpCacheService';
import { isPlatformBrowser } from '@angular/common';

declare const google: any

export interface userProfile {
  email: string;
  firstName?: string;
  lastName?: string;
  imgUrl?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  authenticationType:string;
  origin:string;
  loggedIn: boolean = false;

  organizations: any = [];
  selectedOrganization: string;

  authCheckPositive = new Subject<any>()

  profileLoaded = new BehaviorSubject<any>('')
  simulated = new Subject<any>()
  userExist = new Subject<any>()
  simulationStatus: boolean = false;

  subproduct: any;

  private changeOrg = new Subject<any>();
  // userMap: any;

  private _profile: any = {};
  onGuestPage: boolean = false;
  isFromMobile: boolean;
  simulationChange: boolean;
  isBrowser: boolean;
  navigateParams: any;
  get profile(){
    return this._profile
  }
  set profile(v){
    this._profile = v
  }
  loggedInProfile: any = {}
  userProfile: any = {};



  constructor(
    public http: HttpClient,
    private _ngZone: NgZone,
    public mps: MixpanelService,
    private router: Router,
    public tokenUtil: TokenUtil,
    public smsService: SmsService,
    private httpCs: HttpCacheService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if(!this.isBrowser) return;
  };

  async updateUser(body){
    var result
    try {
      let url = `${environment.SERVER_BASE_URL}/user/${body.__id}`;
      var response:any = await this.http.put(url, body).toPromise();
      console.log("updated result", response)
      result = response?.data?.[0];
    } catch (err) {
      console.log("Error on updating user", err);
    } finally {
      this.httpCs.deleteCacheBasedOnKeyPrefix("user_");
      return result;
    }
  }

  isInternalUser(){
    return this.userProfile?.email?.includes("@jivrus") || false;
  }

  async regenerateApiKey(){
    console.log("profile", this.profile)
    this.profile.user_api_key = uuidv4();
    await this.updateUser(this.profile);
  }

  async checkAndCreateUser(options?: any) {
    const { password, isNewUser, user_profile, email } = options || {};
    console.log("[CHECK AND CREATE USER]", this.userProfile)
    if (user_profile) this.userProfile = user_profile
    try {
      // this.spinner = true;
      let existingProfile: any = user_profile ? undefined : await this.getWorkspaceUser(email);
      console.log("existingProfile", existingProfile)

      let createResponse;
      if (!existingProfile) {
        console.log("calling createNewUser from checkAndCreate")
        createResponse = await this.createNewUser(true, password);
      }
      if(!existingProfile && !createResponse){
        console.log("user creation failed")
        return false
      } else if(!existingProfile && createResponse){
        existingProfile = await this.getWorkspaceUser(null, true);
        if(!existingProfile){
          console.log("USER CREATED BUT WORKSPACE USER NOT FOUND!!! SOMETHING WRONG")
          return false
        }
      }

      if (!existingProfile.user_api_key) {
        existingProfile.user_api_key = uuidv4();
        if(!this.simulationStatus){
          await this.updateUser(existingProfile);
        }
      }

      if(!existingProfile.first_name || !existingProfile.last_name || !existingProfile.image_url) {
        existingProfile.first_name = this.userProfile.first_name;
        existingProfile.last_name = this.userProfile.last_name;
        existingProfile.image_url = this.userProfile.image_url;
        if(!this.simulationStatus){
          await this.updateUser(existingProfile);
        }
      }

      this.mixPanelinit(isNewUser, existingProfile)

      this.loggedIn = true;
      this.profile = existingProfile;
      this.userProfile = existingProfile;
      if(existingProfile && options?.storeAuth){
        var sessionObject = this.encryptUsingAES256({
          userSignInTime: (new Date()).getTime(),
          user: existingProfile,
          authType: options.authType
        })
        sessionStorage.setItem('_AWuserSession', sessionObject);
      }
      console.log("profile and userProfile", this.userProfile)
      console.log("nexting into profileLoaded");
      this.profileLoaded.next(this.profile)
      this.setOrganization(existingProfile);
      await this.smsService.getSMSAuthentication(this.userProfile);
      return true
    } catch (err) {
      console.log("Error on checkAndCreateUser", err)
      return false
    }
  }

   //-------encryption------
   encryptUsingAES256(input) {
    let _key = enc.Utf8.parse("493531ea-22e8-431c-9c27-4a5ea194343e");
    let _iv = enc.Utf8.parse("cac782ee-d9c4-45a9-ba0e-d99145d49739");
    let encrypted = AES.encrypt(
      JSON.stringify(input), _key, {
      keySize: 16,
      iv: _iv,
      mode: mode.ECB,
      padding: pad.Pkcs7
    });
    var result = encrypted.toString();
    return result;
  }

  async simulateUser(){
    console.log("[CHECK AND CREATE USER]", this.userProfile)
    try {
      // this.spinner = true;
      let existingProfile: any = await this.getWorkspaceUser(this.userProfile.email);

      console.log("existingProfile", existingProfile)
      if(!existingProfile){
        this.userExist.next(false);
        return;
      }

      if(!existingProfile.first_name || !existingProfile.last_name || !existingProfile.image_url) {
        existingProfile.first_name = this.userProfile.first_name;
        existingProfile.last_name = this.userProfile.last_name;
        existingProfile.image_url = this.userProfile.image_url;
      }

      this.loggedIn = true;
      this.profile = existingProfile;
      this.userProfile = existingProfile
      this.profileLoaded.next(this.profile)
      // this.setOrganization(existingProfile);
      this.simulated.next(null);
      return true
    } catch (err) {
      console.log("Error on checkAndCreateUser", err)
      return false
    }

  }

  async createNewUser(isNewUser?, password?) {
    console.log("[CREATE NEW USER] userProfile", this.userProfile)
    let that = this;
    // this.userProfile.email;
    let url = `${environment.SERVER_BASE_URL}/user`;
    let body: any = {
      // userProfile: this.userProfile,
      email: this.userProfile.email,
      first_name: this.userProfile.first_name,
      last_name: this.userProfile.last_name,
      image_url: this.userProfile.image_url,
      user_api_key: uuidv4(),
      authenticationType: that.authenticationType
    };
    if (this.authenticationType == 'manual' && password) body.password = password;

    console.log("çreate user body", body);
    try {
      let response: any = await that.http.post(url, body).toPromise()
      console.log("create user response ---->", response);
       return response.success
    } catch(e){
      throw e;
    }
  }

  async createNewBloomUser(userProfile) {
    console.log("[CREATE NEW USER] userProfile", this.userProfile)
    let that = this;
    let url = `${environment.SERVER_BASE_URL}/user`;
    let body: any = {
      email: userProfile.email,
      first_name: userProfile.first_name,
      last_name: userProfile.last_name,
      image_url: userProfile.image_url,
      user_api_key: uuidv4(),
      authenticationType: "google"
    };
    let response: any = await that.http.post(url, body).toPromise()
    return response.success
  }

  async createNewUserWithEmail(email) {
    let url = `${environment.SERVER_BASE_URL}/user`;
    let body: any = {
      email: email,
      user_api_key: uuidv4()
    };
    let response: any = await this.http.post(url, body).toPromise()
    console.log("create user email response ---->", response);
    return response.data;
  }
 //method to get the logged-in user's email
 getUserEmail(): string | undefined {
  return this.userProfile?.email;
}
  handleCredentialResponse(e){
    console.log("in handle", e)
    var userMap = this.tokenUtil.getDecodedJWT(e.credential);
    let userProfile = {
      email: userMap.email,
      first_name: userMap?.given_name,
      last_name: userMap?.family_name,
      image_url: userMap?.picture
    }

    this.loggedInProfile = userProfile;
    console.log("userProfile check", userProfile)
    // this.smsService.getSMSAuthentication(userProfile);
    this._ngZone.run(async ()=>{
      this.authenticationType = 'google';
      this.userProfile = userProfile;
      let options = {authType: this.authenticationType, storeAuth: true};
      let res = await this.checkAndCreateUser(options)
      let isSuccess = true;
      if(!res) isSuccess = false;
      this.authCheckPositive.next(isSuccess);
    })
  }

  authCheck(isAskConfirm?){
    console.log("authcheck isAskConfirm", isAskConfirm, this.isBrowser)
    if(!this.isBrowser) return;
    if(!this.loggedIn){
      if (sessionStorage && sessionStorage.getItem('_AWuserSession')){
        var obj = sessionStorage.getItem('_AWuserSession');

        var sessionObj = JSON.parse(this.decryptUsingAES256(obj));
        console.log("sessionStorage sessionObj", sessionObj)
        var sessionExpireAt = sessionObj.userSignInTime + 30*60*1000;
        if ((new Date()).getTime() > sessionExpireAt){
          sessionStorage.clear();
          this.userProfile = undefined;
          if(sessionObj && sessionObj.authType == 'google'){
            this.processGoogleLogin(isAskConfirm);
          } else {
            this._ngZone.run(() => this.router.navigate(['']))
          }
        } else {
          let userProfile = {
            email: sessionObj.user?.email,
            first_name: sessionObj?.user?.first_name,
            last_name: sessionObj?.user?.last_name,
            image_url: sessionObj?.user?.image_url
          }
          this.loggedInProfile = userProfile;
          console.log("userProfile check", userProfile)
          this.smsService.getSMSAuthentication(userProfile);
          this._ngZone.run(async ()=>{
            this.authenticationType = 'manual';
            this.userProfile = userProfile
            let res = await this.checkAndCreateUser();

            this.authCheckPositive.next(true)
          })
        }
      } else {
        this.processGoogleLogin(isAskConfirm);
      }
    } else {
      this.authCheckPositive.next(true)
    }
  }

  processGoogleLogin(isAskConfirm){
    var auto_select = isAskConfirm ? false : true;
    console.log("google", google)
    google.accounts.id.initialize({
        client_id:  environment.GOOGLE_CLIENT,
        callback: this.handleCredentialResponse.bind(this),
        auto_select: auto_select
    })
    google.accounts.id.prompt((notification) => {
      console.log("notification popup ", notification)
      if (notification.isSkippedMoment()) {
          // try next provider if OneTap is not displayed or skipped
          this.authCheckPositive.next(false)
        }
      })
  }


  async getWorkspaceUser(email?, clearCache:boolean = false) {
    var result = null
    let url = `${environment.SERVER_BASE_URL}/user/${email ? email: this.userProfile.email}`;
    console.log("get user email %s and url:", email, url)
    try{
      // var data = await this.http.get(url).toPromise();
      let chOptions: any = {isLocalStorage: true, keyPrefix: "user_", clearCache: clearCache};
      var data = await this.httpCs.get(url, null, chOptions);
      result = data;
      // this.userMap = result;
    } catch(error){
      console.log("Error while get Workspace user", error)
      throw error;
    };
    return result;
  }

  async checkAndGetBloomUser(e){
    var userMap = this.tokenUtil.getDecodedJWT(e.credential);
    let userProfile = {
      email: userMap.email,
      first_name: userMap?.given_name,
      last_name: userMap?.family_name,
      image_url: userMap?.picture
    }

    let existingProfile: any = await this.getWorkspaceUser(userMap.email);
    if (!existingProfile) {
        await this.createNewBloomUser(userProfile);
        existingProfile = await this.getWorkspaceUser(userMap.email);
    }
    return existingProfile;
  }

  async handleUser(userMap){
    let userProfile = {
      email: userMap.email,
      first_name: userMap?.given_name,
      last_name: userMap?.family_name,
      image_url: userMap?.picture
    }
    let existingProfile: any = await this.getWorkspaceUser(userMap.email);
    if (!existingProfile) {
        await this.createNewBloomUser(userProfile);
        existingProfile = await this.getWorkspaceUser(userMap.email);
    }
    return existingProfile;
  }



  /**
   * Initialize mix panel
   * @param isNewUser
   * @param existingProfile
   */
   mixPanelinit(isNewUser: boolean, existingProfile: any){
    if (isNewUser) {
      var mixAction = "Sign Up";
    } else {
      mixAction = "Sign In";
    }

    //set mixpanel
    this.mps.init(existingProfile._id)
    this.mps.track(mixAction, {
      "Email": existingProfile.email
    })
    var mixProfile: any = {
      $email: existingProfile.email
    };
    if (existingProfile?.user_profile?.imgUrl) mixProfile['$avatar'] = existingProfile?.user_profile?.imgUrl;
    if (existingProfile?.user_profile?.firstName) mixProfile['$name'] = `${existingProfile?.user_profile?.firstName} ${existingProfile?.user_profile?.lastName}`;
    this.mps.setPeople(mixProfile);
  }



  // ------------------------------------------------------------------------------------------------


  //-----------------------------------

  sendOrganizationChange(id) {
    this.changeOrg.next(id);
  }
  getOrganizationChange(): Observable<any> {
    return this.changeOrg.asObservable();
  }

  setOrganization(profile) {
    if (profile.organization && profile.organization.length < 0) {
    } else {
      var object = {
        name: 'Default',
        id: 'default',
      };
      this.organizations.push(object);
      this.selectedOrganization = 'default';
    }
    this.sendOrganizationChange(this.selectedOrganization);
  }

  async setSimulation(email){
    this.simulationStatus = true;
    this.simulationChange = true;
    console.log(this.userProfile)
    console.log(this.profile, this.loggedInProfile)
    this.userProfile.email = email;
    let res = await this.simulateUser();
    console.log(res)
    if(res){
    }else{
      this.userExist.next(false)
    }
    console.log(this.profile, this.loggedInProfile)
    this.simulationChange = false;
  }

  async resetSimulation(){
    this.simulationChange = true;
    this.simulationStatus = false;
    console.log(this.loggedInProfile)
    this.userProfile = this.loggedInProfile;
    let res = await this.checkAndCreateUser();
    console.log(res)
    this.simulated.next(null);
    this.simulationChange = false;
  }

  decryptUsingAES256(input) {
    let _key = enc.Utf8.parse("493531ea-22e8-431c-9c27-4a5ea194343e");
    let _iv = enc.Utf8.parse("cac782ee-d9c4-45a9-ba0e-d99145d49739");
    var result = AES.decrypt(
      input, _key, {
      keySize: 16,
      iv: _iv,
      mode: mode.ECB,
      padding: pad.Pkcs7
    }).toString(enc.Utf8);
    return result;
  }
}
