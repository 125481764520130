import { Component, OnInit, Output, ViewChild, EventEmitter, SimpleChanges, OnChanges, ElementRef, DoCheck } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators'
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { ActionManager } from 'src/app/bloom/models/Action/ActionManager';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.css']
})
export class AutoCompleteComponent extends BaseWidgetComponent implements OnInit, OnChanges, DoCheck {

  contextMenuActions: any;
  myControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  availableOptions: any[] = [];
  private destroy:any = new Subject();

  value: string = ""
  oldValue: string
  fc = new FormControl('')
  preparingOptions: boolean = false

  @ViewChild('menuTrigger') autocompleteMenuTrigger: MatMenuTrigger
  @ViewChild('acInput') acInput: ElementRef

  @Output() selectionChange = new EventEmitter<any>();
  validationSubscription: any;

  dynamicOptionsError: any;

  constructor(
    private widgetUtilityService: WidgetUtilityService,
    private _snackBar: MatSnackBar,
    public actionManager: ActionManager,
    public validationService: ValidationService,
    public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
      // console.log("calling from onChanges()")
      // this.generateAvailableOptions()
    }
  }

  ngDoCheck(): void {
    //Called every time that the input properties of a component or a directive are checked. Use it to extend change detection by performing a custom check.
    //Add 'implements DoCheck' to the class.
    let newValue = this.widgetMeta.config?.value?.value || ""
    if(newValue != this.oldValue){
      // console.log("oldValue", this.oldValue)
      // console.log("vlaueChange", newValue)
      this.oldValue = JSON.parse(JSON.stringify(newValue))
      this.value = newValue
      // console.log("calling from doCheck")
      this.setValue()
    }
  }

  ngOnInit(): void {
    super.ngOnInit()
    this.oldValue = this.widgetMeta.config?.value?.value ? JSON.stringify(this.widgetMeta.config?.value?.value) : ""

    // console.log("calling from onInit()")
    this.generateAvailableOptions()

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          // console.log("valueChange", value);
          if(typeof value == 'string'){
            return this._filter(value)
          }else if(value && typeof value == 'object' && typeof value.name == 'string'){
            return this._filter(value.name)
          }else{
            return []
          }
        })
      )

    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })

    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      console.log("validation subscription", data)
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        this.fc.markAsTouched()
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
    this.validationSubscription?.unsubscribe();
  }

  //------------------------------------ METHODS -------------------------------------

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit"
        // "addOption"
      ]
    }
    if(this.widgetMeta.config.appearance){
      this.contextMenuActions.actions.unshift('appearance')
      this.contextMenuActions['appearance'] = {
        value: this.widgetMeta?.config.appearance.value,
        type: this.widgetMeta?.config.appearance.type
      }
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    // console.log("action is", event)
    // console.log("widgetMeta", this.widgetMeta)
    switch (event.actionType) {
      case "delete":
        this.onDelete();
        break;
      case 'updateStyles':
        if (event?.data) {
          this.widgetMeta = event.data;
        }
        this.generateStyles();
        this.newWidgetMeta.emit(this.widgetMeta);
        break;

      case 'settingsChanged':
        this.widgetMeta = event.data
        // console.log("calling from action()")
        this.generateAvailableOptions()
        // this.newWidgetMeta.next(this.widgetMeta)
        this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        break;

      case "newOption":
        console.log("need to add option", event.returnData)
        if(event.returnData && typeof event.returnData == 'string'){
          this.addOption(event.returnData)
        }
        this.autocompleteMenuTrigger.closeMenu();
        break;

      case "removeOption":
        let temp: any = []
        this.widgetMeta.config.availableOptions.staticOptions.forEach(option => {
          if (option !== event.returnData) {
            temp.push(option)
          }
        });
        this.widgetMeta.config.availableOptions.staticOptions = temp

        console.log("option removed", this.widgetMeta.config.availableOptions.staticOptions)
        // this.newWidgetMeta.emit(this.widgetMeta)
        this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        this.autocompleteMenuTrigger.closeMenu();
        break;
      default:
        break;
    }
  }

  displayFn(option) {
    return option && option.name ? option.name : ''
  }

  // onFocus(){
  //   console.log("focus detected: value:", this.acInput.nativeElement.value)
  //   console.log("patching:", this.acInput.nativeElement.value)
  //   this.myControl.patchValue(this.acInput.nativeElement.value)
  //   // this.filteredOptions = this._filter(this.acInput.nativeElement.value)
  // }

  // onInput(){
  //   console.log("input detected: value:", this.acInput.nativeElement.value)
  //   this.myControl.patchValue(this.acInput.nativeElement.value)
  // }

  private _filter(value: any): any[] {
    // console.log("filter value", value)
    // console.log("options", this.availableOptions)
    const filterValue = value.toLowerCase();
    return this.availableOptions.filter(option => {
      return option && option.name && typeof option.name == 'string' && option.name.toLowerCase().includes(filterValue)
    });
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.autocompleteMenuTrigger.closeMenu();
  }

  private addOption(option: string){
    let optionObject = {
      name: option,
      value: option
    }
    this.widgetMeta.config.availableOptions.staticOptions.push(optionObject)
    // console.log("calling from addOption()")

    this.generateAvailableOptions(true)
    // this.newWidgetMeta.next(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
    this._snackBar.open("option added", '', {duration: 1500})
  }

  removeOption(i: number){
    this.widgetMeta.config.availableOptions.staticOptions.splice(i, 1)
    // console.log("option removed")
    // console.log("calling from removeOption()")

    this.generateAvailableOptions(true)
    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  onClick() {
    if (!this.builderMode) {
      return
    }

    // console.log("autocomplete clicked")
    if (!this.builderMode) {
      this.autocompleteMenuTrigger.closeMenu();
    }
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  async onSelect(event){
    event.type = "select";
    let res = await this.executeAction(event)
  }

  async executeAction(e) {
    if(!this.widgetMeta.actionConfig || !this.widgetMeta.actionConfig.actions) return
    // if (!this.builderMode){
      let res = await this.actionManager.executeActions(this.widgetMeta.actionConfig.actions, e);
      return res
    // }
  }

  selectionHandler(event) {
    // console.log("selectionChange", event.option.value)

    //have to decide what to send on selection change, just the selected value or widgetMeta

    this.widgetMeta.config.value = event.option.value
    // this.emitUserInput(event.option.value);
    this.selectionChange.emit(event.option.value)
    this.onSelect(event);
    //update models
  }

  emitUserInput(valueMap){
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: valueMap.value
    }
    this.userInputReceived.emit(userInput);
  }


  async generateAvailableOptions(noRefetch: boolean = false){

    console.log("generate available options hit", noRefetch)
    let staticOptions: any[] = this.widgetMeta.config.availableOptions.staticOptions

    let rawDynamicOptions: any[] = []
    let dynamicOptionItems: any[] = []
    if(noRefetch){
      dynamicOptionItems = this.availableOptions.filter(opt => opt.type == 'dynamic')
      console.log("preserved dynamic options", dynamicOptionItems)
    } else {
      if(this.widgetMeta.config.availableOptions.dynamicOptions.enabled){
        console.log("will fetch dynamic options")
        this.preparingOptions = true
        this.dynamicOptionsError = ''
        // this.myControl.disable()
        if(!this.widgetMeta.config.availableOptions.dynamicOptions?.userData){
          try {
            rawDynamicOptions = await this.widgetUtilityService.fetchDynamicOptions(this.widgetMeta, this.builderMode);
          } catch(e) {
            console.log("error fetching dynamic options", e)
            this.dynamicOptionsError = "Could not fetch dynamic options"
            this.preparingOptions = false
            // this.myControl.disable()
          }
        } else {
          rawDynamicOptions = await this.widgetUtilityService.getBloomUsers();
        }
        console.log("dynamic options", rawDynamicOptions)
        dynamicOptionItems = this.widgetUtilityService.processDynamicOptions(rawDynamicOptions, this.widgetMeta)
        console.log("processed dynamic options", dynamicOptionItems)
        this.preparingOptions = false
        // this.myControl.enable()
      }
    }

    if(this.builderMode){
      staticOptions.map(opt => opt['type'] = 'static')
      dynamicOptionItems.map(opt => opt['type'] = 'dynamic')
    }

    let newOptions: any[] = []
    newOptions = newOptions.concat(staticOptions)
    newOptions = newOptions.concat(dynamicOptionItems)
    this.availableOptions = newOptions

    if(this.value){
      console.log("calling from generateOptions if")
      this.setValue()
    }else{
      let defaultOption = this.availableOptions.find(option => option.default)
      if(defaultOption){
        this.value = defaultOption.value
        console.log("calling from generateOptions else")
        this.setValue()
      }
    }

    // console.log("options", this.availableOptions)
  }

  setValue(){
    if(!this.value) return
    console.log("will find and set value", this.value)
    let selectedOptIndex = this.availableOptions.findIndex(op => op.value == this.value)
    if(selectedOptIndex > -1){
      // console.log("selected already in list")
      this.myControl.patchValue(this.availableOptions[selectedOptIndex])
    }else{
      console.log("selected newly created")
      let newTempOption = {
        name: this.value,
        value: this.value
      }
      this.availableOptions.push(newTempOption)
      this.myControl.patchValue(newTempOption)
    }

    this.emitUserInput({value: this.value})

  }

  clearSelection(e) {
    e.stopPropagation()
    this.myControl.patchValue('')
    this.userInputReceived.emit('');
  }
}
