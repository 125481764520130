import { Injectable } from '@angular/core';
import { WidgetManager } from '../models/WidgetManager';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';
import { ListPanel } from '../models/panelClasses/listPanel';
// import { BoxService } from './box-service.service';
import { MetaService } from './meta-service';
import { PageService } from './page-service.service';
import { FormPanel } from '../models/panelClasses/formPanel';
import { DetailsPanel } from '../models/panelClasses/detailsPanel';
import { WidgetService } from './widget-service.service';
import { BoxService as BloomBoxService } from './box-service.service';
import { PopupEditViewComponent } from 'src/app/shared/popup-edit-view/popup-edit-view.component';
import { MatDialog } from '@angular/material/dialog';

// import { BoxService } from 'src/app/shared/services/box.service';

// information required to generate a form panel meta
interface BoxBinding {
  boxId: string,
  boxName: string,
  boxObjectId: string,
  connectionId: string,
  attributeOptions: any[],
  getFnOptions: any[],
  primaryAttribute?: any,
}

const dataTypeToWidgetMapForm = {
  number: 'numberinput',
  string: 'textinput',
  boolean: 'checkbox',
  date: 'date',
  time: 'time',
  datetime: 'datetime',
  timestamp: 'numberinput',
  object: 'textarea',
  array: 'textarea',
}
const dataTypeToWidgetMapForDetails = {
  number: 'label',
  string: 'label',
  boolean: 'label',
  date: 'date',
  time: 'time',
  datetime: 'datetime',
  timestamp: 'label',
  object: 'object',
  array: 'array',
  image: 'image',
  link: 'link',
}

@Injectable({
  providedIn: 'root'
})
export class AutomationService {

  constructor(
    private metaService: MetaService,
    private connectionService: ConnectionService,
    private bloomBoxService: BloomBoxService,
    // private BoxServiceNew: BoxServiceNew,
    private pageService: PageService,
    private widgetService: WidgetService,
    private dialog: MatDialog
  ) { }

  async createPageMeta(connectionId: string, boxObjectId: string, pageStructure: any) {
    console.log("[AUTOMATION] createPage()", connectionId, boxObjectId, pageStructure)

    // 1. GET CONNECTION
    let connection: any;
    try {
      connection = await this.connectionService.getConnection(connectionId, this.connectionService.preAuthenticatedToken)
    } catch (error) {
      throw error
    }

    // 2. DETERMINE PAGE CODE
    let created = this.determinePageCode(connection, boxObjectId, pageStructure)
    let pageCode = created.code
    let pageName = created.name

    // 3. CREATE PAGE META
    let pageMeta: any = this.getGenericPageMeta(pageName, pageCode)

    // 4. RETURN PAGE CREATION RESULT
    return pageMeta
  }


  async createPageInDb(pageMeta: any, pageStructure: any) {
    let pageCode = pageMeta.code

    // 1. SAVE PAGE
    let pageCreationResponse;
    try {
      pageCreationResponse = await this.metaService.create(pageMeta)
      console.log("[AUTOMATION] page created in DB", pageCreationResponse)
    } catch (error) {
      console.log("[AUTOMATION] could not create page", error)
      throw error
    }

    /** TODO */
    // validate page name and code before appending page structure

    // 2. APPPEND PAGE STRUCTURE
    let pageId: string = pageCreationResponse._id
    pageStructure.pages.push(pageCode)
    pageStructure[pageCode] = {
      code: pageCode,
      name: pageCode,
      id: pageId
    }
    console.log("appended page structure", pageStructure)

    // 3. SAVE PAGE STRUCTURE
    let updateResponse: any
    try {
      updateResponse = await this.metaService.updatePageStructure(pageStructure)
      console.log("updated page structure saved", updateResponse)
    } catch (error) {
      console.log("[AUTOAMTION] could not save page structure", error)
      throw error
    }

    return updateResponse
  }

  getGenericPageMeta(pageName, pageCode) {
    let pageMeta = {
      name: pageName,
      code: pageCode,
      panels: []
    }
    return pageMeta
  }

  transformedName(name) {
    let trimmed = name.trim()
    let parts = trimmed.split('_')
    for (let i = 0; i < parts.length; i++) {
      parts[i] = parts[i].length ? parts[i][0].toUpperCase() + parts[i].substr(1).toLowerCase() : ''
    }
    return parts.join(' ')
  }

  /**
   *
   * @param connection
   * @param boxObjectId
   * @param pageStructure
   * @returns string: a valid pageCode
   */
  determinePageCode(connection: any, boxObjectId: string, pageStructure: any, suffix?: string) {
    let i = 0
    while (true) {  // run the loop until found a valid page code
      let pageName = `[${connection.name}] ${boxObjectId}`
      let connectionName = connection.name.trim().replace(' ', '_')
      let pageCode = `[${connectionName}]_${boxObjectId}`
      if (i > 0) {
        pageCode = pageCode + `(${i})`
        pageName = pageName + `(${i})`
      }
      if (suffix) {
        pageCode += `_${suffix}`
        pageName += ` ${suffix}`
      }
      let res = this.checkNoDuplication(pageStructure, pageCode)
      if (res.validity) {
        console.log("pageCode decided", pageCode)
        return {
          code: pageCode,
          name: pageName
        }
      } else {
        i++
        continue
      }
    }
  }

  /**
   * checks if chosen pageCode already exists or attempting to use reserved keywords
   * @param pageStructure
   * @param pageCode
   * @returns
   * {
   *    validity: boolean,
   *    error?: string (if validity false)
   * }
   */
  checkNoDuplication(pageStructure: any, pageCode: string) {
    console.log("page structure", pageStructure)
    console.log("checking code", pageCode)
    if (pageStructure.pages.findIndex(code => code == pageCode) > -1) {
      return {
        validity: false,
        error: "Attempt to create duplicate page"
      }
    }

    if (pageCode == 'pages' || pageCode == 'homePageCode') {
      return {
        validity: false,
        error: "Attempt to create page with reserved name"
      }
    }

    return {
      validity: true
    }
  }

  generateActionPanel(inputs: any, action?: any, connection?: any){

    let panelConfig:any = {
      id : Date.now(),
      layout: "flex-start",
      name : "panel_" + action?.__id,
      type: "regular",
      widgets: []
    }

    let widgetMetas: any[] = []
    let mappings = [];
    let list:any = inputs?.list || [];
    for (let i = 0; i < list.length; i++) {
      const attr = inputs[list[i]];
      let id: any = Date.now().toString() + Math.random().toString(16).slice(8)
      let name: any =  attr.name + '_' + i;
      let widgetMeta: any
      switch (attr.dataType) {
        case 'string':
          widgetMeta = WidgetManager.getWidget("input", id, name);
          widgetMeta.config.placeholder.value = attr.name || "Provide Value";
          break;
        default:
          widgetMeta = WidgetManager.getWidget("input", id, name);
          widgetMeta.config.placeholder.value = attr.name || "Provide Value";
          break;
      }
      let mapping = this.createMapping(attr, widgetMeta, panelConfig.id)
      mappings.push(mapping)
      widgetMetas.push(widgetMeta)
    }

    let actionConfig :any = this.createActionConfig()
    let submitButtonMeta = this.pageService.createWidget('button');
    submitButtonMeta.config.buttonText.value = action?.name || "Execute"
    actionConfig.actions[0].actionMap.boxId = connection.box_id;
    actionConfig.actions[0].actionMap.connection = connection._id
    actionConfig.actions[0].actionMap.action = action?.__id;
    actionConfig.actions[0].actionMap.mapping = mappings;
    submitButtonMeta.actionConfig = actionConfig
    widgetMetas.push(submitButtonMeta)
    panelConfig['widgets'] = widgetMetas
    return panelConfig;
  }


  getWidgetsFromPanel(panelMeta){
    let layoutMap = panelMeta.layoutMap;
    let widgets = [];
    if(!layoutMap) return widgets;
    layoutMap?.list?.forEach(layout => {
      console.log("layout", layout)
      layoutMap[layout]?.list?.forEach(row => {
        console.log("row", row)
        let rowMap = layoutMap[layout][row];
        if(rowMap?.type == 'elements' && rowMap?.elements?.length > 0) {
          widgets = widgets.concat(rowMap.elements);
        }
      });
    });
    console.log("returning widgets from layout", JSON.parse(JSON.stringify(widgets)))
    return widgets;
  }

  transformWidgetToLayout(widgets, rowMap?: any){
    console.log("transform widget to layout", JSON.parse(JSON.stringify(widgets)))
    let layoutId = new Date().valueOf();
    let layout = {
      list: [],
      gridX: 12
    }
    // for (let i = 0; i < widgets.length; i++) {
    //   const element = widgets[i];
    //   let rowId = new Date().setMilliseconds(new Date().getMilliseconds() + i).valueOf();
    //   layout.list.push(rowId);
    //   layout[rowId] = {
    //     type: "elements",
    //     elements: [element]
    //   }

    //   if(Array.isArray(rowMap[element.id]) && rowMap[element.id].length){
    //     let rowMembers = widgets.filter(w => rowMap[element.id].includes(w.id))
    //     console.log("row members", rowMembers)

    //     // layout[rowId].elements = layout[rowId].elements.merge(rowMembers)
    //     rowMembers.forEach(rm => {
    //       layout[rowId].elements.push(rm)
    //       widgets.splice()
    //     })
    //   }
    // }
    while (widgets.length) {
      const element = widgets[0];
      let rowId = Date.now().toString() + Math.floor(1000 + Math.random() * 9000);
      layout.list.push(rowId);
      layout[rowId] = {
        type: "elements",
        elements: [element]
      }
      if(rowMap && Array.isArray(rowMap[element.id]) && rowMap[element.id].length){
        console.log("inside if")
        let rowMembers = widgets.filter(w => rowMap[element.id].includes(w.id))
        console.log("row members", rowMembers)

        layout[rowId].elements = layout[rowId].elements.concat(rowMembers)

        console.log("layout now", JSON.parse(JSON.stringify(layout)))
        rowMembers.forEach(rowMember => {
          // layout[rowId].elements.push(rowMember)
          widgets.splice(widgets.findIndex(w => w.id == rowMember.id), 1)
          console.log("removed id", rowMember.id, "array now", JSON.parse(JSON.stringify(widgets)))
        })
      }
      widgets.splice(widgets.findIndex(w => w.id == element.id), 1)
      // widgets.shift()
      // console.log("removed dealing element", element.id, "widgets now", JSON.parse(JSON.stringify(widgets)))
    }
    let layoutMap = {
      [layoutId]: layout,
      list: [layoutId]
    }

    return layoutMap;
  }

  getSubmitButtonMeta(panelMeta){
    let meta;
    // console.log("getSubmitButtonMeta hit: panelMeta", JSON.parse(JSON.stringify(panelMeta)))
    if(panelMeta.submitDecoupled) return panelMeta.submitButtonMeta
    if(panelMeta.layoutMap){
      // let layout = panelMeta?.layoutMap?.list[panelMeta?.layoutMap?.list?.length - 1];
      // let row = panelMeta.layoutMap[layout].list[panelMeta.layoutMap[layout].list.length - 1];
      // meta = panelMeta.layoutMap[layout][row].elements[0];

      panelMeta.layoutMap?.list?.forEach(layoutId => {
        panelMeta.layoutMap[layoutId]?.list?.forEach(rowId => {
          panelMeta.layoutMap[layoutId]?.[rowId]?.elements?.forEach(wid => {
            wid.id = wid.id.toString()
            // let parts = wid.id.split("_")
            if(wid.id.includes('submit')){
              meta = wid
            }
          })
        })
      })
    };
    return meta
  }

  setSubmitButtonMeta(panelMeta, buttonData){
    if(panelMeta.submitDecoupled) {
      panelMeta["submitButtonMeta"] = buttonData
      return
    }
    panelMeta.layoutMap?.list?.forEach(layoutId => {
      let layout = panelMeta.layoutMap[layoutId];
      if(!layout || !layout.list?.length) return
      layout.list.forEach(rowId => {
        let row = layout[rowId];
        row.elements.forEach((wid, i) => {
          wid.id = wid.id.toString()
          if(wid.id.includes('submit')){
            row.elements[i] = buttonData
          }
        })
      })
    })
    // if(panelMeta.layoutMap){
    //   let layout = panelMeta?.layoutMap?.list[panelMeta?.layoutMap?.list?.length - 1];
    //   let row = panelMeta.layoutMap[layout].list[panelMeta.layoutMap[layout].list.length - 1];
    //   panelMeta.layoutMap[layout][row].elements[0] = buttonData;
    // };
  }



  generateSearchWidgets(panelMeta) {
    let searchInputWidgets = []
    let widgets = this.widgetService.getWidgetsFromPanel(panelMeta) || []
    console.log("existing widgets", JSON.parse(JSON.stringify(widgets)))

    if(panelMeta.commonSearchField){
      let oldWid = widgets.find(wid => wid.id == panelMeta.id + '__common__')
      let wid: any
      if(oldWid) {
        wid = oldWid
      }else{
        wid = WidgetManager.getWidget('input', panelMeta.id + '__common__', panelMeta.id + '__common__')
      }
      // attach widget placeholder/title
      let str = ''
      panelMeta.searchAttributes.forEach((attr, i) => {
        str += attr.name
        if(i < panelMeta.searchAttributes.length - 2){
          str += ','
        } else if (i == panelMeta.searchAttributes.length - 2) {
          str += ' or '
        }
      })
      wid.config.placeholder.value = `Search on ${str}`
      searchInputWidgets.push(wid)
    } else {
      panelMeta.searchAttributes.forEach(attr => {
        if(!attr.isDrillDown){
          let wid: any
          console.log("search attr", attr)
          let oldWid = widgets.find(w => {
            if(!w || !w.id){
              // console.log("w missing", w)
              return false
            }else if(w.id.split("-")[1] == attr.__id && w.type == attr.widgetType){
              // console.log("found", w)
              return true
            }else {
              // console.log("no match")
              return false
            }
          })
          // console.log("old widget search res", oldWid)
          if(oldWid && oldWid.type == attr.widgetType){
            wid = oldWid
            // console.log("old widget reused", wid)
          }else{
            wid = WidgetManager.getWidget(attr.widgetType || 'input')
            wid.id = wid.id + "-" + attr.__id
            if(wid.type == 'input') {
              wid.config.placeholder.value = "Search by "  + attr.name
              wid.name = attr.name
            }else if(wid.type == 'select'){
              // wid.config.textContent.value = attr.name
              wid.config.label.value = attr.name
            }else if(wid.type == 'autocomplete'){
              wid.config.placeholder.value = ""
              wid.config.label.value = attr.name
            }else if(wid.type == 'numberinput'){
              wid.config.placeholder.value = attr.name
            }else if(wid.type == 'checkbox'){
              console.log("inside checkbox")
              // wid.config.title.value = attr.name
              wid.config.showTitle.value = false
              wid.config.availableOptions.staticOptions.push({
                default: false, name: attr.name, value: true
              })
              console.log("added option", JSON.parse(JSON.stringify(wid)))
            }else if(wid.type == 'datetime'){
              wid.config.placeholder.value = attr.name
            }
          }
          searchInputWidgets.push(wid)

        }else{
          let wid: any
          console.log("nested attr", attr)
          for (let i = 0; i < attr.nestedProperties.length; i++) {
            const nestedAttr = attr.nestedProperties[i];
            if(nestedAttr.path.length == 0 || !nestedAttr.widgetType) continue

            let oldWid = widgets.find(w => {
              if(!w || !w.id){
                // console.log("w missing", w)
                return false
              }else if(w.id.split("-")[1] == `${attr.__id}.${nestedAttr.path}` && w.type == nestedAttr.widgetType){
                // console.log("found", w)
                return true
              }else {
                // console.log("no match")
                return false
              }
            })
            console.log("old widget search res", oldWid)
            if(oldWid && oldWid.type == nestedAttr.widgetType){
              wid = oldWid
              // console.log("old widget reused", wid)
            }else{
              wid = WidgetManager.getWidget(nestedAttr.widgetType || 'input')
              wid.id = wid.id + "-" + attr.__id + "." + nestedAttr.path
              if(wid.type == 'input') {
                wid.config.placeholder.value = attr.name + " > " + nestedAttr.path
              }else if(wid.type == 'select'){
                // wid.config.textContent.value = attr.name
                wid.config.label.value = attr.name + " > " + nestedAttr.path
              }else if(wid.type == 'autocomplete'){
                wid.config.placeholder.value = attr.name + " > " + nestedAttr.path
              }
              console.log("nested attr widget created", wid)
            }
            searchInputWidgets.push(wid)
          }
        }
      });
    }

    console.log("searchInputWidgets created", JSON.parse(JSON.stringify(searchInputWidgets)))

    let gridX;
    if(searchInputWidgets.length){
      if (searchInputWidgets.length >= 3) gridX = 3.5;
      else if (searchInputWidgets.length == 2) gridX = 5.5
      else if (searchInputWidgets.length == 1) gridX = 12
    }
    searchInputWidgets.forEach(w => w['gridX'] = gridX)

    return searchInputWidgets
  }

  async generatePopupForm(panelMeta: any, data: any, updateFnOptions: any[]){
    // console.log("panelMeta", panelMeta)
    // console.log("data", data)
    let formPanel: any = this.generateFormPanel(panelMeta)
    formPanel['hideTitle'] = true
    // let data = this.rawBoxData[rowIndex]

    // console.log("primary attribute found", this.panelMeta.primaryAttribute)
    // console.log("primary data found", data[primaryAttr?.__id])

    let boxConfigToken: string
    if(formPanel.boxId == 'starch') boxConfigToken = formPanel.boxConfigToken
    else{
      let conn = await this.connectionService.getConnection(formPanel.connectionId)
      // console.log("connection received", conn)
      boxConfigToken = conn.box_token
    }
    let attributes: any
    try{
      let res: any = await this.bloomBoxService.getAttributes(
        // formPanel.boxConfigToken,
        boxConfigToken,
        formPanel.boxId == 'starch' ? formPanel.baseMap.box_id : formPanel.boxId,
        formPanel.boxObjectId,
        formPanel.attributeOptions,
        null,
        "token"
      )
      attributes = res.result
      // attributes.forEach(a => a['dataType'] = a['dataType'] || 'string')
      // console.log("attributes loaded", attributes)
    }catch(e){
      console.error("error in fetching attributes", e)
      throw e
    }
    attributes.forEach(attr => {
      attr['enabled'] = true
      attr['widgetType'] = 'input'
      attr['isDrillDown'] = false
      attr['editable'] = true
    })
    formPanel['formAttributes'] = attributes
    // console.log("form attributes attached", JSON.parse(JSON.stringify(formPanel)))

    console.log("primary", formPanel?.primaryAttribute)
    let primaryAttr = formPanel.primaryAttribute

    // attach filters
    if (primaryAttr?.__id && data[primaryAttr.__id]) {
      console.log("if")
      let filter = {
        "attribute": primaryAttr.__id,
        "operator": "=",
        "value": data[primaryAttr.__id],
        "dataType": primaryAttr.dataType,
        "filterType": "static_filter"
      }
      formPanel.filter.filterItems.push(filter)
    } else {
      // console.log("no primary attribute")

      // check if update fn options has a primary attribute and its corresponding value is present in data
      let primaryForUpdate: any = updateFnOptions.find(input => input.__id.includes('primary'))
      if(primaryForUpdate?.value && data[primaryForUpdate.value]){   // if primary attribute present in action fn options, use that as filter
        let filter = {
          "attribute": primaryForUpdate.value,
          "operator": "=",
          "value": data[primaryForUpdate.value],
          "dataType": primaryForUpdate.dataType,
          "filterType": "static_filter"
        }
        formPanel.filter.filterItems.push(filter)
      }else{
        // use whatever values available as filters
        Object.keys(data).forEach(attrId => {
          let attr = formPanel.formAttributes.find(a => a.__id == attrId)
          if(data[attrId] && (['string', 'number', 'boolean'].includes(attr.dataType) || !attr.dataType)){
            let filter = {
              "attribute": attrId,
              "operator": "=",
              "value": data[attrId],
              "dataType": attr.dataType,
              "filterType": "static_filter"
            }
            formPanel.filter.filterItems.push(filter)
          }
        })
      }
    }

    console.log("form panel", formPanel)
    return formPanel
  }

  async generatePopupDetails(panelMeta: any, data: any){
    console.log("panelMeta", panelMeta)
    console.log("data", data)
    let detailsPanel: any = {
      type: 'detailspanel',
      primaryAttribute: panelMeta.primaryAttribute,
      name: "Edit Data",
      id: Date.now(),
      layout: 'flex-start',
      hideTitle: true,
      getFnOptions: panelMeta.getFnOptions,
      attributeOptions: panelMeta.attributeOptions,
      boxObjectId: panelMeta.boxObjectId,
      boxId: panelMeta.boxId,
      baseId: panelMeta.baseId,
      baseMap: panelMeta.baseMap,
      connectionId: panelMeta.connectionId,
      boxConfigToken: panelMeta.boxConfigToken,
      filter: {
        filterEnabled: true,
        filterItems: []
      },
      showLabels: true,
    }
    console.log("details meta created", detailsPanel)
    // let data = this.rawBoxData[rowIndex]

    // console.log("primary attribute found", this.panelMeta.primaryAttribute)
    // console.log("primary data found", data[primaryAttr?.__id])

    let boxConfigToken: string
    if(detailsPanel.boxId == 'starch') boxConfigToken = detailsPanel.boxConfigToken
    else{
      let conn = await this.connectionService.getConnection(detailsPanel.connectionId)
      // console.log("connection received", conn)
      boxConfigToken = conn.box_token
    }
    let attributes: any
    try{
      let res: any = await this.bloomBoxService.getAttributes(
        // formPanel.boxConfigToken,
        boxConfigToken,
        detailsPanel.boxId == 'starch' ? detailsPanel.baseMap.box_id : detailsPanel.boxId,
        detailsPanel.boxObjectId,
        detailsPanel.attributeOptions,
        null,
        "token"
      )
      attributes = res.result
      // attributes.forEach(a => a['dataType'] = a['dataType'] || 'string')
      // console.log("attributes loaded", attributes)
    }catch(e){
      console.error("error in fetching attributes", e)
      throw e
    }
    attributes.forEach(attr => {
      attr['enabled'] = true
      attr['widgetType'] = 'label'
      attr['isDrillDown'] = false
    })
    detailsPanel['detailsAttributes'] = attributes
    // console.log("form attributes attached", JSON.parse(JSON.stringify(formPanel)))

    console.log("primary", detailsPanel?.primaryAttribute)
    let primaryAttr = detailsPanel.primaryAttribute

    // attach filters
    if (primaryAttr?.__id && data[primaryAttr.__id]) {
      console.log("if")
      let filter = {
        "attribute": primaryAttr.__id,
        "operator": "=",
        "value": data[primaryAttr.__id],
        "dataType": primaryAttr.dataType,
        "filterType": "static_filter"
      }
      detailsPanel.filter.filterItems.push(filter)
    } else {
      // use whatever values available as filters
      Object.keys(data).forEach(attrId => {
        let attr = detailsPanel.formAttributes.find(a => a.__id == attrId)
        if(data[attrId] && (['string', 'number', 'boolean'].includes(attr.dataType) || !attr.dataType)){
          let filter = {
            "attribute": attrId,
            "operator": "=",
            "value": data[attrId],
            "dataType": attr.dataType,
            "filterType": "static_filter"
          }
          detailsPanel.filter.filterItems.push(filter)
        }
      })
    }

    console.log("details panel", detailsPanel)
    return detailsPanel
  }

  generateFormPanel(panelMeta: any){
    let formPanel = {
      type: 'formpanel',
      submitButtonTitle: "Save",
      primaryAttribute: panelMeta.primaryAttribute,
      name: "Edit Data",
      mode: "update",
      id: Date.now(),
      layout: 'flex-start',
      getFnOptions: panelMeta.getFnOptions,
      attributeOptions: panelMeta.attributeOptions,
      boxObjectId: panelMeta.boxObjectId,
      boxId: panelMeta.boxId,
      baseId: panelMeta.baseId,
      baseMap: panelMeta.baseMap,
      connectionId: panelMeta.connectionId,
      boxConfigToken: panelMeta.boxConfigToken,
      filter: {
        filterEnabled: true,
        filterItems: []
      }
    }
    console.log("form panel created", JSON.parse(JSON.stringify(formPanel)))
    return formPanel
  }


  /**
   * does 2 things
   * 1. cretes input widgets for the form
   * 2. simultaneously generate and attach mapping for action config and attaches to submit button meta
   */
  generateFormWidgets(formAttributes: any[], panelMeta: any, submitButtonMeta?: any, action?: string){
    console.log("hit generate form widgets fn", JSON.parse(JSON.stringify(formAttributes)), JSON.parse(JSON.stringify(panelMeta || {})))
    // console.log("[GENERATE FORM WIDGETS] action:", action)
    let widgetMetas: any[] = []
    let formWidgetMetas: any[] = []
    let oldFormAttributeWidgets: any[] = []

    //===================== KEEP EXTRA WIDGETS THAT ARE MANUALLY ADDED ==================

    if(panelMeta.widgets?.length){
      widgetMetas = JSON.parse(JSON.stringify(panelMeta.widgets))
    } else {
      widgetMetas = JSON.parse(JSON.stringify(this.getWidgetsFromPanel(panelMeta)));
    }

    // console.log("widget metas before removing submit", JSON.parse(JSON.stringify(widgetMetas)))
    //remove the submit button (will be added later)
    let submitIndex = this.findSubmitButtonIndex(widgetMetas)
    // console.log("will remove submit button from index", submitIndex)
    if(submitIndex > -1){
      widgetMetas.splice(submitIndex, 1)
      // console.log("submit removed temporarily", JSON.parse(JSON.stringify(widgetMetas)))
    }


    /**
     * fill with null values in place of widgets that are tied to form attributes (to be filled with new widget later)
     * ['form_wid_1', 'form_wid_2', 'manual_wid_1', 'form_wid_3', 'manual_wid_2'] ==>> [null, null, 'manual_wid_1', null, 'manual_wid_2']
     */
    widgetMetas?.forEach((widget, i) => {
      if(widget.id.split('-').length > 1){
        oldFormAttributeWidgets.push(JSON.parse(JSON.stringify(widgetMetas[i])))
        widgetMetas[i] = null
      }
    })
    // console.log("old widgets", JSON.parse(JSON.stringify(widgetMetas)))
    //===================================================================================

    let actionConfig :any;
    //on edit use the existing actionconfig as there can be multiple actions exists
    if(submitButtonMeta?.actionConfig?.actions?.length){
      actionConfig = submitButtonMeta?.actionConfig;
    } else actionConfig =  this.createActionConfig();
    // console.log("actionConfig", actionConfig)


    // ====================== GENERATE WIDGETS FOR FORM ATTRIBUTES =======================
    for (let i = 0; i < formAttributes.length; i++) {
      const attr = formAttributes[i];
      // console.log("dealng form attribute", attr)
      if (!attr.enabled) continue

      let widgetMeta: any

      if (!attr.isDrillDown) {
        // if widget already exists and type is same then reuse
        let j = oldFormAttributeWidgets.findIndex(wid => wid?.id?.split('-')[1] == attr.__id)
        if (j > -1 && oldFormAttributeWidgets[j].type == attr.widgetType){
          widgetMeta = oldFormAttributeWidgets[j]
          // console.log("old widget exists for", attr.__id, "=>", widgetMeta)
        } else {
          widgetMeta = this.createFormWidget(attr, panelMeta)
          if(j > -1) widgetMeta.id = oldFormAttributeWidgets[j].id // if widget existed but with differe type, then
          // console.log("created new widget for", attr.__id, "=>", widgetMeta)
        }
        if(attr.hidden) {
          widgetMeta.config.hidden.value = true;
        }
        if(!attr.editable) widgetMeta.config.editable = false;
        let mapping = this.createMapping(attr, widgetMeta, panelMeta.id)
        actionConfig.actions[0].actionMap.mapping.push(mapping)
        formWidgetMetas.push(widgetMeta)

      } else {
        for (let i = 0; i < attr.nestedProperties.length; i++) {
          const element = attr.nestedProperties[i];
          if(element.path.length == 0 || !element.widgetType) continue
          let attrTemp: any = {}
          attrTemp = JSON.parse(JSON.stringify(attr))
          attrTemp['name'] = element.customName || `${attr.name} -> ${element.path}`
          attrTemp['__id'] = `${attr.__id}.${element.path}`
          attrTemp['widgetType'] = element.widgetType
          attrTemp['path'] = element.path

          let j = oldFormAttributeWidgets.findIndex(wid => wid?.id?.split('-')[1] == attrTemp.__id)
          if (j > -1 && oldFormAttributeWidgets[j].type == attrTemp.widgetType){
            widgetMeta = oldFormAttributeWidgets[j]
            console.log("old widget exists for", attrTemp.__id, "=>", widgetMeta)
          } else {
            // console.log("will create new widget for", attrTemp.__id)
            widgetMeta = this.createFormWidget(attrTemp, panelMeta)
            // console.log("form widget created", widgetMeta)
          }
          if(attr.hidden) {
            widgetMeta.config.hidden.value = true;
          }
          if(!attr.editable) widgetMeta.config.editable = false;
          let mapping = this.createMapping(attrTemp, widgetMeta, panelMeta.id)
          actionConfig.actions[0].actionMap.mapping.push(mapping)
          console.log("pushing meta", widgetMeta)
          formWidgetMetas.push(widgetMeta)
        }
      }
    }

    /**
     *  merge newly created form widgets along with old widgets array containing manually created widgets
     */
    widgetMetas = this.mergeWidgetArrays(widgetMetas, formWidgetMetas)

    actionConfig.actions[0].actionMap.boxId = panelMeta.boxId
    actionConfig.actions[0].actionMap.connection = panelMeta.connectionId
    actionConfig.actions[0].actionMap.action = action || (panelMeta.boxObjectId + '/save')
    actionConfig.actions[0].actionMap.successMessage = panelMeta.successMessage
    if(actionConfig.actions[0].actionMap.boxId == 'starch') {
      actionConfig.actions[0].actionMap.baseMap = panelMeta.baseMap
      actionConfig.actions[0].actionMap.boxConfigToken = panelMeta.boxConfigToken
    }

    if (!submitButtonMeta) {
      console.log("will create submit button meta")
      submitButtonMeta = WidgetManager.getWidget('button')
      submitButtonMeta.gridX = 3
    }
    submitButtonMeta.id = panelMeta.id + '_submit'
    submitButtonMeta['noDelete'] = true

    if(submitButtonMeta?.actionConfig?.actions[0]?.actionMap?.boxConfigToken){
      actionConfig.actions[0].actionMap.boxConfigToken = submitButtonMeta.actionConfig.actions[0].actionMap.boxConfigToken;
      if(submitButtonMeta.actionConfig.actions[0]?.actionMap?.baseMap) actionConfig.actions[0].actionMap.baseMap = submitButtonMeta.actionConfig.actions[0].actionMap.baseMap;
    }

    if(submitButtonMeta?.actionConfig?.actions[0]?.actionMap?.successMessage) actionConfig.actions[0].actionMap.successMessage = submitButtonMeta?.actionConfig?.actions[0]?.actionMap?.successMessage;

    let submitButtonText = panelMeta.submitButtonTitle || 'Submit'
    submitButtonMeta.config.buttonText.value = submitButtonText;
    submitButtonMeta.gridX = this.getButtonGridCount(submitButtonText)

    let effectiveAction = actionConfig.actions[0].actionMap.action.split('/')[1]
    if(effectiveAction == 'create'){
      actionConfig.actions[0].actionMap.actionMode = 'create'
    }else if(effectiveAction == 'update'){
      actionConfig.actions[0].actionMap.actionMode = 'update'
    }else{
      actionConfig.actions[0].actionMap.actionMode = 'create'
    }

    // attach action origin
    actionConfig.actions[0].actionMap['origin'] = "formpanel"

    if(panelMeta.actionFnOptions?.length){
      let inputParams: any = {}
      panelMeta.actionFnOptions.forEach(op => {
        inputParams[op.__id] = op.value
      })
      actionConfig.actions[0].actionMap['inputParams'] = inputParams
    }

    submitButtonMeta.actionConfig = actionConfig
    console.log("submit button meta", submitButtonMeta)

    panelMeta["submitButtonMeta"] = submitButtonMeta
    if (!panelMeta.submitDecoupled){
      widgetMetas.push(submitButtonMeta)
    }

    // let layoutMap = this.transformWidgetToLayout(widgetMetas)
    // panelMeta['widgets'] = []//widgetMetas
    // panelMeta['layoutMap'] = layoutMap
    // console.log("layoutMap generated", layoutMap)

    // console.log("will alter existing layoutmap", JSON.parse(JSON.stringify(layoutMap)))
    let injectedWidgets: any = [] // widgets that are placed into layoutMap
    let layoutMap = panelMeta.layoutMap
    
    if(layoutMap?.list?.length){
      // if widget already exists for this attribute but of different type, then replace
      // console.log("widgetList to insert", JSON.parse(JSON.stringify(widgetMetas)));
      let widgetMetasCopy = JSON.parse(JSON.stringify(widgetMetas))
      for(let i = 0; i < widgetMetasCopy.length; i++){
        let w = widgetMetasCopy[i]
        // console.log("index", i, "handlinggg wid", JSON.parse(JSON.stringify(w)))
        let widgetExistanceMap = this.findWidgetFromLayoutMap(panelMeta.layoutMap, w.id)
        // console.log("widget existance map", widgetExistanceMap)
        if(widgetExistanceMap) {
          if(w.type !== widgetExistanceMap.wid?.type) { // if exists bt different widget type, then only replace, ignore if same to preserve styles
            layoutMap[widgetExistanceMap.colId][widgetExistanceMap.rowId].elements[widgetExistanceMap.index] = w
          }
          injectedWidgets.push(w)
          let removalIndex = widgetMetas.findIndex(widd => String(widd.id) == String(w.id))
          widgetMetas.splice(removalIndex, 1)  // at the end, widgetMetas will contain widgets that are not placed into layoutMap
        }
      }
      console.log("widgets injected into layoutMap", JSON.parse(JSON.stringify(layoutMap || "")))

      // if widget not already injected, add as new widgets now at the end
      widgetMetas.forEach((w, i) => {
        // console.log("handling uninjected wid", w)
        let lastColId = panelMeta.layoutMap.list[panelMeta.layoutMap.list.length - 1]
        let newRowId = Date.now() + "" + Math.floor(100000 + Math.random() * 900000);
        layoutMap[lastColId].list.push(newRowId)
        layoutMap[lastColId][newRowId] = {
          elements: [w],
          type: "elements"
        }
      })
      console.log("new widgets injected", JSON.parse(JSON.stringify(layoutMap)))

      // remove expired widgets
      layoutMap.list.forEach((colId, i) => {
        layoutMap[colId].list.forEach((rowId, j) => {
          layoutMap[colId][rowId].elements.forEach((wid, k) => {

            // if some widget exists that is not manually added and that is not yet to be placed and not already placed, it should be removed
            if (String(wid.id).split('-')[1] || String(wid.id).includes('LABEL')) {
              if (!injectedWidgets.find(w => w.id == wid.id) && !widgetMetas.find(w => w.id == wid.id)) {
                layoutMap[colId][rowId].elements.splice(k, 1)
              }
            }
          })
        })
      })
      console.log("removed expired widgets", JSON.parse(JSON.stringify(layoutMap)))

    } else {  // FOR FRESH WIDGETS GENERATION 
      layoutMap = this.transformWidgetToLayout(widgetMetas)
    }
    console.log("form widgets generated", JSON.parse(JSON.stringify(layoutMap || "")))

    panelMeta.layoutMap = layoutMap

    return panelMeta
  }

  /**
   * @param widgetMetas 1D array
   * @returns valid index or -1
   */
  findSubmitButtonIndex(widgetMetas: any){
    let submitIndex = -1;
    for(let i = 0; i < widgetMetas.length; i++){
      let parts = widgetMetas[i].id.split("_")
      if(parts[parts.length - 1] == "submit"){
        console.log("matched")
        submitIndex = i
        break
      }
    }
    return submitIndex
  }

  createFormWidget(attr: any, panelMeta: any){
    let widgetMeta = JSON.parse(JSON.stringify(WidgetManager.getWidget(attr.widgetType, null, this.toTitleCase(attr.name))));

    widgetMeta.id = widgetMeta.id + '-' + attr.__id;
    widgetMeta.origin = "formpanel";
    switch (widgetMeta.type) {
      case 'input':
        if(attr.primary){
          if(!widgetMeta.config.viewOnly) {
            let wid = WidgetManager.getWidget('input')
            widgetMeta.config.props.push('viewOnly')
            widgetMeta.config['viewOnly'] = wid.config.viewOnly
          }
          widgetMeta.config.viewOnly.value = true;
        }
        widgetMeta.config.placeholder.value = this.toTitleCase(attr.name);
        break;
      case 'select':
        widgetMeta.config.label.value = this.toTitleCase(attr.name)
        break;
      case 'autocomplete':
        if(attr.relation){
          widgetMeta.config.availableOptions.dynamicOptions.enabled = true;
          widgetMeta.config.availableOptions.dynamicOptions.boxId = panelMeta.boxId;
          widgetMeta.config.availableOptions.dynamicOptions.boxObjectId = attr.relation?.object;
          widgetMeta.config.availableOptions.dynamicOptions.baseMap = panelMeta.baseMap;
          widgetMeta.config.availableOptions.dynamicOptions.baseId = panelMeta.baseId;
          widgetMeta.config.availableOptions.dynamicOptions.boxConfigToken = panelMeta.boxConfigToken;
          widgetMeta.config.availableOptions.dynamicOptions.nameAttribute = {
            __id: attr.relation?.name,
            name: attr.relation?.name,
            dataType: attr.relation.dataType|| "string"
          }
          widgetMeta.config.availableOptions.dynamicOptions.valueAttribute = {
            __id: attr.relation?.attribute,
            name: attr.relation?.attribute,
            dataType: attr.relation.dataType|| "string"
          }
        }
        widgetMeta.config.label.value = this.toTitleCase(attr.name)
        break;
      case 'image':
        break;
      case 'checkbox':
        widgetMeta.heading = attr.name;
        break
      case 'datetime':
        widgetMeta.config.placeholder.value = this.toTitleCase(attr.name);
        break;
      case 'textarea':
        widgetMeta.config.placeholder.value = this.toTitleCase(attr.name);
        break;
      case 'numberinput':
        widgetMeta.config.placeholder.value = this.toTitleCase(attr.name);
        break;
      case 'imageinput':
        break;
      case 'chips':
        widgetMeta.config.inputMode.value = true
        widgetMeta.config.showTitle.value = false
        widgetMeta.config.placeholder.value = "Type and press ENTER to add " + attr.name
        widgetMeta.config.availableOptions.staticOptions = []
        widgetMeta.config.csvMode.value = true
        break;
      default:
        break;
    }
    widgetMeta.gridX = 12
    return widgetMeta
  }


  /**
   *
   * @param oldWidgets
   * @param formWidgets
   * @returns
   */
  mergeWidgetArrays(oldWidgets: any[], formWidgets: any[]){
    // console.log("[MERGER], oldWidgets", oldWidgets)
    // console.log("[MERGER], formWidgets", formWidgets)
    let masterList = oldWidgets
    formWidgets.forEach(wid => {
      let injectionIndex = masterList.findIndex(w => !w)
      if (injectionIndex >= 0) {
        // console.log("injection index found", injectionIndex)
        masterList[injectionIndex] = wid
      } else {
        // console.log("injection index not found")
        masterList.push(wid)
      }
    })
    // console.log("final master list", masterList)

    masterList = masterList.filter(wid => !!wid)  // removes null values
    return masterList
  }

  /**
   * a string separated by a delimiting character to Title Case
   * @param val
   * @param delimiter
   * @returns string in titlecase
   */
  toTitleCase(val: string, delimiter: string = '_'){
    // console.log("val", val)
    // console.log("parts", val.split('_'))
    // console.log("val.map", val.split('_').map(part => part ? part[0].toUpperCase() + part.substring(1).toLowerCase() : ''))
    // console.log("val.map", val.split('_').map(part => part ? part[0].toUpperCase() + part.substring(1).toLowerCase() : '').join(' '))
    return val.split(delimiter)
      .map(part => part ? part[0].toUpperCase() + part.substring(1).toLowerCase() : '')
      .join(' ')
  }


  getButtonGridCount(buttonText: string){
    let len = buttonText.length
    if(len <= 16) return 2;
    else if(len <= 20) return 3
    else if(len <= 26) return 4
    else if(len <= 34) return 5
    else return 6
  }

  /**
   * cretes input widgets for the form
   * @param panelMeta: which may be containing previously created widgets in layoutMap
   * @returns layoutMap
   */
  generateDetailsWidgets(panelMeta: any){
    // console.log("panelMeta", JSON.parse(JSON.stringify(panelMeta)))
    // console.log("panelMeta.detailsAttributes", panelMeta.detailsAttributes)
    // console.log("isPresent", Object.keys(panelMeta).find(key => key == 'detailsAttributes'))
    let attributes: any = panelMeta.detailsAttributes
    // console.log("hit generate widget fn", attributes)
    let widgetMetas: any[] = []
    let detailsWidgetMetas: any[] = []
    let oldWidgetMetas: any[] = []
    let isOldMetaShowLabels: boolean = false

    if(panelMeta.widgets?.length){
      widgetMetas = JSON.parse(JSON.stringify(panelMeta.widgets))
    } else {
      widgetMetas = JSON.parse(JSON.stringify(this.getWidgetsFromPanel(panelMeta)));
    }
    // console.log("widgets array generated from existing panelmeta", JSON.parse(JSON.stringify(widgetMetas)))

    if(!panelMeta.showLabels){
      let lenWithLabels = widgetMetas.length
      let temp = widgetMetas.filter(w => w.id.split('-')[1] !== 'LABEL')
      if(widgetMetas.length !== lenWithLabels) isOldMetaShowLabels = true
    }
    // console.log("LABELs removed", JSON.parse(JSON.stringify(widgetMetas)))

    widgetMetas?.forEach((widget, i) => {
      if(widget.id.split('-').length > 1){ // widget is representing some attribute; has attribute stamp in id
        oldWidgetMetas.push(JSON.parse(JSON.stringify(widgetMetas[i])))
        widgetMetas[i] = null
      }
    })
    // console.log("old widgets", JSON.parse(JSON.stringify(oldWidgetMetas)))

    // key is row's first widget's id, value is array containing other widget ids
    // e.g. { wid1_id: [wid2_id, wid3_id] }
    let rowMemberMap: any = {}

    for (let i = 0; i < attributes.length; i++) {
      const detailsAttr = attributes[i];
      if(!detailsAttr.enabled) continue
      // console.log("dealng details attribute", detailsAttr)

      let valueWidget: any
      let attr: any = {}
      if(!detailsAttr.isDrillDown){
        attr = detailsAttr
        let j = oldWidgetMetas.findIndex(wid => wid?.id?.split('-')[1] == attr.__id)
        if(j > -1 && oldWidgetMetas[j].type == attr.widgetType){
          valueWidget = oldWidgetMetas[j]
          // console.log("old widget exists for", attr.__id, "=>", valueWidget)
        }else{
          valueWidget = this.createDetailsWidget(attr)
          // console.log("created new widget for", attr.__id)
        }
        valueWidget['gridX'] = panelMeta.showLabels ? 5 : 12
        valueWidget['origin'] = "detailspanel";

        if(panelMeta.showLabels){
          let oldWid = oldWidgetMetas.find(wid => ((wid.id.split('-')[2] == attr.__id) && (wid?.id?.split('-')[1] == "LABEL")))
          // console.log("oldWid for label", oldWid)
          let attributeTitleWidget: any = oldWid || this.createDetailsAttrTitleWidget(attr)
          detailsWidgetMetas.push(attributeTitleWidget)
          rowMemberMap[attributeTitleWidget.id] = [valueWidget.id]
        }

        detailsWidgetMetas.push(valueWidget)

      }else{
        console.log("drill down handler")
        for (let i = 0; i < detailsAttr.nestedProperties.length; i++) {
          const attribute = detailsAttr.nestedProperties[i];
          // console.log("nested prop", attribute)
          // console.log("isNotPath ?", attribute.path.length == 0)
          // console.log("isNotType ?", !attribute.widgetType)
          if(attribute.path.length == 0 || !attribute.widgetType) continue
          // console.log("after if")
          attr = {
            name: attribute.customName || `${detailsAttr.name} -> ${attribute.path}`,
            __id: `${detailsAttr.__id}.${attribute.path}`,
            widgetType: attribute.widgetType,
            path: attribute.path
          }

          let j = oldWidgetMetas.findIndex(wid => wid?.id?.split('-')[1] == attr.__id)
          if(j > -1 && oldWidgetMetas[j].type == attr.widgetType){
            valueWidget = oldWidgetMetas[j]
            console.log("old drill down widget exists for", attr.__id, "=>", valueWidget)
          }else{
            valueWidget = this.createDetailsWidget(attr)
            console.log("created new drill down widget for", attr.__id)
          }

          if(panelMeta.showLabels){
            // let attributeTitleWidget = this.createDetailsAttrTitleWidget(attr)
            // detailsWidgetMetas.push(attributeTitleWidget)
            let oldWid = oldWidgetMetas.find(wid => ((wid.id.split('-')[2] == attr.__id) && (wid?.id?.split('-')[1] == "LABEL")))
            console.log("oldWid for label", oldWid)
            let attributeTitleWidget: any = oldWid || this.createDetailsAttrTitleWidget(attr)
            detailsWidgetMetas.push(attributeTitleWidget)
            rowMemberMap[attributeTitleWidget.id] = [valueWidget.id]
          }
          // valueWidget = this.createDetailsWidget(attr)
          console.log("value widget", valueWidget)

          valueWidget['gridX'] = panelMeta.showLabels ? 5 : 12
          valueWidget['origin'] = "detailspanel";
          detailsWidgetMetas.push(valueWidget)
        }
      }
    }
    widgetMetas = this.mergeWidgetArrays(widgetMetas, detailsWidgetMetas)

    let layoutMap = panelMeta.layoutMap;
    // console.log("widgetList generated", JSON.parse(JSON.stringify(widgetMetas)));
    // console.log("layoutMap", JSON.parse(JSON.stringify(layoutMap)));
    let oldWidgets = this.getWidgetsFromPanel(panelMeta)
    // console.log("old widgets", JSON.parse(JSON.stringify(oldWidgets)))
    if (isOldMetaShowLabels !== panelMeta.showLabels || !layoutMap || !oldWidgets.length) {
      layoutMap = this.transformWidgetToLayout(widgetMetas, rowMemberMap)
    } else {
      // console.log("will alter existing layoutmap", JSON.parse(JSON.stringify(layoutMap)))
      let injectedWidgets: any = [] // widgets that are placed into layoutMap
      
      // if widget already exists for this attribute but of different type, then replace
      // console.log("widgetList to insert", JSON.parse(JSON.stringify(widgetMetas)));
      let widgetMetasCopy = JSON.parse(JSON.stringify(widgetMetas))
      for(let i = 0; i < widgetMetasCopy.length; i++){
        let w = widgetMetasCopy[i]
        // console.log("index", i, "handlinggg wid", JSON.parse(JSON.stringify(w)))
        let widgetExistanceMap = this.findWidgetFromLayoutMap(panelMeta.layoutMap, w.id)
        // console.log("widget existance map", widgetExistanceMap)
        if(widgetExistanceMap) {
          if(w.type !== widgetExistanceMap.wid?.type) { // if exists bt different widget type, then only replace, ignore if same to preserve styles
            layoutMap[widgetExistanceMap.colId][widgetExistanceMap.rowId].elements[widgetExistanceMap.index] = w
          }
          injectedWidgets.push(w)
          let removalIndex = widgetMetas.findIndex(widd => String(widd.id) == String(w.id))
          widgetMetas.splice(removalIndex, 1)  // at the end, widgetMetas will contain widgets that are not placed into layoutMap
        }
      }
      console.log("widgets injected into layoutMap", JSON.parse(JSON.stringify(layoutMap)))

      // if widget not already injected, add as new widgets now at the end
      widgetMetas.forEach((w, i) => {
        // console.log("handling uninjected wid", w)
        let lastColId = panelMeta.layoutMap.list[panelMeta.layoutMap.list.length - 1]
        let newRowId = Date.now() + "" + Math.floor(100000 + Math.random() * 900000);
        layoutMap[lastColId].list.push(newRowId)
        layoutMap[lastColId][newRowId] = {
          elements: [w],
          type: "elements"
        }
      })
      console.log("new widgets injected", JSON.parse(JSON.stringify(layoutMap)))

      // remove expired widgets
      layoutMap.list.forEach((colId, i) => {
        layoutMap[colId].list.forEach((rowId, j) => {
          layoutMap[colId][rowId].elements.forEach((wid, k) => {

            // if some widget exists that is not manually added and that is not yet to be placed and not already placed, it should be removed
            if (String(wid.id).split('-')[1] || String(wid.id).includes('LABEL')) {
              if (!injectedWidgets.find(w => w.id == wid.id) && !widgetMetas.find(w => w.id == wid.id)) {
                layoutMap[colId][rowId].elements.splice(k, 1)
              }
            }
          })
        })
      })
      console.log("removed expired widgets", JSON.parse(JSON.stringify(layoutMap)))
    }
    return layoutMap;
  }

  findWidgetFromLayoutMap(layoutMap: any, widgetId: string) {
    console.log("layoutMap", JSON.parse(JSON.stringify(layoutMap || {})), "widget id", widgetId)
    let widgetExistanceMap = null
    layoutMap?.list?.forEach(colId => {
      layoutMap[colId].list.forEach(rowId => {
        layoutMap[colId][rowId].elements.forEach((wid, i) => {
          if(wid.id == widgetId) 
            widgetExistanceMap = {
            colId: colId,
            rowId: rowId,
            index: i,
            widget: wid
          }
        })
      })
    })
    return widgetExistanceMap
  }

  /**
   * cretes input widgets for the form
   * @param panelMeta
   * @returns layoutMap
   */
  generateDetailsWidgetsOld(panelMeta: any){
    // console.log("panelMeta", panelMeta)
    // console.log("panelMeta.detailsAttributes", panelMeta.detailsAttributes)
    // console.log("isPresent", Object.keys(panelMeta).find(key => key == 'detailsAttributes'))
    let attributes: any = panelMeta.detailsAttributes
    console.log("hit generate widget fn", attributes)
    let widgetMetas: any[] = []
    let detailsWidgetMetas: any[] = []
    let oldWidgetMetas: any[] = []

    if(panelMeta.widgets?.length){
      widgetMetas = JSON.parse(JSON.stringify(panelMeta.widgets))
    } else {
      widgetMetas = JSON.parse(JSON.stringify(this.getWidgetsFromPanel(panelMeta)));
    }
    // console.log("widgets", widgetMetas)

    if(!panelMeta.showLabels){
      widgetMetas = widgetMetas.filter(w => w.id.split('-')[1] !== 'LABEL')
    }
    // console.log("LABELs removed", JSON.parse(JSON.stringify(widgetMetas)))

    widgetMetas?.forEach((widget, i) => {
      if(widget.id.split('-').length > 1){
        oldWidgetMetas.push(JSON.parse(JSON.stringify(widgetMetas[i])))
        widgetMetas[i] = null
      }
    })
    console.log("old widgets", widgetMetas)

    // key is row's first widget's id, value is array containing other widget ids
    // e.g. { wid1_id: [wid2_id, wid3_id] }
    let rowMemberMap: any = {}

    for (let i = 0; i < attributes.length; i++) {


      const detailsAttr = attributes[i];
      if(!detailsAttr.enabled) continue
      console.log("dealng details attribute", detailsAttr)

      let valueWidget: any
      let attr: any = {}
      if(!detailsAttr.isDrillDown){
        attr = detailsAttr
        let j = oldWidgetMetas.findIndex(wid => wid?.id?.split('-')[1] == attr.__id)
        if(j > -1 && oldWidgetMetas[j].type == attr.widgetType){
          valueWidget = oldWidgetMetas[j]
          // console.log("old widget exists for", attr.__id, "=>", valueWidget)
        }else{
          valueWidget = this.createDetailsWidget(attr)
          // console.log("created new widget for", attr.__id)
        }
        valueWidget['gridX'] = panelMeta.showLabels ? 5 : 12
        valueWidget['origin'] = "detailspanel";

        if(panelMeta.showLabels){
          let oldWid = oldWidgetMetas.find(wid => ((wid.id.split('-')[2] == attr.__id) && (wid?.id?.split('-')[1] == "LABEL")))
          // console.log("oldWid for label", oldWid)
          let attributeTitleWidget: any = oldWid || this.createDetailsAttrTitleWidget(attr)
          detailsWidgetMetas.push(attributeTitleWidget)
          rowMemberMap[attributeTitleWidget.id] = [valueWidget.id]
        }

        detailsWidgetMetas.push(valueWidget)

      }else{
        console.log("drill down handler")
        for (let i = 0; i < detailsAttr.nestedProperties.length; i++) {
          const element = detailsAttr.nestedProperties[i];
          console.log("nested prop", element)
          console.log("isNotPath ?", element.path.length == 0)
          console.log("isNotType ?", !element.widgetType)
          if(element.path.length == 0 || !element.widgetType) continue
          console.log("after if")
          attr = {
            name: element.customName || `${detailsAttr.name} -> ${element.path}`,
            __id: `${detailsAttr.__id}.${element.path}`,
            widgetType: element.widgetType,
            path: element.path
          }
          if(panelMeta.showLabels){
            // let attributeTitleWidget = this.createDetailsAttrTitleWidget(attr)
            // detailsWidgetMetas.push(attributeTitleWidget)
            let oldWid = oldWidgetMetas.find(wid => ((wid.id.split('-')[2] == attr.__id) && (wid?.id?.split('-')[1] == "LABEL")))
            console.log("oldWid for label", oldWid)
            let attributeTitleWidget: any = oldWid || this.createDetailsAttrTitleWidget(attr)
            detailsWidgetMetas.push(attributeTitleWidget)
            rowMemberMap[attributeTitleWidget.id] = [valueWidget.id]
          }
          valueWidget = this.createDetailsWidget(attr)
          console.log("value widget", valueWidget)

          valueWidget['gridX'] = panelMeta.showLabels ? 5 : 12
          valueWidget['origin'] = "detailspanel";
          detailsWidgetMetas.push(valueWidget)
        }
      }
    }
    widgetMetas = this.mergeWidgetArrays(widgetMetas, detailsWidgetMetas)

    console.log("widgetList generated", widgetMetas);
    let layoutMap = this.transformWidgetToLayout(widgetMetas, rowMemberMap)
    return layoutMap;
  }

  createDetailsAttrTitleWidget(attr){
    let attributeTitle: any = WidgetManager.getWidget('label', '', attr.name)
    attributeTitle.id = attributeTitle.id + "-" + "LABEL" + "-" + attr.__id
    attributeTitle.gridX = 5
    attributeTitle.name = this.toTitleCase(attr.name)
    attributeTitle.config.labelText.value = this.toTitleCase(attr.name) + ":"
    return attributeTitle
  }

  createDetailsWidget(attr: any){
    let valueWidget: any
    switch (attr.widgetType) {
      case 'label':
        valueWidget = WidgetManager.getWidget('label', null, attr.name)
        valueWidget.setValue('${' + this.toTitleCase(attr.name) + '}')
        console.log("value widget after set value", valueWidget)
        break;

      case 'image':
        valueWidget = WidgetManager.getWidget('image', null, attr.name)
        valueWidget.setValue('${reset}')
        break;

      case 'link':
        valueWidget = WidgetManager.getWidget('link', null, attr.name)
        valueWidget.setValue('${' +this.toTitleCase(attr.name) + '}')
        break;

      case 'embed':
        valueWidget = WidgetManager.getWidget('embed', null, attr.name)
        valueWidget.setValue('${' +this.toTitleCase(attr.name) + '}')
        break;

      case 'date':
        valueWidget = WidgetManager.getWidget('date', null, attr.name)
        valueWidget.config.viewOnly.value = true
        // valueWidget.setValue('${' + this.toTitleCase(attr.name) + '}')
        break;

      case 'time':
        valueWidget = WidgetManager.getWidget('label', null, attr.name)
        valueWidget.config.viewOnly.value = true
        valueWidget.setValue('${' +this.toTitleCase(attr.name) + '}')
        break;

      case 'datetime':
        valueWidget = WidgetManager.getWidget('datetime', null, attr.name)
        valueWidget.config.viewOnly.value = true
        // valueWidget.setValue('${' + this.toTitleCase(attr.name) + '}')
        break;

      case 'object':
        valueWidget = WidgetManager.getWidget('label', null, attr.name)
        valueWidget.setValue('${' +this.toTitleCase(attr.name) + '}')
        break;

      case 'array':
        valueWidget = WidgetManager.getWidget('label', null, attr.name)
        valueWidget.setValue('${' + this.toTitleCase(attr.name) + '}')
        break;

      case 'richtext':
        valueWidget = WidgetManager.getWidget('richtext', null, attr.name)
        valueWidget.config.viewOnly.value = true
        valueWidget.setValue('${' + attr.name + '}')
        break;

      case 'chips':
        valueWidget = WidgetManager.getWidget('chips', null, attr.name)
        valueWidget.config.inputMode.value = false
        valueWidget.config.showTitle.value = false
        valueWidget.config.availableOptions.staticOptions = []
        valueWidget.config.csvMode.value = true
        break;
      case 'tags':
        valueWidget = WidgetManager.getWidget('tags', null, attr.name)
        // valueWidget.config.viewOnly.value = true
        valueWidget.setValue('${' + attr.name + '}', true)
        break;

      case 'autocomplete':
        valueWidget = WidgetManager.getWidget('autocomplete', null, attr.name);
        valueWidget.config.viewOnly.value = true
        valueWidget.setValue('${' + attr.name + '}', true)
        break;
      case 'connection':
        valueWidget = WidgetManager.getWidget('connection', null, attr.name);
        valueWidget.config.viewOnly.value = true
        // valueWidget.setValue('${' + attr.name + '}', true)
        break;
      default:
        break;
    }
    valueWidget.id = valueWidget.id + '-' + attr.__id;
    return valueWidget
  }


  createActionConfig() {
    let actionConfig = {
      actions: [
        {
          event: 'click',
          action: 'application',
          actionMap: {
            action: '',
            boxId: '',
            connection: '',
            actionMode: '',
            mapping: []
          } 
        }
      ]
    }
    return actionConfig
  }

  createMapping(attr: any, widgetMeta: any, panelId: string) {
    let appField = JSON.parse(JSON.stringify(attr))
    delete appField.editable
    delete appField.enabled
    delete appField.widgetType

    let mappingType = 'sourceField'
    let sourceField = {
      name: widgetMeta.name,
      dataType: attr.dataType,
      __id: panelId + '.' + widgetMeta.id + '.value'
    }
    let mapping = {
      appField: appField,
      mappingType: mappingType,
      sourceField: sourceField
    }
    // console.log("mapping is", mapping)
    return mapping
  }


  createListPanel(searchPanelMeta: any, pageMeta: any) {
    console.log("will create list panel for search panel", searchPanelMeta)
    // console.log("pageMeta", pageMeta['panels'][0])

    let listPanel = new ListPanel(Date.now(), 'listPanel_' + (pageMeta.panels.length + 1));
    console.log("list panel created", listPanel)
    listPanel['viewTypes'] = {
      views: ['table', 'card', 'board'],
      defaultView: 'table',
      boardStatusColumn: '',
      userCanChoose: false,
      table: {name: 'table', displayName: 'Table', userCanChoose: true, icon: "list"},
      card: {name: 'card', displayName: 'Card', userCanChoose: true, icon: "grid_on"},
      board: {name: 'board', displayName: 'Board', userCanChoose: false, icon: "view_kanban"},
    }
    // set list panel properties
    listPanel['boxId'] = searchPanelMeta['boxId']
    listPanel['boxObjectId'] = searchPanelMeta['boxObjectId']
    listPanel['boxName'] = searchPanelMeta['boxName']
    listPanel['connectionId'] = searchPanelMeta['connectionId']

    return listPanel
  }

  /**
   * @param formBoxBinding: FormBoxBinding
   */
  async createFormPanelMeta(formBoxBinding: BoxBinding){
    let meta: any = new FormPanel(Date.now(), `${formBoxBinding.boxName} - ${formBoxBinding.boxObjectId} Form }`)
    meta['boxId'] = formBoxBinding['boxId'] || ''
    meta['boxName'] = formBoxBinding['boxName'] || ''
    meta['boxObjectId'] = formBoxBinding['boxObjectId'] || ''
    meta['connectionId'] = formBoxBinding['connectionId'] || ''
    // meta['formAttributes'] = formBoxBinding['formAttributes'] || []
    meta['attributeOptions'] = formBoxBinding['attributeOptions'] || []
    meta['getFnOptions'] = formBoxBinding['getFnOptions'] || []
    meta['primaryAttribute'] = formBoxBinding['primaryAttribute']
    meta['formPanelTitle'] = `${meta['boxName']} - ${meta['boxObjectId']} Form`

    // fetch attribute
    let attributes: any[] = []
    let response: any
    try {
      response = await this.bloomBoxService.getAttributes(meta.connectionId, meta.boxId, meta.boxObjectId, meta.attributeOptions)
    } catch (e) {
      console.error("error occurred in fetching attributes", e)
      throw e
    }
    if (!response || !response.result || !Array.isArray(response.result) || !response.result.length) {
      console.log("no attributes found")
      throw new Error('Attributes not found')
    } else {
      attributes = response.result

      meta['formAttributes'] = attributes
      console.log("form attributes", meta['formAttributes'])
      meta['formAttributes'].forEach(attr => {
        attr['editable'] = true
        attr['enabled'] = true
        attr['widgetType'] = dataTypeToWidgetMapForm[attr.dataType] || 'textinput'
      })
      console.log("form attributes", meta['formAttributes'])

      // find primary attribute
      meta['primaryAttribute'] = meta['formAttributes'].find(attr => attr.primary)
      console.log("primary", meta['primaryAttribute'])

      // generate widget list
      meta = this.generateFormWidgets(meta['formAttributes'], meta, null)
      console.log("meta", meta)

      if(!meta.submitDecoupled) meta['submitButtonMeta'] = meta.widgets[meta.widgets.length - 1]
      meta['submitButtonTitle'] = 'Submit'
      meta['layout'] = 'flex-start'
      meta['selectedNow'] = false

      return meta
    }
  }

  /**
   * @param formBoxBinding: FormBoxBinding
   */
  async createDetailsPanelMeta(detailsBoxBinding: BoxBinding){
    let meta: any = new DetailsPanel(Date.now(), `${detailsBoxBinding.boxName} - ${detailsBoxBinding.boxObjectId} Details`)
    meta['boxId'] = detailsBoxBinding['boxId'] || ''
    meta['boxName'] = detailsBoxBinding['boxName'] || ''
    meta['boxObjectId'] = detailsBoxBinding['boxObjectId'] || ''
    meta['connectionId'] = detailsBoxBinding['connectionId'] || ''
    // meta['formAttributes'] = formBoxBinding['formAttributes'] || []
    meta['attributeOptions'] = detailsBoxBinding['attributeOptions'] || []
    meta['getFnOptions'] = detailsBoxBinding['getFnOptions'] || []
    meta['primaryAttribute'] = detailsBoxBinding['primaryAttribute']
    meta['detailsPanelTitle'] = `${meta['boxName']} - ${meta['boxObjectId']} Details`

    // fetch attribute
    let attributes: any[] = []
    let response: any
    try{
      response = await this.bloomBoxService.getAttributes(meta.connectionId, meta.boxId, meta.boxObjectId, meta.attributeOptions)
    }catch(e){
      console.error("error occurred in fetching attributes", e)
      throw e
    }
    if(!response || !response.result || !Array.isArray(response.result) || !response.result.length){
      console.log("no attributes found")
      throw new Error('Attributes not found')
    }else{
      attributes = response.result

      meta['detailsAttributes'] = attributes
      console.log("details attributes", meta['detailsAttributes'])
      meta['detailsAttributes'].forEach(attr => {
        attr['enabled'] = true
        attr['widgetType'] = dataTypeToWidgetMapForDetails[attr.dataType] || 'label'
      })
      console.log("details attributes", meta['detailsAttributes'])

      // find primary attribute
      meta['primaryAttribute'] = meta['detailsAttributes'].find(attr => attr.primary)
      console.log("primary", meta['primaryAttribute'])

      // generate widget list
      meta['widgets'] = await this.generateDetailsWidgets(meta['detailsAttributes'])
      console.log("meta", meta)

      meta['layout'] = 'flex-start'

      return meta
    }
  }

  openPopupDialog(formPanel?: any, detailsPanel?: any) {
    const dialogRef = this.dialog.open(PopupEditViewComponent, {
      // position: { right: 0, top: 0, bottom: 0 },
      width: '600px',
      maxHeight: '100vh',
      height: '100vh',
      data: { formPanel: formPanel, detailsPanel: detailsPanel },
      position: { right: '0', top: '0' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
