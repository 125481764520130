<div fxLayout="row" class="cover">
  <div fxLayout="column" fxFlex="2"> </div>
  <div fxLayout="column" fxFlex="96">

    <mat-accordion multi>
      <div cdkDropList [cdkDropListData]="widgetMeta.actionConfig?.actions" (cdkDropListDropped)="actionReorder($event)">

    <mat-expansion-panel
    [disabled]="isActionDisabled(i)"
    [expanded]="(!isHidden(i, widgetMeta.actionConfig?.actions) && widgetMeta.actionConfig?.actions?.length === i+1 && !isActionDisabled(i)) || (config?.hideLastAction && widgetMeta.actionConfig?.actions?.length === i + 2 && !isActionDisabled(i))"
     *ngFor="let action of widgetMeta.actionConfig?.actions;let i = index">
     <mat-expansion-panel-header
      *ngIf="!isHidden(i, widgetMeta.actionConfig?.actions)">
        <mat-panel-title>
          <div fxLayout fxLayoutAlign="space-between center">
            <mat-icon class="handle" cdkDrag cdkDragHandle style="opacity: 20%;">drag_indicator</mat-icon>
             Action #{{i + 1}}
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <div fxLayout="row" fxFlex="90">
            <div fxLayout="column">
              <div *ngIf="this.config?.disableActionsMap?.[i + 1]?.actionDesc; else showSecondDesc">
                {{ this.config.disableActionsMap[i + 1].actionDesc }}
              </div>
              <ng-template #showSecondDesc>
                <div>
                  on {{ action.event }} : {{ action.action.charAt(0).toUpperCase() + action.action.slice(1) }}
                </div>
              </ng-template>
            </div>
          </div>
          <div fxLayout="row" fxFlex="10" *ngIf="!isActionDisabled(i)">
            <mat-icon color="{{action?.condition?.conditions?.length ? 'accent': '' }}" (click)="onConditionClick($event, action)"
            matTooltip="{{action?.condition?.conditions?.length ? 'Update the conditions': 'Setup condition to execute this action' }}"
            >alt_route</mat-icon>
          </div>
          <div fxLayout="row" fxFlex="10" *ngIf="isActionDisabled(i)">
          </div>

        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-card appearance="outlined" *ngIf="!isHidden(i, widgetMeta.actionConfig.actions)">
        <div>
          <div fxLayout="column" fxFlex="95"></div>
          <div fxLayout="column" fxFlex="5">
            <mat-icon style="cursor: pointer;" color="warn"
            (click)="removeAction(widgetMeta.actionConfig.actions, i)">
            delete
            </mat-icon>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap=".3rem" *ngIf="!config?.hideActionSelection">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="5">
            <div> On </div>
          </div>
          <div class="acItem" fxLayout="column" fxFlex="35">
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon class="icon" style="min-width: 1.5rem;" matTooltip="On mouse event" color="primary"> mouse </mat-icon>
              <mat-form-field class="no-bottom-margin">
                <mat-select [(ngModel)] = "action.event">
                  <mat-option [value]="option.id" *ngFor="let option of availableEvents">
                    {{ option.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="acItem descMsg" fxLayout="column" fxFlex="25"> &nbsp;execute the action</div>

          <div class="acItem" fxLayout="column" fxFlex="30">
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon class="icon" style="min-width: 1.5rem;" matTooltip="Action" color="primary">settings_applications</mat-icon>
              <mat-form-field class="no-bottom-margin">
                <mat-select [(ngModel)] = "action.action">
                  <mat-option [value]="option.id" *ngFor="let option of availableActions">
                    {{ option.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="column" fxFlex="5"> &nbsp;</div>
        </div>

        <div fxLayout="row" *ngIf="!config?.hideActionSelection" style="font-style: italic;background: #eeecec;border-radius: 5px;" fxLayoutAlign="start center">
          <mat-icon style="font-size: 20px;">info_outline</mat-icon> {{actionDetailMap[action.action].desc}}
        </div>


        <div fxLayout="row">
          <fieldset class="fieldset-container">
            <legend> {{actionDetailMap[action.action].name}}</legend>
          
            <app-action-navigation
              *ngIf="action.action === 'navigation'"
              [actionConfig]="action"
              [widget] = "widgetMeta"
            ></app-action-navigation>
            
            <app-action-application
            *ngIf="action.action === 'application'"
            [actionConfig]="action"
            [widget] = "widgetMeta"
            [displayConfig] = "displayConfig"
            ></app-action-application>

            <app-widget-action
              *ngIf="action.action === 'widget'"
              [actionConfig]="action"
              [widget] = "widgetMeta">
            </app-widget-action>

            <app-action-flow
              *ngIf="action.action === 'flow'"
              [actionConfig]="action"
              [hideUseInput] = "config?.hideFlowUseInput"
              [widget] = "widgetMeta">
            </app-action-flow>

          </fieldset>
        </div>

    </mat-card>
    </mat-expansion-panel>
    </div>
    </mat-accordion>

  <div style="height: 10px;"> </div>
  </div>
  <div fxLayout="column" fxFlex="2"> </div>
</div>

<div fxLayout="row" >
  <div fxLayout="column" fxFlex="2"> </div>
  <div fxLayout="column" fxFlex="96">
    <div fxLayout="row" fxLayoutAlign="start center">
      <button *ngIf="!config?.allowActions || config?.allowActions.length == 0" style="cursor: pointer;" (click)="addNewAction()"  color="primary" mat-stroked-button matTooltip="Add new action">
          <mat-icon >
              add_circle_outline
           </mat-icon>
           Add action
      </button>

      <button [matMenuTriggerFor]="actionmenu" *ngIf="config?.allowActions?.length > 0" style="cursor: pointer;"
         color="primary" mat-stroked-button matTooltip="Add new action">
        <mat-icon >
            add_circle_outline
         </mat-icon>
         Add action
    </button>
    </div>
    <mat-menu #actionmenu="matMenu">
      <div *ngFor="let actionDetail of actionDetailsArray" fxLayoutAlign="start">
       <button style="min-width: 20px;" fxLayoutAlign="start" *ngIf="config?.allowActions?.includes(actionDetail.key)" mat-button
       matTooltip="{{actionDetail.name}} Action" (click)="addNewAction({action: actionDetail.key})">
         {{actionDetail.name}}
       </button>
      </div>
     </mat-menu>
  </div>
  <div fxLayout="column" fxFlex="2"> </div>
</div>





<pre>
  <!-- {{widgetMeta | json}} -->
</pre>
<!-- <h4>Visibility</h4>
<mat-form-field appearance="fill">
  <mat-label>Visibility options</mat-label>
  <mat-select [(value)]="selectedVisibility">
    <mat-option></mat-option>
    <mat-option [value]="option.value" *ngFor="let option of visibilityOptions">{{ option }}</mat-option>
  </mat-select>
</mat-form-field> -->

<!-- <h4>Events</h4> -->
<!-- DOM event -->
<!-- <form class="events">
  <mat-form-field appearance="fill">
    <mat-label>Event type</mat-label>
    <mat-select
      [(value)]="selectedDomEvent"
      (selectionChange)="domEventChange($event)"
    >
      <mat-option></mat-option>
      <mat-option [value]="option" *ngFor="let option of domEvents">{{ option }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Event scope</mat-label>
    <mat-select [(value)]="selectedEventScope">
      <mat-option></mat-option>
      <mat-option [value]="option" *ngFor="let option of eventScopes">{{ option }}</mat-option>
    </mat-select>
  </mat-form-field>

</form> -->

<!-- Connection -->

<!-- <h4>Connections</h4> -->
<!-- autocomplete for boxID(connection type) -->
<form class="connections" *ngIf="isReceivedConnections">
  <!-- <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Connection</mat-label>
    <input type="text"
      placeholder="Pick one"
      aria-label="connection"
      matInput
      [formControl]="selectBoxControl"
      [matAutocomplete]="auto"
    >
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="boxSelected($event)">
      <mat-option *ngFor="let box of filteredBoxes | async" [value]="box">
        {{box}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field> -->


  <!-- autocomplete for box object -->
<!-- <form class="box_object_select_ac"> -->
  <!-- <mat-form-field class="example-full-width" appearance="fill" *ngIf="isBoxSelected && isReceivedBoxObjects">
    <mat-label>Box Objects</mat-label>
    <input type="text"
           placeholder="Pick one"
           aria-label="box object"
           matInput
           [formControl]="selectBoxObjectControl"
           [matAutocomplete]="auto2">
    <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" (optionSelected)="boxObjectSelected($event)">
      <mat-option *ngFor="let boxObject of filteredBoxObjects | async" [value]="boxObject">
        {{boxObject}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field> -->
<!-- </form> -->


  <!-- <mat-form-field *ngIf="isGotBoxObjectFunction"  appearance="fill" (click)="$event.preventDefault()">
    <mat-label>Box Object function</mat-label>
    <mat-select
      (click)="$event.preventDefault()"
      [(value)]="selectedBoxObjectFunction"
      (selectionChange)="boxObjectFunctionSelection($event)"
    >
      <ng-container *ngFor="let boxObjectfunction of boxObjectFunctions" >
        <mat-option
          [value]="boxObjectfunction.functionName"
        >
          {{boxObjectfunction.functionName}}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field> -->

  <!-- <button
    *ngIf="isBoxObjectFunctionSelected"
    mat-raised-button
    (click)="saveActionConfig()"
  >
    Save action Config
  </button> -->

</form>

<!-- mapping -->
<ng-container *ngIf="isBoxObjectFunctionSelected">
  <!-- <h4>Map attributes to available inputs</h4> -->
  <!-- <button
    mat-raised-button
    (click)="configureSourceMap()"
  >
    configure source mapping
  </button> -->

  <!-- <button
    mat-raised-button
    (click)="executeAction()"
  >
    Execute action
  </button> -->
</ng-container>






