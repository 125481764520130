import { Injectable } from "@angular/core";
import { MetaService } from "src/app/bloom/services/meta-service";
import { ThemeService } from "../../services/theme.service";
import { BaseExpression } from "./BaseExpression";


@Injectable({
  providedIn: 'root',
})
export class BloomUserLocaleExpression extends BaseExpression {

  config: any = {}

  constructor(private themeService?: ThemeService){
    super()
    this.config.id = '__bloomuserlocale__';
    this.config.name = "Bloom User Locale Settings";
    this.config.description = "Returns bloom user locale setting";
    this.config.parameters = {
      paramIds: [],
    }
  }

  evaluateExpression(config?: any) {
    if(config?.id !== this.config.id) return ''
    let currentLocale = this.themeService?.currentLocale;
    console.log("current bloom currentLocale", currentLocale)
    if(!currentLocale) currentLocale = {};
    return currentLocale || null;
  }

  getExpressionDetail() {
    return this.config
  }
}
